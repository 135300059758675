import React from 'react';

import { useCustomerServiceOrderInstallationAppointmentCollectionData } from '@abb-emobility/platform/data-provider';
import { CustomerServiceOrderInstallationAppointmentModel } from '@abb-emobility/platform/domain-model';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	SectionHeader,
	SegmentHeader,
	TabularInformationItem,
	TabularInformationItemType,
	TabularInformationRow
} from '@abb-emobility/shared/ui-primitive';

type SortedTradeAppointment = {
	tradeName: string,
	installerAppointments: Array<CustomerServiceOrderInstallationAppointmentModel>
};

export function PlannedInstallationInformation() {

	const l10n = useL10n();
	const appointmentCollectionData = useCustomerServiceOrderInstallationAppointmentCollectionData();
	const appointmentCollection = appointmentCollectionData.query();

	const sortedAppointments = (): Array<SortedTradeAppointment> => {
		const result: Array<SortedTradeAppointment> = [];

		appointmentCollection.forEach((installerAppointment) => {
			const availableResult = result.find(item => item.tradeName === installerAppointment.trade.name);

			if (availableResult) {
				availableResult.installerAppointments.push(installerAppointment);
				return;
			} else {
				result.push({
					tradeName: installerAppointment.trade.name,
					installerAppointments: [installerAppointment]
				});
			}
		});

		return result;
	};

	const renderTradeAppointments = () => {
		return sortedAppointments().map((tradeAppointment) => {

			const renderAppointments = () => {
				return tradeAppointment.installerAppointments.map((appointment, index) => {

					const { name = 'Name', phoneNumber = 'Number', mailAddress = 'Mail' } = appointment.installer;

					const startTime = l10n.formatTimestampTime(appointment.period.start);
					const endTime = l10n.formatTimestampTime(appointment.period.end);

					return (
						<TabularInformationRow key={index}>
							<TabularInformationItem
								label={name}
								variant={TabularInformationItemType.GREY}
								width={200}
							/>
							<TabularInformationItem
								label={`${l10n.formatTimestampDate(appointment.periodStartsAt)}; ${startTime} - ${endTime}`}
								width={300}
							/>
							<TabularInformationItem
								label={phoneNumber}
							/>
							<TabularInformationItem label={mailAddress} />
						</TabularInformationRow>
					);
				});
			};

			return (
				<section className="order-detail-installation-appointments__section" key={tradeAppointment.tradeName}>
					<div className="order-detail-installation-appointments__section__header">
						<SectionHeader heading={tradeAppointment.tradeName} />
					</div>
					<TabularInformationRow>
						<TabularInformationItem
							label={l10n.translate('platformCustomerServiceApp.orderDetail.installation.installationAppointments.installer')}
							variant={TabularInformationItemType.HEADING}
							width={200}
						/>
						<TabularInformationItem
							label={l10n.translate('platformCustomerServiceApp.orderDetail.installation.installationAppointments.appointment')}
							variant={TabularInformationItemType.HEADING}
							width={300}
						/>
						<TabularInformationItem
							label={l10n.translate('platformCustomerServiceApp.orderDetail.installation.installationAppointments.phone')}
							variant={TabularInformationItemType.HEADING}
						/>
						<TabularInformationItem
							label={l10n.translate('platformCustomerServiceApp.orderDetail.installation.installationAppointments.mail')}
							variant={TabularInformationItemType.HEADING}
						/>
					</TabularInformationRow>

					{renderAppointments()}
				</section>
			);
		});
	};

	return (
		<section>
			<SegmentHeader caption={l10n.translate('platformCustomerServiceApp.orderDetail.installation.installationAppointments.heading')} />

			<section className="order-detail-installation-appointments">
				{renderTradeAppointments()}
			</section>
		</section>
	);
}
