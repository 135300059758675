import {
	CustomerServiceOrderEventEntityApiClientFactory,
	CustomerServiceOrderEventEntityApiClientInterface
} from '@abb-emobility/platform/api-integration';
import { AnyCustomerServiceOrderEventEntityModel } from '@abb-emobility/platform/domain-model';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import {
	createCrudEntitySlice,
	createCrudDeleteThunk,
	createCrudFetchEntityThunk,
	createCrudMutateThunk,
	createCrudSelectEntity,
	CrudEntityStore,
	CrudEntityStoreAccessor,
	ThunkApiConfig,
	createCrudSelectHypermediaLinks, createCrudEntityStoreSize, createCrudEntityStoreEntryIds
} from '@abb-emobility/shared/data-provider-foundation';
import { Nullable } from '@abb-emobility/shared/util';

// Basic definition
export const customerServiceOrderEventEntityStoreName = 'customer-service-order-event-entity-crud';
export type CustomerServiceOrderEventEntityStore = { [customerServiceOrderEventEntityStoreName]: CrudEntityStore<AnyCustomerServiceOrderEventEntityModel> };

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the
const createApiClient = (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) => {
	return CustomerServiceOrderEventEntityApiClientFactory.create(apiBaseUrl, jsonWebToken);
};
const fetchThunk = createCrudFetchEntityThunk<AnyCustomerServiceOrderEventEntityModel, CustomerServiceOrderEventEntityStore, CustomerServiceOrderEventEntityApiClientInterface>(customerServiceOrderEventEntityStoreName, createApiClient);
const mutateThunk = createCrudMutateThunk<AnyCustomerServiceOrderEventEntityModel, CustomerServiceOrderEventEntityApiClientInterface>(customerServiceOrderEventEntityStoreName, createApiClient);
const deleteThunk = createCrudDeleteThunk<AnyCustomerServiceOrderEventEntityModel, CustomerServiceOrderEventEntityApiClientInterface>(customerServiceOrderEventEntityStoreName, createApiClient);

// Slice definition
export const customerServiceOrderEventEntitySlice = createCrudEntitySlice<AnyCustomerServiceOrderEventEntityModel, ThunkApiConfig>(customerServiceOrderEventEntityStoreName, fetchThunk, mutateThunk, deleteThunk);
const { resolveFetchStatus, resolveActionStatus, resetStore } = customerServiceOrderEventEntitySlice.actions;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
const select = createCrudSelectEntity<AnyCustomerServiceOrderEventEntityModel, CustomerServiceOrderEventEntityStore>(customerServiceOrderEventEntityStoreName);
const selectHypermediaLinks = createCrudSelectHypermediaLinks<AnyCustomerServiceOrderEventEntityModel, CustomerServiceOrderEventEntityStore>(customerServiceOrderEventEntityStoreName);
const storeSize = createCrudEntityStoreSize<AnyCustomerServiceOrderEventEntityModel, CustomerServiceOrderEventEntityStore>(customerServiceOrderEventEntityStoreName);
const storeEntryIds = createCrudEntityStoreEntryIds<AnyCustomerServiceOrderEventEntityModel, CustomerServiceOrderEventEntityStore>(customerServiceOrderEventEntityStoreName);

// Export the store accessors to be used by the data provider value
export const customerServiceOrderEventEntityStoreAccessors = {
	fetch: fetchThunk,
	mutate: mutateThunk,
	delete: deleteThunk,
	resolveFetchStatus,
	resolveActionStatus,
	select,
	selectHypermediaLinks,
	resetStore,
	storeSize,
	storeEntryIds
} as CrudEntityStoreAccessor<AnyCustomerServiceOrderEventEntityModel, CustomerServiceOrderEventEntityStore>;
