import { CustomerServiceOrderLineItemModel } from '@abb-emobility/platform/domain-model';
import { createTimestampDtoFromDate, Dto } from '@abb-emobility/shared/domain-model-foundation';

import { pricingModelMock01, pricingModelMock02, pricingModelMock03 } from './common/PricingModel.mock';

export const customerServiceOrderLineItemModelMock01 = (): Dto<CustomerServiceOrderLineItemModel> => {
	return {
		id: '1',
		productName: 'Extended warranty 2 years',
		price: pricingModelMock01,
		productCode: '77351',
		cancelled: false
	};
};

export const customerServiceOrderLineItemModelMock02 = (): Dto<CustomerServiceOrderLineItemModel> => {
	return {
		id: '2',
		productName: 'Terra AC Wallbox, IP54',
		price: pricingModelMock02,
		productCode: '77352',
		cancelled: false
	};
};

export const customerServiceOrderLineItemModelMock03 = (): Dto<CustomerServiceOrderLineItemModel> => {
	return {
		id: '3',
		productName: 'TAC pedestal single-wallbox',
		price: pricingModelMock03,
		productCode: '77351',
		cancelled: true,
		cancellationDate: createTimestampDtoFromDate(new Date())
	};
};
