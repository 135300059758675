import {
	AnyCustomerServiceOrderInformationCollectionItemModel,
	AnyCustomerServiceOrderInformationCollectionItemModelConverter
} from '@abb-emobility/platform/domain-model';
import { AbstractCrudApiClient } from '@abb-emobility/shared/api-integration-foundation';

export class AnyCustomerServiceOrderInformationCollectionApiClient extends AbstractCrudApiClient<AnyCustomerServiceOrderInformationCollectionItemModel> {
	protected collectionControllerUri = 'order-informations/';
	protected paginationControllerUri = 'order-informations/';
	protected entityControllerUri = 'order-information/{id}/';
	protected entitySearchControllerUri = 'order-information/';
	protected override paginationSize = 20;
	protected modelConverter = new AnyCustomerServiceOrderInformationCollectionItemModelConverter();
}
