import { AnyCustomerServiceOrderInformationModel} from '@abb-emobility/platform/domain-model';
import { CrudApiClient } from '@abb-emobility/shared/api-integration-foundation';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import { Nullable } from '@abb-emobility/shared/util';

import { AnyCustomerServiceOrderInformationApiClient } from './AnyCustomerServiceOrderInformationApiClient';
import { AnyCustomerServiceOrderInformationApiClientMock } from './AnyCustomerServiceOrderInformationApiClientMock';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AnyCustomerServiceOrderInformationApiClientInterface extends CrudApiClient<AnyCustomerServiceOrderInformationModel> {
}

export class AnyCustomerServiceOrderInformationApiClientFactory {

	private static mockConfigured = false;

	public static create(baseUrl: string, jsonWebToken: Nullable<JsonWebToken> = null): AnyCustomerServiceOrderInformationApiClientInterface {
		if (process.env.NX_PLATFORM_CS_ORDER_API_MOCKED === 'true') {
			const apiClientMock = new AnyCustomerServiceOrderInformationApiClientMock().init(baseUrl, jsonWebToken);
			if (!this.mockConfigured) {
				apiClientMock.configureFetchMock(baseUrl);
				this.mockConfigured = true;
			}
			return apiClientMock;
		}
		return new AnyCustomerServiceOrderInformationApiClient().init(baseUrl, jsonWebToken);
	}

}
