import { useEffect } from 'react';

import { useActionbarContent } from './ActionbarContent.context';
import { HypermediaAction } from '../action-modal-factory/ActionModalFactory.types';
import { ApplicationAction } from '../application-action-factory/ApplicationActionItemFactory.types';

export const useActionbarContentModifier = (hypermediaActions: Array<HypermediaAction>, rerouteActions: Array<ApplicationAction>): void => {
	const actionbarContent = useActionbarContent();

	useEffect(() => {
		actionbarContent.setHypermediaActions(hypermediaActions);
		actionbarContent.setApplicationActions(rerouteActions);
	}, []);
};
