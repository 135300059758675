import { CustomerServiceCancelOrderPayloadModel } from '@abb-emobility/platform/domain-model';
import { Dto } from '@abb-emobility/shared/domain-model-foundation';

import {
	customerServiceOrderLineItemModelMock01,
	customerServiceOrderLineItemModelMock02, customerServiceOrderLineItemModelMock03
} from '../../CustomerServiceOrderLineItemModel.mock';

export const customerServiceCancelOrderPayloadModelMock: Dto<CustomerServiceCancelOrderPayloadModel> = {
	orderLineItems: [
		customerServiceOrderLineItemModelMock01(),
		customerServiceOrderLineItemModelMock02(),
		customerServiceOrderLineItemModelMock03()
	]
};
