import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	platformCustomerServiceApp: {
		action: {
			showCustomer: 'Mostrar cliente'
		},
		actionItemFactory: {
			addCustomerNoteLabel: 'Añadir nota',
			addOrderNoteLabel: 'Añadir nota',
			cancelOrderLabel: 'Cancelar',
			cancelInstallationLabel: 'Cancelar instalación',
			cancelOrderAppointmentsLabel: 'Cancelar citas',
			externalLinkLabel: 'Enlace externo',
			resendMailLabel: 'Reenviar correo',
			returnOrderLabel: 'Devolver',
			replaceOrderLabel: 'Sustituir',
			updateCustomerLabel: 'Cambiar datos',
			updateOrderLabel: 'Cambiar datos'
		},
		actionModalFactory: {
			addCustomerNoteHeading: 'Añadir nota',
			addOrderNoteHeading: 'Añadir nota',
			cancelOrderHeading: 'Cancelar pedido',
			cancelInstallationHeading: 'Cancelar instalación',
			cancelOrderAppointmentsHeading: 'Cancelar citas de pedidos',
			resendMailHeading: 'Reenviar correo',
			returnOrderHeading: 'Devolver pedido',
			replaceOrderHeading: 'Sustituir pedido',
			updateCustomerHeading: 'Cambiar datos del cliente',
			updateOrderHeading: 'Cambiar datos del pedido'
		},
		actionModal: {
			pleaseSelect: 'Seleccionar',
			addNote: {
				content: {
					category: 'Categoría',
					categories: {
						order: 'Nota de pedido',
						customer: 'Nota del cliente',
						customerCall: 'Llamada del cliente'
					}
				}
			},
			addOrderNote: {
				content: {
					noteHeading: 'Nota',
					notePlaceholder: 'Insertar texto',
					successHeading: 'Nota de pedido creada con éxito',
					closeButtonLabel: 'Cerrar'
				},
				footer: {
					confirm: 'Confirmar',
					cancel: 'Cancelar'
				},
				error: {
					heading: 'No se ha podido crear nota de pedido.',
					message: 'Se ha producido un error al crear la nota.'
				}
			},
			addCustomerNote: {
				content: {
					noteHeading: 'Nota',
					notePlaceholder: 'Insertar texto',
					successHeading: 'Nota de cliente creada con éxito',
					closeButtonLabel: 'Cerrar'
				},
				footer: {
					confirm: 'Confirmar',
					cancel: 'Cancelar'
				},
				error: {
					heading: 'No se ha podido crear la nota de cliente.',
					message: 'Se ha producido un error al crear la nota de cliente.'
				}
			},
			addCustomerCallNote: {
				content: {
					noteHeading: 'Nota',
					notePlaceholder: 'Insertar texto',
					customerCallReason: 'Motivo de la llamada del cliente',
					callReasonOptions: {
						afterSales: 'Posventa',
						product: 'Producto',
						installation: 'Instalación',
						preCheck: 'Comprobación previa',
						payment: 'Pago',
						dataProtection: 'Protección de datos',
						shipment: 'Envío',
						cancellation: 'Devoluciones',
						falseRouting: 'Enrutamiento falso',
						other: 'Otros',
						complaints: 'Quejas',
						returns: 'Devoluciones'
					},
					callReasonNotePlaceholder: 'Descripción',
					successHeading: 'Nota de llamada del cliente creada con éxito',
					closeButtonLabel: 'Cerrar'
				},
				footer: {
					confirm: 'Confirmar',
					cancel: 'Cancelar'
				},
				error: {
					heading: 'No se ha podido crear la nota de llamada del cliente.',
					message: 'Se ha producido un error al crear la nota de llamada del cliente.'
				}
			},
			cancelOrder: {
				heading: 'Pedido',
				position: 'Posición',
				description: 'Descripción',
				reason: 'Motivo',
				note: 'Descripción',
				cancelReasonValue: {
					other: 'Otros',
					wallboxBroken: 'Wallbox roto',
					wallboxNotWorking: 'Wallbox no funciona',
					withdrawal: 'Retirada',
					installationNotPossible: 'Instalación no es posible'
				},
				footer: {
					cancel: 'Cancelar',
					confirm: 'Confirmar'
				},
				content: {
					successHeading: 'Pedido cancelado con éxito',
					closeButtonLabel: 'Cerrar'
				},
				error: {
					loading: {
						heading: 'No se han podido cargar los datos del pedido.',
						message: 'Se ha producido un error al cargar los datos del pedido.'
					},
					writing: {
						heading: 'No se ha podido cancelar el pedido.',
						message: 'Se ha producido un error al cancelar el pedido.'
					}
				}
			},
			cancelInstallation: {
				heading: 'Pedido',
				position: 'Posición',
				description: 'Descripción',
				reason: 'Motivo',
				note: 'Descripción',
				cancelReasonValue: {
					other: 'Otros',
					wallboxBroken: 'Wallbox roto',
					wallboxNotWorking: 'Wallbox no funciona',
					withdrawal: 'Retirada',
					installationNotPossible: 'Instalación no es posible'
				},
				footer: {
					cancel: 'Cancelar',
					confirm: 'Confirmar'
				},
				content: {
					successHeading: 'Cancelación de la instalación con éxito',
					closeButtonLabel: 'Cerrar'
				},
				error: {
					loading: {
						heading: 'No se han podido cargar los datos del pedido.',
						message: 'Se ha producido un error al cargar los datos del pedido.'
					},
					writing: {
						heading: 'No se pudo cancelar la instalación',
						message: 'Se ha producido un error al cancelar la instalación'
					}
				}
			},
			cancelOrderAppointments: {
				content: {
					notificationHeading: 'El socio instalador será informado de la cancelación de la cita.',
					notificationMessage: 'El socio instalador se pondrá en contacto con el cliente para concertar una nueva cita.',
					successHeading: 'Citas canceladas',
					successCloseButtonLabel: 'Cerrar',
					reason: 'Motivo'
				},
				cancelReasonValue: {
					deliveryDelay: 'Retraso en la entrega',
					installationPartnerConflict: 'Conflicto con socio instalador',
					customerConflict: 'Conflicto con cliente',
					replacement: 'Sustitución'
				},
				footer: {
					confirm: 'Confirmar cancelación',
					cancel: 'Cancelar'
				},
				error: {
					heading: 'No han podido cancelar citas.',
					message: 'Se ha producido un error al eliminar las citas del pedido.'
				}
			},
			resendMail: {
				content: {
					notificationHeading: 'El correo electrónico se reenviará.',
					notificationMessage: 'El cliente recibirá un nuevo correo electrónico.',
					successHeading: 'Correo electrónico enviado con éxito.',
					successCloseButtonLabel: 'Cerrar'
				},
				footer: {
					confirm: 'Reenviar correo electrónico',
					cancel: 'Cancelar'
				},
				error: {
					heading: 'No se ha podido reenviar el correo electrónico.',
					message: 'Se ha producido un error al enviar el correo electrónico.'
				}
			},
			returnOrder: {
				heading: 'Pedido',
				position: 'Posición',
				description: 'Descripción',
				note: 'Descripción',
				'return': 'Devolver',
				selectReturn: 'Seleccionar artículos de devolución',
				selectReason: 'Seleccionar el motivo de la devolución',
				replacementItem: 'Artículo de sustitución',
				yes: 'Sí',
				no: 'No',
				returnReason: {
					other: 'Otros',
					broken: 'Roto'
				},
				footer: {
					cancel: 'Cancelar',
					confirm: 'Confirmar'
				},
				content: {
					successHeading: 'Iniciado con éxito el proceso de devolución',
					closeButtonLabel: 'Cerrar'
				},
				error: {
					loading: {
						heading: 'No se han podido cargar los datos del pedido.',
						message: 'Se ha producido un error al cargar los datos del pedido.'
					},
					writing: {
						heading: 'No se ha podido iniciar el proceso de devolución.',
						message: 'Se ha producido un error al invocar el proceso de orden de devolución.'
					}
				}
			},
			replaceOrder: {
				heading: 'Pedido',
				position: 'Posición',
				description: 'Descripción',
				note: 'Descripción',
				'return': 'Sustituir',
				selectReturn: 'Seleccionar artículos de sustitución',
				selectReason: 'Seleccionar motivo de sustitución',
				returnReason: {
					other: 'Otros',
					broken: 'Roto'
				},
				footer: {
					cancel: 'Cancelar',
					confirm: 'Confirmar'
				},
				content: {
					successHeading: 'Iniciado con éxito el proceso de sustitución',
					closeButtonLabel: 'Cerrar'
				},
				error: {
					loading: {
						heading: 'No se han podido cargar los datos del pedido.',
						message: 'Se ha producido un error al cargar los datos del pedido.'
					},
					writing: {
						heading: 'No se ha podido iniciar el proceso de sustitución.',
						message: 'Se ha producido un error al invocar el proceso de orden de sustitución.'
					}
				}
			},
			updateCustomer: {
				content: {
					firstname: 'Nombre',
					lastname: 'Apellidos',
					email: 'Correo electrónico',
					personalInfoHeading: 'Información personal',
					contactDataHeading: 'Datos de contacto',
					successHeading: 'La información del usuario se ha modificado correctamente.',
					successCloseButtonLabel: 'Cerrar'
				},
				footer: {
					confirm: 'Confirmar',
					cancel: 'Cancelar'
				},
				error: {
					loading: {
						heading: 'No se ha podido cargar la información del cliente.',
						message: 'Se ha producido un error al cargar la información del cliente.'
					},
					writing: {
						heading: 'No se ha podido actualizar la información del cliente.',
						message: 'Se ha producido un error al actualizar la información del cliente.'
					}
				}
			},
			updateOrder: {
				content: {
					orderInformationHeading: 'Información del pedido',
					phoneLabel: 'Teléfono',
					languageHeading: 'Idioma',
					languageLabel: ' Código de idioma',
					successHeading: 'La información del pedido se ha modificado correctamente.',
					successCloseButtonLabel: 'Cerrar'
				},
				footer: {
					confirm: 'Confirmar',
					cancel: 'Cancelar'
				},
				error: {
					loading: {
						heading: 'No se ha podido cargar la información del pedido.',
						message: 'Se ha producido un error al cargar la información del pedido.'
					},
					writing: {
						heading: 'No se ha podido actualizar la información del pedido.',
						message: 'Se ha producido un error al actualizar la información del pedido.'
					}
				}
			}
		},
		appName: 'Servicio de atención al cliente de ABB',
		breadcrumbs: {
			tasks: 'Información general de tareas',
			orders: 'Información general del pedido',
			marketplaceOrders: 'Resumen de pedidos del mercado',
			customers: 'Información general del cliente'
		},
		comment: {
			categories: {
				order: 'Nota de pedido',
				customer: 'Nota del cliente',
				customerCall: 'Llamada del cliente'
			},
			callReason: {
				afterSales: 'Posventa',
				product: 'Producto',
				installation: 'Instalación',
				preCheck: 'Comprobación previa',
				payment: 'Pago',
				dataProtection: 'Protección de datos',
				shipment: 'Envío',
				cancellation: 'Devoluciones',
				falseRouting: 'Enrutamiento falso',
				other: 'Otros',
				complaints: 'Quejas',
				returns: 'Devoluciones'
			}
		},
		commentCollection: {
			heading: 'Notas',
			emptyState: {
				heading: 'Sin comentarios',
				message: 'Actualmente no hay comentarios disponibles.'
			}
		},
		common: {
			tableHeader: {
				position: 'Posición',
				description: 'Descripción'
			}
		},
		customerCollection: {
			heading: 'Clientes',
			tableHead: {
				customer: 'Cliente',
				customerId: 'Id. del cliente',
				firstOrder: 'Primer pedido',
				lastOrder: 'Último pedido'
			},
			emptyState: {
				heading: 'No se han encontrado clientes',
				message: 'Ajuste la configuración de sus filtros para encontrar algunos clientes.'
			},
			action: {
				showMore: 'Mostrar más'
			}
		},
		customerInformation: {
			heading: 'Información del cliente',
			tableHeader: {
				position: 'Posición',
				description: 'Descripción'
			},
			tableItem: {
				firstName: 'Nombre',
				lastName: 'Apellidos',
				companyName: 'Nombre de la empresa',
				mail: 'Correo',
				firstOrder: 'Primer pedido',
				lastOrder: 'Último pedido'
			}
		},
		customerOrders: {
			heading: 'Pedidos del cliente'
		},
		customerMarketplaceOrders: {
			heading: 'Marketplace pedidos del cliente'
		},
		customerDetail: {
			subheader: {
				customerId: 'Id. del cliente'
			},
			tabItems: {
				overview: 'Resumen',
				comments: 'Notas'
			}
		},
		error: {
			generic: {
				heading: 'Algo salió mal',
				message: 'Se ha producido un error imprevisto que no se ha podido resolver',
				resolveAction: {
					label: 'Volver a cargar'
				}
			},
			offline: {
				heading: 'Conexión de red no disponible',
				message: 'Conéctese a una red para utilizar la aplicación.'
			},
			notFound: {
				heading: 'No encontrado',
				message: 'No se ha encontrado el recurso solicitado',
				resolveAction: {
					label: 'Mis tareas'
				}
			},
			customerNotFound: 'No se ha encontrado el cliente solicitado',
			orderNotFound: 'No se ha encontrado el pedido solicitado',
			taskNotFound: 'No se ha encontrado la tarea solicitada',
			callerNotFound: 'No se ha encontrado información sobre la persona que llama',
			taskAssignMessage: 'Al asignar la tarea se ha producido un error.',
			taskCompleteMessage: 'Se produjo un error al completar la tarea.'
		},
		navigation: {
			tasks: 'Mis tareas',
			orders: 'Pedidos',
			marketplaceOrders: 'Marketplace Pedidos',
			customers: 'Clientes',
			logout: 'Cerrar sesión'
		},
		order: {
			installationType: {
				undetermined: 'Indeterminado',
				standard: 'Norma',
				delta: 'Delta',
				cancelled: 'Cancelado'
			}
		},
		orderDetail: {
			subheader: {
				orderDate: 'Fecha del pedido',
				orderId: 'Id. de pedido',
				marketplace: 'Marketplace',
				plannedDelivery: 'Entrega prevista',
				orderState: {
					onSchedule: 'Según lo previsto',
					delayed: 'Retrasado',
					cancelPending: 'Cancelar pendiente',
					cancelled: 'Cancelado',
					imported: 'Importado',
					validationFailed: 'No válido',
					done: 'Hecho'
				}
			},
			tabItems: {
				overview: 'Resumen',
				installation: 'Instalación',
				delivery: 'Entrega',
				accounting: 'Contabilidad',
				files: 'Archivos',
				comments: 'Notas',
				tasks: 'Tareas'
			},
			overview: {
				customerInformation: {
					heading: 'Información del cliente'
				},
				orderInformation: {
					heading: 'Información del pedido',
					tableHeader: {
						position: 'Posición',
						description: 'Descripción'
					},
					tableItem: {
						language: 'Idioma',
						phone: 'Teléfono',
						deliveryAddress: 'Dirección'
					}
				},
				timeline: {
					heading: 'Calendario'
				}
			},
			installation: {
				cancellation: {
					notification: {
						heading: 'La instalación ha sido cancelada',
						message: 'La cancelación se llevó a cabo el {{cancellationDate}}. El motivo indicado era "{{cancellationReason}}".'
					}
				},
				information: {
					heading: 'Información',
					types: {
						basicAnswers: 'Respuestas básicas',
						gridOperatorRegistration: 'Registro del operador de red',
						gridOperatorDecision: 'Decisión del operador de red',
						preCheck: 'Comprobación previa',
						offer: 'Oferta',
						approval: 'Aprobación'
					},
					emptyState: {
						heading: 'No se dispone de información sobre el pedido',
						message: 'No hay información disponible sobre el pedido.'
					}
				},
				installationAppointments: {
					heading: 'Instalaciones previstas',
					installer: 'Instalador',
					appointment: 'Cita',
					phone: 'Teléfono',
					mail: 'Correo electrónico',
					emptyState: {
						heading: 'No se han encontrado citas.',
						message: 'No hay citas planificadas.'
					}
				}
			},
			delivery: {
				orderItem: 'Artículo de pedido',
				productCode: 'Código de producto',
				address: {
					heading: 'Dirección de entrega',
					name: 'Nombre',
					street: 'Calle',
					location: 'Ciudad'
				},
				details: {
					heading: 'Detalles de la entrega',
					shippingDate: 'Fecha de envío',
					address: 'Dirección de envío',
					trackingCode: 'Código de seguimiento',
					state: 'Estado del envío',
					partner: 'Socio transportista',
					tracking: 'Seguimiento',
					shippingState: {
						delivered: 'Entregado',
						pending: 'Pendiente'
					},
					estimatedDeliveryDate: 'Fecha estimada de entrega',
					deliveryDate: 'Fecha de entrega'
				},
				retoureDetails: {
					heading: 'Detalles de devolución',
					date: 'Fecha de solicitud',
					address: 'Dirección de devolución',
					reason: 'Motivo de la devolución',
					returnNote: 'Nota de devolución',
					created: 'Creado en'
				},
				replacementDetails: {
					heading: 'Detalles de la entrega de sustitución'
				},
				emptyState: {
					heading: 'No hay información disponible sobre el envío',
					message: 'Actualmente no hay más información sobre el envío.'
				}
			},
			accounting: {
				heading: 'Transacción',
				date: 'Fecha de pago',
				state: 'Estado',
				notification: 'Mensaje de error',
				cardOwner: 'Propietario de la tarjeta',
				cardNumber: 'Número de tarjeta',
				cardDueDate: 'Fecha de vencimiento de la tarjeta',
				accountOwner: 'Titular de la cuenta',
				accountNumber: 'IBAN',
				paymentState: {
					success: 'Éxito',
					failed: 'Error',
					settlementPending: 'Liquidación pendiente'
				},
				emptyPaymentState: {
					heading: 'No hay información de pago disponible',
					message: 'Actualmente no hay más información sobre los pagos.'
				},
				emptyDownloadFilesState: {
					heading: 'No hay archivos de descarga disponibles',
					message: 'Actualmente no hay archivos de descarga disponibles.'
				}
			},
			files: {
				emptyState: {
					heading: 'No hay archivos de descarga disponibles',
					message: 'Actualmente no hay archivos de descarga disponibles.'
				}
			},
			throttleWallboxHint: {
				caption: 'Atención',
				message: 'El consumo de energía del wallbox debe reducirse en función de los requisitos del operador de red.'
			},
			informationList: {
				heading: 'Información',
				emptyState: {
					heading: 'Aún no hay información disponible sobre el pedido',
					message: 'A medida que avance el pedido, encontrará aquí toda la información pertinente sobre el mismo.'
				},
				caption: {
					basicAnswers: 'Respuestas básicas',
					gridOperatorRegistration: 'Registro del operador de red',
					gridOperatorDecision: 'Decisión del operador de red',
					preCheck: 'Comprobación previa',
					offer: 'Oferta',
					approval: 'Aprobación'
				},
				precheck: {
					heading: 'Comprobación previa',
					effort: 'Resultados de la comprobación previa: Citas y esfuerzo de instalación',
					questionnaire: {
						heading: 'Respuestas del cliente'
					},
					installationType: {
						undetermined: 'Indeterminado',
						standard: 'Instalación estándar',
						delta: 'Instalación Delta'
					},
					decision: {
						accepted: 'Información suficiente',
						more: 'Se necesita más información',
						denied: 'Instalación no es posible'
					},
					notes: 'Notas',
					hours: 'horas'
				},
				decision: {
					heading: 'Decisión del operador de red',
					decision: {
						pending: 'La solicitud de instalación sigue pendiente',
						accepted: 'El operador de red está de acuerdo con la instalación',
						denied: 'El operador de red rechazó la instalación'
					}
				},
				registration: {
					heading: 'Registro del operador de red',
					documents: 'Documentos',
					gridOperator: 'Operador de red'
				},
				quotation: {
					heading: 'Presupuesto',
					conditions: 'Condiciones de la oferta'
				},
				complete: {
					success: {
						heading: 'Instalación correcta'
					},
					notes: 'Notas',
					images: 'Imágenes relacionadas con la instalación',
					documents: 'Documentos relacionados con la instalación',
					signature: 'Firma del cliente'
				}
			}
		},
		orderEventTimeline: {
			emptyState: {
				encabezado: 'No se han encontrado eventos',
				message: 'El historial sigue vacío'
			}
		},
		timelineItemDetails: {
			mailAddress: {
				label: 'Correo electrónico'
			},
			section: {
				payment: 'Pagos',
				appointments: 'Citas programadas'
			}
		},
		orderEvent: {
			type: {
				orderReceived: 'Pedido recibido',
				orderAddressValidationSucceeded: 'Validación de dirección correcta',
				orderAddressValidationFailed: 'Validación de dirección fallida',
				orderAddressCorrected: 'Dirección del pedido corregida',
				accountingOrderCreated: 'Pedido contable creado',
				orderConfirmationMailSent: 'Correo de confirmación enviado',
				basicAnswersReceived: 'Respuestas de cuestionario recibidas',
				gridOperatorRegistrationStarted: 'Registro de operador de red iniciado',
				gridOperatorFeedbackReceived: 'Feedback de operador de red recibido',
				gridOperatorRejectionNotificationSent: 'Rechazo de operador de red recibido',
				basicAnswersEvaluated: 'Respuestas del cuestionario evaluadas',
				precheckPreferencesMailSent: 'Correo de solicitudes de contacto precomprobadas enviado',
				remotePrecheckExecuted: 'Prechequeo remoto ejecutado',
				deltaQuotationCreated: 'Cita delta creada',
				deltaQuotationMailSent: 'Correo de cotización delta enviado',
				deltaQuotationAccepted: 'Oferta Delta aceptada',
				paymentSucceeded: 'Pago realizado con éxito',
				paymentFailedOrTimedOut: 'Pago fallido',
				paymentReminderMailSent: 'Recordatorio de pago enviado',
				paymentConfirmationMailSent: 'Correo de confirmación de pago enviado',
				appointmentArranged: 'Cita concertada',
				appointmentArrangedNotificationSent: 'Correo de notificación de cita enviado',
				shippingConfirmed: 'Entrega confirmada',
				installationConfirmed: 'Instalación confirmada',
				replacementInstallationConfirmed: 'Instalación de sustitución confirmada',
				installationApprovalMailSent: 'Correo de confirmación de instalación enviado',
				invoiceCreated: 'Factura creada',
				invoiceNotificationSent: 'Notificación de factura enviada',
				orderCancelled: 'Pedido cancelado',
				gridOperatorRegistrationRevoked: 'Registro de operador de red cancelado',
				customerCallReceived: 'Llamada de cliente recibida',
				returnOrReplacementShipmentReceived: 'Devolución o envío de sustitución recibido',
				returnInitiated: 'Devolución iniciada',
				replacementInitiated: 'Entrega de sustitución iniciada',
				cancellationInitiated: 'Cancelación iniciada',
				orderNoteCreated: 'Nota de pedido creada',
				refundCompleted: 'Reembolso completado',
				installationCancellationMailSent: 'Envío de la notificación de cancelación de la instalación'
			},
			category: {
				accounting: 'Contabilidad',
				customer: 'Cliente',
				customerService: 'Servicio al cliente',
				deltaInstallation: 'Instalación Delta',
				gridOperator: 'Operador de red',
				installation: 'Instalación',
				order: 'Pedido',
				payment: 'Pago',
				preCheck: 'PreCheck',
				shipping: 'Envío'
			}
		},
		marketplaceOrder: {
			orderState: {
				paymentPending: 'Pago Pendiente',
				waitingForFulfillment: 'Esperando cumplimiento',
				fulfillmentStarted: 'Cumplimiento iniciado',
				cancelled: 'Cancelado',
				done: 'Hecho'
			}
		},
		orderCollection: {
			heading: 'Pedidos',
			tableHead: {
				order: 'Pedido',
				status: 'Estado',
				language: 'Idioma',
				plannedDeliveryDate: 'Entrega prevista',
				createdAt: 'Creado en'
			},
			emptyState: {
				heading: 'No se han encontrado pedidos',
				message: 'Ajuste la configuración de sus filtros para encontrar algunos pedidos.'
			},
			action: {
				refresh: 'Actualizar',
				showMore: 'Mostrar más'
			},
			orderState: {
				onSchedule: 'Según lo previsto',
				delayed: 'Retrasado',
				cancelPending: 'Cancelar pendiente',
				cancelled: 'Cancelado',
				imported: 'Importado',
				validationFailed: 'No válido',
				done: 'Hecho'
			}
		},
		customerOrderCollection: {
			emptyState: {
				heading: 'No se han encontrado pedidos',
				message: 'No se han encontrado pedidos para este cliente.'
			}
		},
		orderLineItemCollection: {
			heading: 'Pedido',
			position: 'Posición',
			description: 'Descripción',
			price: 'Precio',
			productCode: 'Código de producto',
			totalPrice: 'Precio total'
		},
		inboundOrderCollection: {
			heading: 'Pedidos asociados con el número de teléfono {{caller}}',
			emptyState: {
				heading: 'No se han encontrado pedidos',
				message: 'Tal vez quiera intentar una búsqueda en su lugar.',
				searchLabel: 'Buscar'
			},
			action: {
				showMore: 'Mostrar más'
			}
		},
		searchbar: {
			placeholder: 'Buscar',
			results: {
				orders: 'Pedidos',
				marketplaceOrders: 'Pedidos al mercado',
				customers: 'Clientes',
				showResultPage: 'Mostrar página de resultados',
				showResultPageDescription: {
					resultsFromTo: 'Resultado 1 a',
					of: 'de'
				}
			}
		},
		searchResultCollection: {
			heading: 'Resultados de búsqueda',
			headingWithSearchterm: 'Resultados de búsqueda de',
			headingCustomers: 'Clientes',
			headingOrders: 'Pedidos',
			headingMarketplaceOrders: 'Pedidos al mercado',
			emptyState: {
				heading: 'No se han encontrado resultados de búsqueda',
				message: 'Ajuste el término de búsqueda y vuelva a intentarlo.'
			},
			idleState: {
				heading: 'No se ha introducido ningún término de búsqueda',
				message: 'Por favor, introduzca un término de búsqueda.'
			},
			action: {
				showMore: 'Mostrar más'
			}
		},
		task: {
			assign: {
				successMessage: 'Tarea asignada'
			},
			error: {
				taskNotFound: 'Tarea no encontrada',
				taskTypeNotFound: 'Tipo de tarea no encontrado'
			},
			gridOperatorRegistration: {
				continueToFeedback: {
					buttonLabel: 'Continuar'
				}
			},
			basicAnswers: {
				continueToQuotation: {
					buttonLabel: 'Continuar'
				},
				button: {
					close: 'Cerrar',
					submit: 'Enviar'
				},
				questionnaire: {
					heading: 'Respuestas básicas'
				}
			},
			completion: {
				successHeading: 'Tarea completada con éxito',
				closeButtonLabel: 'Cerrar'
			},
			preCheck: {
				button: {
					close: 'Cerrar',
					submit: 'Enviar'
				},
				contact: {
					description: 'Contacto'
				}
			},
			gridOperator: {
				button: {
					close: 'Cerrar',
					submit: 'Enviar'
				}
			},
			quotation: {
				button: {
					close: 'Cerrar',
					submit: 'Enviar'
				},
				deltaPackages: {
					totalPurchaseNetPrice: 'Precio total de compra (neto)',
					totalSalesGrossPrice: 'Precio total de venta (incluido {{vatRate}}% IVA)',
					purchaseNetPrice: 'Precio de compra (neto)',
					salesGrossPrice: 'Precio de venta (bruto)'
				}
			},
			payment: {
				button: {
					close: 'Cerrar',
					submit: 'Enviar'
				}
			},
			appointment: {
				button: {
					close: 'Cerrar',
					submit: 'Enviar'
				}
			},
			installation: {
				button: {
					close: 'Cerrar',
					submit: 'Enviar'
				}
			}
		},
		taskCollection: {
			heading: 'Tareas',
			tableHead: {
				task: 'Tarea',
				status: 'Estado',
				language: 'Idioma'
			},
			action: {
				refresh: 'Actualizar',
				showMore: 'Mostrar más'
			},
			assign: {
				successMessage: 'La tarea se asignó con éxito',
				errorMessage: 'Al asignar la tarea se ha producido un error.'
			},
			emptyState: {
				heading: 'No se han encontrado tareas',
				message: 'Ajuste la configuración del filtro para encontrar algunas tareas.'
			},
			orderEmptyState: {
				heading: 'No se han encontrado tareas',
				message: 'No es necesario hacer nada para este pedido.'
			}
		},
		taskCollectionItem: {
			type: {
				customerAnswerBasicQuestionsEscalation: 'Escalado: Pregunta básica',
				customerProvidePreCheckPreferencesEscalation: 'Escalado: Comprobación previa',
				customerHandleUnfeasibleInstallationEscalation: 'Escalado: Instalación inviable',
				customerHandleGridOperatorRejectionEscalation: 'Escalado: Rechazo del operador de red',
				customerAcceptQuotationEscalation: 'Escalado: Presupuesto',
				customerPerformPaymentEscalation: 'Escalado: Pago',
				customerApproveInstallationEscalation: 'Escalado: Aprobar instalación',
				customerAddressValidationEscalation: 'Validación de dirección',
				customerUncoveredInstallationAddress: 'Dirección de instalación descubierta',
				installationPartnerEvaluateBasicAnswersEscalation: 'Escalado: Respuestas básicas',
				installationPartnerExecuteRemotePreCheckEscalation: 'Escalado: Ejecutar comprobación previa remota',
				installationPartnerRegisterAtGridOperatorEscalation: 'Escalado: Registrarse en el operador de red',
				installationPartnerProvideGridOperatorDecisionEscalation: 'Escalado: Proporcionar operador de red',
				installationPartnerProvideQuotationEscalation: 'Escalado: Proporcionar presupuesto',
				installationPartnerArrangeAppointmentEscalation: 'Escalado: Concertar citas',
				installationPartnerCompleteInstallationEscalation: 'Escalado: Instalación completa'
			},
			status: {
				started: 'Próximamente',
				dueToday: 'Vence hoy',
				overdue: 'Atrasado',
				escalated: 'Escalado',
				done: 'Hecho',
				doneOverdue: 'Hecho',
				doneEscalated: 'Hecho'
			},
			category: {
				customer: 'Cliente',
				installationPartner: 'Instalar. Socio'
			}
		},
		pageTitle: {
			tasks: 'Información general de tareas',
			orders: 'Información general del pedido',
			order: 'Detalles del pedido',
			marketplaceOrders: 'Vista general de pedidos de marketplace',
			marketplaceOrder: 'Detalle del pedido del mercado',
			customers: 'Información general del cliente',
			error: 'Error',
			notFound: 'No encontrado',
			inbound: 'Llamada entrante {{caller}}'
		},
		viewHeader: {
			tasks: 'Información general de tareas',
			orders: 'Información general del pedido',
			marketplaceOrders: 'Vista general de pedidos de marketplace',
			customers: 'Información general del cliente',
			inbound: 'Llamada entrante de {{caller}}'
		},
		modal: {
			task: {
				section: {
					customerInformation: {
						heading: 'Cliente',
						position: 'Posición',
						description: 'Descripción',
						name: 'Nombre',
						address: 'Dirección de entrega',
						phone: 'Número de teléfono',
						mail: 'Correo electrónico'
					},
					installationEffortInformation: {
						heading: 'Esfuerzo de instalación',
						trade: 'Operación',
						effort: 'Esfuerzo',
						hours: 'horas'
					},
					installationPartnerInformation: {
						heading: 'Socio instalador',
						position: 'Posición',
						description: 'Descripción',
						name: 'Nombre',
						mail: 'Correo electrónico',
						phone: 'Phone'
					},
					orderInformation: {
						heading: 'Información del pedido'
					},
					quotationInformation: {
						heading: 'Presupuesto',
						position: 'Posición',
						description: 'Descripción',
						price: 'Precio',
						deltaInstallation: 'Decisión: Instalación Delta',
						grossPrice: 'Cantidad',
						vat: 'IVA',
						include: 'incluir'
					},
					taskInformation: {
						heading: 'Tarea',
						position: 'Posición',
						description: 'Descripción',
						createdAt: 'Creado en',
						dueDate: 'Fecha límite',
						responsible: 'Responsable',
						openTask: 'Tarea pendiente'
					},
					contactInformation: {
						heading: 'Preferencias de contacto'
					},
					gridOperatorInformation: {
						heading: 'Información sobre el operador de red'
					},
					payment: {
						accept: 'Presupuesto aceptado'
					},
					addressValidation: {
						heading: 'Validación de dirección',
						invalidAddressLabel: {
							delivery: 'Dirección de entrega no válida',
							billing: 'Dirección de facturación no válida',
							multiple: 'Dirección de entrega y facturación no válida'
						},
						hint: {
							heading: 'Insertar una nueva dirección para la validación',
							message: 'La nueva dirección se volverá a validar cuando se envíe la tarea.'
						},
						error: {
							heading: 'Ha fallado la validación de la dirección',
							message: 'Introduzca la información de la dirección correcta.'
						},
						newAddress: {
							heading: 'Nueva dirección',
							street: 'Calle y número ',
							zip: 'Código postal',
							city: 'Ciudad',
							country: 'País',
							region: 'Región'
						}
					},
					uncoveredInstallationAddress: {
						message: 'El cliente fue informado de la anulación del pedido',
						info: 'Dirección de instalación descubierta',
						infoHeading: 'Informar al cliente',
						infoDescription: 'El cliente debe ser informado de que la dirección de instalación no está cubierta y de que la instalación ha sido cancelada.'
					},
					hint: {
						heading: 'Comprobación final',
						unfeasible: 'Decisión: Instalación no viable',
						rejected: 'Rechazado por el operador de red'
					}
				}
			}
		}
	}
};

export default literals;
