/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	ArrayOfTimestampFieldNames,
	Dto,
	ModelConverter,
	ModelConverterInterface,
	ModelPrimaryKey,
	Timestamp,
	TimestampFieldNames
} from '@abb-emobility/shared/domain-model-foundation';
import { AppError } from '@abb-emobility/shared/error';

import { CustomerServiceSearchResultType } from './AnyCustomerServiceSearchResultModel.enums';
import { CustomerServiceCustomerModel, CustomerServiceCustomerModelConverter } from './CustomerServiceCustomerModel';
import {
	CustomerServiceMarketplaceOrderCollectionItemModel,
	CustomerServiceMarketplaceOrderCollectionItemModelConverter
} from './CustomerServiceMarketplaceOrderCollectionItemModel';
import {
	CustomerServiceOrderCollectionItemModel,
	CustomerServiceOrderCollectionItemModelConverter
} from './CustomerServiceOrderCollectionItemModel';

type CustomerServiceSearchResultFoundationModel = {
	readonly id: ModelPrimaryKey,
	readonly type: CustomerServiceSearchResultType
	readonly createdAt?: Timestamp
};

export type CustomerServiceCustomerSearchResultModel = CustomerServiceSearchResultFoundationModel & {
	readonly type: CustomerServiceSearchResultType.CUSTOMER,
	readonly payload: CustomerServiceCustomerModel
};

export type CustomerServiceOrderSearchResultModel = CustomerServiceSearchResultFoundationModel & {
	readonly type: CustomerServiceSearchResultType.ORDER,
	readonly payload: CustomerServiceOrderCollectionItemModel
};

export type CustomerServiceMarketplaceOrderSearchResultModel = CustomerServiceSearchResultFoundationModel & {
	readonly type: CustomerServiceSearchResultType.MARKETPLACE_ORDER,
	readonly payload: CustomerServiceMarketplaceOrderCollectionItemModel
};

export type AnyCustomerServiceSearchResultModel =
	CustomerServiceCustomerSearchResultModel |
	CustomerServiceOrderSearchResultModel |
	CustomerServiceMarketplaceOrderSearchResultModel;

export class AnyCustomerServiceSearchResultModelConverter extends ModelConverter<AnyCustomerServiceSearchResultModel> {

	override getTimestampFields(): Array<TimestampFieldNames<AnyCustomerServiceSearchResultModel> | ArrayOfTimestampFieldNames<AnyCustomerServiceSearchResultModel>> {
		return ['createdAt'];
	}

	override getFieldConverterMapByModel(
		model: Dto<AnyCustomerServiceSearchResultModel> | AnyCustomerServiceSearchResultModel
	): Map<keyof AnyCustomerServiceSearchResultModel, ModelConverterInterface<any>> {
		switch (model.type) {
			case CustomerServiceSearchResultType.CUSTOMER:
				return new Map<keyof AnyCustomerServiceSearchResultModel, ModelConverterInterface<CustomerServiceCustomerModel>>([
					['payload', new CustomerServiceCustomerModelConverter()]
				]);
			case CustomerServiceSearchResultType.ORDER:
				return new Map<keyof AnyCustomerServiceSearchResultModel, ModelConverterInterface<CustomerServiceOrderCollectionItemModel>>([
					['payload', new CustomerServiceOrderCollectionItemModelConverter()]
				]);
			case CustomerServiceSearchResultType.MARKETPLACE_ORDER:
				return new Map<keyof AnyCustomerServiceSearchResultModel, ModelConverterInterface<CustomerServiceMarketplaceOrderCollectionItemModel>>([
					['payload', new CustomerServiceMarketplaceOrderCollectionItemModelConverter()]
				]);
			default:
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				throw new AppError('Search result type ' + model.type + ' invalid');
		}
	}
}
