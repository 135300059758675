import React, { ReactNode } from 'react';

import {
	CustomerServiceOrderEventCollectionDataInterceptEmpty,
	CustomerServiceOrderEventCollectionDataSuspense,
	useCustomerServiceOrderEventCollectionData
} from '@abb-emobility/platform/data-provider';
import { CustomerServiceOrderEventCollectionModel } from '@abb-emobility/platform/domain-model';
import { createDateFromTimestamp } from '@abb-emobility/shared/domain-model-foundation';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { NotificationBig, NotificationBigStatus, SpinnerCircle } from '@abb-emobility/shared/ui-primitive';

import { groupByMonth, preprocessEvents } from './OrderEventTimeline.util';
import { Timeline } from '../timeline/Timeline';
import { TimelineItem } from '../timeline-item/TimelineItem';
import { TimelineSection } from '../timeline-section/TimelineSection';

export function OrderEventTimeline() {

	const l10n = useL10n();
	const eventData = useCustomerServiceOrderEventCollectionData();
	const events = eventData.query();

	const eventsGrouped = groupByMonth(preprocessEvents(events));

	const renderTimelineSections = () => {
		return eventsGrouped.map((eventGroup, index) => {
				return (
					<TimelineSection
						label={l10n.formatMonthname(createDateFromTimestamp(eventGroup.month), 'month')}
						key={index}
					>
						{renderTimelineItems(eventGroup.events)}
					</TimelineSection>
				);
			}
		);
	};

	const renderTimelineItems = (events: Array<CustomerServiceOrderEventCollectionModel>): ReactNode => {
		return events.map((event) => {
			return (<TimelineItem key={event.id} event={event} />);
		});
	};

	const renderEmptyState = (): ReactNode => {
		return (
			<NotificationBig
				status={NotificationBigStatus.EMPTY2}
				heading={l10n.translate('platformCustomerServiceApp.orderEventTimeline.emptyState.heading')}
				message={l10n.translate('platformCustomerServiceApp.orderEventTimeline.emptyState.message')}
			/>
		);
	};

	return (
		<Timeline>
			<CustomerServiceOrderEventCollectionDataSuspense pendingComponent={SpinnerCircle}>
				<CustomerServiceOrderEventCollectionDataInterceptEmpty emptyStateComponent={renderEmptyState}>
					{renderTimelineSections()}
				</CustomerServiceOrderEventCollectionDataInterceptEmpty>
			</CustomerServiceOrderEventCollectionDataSuspense>
		</Timeline>
	);

}
