import React from 'react';

import { useCustomerServiceOrderInformationEntityData } from '@abb-emobility/platform/data-provider';
import { CustomerServiceOrderInformationType } from '@abb-emobility/platform/domain-model';
import { AppError } from '@abb-emobility/shared/error';

import { OrderInformationApproval } from '../order-information/OrderInformationApproval';
import { OrderInformationBasicAnswers } from '../order-information/OrderInformationBasicAnswers';
import { OrderInformationGridOperatorDecision } from '../order-information/OrderInformationGridOperatorDecision';
import { OrderInformationGridOperatorRegistration } from '../order-information/OrderInformationGridOperatorRegistration';
import { OrderInformationPrecheck } from '../order-information/OrderInformationPrecheck';
import { OrderInformationQuotation } from '../order-information/OrderInformationQuotation';

export function OrderDetailInstallationInformationFactory() {
	const orderInformationData = useCustomerServiceOrderInformationEntityData();
	const orderInformation = orderInformationData.query().getOrThrow(new AppError('Order information not found'));

	switch (orderInformation.type) {
		case CustomerServiceOrderInformationType.BASIC_ANSWERS:
			return (<OrderInformationBasicAnswers orderInformation={orderInformation} />);
		case CustomerServiceOrderInformationType.GRID_OPERATOR_REGISTRATION:
			return (<OrderInformationGridOperatorRegistration orderInformation={orderInformation} />);
		case CustomerServiceOrderInformationType.GRID_OPERATOR_DECISION:
			return (<OrderInformationGridOperatorDecision orderInformation={orderInformation} />);
		case CustomerServiceOrderInformationType.PRE_CHECK:
			return (<OrderInformationPrecheck orderInformation={orderInformation} />);
		case CustomerServiceOrderInformationType.OFFER:
			return (<OrderInformationQuotation orderInformation={orderInformation} />);
		case CustomerServiceOrderInformationType.APPROVAL:
			return (<OrderInformationApproval orderInformation={orderInformation} />);
	}
}
