import {
	ArrayOfTimestampFieldNames, CustomConversion,
	ModelConverter,
	ModelConverterInterface,
	TimestampFieldNames
} from '@abb-emobility/shared/domain-model-foundation';
import { IsoLanguageCode } from '@abb-emobility/shared/iso-language-code';

import { CustomerServiceOrderCustomerModel, CustomerServiceOrderCustomerModelConverter } from './CustomerServiceOrderCustomerModel';
import { CustomerServiceOrderFoundationModel } from './foundation/CustomerServiceOrderFoundationModel';

export type CustomerServiceOrderCollectionItemModel = CustomerServiceOrderFoundationModel & {
	readonly customer: CustomerServiceOrderCustomerModel,
};

export class CustomerServiceOrderCollectionItemModelConverter extends ModelConverter<CustomerServiceOrderCollectionItemModel> {

	override getCustomConversionFields(): Map<keyof CustomerServiceOrderFoundationModel, CustomConversion> {
		return new Map([[
			'languageCode', {
				toModel: (dtoValue: string) => {
					return dtoValue?.toLowerCase() as IsoLanguageCode;
				},
				toDto: (modelValue) => {
					return modelValue;
				}
			}
		]]);
	}

	override getTimestampFields(): Array<TimestampFieldNames<CustomerServiceOrderCollectionItemModel> | ArrayOfTimestampFieldNames<CustomerServiceOrderCollectionItemModel>> {
		return ['estimatedCompletionDate', 'orderDate'];
	}

	override getFieldConverterMapByModel() {
		return new Map<keyof CustomerServiceOrderCollectionItemModel, ModelConverterInterface<CustomerServiceOrderCustomerModel>>([
			['customer', new CustomerServiceOrderCustomerModelConverter()]
		]);
	}
}
