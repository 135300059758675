import { ReactNode, useState } from 'react';

import { Nullable } from '@abb-emobility/shared/util';

import { searchbarContentContext } from './SearchbarContent.context';

export type TopbarProps = {
	children: ReactNode
};

export function SearchbarContent(props: TopbarProps) {

	const { children } = props;
	const [currentOrderId, setCurrentOrderId] = useState<Nullable<string>>(null);
	const [currentCustomerId, setCurrentCustomerId] = useState<Nullable<string>>(null);
	const [dropdownAvailable, setDroptdownAvailable] = useState(true);

	const providerValue = {
		getOrderId: (): Nullable<string> => {
			return currentOrderId;
		},
		setOrderId: (orderId: Nullable<string>) => {
			setCurrentOrderId(orderId);
		},
		getCustomerId: (): Nullable<string> => {
			return currentCustomerId;
		},
		setCustomerId: (orderId: Nullable<string>) => {
			setCurrentCustomerId(orderId);
		},
		getDropdownAvailable: (): boolean => {
			return dropdownAvailable;
		},
		setDropdownAvailable: (dropdownAvailable: boolean) => {
			setDroptdownAvailable(dropdownAvailable);
		}
	};

	return (
		<searchbarContentContext.Provider value={providerValue}>
			{children}
		</searchbarContentContext.Provider>
	);
}
