import React from 'react';
import { useNavigate } from 'react-router-dom';

import { createFullNameFromCustomer, CustomerServiceCustomerModel } from '@abb-emobility/platform/domain-model';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	ButtonIcon, CollectionItemDate,
	CollectionItemHeader, CollectionItemHeaderItem, CollectionItemLabel,
	Icon,
	IconIdentifier,
	Separator
} from '@abb-emobility/shared/ui-primitive';

import { createRouteUrl, RoutePath } from '../../router/Routes';

type CustomerCollectionItemProps = {
	customer: CustomerServiceCustomerModel,
	disabled?: boolean,
	onNavigate?: () => void
};

export function CustomerCollectionItem(props: CustomerCollectionItemProps) {

	const { customer, disabled = false, onNavigate } = props;

	const l10n = useL10n();
	const navigate = useNavigate();

	const handleNavigate = () => {
		if (onNavigate) {
			onNavigate();
		}
		navigate(createRouteUrl(RoutePath.CUSTOMER, ['customerId', customer.id]));
	};

	return (
		<>
			<CollectionItemHeader disabled={disabled}>
				<CollectionItemHeaderItem>
					<CollectionItemLabel
						label={createFullNameFromCustomer(customer)}
						disabled={false}
						additionalAttribute={customer.mailAddress}
						additionalIcon={IconIdentifier.ENVELOPE}
					>
					</CollectionItemLabel>
				</CollectionItemHeaderItem>

				<CollectionItemHeaderItem>
					<CollectionItemDate date={l10n.formatTimestampDate(customer.firstOrder)} />
				</CollectionItemHeaderItem>

				<CollectionItemHeaderItem>
					<CollectionItemDate date={l10n.formatTimestampDate(customer.lastOrder)} />
				</CollectionItemHeaderItem>

				<CollectionItemHeaderItem>
					<ButtonIcon onClick={handleNavigate} disabled={disabled}>
						<Icon name={IconIdentifier.ARROW_RIGHT} />
					</ButtonIcon>
				</CollectionItemHeaderItem>

			</CollectionItemHeader>
			<Separator />
		</>
	);
}
