import { CurrencyCode } from '@abb-emobility/shared/currency';
import { AddressModel, AddressModelConverter, InstallationCancelReason } from '@abb-emobility/shared/domain-model';
import {
	ArrayOfTimestampFieldNames, CustomConversion,
	ModelConverter,
	ModelConverterInterface, Timestamp,
	TimestampFieldNames
} from '@abb-emobility/shared/domain-model-foundation';
import { IsoLanguageCode } from '@abb-emobility/shared/iso-language-code';

import { CustomerServiceCustomerModel, CustomerServiceCustomerModelConverter } from './CustomerServiceCustomerModel';
import { CustomerServiceOrderCustomerModel, CustomerServiceOrderCustomerModelConverter } from './CustomerServiceOrderCustomerModel';
import { CustomerServiceOrderLineItemModel } from './CustomerServiceOrderLineItemModel';
import { CustomerServiceOrderFoundationModel } from './foundation/CustomerServiceOrderFoundationModel';

export type CustomerServiceOrderModel = CustomerServiceOrderFoundationModel & {
	readonly customer: CustomerServiceOrderCustomerModel,
	readonly deliveryAddress: AddressModel,
	readonly orderLineItems: Array<CustomerServiceOrderLineItemModel>,
	readonly currencyCode: CurrencyCode,
	readonly phoneNumber: string,
	readonly cancellationDate?: Timestamp
	readonly cancellationReason?: InstallationCancelReason
};

export class CustomerServiceOrderModelConverter extends ModelConverter<CustomerServiceOrderModel> {
	override getTimestampFields(): Array<TimestampFieldNames<CustomerServiceOrderModel> | ArrayOfTimestampFieldNames<CustomerServiceOrderModel>> {
		return ['estimatedCompletionDate', 'orderDate', 'cancellationDate'];
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	override getFieldConverterMapByModel() {
		return new Map<
			keyof CustomerServiceOrderModel,
			ModelConverterInterface<CustomerServiceOrderCustomerModel> | ModelConverterInterface<AddressModel> | ModelConverterInterface<CustomerServiceCustomerModel>
		>([
			['customer', new CustomerServiceOrderCustomerModelConverter()],
			['deliveryAddress', new AddressModelConverter()],
			['orderLineItems', new CustomerServiceCustomerModelConverter()]
		]);
	}

	override getCustomConversionFields(): Map<keyof CustomerServiceOrderModel, CustomConversion> {
		return new Map([[
			'languageCode', {
				toModel: (dtoValue: string) => {
					return dtoValue?.toLowerCase() as IsoLanguageCode;
				},
				toDto: (modelValue) => {
					return modelValue;
				}
			}
		]]);
	}
}
