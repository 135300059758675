import React from 'react';

import {
	createFilterCriteriaFromSearchString,
	CustomerServiceSearchDataProvider,
	defaultCustomerServiceSearchDataProviderSortCriteria
} from '@abb-emobility/platform/data-provider';
import { useSearchParams } from '@abb-emobility/shared/browser';
import { NotFoundError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { usePageTitle } from '@abb-emobility/shared/react';
import { UiErrorHandler } from '@abb-emobility/shared/ui-error-handler';
import { useBreadcrumbModifier, SpinnerCircle, ViewHeader } from '@abb-emobility/shared/ui-primitive';
import { removeNonNumericChars, trimFromLeft } from '@abb-emobility/shared/util';

import { InboundOrderCollection } from '../components/order-collection/InboundOrderCollection';
import { useBuildPageTitle } from '../components/page-title/PageTitle.hook';
import { useSearchbarContentModifier } from '../components/searchbar-content/SearchbarContent.hooks';
import { createRouteUrl, RoutePath } from '../router/Routes';

export function InboundView() {

	const l10n = useL10n();
	const searchParams = useSearchParams();

	let caller = searchParams.get('caller');
	if (caller === null) {
		throw new NotFoundError(l10n.translate('platformCustomerServiceApp.error.callerNotFound'));
	}
	caller = removeNonNumericChars(caller);
	caller = trimFromLeft(caller, '0');
	if (caller.length === 0) {
		throw new NotFoundError(l10n.translate('platformCustomerServiceApp.error.callerNotFound'));
	}

	const searchFilterCriteria = createFilterCriteriaFromSearchString(caller);

	const viewHeader = l10n.translate('platformCustomerServiceApp.viewHeader.inbound', new Map([['caller', caller]]));
	const pageTitle = l10n.translate('platformCustomerServiceApp.pageTitle.inbound', new Map([['caller', caller]]));

	usePageTitle(useBuildPageTitle(pageTitle));
	useBreadcrumbModifier([
		{
			label: l10n.translate('platformCustomerServiceApp.breadcrumbs.orders'),
			link: createRouteUrl(RoutePath.CUSTOMERS)
		}
	]);
	useSearchbarContentModifier(null, null, true);

	return (
		<>
			<ViewHeader heading={viewHeader} />
			<UiErrorHandler>
				<CustomerServiceSearchDataProvider
					pendingComponent={SpinnerCircle}
					suspense={false}
					filterCriteria={searchFilterCriteria}
					sortCriteria={defaultCustomerServiceSearchDataProviderSortCriteria}
				>
					<InboundOrderCollection caller={caller} />
				</CustomerServiceSearchDataProvider>
			</UiErrorHandler>
		</>
	);
}
