import {
	FilterCriteria,
	FilterCriteriaEntry,
	FilterCriteriaRuleValue,
	MultiValueFilterComparator,
	SingleValueFilterComparator,
	ValueFilterCriteriaRule
} from '@abb-emobility/shared/api-integration-foundation';
import { createTimestampDtoFromDate } from '@abb-emobility/shared/domain-model-foundation';
import { FilterOptionGroup } from '@abb-emobility/shared/ui-primitive';
import { getCalendarDay, getToday, Nullable } from '@abb-emobility/shared/util';
import { AnyTaskCollectionItemModel } from '@abb-emobility/usertask/domain-model';


export enum TaskFilterOption {
	DUE_DATE_OVERDUE = 'DUE_DATE_OVERDUE',
	DUE_DATE_TODAY = 'DUE_DATE_TODAY',
	PRIORITY_HIGH = 'PRIORITY_HIGH',
	PRIORITY_MEDIUM = 'PRIORITY_MEDIUM',
	PRIORITY_LOW = 'PRIORITY_LOW'
}

const dueDateFilterOptions = [
	TaskFilterOption.DUE_DATE_OVERDUE,
	TaskFilterOption.DUE_DATE_TODAY
];

const priorityFilterOptions = [
	TaskFilterOption.PRIORITY_HIGH,
	TaskFilterOption.PRIORITY_MEDIUM,
	TaskFilterOption.PRIORITY_LOW
];

export const taskFilterGroups: Array<FilterOptionGroup<TaskFilterOption, AnyTaskCollectionItemModel>> = [
	{
		property: 'dueDate',
		options: dueDateFilterOptions,
		buildFilterCriteria: (selectedOptions: Array<FilterCriteriaRuleValue>): Nullable<FilterCriteriaEntry<AnyTaskCollectionItemModel>> => {
			selectedOptions = selectedOptions.filter((selectedOption) => {
				return dueDateFilterOptions.includes(selectedOption as TaskFilterOption);
			});
			if (selectedOptions.length === 0) {
				return null;
			}
			let criteriaId = '';
			let criteria: Array<ValueFilterCriteriaRule<AnyTaskCollectionItemModel>> = [];
			if (selectedOptions.length === 1) {
				const selectedOption = selectedOptions[0];
				if (selectedOption === TaskFilterOption.DUE_DATE_OVERDUE) {
					criteriaId = TaskFilterOption.DUE_DATE_OVERDUE;
					criteria = [
						{
							property: 'dueDate',
							comparator: SingleValueFilterComparator.LESS_THAN,
							value: createTimestampDtoFromDate(getToday())
						}
					];
				}
				if (selectedOption === TaskFilterOption.DUE_DATE_TODAY) {
					criteriaId = TaskFilterOption.DUE_DATE_TODAY;
					criteria = [
						{
							property: 'dueDate',
							comparator: SingleValueFilterComparator.GREATER_THAN,
							value: createTimestampDtoFromDate(getToday())
						},
						{
							property: 'dueDate',
							comparator: SingleValueFilterComparator.LESS_THAN,
							value: createTimestampDtoFromDate(getCalendarDay(1))
						}
					];
				}
			} else {
				criteriaId = TaskFilterOption.DUE_DATE_TODAY + ':' + TaskFilterOption.DUE_DATE_OVERDUE;
				criteria = [
					{
						property: 'dueDate',
						comparator: SingleValueFilterComparator.LESS_THAN,
						value: createTimestampDtoFromDate(getCalendarDay(1))
					}
				];
			}
			return {
				id: criteriaId,
				criteria
			};
		},
		buildFilterOptions: (filterCriteria: FilterCriteria<AnyTaskCollectionItemModel>): Array<TaskFilterOption> => {
			let filterOptions: Array<TaskFilterOption> = [];
			for (const filterCriterion of filterCriteria) {
				filterOptions = [...filterOptions, ...(filterCriterion.id.split(':') as Array<TaskFilterOption>)];
			}
			return filterOptions.filter((taskFilterOption) => {
				return dueDateFilterOptions.includes(taskFilterOption);
			});
		}
	},
	{
		property: 'priority',
		options: priorityFilterOptions,
		buildFilterCriteria: (selectedOptions: Array<FilterCriteriaRuleValue>): Nullable<FilterCriteriaEntry<AnyTaskCollectionItemModel>> => {
			selectedOptions = selectedOptions.filter((selectedOption) => {
				return priorityFilterOptions.includes(selectedOption as TaskFilterOption);
			});
			if (selectedOptions.length === 0) {
				return null;
			}
			let criteriaId: string;
			let criteria: Array<ValueFilterCriteriaRule<AnyTaskCollectionItemModel>>;
			if (selectedOptions.length === 1) {
				const selectedOption = selectedOptions[0];
				criteriaId = selectedOption as string;
				criteria = [
					{
						property: 'priority',
						comparator: SingleValueFilterComparator.EQUAL,
						value: selectedOption
					}
				];
			} else {
				criteriaId = selectedOptions.join(':');
				criteria = [
					{
						property: 'priority',
						comparator: MultiValueFilterComparator.ONE_OF,
						value: selectedOptions
					}
				];
			}
			return {
				id: criteriaId,
				criteria
			};
		},
		buildFilterOptions: (filterCriteria: FilterCriteria<AnyTaskCollectionItemModel>): Array<TaskFilterOption> => {
			let filterOptions: Array<TaskFilterOption> = [];
			for (const filterCriterion of filterCriteria) {
				filterOptions = [...filterOptions, ...(filterCriterion.id.split(':') as Array<TaskFilterOption>)];
			}
			return filterOptions.filter((taskFilterOption) => {
				return priorityFilterOptions.includes(taskFilterOption);
			});
		}
	}
];
