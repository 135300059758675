import { CurrencyCode } from '@abb-emobility/shared/currency';
import { numericApiSafeguard } from '@abb-emobility/shared/domain-model';
import { CustomConversion, ModelConverter } from '@abb-emobility/shared/domain-model-foundation';

export type PricingModel = {
	amount: number,
	currency: CurrencyCode
};

export class PricingModelConverter extends ModelConverter<PricingModel> {

	override getCustomConversionFields(): Map<keyof PricingModel, CustomConversion> {
		return new Map([
			['amount', numericApiSafeguard]
		]);
	}
}
