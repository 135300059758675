export enum RoutePath {
	ROOT = '/',
	TASKS = '/tasks/',
	TASK = '/task/:taskId',
	ORDERS = '/orders/',
	ORDER = '/order/:orderId',
	ORDER_TASK = '/order/:orderId/task/:taskId',
	MARKETPLACE_ORDERS = '/marketplace-orders/',
	MARKETPLACE_ORDER = '/marketplace-order/:orderId',
	CUSTOMERS = '/customers/',
	CUSTOMER = '/customer/:customerId',
	INBOUND = '/inbound/',
	SEARCH_RESULTS = '/search-results/'
}

export type RouteParameter = [string, string];

export const createRouteUrl = (routePath: RoutePath, ...parameters: Array<RouteParameter>): string => {
	let url = String(routePath);
	for (const parameter of parameters) {
		url = url.replace(':' + parameter[0], parameter[1]);
	}
	return url;
};
