import { numericApiSafeguard } from '@abb-emobility/shared/domain-model';
import {
	ArrayOfTimestampFieldNames,
	CustomConversion,
	ModelConverter,
	Timestamp,
	TimestampFieldNames
} from '@abb-emobility/shared/domain-model-foundation';

import { CustomerServiceCustomerFoundationModel } from './foundation/CustomerServiceCustomerFoundationModel';

export type CustomerServiceCustomerModel = CustomerServiceCustomerFoundationModel & {
	readonly firstOrder: Timestamp,
	readonly lastOrder: Timestamp,
	readonly runningOrderCount: number
};

export class CustomerServiceCustomerModelConverter extends ModelConverter<CustomerServiceCustomerModel> {

	override getTimestampFields(): Array<TimestampFieldNames<CustomerServiceCustomerModel> | ArrayOfTimestampFieldNames<CustomerServiceCustomerModel>> {
		return ['firstOrder', 'lastOrder'];
	}

	override getCustomConversionFields(): Map<keyof CustomerServiceCustomerModel, CustomConversion> {
		return new Map([
			['runningOrderCount', numericApiSafeguard]
		]);
	}

}
