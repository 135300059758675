import React, { useEffect, useState } from 'react';

import {
	CustomerServiceAddCustomerCallNoteInvocationPayloadModelConverter,
	CustomerServiceCommentType,
	CustomerServiceCustomerCallReason
} from '@abb-emobility/platform/domain-model';
import { CommandStatus, useCommandData } from '@abb-emobility/shared/command-api';
import { VoidModelConverter } from '@abb-emobility/shared/domain-model-foundation';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	ActionModalContent,
	ActionModalFooter,
	ButtonGhost,
	ButtonPrimary,
	InputSelect,
	InputSelectOption,
	InputTextArea,
	NotificationBig,
	NotificationBigStatus,
	SubmissionStatus,
	SuccessFeeback,
	SuccessImageIdentifier,
	useModalDialogue,
	useModalDialogueManager
} from '@abb-emobility/shared/ui-primitive';
import { l10nLiteralFromEnumValue, Nullable } from '@abb-emobility/shared/util';

import { HypermediaAction } from '../../../ActionModalFactory.types';

import './AddCustomerCallNoteActionModal.scss';

export type AddCustomerCallNoteActionModalProps = {
	action: HypermediaAction,
};

export function AddCustomerCallNoteActionModal(props: AddCustomerCallNoteActionModalProps) {

	const { action } = props;
	const l10n = useL10n();
	const modalDialogue = useModalDialogue();
	const dialogueManager = useModalDialogueManager();

	const commandData = useCommandData(
		action.hypermediaLink,
		new VoidModelConverter(),
		new CustomerServiceAddCustomerCallNoteInvocationPayloadModelConverter()
	);

	const invokeCommandStatus = commandData.queryInvocationStatus();
	const invokeCommandError = commandData.queryInvocationError();

	const [contactReason, setContactReason] = useState<Nullable<CustomerServiceCustomerCallReason>>(null);
	const [contactReasonOther, setContactReasonOther] = useState<Nullable<string>>(null);
	const [comment, setComment] = useState<Nullable<string>>(null);

	const contactReasonOptions: Array<InputSelectOption> = Object.values(CustomerServiceCustomerCallReason)
		.map((value) => (
				{
					label: l10n.translate(l10nLiteralFromEnumValue(value, 'platformCustomerServiceApp.actionModal.addCustomerCallNote.content.callReasonOptions')),
					value: value
				}
			)
		);

	useEffect(() => {
		const contactReasonIsReady = (contactReason !== null) &&
			(contactReason !== CustomerServiceCustomerCallReason.OTHER) ||
			((contactReason === CustomerServiceCustomerCallReason.OTHER) && (contactReasonOther?.length ?? 0) > 0);
		const formIsReady = contactReasonIsReady && (comment?.length ?? 0) > 0;

		const handleSubmitAction = () => {
			if (comment !== null && contactReason !== null) {
				commandData.invoke({
					commentType: CustomerServiceCommentType.CUSTOMER_CALL,
					contactReason: contactReason,
					contactReasonOther: contactReasonOther ?? undefined,
					comment: comment
				});
			}

		};

		switch (invokeCommandStatus) {
			case CommandStatus.IDLE:
				modalDialogue.setFooter(
					<ActionModalFooter>
						<ButtonGhost
							label={l10n.translate('platformCustomerServiceApp.actionModal.addCustomerCallNote.footer.cancel')}
							onClick={handleDismissDialogue}
						/>
						<ButtonPrimary
							label={l10n.translate('platformCustomerServiceApp.actionModal.addCustomerCallNote.footer.confirm')}
							submissionStatus={SubmissionStatus.IDLE}
							onClick={handleSubmitAction}
							disabled={!formIsReady}
						/>
					</ActionModalFooter>
				);
				break;
			case CommandStatus.PENDING:
				modalDialogue.setFooter(
					<ActionModalFooter>
						<ButtonGhost
							label={l10n.translate('platformCustomerServiceApp.actionModal.addCustomerCallNote.footer.cancel')}
							onClick={handleDismissDialogue}
						/>
						<ButtonPrimary
							label={l10n.translate('platformCustomerServiceApp.actionModal.addCustomerCallNote.footer.confirm')}
							submissionStatus={SubmissionStatus.PENDING}
							onClick={handleSubmitAction}
						/>
					</ActionModalFooter>
				);
				break;
			case CommandStatus.SUCCESSFULL:
				modalDialogue.setContent(
					<ActionModalContent>
						<SuccessFeeback
							heading={l10n.translate('platformCustomerServiceApp.actionModal.addCustomerCallNote.content.successHeading')}
							image={SuccessImageIdentifier.IMAGE4}
						>
							<ButtonGhost
								label={l10n.translate('platformCustomerServiceApp.actionModal.addCustomerCallNote.content.closeButtonLabel')}
								onClick={handleDismissDialogue}
							/>
						</SuccessFeeback>
					</ActionModalContent>
				);
				modalDialogue.unsetFooter();
				break;
			case CommandStatus.FAILED:
				modalDialogue.setContent(
					<ActionModalContent>
						<NotificationBig
							status={NotificationBigStatus.ERROR}
							heading={l10n.translate('platformCustomerServiceApp.actionModal.addCustomerCallNote.error.heading')}
							message={l10n.translate('platformCustomerServiceApp.actionModal.addCustomerCallNote.error.message')}
							subline={invokeCommandError?.message}
						/>
					</ActionModalContent>
				);
				modalDialogue.unsetFooter();
				break;
		}

	}, [invokeCommandStatus, contactReason, contactReasonOther, comment]);

	const handleDismissDialogue = () => {
		dialogueManager.pop();
	};

	const handleContactReasonSelect = (value: string) => {
		setContactReason(value as CustomerServiceCustomerCallReason);
	};
	const handleOtherReasonChange = (value: string): void => {
		setContactReasonOther(value);
	};

	const handleInputNoteChange = (value: string): void => {
		setComment(value);
	};

	const renderOtherReasonInputTextArea = () => {
		if (contactReason !== CustomerServiceCustomerCallReason.OTHER) {
			return null;
		}
		return (
			<div className="add-customer-call-note-action-modal__call-section-text-input">
				<InputTextArea
					placeholder={l10n.translate('platformCustomerServiceApp.actionModal.addCustomerCallNote.content.callReasonNotePlaceholder')}
					rows={5}
					onChange={handleOtherReasonChange}
					value={contactReasonOther ?? ''}
				/>
			</div>
		);
	};

	return (
		<>
			<section>
				<InputSelect
					label={l10n.translate('platformCustomerServiceApp.actionModal.addCustomerCallNote.content.customerCallReason')}
					options={contactReasonOptions}
					onSelect={handleContactReasonSelect}
					value={contactReason === null ? undefined : contactReason}
					placeholder={l10n.translate('platformCustomerServiceApp.actionModal.pleaseSelect')}
				/>
				{renderOtherReasonInputTextArea()}
			</section>
			<InputTextArea
				label={l10n.translate('platformCustomerServiceApp.actionModal.addCustomerCallNote.content.noteHeading')}
				placeholder={l10n.translate('platformCustomerServiceApp.actionModal.addCustomerCallNote.content.notePlaceholder')}
				rows={5}
				onChange={handleInputNoteChange}
				value={comment ?? ''}
			/>
		</>
	);
}
