import React from 'react';

import { useCustomerServiceCustomerCommentCollectionData } from '@abb-emobility/platform/data-provider';

import { CommentCollection } from './CommentCollection';

export function CustomerCommentCollection() {

	const commentsData = useCustomerServiceCustomerCommentCollectionData();
	const comments = commentsData.query();

	return (
		<CommentCollection comments={comments} />
	);
}
