import React from 'react';

import { CustomerServiceOrderDownloadFileModel, CustomerServiceOrderDownloadFileType } from '@abb-emobility/platform/domain-model';
import { FilterCriteria, SingleValueFilterComparator, SortCriteria, SortDirection } from '@abb-emobility/shared/api-integration-foundation';
import { createAccessTokenLoader, useAuth } from '@abb-emobility/shared/auth-provider';
import { FetchError } from '@abb-emobility/shared/business-error';
import {
	CollectionDataProviderProps,
	createCrudCollectionDataProviderValue,
	FetchMode,
	useDataProviderFetchFailedHandler,
	useDataProviderFetchInit,
	useDataProviderFetchSuspense
} from '@abb-emobility/shared/data-provider-foundation';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { useEnv } from '@abb-emobility/shared/environment';
import { AppError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { Optional } from '@abb-emobility/shared/util';

import { customerServiceOrderDownloadFileCollectionData } from './CustomerServiceOrderDownloadFileCollectionData';
import {
	CustomerServiceOrderDownloadFileCollectionStore,
	customerServiceOrderDownloadFileCollectionStoreAccessors,
	customerServiceOrderDownloadFileCollectionStoreName
} from './CustomerServiceOrderDownloadFileCollectionSlice';

export type CustomerServiceOrderDownloadFileCollectionDataProviderProps = {
	orderId: ModelPrimaryKey,
	fileType: CustomerServiceOrderDownloadFileType
} & Omit<CollectionDataProviderProps<CustomerServiceOrderDownloadFileModel>, 'filterCriteria' | 'sortCriteria'>;

export function CustomerServiceOrderDownloadFileCollectionDataProvider(props: CustomerServiceOrderDownloadFileCollectionDataProviderProps) {
	const {
		fetchMode = FetchMode.IMMEDIATE,
		suspense = fetchMode === FetchMode.IMMEDIATE,
		pendingComponent = null,
		forceFetch,
		orderId,
		fileType
	} = props;

	const auth = useAuth();
	const env = useEnv();
	const l10n = useL10n();

	const apiBaseUrl = new Optional(process.env['NX_CUSTOMER_SERVICE_API_BASE_URL']).getOrThrow(new AppError('API base URL unavailable'));

	const dataProviderValue = createCrudCollectionDataProviderValue<CustomerServiceOrderDownloadFileModel, CustomerServiceOrderDownloadFileCollectionStore>(
		customerServiceOrderDownloadFileCollectionStoreName,
		customerServiceOrderDownloadFileCollectionStoreAccessors,
		apiBaseUrl,
		createAccessTokenLoader(auth, env)
	);

	useDataProviderFetchFailedHandler(dataProviderValue, new FetchError(l10n.translate('platformDataProvider.error.orderFilesCollectionFetchMessage')));

	const sortCriteria: SortCriteria<CustomerServiceOrderDownloadFileModel> = [{
		property: 'createdAt',
		direction: SortDirection.DESCENDING
	}];
	const filterCriteria: FilterCriteria<CustomerServiceOrderDownloadFileModel> = [{
		id: 'order',
		criteria: [
			{
				property: 'orderId',
				value: orderId,
				comparator: SingleValueFilterComparator.EQUAL
			},
			{
				property: 'fileType',
				value: fileType,
				comparator: SingleValueFilterComparator.EQUAL
			}
		]
	}];
	useDataProviderFetchInit(fetchMode, () => dataProviderValue.fetch(sortCriteria, filterCriteria, forceFetch));

	return useDataProviderFetchSuspense(dataProviderValue, suspense, pendingComponent).getOrDefault(
		<customerServiceOrderDownloadFileCollectionData.Provider value={dataProviderValue}>
			{props.children}
		</customerServiceOrderDownloadFileCollectionData.Provider>
	);

}
