import React from 'react';

import { CustomerServiceOrderEventCollectionModel } from '@abb-emobility/platform/domain-model';
import { FilterCriteria, SingleValueFilterComparator, SortCriteria, SortDirection } from '@abb-emobility/shared/api-integration-foundation';
import { createAccessTokenLoader, useAuth } from '@abb-emobility/shared/auth-provider';
import { FetchError } from '@abb-emobility/shared/business-error';
import {
	CollectionDataProviderProps,
	createCrudCollectionDataProviderValue,
	FetchMode,
	useDataProviderFetchFailedHandler,
	useDataProviderFetchInit,
	useDataProviderFetchSuspense
} from '@abb-emobility/shared/data-provider-foundation';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { useEnv } from '@abb-emobility/shared/environment';
import { AppError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { Optional } from '@abb-emobility/shared/util';

import { customerServiceOrderEventCollectionData } from './CustomerServiceOrderEventCollectionData';
import {
	customerServiceOrderEventCollectionStoreAccessors,
	CustomerServiceOrderEventCollectionStore,
	customerServiceOrderEventCollectionStoreName
} from './CustomerServiceOrderEventCollectionSlice';

type CustomerServiceOrderEventCollectionDataProviderProps = Omit<
	CollectionDataProviderProps<CustomerServiceOrderEventCollectionModel>,
	'filterCriteria' | 'sortCriteria'
> & {
	orderId: ModelPrimaryKey
};

export function CustomerServiceOrderEventCollectionDataProvider(props: CustomerServiceOrderEventCollectionDataProviderProps) {
	const {
		fetchMode = FetchMode.IMMEDIATE,
		suspense = fetchMode === FetchMode.IMMEDIATE,
		pendingComponent = null,
		forceFetch,
		orderId
	} = props;

	const auth = useAuth();
	const env = useEnv();
	const l10n = useL10n();

	const apiBaseUrl = new Optional(process.env['NX_CUSTOMER_SERVICE_API_BASE_URL']).getOrThrow(new AppError('API base URL unavailable'));

	const dataProviderValue = createCrudCollectionDataProviderValue<CustomerServiceOrderEventCollectionModel, CustomerServiceOrderEventCollectionStore>(
		customerServiceOrderEventCollectionStoreName,
		customerServiceOrderEventCollectionStoreAccessors,
		apiBaseUrl,
		createAccessTokenLoader(auth, env)
	);

	useDataProviderFetchFailedHandler(dataProviderValue, new FetchError(l10n.translate('platformDataProvider.error.orderEventCollectionFetchMessage')));

	const sortCriteria: SortCriteria<CustomerServiceOrderEventCollectionModel> = [
		{ property: 'eventDate', direction: SortDirection.DESCENDING }
	];
	const filterCriteria: FilterCriteria<CustomerServiceOrderEventCollectionModel> = [{
		id: 'ORDER_ID',
		criteria: [{
			property: 'orderId',
			comparator: SingleValueFilterComparator.EQUAL,
			value: orderId
		}]
	}];

	useDataProviderFetchInit(fetchMode, () => dataProviderValue.fetch(sortCriteria, filterCriteria, forceFetch));

	return useDataProviderFetchSuspense(dataProviderValue, suspense, pendingComponent).getOrDefault(
		<customerServiceOrderEventCollectionData.Provider value={dataProviderValue}>
			{props.children}
		</customerServiceOrderEventCollectionData.Provider>
	);

}
