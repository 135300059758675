import React, { useEffect, useState } from 'react';

import {
	CustomerServiceAddOrderNoteInvocationPayloadModelConverter,
	CustomerServiceCommentType
} from '@abb-emobility/platform/domain-model';
import { CommandStatus, useCommandData } from '@abb-emobility/shared/command-api';
import { VoidModelConverter } from '@abb-emobility/shared/domain-model-foundation';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	ActionModalContent,
	ActionModalFooter,
	ButtonGhost,
	ButtonPrimary,
	InputTextArea,
	NotificationBig,
	NotificationBigStatus,
	SubmissionStatus,
	SuccessFeeback,
	SuccessImageIdentifier,
	useModalDialogue,
	useModalDialogueManager
} from '@abb-emobility/shared/ui-primitive';

import { HypermediaAction } from '../../../ActionModalFactory.types';

export type AddOrderNoteActionModalProps = {
	action: HypermediaAction,
};

export function AddOrderNoteActionModal(props: AddOrderNoteActionModalProps) {

	const { action } = props;

	const l10n = useL10n();

	const modalDialogue = useModalDialogue();
	const dialogueManager = useModalDialogueManager();

	const commandData = useCommandData(
		action.hypermediaLink,
		new VoidModelConverter(),
		new CustomerServiceAddOrderNoteInvocationPayloadModelConverter()
	);

	const invokeCommandStatus = commandData.queryInvocationStatus();
	const invokeCommandError = commandData.queryInvocationError();

	const [comment, setComment] = useState<string>('');

	useEffect(() => {
		const handleSubmitAction = () => {
			commandData.invoke({
				commentType: CustomerServiceCommentType.ORDER,
				comment: comment
			});
		};

		const formIsReady = (comment?.length ?? 0) > 0;

		switch (invokeCommandStatus) {
			case CommandStatus.IDLE:
				modalDialogue.setFooter(
					<ActionModalFooter>
						<ButtonGhost
							label={l10n.translate('platformCustomerServiceApp.actionModal.addOrderNote.footer.cancel')}
							onClick={handleDismissDialogue}
						/>
						<ButtonPrimary
							label={l10n.translate('platformCustomerServiceApp.actionModal.addOrderNote.footer.confirm')}
							submissionStatus={SubmissionStatus.IDLE}
							onClick={handleSubmitAction}
							disabled={!formIsReady}
						/>
					</ActionModalFooter>
				);
				break;
			case CommandStatus.PENDING:
				modalDialogue.setFooter(
					<ActionModalFooter>
						<ButtonGhost
							label={l10n.translate('platformCustomerServiceApp.actionModal.addOrderNote.footer.cancel')}
							onClick={handleDismissDialogue}
						/>
						<ButtonPrimary
							label={l10n.translate('platformCustomerServiceApp.actionModal.addOrderNote.footer.confirm')}
							submissionStatus={SubmissionStatus.PENDING}
							onClick={handleSubmitAction}
						/>
					</ActionModalFooter>
				);
				break;
			case CommandStatus.SUCCESSFULL:
				modalDialogue.setContent(
					<ActionModalContent>
						<SuccessFeeback
							heading={l10n.translate('platformCustomerServiceApp.actionModal.addOrderNote.content.successHeading')}
							image={SuccessImageIdentifier.IMAGE4}
						>
							<ButtonGhost
								label={l10n.translate('platformCustomerServiceApp.actionModal.addOrderNote.content.closeButtonLabel')}
								onClick={handleDismissDialogue}
							/>
						</SuccessFeeback>
					</ActionModalContent>
				);
				modalDialogue.unsetFooter();
				break;
			case CommandStatus.FAILED:
				modalDialogue.setContent(
					<ActionModalContent>
						<NotificationBig
							status={NotificationBigStatus.ERROR}
							heading={l10n.translate('platformCustomerServiceApp.actionModal.addOrderNote.error.heading')}
							message={l10n.translate('platformCustomerServiceApp.actionModal.addOrderNote.error.message')}
							subline={invokeCommandError?.message}
						/>
					</ActionModalContent>
				);
				modalDialogue.unsetFooter();
				break;
		}

	}, [invokeCommandStatus, comment]);

	const handleDismissDialogue = () => {
		dialogueManager.pop();
	};

	const handleInputChange = (value: string): void => {
		setComment(value);
	};

	return (
		<InputTextArea
			label={l10n.translate('platformCustomerServiceApp.actionModal.addOrderNote.content.noteHeading')}
			placeholder={l10n.translate('platformCustomerServiceApp.actionModal.addOrderNote.content.notePlaceholder')}
			rows={5}
			onChange={handleInputChange}
			value={comment}
		/>
	);
}
