/* eslint-disable @typescript-eslint/no-explicit-any */
import { AddressModel, AddressModelConverter, ShippingState } from '@abb-emobility/shared/domain-model';
import {
	ArrayOfDownloadFileFieldNames,
	ArrayOfTimestampFieldNames,
	DownloadFile,
	DownloadFileFieldNames,
	ModelConverter,
	ModelConverterInterface,
	ModelPrimaryKey,
	Timestamp,
	TimestampFieldNames
} from '@abb-emobility/shared/domain-model-foundation';

import { CustomerServiceOrderLineItemModel, CustomerServiceOrderLineItemModelConverter } from './CustomerServiceOrderLineItemModel';
export enum ShipmentType {
	SHIPMENT = 'SHIPMENT',
	RETURN = 'RETURN',
	REPLACEMENT = 'REPLACEMENT'
}

export type ShipmentTrackingModel = {
	readonly carrier: string,
	readonly state: ShippingState,
	readonly trackingCode?: string,
	readonly trackingUrl?: string
};

export type CustomerServiceOrderShipmentFoundationModel = {
	readonly id: ModelPrimaryKey,
	readonly orderId: ModelPrimaryKey,
	readonly shipmentDate: Timestamp,
	readonly estimatedDeliveryDate?: Timestamp,
	readonly deliveryDate?: Timestamp,
	readonly receiverName: string,
	readonly deliveryAddress: AddressModel,
	readonly orderLineItems: Array<CustomerServiceOrderLineItemModel>,
	readonly tracking?: ShipmentTrackingModel,
	readonly shipmentDocuments?: Array<DownloadFile>
};

export type CustomerServiceOrderShipmentModel = CustomerServiceOrderShipmentFoundationModel & {
	readonly type: ShipmentType.SHIPMENT
};

export type CustomerServiceOrderReturnModel = CustomerServiceOrderShipmentFoundationModel & {
	readonly type: ShipmentType.RETURN,
	readonly reason?: string
};

export type CustomerServiceOrderReplacementModel = CustomerServiceOrderShipmentFoundationModel & {
	readonly type: ShipmentType.REPLACEMENT
};

export type AnyCustomerServiceOrderShipmentModel = CustomerServiceOrderShipmentModel
	| CustomerServiceOrderReturnModel
	| CustomerServiceOrderReplacementModel;

export class AnyCustomerServiceOrderShipmentModelConverter extends ModelConverter<AnyCustomerServiceOrderShipmentModel> {
	override getTimestampFields(): Array<TimestampFieldNames<AnyCustomerServiceOrderShipmentModel> | ArrayOfTimestampFieldNames<AnyCustomerServiceOrderShipmentModel>> {
		return ['shipmentDate', 'estimatedDeliveryDate', 'deliveryDate'];
	}

	override getDownloadFileFields(): Array<DownloadFileFieldNames<AnyCustomerServiceOrderShipmentModel> | ArrayOfDownloadFileFieldNames<AnyCustomerServiceOrderShipmentModel>> {
		return ['shipmentDocuments'];
	}

	override getFieldConverterMapByModel(
		_model: AnyCustomerServiceOrderShipmentModel
	): Map<keyof AnyCustomerServiceOrderShipmentModel, ModelConverterInterface<any>> {
		const orderLineItemModelConverter: ModelConverterInterface<CustomerServiceOrderLineItemModel> = new CustomerServiceOrderLineItemModelConverter();
		const converterMap = new Map<keyof AnyCustomerServiceOrderShipmentModel, ModelConverterInterface<any>>([
			['deliveryAddress', new AddressModelConverter()],
			['orderLineItems', orderLineItemModelConverter]
		]);
		return converterMap;
	}
}
