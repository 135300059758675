import { CustomerServiceCancelInstallationPayloadModel } from '@abb-emobility/platform/domain-model';
import { CurrencyCode } from '@abb-emobility/shared/currency';
import { Dto } from '@abb-emobility/shared/domain-model-foundation';

import {
	customerServiceOrderLineItemModelMock01,
	customerServiceOrderLineItemModelMock02,
	customerServiceOrderLineItemModelMock03
} from '../../CustomerServiceOrderLineItemModel.mock';

export const customerServiceCancelInstallationPayloadModelMock: Dto<CustomerServiceCancelInstallationPayloadModel> = {
	orderLineItems: [
		customerServiceOrderLineItemModelMock01(),
		customerServiceOrderLineItemModelMock02(),
		customerServiceOrderLineItemModelMock03()
	],
	totalPrice: {
		amount: 20000,
		currency: CurrencyCode.EUR
	}
};
