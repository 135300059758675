import React, { ReactNode } from 'react';

import { CustomerServiceOrderEventCollectionDataProvider } from '@abb-emobility/platform/data-provider';
import { CustomerServiceOrderModel } from '@abb-emobility/platform/domain-model';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { UiErrorHandler } from '@abb-emobility/shared/ui-error-handler';
import {
	Card,
	SegmentHeader,
	SpinnerCircle,
	TabularInformationItem,
	TabularInformationItemType,
	TabularInformationRow
} from '@abb-emobility/shared/ui-primitive';

import { CustomerInformation } from '../customer-information/CustomerInformation';
import { OrderEventTimeline } from '../order-event-timeline/OrderEventTimeline';
import { OrderLineItemCollection } from '../order-line-item-collection/OrderLineItemCollection';

import './OrderDetailOverview.scss';

export type OrderDetailOverviewProps = {
	order: CustomerServiceOrderModel
};

export function OrderDetailOverview(props: OrderDetailOverviewProps) {

	const l10n = useL10n();

	const { order } = props;

	const renderTimelineSection = (): ReactNode => {
		return (
			<Card>
				<SegmentHeader caption={l10n.translate('platformCustomerServiceApp.orderDetail.overview.timeline.heading')} />
				<UiErrorHandler>
					<CustomerServiceOrderEventCollectionDataProvider
						pendingComponent={SpinnerCircle}
						suspense={false}
						orderId={order.id}
					>
						<OrderEventTimeline />
					</CustomerServiceOrderEventCollectionDataProvider>
				</UiErrorHandler>
			</Card>
		);
	};

	return (
		<article className="order-detail-overview">
			<Card>
				<CustomerInformation
					header={l10n.translate('platformCustomerServiceApp.orderDetail.overview.customerInformation.heading')}
					customer={order.customer}
				/>
			</Card>

			<Card>
				<SegmentHeader caption={l10n.translate('platformCustomerServiceApp.orderDetail.overview.orderInformation.heading')} />

				<TabularInformationRow>
					<TabularInformationItem
						label={l10n.translate('platformCustomerServiceApp.orderDetail.overview.orderInformation.tableItem.phone')}
						variant={TabularInformationItemType.GREY}
					/>
					<TabularInformationItem label={order.phoneNumber} />
				</TabularInformationRow>

				<TabularInformationRow>
					<TabularInformationItem
						label={l10n.translate('platformCustomerServiceApp.orderDetail.overview.orderInformation.tableItem.language')}
						variant={TabularInformationItemType.GREY}
					/>
					<TabularInformationItem label={order.languageCode.toUpperCase()} />
				</TabularInformationRow>

				<TabularInformationRow>
					<TabularInformationItem
						label={l10n.translate('platformCustomerServiceApp.orderDetail.overview.orderInformation.tableItem.deliveryAddress')}
						variant={TabularInformationItemType.GREY}
					/>
					<TabularInformationItem
						label={
							order.deliveryAddress.street + ', ' +
							order.deliveryAddress.zip + ' ' +
							order.deliveryAddress.city + ', ' +
							order.deliveryAddress.countryCode
						}
					/>
				</TabularInformationRow>
			</Card>

			<Card>
				<OrderLineItemCollection orderLineItems={order.orderLineItems} currencyCode={order.currencyCode} />
			</Card>

			{renderTimelineSection()}
		</article>

	);
}
