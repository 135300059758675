import { ModelConverter } from '@abb-emobility/shared/domain-model-foundation';
import {
	PersistedAnswersDto,
	QuestionnaireEnvironmentModel,
	AnyQuestionnaireModel
} from '@abb-emobility/shared/questionnaire';

export type CustomerServiceOrderInformationBasicAnswersInformationModel = {
	readonly questionnaire: AnyQuestionnaireModel,
	readonly questionnaireEnvironment: QuestionnaireEnvironmentModel,
	readonly answers: PersistedAnswersDto
};

export class CustomerServiceOrderInformationBasicAnswersInformationModelConverter extends ModelConverter<CustomerServiceOrderInformationBasicAnswersInformationModel> {}
