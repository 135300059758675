import { ReactNode } from 'react';

import './ActionModalContentSection.scss';

export type ActionModalContentSectionProps = {
	children?: ReactNode
};

export function ActionModalContentSection(props: ActionModalContentSectionProps) {

	const { children } = props;

	return (
		<section className="action-modal-content-section">
			{children}
		</section>
	);
}
