import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	platformCustomerServiceApp: {
		action: {
			showCustomer: 'Mostrar ao cliente'
		},
		actionItemFactory: {
			addCustomerNoteLabel: 'Adicionar nota',
			addOrderNoteLabel: 'Adicionar nota',
			cancelOrderLabel: 'Cancelar',
			cancelInstallationLabel: 'Cancelar a instalação',
			cancelOrderAppointmentsLabel: 'Cancelar marcações',
			externalLinkLabel: 'Ligação externa',
			resendMailLabel: 'Reenviar correio',
			returnOrderLabel: 'Voltar',
			replaceOrderLabel: 'Substituir',
			updateCustomerLabel: 'Modificar dados',
			updateOrderLabel: 'Modificar dados'
		},
		actionModalFactory: {
			addCustomerNoteHeading: 'Adicionar nota',
			addOrderNoteHeading: 'Adicionar nota',
			cancelOrderHeading: 'Cancelar encomenda',
			cancelInstallationHeading: 'Cancelar a instalação',
			cancelOrderAppointmentsHeading: 'Cancelar marcações de encomendas',
			resendMailHeading: 'Reenviar correio',
			returnOrderHeading: 'Devolver encomenda',
			replaceOrderHeading: 'Substituir encomenda',
			updateCustomerHeading: 'Alterar dados do cliente',
			updateOrderHeading: 'Alterar dados da encomenda'
		},
		actionModal: {
			pleaseSelect: 'Seleccione',
			addNote: {
				content: {
					category: 'Categoria',
					categories: {
						order: 'Nota de encomenda',
						customer: 'Nota do cliente',
						customerCall: 'Chamada do cliente'
					}
				}
			},
			addOrderNote: {
				content: {
					noteHeading: 'Nota',
					notePlaceholder: 'Inserir texto',
					successHeading: 'Nota de encomenda criada com sucesso',
					closeButtonLabel: 'Fechar'
				},
				footer: {
					confirm: 'Confirmar',
					cancel: 'Cancelar'
				},
				error: {
					heading: 'Não foi possível criar nota de encomenda.',
					message: 'Ocorreu um erro ao criar a nota.'
				}
			},
			addCustomerNote: {
				content: {
					noteHeading: 'Nota',
					notePlaceholder: 'Inserir texto',
					successHeading: 'Nota de cliente criada com sucesso',
					closeButtonLabel: 'Fechar'
				},
				footer: {
					confirm: 'Confirmar',
					cancel: 'Cancelar'
				},
				error: {
					heading: 'Não foi possível criar uma nota de cliente.',
					message: 'Ocorreu um erro ao criar a nota de cliente.'
				}
			},
			addCustomerCallNote: {
				content: {
					noteHeading: 'Nota',
					notePlaceholder: 'Inserir texto',
					customerCallReason: 'Motivo da chamada do cliente',
					callReasonOptions: {
						afterSales: 'Pós-venda',
						product: 'Produto',
						installation: 'Instalação',
						preCheck: 'Pré-verificação',
						payment: 'Pagamento',
						dataProtection: 'Proteção de dados',
						shipment: 'Envio',
						cancellation: 'Devoluções',
						falseRouting: 'Falso encaminhamento',
						other: 'Outros',
						complaints: 'Queixas',
						returns: 'Devoluções'
					},
					callReasonNotePlaceholder: 'Descrição',
					successHeading: 'Nota de chamada do cliente criada com sucesso',
					closeButtonLabel: 'Fechar'
				},
				footer: {
					confirm: 'Confirmar',
					cancel: 'Cancelar'
				},
				error: {
					heading: 'Não foi possível criar uma nota de chamada do cliente.',
					message: 'Ocorreu um erro ao criar a nota de chamada do cliente.'
				}
			},
			cancelOrder: {
				heading: 'Encomenda',
				position: 'Posição',
				description: 'Descrição',
				reason: 'Motivo',
				note: 'Descrição',
				cancelReasonValue: {
					other: 'Outros',
					wallboxBroken: 'Wallbox avariada',
					wallboxNotWorking: 'A Wallbox não está a funcionar',
					withdrawal: 'Levantamento',
					installationNotPossible: 'A instalação não é possível'
				},
				footer: {
					cancel: 'Cancelar',
					confirm: 'Confirmar'
				},
				content: {
					successHeading: 'Cancelamento da encomenda com êxito',
					closeButtonLabel: 'Fechar'
				},
				error: {
					loading: {
						heading: 'Não foi possível carregar os dados da encomenda.',
						message: 'Ocorreu um erro ao carregar os dados da encomenda.'
					},
					writing: {
						heading: 'Não foi possível cancelar a encomenda.',
						message: 'Ocorreu um erro durante o cancelamento da encomenda.'
					}
				}
			},
			cancelInstallation: {
				heading: 'Encomenda',
				position: 'Posição',
				description: 'Descrição',
				reason: 'Motivo',
				note: 'Descrição',
				cancelReasonValue: {
					other: 'Outros',
					wallboxBroken: 'Wallbox avariada',
					wallboxNotWorking: 'A Wallbox não está a funcionar',
					withdrawal: 'Levantamento',
					installationNotPossible: 'A instalação não é possível'
				},
				footer: {
					cancel: 'Cancelar',
					confirm: 'Confirmar'
				},
				content: {
					successHeading: 'Cancelamento da instalação com êxito',
					closeButtonLabel: 'Fechar'
				},
				error: {
					loading: {
						heading: 'Não foi possível carregar os dados da encomenda.',
						message: 'Ocorreu um erro ao carregar os dados da encomenda.'
					},
					writing: {
						heading: "Não foi possível cancelar a instalação",
						message: 'Ocorreu um erro ao cancelar a instalação'
					}
				}
			},
			cancelOrderAppointments: {
				content: {
					notificationHeading: 'O parceiro de instalação será informado do cancelamento da marcação.',
					notificationMessage: 'O parceiro de instalação entrará em contacto com o cliente para efetuar uma nova marcação.',
					successHeading: 'Marcações canceladas',
					successCloseButtonLabel: 'Fechar',
					reason: 'Motivo'
				},
				cancelReasonValue: {
					deliveryDelay: 'Atraso na entrega',
					installationPartnerConflict: 'Conflito entre parceiros de instalação',
					customerConflict: 'Conflito com clientes',
					replacement: 'Substituição'
				},
				footer: {
					confirm: 'Confirmar o cancelamento',
					cancel: 'Cancelar'
				},
				error: {
					heading: 'Não foi possível cancelar as marcações.',
					message: 'Ocorreu um erro ao eliminar as marcações da encomenda.'
				}
			},
			resendMail: {
				content: {
					notificationHeading: 'O correio eletrónico será reenviado.',
					notificationMessage: 'O cliente receberá uma nova mensagem de correio eletrónico.',
					successHeading: 'Correio eletrónico enviado com sucesso.',
					successCloseButtonLabel: 'Fechar'
				},
				footer: {
					confirm: 'Reenviar correio eletrónico',
					cancel: 'Cancelar'
				},
				error: {
					heading: 'Não foi possível reenviar o correio eletrónico.',
					message: 'Ocorreu um erro ao enviar o correio eletrónico.'
				}
			},
			returnOrder: {
				heading: 'Encomenda',
				position: 'Posição',
				description: 'Descrição',
				note: 'Descrição',
				'return': 'Voltar',
				selectReturn: 'Selecionar itens de devolução',
				selectReason: 'Selecionar o motivo da devolução',
				replacementItem: 'Artigo de substituição',
				yes: 'Sim',
				no: 'Não',
				returnReason: {
					other: 'Outros',
					broken: 'Avariado'
				},
				footer: {
					cancel: 'Cancelar',
					confirm: 'Confirmar'
				},
				content: {
					successHeading: 'Iniciou com êxito o processo de devolução',
					closeButtonLabel: 'Fechar'
				},
				error: {
					loading: {
						heading: 'Não foi possível carregar os dados da encomenda.',
						message: 'Ocorreu um erro ao carregar os dados da encomenda.'
					},
					writing: {
						heading: 'Não foi possível iniciar o processo de devolução.',
						message: 'Ocorreu um erro ao invocar o processo de ordem de devolução.'
					}
				}
			},
			replaceOrder: {
				heading: 'Encomenda',
				position: 'Posição',
				description: 'Descrição',
				note: 'Descrição',
				'return': 'Substituir',
				selectReturn: 'Selecionar itens de substituição',
				selectReason: 'Selecionar o motivo da substituição',
				returnReason: {
					other: 'Outros',
					broken: 'Avariado'
				},
				footer: {
					cancel: 'Cancelar',
					confirm: 'Confirmar'
				},
				content: {
					successHeading: 'Iniciou com êxito o processo de substituição',
					closeButtonLabel: 'Fechar'
				},
				error: {
					loading: {
						heading: 'Não foi possível carregar os dados da encomenda.',
						message: 'Ocorreu um erro ao carregar os dados da encomenda.'
					},
					writing: {
						heading: 'Não foi possível iniciar o processo de substituição.',
						message: 'Ocorreu um erro ao invocar o processo de ordem de substituição.'
					}
				}
			},
			updateCustomer: {
				content: {
					firstname: 'Nome próprio',
					lastname: 'Apelido',
					email: 'E-mail',
					personalInfoHeading: 'Informação pessoal',
					contactDataHeading: 'Dados de contacto',
					successHeading: 'Informação do utilizador alterada com sucesso.',
					successCloseButtonLabel: 'Fechar'
				},
				footer: {
					confirm: 'Confirmar',
					cancel: 'Cancelar'
				},
				error: {
					loading: {
						heading: 'Não foi possível carregar as informações do cliente.',
						message: 'Ocorreu um erro ao carregar as informações do cliente.'
					},
					writing: {
						heading: 'Não foi possível atualizar as informações do cliente.',
						message: 'Ocorreu um erro ao atualizar as informações do cliente.'
					}
				}
			},
			updateOrder: {
				content: {
					orderInformationHeading: 'Informação da encomenda',
					phoneLabel: 'Telefone',
					languageHeading: 'Idioma',
					languageLabel: ' Código de idioma',
					successHeading: 'A informação da encomenda foi alterada com sucesso.',
					successCloseButtonLabel: 'Fechar'
				},
				footer: {
					confirm: 'Confirmar',
					cancel: 'Cancelar'
				},
				error: {
					loading: {
						heading: 'Não foi possível carregar as informações da encomenda.',
						message: 'Ocorreu um erro ao carregar as informações da encomenda.'
					},
					writing: {
						heading: 'Não foi possível atualizar as informações da encomenda.',
						message: 'Ocorreu um erro ao atualizar as informações da encomenda.'
					}
				}
			}
		},
		appName: 'Serviço de apoio ao cliente ABB',
		breadcrumbs: {
			tasks: 'Síntese da tarefa',
			orders: 'Síntese da encomenda',
			marketplaceOrders: 'Síntese das encomendas do mercado',
			customers: 'Síntese do cliente'
		},
		comment: {
			categories: {
				order: 'Nota de encomenda',
				customer: 'Nota do cliente',
				customerCall: 'Chamada do cliente'
			},
			callReason: {
				afterSales: 'Pós-venda',
				product: 'Produto',
				installation: 'Instalação',
				preCheck: 'Pré-verificação',
				payment: 'Pagamento',
				dataProtection: 'Proteção de dados',
				shipment: 'Envio',
				cancellation: 'Devoluções',
				falseRouting: 'Falso encaminhamento',
				other: 'Outros',
				complaints: 'Queixas',
				returns: 'Devoluções'
			}
		},
		commentCollection: {
			heading: 'Notas',
			emptyState: {
				heading: 'Sem comentários',
				message: 'Atualmente, não existem comentários disponíveis.'
			}
		},
		common: {
			tableHeader: {
				position: 'Posição',
				description: 'Descrição'
			}
		},
		customerCollection: {
			heading: 'Clientes',
			tableHead: {
				customer: 'Cliente',
				customerId: 'ID do cliente',
				firstOrder: 'Primeira encomenda',
				lastOrder: 'Última encomenda'
			},
			emptyState: {
				heading: 'Nenhum cliente encontrado',
				message: 'Ajuste as definições do seu filtro para encontrar alguns clientes.'
			},
			action: {
				showMore: 'Mostrar mais'
			}
		},
		customerInformation: {
			heading: 'Informação ao cliente',
			tableHeader: {
				position: 'Posição',
				description: 'Descrição'
			},
			tableItem: {
				firstName: 'Nome próprio',
				lastName: 'Apelido',
				companyName: 'Nome da empresa',
				mail: 'Correio',
				firstOrder: 'Primeira encomenda',
				lastOrder: 'Última encomenda'
			}
		},
		customerOrders: {
			heading: 'Encomendas do cliente'
		},
		customerMarketplaceOrders: {
			heading: 'Marketplace encomendas do cliente'
		},
		customerDetail: {
			subheader: {
				customerId: 'ID do cliente'
			},
			tabItems: {
				overview: 'Síntese',
				comments: 'Notas'
			}
		},
		error: {
			generic: {
				heading: 'Algo correu mal',
				message: 'Ocorreu um erro imprevisto que não pôde ser resolvido',
				resolveAction: {
					label: 'Recarregar'
				}
			},
			offline: {
				heading: 'Ligação de rede indisponível',
				message: 'Ligue-se a uma rede para utilizar a aplicação.'
			},
			notFound: {
				heading: 'Não encontrado',
				message: 'O recurso solicitado não foi encontrado',
				resolveAction: {
					label: 'As minhas tarefas'
				}
			},
			customerNotFound: 'O cliente solicitado não foi encontrado',
			orderNotFound: 'A nota de encomenda solicitada não foi encontrada',
			taskNotFound: 'A tarefa solicitada não foi encontrada',
			callerNotFound: 'Não foram encontradas informações sobre o autor da chamada',
			taskAssignMessage: 'Durante a atribuição da tarefa, ocorreu um erro.',
			taskCompleteMessage: 'Durante a conclusão da tarefa, ocorreu um erro.'
		},
		navigation: {
			tasks: 'As minhas tarefas',
			orders: 'Encomendas',
			marketplaceOrders: 'Marketplace Encomendas',
			customers: 'Clientes',
			logout: 'Terminar sessão'
		},
		order: {
			installationType: {
				undetermined: 'Indeterminado',
				standard: 'Padrão',
				delta: 'Delta',
				cancelled: 'Cancelado'
			}
		},
		orderDetail: {
			subheader: {
				orderDate: 'Data da encomenda',
				orderId: 'ID da encomenda',
				marketplace: 'Marketplace',
				plannedDelivery: 'Entrega planeada',
				orderState: {
					onSchedule: 'Dentro do prazo',
					delayed: 'Atrasada',
					cancelPending: 'Cancelar pendente',
					cancelled: 'Cancelada',
					imported: 'Importado',
					validationFailed: 'Inválido',
					done: 'Pronto'
				}
			},
			tabItems: {
				overview: 'Síntese',
				installation: 'Instalação',
				delivery: 'Entrega',
				accounting: 'Contabilidade',
				files: 'Ficheiros',
				comments: 'Notas',
				tasks: 'Tarefas'
			},
			overview: {
				customerInformation: {
					heading: 'Informação ao cliente'
				},
				orderInformation: {
					heading: 'Informação da encomenda',
					tableHeader: {
						position: 'Posição',
						description: 'Descrição'
					},
					tableItem: {
						language: 'Idioma',
						phone: 'Telefone',
						deliveryAddress: 'Endereço'
					}
				},
				timeline: {
					heading: 'Cronologia'
				}
			},
			installation: {
				cancellation: {
					notification: {
						heading: 'A instalação foi cancelada',
						message: 'O cancelamento foi efectuado em {{cancellationDate}}. O motivo apresentado foi "{{cancellationReason}}".'
					}
				},
				information: {
					heading: 'Informação',
					types: {
						basicAnswers: 'Respostas básicas',
						gridOperatorRegistration: 'Registo do operador de rede',
						gridOperatorDecision: 'Decisão do operador de rede',
						preCheck: 'Verificação prévia',
						offer: 'Oferta',
						approval: 'Aprovação'
					},
					emptyState: {
						heading: 'Não existem informações disponíveis sobre a encomenda',
						message: 'Não estão disponíveis informações sobre a encomenda.'
					}
				},
				installationAppointments: {
					heading: 'Instalações planeadas',
					installer: 'Instalador',
					appointment: 'Marcação',
					phone: 'Telefone',
					mail: 'E-mail',
					emptyState: {
						heading: 'Não foram encontradas marcações.',
						message: 'Não estão previstas marcações.'
					}
				}
			},
			delivery: {
				orderItem: 'Encomendar artigo',
				productCode: 'Código do produto',
				address: {
					heading: 'Endereço de entrega',
					name: 'Nome',
					street: 'Rua',
					location: 'Cidade'
				},
				details: {
					heading: 'Detalhes da entrega',
					shippingDate: 'Data de envio',
					address: 'Morada de envio',
					trackingCode: 'Código de rastreio',
					state: 'Estado do envio',
					partner: 'Parceiro de envio',
					tracking: 'Rastreio',
					shippingState: {
						delivered: 'Entregue',
						pending: 'Pendente'
					},
					estimatedDeliveryDate: 'Data de entrega prevista',
					deliveryDate: 'Data de entrega'
				},
				retoureDetails: {
					heading: 'Detalhes do reencaminhamento',
					date: 'Data do pedido',
					address: 'Endereço de devolução',
					reason: 'Motivo da devolução:',
					returnNote: 'Nota de devolução',
					created: 'Criado em'
				},
				replacementDetails: {
					heading: 'Detalhes da entrega da substituição'
				},
				emptyState: {
					heading: 'Não existem informações sobre o envio',
					message: 'Atualmente, não existem mais informações sobre o envio.'
				}
			},
			accounting: {
				heading: 'Transação',
				date: 'Data de pagamento',
				state: 'Estado',
				notification: 'Mensagem de erro',
				cardOwner: 'Proprietário do cartão',
				cardNumber: 'Número do cartão',
				cardDueDate: 'Data de vencimento do cartão',
				accountOwner: 'Proprietário da conta',
				accountNumber: 'IBAN',
				paymentState: {
					success: 'Sucesso',
					failed: 'Falhou',
					settlementPending: 'Liquidação pendente'
				},
				emptyPaymentState: {
					heading: 'Não existem informações de pagamento disponíveis',
					message: 'Atualmente, não existem mais informações sobre os pagamentos.'
				},
				emptyDownloadFilesState: {
					heading: 'Não há ficheiros de transferência disponíveis',
					message: 'Atualmente, não existem ficheiros de transferência disponíveis.'
				}
			},
			files: {
				emptyState: {
					heading: 'Não há ficheiros de transferência disponíveis',
					message: 'Atualmente, não existem ficheiros de transferência disponíveis.'
				}
			},
			throttleWallboxHint: {
				caption: 'Atenção',
				message: 'O consumo de energia da Wallbox tem de ser reduzido de acordo com os requisitos do operador da rede.'
			},
			informationList: {
				heading: 'Informação',
				emptyState: {
					heading: 'Ainda não estão disponíveis informações sobre a encomenda',
					message: 'À medida que a encomenda avança, encontrará aqui todas as informações relevantes sobre a encomenda.'
				},
				caption: {
					basicAnswers: 'Respostas básicas',
					gridOperatorRegistration: 'Registo do operador de rede',
					gridOperatorDecision: 'Decisão do operador de rede',
					preCheck: 'Verificação prévia',
					offer: 'Oferta',
					approval: 'Aprovação'
				},
				precheck: {
					heading: 'Verificação prévia',
					effort: 'Verificar previamente os resultados: Prazos e esforço de instalação',
					questionnaire: {
						heading: 'Respostas dos clientes'
					},
					installationType: {
						undetermined: 'Indeterminado',
						standard: 'Instalação standard',
						delta: 'Instalação Delta'
					},
					decision: {
						accepted: 'Informação suficiente',
						more: 'Mais informações necessárias',
						denied: 'Instalação não é possível'
					},
					notes: 'Notas',
					hours: 'horas'
				},
				decision: {
					heading: 'Decisão do operador de rede',
					decision: {
						pending: 'O pedido de instalação ainda está pendente',
						accepted: 'O operador da rede concordou com a instalação',
						denied: 'O operador da rede recusou a instalação'
					}
				},
				registration: {
					heading: 'Registo do operador de rede',
					documents: 'Documentos',
					gridOperator: 'Operador de rede'
				},
				quotation: {
					heading: 'Orçamento',
					conditions: 'Condições da oferta'
				},
				complete: {
					success: {
						heading: 'Instalação bem-sucedida'
					},
					notes: 'Notas',
					images: 'Imagens relacionadas com a instalação',
					documents: 'Documentos relacionados com a instalação',
					signature: 'Assinatura do cliente'
				}
			}
		},
		orderEventTimeline: {
			emptyState: {
				heading: 'Não foram encontrados eventos',
				message: 'O histórico ainda está vazio'
			}
		},
		timelineItemDetails: {
			mailAddress: {
				label: 'Email'
			},
			section: {
				payment: 'Pagamentos',
				appointments: 'Compromissos agendados'
			}
		},
		orderEvent: {
			type: {
				orderReceived: 'Encomenda recebida',
				orderAddressValidationSucceeded: 'Validação de endereço bem sucedida',
				orderAddressValidationFailed: 'A validação do endereço falhou',
				orderAddressCorrected: 'Endereço da encomenda corrigido',
				accountingOrderCreated: 'Ordem de contabilidade criada',
				orderConfirmationMailSent: 'Correio de confirmação enviado',
				basicAnswersReceived: 'Respostas do questionário recebidas',
				gridOperatorRegistrationStarted: 'Registo do operador de grelha iniciado',
				gridOperatorFeedbackReceived: 'Feedback do operador de rede recebido',
				gridOperatorRejectionNotificationSent: 'Rejeição do operador de rede recebida',
				basicAnswersEvaluated: 'Respostas do questionário avaliadas',
				precheckPreferencesMailSent: 'Correio de pedidos de contacto de pré-verificação enviado',
				remotePrecheckExecuted: 'Verificação prévia remota executada',
				deltaQuotationCreated: 'Cotação delta criada',
				deltaQuotationMailSent: 'Correio eletrónico de cotação delta enviado',
				deltaQuotationAccepted: 'Oferta Delta aceite',
				paymentSucceeded: 'Pagamento efectuado com êxito',
				paymentFailedOrTimedOut: 'Pagamento falhado',
				paymentReminderMailSent: 'Lembrete de pagamento enviado',
				paymentConfirmationMailSent: 'Correio eletrónico de confirmação do pagamento enviado',
				appointmentArranged: 'Compromisso marcado',
				appointmentArrangedNotificationSent: 'Correio de notificação de compromisso enviado',
				shippingConfirmed: 'Entrega confirmada',
				installationConfirmed: 'Instalação confirmada',
				replacementInstallationConfirmed: 'Instalação de substituição confirmada',
				installationApprovalMailSent: 'Correio eletrónico de confirmação da instalação enviado',
				invoiceCreated: 'Fatura criada',
				invoiceNotificationSent: 'Notificação de fatura enviada',
				orderCancelled: 'Encomenda cancelada',
				gridOperatorRegistrationRevoked: 'Registo de operador de rede cancelado',
				customerCallReceived: 'Chamada de cliente recebida',
				returnOrReplacementShipmentReceived: 'Devolução ou entrega de substituição recebida',
				returnInitiated: 'Devolução inicializada',
				replacementInitiated: 'Entrega de substituição iniciada',
				cancellationInitiated: 'Cancelamento iniciado',
				orderNoteCreated: 'Nota de encomenda criada',
				refundCompleted: 'Reembolso concluído',
				installationCancellationMailSent: 'Notificação de cancelamento da instalação enviada'
			},
			category: {
				accounting: 'Contabilidade',
				customer: 'Cliente',
				customerService: 'Serviço ao cliente',
				deltaInstallation: 'Instalação delta',
				gridOperator: 'Operador de grelha',
				installation: 'Instalação',
				order: 'Ordem',
				payment: 'Pagamento',
				preCheck: 'PreCheck',
				shipping: 'Envio'
			}
		},
		marketplaceOrder: {
			orderState: {
				paymentPending: 'Pagamento pendente',
				waitingForFulfillment: 'Aguardando cumprimento',
				fulfillmentStarted: 'Fulfillment iniciado',
				cancelled: 'Cancelado',
				done: 'Pronto'
			}
		},
		orderCollection: {
			heading: 'Encomendas',
			tableHead: {
				order: 'Encomenda',
				status: 'Estado',
				language: 'Idioma',
				plannedDeliveryDate: 'Entrega planeada',
				createdAt: 'Criado em'
			},
			emptyState: {
				heading: 'Não foram encontradas encomendas',
				message: 'Ajuste as definições de filtro para encontrar algumas encomendas.'
			},
			action: {
				refresh: 'Atualizar',
				showMore: 'Mostrar mais'
			},
			orderState: {
				onSchedule: 'Dentro do prazo',
				delayed: 'Atrasada',
				cancelPending: 'Cancelar pendente',
				cancelled: 'Cancelada',
				imported: 'Importado',
				validationFailed: 'Inválido',
				done: 'Pronto'
			}
		},
		customerOrderCollection : {
			emptyState: {
				heading: 'Não foram encontradas encomendas',
				message: 'Não foram encontradas encomendas para este cliente.'
			}
		},
		orderLineItemCollection: {
			heading: 'Encomenda',
			position: 'Posição',
			description: 'Descrição',
			price: 'Preço',
			productCode: 'Código do produto',
			totalPrice: 'Preço total'
		},
		inboundOrderCollection: {
			heading: 'Encomendas associadas ao número de telefone {{caller}}',
			emptyState: {
				heading: 'Não foram encontradas encomendas',
				message: 'Talvez seja melhor tentar fazer uma pesquisa.',
				searchLabel: 'Procurar'
			},
			action: {
				showMore: 'Mostrar mais'
			}
		},
		searchbar: {
			placeholder: 'Procurar',
			results: {
				orders: 'Encomendas',
				marketplaceOrders: 'Ordens do mercado',
				customers: 'Clientes',
				showResultPage: 'Mostrar página de resultados',
				showResultPageDescription: {
					resultsFromTo: 'Resultado 1 a',
					of: 'de'
				}
			}
		},
		searchResultCollection: {
			heading: 'Resultados da pesquisa',
			headingWithSearchterm: 'Resultados da pesquisa',
			headingCustomers: 'Clientes',
			headingOrders: 'Encomendas',
			headingMarketplaceOrders: 'Ordens do mercado',
			emptyState: {
				heading: 'Não foram encontrados resultados de pesquisa',
				message: 'Ajuste o seu termo de pesquisa e tente novamente.'
			},
			idleState: {
				heading: 'Nenhum termo de pesquisa fornecido',
				message: 'Por favor, introduza um termo de pesquisa.'
			},
			action: {
				showMore: 'Mostrar mais'
			}
		},
		task: {
			assign: {
				successMessage: 'Tarefa atribuída'
			},
			error: {
				taskNotFound: 'Tarefa não encontrada',
				taskTypeNotFound: 'Tipo de tarefa não encontrado'
			},
			gridOperatorRegistration: {
				continueToFeedback: {
					buttonLabel: 'Continuar'
				}
			},
			basicAnswers: {
				continueToQuotation: {
					buttonLabel: 'Continuar'
				},
				button: {
					close: 'Fechar',
					submit: 'Submeter'
				},
				questionnaire: {
					heading: 'Respostas básicas'
				}
			},
			completion: {
				successHeading: 'Tarefa concluída com êxito',
				closeButtonLabel: 'Fechar'
			},
			preCheck: {
				button: {
					close: 'Fechar',
					submit: 'Submeter'
				},
				contact: {
					description: 'Contactar'
				}
			},
			gridOperator: {
				button: {
					close: 'Fechar',
					submit: 'Submeter'
				}
			},
			quotation: {
				button: {
					close: 'Fechar',
					submit: 'Submeter'
				},
				deltaPackages: {
					totalPurchaseNetPrice: 'Preço total de compra (líquido)',
					totalSalesGrossPrice: 'Preço total de venda (incluindo {{vatRate}}% IVA)',
					purchaseNetPrice: 'Preço de compra (líquido)',
					salesGrossPrice: 'Preço de venda (bruto)'
				}
			},
			payment: {
				button: {
					close: 'Fechar',
					submit: 'Submeter'
				}
			},
			appointment: {
				button: {
					close: 'Fechar',
					submit: 'Submeter'
				}
			},
			installation: {
				button: {
					close: 'Fechar',
					submit: 'Submeter'
				}
			}
		},
		taskCollection: {
			heading: 'Tarefas',
			tableHead: {
				task: 'Tarefa',
				status: 'Estado',
				language: 'Idioma'
			},
			action: {
				refresh: 'Atualizar',
				showMore: 'Mostrar mais'
			},
			assign: {
				successMessage: 'A tarefa foi atribuída com êxito',
				errorMessage: 'Durante a atribuição da tarefa, ocorreu um erro.'
			},
			emptyState: {
				heading: 'Não foram encontradas tarefas',
				message: 'Ajuste as definições de filtro para encontrar algumas tarefas.'
			},
			orderEmptyState: {
				heading: 'Não foram encontradas tarefas',
				message: 'Não é necessário fazer nada para esta encomenda.'
			}
		},
		taskCollectionItem: {
			type: {
				customerAnswerBasicQuestionsEscalation: 'Encaminhamento: Pergunta básica',
				customerProvidePreCheckPreferencesEscalation: 'Encaminhamento: Pré-verificação',
				customerHandleUnfeasibleInstallationEscalation: 'Encaminhamento: Instalação inviável',
				customerHandleGridOperatorRejectionEscalation: 'Encaminhamento: Rejeição do operador de rede',
				customerAcceptQuotationEscalation: 'Encaminhamento: Orçamento',
				customerPerformPaymentEscalation: 'Encaminhamento: Pagamento',
				customerApproveInstallationEscalation: 'Encaminhamento: Aprovar a instalação',
				customerAddressValidationEscalation: 'Encaminhamento: Validação do endereço',
				customerUncoveredInstallationAddress: 'Endereço da instalação não coberta',
				installationPartnerEvaluateBasicAnswersEscalation: 'Encaminhamento: Respostas básicas',
				installationPartnerExecuteRemotePreCheckEscalation: 'Encaminhamento: Executar a verificação prévia remota',
				installationPartnerRegisterAtGridOperatorEscalation: 'Encaminhamento: Registo no operador de rede',
				installationPartnerProvideGridOperatorDecisionEscalation: 'Encaminhamento: Fornecer ao operador de rede',
				installationPartnerProvideQuotationEscalation: 'Encaminhamento: Fornecer orçamento',
				installationPartnerArrangeAppointmentEscalation: 'Encaminhamento: Efetuar marcações',
				installationPartnerCompleteInstallationEscalation: 'Encaminhamento: Instalação completa'
			},
			status: {
				started: 'Em breve',
				dueToday: 'Prazo para entrega hoje',
				overdue: 'Atrasada',
				escalated: 'Encaminhada',
				done: 'Pronto',
				doneOverdue: 'Pronto',
				doneEscalated: 'Pronto'
			},
			category: {
				customer: 'Cliente',
				installationPartner: 'Instalar. Parceiro'
			}
		},
		pageTitle: {
			tasks: 'Síntese da tarefa',
			orders: 'Síntese da encomenda',
			order: 'Detalhes da encomenda',
			marketplaceOrders: 'Visão geral das ordens do mercado',
			marketplaceOrder: 'Detalhe da ordem do mercado',
			customers: 'Síntese do cliente',
			error: 'Erro',
			notFound: 'Não encontrado',
			inbound: 'Chamada recebida {{caller}}'
		},
		viewHeader: {
			tasks: 'Síntese da tarefa',
			orders: 'Síntese da encomenda',
			marketplaceOrders: 'Síntese das encomendas do mercado',
			customers: 'Síntese do cliente',
			inbound: 'Chamada recebida de {{caller}}'
		},
		modal: {
			task: {
				section: {
					customerInformation: {
						heading: 'Cliente',
						position: 'Posição',
						description: 'Descrição',
						name: 'Nome',
						address: 'Endereço de entrega',
						phone: 'Número de telefone',
						mail: 'E-mail'
					},
					installationEffortInformation: {
						heading: 'Esforço de instalação',
						trade: 'Trabalho',
						effort: 'Esforço',
						hours: 'horas'
					},
					installationPartnerInformation: {
						heading: 'Parceiro de instalação',
						position: 'Posição',
						description: 'Descrição',
						name: 'Nome',
						mail: 'E-mail',
						phone: 'Telefone'
					},
					orderInformation: {
						heading: 'Informação da encomenda'
					},
					quotationInformation: {
						heading: 'Orçamento',
						position: 'Posição',
						description: 'Descrição',
						price: 'Preço',
						deltaInstallation: 'Decisão: Instalação Delta',
						grossPrice: 'Soma',
						vat: 'IVA',
						include: 'Inclui'
					},
					taskInformation: {
						heading: 'Tarefa',
						position: 'Posição',
						description: 'Descrição',
						createdAt: 'Criado em',
						dueDate: 'Data de vencimento',
						responsible: 'Responsável',
						openTask: 'Abrir tarefa'
					},
					contactInformation: {
						heading: 'Preferências de contacto'
					},
					gridOperatorInformation: {
						heading: 'Informações sobre o operador de rede'
					},
					payment: {
						accept: 'Orçamento aceite'
					},
					addressValidation: {
						heading: 'Validação do endereço',
						invalidAddressLabel: {
							delivery: 'Endereço de entrega inválido',
							billing: 'Endereço de faturação inválido',
							multiple: 'Endereço de entrega e de faturação inválidos'
						},
						hint: {
							heading: 'Inserir um novo endereço para validação',
							message: 'O novo endereço será novamente validado quando a tarefa for apresentada.'
						},
						error: {
							heading: 'A validação do endereço falhou',
							message: 'Introduza a informação de endereço correta.'
						},
						newAddress: {
							heading: 'Novo morada',
							street: 'Rua e número da porta ',
							zip: 'Código postal',
							city: 'Cidade',
							country: 'País',
							region: 'Região'
						}
					},
					uncoveredInstallationAddress: {
						message: 'O cliente foi informado da anulação da encomenda',
						info: 'Endereço da instalação não coberta',
						infoHeading: 'Informar o cliente',
						infoDescription: 'O cliente deve ser informado de que o endereço de instalação não está coberto e que a instalação é cancelada'
					},
					hint: {
						heading: 'Verificação final',
						unfeasible: 'Decisão: Instalação inviável',
						rejected: 'Rejeitada pelo operador de rede'
					}
				}
			}
		}
	}
};

export default literals;
