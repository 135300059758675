/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	ArrayOfTimestampFieldNames,
	ModelConverter,
	ModelConverterInterface,
	Timestamp,
	TimestampFieldNames
} from '@abb-emobility/shared/domain-model-foundation';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';

import { CustomerServiceCustomerCallReason } from './AnyCustomerServiceCommentModel.enums';

export enum CustomerServiceCommentType {
	ORDER = 'ORDER',
	CUSTOMER = 'CUSTOMER',
	CUSTOMER_CALL = 'CUSTOMER_CALL'
}

export type CustomerServiceCommentAuthorModel = {
	readonly id: ModelPrimaryKey,
	readonly fullname: string,
	readonly mailAddress: string
};

export class CustomerServiceCommentAuthorModelConverter extends ModelConverter<CustomerServiceCommentAuthorModel> {
}

export type CustomerServiceCommentFoundationModel = {
	readonly id: ModelPrimaryKey,
	readonly author: CustomerServiceCommentAuthorModel,
	readonly createdAt: Timestamp,
	readonly comment: string
};

export type CustomerServiceCustomerCallCommentModel = {
	readonly type: CustomerServiceCommentType.CUSTOMER_CALL,
	readonly customerId: ModelPrimaryKey,
	readonly orderId?: ModelPrimaryKey,
	readonly contactReason: CustomerServiceCustomerCallReason,
	readonly contactReasonOther?: string
} & CustomerServiceCommentFoundationModel;

export type CustomerServiceOrderCommentModel = {
	readonly type: CustomerServiceCommentType.ORDER,
	readonly orderId: ModelPrimaryKey,
	readonly customerId?: never
} & CustomerServiceCommentFoundationModel;

export type CustomerServiceCustomerCommentModel = {
	readonly type: CustomerServiceCommentType.CUSTOMER,
	readonly customerId: ModelPrimaryKey,
	readonly orderId?: never
} & CustomerServiceCommentFoundationModel;

export type AnyCustomerServiceCommentModel =
	| CustomerServiceOrderCommentModel
	| CustomerServiceCustomerCommentModel
	| CustomerServiceCustomerCallCommentModel;

export class AnyCustomerServiceCommentModelConverter extends ModelConverter<AnyCustomerServiceCommentModel> {

	override getTimestampFields(): Array<TimestampFieldNames<AnyCustomerServiceCommentModel> | ArrayOfTimestampFieldNames<AnyCustomerServiceCommentModel>> {
		return ['createdAt'];
	}

	override getFieldConverterMapByModel(): Map<keyof AnyCustomerServiceCommentModel, ModelConverterInterface<any>> {
		return new Map<keyof AnyCustomerServiceCommentModel, ModelConverterInterface<any>>([
			['author', new CustomerServiceCommentAuthorModelConverter()]
		]);
	}

}
