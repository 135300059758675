import { AnyCustomerServiceCommentModel } from '@abb-emobility/platform/domain-model';
import { CrudApiClient } from '@abb-emobility/shared/api-integration-foundation';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import { Nullable } from '@abb-emobility/shared/util';

import { CustomerServiceOrderCommentApiClient } from './CustomerServiceOrderCommentApiClient';
import { CustomerServiceOrderCommentApiClientMock } from './CustomerServiceOrderCommentApiClientMock';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CustomerServiceOrderCommentApiClientInterface extends CrudApiClient<AnyCustomerServiceCommentModel> {
}

export class CustomerServiceOrderCommentApiClientFactory {

	private static mockConfigured = false;

	public static create(baseUrl: string, jsonWebToken: Nullable<JsonWebToken> = null): CustomerServiceOrderCommentApiClientInterface {
		if (process.env.NX_PLATFORM_CS_ORDER_COMMENT_API_MOCKED === 'true') {
			const apiClientMock = new CustomerServiceOrderCommentApiClientMock().init(baseUrl, jsonWebToken);
			if (!this.mockConfigured) {
				apiClientMock.configureFetchMock();
				this.mockConfigured = true;
			}
			return apiClientMock;
		}
		return new CustomerServiceOrderCommentApiClient().init(baseUrl, jsonWebToken);
	}

}
