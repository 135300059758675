import React from 'react';

import { SortCriteria, SortDirection } from '@abb-emobility/shared/api-integration-foundation';
import { useFilterCriteriaStorage, useSortCriteriaStorage } from '@abb-emobility/shared/data-provider-util';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { usePageTitle } from '@abb-emobility/shared/react';
import { UiErrorHandler } from '@abb-emobility/shared/ui-error-handler';
import { useBreadcrumbModifier, SpinnerCircle, ViewHeader } from '@abb-emobility/shared/ui-primitive';
import { TaskCollectionDataProvider, taskCollectionStoreName } from '@abb-emobility/usertask/data-provider';
import { AnyTaskCollectionItemModel } from '@abb-emobility/usertask/domain-model';

import { useBuildPageTitle } from '../components/page-title/PageTitle.hook';
import {
	useClearSearchbarContentModifier
} from '../components/searchbar-content/SearchbarContent.hooks';
import {
	TaskCollectionDataAssembler
} from '../components/task-collection/TaskCollectionDataAssembler';
import { createRouteUrl, RoutePath } from '../router/Routes';

export function TasksView() {

	const l10n = useL10n();

	usePageTitle(useBuildPageTitle('platformCustomerServiceApp.pageTitle.tasks'));
	useBreadcrumbModifier([
		{
			label: l10n.translate('platformCustomerServiceApp.breadcrumbs.tasks'),
			link: createRouteUrl(RoutePath.TASKS)
		}
	]);
	useClearSearchbarContentModifier();

	const filterCriteriaStorage = useFilterCriteriaStorage<AnyTaskCollectionItemModel>(taskCollectionStoreName);
	const persistedFilterCriteria = filterCriteriaStorage.readFilterCriteria();

	const sortCriteriaStorage = useSortCriteriaStorage<AnyTaskCollectionItemModel>(taskCollectionStoreName);
	const persistedSortCriteria = sortCriteriaStorage.readSortCriteria();
	const defaultSortCriteria: SortCriteria<AnyTaskCollectionItemModel> = [
		{ property: 'dueDate', direction: SortDirection.ASCENDING },
		{ property: 'priority', direction: SortDirection.ASCENDING }
	];
	const effectiveSortCriteria = persistedSortCriteria ?? defaultSortCriteria;

	return (
		<>
			<ViewHeader heading={l10n.translate('platformCustomerServiceApp.viewHeader.tasks')} />
			<UiErrorHandler>
				<TaskCollectionDataProvider
					pendingComponent={SpinnerCircle}
					filterCriteria={persistedFilterCriteria}
					sortCriteria={effectiveSortCriteria}
					suspense={false}
				>
					<TaskCollectionDataAssembler />
				</TaskCollectionDataProvider>
			</UiErrorHandler>
		</>
	);
}
