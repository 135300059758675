import { CustomerServiceOrderEventCollectionModel } from '@abb-emobility/platform/domain-model';
import { CrudApiClient } from '@abb-emobility/shared/api-integration-foundation';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import { Nullable } from '@abb-emobility/shared/util';

import { CustomerServiceOrderEventCollectionApiClient } from './CustomerServiceOrderEventCollectionApiClient';
import { CustomerServiceOrderEventCollectionApiClientMock } from './CustomerServiceOrderEventCollectionApiClientMock';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CustomerServiceOrderEventCollectionApiClientInterface extends CrudApiClient<CustomerServiceOrderEventCollectionModel> {
}

export class CustomerServiceOrderEventCollectionApiClientFactory {

	private static mockConfigured = false;

	public static create(baseUrl: string, jsonWebToken: Nullable<JsonWebToken> = null): CustomerServiceOrderEventCollectionApiClientInterface {
		if (process.env.NX_PLATFORM_CS_ORDER_EVENT_API_MOCKED === 'true') {
			const apiClientMock = new CustomerServiceOrderEventCollectionApiClientMock().init(baseUrl, jsonWebToken);
			if (!this.mockConfigured) {
				apiClientMock.configureFetchMock();
				this.mockConfigured = true;
			}
			return apiClientMock;
		}
		return new CustomerServiceOrderEventCollectionApiClient().init(baseUrl, jsonWebToken);
	}

}
