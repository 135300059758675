import React, { ReactNode, useEffect } from 'react';

import { TaskAssignError, TaskCompleteError } from '@abb-emobility/shared/business-error';
import { TaskActionStatus } from '@abb-emobility/shared/data-provider-foundation';
import { InstallationDecisionType } from '@abb-emobility/shared/domain-model';
import {
	AuthenticationFailedError,
	AuthenticationRequiredError,
	isValidationError,
	NotFoundError,
	restoreError
} from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	ButtonGhost,
	ButtonSecondary,
	IconIdentifier,
	SuccessFeeback,
	SuccessImageIdentifier,
	ToastLevel,
	useModalDialogue,
	useModalDialogueManager,
	useToastManager
} from '@abb-emobility/shared/ui-primitive';
import { useAnyTaskEntityData } from '@abb-emobility/usertask/data-provider';
import { taskIsCompleted, TaskType } from '@abb-emobility/usertask/domain-model';

import { TaskAcceptQuotationEscalation } from './payload/TaskAcceptQuotationEscalation';
import { TaskArrangeAppointmentEscalation } from './payload/TaskArrangeAppointmentEscalation';
import { TaskBasicQuestionEscalation } from './payload/TaskBasicQuestionEscalation';
import { TaskCompleteInstallationEscalation } from './payload/TaskCompleteInstallationEscalation';
import { TaskEvaluateBasicAnswersEscalation } from './payload/TaskEvaluateBasicAnswersEscalation';
import { TaskExecuteRemotePreCheckEscalation } from './payload/TaskExecuteRemotePreCheckEscalation';
import { TaskGridOperatorRejectionEscalation } from './payload/TaskGridOperatorRejectionEscalation';
import { TaskPerformPaymentEscalation } from './payload/TaskPerformPaymentEscalation';
import { TaskPreCheckUnfeasibleInstallationEscalation } from './payload/TaskPreCheckUnfeasibleInstallationEscalation';
import { TaskPrecheckPreferencesEscalation } from './payload/TaskPrecheckPreferencesEscalation';
import { TaskProvideGridOperatorDecisionEscalation } from './payload/TaskProvideGridOperatorDecisionEscalation';
import { TaskProvideQuotationEscalation } from './payload/TaskProvideQuotationEscalation';
import { TaskRegisterAtGridOperatorEscalation } from './payload/TaskRegisterAtGridOperatorEscalation';
import { TaskUncoveredInstallationAddress } from './payload/TaskUncoveredInstallationAddress';
import { TaskAddressValidation } from './payload/task-address-validation/TaskAddressValidation';
import { TaskCollectionItemHeader } from '../task-collection-item/TaskCollectionItemHeader';
import { TaskSearchModalAssembler } from '../task-modal/TaskSearchModalAssembler';

export function TaskFactory() {

	const l10n = useL10n();
	const toast = useToastManager();
	const modalDialogue = useModalDialogue();
	const modalDialogueManager = useModalDialogueManager();

	const anyTaskData = useAnyTaskEntityData();
	const task = anyTaskData.query().getOrThrow(new NotFoundError(l10n.translate('platformCustomerServiceApp.task.error.taskNotFound')));
	const actionErrorObject = anyTaskData.queryActionError();

	anyTaskData.useActionStatusEffect([TaskActionStatus.ASSIGN_FAILED], () => {
		if (actionErrorObject === null) {
			return;
		}
		const error = restoreError(actionErrorObject);
		if (error instanceof AuthenticationFailedError || error instanceof AuthenticationRequiredError) {
			throw error;
		}
		throw new TaskAssignError(l10n.translate('platformCustomerServiceApp.error.taskAssignMessage'), undefined, undefined, error);
	}, true);

	anyTaskData.useActionStatusEffect([TaskActionStatus.COMPLETE_FAILED], () => {
		if (actionErrorObject === null || isValidationError(actionErrorObject)) {
			return;
		}
		const error = restoreError(actionErrorObject);
		if (error instanceof AuthenticationFailedError || error instanceof AuthenticationRequiredError) {
			throw error;
		}
		throw new TaskCompleteError(l10n.translate('platformCustomerServiceApp.error.taskCompleteMessage'), undefined, undefined, error);
	}, true);

	anyTaskData.useActionStatusEffect([TaskActionStatus.ASSIGN_SUCCESS], () => {
		toast.addToastEntry({
			label: l10n.translate('platformCustomerServiceApp.task.assign.successMessage'),
			level: ToastLevel.SUCCESS,
			autoDismiss: true
		});
	}, true);

	const handleDismissModal = () => {
		modalDialogueManager.pop();
	};

	useEffect(() => {
		modalDialogue.setHeader(
			<TaskCollectionItemHeader
				task={task}
				icon={IconIdentifier.X}
				onClick={handleDismissModal}
				disabled={taskIsCompleted(task.status)}
			/>
		);
		return () => {
			modalDialogue.unsetHeader();
		};
	}, [task]);

	const renderTaskCompletionFeedback = (): ReactNode => {
		if (!taskIsCompleted(task.status)) {
			return null;
		}

		let continueButton: ReactNode = null;
		switch (task.type) {
			case TaskType.INSTALLATION_PARTNER_REGISTER_AT_GRID_OPERATOR:
				if (!task.payload.registrationUnnecessary) {
					const continueToGridOperatorFeedback = (): void => {
						modalDialogueManager.replace({
							content: (): ReactNode => {
								return (
									<TaskSearchModalAssembler
										orderId={task.orderId}
										taskType={TaskType.INSTALLATION_PARTNER_PROVIDE_GRID_OPERATOR_DECISION}
									/>
								);
							}
						});
					};
					continueButton = (
						<ButtonSecondary
							label={l10n.translate('platformCustomerServiceApp.task.gridOperatorRegistration.continueToFeedback.buttonLabel')}
							onClick={continueToGridOperatorFeedback}
						/>
					);
				}
				break;
			case TaskType.INSTALLATION_PARTNER_EVALUATE_BASIC_ANSWERS:
			case TaskType.INSTALLATION_PARTNER_EXECUTE_REMOTE_PRE_CHECK:
				if (task.payload.installationType === InstallationDecisionType.DELTA) {
					const continueToQuotation = (): void => {
						modalDialogueManager.replace({
							content: (): ReactNode => {
								return (
									<TaskSearchModalAssembler
										orderId={task.orderId}
										taskType={TaskType.INSTALLATION_PARTNER_PROVIDE_QUOTATION}
									/>
								);
							}
						});
					};
					continueButton = (
						<ButtonSecondary
							label={l10n.translate('platformCustomerServiceApp.task.basicAnswers.continueToQuotation.buttonLabel')}
							onClick={continueToQuotation}
						/>
					);
				}
				break;
		}

		return (
			<SuccessFeeback
				heading={l10n.translate('platformCustomerServiceApp.task.completion.successHeading')}
				image={SuccessImageIdentifier.IMAGE4}
			>
				{continueButton}
				<ButtonGhost label={l10n.translate('platformCustomerServiceApp.task.completion.closeButtonLabel')} onClick={handleDismissModal} />
			</SuccessFeeback>
		);
	};

	const renderTask = () => {
		if (taskIsCompleted(task.status)) {
			return;
		}
		switch (task.type) {
			case TaskType.CUSTOMER_ANSWER_BASIC_QUESTIONS_ESCALATION:
				return <TaskBasicQuestionEscalation task={task} />;
			case TaskType.CUSTOMER_PROVIDE_PRE_CHECK_PREFERENCES_ESCALATION:
				return <TaskPrecheckPreferencesEscalation task={task} />;
			case TaskType.CUSTOMER_HANDLE_UNFEASIBLE_INSTALLATION_ESCALATION:
				return <TaskPreCheckUnfeasibleInstallationEscalation task={task} />;
			case TaskType.CUSTOMER_HANDLE_GRID_OPERATOR_REJECTION_ESCALATION:
				return <TaskGridOperatorRejectionEscalation task={task} />;
			case TaskType.CUSTOMER_ACCEPT_QUOTATION_ESCALATION:
				return <TaskAcceptQuotationEscalation task={task} />;
			case TaskType.CUSTOMER_PERFORM_PAYMENT_ESCALATION:
				return <TaskPerformPaymentEscalation task={task} />;
			case TaskType.CUSTOMER_ADDRESS_VALIDATION_ESCALATION:
				return <TaskAddressValidation task={task} />;
			case TaskType.CUSTOMER_UNCOVERED_INSTALLATION_ADDRESS:
				return <TaskUncoveredInstallationAddress task={task} />;
			case TaskType.INSTALLATION_PARTNER_EVALUATE_BASIC_ANSWERS_ESCALATION:
				return <TaskEvaluateBasicAnswersEscalation task={task} />;
			case TaskType.INSTALLATION_PARTNER_EXECUTE_REMOTE_PRE_CHECK_ESCALATION:
				return <TaskExecuteRemotePreCheckEscalation task={task} />;
			case TaskType.INSTALLATION_PARTNER_REGISTER_AT_GRID_OPERATOR_ESCALATION:
				return <TaskRegisterAtGridOperatorEscalation task={task} />;
			case TaskType.INSTALLATION_PARTNER_PROVIDE_GRID_OPERATOR_DECISION_ESCALATION:
				return <TaskProvideGridOperatorDecisionEscalation task={task} />;
			case TaskType.INSTALLATION_PARTNER_PROVIDE_QUOTATION_ESCALATION:
				return <TaskProvideQuotationEscalation task={task} />;
			case TaskType.INSTALLATION_PARTNER_ARRANGE_APPOINTMENT_ESCALATION:
				return <TaskArrangeAppointmentEscalation task={task} />;
			case TaskType.INSTALLATION_PARTNER_COMPLETE_INSTALLATION_ESCALATION:
				return <TaskCompleteInstallationEscalation task={task} />;
			default:
				throw new NotFoundError(l10n.translate('platformCustomerServiceApp.task.error.taskTypeNotFound', new Map([['type', task.type]])));
		}
	};

	return (
		<>
			{renderTaskCompletionFeedback()}
			{renderTask()}
		</>
	);

}
