/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	AccessDeniedErrorObject,
	ApiErrorObject,
	AuthenticationFailedErrorObject,
	AuthenticationRequiredErrorObject,
	ErrorObject,
	NetworkErrorObject,
	NotFoundErrorObject,
	StoreErrorObject,
	TimeoutErrorObject, ValidationErrorObject, WebSocketErrorObject, TooEarlyErrorObject
} from './ErrorObject';

export const isAppErrorObject = (errorObject: any): errorObject is ErrorObject => {
	return (errorObject as ErrorObject)?.discriminator === 'AppError';
};

export const isStoreError = (error: any): error is StoreErrorObject => {
	return (error as StoreErrorObject)?.discriminator === 'StoreError';
};

export const isNotFoundError = (error: any): error is NotFoundErrorObject => {
	return (error as NotFoundErrorObject)?.discriminator === 'NotFoundError';
};

export const isTooEarlyError = (error: any): error is TooEarlyErrorObject => {
	return (error as TooEarlyErrorObject)?.discriminator === 'TooEarlyError';
};

export const isTimeoutError = (error: any): error is TimeoutErrorObject => {
	return (error as TimeoutErrorObject)?.discriminator === 'TimeoutError';
};

export const isNetworkError = (error: any): error is NetworkErrorObject => {
	return (error as NetworkErrorObject)?.discriminator === 'NetworkError';
};

export const isApiError = (error: any): error is ApiErrorObject => {
	return (error as ApiErrorObject)?.discriminator === 'ApiError';
};

export const isValidationError = (error: any): error is ValidationErrorObject => {
	return (error as ValidationErrorObject)?.discriminator === 'ValidationError';
};

export const isAuthenticationRequiredError = (error: any): error is AuthenticationRequiredErrorObject => {
	return (error as AuthenticationRequiredErrorObject)?.discriminator === 'AuthenticationRequiredError';
};

export const isAuthenticationFailedError = (error: any): error is AuthenticationFailedErrorObject => {
	return (error as AuthenticationFailedErrorObject)?.discriminator === 'AuthenticationFailedError';
};

export const isAccessDeniedError = (error: any): error is AccessDeniedErrorObject => {
	return (error as AccessDeniedErrorObject)?.discriminator === 'AccessDeniedError';
};

export const isWebSocketError = (error: any): error is WebSocketErrorObject => {
	return (error as WebSocketErrorObject)?.discriminator === 'WebSocketError';
};
