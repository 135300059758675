import { CustomerServiceOrderEventPaymentConfirmationMailSentDetailModel } from '@abb-emobility/platform/domain-model';
import { Dto } from '@abb-emobility/shared/domain-model-foundation';

import {
	customerServiceOrderPaymentModelCollectionMock
} from '../CustomerServiceOrderPaymentModel.mock';
import {
	customerServiceOrderInformationQuotationInformationModelMock
} from '../order-information/CustomerServiceOrderInformationQuotationInformationModel.mock';

export const customerServiceOrderEventPaymentConfirmationMailSentDetailMock: Dto<CustomerServiceOrderEventPaymentConfirmationMailSentDetailModel> = {
	mailAddress: 'lisa-marie.kloster@fiege.com',
	quotation: customerServiceOrderInformationQuotationInformationModelMock,
	paymentData: customerServiceOrderPaymentModelCollectionMock
};
