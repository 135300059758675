import {
	CustomerServiceOrderInformationType,
	CustomerServiceOrderInformationApprovalModel,
	CustomerServiceOrderInformationBasicAnswersModel,
	CustomerServiceOrderInformationGridOperatorDecisionModel,
	CustomerServiceOrderInformationGridOperatorRegistrationModel,
	CustomerServiceOrderInformationPrecheckModel,
	CustomerServiceOrderInformationQuotationModel
} from '@abb-emobility/platform/domain-model';
import { taskCompletionCommentModelMock } from '@abb-emobility/shared/api-integration';
import { Dto } from '@abb-emobility/shared/domain-model-foundation';

import {
	customerServiceOrderInformationApprovalInformationModelMock
} from './order-information/CustomerServiceOrderInformationApprovalInformationModel.mock';
import {
	customerServiceOrderInformationBasicAnswersInformationModelMock
} from './order-information/CustomerServiceOrderInformationBasicAnswersInformationModel.mock';
import {
	customerServiceOrderInformationGridOperatorDecisionInformationModel
} from './order-information/CustomerServiceOrderInformationGridOperatorDecisionInformationModel.mock';
import {
	customerServiceOrderInformationGridOperatorRegistrationInformationModelMock
} from './order-information/CustomerServiceOrderInformationGridOperatorRegistrationInformationModel.mock';
import {
	customerServiceOrderInformationPrecheckInformationModelMock
} from './order-information/CustomerServiceOrderInformationPrecheckInformationModel.mock';
import {
	customerServiceOrderInformationQuotationInformationModelMock
} from './order-information/CustomerServiceOrderInformationQuotationInformationModel.mock';

export const customerServiceOrderInformationBasicAnswersModelMock = (): Dto<CustomerServiceOrderInformationBasicAnswersModel> => {
	return {
		id: '1',
		orderId: '1',
		type: CustomerServiceOrderInformationType.BASIC_ANSWERS,
		createdAt: '2022-01-01T16:30:12.345Z',
		payload: customerServiceOrderInformationBasicAnswersInformationModelMock(),
		comment: taskCompletionCommentModelMock
	};
};

export const customerServiceOrderInformationGridOperatorRegistrationModelMock: Dto<CustomerServiceOrderInformationGridOperatorRegistrationModel> = {
	id: '2',
	orderId: '1',
	type: CustomerServiceOrderInformationType.GRID_OPERATOR_REGISTRATION,
	createdAt: '2022-01-01T16:30:12.345Z',
	payload: customerServiceOrderInformationGridOperatorRegistrationInformationModelMock,
	comment: taskCompletionCommentModelMock
};

export const customerServiceOrderInformationGridOperatorDecisionModelMock: Dto<CustomerServiceOrderInformationGridOperatorDecisionModel> = {
	id: '3',
	orderId: '1',
	type: CustomerServiceOrderInformationType.GRID_OPERATOR_DECISION,
	createdAt: '2022-01-01T16:30:12.345Z',
	payload: customerServiceOrderInformationGridOperatorDecisionInformationModel,
	comment: taskCompletionCommentModelMock
};

export const customerServiceOrderInformationPrecheckModelMock: Dto<CustomerServiceOrderInformationPrecheckModel> = {
	id: '4',
	orderId: '1',
	type: CustomerServiceOrderInformationType.PRE_CHECK,
	createdAt: '2022-01-01T16:30:12.345Z',
	payload: customerServiceOrderInformationPrecheckInformationModelMock,
	comment: taskCompletionCommentModelMock
};

export const customerServiceOrderInformationQuotationModelMock: Dto<CustomerServiceOrderInformationQuotationModel> = {
	id: '5',
	orderId: '1',
	type: CustomerServiceOrderInformationType.OFFER,
	createdAt: '2022-01-01T16:30:12.345Z',
	payload: customerServiceOrderInformationQuotationInformationModelMock,
	comment: taskCompletionCommentModelMock
};

export const customerServiceOrderInformationApprovalModelMock: Dto<CustomerServiceOrderInformationApprovalModel> = {
	id: '6',
	orderId: '1',
	type: CustomerServiceOrderInformationType.APPROVAL,
	createdAt: '2022-01-01T16:30:12.345Z',
	payload: customerServiceOrderInformationApprovalInformationModelMock,
	comment: taskCompletionCommentModelMock
};
