import React, { ForwardedRef } from 'react';

import { IsoCountryCode } from '@abb-emobility/shared/iso-country-code';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	Hint,
	HintLevel,
	IconIdentifier,
	InputSelect,
	InputSelectOption,
	InputText,
	SectionHeader,
	TabularInformationItem,
	TabularInformationItemType,
	TabularInformationRow

} from '@abb-emobility/shared/ui-primitive';
import { l10nLiteralFromEnumValue, Nullable } from '@abb-emobility/shared/util';

import { AddressStateModel } from './TaskAddressValidation';

import './AddressForm.scss';

export type UpdateAddressFormProps = {
	errorOccured: boolean,
	invalidAddress: AddressStateModel,
	initialAddress: AddressStateModel,
	handleAddressChange: (updatedAddress: AddressStateModel) => void
};

export const UpdateAddressForm = React.forwardRef(function UpdateAddressForm(props: UpdateAddressFormProps, ref: ForwardedRef<Nullable<HTMLFormElement>>) {
	const { errorOccured, invalidAddress, handleAddressChange, initialAddress } = props;

	const l10n = useL10n();

	const addressUsage = initialAddress.usage.length === 1 ? initialAddress.usage[0] : 'MULTIPLE';

	let invalidAddressString = `${initialAddress.address.street}, ${initialAddress.address.zip} ${initialAddress.address.city},
								${initialAddress.address.countryCode}`;
	if (initialAddress.address.region !== undefined) {
		invalidAddressString += `, ${initialAddress.address.region}`;
	}

	const options: Array<InputSelectOption<IsoCountryCode>> = Object.values(IsoCountryCode)
		.map((value) => (
				{
					label: value.toUpperCase(),
					value: value
				}
			)
		);

	const handleStreetChange = (value: string) => {
		handleAddressChange({
			...invalidAddress,
			address: { ...invalidAddress.address, street: value }
		});
	};

	const handleZipChange = (value: string) => {
		handleAddressChange({
			...invalidAddress,
			address: { ...invalidAddress.address, zip: value }
		});
	};

	const handleCityChange = (value: string) => {
		handleAddressChange({
			...invalidAddress,
			address: { ...invalidAddress.address, city: value }
		});
	};

	const handleCountryChange = (value: string) => {
		handleAddressChange({
			...invalidAddress,
			address: { ...invalidAddress.address, countryCode: value as IsoCountryCode }
		});
	};
	const handleRegionChange = (value: string) => {
		handleAddressChange({
			...invalidAddress,
			address: { ...invalidAddress.address, region: value }
		});
	};

	const renderRegion = () => {
		const region = invalidAddress.address.region ?? null;

		if (region === null) {
			return null;
		}

		return (
			<InputText
				label={l10n.translate('platformCustomerServiceApp.modal.task.section.addressValidation.newAddress.region')}
				value={invalidAddress.address.region}
				onChange={handleRegionChange}
			/>
		);
	};

	const renderHint = () => {
		if (errorOccured) {
			return (
				<div ref={undefined}>
					<Hint
						gray={true}
						heading={l10n.translate('platformCustomerServiceApp.modal.task.section.addressValidation.error.heading')}
						message={l10n.translate('platformCustomerServiceApp.modal.task.section.addressValidation.error.message')}
						icon={IconIdentifier.X}
						level={HintLevel.ERROR}
					/>
				</div>
			);
		}

		return (
			<Hint
				gray={true}
				heading={l10n.translate('platformCustomerServiceApp.modal.task.section.addressValidation.hint.heading')}
				message={l10n.translate('platformCustomerServiceApp.modal.task.section.addressValidation.hint.message')}
				icon={IconIdentifier.INFO}
				level={HintLevel.INFO}
			/>
		);

	};
	return (
		<>
			<TabularInformationRow>
				<TabularInformationItem
					label={l10n.translate(l10nLiteralFromEnumValue(
						addressUsage,
						'platformCustomerServiceApp.modal.task.section.addressValidation.invalidAddressLabel'
					))}
					variant={TabularInformationItemType.GREY}
				/>
				<TabularInformationItem label={invalidAddressString} />
			</TabularInformationRow>

			<form className="address-form" ref={ref}>
				<SectionHeader heading={l10n.translate('platformCustomerServiceApp.modal.task.section.addressValidation.newAddress.heading')} />

				{renderHint()}

				<div className={'address-form__input-row'}>
					<InputText
						label={l10n.translate('platformCustomerServiceApp.modal.task.section.addressValidation.newAddress.street')}
						value={invalidAddress.address.street}
						onChange={handleStreetChange}
					/>
				</div>

				<div className={'address-form__input-row'}>
					<InputText
						label={l10n.translate('platformCustomerServiceApp.modal.task.section.addressValidation.newAddress.zip')}
						value={invalidAddress.address.zip}
						onChange={handleZipChange}
					/>
					<InputText
						label={l10n.translate('platformCustomerServiceApp.modal.task.section.addressValidation.newAddress.city')}
						value={invalidAddress.address.city}
						onChange={handleCityChange}
					/>
				</div>

				<div className={'address-form__input-row'}>
					<InputSelect<IsoCountryCode>
						label={l10n.translate('platformCustomerServiceApp.modal.task.section.addressValidation.newAddress.country')}
						value={invalidAddress.address.countryCode as IsoCountryCode}
						options={options}
						onSelect={handleCountryChange}
					/>

					{renderRegion()}
				</div>
			</form>
		</>
	);
});
