import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	platformCustomerServiceApp: {
		action: {
			showCustomer: 'Kunde anzeigen'
		},
		actionItemFactory: {
			addCustomerNoteLabel: 'Notiz anfügen',
			addOrderNoteLabel: 'Notiz anfügen',
			cancelOrderLabel: 'Stornieren',
			cancelInstallationLabel: 'Installation stornieren',
			cancelOrderAppointmentsLabel: 'Termine stornieren',
			externalLinkLabel: 'Externer Link',
			resendMailLabel: 'Erneut senden',
			returnOrderLabel: 'Retoure',
			replaceOrderLabel: 'Ersatzlieferung',
			updateCustomerLabel: 'Kundendaten ändern',
			updateOrderLabel: 'Bestelldaten ändern'
		},
		actionModalFactory: {
			addCustomerNoteHeading: 'Notiz anfügen',
			addOrderNoteHeading: 'Notiz anfügen',
			cancelOrderHeading: 'Bestellung stornieren',
			cancelInstallationHeading: 'Installation stornieren',
			cancelOrderAppointmentsHeading: 'Termine stornieren',
			resendMailHeading: 'Erneut senden',
			returnOrderHeading: 'Retoure',
			replaceOrderHeading: 'Ersatzlieferung',
			updateCustomerHeading: 'Kundendaten ändern',
			updateOrderHeading: 'Bestelldaten ändern'
		},
		actionModal: {
			pleaseSelect: 'Bitte wählen',
			addNote: {
				content: {
					category: 'Kategorie',
					categories: {
						order: 'Bestellnotiz',
						customer: 'Kunden-Notiz',
						customerCall: 'Kundenanruf'
					}
				}
			},
			addOrderNote: {
				content: {
					noteHeading: 'Notiz',
					notePlaceholder: 'Text einfügen',
					successHeading: 'Bestellnotiz erfolgreich hinzugefügt',
					closeButtonLabel: 'Schließen'
				},
				footer: {
					confirm: 'Bestätigen',
					cancel: 'Abbrechen'
				},
				error: {
					heading: 'Hinzufügen fehlgeschlagen',
					message: 'Beim Hinzufügen der Bestellnotiz ist ein Fehler aufgetreten.'
				}
			},
			addCustomerNote: {
				content: {
					noteHeading: 'Notiz',
					notePlaceholder: 'Text einfügen',
					successHeading: 'Kunden-Notiz erfolgreich hinzugefügt',
					closeButtonLabel: 'Schließen'
				},
				footer: {
					confirm: 'Bestätigen',
					cancel: 'Abbrechen'
				},
				error: {
					heading: 'Hinzufügen fehlgeschlagen',
					message: 'Beim Hinzufügen der Kunden-Notiz ist ein Fehler aufgetreten.'
				}
			},
			addCustomerCallNote: {
				content: {
					noteHeading: 'Notiz',
					notePlaceholder: 'Text einfügen',
					customerCallReason: 'Anrufgrund',
					callReasonOptions: {
						afterSales: 'Reklamationen',
						product: 'Produkt',
						installation: 'Installation',
						preCheck: 'Pre-Check',
						payment: 'Zahlung',
						dataProtection: 'Datenschutz',
						shipment: 'Versand',
						cancellation: 'Retoure',
						falseRouting: 'False Routing',
						other: 'Anderer',
						complaints: 'Beschwerden',
						returns: 'Retouren'
					},
					callReasonNotePlaceholder: 'Beschreibung',
					successHeading: 'Notiz zum Kundenanruf hinzugefügt',
					closeButtonLabel: 'Schließen'
				},
				footer: {
					confirm: 'Bestätigen',
					cancel: 'Abbrechen'
				},
				error: {
					heading: 'Hinzufügen fehlgeschlagen',
					message: 'Beim Hinzufügen der Notiz zum Kundenanruf ist ein Fehler aufgetreten.'
				}
			},
			cancelOrder: {
				heading: 'Bestellung',
				position: 'Position',
				description: 'Beschreibung',
				reason: 'Grund',
				note: 'Beschreibung',
				cancelReasonValue: {
					other: 'Anderer',
					wallboxBroken: 'Wallbox defekt',
					wallboxNotWorking: 'Wallbox nicht funktionstüchtig',
					withdrawal: 'Rücktritt vom Kauf',
					installationNotPossible: 'Installation nicht durchführbar'
				},
				footer: {
					cancel: 'Abbrechen',
					confirm: 'Bestätigen'
				},
				content: {
					successHeading: 'Bestell-Stornierung erfolgreich',
					closeButtonLabel: 'Schließen'
				},
				error: {
					loading: {
						heading: 'Bestelldaten konnte nicht geladen werden',
						message: 'Während des Ladens ist ein Fehler aufgetreten.'
					},
					writing: {
						heading: 'Bestellung konnte nicht storniert werden',
						message: 'Während der Stornierung ist ein Fehler aufgetreten.'
					}
				}
			},
			cancelInstallation: {
				heading: 'Bestellung',
				position: 'Position',
				description: 'Beschreibung',
				reason: 'Grund',
				note: 'Beschreibung',
				cancelReasonValue: {
					other: 'Anderer',
					wallboxBroken: 'Wallbox defekt',
					wallboxNotWorking: 'Wallbox nicht funktionstüchtig',
					withdrawal: 'Rücktritt vom Kauf',
					installationNotPossible: 'Installation nicht durchführbar'
				},
				footer: {
					cancel: 'Abbrechen',
					confirm: 'Bestätigen'
				},
				content: {
					successHeading: 'Stornierung der Installation erfolgreich',
					closeButtonLabel: 'Schließen'
				},
				error: {
					loading: {
						heading: 'Bestelldaten konnte nicht geladen werden',
						message: 'Während des Ladens ist ein Fehler aufgetreten.'
					},
					writing: {
						heading: 'Installation konnte nicht storniert werden',
						message: 'Während der Stornierung der Installation ist ein Fehler aufgetreten.'
					}
				}
			},
			cancelOrderAppointments: {
				content: {
					notificationHeading: 'Der Installationspartner wird über die Stornierung in Kenntnis gesetzt',
					notificationMessage: 'Der Installationspartner wird sich mit dem Kunden in Verbindung setzen, um einen weiteren Installationstermin zu vereinbaren. ',
					successHeading: 'Termine storniert',
					successCloseButtonLabel: 'Schließen',
					reason: 'Grund'
				},
				cancelReasonValue: {
					deliveryDelay: 'Lieferverzögerung',
					installationPartnerConflict: 'Terminkonflikt beim Installationspartner',
					customerConflict: 'Terminkonflikt beim Kunden',
					replacement: 'Ersatzlieferung erforderlich'
				},
				footer: {
					confirm: 'Stornierung bestätigen',
					cancel: 'Abbrechen'
				},
				error: {
					heading: 'Terminstornierung fehlgeschlagen',
					message: 'Während der Terminstornierung ist ein Fehler aufgetreten.'
				}
			},
			resendMail: {
				content: {
					notificationHeading: 'Erneutes Senden initiiert',
					notificationMessage: 'Der Kunde erhält die E-Mail ein weiteres mal',
					successHeading: 'Senden erfolgreich',
					successCloseButtonLabel: 'Schließen'
				},
				footer: {
					confirm: 'Erneut senden',
					cancel: 'Abbrechen'
				},
				error: {
					heading: 'Erneutes Senden fehlgeschlagen',
					message: 'Während des Sendens ist ein Fehler aufgetreten.'
				}
			},
			returnOrder: {
				heading: 'Bestellung',
				position: 'Position',
				description: 'Beschreibung',
				note: 'Beschreibung',
				return: 'Retoure',
				selectReturn: 'Zu stornierende Bestellpositionen auswählen',
				selectReason: 'Stornierungsgrund',
				replacementItem: 'Ersatzlieferungspositionen',
				yes: 'Ja',
				no: 'Nein',
				returnReason: {
					other: 'Anderer',
					broken: 'Defekt'
				},
				footer: {
					cancel: 'Abbrechen',
					confirm: 'Bestätigen'
				},
				content: {
					successHeading: 'Retourenprozess gestartet',
					closeButtonLabel: 'Schließen'
				},
				error: {
					loading: {
						heading: 'Bestelldaten konnte nicht geladen werden',
						message: 'Während des Ladens ist ein Fehler aufgetreten.'
					},
					writing: {
						heading: 'Retourenprozess konnte nicht gestartet werden',
						message: 'Während der Anfrage ist ein Fehler aufgetreten.'
					}
				}
			},
			replaceOrder: {
				heading: 'Bestellung',
				position: 'Position',
				description: 'Beschreibung',
				note: 'Beschreibung',
				return: 'Ersatzlieferung',
				selectReturn: 'Retourenposition wählen',
				selectReason: 'Grund wählen',
				returnReason: {
					other: 'Anderer',
					broken: 'Defekt'
				},
				footer: {
					cancel: 'Abbrechen',
					confirm: 'Bestätigen'
				},
				content: {
					successHeading: 'Ersatzlieferungs-Prozess gestartet',
					closeButtonLabel: 'Schließen'
				},
				error: {
					loading: {
						heading: 'Bestelldaten konnte nicht geladen werden',
						message: 'Während des Ladens ist ein Fehler aufgetreten.'
					},
					writing: {
						heading: 'Der Ersatzlieferungs-Prozess konnte nicht gestartet werden.',
						message: 'Während des Startens ist ein Fehler aufgetreten.'
					}
				}
			},
			updateCustomer: {
				content: {
					firstname: 'Vorname',
					lastname: 'Nachname',
					email: 'E-Mail',
					personalInfoHeading: 'Personal information',
					contactDataHeading: 'Contact data',
					successHeading: 'User information successfully changed.',
					successCloseButtonLabel: 'Schließen'
				},
				footer: {
					confirm: 'Bestätigen',
					cancel: 'Abbrechen'
				},
				error: {
					loading: {
						heading: 'Could not load customer information.',
						message: 'An Error occured while loading the customer information.'
					},
					writing: {
						heading: 'Could not update customer information.',
						message: 'An Error occured while updating the customer infromation.'
					}
				}
			},
			updateOrder: {
				content: {
					orderInformationHeading: 'Bestellung information',
					phoneLabel: 'Telefon',
					languageHeading: 'Sprache',
					languageLabel: ' Sprache Code',
					successHeading: 'Bestellung information successfully changed.',
					successCloseButtonLabel: 'Schließen'
				},
				footer: {
					confirm: 'Bestätigen',
					cancel: 'Abbrechen'
				},
				error: {
					loading: {
						heading: 'Could not load order information.',
						message: 'An Error occured while loading the order information.'
					},
					writing: {
						heading: 'Could not update order information.',
						message: 'An Error occured while updating the order information.'
					}
				}
			}
		},
		appName: 'ABB customer service',
		breadcrumbs: {
			tasks: 'Aufgabenübersicht',
			orders: 'Bestellübersicht',
			marketplaceOrders: 'Marketplace Bestellübersicht',
			customers: 'Kundenübersicht'
		},
		comment: {
			categories: {
				order: 'Bestellnotiz',
				customer: 'Kunden-Notiz',
				customerCall: 'Kundenanruf'
			},
			callReason: {
				afterSales: 'Reklamationen',
				product: 'Produkt',
				installation: 'Installation',
				preCheck: 'Pre-Check',
				payment: 'Zahlung',
				dataProtection: 'Datenschutz',
				shipment: 'Versand',
				cancellation: 'Retoure',
				falseRouting: 'False Routing',
				other: 'Anderer',
				complaints: 'Beschwerden',
				returns: 'Retouren'
			}
		},
		commentCollection: {
			heading: 'Notizen',
			emptyState: {
				heading: 'Keine Kommentare',
				message: 'Es sind momentan keine Kommentare verfügbar.'
			}
		},
		common: {
			tableHeader: {
				position: 'Position',
				description: 'Beschreibung'
			}

		},
		customerCollection: {
			heading: 'Kunden',
			tableHead: {
				customer: 'Kunde',
				customerId: 'Kundennummer',
				firstOrder: 'Erste Bestellung',
				lastOrder: 'Letzte Bestellung'
			},
			emptyState: {
				heading: 'Keine Kunden gefunden',
				message: 'Passen sie ihre Filtereinstellungen an, um Kunden zu finden.'
			},
			action: {
				showMore: 'Mehr anzeigen'
			}
		},
		customerInformation: {
			heading: 'Kunden-Information',
			tableHeader: {
				position: 'Position',
				description: 'Beschreibung'
			},
			tableItem: {
				firstName: 'Vorname',
				lastName: 'Nachname',
				companyName: 'Firmenname',
				mail: 'E-Mail',
				firstOrder: 'Erste Bestellung',
				lastOrder: 'Letzte Bestellung'
			}
		},
		customerOrders: {
			heading: 'Kunden-Bestellung'
		},
		customerMarketplaceOrders: {
			heading: 'Marketplace Bestellung'
		},
		customerDetail: {
			subheader: {
				customerId: 'Kundennummer'
			},
			tabItems: {
				overview: 'Übersicht',
				comments: 'Notizen'
			}
		},
		error: {
			generic: {
				heading: 'Etwas ist schiegegangen',
				message: 'Ein unvorhergesehener Fehler ist aufgetreten.',
				resolveAction: {
					label: 'Neu laden'
				}
			},
			offline: {
				heading: 'Netzwerkverbindung nicht verfügbar',
				message: 'Bitte verbinden Sie sich mit einem Netzwerk, um die Anwendung nutzen zu können.'
			},
			notFound: {
				heading: 'Nicht gefunden',
				message: 'Die angefragte Resource konnte nicht gefunden werden. ',
				resolveAction: {
					label: 'Meine Aufgaben'
				}
			},
			customerNotFound: 'Der Kunde konnte nicht gefunden werden',
			orderNotFound: 'Die Bestellung wurde nicht gefunden',
			taskNotFound: 'Die Aufgabe wurde nicht gefunden',
			callerNotFound: 'Die Anrufinformationen wurde nicht gefunden',
			taskAssignMessage: 'Während der Zuweisung der Aufgabe ist ein Fehler aufgetreten',
			taskCompleteMessage: 'Während der Erledigung der Aufgabe ist ein Fehler aufgetreten'
		},
		navigation: {
			tasks: 'Meine Aufgaben',
			orders: 'Bestellungen',
			marketplaceOrders: 'Marketplace Bestellungen',
			customers: 'Kunden',
			logout: 'Abmelden'
		},
		order: {
			installationType: {
				undetermined: 'Nicht ermittelt',
				standard: 'Standard',
				delta: 'Delta',
				cancelled: 'Storniert'
			}
		},
		orderDetail: {
			subheader: {
				orderDate: 'Bestelldatum',
				orderId: 'Bestellnummer',
				marketplace: 'Marktplatz',
				plannedDelivery: 'Geplante Lieferung',
				orderState: {
					onSchedule: 'Im Zeitplan',
					delayed: 'Verzögert',
					cancelPending: 'Wird abgebrochen',
					cancelled: 'Abgebrochen',
					imported: 'Importiert',
					validationFailed: 'Ungültig',
					done: 'Abgeschlossen'
				}
			},
			tabItems: {
				overview: 'Übersicht',
				installation: 'Installation',
				delivery: 'Lieferung',
				accounting: 'Buchhaltung',
				files: 'Dateien',
				comments: 'Notizen',
				tasks: 'Aufgaben'
			},
			overview: {
				customerInformation: {
					heading: 'Kunden-Information'
				},
				orderInformation: {
					heading: 'Bestell-Information',
					tableHeader: {
						position: 'Position',
						description: 'Beschreibung'
					},
					tableItem: {
						language: 'Sprache',
						phone: 'Telefon',
						deliveryAddress: 'Adresse'
					}
				},
				timeline: {
					heading: 'Historie'
				}
			},
			installation: {
				cancellation: {
					notification: {
						heading: 'Die Installation wurde storniert',
						message: 'Die Stornierung wurde am {{cancellationDate}} durchgeführt. Als Grund wurde "{{cancellationReason}}" angegeben.'
					}
				},
				information: {
					heading: 'Informationen',
					types: {
						basicAnswers: 'Check',
						gridOperatorRegistration: 'Registrierung Netzbetreiber',
						gridOperatorDecision: 'Rückmeldung Netzbetreiber',
						preCheck: 'PreCheck',
						offer: 'Angebot',
						approval: 'Abnahme'
					},
					emptyState: {
						heading: 'Keine Bestell-Informationen verfügbar',
						message: 'Bestell-Informationen zur Zeit nicht verfügbar'
					}
				},
				installationAppointments: {
					heading: 'Geplante Installation',
					installer: 'Installateur',
					appointment: 'Termin',
					phone: 'Telefon',
					mail: 'E-Mail',
					emptyState: {
						heading: 'Keine Termine gefunden',
						message: 'Zur Zeit liegen keine geplanten Termine vor.'
					}
				}
			},
			delivery: {
				orderItem: 'Bestellposition',
				productCode: 'Produkt-Code',
				address: {
					heading: 'Lieferadresse',
					name: 'Name',
					street: 'Straße',
					location: 'Stadt'
				},
				details: {
					heading: 'Lieferdetails',
					shippingDate: 'Versanddatum',
					address: 'Lieferadresse',
					trackingCode: 'Trackingcode',
					state: 'Lieferstatus',
					partner: 'Versandpartner',
					tracking: 'Sendungsverfolgung',
					shippingState: {
						delivered: 'Geliefert',
						pending: 'In Zustellung'
					},
					estimatedDeliveryDate: 'Erwartetes Lieferdatum',
					deliveryDate: 'Lieferdatum'
				},
				retoureDetails: {
					heading: 'Retourendetails',
					date: 'Anforderungsdatum',
					address: 'Retouren-Adresse',
					reason: 'Retouren-Grund',
					returnNote: 'Retouren-Notiz',
					created: 'Erstelldatum'
				},
				replacementDetails: {
					heading: 'Ersatzlieferung Details'
				},
				emptyState: {
					heading: 'Keine Versandinformationen verfügbar',
					message: 'Derzeit liegen keine Informationen zum Versand vor.'
				}
			},
			accounting: {
				heading: 'Zahlungs-Transaktion',
				date: 'Zahluungsdatum',
				state: 'Status',
				notification: 'Fehlermeldung',
				cardOwner: 'Karten-Inhaber',
				cardNumber: 'Kartennummer',
				cardDueDate: 'Karten-Ablaufdatum',
				accountOwner: 'Kontoinhaber',
				accountNumber: 'IBAN',
				paymentState: {
					success: 'Erfolgreich',
					failed: 'Fehlgeschlagen',
					settlementPending: 'Zahlung wird durchgeführt'
				},
				emptyPaymentState: {
					heading: 'Keine Zahlungsinformationen vorhanden',
					message: 'Zur Zeit liegen keine Zahlungsinformationen vor.'
				},
				emptyDownloadFilesState: {
					heading: 'Keine Dateien vorhanden',
					message: 'Zur Zeit liegen keine Dateien vor.'
				}
			},
			files: {
				emptyState: {
					heading: 'Keine Dateien vorhanden',
					message: 'Zur Zeit liegen keine Dateien vor.'
				}
			},
			throttleWallboxHint: {
				caption: 'Achtung',
				message: 'Die Leistungsaufnahme der Wallbox muss gemäß der Vorgaben des Netzbetreibers angepasst werden.'
			},
			informationList: {
				heading: 'Informationen',
				emptyState: {
					heading: 'Noch keine Bestellinformationen vorhanden',
					message: 'Im weiteren Verlauf der Bestellung finden Sie hier alle relevanten Informationen zu der Bestellung.'
				},
				caption: {
					basicAnswers: 'Installations-Check',
					gridOperatorRegistration: 'Registrierung Netzbetreiber',
					gridOperatorDecision: 'Rückmeldung Netzbetreiber',
					preCheck: 'Finaler Check',
					offer: 'Angebot',
					approval: 'Abnahme'
				},
				precheck: {
					heading: 'Finaler Check',
					effort: 'Ergebnisse: Gewerke und Aufwände',
					questionnaire: {
						heading: 'Antworten des Kunden'
					},
					installationType: {
						undetermined: 'Noch nicht ermittelt',
						standard: 'Standard Installation',
						delta: 'Delta Installation'
					},
					decision: {
						accepted: 'Informationen ausreichend',
						more: 'Weitere Informationen benötigt',
						denied: 'Installation nicht möglich'
					},
					notes: 'Anmerkungen',
					hours: 'Stunden'
				},
				decision: {
					heading: 'Rückmeldung Netzbetreiber',
					decision: {
						pending: 'Die Anfrage ist unbeantwortet',
						accepted: 'Der Netzbetreiber hat der Installation zugestimmt.',
						denied: 'Der Netzbetreiber hat der Installation nicht zugestimmt.'
					}
				},
				registration: {
					heading: 'Registrierung Netzbetreiber',
					documents: 'Zusätzliche Dokumente',
					gridOperator: 'Netzbetreiber'
				},
				quotation: {
					heading: 'Angebot',
					conditions: 'Angebotsbedingungen'
				},
				complete: {
					success: {
						heading: 'Installation erfolgreich'
					},
					notes: 'Anmerkungen',
					images: 'Bilder der Installation',
					documents: 'Dokumente',
					signature: 'Kundenunterschrift'
				}
			}
		},
		orderEventTimeline: {
			emptyState: {
				heading: 'Keine Events gefunden',
				message: 'Die Historie ist bisher noch leer.'
			}
		},
		timelineItemDetails: {
			mailAddress: {
				label: 'E-Mail'
			},
			section: {
				payment: 'Zahlungen',
				appointments: 'Geplante Termine'
			}
		},
		orderEvent: {
			type: {
				orderReceived: 'Bestellung empfangen',
				orderAddressValidationSucceeded: 'Addressvalidierung erfolgreich',
				orderAddressValidationFailed: 'Addressvalidierung gescheitert',
				orderAddressCorrected: 'Bestelladdresse korrigiert',
				accountingOrderCreated: 'Buchhaltung Bestellung erstellt',
				orderConfirmationMailSent: 'Bestätigungsmail versendet',
				basicAnswersReceived: 'Frageboden Antworten erhalten',
				gridOperatorRegistrationStarted: 'Netzbetreiber Registrierung gestartet',
				gridOperatorFeedbackReceived: 'Netzbetreiber Rückmeldung erhalten',
				gridOperatorRejectionNotificationSent: 'Netzbetreiber Ablehung erhalten',
				basicAnswersEvaluated: 'Fragenbogen Antworten ausgewertet',
				precheckPreferencesMailSent: 'PreCheck Kontaktwünsche Mail verschickt',
				remotePrecheckExecuted: 'Remote Precheck ausgeführt',
				deltaQuotationCreated: 'Delta-Angebot erstellt',
				deltaQuotationMailSent: 'Delta-Angebot Mail versendet',
				deltaQuotationAccepted: 'Delta-Angebot angenommen',
				paymentSucceeded: 'Zahlung ergolgreich',
				paymentFailedOrTimedOut: 'Zahlung gescheitert',
				paymentReminderMailSent: 'Zahlungserinnerung versendet',
				paymentConfirmationMailSent: 'Zahlungsbestätigungsmail versendet',
				appointmentArranged: 'Termin vereinbart',
				appointmentArrangedNotificationSent: 'Termin Benachrichtigungsmail versendet',
				shippingConfirmed: 'Zustellung bestätigt',
				installationConfirmed: 'Installation bestätigt',
				replacementInstallationConfirmed: 'Ersatzinstallation bestätigt',
				installationApprovalMailSent: 'Installation Bestätigungsmail versendet',
				invoiceCreated: 'Rechnung erstellt',
				invoiceNotificationSent: 'Rechnungsbenachrichtigung versendet',
				orderCancelled: 'Bestellung storniert',
				gridOperatorRegistrationRevoked: 'Netbetreiber Registrierung aufgehoben',
				customerCallReceived: 'Kundenanruf erhalten',
				returnOrReplacementShipmentReceived: 'Retoure- oder Ersatzlieferung erhalten',
				returnInitiated: 'Retoure initialisiert',
				replacementInitiated: 'Ersatzlieferung initialisiert',
				cancellationInitiated: 'Stornierung initialisiert',
				orderNoteCreated: 'Bestellnotiz erzeugt',
				refundCompleted: 'Erstattung abgeschlossen',
				installationCancellationMailSent: 'Stornierung Installation Benachrichtigungsmail versendet'
			},
			category: {
				accounting: 'Buchhaltung',
				customer: 'Kunde',
				customerService: 'Kundenbetreuung',
				deltaInstallation: 'Delta-Installation',
				gridOperator: 'Netzbetreiber',
				installation: 'Installation',
				order: 'Bestellung',
				payment: 'Zahlung',
				preCheck: 'PreCheck',
				shipping: 'Versand'
			}
		},
		marketplaceOrder: {
			orderState: {
				paymentPending: 'Zahlung ausstehend',
				waitingForFulfillment: 'Warten auf Erfüllung',
				fulfillmentStarted: 'Erfüllung gestartet',
				cancelled: 'Storniert',
				done: 'Abgeschlossen'
			}
		},
		orderCollection: {
			heading: 'Bestellungen',
			tableHead: {
				order: 'Bestellung',
				status: 'Status',
				language: 'Sprache',
				plannedDeliveryDate: 'Geplante Lieferung',
				createdAt: 'Bestelldatum'
			},
			emptyState: {
				heading: 'Keine Bestellungen gefunden',
				message: 'Bitte passen Sie Ihre Filter-Einstellungen an, um passende Bestellungen zu finden.'
			},
			action: {
				refresh: 'Aktualisieren',
				showMore: 'Mehr anzeigen'
			},
			orderState: {
				onSchedule: 'Im Zeitplan',
				delayed: 'Verzögert',
				cancelPending: 'Wird abgebrochen',
				cancelled: 'Abgebrochen',
				imported: 'Importiert',
				validationFailed: 'Ungültig',
				done: 'Abgeschlossen'
			}
		},
		customerOrderCollection : {
			emptyState: {
				heading: 'Keine Bestellungen gefunden',
				message: 'Für diesen Kunden wurden keine Bestellungen gefunden.'
			}
		},
		orderLineItemCollection: {
			heading: 'Bestellung',
			position: 'Position',
			description: 'Beschreibung',
			price: 'Preis',
			productCode: 'Produkt-Code',
			totalPrice: 'Gesamtpreis'
		},
		inboundOrderCollection: {
			heading: 'Bestellungen zur Telefonnummer {{caller}}',
			emptyState: {
				heading: 'Keine Bestellungen gefunden',
				message: 'Eventuell ist es sinnvoll stattdessen die Suchfunktion zu verwenden.',
				searchLabel: 'Suchen'
			},
			action: {
				showMore: 'Mehr anzeigen'
			}
		},
		searchbar: {
			placeholder: 'Suche',
			results: {
				orders: 'Bestellungen',
				marketplaceOrders: 'Marketplace Bestellungen',
				customers: 'Kunden',
				showResultPage: 'Ergebnisseite anzeigen',
				showResultPageDescription: {
					resultsFromTo: 'Ergebnis 1 bis',
					of: 'von'
				}
			}
		},
		searchResultCollection: {
			heading: 'Suchergebnisse',
			headingWithSearchterm: 'Suchergebnisse für ',
			headingCustomers: 'Kunden',
			headingOrders: 'Bestellungen',
			headingMarketplaceOrders: 'Marketplace Bestellungen',
			emptyState: {
				heading: 'Keine Ergebnisse gefunden',
				message: 'Versuchen sie es erneut mit geänderten Suchkriterien.'
			},
			idleState: {
				heading: 'Kein Suchbegriff eingegeben',
				message: 'Bitte geben sie einen Suchbegriff ein.'
			},
			action: {
				showMore: 'Mehr Ergebnisse anzeigen'
			}
		},
		task: {
			assign: {
				successMessage: 'Aufgabe zugewiesen'
			},
			error: {
				taskNotFound: 'Aufgabe nicht gefunden',
				taskTypeNotFound: 'Task-Typ unbekannt'
			},
			gridOperatorRegistration: {
				continueToFeedback: {
					buttonLabel: 'Fortfahren'
				}
			},
			basicAnswers: {
				continueToQuotation: {
					buttonLabel: 'Fortfahren'
				},
				button: {
					close: 'Schließen',
					submit: 'Absenden'
				},
				questionnaire: {
					heading: 'Check'
				}
			},
			completion: {
				successHeading: 'Aufgabe erfolgreich erledigt',
				closeButtonLabel: 'Schließen'
			},
			preCheck: {
				button: {
					close: 'Schließen',
					submit: 'Absenden'
				},
				contact: {
					description: 'Kontakt'
				}
			},
			gridOperator: {
				button: {
					close: 'Schließen',
					submit: 'Absenden'
				}
			},
			quotation: {
				button: {
					close: 'Schließen',
					submit: 'Absenden'
				},
				deltaPackages: {
					totalPurchaseNetPrice: 'Gesamtkosten Einkauf (netto)',
					totalSalesGrossPrice: 'Gesamtkosten Endkunde (brutto)',
					purchaseNetPrice: 'Einkaufspreis (netto)',
					salesGrossPrice: 'Endkundenpreis (brutto)'
				}
			},
			payment: {
				button: {
					close: 'Schließen',
					submit: 'Absenden'
				}
			},
			appointment: {
				button: {
					close: 'Schließen',
					submit: 'Absenden'
				}
			},
			installation: {
				button: {
					close: 'Schließen',
					submit: 'Absenden'
				}
			}
		},
		taskCollection: {
			heading: 'Aufgaben',
			tableHead: {
				task: 'Aufgabe',
				status: 'Status',
				language: 'Sprache'
			},
			action: {
				refresh: 'Aktualisieren',
				showMore: 'Mehr anzeigen'
			},
			assign: {
				successMessage: 'Die Aufgabe wurde zugewiesen',
				errorMessage: 'Während der Zuweisung der Aufgabe ist ein Fehler aufgetreten'
			},
			emptyState: {
				heading: 'Keine Aufgaben gefunden',
				message: 'Bitte passen Sie Ihre Filter-Einstellungen an, um passende Aufgaben zu finden.'
			},
			orderEmptyState: {
				heading: 'Keine Aufgaben gefunden',
				message: 'Für die Bestellung sind keine Aufgaben offen.'
			}
		},
		taskCollectionItem: {
			type: {
				customerAnswerBasicQuestionsEscalation: 'Eskalation: Fragebogen',
				customerProvidePreCheckPreferencesEscalation: 'Eskalation: Kontaktwünsche',
				customerHandleUnfeasibleInstallationEscalation: 'Eskalation: Installation technisch nicht möglich',
				customerHandleGridOperatorRejectionEscalation: 'Eskalation: Negative Rückmeldung Netzbetreiber',
				customerAcceptQuotationEscalation: 'Eskalation: Angebot Mehraufwand',
				customerPerformPaymentEscalation: 'Eskalation: Bezahlung',
				customerApproveInstallationEscalation: 'Eskalation: Bestätigung Installation',
				customerAddressValidationEscalation: 'Addressvalidierung',
				customerUncoveredInstallationAddress: 'Installationadresse nicht verfügbar',
				installationPartnerEvaluateBasicAnswersEscalation: 'Eskalation: Installations-Check',
				installationPartnerExecuteRemotePreCheckEscalation: 'Eskalation: Finaler Check',
				installationPartnerRegisterAtGridOperatorEscalation: 'Eskalation: Registrierung Netzbetreiber',
				installationPartnerProvideGridOperatorDecisionEscalation: 'Eskalation: Rückmeldung Netzbetreiber',
				installationPartnerProvideQuotationEscalation: 'Eskalation: Erstellung Angebot',
				installationPartnerArrangeAppointmentEscalation: 'Eskalation: Terminvereinbarung',
				installationPartnerCompleteInstallationEscalation: 'Eskalation: Installationsdurchführung'
			},
			status: {
				started: 'Kommend',
				dueToday: 'Heute fällig',
				overdue: 'Überfällig',
				escalated: 'Eskaliert',
				done: 'Abgeschlossen',
				doneOverdue: 'Abgeschlossen',
				doneEscalated: 'Abgeschlossen'
			},
			category: {
				customer: 'Kunde',
				installationPartner: 'Install. Partner'
			}
		},
		pageTitle: {
			tasks: 'Aufgabenübersicht',
			orders: 'Bestellübersicht',
			order: 'Bestelldetail',
			marketplaceOrders: 'Marketplace Bestellübersicht',
			marketplaceOrder: 'Marketplace Bestelldetail',
			customers: 'Kundenübersicht',
			error: 'Fehler',
			notFound: 'Nicht gefunden',
			inbound: 'Eingehender Anruf von {{caller}}'
		},
		viewHeader: {
			tasks: 'Aufgabenübersicht',
			orders: 'Bestellübersicht',
			marketplaceOrders: 'Marketplace Bestellübersicht',
			customers: 'Kundenübersicht',
			inbound: 'Eingehender Anruf von {{caller}}'
		},
		modal: {
			task: {
				section: {
					customerInformation: {
						heading: 'Kunde',
						position: 'Position',
						description: 'Beschreibung',
						name: 'Name',
						address: 'Lieferadresse',
						phone: 'Telefonnummer',
						mail: 'E-Mail'
					},
					installationEffortInformation: {
						heading: 'Installationsaufwand',
						trade: 'Gewerk',
						effort: 'Aufwand',
						hours: 'Stunden'
					},
					installationPartnerInformation: {
						heading: 'Installationspartner',
						position: 'Position',
						description: 'Beschreibung',
						name: 'Name',
						mail: 'E-Mail',
						phone: 'Telefon'
					},
					orderInformation: {
						heading: 'Bestellinformationen'
					},
					quotationInformation: {
						heading: 'Angebot',
						position: 'Position',
						description: 'Beschreibung',
						price: 'Preis',
						deltaInstallation: 'Entscheidung: Delta Installation',
						grossPrice: 'Summe',
						vat: 'MwSt',
						include: 'inklusive'
					},
					taskInformation: {
						heading: 'Aufgabe',
						position: 'Position',
						description: 'Beschreibung',
						createdAt: 'Erstelldatum',
						dueDate: 'Fälligkeitsdatum',
						responsible: 'Zugewiesen',
						openTask: 'Aufgabe anzeigen'
					},
					contactInformation: {
						heading: 'Kontaktwünsche'
					},
					gridOperatorInformation: {
						heading: 'Netzbetreiber Informationen'
					},
					payment: {
						accept: 'Angebot angenommmen'
					},
					addressValidation: {
						heading: 'Addressvalidierung',
						invalidAddressLabel: {
							delivery: 'Ungültige Lieferadresse',
							billing: 'Ungültige Rechnungsaddresse',
							multiple: 'Ungültige Liefer- und Rechnungsadresse'
						},
						hint: {
							heading: 'Neue Adresse zur Validierung angeben',
							message: 'Die neue Address wird beim Abschließen der Aufgabe erneut geprüft.'
						},
						error: {
							heading: 'Die Addressvalidierung ist fehlgeschlagen',
							message: 'Die Addressinformation bitte erneut eingeben.'
						},
						newAddress: {
							heading: 'Neue Adresse',
							street: 'Straße und Hausnummer',
							zip: 'Postleitzahl',
							city: 'Stadt',
							country: 'Land',
							region: 'Region'
						}
					},
					uncoveredInstallationAddress: {
						message: 'Der Kunde wurde über die Stornierung der Bestellung informiert',
						info: 'Installationsaddresse nicht verfügbar',
						infoHeading: 'Informieren Sie den Kunden',
						infoDescription: 'Der Kunde muss darüber informiert werden, dass die Installationsadresse nicht abgedeckt ist und dass die Installation storniert wird.'
					},
					hint: {
						heading: 'Finaler Check',
						unfeasible: 'Entscheidung: Installation technisch nicht durchführbar',
						rejected: 'Abgelehnt vom Netzbetreiber'
					}
				}
			}
		}
	}
};

export default literals;
