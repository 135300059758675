import React from 'react';
import { useParams } from 'react-router-dom';

import { CustomerServiceOrderEntityDataProvider } from '@abb-emobility/platform/data-provider';
import { NotFoundError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { UiErrorHandler } from '@abb-emobility/shared/ui-error-handler';
import { SpinnerCircle } from '@abb-emobility/shared/ui-primitive';

import { OrderDetailDataAccessor } from '../components/order-detail/OrderDetailDataAccessor';
import { useSearchbarContentModifier } from '../components/searchbar-content/SearchbarContent.hooks';

// SUGGESTION: understand why key={orderId} is necessary / remove it if possible
export function OrderView() {

	const params = useParams();
	const orderId = params?.orderId ?? null;
	const l10n = useL10n();

	useSearchbarContentModifier(orderId, null, true);

	if (orderId === null) {
		throw new NotFoundError(l10n.translate('platformCustomerServiceApp.error.orderNotFound'));
	}

	return (
		<UiErrorHandler>
			<CustomerServiceOrderEntityDataProvider
				pendingComponent={SpinnerCircle}
				primaryKey={orderId}
				key={orderId}
			>
				<OrderDetailDataAccessor />
			</CustomerServiceOrderEntityDataProvider>
		</UiErrorHandler>
	);
}
