import { PricingModel } from '@abb-emobility/platform/domain-model';
import { CurrencyCode } from '@abb-emobility/shared/currency';
import { Dto } from '@abb-emobility/shared/domain-model-foundation';

export const pricingModelMock01: Dto<PricingModel> = {
	amount: 10.99,
	currency: CurrencyCode.EUR
};

export const pricingModelMock02: Dto<PricingModel> = {
	amount: 99.99,
	currency: CurrencyCode.EUR
};

export const pricingModelMock03: Dto<PricingModel> = {
	amount: 1112.99,
	currency: CurrencyCode.EUR
};
