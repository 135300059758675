import { Navigate, Route } from 'react-router-dom';

import { useSentryRoutes } from '@abb-emobility/shared/sentry-integration';

import { createRouteUrl, RoutePath } from './Routes';
import { CustomerView } from '../views/CustomerView';
import { CustomersView } from '../views/CustomersView';
import { InboundView } from '../views/InboundView';
import { MarketplaceOrderView } from '../views/MarketplaceOrderView';
import { MarketplaceOrdersView } from '../views/MarketplaceOrdersView';
import { NotFoundView } from '../views/NotFoundView';
import { OrderView } from '../views/OrderView';
import { OrdersView } from '../views/OrdersView';
import { SearchResultsView } from '../views/SearchResultsView';
import { TasksView } from '../views/TasksView';

export function Router() {

	// eslint-disable-next-line @typescript-eslint/naming-convention
	const Routes = useSentryRoutes();

	return (
		<Routes>
			<Route path={RoutePath.ROOT} element={<Navigate to={createRouteUrl(RoutePath.TASKS)} replace />} />
			<Route path={RoutePath.TASKS} element={<TasksView />} />
			<Route path={RoutePath.TASK} element={<TasksView />} />
			<Route path={RoutePath.ORDERS} element={<OrdersView />} />
			<Route path={RoutePath.ORDER} element={<OrderView />} />
			<Route path={RoutePath.ORDER_TASK} element={<OrderView />} />
			<Route path={RoutePath.MARKETPLACE_ORDERS} element={<MarketplaceOrdersView />} />
			<Route path={RoutePath.MARKETPLACE_ORDER} element={<MarketplaceOrderView />} />
			<Route path={RoutePath.CUSTOMERS} element={<CustomersView />} />
			<Route path={RoutePath.CUSTOMER} element={<CustomerView />} />
			<Route path={RoutePath.INBOUND} element={<InboundView />} />
			<Route path={RoutePath.SEARCH_RESULTS} element={<SearchResultsView />} />
			<Route path="*" element={<NotFoundView />} />
		</Routes>
	);

}
