import { ModelConverter, ModelConverterInterface } from '@abb-emobility/shared/domain-model-foundation';

import {
	CustomerServiceOrderInformationQuotationInformationModel, CustomerServiceOrderInformationQuotationInformationModelConverter
} from '../order-information/CustomerServiceOrderInformationQuotationInformationModel';

export type CustomerServiceOrderEventPaymentFailedOrTimedOutDetailModel = {
	quotation: CustomerServiceOrderInformationQuotationInformationModel
};

export class CustomerServiceOrderEventPaymentFailedOrTimedOutDetailModelConverter extends ModelConverter<CustomerServiceOrderEventPaymentFailedOrTimedOutDetailModel> {
override getFieldConverterMapByModel(): Map<keyof CustomerServiceOrderEventPaymentFailedOrTimedOutDetailModel, ModelConverterInterface<CustomerServiceOrderInformationQuotationInformationModel>> {
		return new Map([
			['quotation', new CustomerServiceOrderInformationQuotationInformationModelConverter()]
		]);
	}
}
