import React from 'react';

import { AnyCustomerServiceCommentModel } from '@abb-emobility/platform/domain-model';
import { TabularInformationRow } from '@abb-emobility/shared/ui-primitive';

import { Comment } from '../comment/Comment';

export type CommentCollectionProps = {
	comments: ReadonlyArray<AnyCustomerServiceCommentModel>
};

export function CommentCollection(props: CommentCollectionProps) {

	const { comments } = props;

	const renderComments = () => {
		return comments.map((comment) => {
			return (
				<TabularInformationRow key={comment.id}>
					<Comment comment={comment} />
				</TabularInformationRow>
			);
		});
	};

	return (
		<>
			{renderComments()}
		</>
	);
}
