import React from 'react';

import { getCurrencySymbolByCode, calculateMajorUnitFromMinorUnit } from '@abb-emobility/shared/currency';
import { QuotationModel } from '@abb-emobility/shared/domain-model';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	SegmentHeader,
	Separator,
	TabularInformationItem,
	TabularInformationItemType,
	TabularInformationRow
} from '@abb-emobility/shared/ui-primitive';

import './QuotationInformation.scss';

export type QuotationInformationProps = {
	quotation: QuotationModel
};

export function QuotationInformation(props: QuotationInformationProps) {

	const { quotation } = props;

	const l10n = useL10n();

	const calculateTotalPrice = (): number => {
		let totalPrice = 0;
		quotation.items.forEach((item) => {
			totalPrice += item.salesGrossPrice;
		});

		return totalPrice;
	};

	const renderEfforts = () => {
		return quotation.items.map((item) => {
			return (
				<TabularInformationRow alignTop={true} key={item.id}>
					<TabularInformationItem label={item.name} variant={TabularInformationItemType.GREY} />
					<TabularInformationItem flex={1} label={''}>
						<span style={{ width: '95%', overflow: 'hidden' }}>{item.description}</span>
					</TabularInformationItem>
					<TabularInformationItem label={`${l10n.formatNumber(calculateMajorUnitFromMinorUnit(item.currencyCode, item.salesGrossPrice), 2)} ${getCurrencySymbolByCode(item.currencyCode)}`} />
				</TabularInformationRow>
			);
		});
	};

	return (
		<section>
			<SegmentHeader caption={l10n.translate('platformCustomerServiceApp.modal.task.section.quotationInformation.heading')} />
			<Separator />
			<TabularInformationRow>
				<TabularInformationItem
					label={l10n.translate('platformCustomerServiceApp.modal.task.section.quotationInformation.position')}
					variant={TabularInformationItemType.HEADING} />
				<TabularInformationItem
					label={l10n.translate('platformCustomerServiceApp.modal.task.section.quotationInformation.description')}
					variant={TabularInformationItemType.HEADING}
					flex={1}
				/>
				<TabularInformationItem
					label={l10n.translate('platformCustomerServiceApp.modal.task.section.quotationInformation.price')}
					variant={TabularInformationItemType.HEADING} />
			</TabularInformationRow>

			{renderEfforts()}

			<section className="quotation-information">
				<section className="quotation-information__section">
					<TabularInformationItem flex={1} label={l10n.translate('platformCustomerServiceApp.modal.task.section.quotationInformation.grossPrice')} />
					<TabularInformationItem label={`${l10n.formatNumber(calculateMajorUnitFromMinorUnit(quotation.currencyCode, calculateTotalPrice()), 2)} ${getCurrencySymbolByCode(quotation.currencyCode)}`} />

				</section>
				<section className="quotation-information__section">
					<TabularInformationItem flex={1} label={`${l10n.translate('platformCustomerServiceApp.modal.task.section.quotationInformation.include')} ${quotation.vatRate}% ${l10n.translate('platformCustomerServiceApp.modal.task.section.quotationInformation.vat')}`} variant={TabularInformationItemType.GREY} />
					<TabularInformationItem variant={TabularInformationItemType.GREY} label={`${quotation.vatRate === 0 ? 0 : l10n.formatNumber((calculateMajorUnitFromMinorUnit(quotation.currencyCode,calculateTotalPrice()),2) / quotation.vatRate)} ${getCurrencySymbolByCode(quotation.currencyCode)}`} />
				</section>
			</section>
			<Separator />
		</section>
	);
}
