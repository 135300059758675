import React from 'react';

import {
	CustomerServiceMarketplaceOrderCollectionDataProvider,
	customerServiceMarketplaceOrderCollectionStoreName
} from '@abb-emobility/platform/data-provider';
import {
	CustomerServiceMarketplaceOrderCollectionItemModel
} from '@abb-emobility/platform/domain-model';
import { SortCriteria } from '@abb-emobility/shared/api-integration-foundation';
import { useSortCriteriaStorage } from '@abb-emobility/shared/data-provider-util';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { usePageTitle } from '@abb-emobility/shared/react';
import { UiErrorHandler } from '@abb-emobility/shared/ui-error-handler';
import { useBreadcrumbModifier, SpinnerCircle, ViewHeader } from '@abb-emobility/shared/ui-primitive';

import { MarketplaceOrderCollection } from '../components/marketplace-order-collection/MarketplaceOrderCollection';
import {
	marketplaceOrderCollectionDefaultSortCriteria
} from '../components/marketplace-order-collection/MarketplaceOrderCollectionDefaultSortCriteria';
import { useBuildPageTitle } from '../components/page-title/PageTitle.hook';
import {
	useClearSearchbarContentModifier
} from '../components/searchbar-content/SearchbarContent.hooks';
import { createRouteUrl, RoutePath } from '../router/Routes';

export function MarketplaceOrdersView() {

	const l10n = useL10n();

	const sortCriteriaStorage = useSortCriteriaStorage<CustomerServiceMarketplaceOrderCollectionItemModel>(customerServiceMarketplaceOrderCollectionStoreName);
	const persistedSortCriteria = sortCriteriaStorage.readSortCriteria();

	const effectiveSortCriteria = persistedSortCriteria ?? marketplaceOrderCollectionDefaultSortCriteria;

	const persistSort = (sortCriteria: SortCriteria<CustomerServiceMarketplaceOrderCollectionItemModel>): void => {
		sortCriteriaStorage.writeSortCriteria(sortCriteria);
	};

	usePageTitle(useBuildPageTitle(l10n.translate('platformCustomerServiceApp.pageTitle.marketplaceOrders')));
	useBreadcrumbModifier([
		{
			label: l10n.translate('platformCustomerServiceApp.breadcrumbs.marketplaceOrders'),
			link: createRouteUrl(RoutePath.CUSTOMERS)
		}
	]);
	useClearSearchbarContentModifier();

	return (
		<>
			<ViewHeader heading={l10n.translate('platformCustomerServiceApp.viewHeader.marketplaceOrders')} />
			<UiErrorHandler>
				<CustomerServiceMarketplaceOrderCollectionDataProvider
					pendingComponent={SpinnerCircle}
					sortCriteria={effectiveSortCriteria}
					suspense={false}
				>
					<MarketplaceOrderCollection onSortChange={persistSort} />
				</CustomerServiceMarketplaceOrderCollectionDataProvider>
			</UiErrorHandler>
		</>
	);
}
