import React from 'react';

import { FilterCriteria, SortCriteria } from '@abb-emobility/shared/api-integration-foundation';
import { useFilterCriteriaStorage, useSortCriteriaStorage } from '@abb-emobility/shared/data-provider-util';
import { Card } from '@abb-emobility/shared/ui-primitive';
import { TaskCollectionDataProviderProvider, taskCollectionStoreName, useTaskCollectionData } from '@abb-emobility/usertask/data-provider';
import { AnyTaskCollectionItemModel } from '@abb-emobility/usertask/domain-model';


import { TaskCollection } from './TaskCollection';
import { useActionbarContentModifier } from '../actionbar-content/ActionbarContent.hooks';

export function TaskCollectionDataAssembler() {

	const taskCollectionDataProvider = useTaskCollectionData();

	const filterCriteriaStorage = useFilterCriteriaStorage<AnyTaskCollectionItemModel>(taskCollectionStoreName);
	const sortCriteriaStorage = useSortCriteriaStorage<AnyTaskCollectionItemModel>(taskCollectionStoreName);

	const persistFilterSettings = (filterCriteria: FilterCriteria<AnyTaskCollectionItemModel>): void => {
		filterCriteriaStorage.writeFilterCriteria(filterCriteria);
	};

	const persistSortSettings = (sortCriteria: SortCriteria<AnyTaskCollectionItemModel>): void => {
		sortCriteriaStorage.writeSortCriteria(sortCriteria);
	};

	useActionbarContentModifier([], []);

	return (
		<Card>
			<TaskCollectionDataProviderProvider dataProvider={taskCollectionDataProvider}>
				<TaskCollection onFilterChange={persistFilterSettings} onSortChange={persistSortSettings} />
			</TaskCollectionDataProviderProvider>
		</Card>
	);
}
