import React, { ReactNode } from 'react';

import {
	CustomerServiceOrderDownloadFileCollectionDataProvider,
	CustomerServiceOrderPaymentCollectionDataProvider,
	CustomerServiceOrderShipmentCollectionDataProvider,
	useCustomerServiceOrderEntityData,
	CustomerServiceOrderCommentCollectionDataProvider,
	CustomerServiceOrderCommentCollectionDataInterceptEmpty
} from '@abb-emobility/platform/data-provider';
import { createFullNameFromCustomer, CustomerServiceOrderDownloadFileType } from '@abb-emobility/platform/domain-model';
import { FetchMode } from '@abb-emobility/shared/data-provider-foundation';
import { NotFoundError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { UiErrorHandler } from '@abb-emobility/shared/ui-error-handler';
import {
	Card,
	NotificationBig,
	NotificationBigStatus,
	SegmentHeader,
	SpinnerCircle,
	Status,
	TabbarItem,
	TabbedPane,
	ViewHeader,
	ViewSubheader,
	ViewSubheaderItem,
	ViewSubheaderItemValueNumber
} from '@abb-emobility/shared/ui-primitive';
import { buildCssClassStringFromClassMap, l10nLiteralFromEnumValue } from '@abb-emobility/shared/util';
import { createOrderTaskCollectionFilterCriteria, OrderTaskCollectionDataProvider } from '@abb-emobility/usertask/data-provider';

import { useTaskCountPolling } from '../../hooks/useTaskCountPolling';
import { OrderCommentCollection } from '../comment-collection/OrderCommentCollection';
import { OrderDetailAccounting } from '../order-detail-accounting/OrderDetailAccounting';
import { OrderDetailDelivery } from '../order-detail-delivery/OrderDetailDelivery';
import { OrderDetailFiles } from '../order-detail-documents/OrderDetailFiles';
import { OrderDetailInstallation } from '../order-detail-installation/OrderDetailInstallation';
import { OrderDetailOverview } from '../order-detail-overview/OrderDetailOverview';
import { OrderTaskCollectionDataAccessor } from '../order-task-collection/OrderTaskCollectionDataAccessor';

import './OrderDetail.scss';

enum TabItem {
	OVERVIEW = 'OVERVIEW',
	INSTALLATION = 'INSTALLATION',
	DELIVERY = 'DELIVERY',
	ACCOUNTING = 'ACCOUNTING',
	FILES = 'FILES',
	COMMENTS = 'COMMENTS',
	TASKS = 'TASKS'
}

type OrderDetailContentProps = {
	modal?: boolean
};

export function OrderDetail(props: OrderDetailContentProps) {

	const { modal = false } = props;

	const l10n = useL10n();

	const orderData = useCustomerServiceOrderEntityData();

	const order = orderData.query().getOrThrow(new NotFoundError(l10n.translate('platformCustomerServiceApp.error.orderNotFound')));
	const taskCount = useTaskCountPolling(undefined, createOrderTaskCollectionFilterCriteria(order.id));

	const renderEmptyCommentsState = (): ReactNode => {
		return (
			<NotificationBig
				status={NotificationBigStatus.EMPTY2}
				heading={l10n.translate('platformCustomerServiceApp.commentCollection.emptyState.heading')}
				message={l10n.translate('platformCustomerServiceApp.commentCollection.emptyState.message')}
			/>
		);
	};

	const taskPill = (taskCount !== null && taskCount > 0) ? l10n.formatNumber(taskCount, 0) : undefined;
	const tasksDisabled = (taskCount !== null && taskCount === 0);

	const renderTabbedPane = (): ReactNode => {
		return (
			<TabbedPane activeTabId={TabItem.OVERVIEW} renderTabContent={renderTabContent} connectUrl={true}>
				<TabbarItem
					label={l10n.translate('platformCustomerServiceApp.orderDetail.tabItems.overview')}
					key={TabItem.OVERVIEW}
					tabId={TabItem.OVERVIEW}
				/>
				<TabbarItem
					label={l10n.translate('platformCustomerServiceApp.orderDetail.tabItems.installation')}
					pill={l10n.translate(l10nLiteralFromEnumValue(order.installationType, 'platformCustomerServiceApp.order.installationType'))}
					key={TabItem.INSTALLATION}
					tabId={TabItem.INSTALLATION}
				/>
				<TabbarItem
					label={l10n.translate('platformCustomerServiceApp.orderDetail.tabItems.delivery')}
					key={TabItem.DELIVERY}
					tabId={TabItem.DELIVERY}
				/>
				<TabbarItem
					label={l10n.translate('platformCustomerServiceApp.orderDetail.tabItems.accounting')}
					key={TabItem.ACCOUNTING}
					tabId={TabItem.ACCOUNTING}
				/>
				<TabbarItem
					label={l10n.translate('platformCustomerServiceApp.orderDetail.tabItems.files')}
					key={TabItem.FILES}
					tabId={TabItem.FILES}
				/>
				<TabbarItem
					label={l10n.translate('platformCustomerServiceApp.orderDetail.tabItems.tasks')}
					key={TabItem.TASKS}
					tabId={TabItem.TASKS}
					pill={taskPill}
					disabled={tasksDisabled}
				/>
				<TabbarItem
					label={l10n.translate('platformCustomerServiceApp.orderDetail.tabItems.comments')}
					key={TabItem.COMMENTS}
					tabId={TabItem.COMMENTS}
				/>
			</TabbedPane>
		);
	};

	const renderTabContent = (tabId: TabItem): ReactNode => {
		switch (tabId) {
			case TabItem.OVERVIEW: {
				return (
					<OrderDetailOverview order={order} />
				);
			}
			case TabItem.INSTALLATION: {
				return (
					<OrderDetailInstallation order={order} />
				);
			}
			case TabItem.DELIVERY: {
				return (
					<UiErrorHandler key={TabItem.DELIVERY}>
						<CustomerServiceOrderShipmentCollectionDataProvider
							orderId={order.id}
							pendingComponent={SpinnerCircle}
						>
							<OrderDetailDelivery />
						</CustomerServiceOrderShipmentCollectionDataProvider>
					</UiErrorHandler>
				);
			}
			case TabItem.ACCOUNTING: {
				return (
					<UiErrorHandler key={TabItem.ACCOUNTING}>
						<CustomerServiceOrderPaymentCollectionDataProvider
							orderId={order.id}
							pendingComponent={SpinnerCircle}
						>
							<CustomerServiceOrderDownloadFileCollectionDataProvider
								orderId={order.id}
								fileType={CustomerServiceOrderDownloadFileType.ACCOUNTING}
								pendingComponent={SpinnerCircle}
							>
								<OrderDetailAccounting />
							</CustomerServiceOrderDownloadFileCollectionDataProvider>
						</CustomerServiceOrderPaymentCollectionDataProvider>
					</UiErrorHandler>
				);
			}
			case TabItem.FILES: {
				return (
					<UiErrorHandler key={TabItem.FILES}>
						<CustomerServiceOrderDownloadFileCollectionDataProvider
							orderId={order.id}
							fileType={CustomerServiceOrderDownloadFileType.NON_ACCOUNTING}
							pendingComponent={SpinnerCircle}
						>
							<OrderDetailFiles />
						</CustomerServiceOrderDownloadFileCollectionDataProvider>
					</UiErrorHandler>
				);
			}
			case TabItem.COMMENTS: {
				return (
					<Card>
						<SegmentHeader caption={l10n.translate('platformCustomerServiceApp.commentCollection.heading')} />
						<UiErrorHandler key={TabItem.COMMENTS}>
							<CustomerServiceOrderCommentCollectionDataProvider
								orderId={order.id}
								customerId={order.customer.id}
								pendingComponent={SpinnerCircle}
							>
								<CustomerServiceOrderCommentCollectionDataInterceptEmpty emptyStateComponent={renderEmptyCommentsState}>
									<OrderCommentCollection />
								</CustomerServiceOrderCommentCollectionDataInterceptEmpty>
							</CustomerServiceOrderCommentCollectionDataProvider>
						</UiErrorHandler>
					</Card>
				);
			}
			case TabItem.TASKS: {
				return (
					<UiErrorHandler>
						<OrderTaskCollectionDataProvider
							pendingComponent={SpinnerCircle}
							fetchMode={FetchMode.LAZY}
							orderId={order.id}
						>
							<OrderTaskCollectionDataAccessor orderId={order.id} />
						</OrderTaskCollectionDataProvider>
					</UiErrorHandler>
				);
			}
			default:
				return null;
		}
	};

	const classMap = {
		'order-detail-content': true,
		'order-detail-content--modal': modal
	};

	return (
		<section className={buildCssClassStringFromClassMap(classMap)}>
			<header>
				<ViewHeader heading={createFullNameFromCustomer(order.customer)} />
				<ViewSubheader>
					<ViewSubheaderItem label={l10n.translate('platformCustomerServiceApp.orderDetail.subheader.orderDate') + ': '}>
						{l10n.formatTimestampDate(order.orderDate)}
					</ViewSubheaderItem>
					<ViewSubheaderItem label={l10n.translate('platformCustomerServiceApp.orderDetail.subheader.orderId') + ': '}>
						<ViewSubheaderItemValueNumber>
							{order.marketplaceOrderId}
						</ViewSubheaderItemValueNumber>
					</ViewSubheaderItem>
					<ViewSubheaderItem label={l10n.translate('platformCustomerServiceApp.orderDetail.subheader.marketplace') + ': '}>
						{order.marketplace}
					</ViewSubheaderItem>
					<ViewSubheaderItem label={l10n.translate('platformCustomerServiceApp.orderDetail.subheader.plannedDelivery') + ': '}>
						{l10n.formatTimestampDate(order.orderDate)}
					</ViewSubheaderItem>
					<Status
						label={l10n.translate(l10nLiteralFromEnumValue(order.state, 'platformCustomerServiceApp.orderDetail.subheader.orderState'))}
						state={order.state}
					/>
				</ViewSubheader>
			</header>
			{renderTabbedPane()}
		</section>
	);
}
