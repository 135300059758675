import { CustomerServiceOrderEventModelItemState } from '../common/CustomerServiceOrderEventModelItemState';

export enum CustomerServiceOrderEventType {
	ORDER_RECEIVED = 'ORDER_RECEIVED',
	ORDER_ADDRESS_VALIDATION_SUCCEEDED = 'ORDER_ADDRESS_VALIDATION_SUCCEEDED',
	ORDER_ADDRESS_VALIDATION_FAILED = 'ORDER_ADDRESS_VALIDATION_FAILED',
	ORDER_ADDRESS_CORRECTED = 'ORDER_ADDRESS_CORRECTED',
	ACCOUNTING_ORDER_CREATED = 'ACCOUNTING_ORDER_CREATED',
	// OMSDE-25
	ORDER_CONFIRMATION_MAIL_SENT = 'ORDER_CONFIRMATION_MAIL_SENT',
	BASIC_ANSWERS_RECEIVED = 'BASIC_ANSWERS_RECEIVED',
	GRID_OPERATOR_REGISTRATION_STARTED = 'GRID_OPERATOR_REGISTRATION_STARTED',
	GRID_OPERATOR_FEEDBACK_RECEIVED = 'GRID_OPERATOR_FEEDBACK_RECEIVED',
	// OMSDE-25
	GRID_OPERATOR_REJECTION_NOTIFICATION_SENT = 'GRID_OPERATOR_REJECTION_NOTIFICATION_SENT',
	BASIC_ANSWERS_EVALUATED = 'BASIC_ANSWERS_EVALUATED',
	// OMSDE-25
	PRECHECK_PREFERENCES_MAIL_SENT = 'PRECHECK_PREFERENCES_MAIL_SENT',
	REMOTE_PRECHECK_EXECUTED = 'REMOTE_PRECHECK_EXECUTED',
	DELTA_QUOTATION_CREATED = 'DELTA_QUOTATION_CREATED',
	// OMSDE-25
	DELTA_QUOTATION_MAIL_SENT = 'DELTA_QUOTATION_MAIL_SENT',
	DELTA_QUOTATION_ACCEPTED = 'DELTA_QUOTATION_ACCEPTED',
	PAYMENT_SUCCEEDED = 'PAYMENT_SUCCEEDED',
	// OMSDE-25
	PAYMENT_FAILED_OR_TIMED_OUT = 'PAYMENT_FAILED_OR_TIMED_OUT',
	// OMSDE-25
	PAYMENT_CONFIRMATION_MAIL_SENT = 'PAYMENT_CONFIRMATION_MAIL_SENT',
	APPOINTMENT_ARRANGED = 'APPOINTMENT_ARRANGED',
	// OMSDE-25
	APPOINTMENT_ARRANGED_NOTIFICATION_SENT = 'APPOINTMENT_ARRANGED_NOTIFICATION_SENT',
	SHIPPING_CONFIRMED = 'SHIPPING_CONFIRMED',
	INSTALLATION_CONFIRMED = 'INSTALLATION_CONFIRMED',
	REPLACEMENT_INSTALLATION_CONFIRMED = 'REPLACEMENT_INSTALLATION_CONFIRMED',
	// OMSDE-25
	INSTALLATION_APPROVAL_MAIL_SENT = 'INSTALLATION_APPROVAL_MAIL_SENT',
	INVOICE_CREATED = 'INVOICE_CREATED',
	// OMSDE-25
	INVOICE_NOTIFICATION_SENT = 'INVOICE_NOTIFICATION_SENT',
	ORDER_CANCELLED = 'ORDER_CANCELLED',
	GRID_OPERATOR_REGISTRATION_REVOKED = 'GRID_OPERATOR_REGISTRATION_REVOKED',
	CUSTOMER_CALL_RECEIVED = 'CUSTOMER_CALL_RECEIVED',
	RETURN_OR_REPLACEMENT_SHIPMENT_RECEIVED = 'RETURN_OR_REPLACEMENT_SHIPMENT_RECEIVED',
	RETURN_INITIATED = 'RETURN_INITIATED',
	REPLACEMENT_INITIATED = 'REPLACEMENT_INITIATED',
	CANCELLATION_INITIATED = 'CANCELLATION_INITIATED',
	ORDER_NOTE_CREATED = 'ORDER_NOTE_CREATED',
	REFUND_COMPLETED = 'REFUND_COMPLETED',
	// OMSDE-790
	INSTALLATION_CANCELLATION_MAIL_SENT = 'INSTALLATION_CANCELLATION_MAIL_SENT'
}

export type CustomerServiceOrderEventTypeSpecificsModel = {
	isMilestone: boolean,
	category: CustomerServiceOrderEventCategories,
	eventState: CustomerServiceOrderEventModelItemState
}

export enum CustomerServiceOrderEventCategories {
	ACCOUNTING = 'ACCOUNTING',
	CUSTOMER = 'CUSTOMER',
	CUSTOMER_SERVICE = 'CUSTOMER_SERVICE',
	DELTA_INSTALLATION = 'DELTA_INSTALLATION',
	GRID_OPERATOR = 'GRID_OPERATOR',
	INSTALLATION = 'INSTALLATION',
	ORDER = 'ORDER',
	PAYMENT = 'PAYMENT',
	PRE_CHECK = 'PRE_CHECK',
	SHIPPING = 'SHIPPING',
}

export const customerServiceOrderEventTypeSpecifics: { [key in CustomerServiceOrderEventType]: CustomerServiceOrderEventTypeSpecificsModel } = {
	[CustomerServiceOrderEventType.ORDER_RECEIVED]: {
		isMilestone: true,
		category: CustomerServiceOrderEventCategories.ORDER,
		eventState: CustomerServiceOrderEventModelItemState.INFO
	},
	[CustomerServiceOrderEventType.ORDER_ADDRESS_VALIDATION_SUCCEEDED]: {
		isMilestone: false,
		category: CustomerServiceOrderEventCategories.ORDER,
		eventState: CustomerServiceOrderEventModelItemState.SUCCESS
	},
	[CustomerServiceOrderEventType.ORDER_ADDRESS_VALIDATION_FAILED]: {
		isMilestone: false,
		category: CustomerServiceOrderEventCategories.ORDER,
		eventState: CustomerServiceOrderEventModelItemState.ERROR
	},
	[CustomerServiceOrderEventType.ORDER_ADDRESS_CORRECTED]: {
		isMilestone: false,
		category: CustomerServiceOrderEventCategories.ORDER,
		eventState: CustomerServiceOrderEventModelItemState.INFO
	},
	[CustomerServiceOrderEventType.ACCOUNTING_ORDER_CREATED]: {
		isMilestone: true,
		category: CustomerServiceOrderEventCategories.ORDER,
		eventState: CustomerServiceOrderEventModelItemState.INFO
	},
	[CustomerServiceOrderEventType.ORDER_CONFIRMATION_MAIL_SENT]: {
		isMilestone: false,
		category: CustomerServiceOrderEventCategories.ORDER,
		eventState: CustomerServiceOrderEventModelItemState.INFO
	},
	[CustomerServiceOrderEventType.BASIC_ANSWERS_RECEIVED]: {
		isMilestone: false,
		category: CustomerServiceOrderEventCategories.PRE_CHECK,
		eventState: CustomerServiceOrderEventModelItemState.INFO
	},
	[CustomerServiceOrderEventType.GRID_OPERATOR_REGISTRATION_STARTED]: {
		isMilestone: false,
		category: CustomerServiceOrderEventCategories.GRID_OPERATOR,
		eventState: CustomerServiceOrderEventModelItemState.INFO
	},
	[CustomerServiceOrderEventType.GRID_OPERATOR_FEEDBACK_RECEIVED]: {
		isMilestone: false,
		category: CustomerServiceOrderEventCategories.GRID_OPERATOR,
		eventState: CustomerServiceOrderEventModelItemState.INFO
	},
	[CustomerServiceOrderEventType.GRID_OPERATOR_REJECTION_NOTIFICATION_SENT]: {
		isMilestone: false,
		category: CustomerServiceOrderEventCategories.GRID_OPERATOR,
		eventState: CustomerServiceOrderEventModelItemState.INFO
	},
	[CustomerServiceOrderEventType.BASIC_ANSWERS_EVALUATED]: {
		isMilestone: true,
		category: CustomerServiceOrderEventCategories.PRE_CHECK,
		eventState: CustomerServiceOrderEventModelItemState.INFO
	},
	[CustomerServiceOrderEventType.PRECHECK_PREFERENCES_MAIL_SENT]: {
		isMilestone: false,
		category: CustomerServiceOrderEventCategories.PRE_CHECK,
		eventState: CustomerServiceOrderEventModelItemState.INFO
	},
	[CustomerServiceOrderEventType.REMOTE_PRECHECK_EXECUTED]: {
		isMilestone: true,
		category: CustomerServiceOrderEventCategories.PRE_CHECK,
		eventState: CustomerServiceOrderEventModelItemState.INFO
	},
	[CustomerServiceOrderEventType.DELTA_QUOTATION_CREATED]: {
		isMilestone: false,
		category: CustomerServiceOrderEventCategories.DELTA_INSTALLATION,
		eventState: CustomerServiceOrderEventModelItemState.INFO
	},
	[CustomerServiceOrderEventType.DELTA_QUOTATION_MAIL_SENT]: {
		isMilestone: false,
		category: CustomerServiceOrderEventCategories.DELTA_INSTALLATION,
		eventState: CustomerServiceOrderEventModelItemState.INFO
	},
	[CustomerServiceOrderEventType.DELTA_QUOTATION_ACCEPTED]: {
		isMilestone: true,
		category: CustomerServiceOrderEventCategories.DELTA_INSTALLATION,
		eventState: CustomerServiceOrderEventModelItemState.SUCCESS
	},
	[CustomerServiceOrderEventType.PAYMENT_SUCCEEDED]: {
		isMilestone: true,
		category: CustomerServiceOrderEventCategories.PAYMENT,
		eventState: CustomerServiceOrderEventModelItemState.SUCCESS
	},
	[CustomerServiceOrderEventType.PAYMENT_FAILED_OR_TIMED_OUT]: {
		isMilestone: false,
		category: CustomerServiceOrderEventCategories.PAYMENT,
		eventState: CustomerServiceOrderEventModelItemState.WARNING
	},
	[CustomerServiceOrderEventType.PAYMENT_CONFIRMATION_MAIL_SENT]: {
		isMilestone: false,
		category: CustomerServiceOrderEventCategories.PAYMENT,
		eventState: CustomerServiceOrderEventModelItemState.INFO
	},
	[CustomerServiceOrderEventType.APPOINTMENT_ARRANGED]: {
		isMilestone: true,
		category: CustomerServiceOrderEventCategories.INSTALLATION,
		eventState: CustomerServiceOrderEventModelItemState.INFO
	},
	[CustomerServiceOrderEventType.APPOINTMENT_ARRANGED_NOTIFICATION_SENT]: {
		isMilestone: false,
		category: CustomerServiceOrderEventCategories.INSTALLATION,
		eventState: CustomerServiceOrderEventModelItemState.INFO
	},
	[CustomerServiceOrderEventType.SHIPPING_CONFIRMED]: {
		isMilestone: false,
		category: CustomerServiceOrderEventCategories.SHIPPING,
		eventState: CustomerServiceOrderEventModelItemState.SUCCESS
	},
	[CustomerServiceOrderEventType.INSTALLATION_CONFIRMED]: {
		isMilestone: true,
		category: CustomerServiceOrderEventCategories.INSTALLATION,
		eventState: CustomerServiceOrderEventModelItemState.SUCCESS
	},
	[CustomerServiceOrderEventType.REPLACEMENT_INSTALLATION_CONFIRMED]: {
		isMilestone: true,
		category: CustomerServiceOrderEventCategories.INSTALLATION,
		eventState: CustomerServiceOrderEventModelItemState.SUCCESS
	},
	[CustomerServiceOrderEventType.INSTALLATION_APPROVAL_MAIL_SENT]: {
		isMilestone: false,
		category: CustomerServiceOrderEventCategories.INSTALLATION,
		eventState: CustomerServiceOrderEventModelItemState.INFO
	},
	[CustomerServiceOrderEventType.INVOICE_CREATED]: {
		isMilestone: false,
		category: CustomerServiceOrderEventCategories.ACCOUNTING,
		eventState: CustomerServiceOrderEventModelItemState.INFO
	},
	[CustomerServiceOrderEventType.INVOICE_NOTIFICATION_SENT]: {
		isMilestone: false,
		category: CustomerServiceOrderEventCategories.ACCOUNTING,
		eventState: CustomerServiceOrderEventModelItemState.INFO
	},
	[CustomerServiceOrderEventType.ORDER_CANCELLED]: {
		isMilestone: true,
		category: CustomerServiceOrderEventCategories.ORDER,
		eventState: CustomerServiceOrderEventModelItemState.SUCCESS
	},
	[CustomerServiceOrderEventType.GRID_OPERATOR_REGISTRATION_REVOKED]: {
		isMilestone: false,
		category: CustomerServiceOrderEventCategories.GRID_OPERATOR,
		eventState: CustomerServiceOrderEventModelItemState.INFO
	},
	[CustomerServiceOrderEventType.CUSTOMER_CALL_RECEIVED]: {
		isMilestone: false,
		category: CustomerServiceOrderEventCategories.CUSTOMER,
		eventState: CustomerServiceOrderEventModelItemState.INFO
	},
	[CustomerServiceOrderEventType.RETURN_OR_REPLACEMENT_SHIPMENT_RECEIVED]: {
		isMilestone: false,
		category: CustomerServiceOrderEventCategories.SHIPPING,
		eventState: CustomerServiceOrderEventModelItemState.INFO
	},
	[CustomerServiceOrderEventType.RETURN_INITIATED]: {
		isMilestone: false,
		category: CustomerServiceOrderEventCategories.ORDER,
		eventState: CustomerServiceOrderEventModelItemState.INFO
	},
	[CustomerServiceOrderEventType.REPLACEMENT_INITIATED]: {
		isMilestone: false,
		category: CustomerServiceOrderEventCategories.ORDER,
		eventState: CustomerServiceOrderEventModelItemState.INFO
	},
	[CustomerServiceOrderEventType.CANCELLATION_INITIATED]: {
		isMilestone: false,
		category: CustomerServiceOrderEventCategories.ORDER,
		eventState: CustomerServiceOrderEventModelItemState.INFO
	},
	[CustomerServiceOrderEventType.ORDER_NOTE_CREATED]: {
		isMilestone: false,
		category: CustomerServiceOrderEventCategories.CUSTOMER_SERVICE,
		eventState: CustomerServiceOrderEventModelItemState.INFO
	},
	[CustomerServiceOrderEventType.REFUND_COMPLETED]: {
		isMilestone: true,
		category: CustomerServiceOrderEventCategories.PAYMENT,
		eventState: CustomerServiceOrderEventModelItemState.SUCCESS
	},
	[CustomerServiceOrderEventType.INSTALLATION_CANCELLATION_MAIL_SENT]: {
		isMilestone: false,
		category: CustomerServiceOrderEventCategories.INSTALLATION,
		eventState: CustomerServiceOrderEventModelItemState.INFO
	}
};

