import { AnyCustomerServiceOrderEventEntityModel, AnyCustomerServiceOrderEventEntityModelConverter } from '@abb-emobility/platform/domain-model';
import { AbstractCrudApiClient } from '@abb-emobility/shared/api-integration-foundation';

export class CustomerServiceOrderEventEntityApiClient extends AbstractCrudApiClient<AnyCustomerServiceOrderEventEntityModel> {
	protected collectionControllerUri = 'order-history/';
	protected paginationControllerUri = 'order-history/';
	protected entityControllerUri = 'order-history/{id}/';
	protected entitySearchControllerUri = 'order-history/';
	protected modelConverter = new AnyCustomerServiceOrderEventEntityModelConverter();
}
