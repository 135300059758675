import React, { ReactNode } from 'react';

import {
	createSearchStringFromFilterCriteria,
	CustomerServiceSearchDataInterceptEmpty,
	CustomerServiceSearchDataSuspense,
	useCustomerServiceSearchData
} from '@abb-emobility/platform/data-provider';
import {
	AnyCustomerServiceSearchResultModel,
	CustomerServiceSearchResultType
} from '@abb-emobility/platform/domain-model';
import { ModelFilter } from '@abb-emobility/shared/data-provider-foundation';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	ButtonGhost,
	Card,
	CollectionFooter,
	NotificationBig,
	NotificationBigStatus,
	SearchbarResultsHeader,
	SegmentHeader,
	Separator,
	SpinnerCircle
} from '@abb-emobility/shared/ui-primitive';

import { useActionbarContentModifier } from '../actionbar-content/ActionbarContent.hooks';
import { CustomerCollectionHeader } from '../customer-collection-header/CustomerCollectionHeader';
import { CustomerCollectionItem } from '../customer-collection-item/CustomerCollectionItem';
import { MarketplaceOrderCollectionHeader } from '../marketplace-order-collection-header/MarketplaceOrderCollectionHeader';
import { MarketplaceOrderCollectionItem } from '../marketplace-order-collection-item/MarketplaceOrderCollectionItem';
import { OrderCollectionHeader } from '../order-collection-header/OrderCollectionHeader';
import { OrderCollectionItem } from '../order-collection-item/OrderCollectionItem';

export function SearchResultCollection() {

	const l10n = useL10n();

	const searchData = useCustomerServiceSearchData();
	const isPaginated = searchData.isPaginated();
	const hasNextPage = searchData.hasNextPage();
	const fetchPending = searchData.pending();
	const fetchIdle = searchData.idle();
	const effectiveFilter = searchData.queryFilterCriteria() ?? [];
	const searchTerm = createSearchStringFromFilterCriteria(effectiveFilter);

	useActionbarContentModifier([], []);

	const customerModelFilter: ModelFilter<AnyCustomerServiceSearchResultModel> = (value) => {
		return value.type === CustomerServiceSearchResultType.CUSTOMER;
	};

	const orderModelFilter: ModelFilter<AnyCustomerServiceSearchResultModel> = (value) => {
		return value.type === CustomerServiceSearchResultType.ORDER;
	};

	const marketplaceOrderModelFilter: ModelFilter<AnyCustomerServiceSearchResultModel> = (value) => {
		return value.type === CustomerServiceSearchResultType.MARKETPLACE_ORDER;
	};

	const customerSearchResults = searchData.query(undefined, customerModelFilter);
	const orderSearchResults = searchData.query(undefined, orderModelFilter);
	const marketplaceOrderSearchResults = searchData.query(undefined, marketplaceOrderModelFilter);

	const handleShowMore = () => {
		searchData.fetchNext();
	};

	const renderEmptyState = (): ReactNode => {
		return (
			<NotificationBig
				status={NotificationBigStatus.EMPTY2}
				heading={l10n.translate('platformCustomerServiceApp.searchResultCollection.emptyState.heading')}
				message={l10n.translate('platformCustomerServiceApp.searchResultCollection.emptyState.message')}
			/>
		);
	};

	const renderIdleState = (): ReactNode => {
		return (
			<NotificationBig
				status={NotificationBigStatus.EMPTY2}
				heading={l10n.translate('platformCustomerServiceApp.searchResultCollection.idleState.heading')}
				message={l10n.translate('platformCustomerServiceApp.searchResultCollection.idleState.message')}
			/>
		);
	};

	const renderShowMore = (): ReactNode => {
		if (!isPaginated) {
			return;
		}
		return (
			<ButtonGhost
				label={l10n.translate('platformCustomerServiceApp.searchResultCollection.action.showMore')}
				onClick={handleShowMore}
				disabled={!hasNextPage || fetchPending}
			/>
		);
	};

	const renderCustomerSearchResults = () => {
		if (customerSearchResults.length === 0) {
			return null;
		}
		return (
			<>
				<SearchbarResultsHeader caption={l10n.translate('platformCustomerServiceApp.searchResultCollection.headingCustomers')} />
				<CustomerCollectionHeader />
				<Separator />
				{renderCustomerSearchResultItems()}
			</>
		);
	};

	const renderOrderSearchResults = () => {
		if (orderSearchResults.length === 0) {
			return null;
		}
		return (
			<>
				<SearchbarResultsHeader caption={l10n.translate('platformCustomerServiceApp.searchResultCollection.headingOrders')} />
				<OrderCollectionHeader />
				<Separator />
				{renderOrderSearchResultItems()}
			</>
		);
	};

	const renderMarketplaceOrderSearchResults = () => {
		if (marketplaceOrderSearchResults.length === 0) {
			return null;
		}
		return (
			<>
				<SearchbarResultsHeader caption={l10n.translate('platformCustomerServiceApp.searchResultCollection.headingMarketplaceOrders')} />
				<MarketplaceOrderCollectionHeader />
				<Separator />
				{renderMarketplaceOrderSearchResultItems()}
			</>
		);
	};

	const renderCustomerSearchResultItems = (): ReactNode => {
		return customerSearchResults.map((res) => {
			if (res.type !== CustomerServiceSearchResultType.CUSTOMER) {
				return null;
			}
			return (
				<CustomerCollectionItem key={res.id} customer={res.payload} />
			);
		});
	};

	const renderOrderSearchResultItems = (): ReactNode => {
		return orderSearchResults.map((res) => {
			if (res.type !== CustomerServiceSearchResultType.ORDER) {
				return null;
			}
			return (
				<OrderCollectionItem key={res.id} order={res.payload} />
			);
		});
	};

	const renderMarketplaceOrderSearchResultItems = (): ReactNode => {
		return marketplaceOrderSearchResults.map((res) => {
			if (res.type !== CustomerServiceSearchResultType.MARKETPLACE_ORDER) {
				return null;
			}
			return (
				<MarketplaceOrderCollectionItem key={res.id} order={res.payload} />
			);
		});
	};

	const resultHeaderSearchTerm = fetchIdle ?
		l10n.translate('platformCustomerServiceApp.searchResultCollection.heading') :
		l10n.translate('platformCustomerServiceApp.searchResultCollection.headingWithSearchterm') + ` "${searchTerm}"`;

	return (
		<Card>
			<SegmentHeader caption={resultHeaderSearchTerm} />

			<Separator />

			<section>
				<CustomerServiceSearchDataSuspense pendingComponent={SpinnerCircle} idleComponent={renderIdleState}>
					<CustomerServiceSearchDataInterceptEmpty emptyStateComponent={renderEmptyState}>

						{renderOrderSearchResults()}
						{renderMarketplaceOrderSearchResults()}
						{renderCustomerSearchResults()}

						<CollectionFooter>
							{renderShowMore()}
						</CollectionFooter>

					</CustomerServiceSearchDataInterceptEmpty>
				</CustomerServiceSearchDataSuspense>
			</section>
		</Card>
	)
		;
}
