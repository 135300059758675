import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	platformDataProvider: {
		error: {
			customerCollectionFetchMessage: 'Se ha producido un error al cargar los datos del cliente.',
			customerEntityFetchMessage: 'Se ha producido un error al cargar los detalles del cliente.',
			taskFetchMessage: 'Se ha producido un error al cargar los detalles de la tarea.',
			taskSearchMessage: 'Se ha producido un error durante la búsqueda de una tarea.',
			taskCollectionFetchMessage: 'Se ha producido un error al cargar los datos de la tarea.',
			orderFetchMessage: 'Se ha producido un error al cargar los detalles del pedido.',
			orderCollectionFetchMessage: 'Se ha producido un error al cargar los datos del pedido.',
			orderEventCollectionFetchMessage: 'Se ha producido un error al cargar el historial de pedidos.',
			orderEventEntityFetchMessage: 'Se ha producido un error al cargar el evento de pedido.',
			orderShipmentCollectionFetchMessage: 'Se ha producido un error al cargar los datos del envío.',
			orderAccountingCollectionFetchMessage: 'Se ha producido un error al cargar los datos de la cuenta.',
			orderFilesCollectionFetchMessage: 'Se ha producido un error al cargar los archivos.',
			orderInstallationCollectionFetchMessage: 'Se ha producido un error al cargar los datos de la instalación.',
			orderNotesCollectionFetchMessage: 'Se ha producido un error al cargar las notas.',
			orderInformationCollectionFetchMessage: 'Se ha producido un error al cargar la información del pedido.',
			installerAppointmentCollectionFetchMessage: 'Se ha producido un error al cargar las citas.',
			reportCollectionFetchMessage: 'Se ha producido un error al cargar los informes.',
			searchCollectionFetchMessage: 'Se ha producido un error durante la búsqueda de datos.',
			orderPaymentCollectionFetchMessage: 'Se ha producido un error al cargar los datos de pago.',
			orderInformationEntityFetchMessage: 'Se ha producido un error al cargar los detalles de información del pedido.'
		}
	}
};

export default literals;
