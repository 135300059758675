import React from 'react';
import { useParams } from 'react-router-dom';

import { CustomerServiceCustomerEntityDataProvider } from '@abb-emobility/platform/data-provider';
import { NotFoundError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { UiErrorHandler } from '@abb-emobility/shared/ui-error-handler';
import { SpinnerCircle } from '@abb-emobility/shared/ui-primitive';

import { CustomerDetail } from '../components/customer-detail/CustomerDetail';
import { useSearchbarContentModifier } from '../components/searchbar-content/SearchbarContent.hooks';

// SUGGESTION: understand why key={customerId} is necessary / remove it if possible
export function CustomerView() {

	const params = useParams();
	const customerId = params?.customerId ?? null;
	const l10n = useL10n();

	useSearchbarContentModifier(null, customerId, true);

	if (customerId === null) {
		throw new NotFoundError(l10n.translate('platformCustomerServiceApp.error.customerNotFound'));
	}

	return (
		<UiErrorHandler>
			<CustomerServiceCustomerEntityDataProvider
				pendingComponent={SpinnerCircle}
				primaryKey={customerId}
				key={customerId}
			>
				<CustomerDetail />
			</CustomerServiceCustomerEntityDataProvider>
		</UiErrorHandler>

	);
}
