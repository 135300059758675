import {
	AnyCustomerServiceOrderInformationApiClientFactory,
	AnyCustomerServiceOrderInformationApiClientInterface
} from '@abb-emobility/platform/api-integration';
import { AnyCustomerServiceOrderInformationModel } from '@abb-emobility/platform/domain-model';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import {
	createCrudEntitySlice,
	createCrudDeleteThunk,
	createCrudFetchEntityThunk,
	createCrudMutateThunk,
	createCrudSelectEntity,
	CrudEntityStore,
	CrudEntityStoreAccessor,
	ThunkApiConfig,
	createCrudSelectHypermediaLinks, createCrudEntityStoreSize, createCrudEntityStoreEntryIds
} from '@abb-emobility/shared/data-provider-foundation';
import { Nullable } from '@abb-emobility/shared/util';

// Basic definition
export const customerServiceOrderInformationEntityStoreName = 'customer-service-order-information-entity-crud';
export type CustomerServiceOrderInformationEntityStore = { [customerServiceOrderInformationEntityStoreName]: CrudEntityStore<AnyCustomerServiceOrderInformationModel> };

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the
const createApiClient = (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) => {
	return AnyCustomerServiceOrderInformationApiClientFactory.create(apiBaseUrl, jsonWebToken);
};
const fetchThunk = createCrudFetchEntityThunk<AnyCustomerServiceOrderInformationModel, CustomerServiceOrderInformationEntityStore, AnyCustomerServiceOrderInformationApiClientInterface>(customerServiceOrderInformationEntityStoreName, createApiClient);
const mutateThunk = createCrudMutateThunk<AnyCustomerServiceOrderInformationModel, AnyCustomerServiceOrderInformationApiClientInterface>(customerServiceOrderInformationEntityStoreName, createApiClient);
const deleteThunk = createCrudDeleteThunk<AnyCustomerServiceOrderInformationModel, AnyCustomerServiceOrderInformationApiClientInterface>(customerServiceOrderInformationEntityStoreName, createApiClient);

// Slice definition
export const customerServiceOrderInformationEntitySlice = createCrudEntitySlice<AnyCustomerServiceOrderInformationModel, ThunkApiConfig>(customerServiceOrderInformationEntityStoreName, fetchThunk, mutateThunk, deleteThunk);
const { resolveFetchStatus, resolveActionStatus, resetStore } = customerServiceOrderInformationEntitySlice.actions;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
const select = createCrudSelectEntity<AnyCustomerServiceOrderInformationModel, CustomerServiceOrderInformationEntityStore>(customerServiceOrderInformationEntityStoreName);
const selectHypermediaLinks = createCrudSelectHypermediaLinks<AnyCustomerServiceOrderInformationModel, CustomerServiceOrderInformationEntityStore>(customerServiceOrderInformationEntityStoreName);
const storeSize = createCrudEntityStoreSize<AnyCustomerServiceOrderInformationModel, CustomerServiceOrderInformationEntityStore>(customerServiceOrderInformationEntityStoreName);
const storeEntryIds = createCrudEntityStoreEntryIds<AnyCustomerServiceOrderInformationModel, CustomerServiceOrderInformationEntityStore>(customerServiceOrderInformationEntityStoreName);

// Export the store accessors to be used by the data provider value
export const installationPartnerOrderInformationEntityStoreAccessors = {
	fetch: fetchThunk,
	mutate: mutateThunk,
	delete: deleteThunk,
	resolveFetchStatus,
	resolveActionStatus,
	select,
	selectHypermediaLinks,
	resetStore,
	storeSize,
	storeEntryIds
} as CrudEntityStoreAccessor<AnyCustomerServiceOrderInformationModel, CustomerServiceOrderInformationEntityStore>;
