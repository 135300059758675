import { CustomerServiceOrderCollectionItemModel } from '@abb-emobility/platform/domain-model';
import { CrudApiClient } from '@abb-emobility/shared/api-integration-foundation';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import { Nullable } from '@abb-emobility/shared/util';

import { CustomerServiceOrderCollectionApiClient } from './CustomerServiceOrderCollectionApiClient';
import { CustomerServiceOrderCollectionApiClientMock } from './CustomerServiceOrderCollectionApiClientMock';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CustomerServiceOrderCollectionApiClientInterface extends CrudApiClient<CustomerServiceOrderCollectionItemModel> {
}

export class CustomerServiceOrderCollectionApiClientFactory {

	private static mockConfigured = false;

	public static create(baseUrl: string, jsonWebToken: Nullable<JsonWebToken> = null): CustomerServiceOrderCollectionApiClientInterface {
		if (process.env.NX_PLATFORM_CS_ORDER_API_MOCKED === 'true') {
			const apiClientMock = new CustomerServiceOrderCollectionApiClientMock().init(baseUrl, jsonWebToken);
			if (!this.mockConfigured) {
				apiClientMock.configureFetchMock();
				this.mockConfigured = true;
			}
			return apiClientMock;
		}
		return new CustomerServiceOrderCollectionApiClient().init(baseUrl, jsonWebToken);
	}

}
