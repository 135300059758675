import { CustomerServiceOrderApiClientFactory, CustomerServiceOrderApiClientInterface } from '@abb-emobility/platform/api-integration';
import { CustomerServiceOrderModel } from '@abb-emobility/platform/domain-model';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import {
	createCrudEntitySlice,
	createCrudDeleteThunk,
	createCrudFetchEntityThunk,
	createCrudMutateThunk,
	createCrudSelectEntity,
	CrudEntityStore,
	CrudEntityStoreAccessor,
	ThunkApiConfig,
	createCrudSelectHypermediaLinks, createCrudEntityStoreSize, createCrudEntityStoreEntryIds
} from '@abb-emobility/shared/data-provider-foundation';
import { Nullable } from '@abb-emobility/shared/util';

// Basic definition
export const customerServiceOrderEntityStoreName = 'customer-service-order-entity-crud';
export type CustomerServiceOrderEntityStore = { [customerServiceOrderEntityStoreName]: CrudEntityStore<CustomerServiceOrderModel> };

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the
const createApiClient = (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) => {
	return CustomerServiceOrderApiClientFactory.create(apiBaseUrl, jsonWebToken);
};
const fetchThunk = createCrudFetchEntityThunk<CustomerServiceOrderModel, CustomerServiceOrderEntityStore, CustomerServiceOrderApiClientInterface>(customerServiceOrderEntityStoreName, createApiClient);
const mutateThunk = createCrudMutateThunk<CustomerServiceOrderModel, CustomerServiceOrderApiClientInterface>(customerServiceOrderEntityStoreName, createApiClient);
const deleteThunk = createCrudDeleteThunk<CustomerServiceOrderModel, CustomerServiceOrderApiClientInterface>(customerServiceOrderEntityStoreName, createApiClient);

// Slice definition
export const customerServiceOrderEntitySlice = createCrudEntitySlice<CustomerServiceOrderModel, ThunkApiConfig>(customerServiceOrderEntityStoreName, fetchThunk, mutateThunk, deleteThunk);
const { resolveFetchStatus, resolveActionStatus, resetStore } = customerServiceOrderEntitySlice.actions;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
const select = createCrudSelectEntity<CustomerServiceOrderModel, CustomerServiceOrderEntityStore>(customerServiceOrderEntityStoreName);
const selectHypermediaLinks = createCrudSelectHypermediaLinks<CustomerServiceOrderModel, CustomerServiceOrderEntityStore>(customerServiceOrderEntityStoreName);
const storeSize = createCrudEntityStoreSize<CustomerServiceOrderModel, CustomerServiceOrderEntityStore>(customerServiceOrderEntityStoreName);
const storeEntryIds = createCrudEntityStoreEntryIds<CustomerServiceOrderModel, CustomerServiceOrderEntityStore>(customerServiceOrderEntityStoreName);

// Export the store accessors to be used by the data provider value
export const customerServiceOrderEntityStoreAccessors = {
	fetch: fetchThunk,
	mutate: mutateThunk,
	delete: deleteThunk,
	resolveFetchStatus,
	resolveActionStatus,
	select,
	selectHypermediaLinks,
	resetStore,
	storeSize,
	storeEntryIds
} as CrudEntityStoreAccessor<CustomerServiceOrderModel, CustomerServiceOrderEntityStore>;
