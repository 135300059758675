import React, { ReactNode, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { CustomerServiceOrderEntityDataProvider } from '@abb-emobility/platform/data-provider';
import { scrollElementIntoView } from '@abb-emobility/shared/browser';
import { TaskActionStatus } from '@abb-emobility/shared/data-provider-foundation';
import { DetailedErrorObject, restoreError, ValidationError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { UiErrorHandler } from '@abb-emobility/shared/ui-error-handler';
import {
	ButtonGhost,
	ButtonGroup,
	ButtonPrimary,
	SegmentHeader,
	Separator,
	SpinnerCircle,
	SubmissionStatus,
	useModalDialogue,
	useModalDialogueManager
} from '@abb-emobility/shared/ui-primitive';
import { Nullable } from '@abb-emobility/shared/util';
import { useAnyTaskEntityData } from '@abb-emobility/usertask/data-provider';
import { AddressValidationTaskModel, AddressPayloadModel, AddressValidationContext } from '@abb-emobility/usertask/domain-model';


import { UpdateAddressForm } from './AdressForm';
import { parseErrorReference } from './TaskAddressValidation.utils';
import { CustomerInformation } from '../../sections/customer-information/CustomerInformation';
import { OrderInformation } from '../../sections/order-information/OrderInformation';
import { TaskInformation } from '../../sections/task-information/TaskInformation';

import './TaskAddressValidation.scss';

export type AddressValidationProps = {
	task: AddressValidationTaskModel
};

export type AddressStateModel = {
	id: number
} & AddressPayloadModel;

export function TaskAddressValidation(props: AddressValidationProps) {

	const { task } = props;

	const l10n = useL10n();
	const modalDialogue = useModalDialogue();
	const modalDialogueManager = useModalDialogueManager();
	const anyTaskData = useAnyTaskEntityData();
	const actionErrorObject = anyTaskData.queryActionError();
	const validationErrorElementRef = useRef<Nullable<HTMLFormElement>>(null);

	const [taskCompletionButtonState, setTaskCompletionButtonState] = useState<SubmissionStatus>(SubmissionStatus.IDLE);
	const [addresses, setAddresses] = useState<Array<AddressStateModel>>(task.payload.invalidAddresses.map((address, idx) => ({ id: idx, ...address })));
	const [validationError, setValidationError] = useState<Nullable<DetailedErrorObject>>(null);
	const completionStatus = anyTaskData.queryActionStatus();
	const taskCompletionButtonDisabled = completionStatus === TaskActionStatus.COMPLETE_PENDING || completionStatus === TaskActionStatus.COMPLETE_SUCCESS;

	anyTaskData.useActionStatusEffect([TaskActionStatus.COMPLETE_PENDING], () => {
		setTaskCompletionButtonState(SubmissionStatus.PENDING);
	}, false);

	anyTaskData.useActionStatusEffect([TaskActionStatus.COMPLETE_SUCCESS], () => {
		setTaskCompletionButtonState(SubmissionStatus.DONE);
	}, true);

	anyTaskData.useActionStatusEffect([TaskActionStatus.IDLE], () => {
		setTaskCompletionButtonState(SubmissionStatus.IDLE);
	}, true);

	anyTaskData.useActionStatusEffect([TaskActionStatus.COMPLETE_FAILED], () => {
		if (actionErrorObject === null) {
			return;
		}
		const error = restoreError(actionErrorObject);
		if (error instanceof ValidationError) {
			setValidationError(error);
		}
	}, true);

	useLayoutEffect(() => {
		if (validationError !== null && validationErrorElementRef.current !== null) {
			scrollElementIntoView(validationErrorElementRef.current, { block: 'nearest' });
		}
	}, [validationError]);

	const getInvalidAddressIndexes = (): Array<number> => {
		if (validationError === null) {
			return [];
		}
		if (validationError.details !== undefined) {
			const invalidIndexes: Array<number> = [];
			for (const detail of validationError.details) {
				if (detail.reference !== undefined) {
					const parsedString = parseErrorReference(detail.reference);
					if (parsedString !== null) {
						invalidIndexes.push(parsedString);
					}
				}
			}
			return invalidIndexes;
		}
		return [];
	};
	const formIsReady = () => {
		let formReady = false;
		for (const address of addresses) {
			if (JSON.stringify(address.address) === JSON.stringify(task.payload.invalidAddresses[address.id].address)) {
				formReady = false;
				break;
			} else {
				formReady = true;
			}
		}
		return formReady;
	};

	const deliveryAddressIsValid = (): boolean => {
		let deliveryAddressValid = true;
		for (const address of addresses) {
			if (address.usage.includes(AddressValidationContext.DELIVERY)) {
				deliveryAddressValid = false;
			}
		}
		return deliveryAddressValid;
	};

	useEffect(() => {
		modalDialogue.setFooter(
			<ButtonGroup>
				<ButtonGhost
					label={l10n.translate('platformCustomerServiceApp.task.preCheck.button.close')}
					onClick={handleCancel}
				/>
				<ButtonPrimary
					label={l10n.translate('platformCustomerServiceApp.task.preCheck.button.submit')}
					submissionStatus={taskCompletionButtonState}
					onClick={handleTaskCompletion}
					disabled={taskCompletionButtonDisabled || !formIsReady()}
				/>
			</ButtonGroup>
		);
		return () => {
			modalDialogue.unsetFooter();
		};
	}, [taskCompletionButtonState, taskCompletionButtonDisabled, addresses]);

	const handleTaskCompletion = () => {
		anyTaskData.complete(task, {
			...task.payload,
			addressDraft: addresses
		});
	};

	const handleCancel = (): void => {
		modalDialogueManager.pop();
	};

	const updateAddress = (updatedAddress: AddressStateModel) => {
		setAddresses((prev) => {
			return prev.map((address) => {
				if (address.id === updatedAddress.id) {
					return updatedAddress;
				}
				return address;
			});
		});
	};

	const renderAddressCollection = (): ReactNode => {
		let errorCounter = 0;
		return addresses.map((invalidAddress, idx) => {

			const ref = (errorCounter === 0) ? validationErrorElementRef : undefined;
			errorCounter++;

			return (
				<div className={`${idx > 0 ? 'task-address-validation__section' : ''} `} key={invalidAddress.id}>
					<Separator />
					<UpdateAddressForm
						errorOccured={getInvalidAddressIndexes().includes(invalidAddress.id)}
						initialAddress={{ ...task.payload.invalidAddresses[invalidAddress.id], id: invalidAddress.id }}
						invalidAddress={invalidAddress}
						handleAddressChange={updateAddress}
						ref={ref}
					/>
				</div>
			);
		});
	};

	return (
		<>
			<TaskInformation task={task} type={task.type} />

			<UiErrorHandler>
				<CustomerServiceOrderEntityDataProvider
					primaryKey={task.orderId}
					pendingComponent={SpinnerCircle}
				>
					<OrderInformation />
				</CustomerServiceOrderEntityDataProvider>
			</UiErrorHandler>

			<CustomerInformation customer={task.payload.customer} showDeliveryAddress={deliveryAddressIsValid()} />
			<section className="task-address-validation">
				<SegmentHeader caption={l10n.translate('platformCustomerServiceApp.modal.task.section.addressValidation.heading')} />
				{renderAddressCollection()}
			</section>
		</>
	);
}
