import { CustomerServiceOrderEventPaymentFailedOrTimedOutDetailModel } from '@abb-emobility/platform/domain-model';
import { Dto } from '@abb-emobility/shared/domain-model-foundation';

import {
	customerServiceOrderInformationQuotationInformationModelMock
} from '../order-information/CustomerServiceOrderInformationQuotationInformationModel.mock';

export const customerServiceOrderEventPaymentFailedOrTimedOutDetailMock: Dto<CustomerServiceOrderEventPaymentFailedOrTimedOutDetailModel> = {
	quotation: customerServiceOrderInformationQuotationInformationModelMock
};
