import { CustomerServiceOrderModel } from '@abb-emobility/platform/domain-model';
import { CrudApiClient } from '@abb-emobility/shared/api-integration-foundation';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import { Nullable } from '@abb-emobility/shared/util';

import { CustomerServiceOrderApiClient } from './CustomerServiceOrderApiClient';
import { CustomerServiceOrderApiClientMock } from './CustomerServiceOrderApiClientMock';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CustomerServiceOrderApiClientInterface extends CrudApiClient<CustomerServiceOrderModel> {
}

export class CustomerServiceOrderApiClientFactory {

	private static mockConfigured = false;

	public static create(baseUrl: string, jsonWebToken: Nullable<JsonWebToken> = null): CustomerServiceOrderApiClientInterface {
		if (process.env.NX_PLATFORM_CS_ORDER_API_MOCKED === 'true') {
			const apiClientMock = new CustomerServiceOrderApiClientMock().init(baseUrl, jsonWebToken);
			if (!this.mockConfigured) {
				apiClientMock.configureFetchMock();
				this.mockConfigured = true;
			}
			return apiClientMock;
		}
		return new CustomerServiceOrderApiClient().init(baseUrl, jsonWebToken);
	}

}
