import { CustomerServiceMarketplaceOrderModel } from '@abb-emobility/platform/domain-model';
import { CrudApiClient } from '@abb-emobility/shared/api-integration-foundation';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import { Nullable } from '@abb-emobility/shared/util';

import { CustomerServiceMarketplaceOrderApiClient } from './CustomerServiceMarketplaceOrderApiClient';
import { CustomerServiceMarketplaceOrderApiClientMock } from './CustomerServiceMarketplaceOrderApiClientMock';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CustomerServiceMarketplaceOrderApiClientInterface extends CrudApiClient<CustomerServiceMarketplaceOrderModel> {
}

export class CustomerServiceMarketplaceOrderApiClientFactory {

	private static mockConfigured = false;

	public static create(baseUrl: string, jsonWebToken: Nullable<JsonWebToken> = null): CustomerServiceMarketplaceOrderApiClientInterface {
		if (process.env.NX_PLATFORM_CS_MARKETPLACE_ORDER_API_MOCKED === 'true') {
			const apiClientMock = new CustomerServiceMarketplaceOrderApiClientMock().init(baseUrl, jsonWebToken);
			if (!this.mockConfigured) {
				apiClientMock.configureFetchMock();
				this.mockConfigured = true;
			}
			return apiClientMock;
		}
		return new CustomerServiceMarketplaceOrderApiClient().init(baseUrl, jsonWebToken);
	}

}
