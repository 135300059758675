import { CurrencyCode } from '@abb-emobility/shared/currency';
import { AddressModel, AddressModelConverter } from '@abb-emobility/shared/domain-model';
import {
	ArrayOfTimestampFieldNames,
	CustomConversion,
	ModelConverter,
	ModelConverterInterface,
	TimestampFieldNames
} from '@abb-emobility/shared/domain-model-foundation';
import { IsoLanguageCode } from '@abb-emobility/shared/iso-language-code';

import { CustomerServiceCustomerModel, CustomerServiceCustomerModelConverter } from './CustomerServiceCustomerModel';
import { CustomerServiceOrderCustomerModel, CustomerServiceOrderCustomerModelConverter } from './CustomerServiceOrderCustomerModel';
import { CustomerServiceOrderLineItemModel } from './CustomerServiceOrderLineItemModel';
import { CustomerServiceMarketplaceOrderFoundationModel } from './foundation/CustomerServiceMarketplaceOrderFoundationModel';

export type CustomerServiceMarketplaceOrderModel = CustomerServiceMarketplaceOrderFoundationModel & {
	readonly customer: CustomerServiceOrderCustomerModel,
	readonly deliveryAddress: AddressModel,
	readonly orderLineItems: Array<CustomerServiceOrderLineItemModel>,
	readonly currencyCode: CurrencyCode,
	readonly phoneNumber: string
};

export class CustomerServiceMarketplaceOrderModelConverter extends ModelConverter<CustomerServiceMarketplaceOrderModel> {
	override getTimestampFields(): Array<TimestampFieldNames<CustomerServiceMarketplaceOrderModel> | ArrayOfTimestampFieldNames<CustomerServiceMarketplaceOrderModel>> {
		return ['orderDate'];
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	override getFieldConverterMapByModel() {
		return new Map<
			keyof CustomerServiceMarketplaceOrderModel,
			ModelConverterInterface<CustomerServiceOrderCustomerModel> | ModelConverterInterface<AddressModel> | ModelConverterInterface<CustomerServiceCustomerModel>
		>([
			['customer', new CustomerServiceOrderCustomerModelConverter()],
			['deliveryAddress', new AddressModelConverter()],
			['orderLineItems', new CustomerServiceCustomerModelConverter()]
		]);
	}

	override getCustomConversionFields(): Map<keyof CustomerServiceMarketplaceOrderModel, CustomConversion> {
		return new Map([[
			'languageCode', {
				toModel: (dtoValue: string) => {
					return dtoValue?.toLowerCase() as IsoLanguageCode;
				},
				toDto: (modelValue) => {
					return modelValue;
				}
			}
		]]);
	}
}
