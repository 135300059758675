import { ModelConverter } from '@abb-emobility/shared/domain-model-foundation';

export enum CustomerServiceCancelAppointmentsReason {
	'DELIVERY_DELAY'='DELIVERY_DELAY',
	'INSTALLATION_PARTNER_CONFLICT' = 'INSTALLATION_PARTNER_CONFLICT',
	'CUSTOMER_CONFLICT' = 'CUSTOMER_CONFLICT',
	'REPLACEMENT' = 'REPLACEMENT'
}

export type CustomerServiceCancelAppointmentsInvocationPayloadModel = {
	reason: CustomerServiceCancelAppointmentsReason,
	comment?: string
};

export class CustomerServiceCancelAppointmentsInvocationPayloadModelConverter extends ModelConverter<CustomerServiceCancelAppointmentsInvocationPayloadModel> {
}
