import { AnyAction, combineReducers, configureStore, MiddlewareArray } from '@reduxjs/toolkit';

import {
	customerServiceCustomerCollectionSlice,
	customerServiceCustomerCollectionStoreName,
	customerServiceOrderCollectionStoreName,
	customerServiceOrderCollectionSlice,
	customerServiceOrderEntitySlice,
	customerServiceOrderEntityStoreName,
	customerServiceOrderEventCollectionStoreName,
	customerServiceOrderEventCollectionSlice,
	customerServiceCustomerEntityStoreName,
	customerServiceCustomerEntitySlice,
	customerServiceCustomerOrderCollectionStoreName,
	customerServiceCustomerOrderCollectionSlice,
	customerServiceOrderEventEntityStoreName,
	customerServiceOrderEventEntitySlice,
	customerServiceOrderShipmentCollectionStoreName,
	customerServiceOrderShipmentCollectionSlice,
	customerServiceOrderDownloadFileCollectionStoreName,
	customerServiceOrderDownloadFileCollectionSlice,
	customerServiceOrderPaymentCollectionStoreName,
	customerServiceOrderPaymentCollectionSlice,
	customerServiceOrderInformationCollectionStoreName,
	customerServiceOrderInformationCollectionSlice,
	customerServiceOrderInstallationAppointmentCollectionStoreName,
	customerServiceOrderInstallationAppointmentCollectionSlice,
	customerServiceOrderInformationEntityStoreName,
	customerServiceOrderInformationEntitySlice,
	customerServiceSearchStoreName,
	customerServiceSearchSlice,
	customerServiceCustomerCommentCollectionSlice,
	customerServiceCustomerCommentCollectionStoreName,
	customerServiceOrderCommentCollectionStoreName,
	customerServiceOrderCommentCollectionSlice,
	customerServiceMarketplaceOrderCollectionStoreName,
	customerServiceMarketplaceOrderEntityStoreName,
	customerServiceMarketplaceOrderCollectionSlice,
	customerServiceMarketplaceOrderEntitySlice,
	customerServiceMarketplaceOrderPaymentCollectionSlice,
	customerServiceMarketplaceOrderPaymentCollectionStoreName,
	customerServiceCustomerMarketplaceOrderCollectionStoreName,
	customerServiceCustomerMarketplaceOrderCollectionSlice
} from '@abb-emobility/platform/data-provider';
import { sentryPerformanceMiddleware, sentrySpanManagementMiddleware } from '@abb-emobility/shared/sentry-integration';
import {
	anyTaskEntityStoreName,
	anyTaskEntitySlice,
	taskCollectionSlice,
	taskCollectionStoreName,
	orderTaskCollectionStoreName,
	orderTaskCollectionSlice
} from '@abb-emobility/usertask/data-provider';

const combinedReducer = combineReducers({
	[anyTaskEntityStoreName]: anyTaskEntitySlice.reducer,
	[taskCollectionStoreName]: taskCollectionSlice.reducer,
	[customerServiceCustomerCollectionStoreName]: customerServiceCustomerCollectionSlice.reducer,
	[customerServiceCustomerEntityStoreName]: customerServiceCustomerEntitySlice.reducer,
	[customerServiceOrderCollectionStoreName]: customerServiceOrderCollectionSlice.reducer,
	[customerServiceOrderEntityStoreName]: customerServiceOrderEntitySlice.reducer,
	[customerServiceMarketplaceOrderCollectionStoreName]: customerServiceMarketplaceOrderCollectionSlice.reducer,
	[customerServiceMarketplaceOrderEntityStoreName]: customerServiceMarketplaceOrderEntitySlice.reducer,
	[customerServiceCustomerOrderCollectionStoreName]: customerServiceCustomerOrderCollectionSlice.reducer,
	[customerServiceCustomerMarketplaceOrderCollectionStoreName]: customerServiceCustomerMarketplaceOrderCollectionSlice.reducer,
	[customerServiceOrderEventCollectionStoreName]: customerServiceOrderEventCollectionSlice.reducer,
	[customerServiceOrderEventEntityStoreName]: customerServiceOrderEventEntitySlice.reducer,
	[customerServiceOrderShipmentCollectionStoreName]: customerServiceOrderShipmentCollectionSlice.reducer,
	[customerServiceSearchStoreName]: customerServiceSearchSlice.reducer,
	[customerServiceOrderDownloadFileCollectionStoreName]: customerServiceOrderDownloadFileCollectionSlice.reducer,
	[customerServiceOrderPaymentCollectionStoreName]: customerServiceOrderPaymentCollectionSlice.reducer,
	[customerServiceMarketplaceOrderPaymentCollectionStoreName]: customerServiceMarketplaceOrderPaymentCollectionSlice.reducer,
	[customerServiceCustomerCommentCollectionStoreName]: customerServiceCustomerCommentCollectionSlice.reducer,
	[customerServiceOrderCommentCollectionStoreName]: customerServiceOrderCommentCollectionSlice.reducer,
	[customerServiceOrderInformationCollectionStoreName]: customerServiceOrderInformationCollectionSlice.reducer,
	[customerServiceOrderInformationEntityStoreName]: customerServiceOrderInformationEntitySlice.reducer,
	[customerServiceOrderInstallationAppointmentCollectionStoreName]: customerServiceOrderInstallationAppointmentCollectionSlice.reducer,
	[orderTaskCollectionStoreName]: orderTaskCollectionSlice.reducer
});

export type RootState = ReturnType<typeof combinedReducer> | undefined;

export const UNAUTHENTICATE_SIGNAL = '@@unauth';

const rootReducer = (state: RootState, action: AnyAction) => {
	if (action.type === UNAUTHENTICATE_SIGNAL) {
		state = undefined;
	}
	return combinedReducer(state, action);
};

export const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		let middleware: MiddlewareArray<any> = getDefaultMiddleware();
		if (process.env['NX_SENTRY_ENABLED'] === 'true') {
			middleware = middleware.concat(sentrySpanManagementMiddleware).concat(sentryPerformanceMiddleware);
		}
		return middleware;
	}
});
