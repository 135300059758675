import { CustomerServiceOrderEventAppointmentArrangedNotificationSentDetailModel } from '@abb-emobility/platform/domain-model';
import { Dto } from '@abb-emobility/shared/domain-model-foundation';

import {
	customerServiceOrderInstallationAppointmentCollectionMock
} from '../CustomerServiceOrderInstallationAppointmentModel.mock';

export const customerServiceOrderEventAppointmentArrangedMailSentDetailMock: Dto<CustomerServiceOrderEventAppointmentArrangedNotificationSentDetailModel> = {
	mailAddress: 'lisa-marie.kloster@fiege.com',
	appointments: customerServiceOrderInstallationAppointmentCollectionMock
};
