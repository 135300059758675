import React, { ReactNode } from 'react';

import { CustomerServiceOrderInformationApprovalInformationModel } from '@abb-emobility/platform/domain-model';
import { InstallationState } from '@abb-emobility/shared/domain-model';
import { DownloadFile } from '@abb-emobility/shared/domain-model-foundation';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	CollectionItemContentSection,
	DownloadList,
	DownloadListItem,
	Hint,
	HintLevel,
	IconIdentifier,
	ImageThumbnail,
	ImageThumbnailCollection,
	SectionHeader,
	Separator
} from '@abb-emobility/shared/ui-primitive';

type ApprovalProps = {
	approvalInformation: CustomerServiceOrderInformationApprovalInformationModel
};

export function Approval(props: ApprovalProps) {

	const { approvalInformation } = props;

	const l10n = useL10n();

	const renderNotification = (): ReactNode => {
		switch (approvalInformation.installationState) {
			case InstallationState.DONE:
				return (
					<Hint
						heading={l10n.translate('platformCustomerServiceApp.orderDetail.informationList.complete.success.heading')}
						level={HintLevel.SUCCESS}
						icon={IconIdentifier.CHECK}
					/>
				);
			default:
				return null;
		}
	};

	const renderImages = (files: Array<DownloadFile>): ReactNode => {
		return files.map((file, index) => renderImage(file, index));
	};

	const renderImage = (file?: DownloadFile, key?: number): ReactNode => {
		if (file === undefined || file === null || !file.meta.mimeType.startsWith('image/')) {
			return undefined;
		}
		return (<ImageThumbnail file={file} key={key} />);
	};

	const renderDocuments = (files: Array<DownloadFile>): ReactNode => {
		if (files.length === 0) {
			return null;
		}
		return (
			<DownloadList>
				{files.map((file, index) => renderDocument(file, index))}
			</DownloadList>
		);
	};

	const renderDocument = (file?: DownloadFile, key?: number): ReactNode => {
		if (file === undefined || file.meta.mimeType.startsWith('image/')) {
			return undefined;
		}
		return (<DownloadListItem file={file} key={key} />);
	};

	const renderImageSection = (): ReactNode => {
		if (approvalInformation.installationDocuments.length === 0) {
			return null;
		}
		return (
			<CollectionItemContentSection>
				<SectionHeader heading={l10n.translate('platformCustomerServiceApp.orderDetail.informationList.complete.images')} />
				<ImageThumbnailCollection>
					{renderImages(approvalInformation.installationDocuments)}
				</ImageThumbnailCollection>
				<Separator />
			</CollectionItemContentSection>
		);
	};

	const renderDocumentSection = (): ReactNode => {
		if (approvalInformation.installationDocuments.length === 0) {
			return null;
		}
		return (
			<CollectionItemContentSection>
				<SectionHeader heading={l10n.translate('platformCustomerServiceApp.orderDetail.informationList.complete.documents')} />
				{renderDocuments(approvalInformation.installationDocuments)}
				<Separator />
			</CollectionItemContentSection>
		);
	};

	const renderSignatureSection = (): ReactNode => {
		if (approvalInformation.customerSignature === undefined || approvalInformation.customerSignature === null) {
			return null;
		}
		return (
			<CollectionItemContentSection>
				<SectionHeader heading={l10n.translate('platformCustomerServiceApp.orderDetail.informationList.complete.signature')} />
				{renderImage(approvalInformation.customerSignature)}
			</CollectionItemContentSection>
		);
	};

	return (
		<>
			{renderNotification()}
			{renderImageSection()}
			{renderDocumentSection()}
			{renderSignatureSection()}
		</>
	);

}
