import { ReactNode } from 'react';

import './TabularInformationRow.scss';

export type TabularInformationRowProps = {
	children?: ReactNode,
	alignTop?: boolean
};

export function TabularInformationRow(props: TabularInformationRowProps) {
	const { children, alignTop } = props;

	return (
		<section className={`tabular-information-row ${alignTop ? 'tabular-information-row--top' : ''}`}>
			{children}
		</section>
	);
}
