import { ModelConverter } from '@abb-emobility/shared/domain-model-foundation';

import { CustomerServiceCommentType } from '../../AnyCustomerServiceCommentModel';
import { CustomerServiceCustomerCallReason } from '../../AnyCustomerServiceCommentModel.enums';
import {
	CustomerServiceAddNoteInvocationPayloadFoundationModel
} from '../../foundation/CustomerServiceAddNoteInvocationPayloadFoundationModel';

export type CustomerServiceAddCustomerCallNoteInvocationPayloadModel = CustomerServiceAddNoteInvocationPayloadFoundationModel & {
	commentType: CustomerServiceCommentType.CUSTOMER_CALL,
	contactReason: CustomerServiceCustomerCallReason,
	contactReasonOther?: string
};

export class CustomerServiceAddCustomerCallNoteInvocationPayloadModelConverter extends ModelConverter<CustomerServiceAddCustomerCallNoteInvocationPayloadModel> {
}
