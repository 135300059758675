import React from 'react';

import { AnyCustomerServiceOrderInformationCollectionItemModel } from '@abb-emobility/platform/domain-model';
import { FilterCriteria, SingleValueFilterComparator, SortCriteria, SortDirection } from '@abb-emobility/shared/api-integration-foundation';
import { createAccessTokenLoader, useAuth } from '@abb-emobility/shared/auth-provider';
import { FetchError } from '@abb-emobility/shared/business-error';
import {
	CollectionDataProviderProps,
	createCrudCollectionDataProviderValue,
	FetchMode,
	useDataProviderFetchFailedHandler,
	useDataProviderFetchInit,
	useDataProviderFetchSuspense
} from '@abb-emobility/shared/data-provider-foundation';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { useEnv } from '@abb-emobility/shared/environment';
import { AppError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { Optional } from '@abb-emobility/shared/util';

import { customerServiceOrderInformationCollectionData } from './CustomerServiceOrderInformationCollectionData';
import {
	CustomerServiceOrderInformationCollectionCrudStore,
	customerServiceOrderInformationCollectionStoreAccessors,
	customerServiceOrderInformationCollectionStoreName
} from './CustomerServiceOrderInformationCollectionSlice';

type CustomerServiceOrderInformationCollectionDataProviderProps =
	Omit<CollectionDataProviderProps<AnyCustomerServiceOrderInformationCollectionItemModel>, 'filterCriteria' | 'sortCriteria'>
	& { orderId: ModelPrimaryKey };

export function CustomerServiceOrderInformationCollectionDataProvider(props: CustomerServiceOrderInformationCollectionDataProviderProps) {
	const {
		fetchMode = FetchMode.IMMEDIATE,
		suspense = fetchMode === FetchMode.IMMEDIATE,
		pendingComponent = null,
		forceFetch,
		orderId
	} = props;

	const env = useEnv();
	const auth = useAuth();
	const l10n = useL10n();

	const apiBaseUrl = new Optional(process.env['NX_CUSTOMER_SERVICE_API_BASE_URL']).getOrThrow(new AppError('API base URL unavailable'));

	const dataProviderValue = createCrudCollectionDataProviderValue<AnyCustomerServiceOrderInformationCollectionItemModel, CustomerServiceOrderInformationCollectionCrudStore>(
		customerServiceOrderInformationCollectionStoreName,
		customerServiceOrderInformationCollectionStoreAccessors,
		apiBaseUrl,
		createAccessTokenLoader(auth, env)
	);

	useDataProviderFetchFailedHandler(dataProviderValue, new FetchError(l10n.translate('platformDataProvider.error.orderInformationCollectionFetchMessage')));

	const sortCriteria: SortCriteria<AnyCustomerServiceOrderInformationCollectionItemModel> = [
		{ property: 'createdAt', direction: SortDirection.ASCENDING }
	];
	const filterCriteria: FilterCriteria<AnyCustomerServiceOrderInformationCollectionItemModel> = [{
		id: 'ORDER_ID',
		criteria: [{
			property: 'orderId',
			comparator: SingleValueFilterComparator.EQUAL,
			value: orderId
		}]
	}];
	useDataProviderFetchInit(fetchMode, () => dataProviderValue.fetch(sortCriteria, filterCriteria, forceFetch));

	return useDataProviderFetchSuspense(dataProviderValue, suspense, pendingComponent).getOrDefault(
		<customerServiceOrderInformationCollectionData.Provider value={dataProviderValue}>
			{props.children}
		</customerServiceOrderInformationCollectionData.Provider>
	);

}
