import { removeWhitespace } from '@abb-emobility/shared/util';

export const formatIban = (iban: string, separator = ' ') => {
	iban = removeWhitespace(iban);
	const formattedIbanParts: Array<string> = [];
	for (let i = 0; i < iban.length; i += 4) {
		formattedIbanParts.push(iban.substring(i, i + 4));
	}
	return formattedIbanParts.join(separator);
};
