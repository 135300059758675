import React from 'react';

import { AnyCustomerServiceCommentModel } from '@abb-emobility/platform/domain-model';
import { FilterCriteria, SingleValueFilterComparator, SortCriteria, SortDirection } from '@abb-emobility/shared/api-integration-foundation';
import { createAccessTokenLoader, useAuth } from '@abb-emobility/shared/auth-provider';
import { FetchError } from '@abb-emobility/shared/business-error';
import {
	CollectionDataProviderProps,
	createCrudCollectionDataProviderValue,
	FetchMode,
	useDataProviderFetchFailedHandler,
	useDataProviderFetchInit,
	useDataProviderFetchSuspense
} from '@abb-emobility/shared/data-provider-foundation';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { useEnv } from '@abb-emobility/shared/environment';
import { AppError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { Optional } from '@abb-emobility/shared/util';

import { customerServiceOrderCommentCollectionData } from './CustomerServiceOrderCommentCollectionData';
import {
	CustomerServiceOrderCommentCollectionStore,
	customerServiceOrderCommentCollectionStoreAccessors,
	customerServiceOrderCommentCollectionStoreName
} from './CustomerServiceOrderCommentCollectionSlice';

export type CustomerServiceOrderCommentCollectionDataProviderProps = {
	orderId: ModelPrimaryKey,
	customerId: ModelPrimaryKey
} & Omit<CollectionDataProviderProps<AnyCustomerServiceCommentModel>, 'filterCriteria' | 'sortCriteria'>;

export function CustomerServiceOrderCommentCollectionDataProvider(props: CustomerServiceOrderCommentCollectionDataProviderProps) {
	const {
		fetchMode = FetchMode.IMMEDIATE,
		suspense = fetchMode === FetchMode.IMMEDIATE,
		pendingComponent = null,
		forceFetch,
		orderId,
		customerId
	} = props;

	const auth = useAuth();
	const env = useEnv();
	const l10n = useL10n();

	const apiBaseUrl = new Optional(process.env['NX_CUSTOMER_SERVICE_API_BASE_URL']).getOrThrow(new AppError('API base URL unavailable'));

	const dataProviderValue = createCrudCollectionDataProviderValue<AnyCustomerServiceCommentModel, CustomerServiceOrderCommentCollectionStore>(
		customerServiceOrderCommentCollectionStoreName,
		customerServiceOrderCommentCollectionStoreAccessors,
		apiBaseUrl,
		createAccessTokenLoader(auth, env)
	);

	useDataProviderFetchFailedHandler(dataProviderValue, new FetchError(l10n.translate('platformDataProvider.error.orderNotesCollectionFetchMessage')));

	const sortCriteria: SortCriteria<AnyCustomerServiceCommentModel> = [{
		property: 'createdAt',
		direction: SortDirection.DESCENDING
	}];
	const filterCriteria: FilterCriteria<AnyCustomerServiceCommentModel> = [{
		id: 'order',
		criteria: []
	}];

	filterCriteria[0].criteria.push({
		property: 'orderId',
		value: orderId,
		comparator: SingleValueFilterComparator.EQUAL
	});

	filterCriteria[0].criteria.push({
		property: 'customerId',
		value: customerId,
		comparator: SingleValueFilterComparator.EQUAL
	});

	useDataProviderFetchInit(fetchMode, () => dataProviderValue.fetch(sortCriteria, filterCriteria, forceFetch));

	return useDataProviderFetchSuspense(dataProviderValue, suspense, pendingComponent).getOrDefault(
		<customerServiceOrderCommentCollectionData.Provider value={dataProviderValue}>
			{props.children}
		</customerServiceOrderCommentCollectionData.Provider>
	);

}
