import React, { useEffect, useState } from 'react';

import {
	CustomerServiceUpdateCustomerInvocationPayloadModelConverter,
	CustomerServiceUpdateCustomerPayloadModelConverter
} from '@abb-emobility/platform/domain-model';
import { CommandStatus, useCommandData } from '@abb-emobility/shared/command-api';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	ActionModalContentSection,
	ActionModalFooter,
	ButtonGhost,
	ButtonPrimary,
	InputText,
	NotificationBig,
	NotificationBigStatus,
	SectionHeader,
	SpinnerCircle,
	SubmissionStatus,
	SuccessFeeback,
	SuccessImageIdentifier,
	useModalDialogue,
	useModalDialogueManager
} from '@abb-emobility/shared/ui-primitive';
import { Nullable } from '@abb-emobility/shared/util';

import { HypermediaAction } from '../../ActionModalFactory.types';

export type UpdateCustomerActionModalProps = {
	action: HypermediaAction
};

export function UpdateCustomerActionModal(props: UpdateCustomerActionModalProps) {

	const { action } = props;

	const l10n = useL10n();
	const modalDialogue = useModalDialogue();
	const dialogueManager = useModalDialogueManager();

	const commandData = useCommandData(
		action.hypermediaLink,
		new CustomerServiceUpdateCustomerPayloadModelConverter(),
		new CustomerServiceUpdateCustomerInvocationPayloadModelConverter()
	);
	const fetchPayload = commandData.query();
	const fetchError = commandData.queryFetchError();
	const invokeCommandStatus = commandData.queryInvocationStatus();
	const invokeCommandError = commandData.queryInvocationError();

	const [mailAddress, setMailAddress] = useState<Nullable<string>>(null);

	const footerCancelLabel = l10n.translate('platformCustomerServiceApp.actionModal.updateCustomer.footer.cancel');
	const footerConfirmlabel = l10n.translate('platformCustomerServiceApp.actionModal.updateCustomer.footer.confirm');

	useEffect(() => {
		if (fetchPayload === null) {
			return;
		}
		setMailAddress(fetchPayload.mailAddress);
	}, [fetchPayload]);

	useEffect(() => {
		const handleSubmitAction = () => {
			if (mailAddress !== null) {
				commandData.invoke({
					mailAddress: mailAddress
				});
			}
		};

		const isFormValid = ((mailAddress?.length ?? 0) > 0) && (mailAddress !== fetchPayload?.mailAddress);

		switch (invokeCommandStatus) {
			case CommandStatus.IDLE:
				modalDialogue.setFooter(
					<ActionModalFooter>
						<ButtonGhost
							label={footerCancelLabel}
							onClick={handleDismissDialogue}
						/>
						<ButtonPrimary
							label={footerConfirmlabel}
							submissionStatus={SubmissionStatus.IDLE}
							onClick={handleSubmitAction}
							disabled={!isFormValid}
						/>
					</ActionModalFooter>
				);
				break;
			case CommandStatus.PENDING:
				modalDialogue.setFooter(
					<ActionModalFooter>
						<ButtonGhost
							label={footerCancelLabel}
							onClick={handleDismissDialogue}
						/>
						<ButtonPrimary
							label={footerConfirmlabel}
							submissionStatus={SubmissionStatus.PENDING}
							onClick={handleSubmitAction}
						/>
					</ActionModalFooter>
				);
				break;
			case CommandStatus.SUCCESSFULL:
			case CommandStatus.FAILED:
				modalDialogue.unsetFooter();
				break;
		}

	}, [invokeCommandStatus, mailAddress]);

	const handleDismissDialogue = () => {
		dialogueManager.pop();
	};

	const handleCustomerMailInputChange = (value: string): void => {
		setMailAddress(value);
	};

	if (fetchPayload === null) {
		return (
			<SpinnerCircle />
		);
	}

	if (fetchError) {
		return (
			<NotificationBig
				status={NotificationBigStatus.ERROR}
				heading={l10n.translate('platformCustomerServiceApp.actionModal.updateCustomer.error.loading.heading')}
				message={l10n.translate('platformCustomerServiceApp.actionModal.updateCustomer.error.loading.message')}
				subline={fetchError?.message}
			/>
		);
	}

	if (invokeCommandStatus === CommandStatus.SUCCESSFULL) {
		return (
			<SuccessFeeback
				heading={l10n.translate('platformCustomerServiceApp.actionModal.updateCustomer.content.successHeading')}
				image={SuccessImageIdentifier.IMAGE4}
			>
				<ButtonGhost
					label={l10n.translate('platformCustomerServiceApp.actionModal.updateCustomer.content.successCloseButtonLabel')}
					onClick={handleDismissDialogue}
				/>
			</SuccessFeeback>
		);
	}

	if (invokeCommandStatus === CommandStatus.FAILED) {
		return (
			<NotificationBig
				status={NotificationBigStatus.ERROR}
				heading={l10n.translate('platformCustomerServiceApp.actionModal.updateCustomer.error.writing.heading')}
				message={l10n.translate('platformCustomerServiceApp.actionModal.updateCustomer.error.writing.message')}
				subline={invokeCommandError?.message}
			/>
		);
	}

	return (
		<ActionModalContentSection>
			<SectionHeader heading={l10n.translate('platformCustomerServiceApp.actionModal.updateCustomer.content.contactDataHeading')} />
			<InputText
				label={l10n.translate('platformCustomerServiceApp.actionModal.updateCustomer.content.email')}
				value={mailAddress ?? ''}
				onChange={handleCustomerMailInputChange}
			/>
		</ActionModalContentSection>
	);
}
