import React, { ReactNode } from 'react';

import {
	CustomerServiceCustomerCollectionDataInterceptEmpty,
	CustomerServiceCustomerCollectionDataSuspense,
	useCustomerServiceCustomerCollectionData
} from '@abb-emobility/platform/data-provider';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	ButtonGhost,
	Card,
	CollectionFooter,
	NotificationBig,
	NotificationBigStatus,
	SegmentHeader,
	Separator,
	SpinnerCircle
} from '@abb-emobility/shared/ui-primitive';

import { useActionbarContentModifier } from '../actionbar-content/ActionbarContent.hooks';
import { CustomerCollectionHeader } from '../customer-collection-header/CustomerCollectionHeader';
import { CustomerCollectionItem } from '../customer-collection-item/CustomerCollectionItem';

export function CustomerCollection() {
	const l10n = useL10n();

	const customerCollectionData = useCustomerServiceCustomerCollectionData();

	const isPaginated = customerCollectionData.isPaginated();
	const hasNextPage = customerCollectionData.hasNextPage();
	const fetchPending = customerCollectionData.pending();
	const customers = customerCollectionData.query();

	useActionbarContentModifier([], []);

	const handleShowMore = () => {
		customerCollectionData.fetchNext();
	};

	const renderEmptyState = (): ReactNode => {
		return (
			<NotificationBig
				status={NotificationBigStatus.EMPTY2}
				heading={l10n.translate('platformCustomerServiceApp.customerCollection.emptyState.heading')}
				message={l10n.translate('platformCustomerServiceApp.customerCollection.emptyState.message')}
			/>
		);
	};
	const renderShowMore = (): ReactNode => {
		if (!isPaginated) {
			return;
		}
		return (
			<ButtonGhost
				label={l10n.translate('platformCustomerServiceApp.customerCollection.action.showMore')}
				onClick={handleShowMore}
				disabled={!hasNextPage || fetchPending}
			/>
		);
	};

	const renderCustomerCollectionItems = () => {
		return customers.map((customer): React.JSX.Element => {
			return (
				<CustomerCollectionItem
					customer={customer}
					key={customer.id}
				/>
			);
		});
	};

	return (
		<Card>
			<SegmentHeader caption={l10n.translate('platformCustomerServiceApp.customerCollection.heading')} />

			<Separator />

			<CustomerServiceCustomerCollectionDataSuspense pendingComponent={SpinnerCircle}>
				<CustomerServiceCustomerCollectionDataInterceptEmpty emptyStateComponent={renderEmptyState}>

					<CustomerCollectionHeader />
					<Separator />

					{renderCustomerCollectionItems()}

					<CollectionFooter>
						{renderShowMore()}
					</CollectionFooter>

				</CustomerServiceCustomerCollectionDataInterceptEmpty>
			</CustomerServiceCustomerCollectionDataSuspense>
		</Card>
	);
}
