import { CustomerServiceReplaceOrderPayloadModel } from '@abb-emobility/platform/domain-model';
import { Dto } from '@abb-emobility/shared/domain-model-foundation';

import {
	customerServiceOrderLineItemModelMock01,
	customerServiceOrderLineItemModelMock02,
	customerServiceOrderLineItemModelMock03
} from '../../CustomerServiceOrderLineItemModel.mock';

export const customerServiceReplaceOrderPayloadModelMock: Dto<CustomerServiceReplaceOrderPayloadModel> = {
	replaceableOrderLineItems: [
		{
			orderLineItem: customerServiceOrderLineItemModelMock01(),
			replaceable: false
		},
		{
			orderLineItem: customerServiceOrderLineItemModelMock02(),
			replaceable: true
		},
		{
			orderLineItem: customerServiceOrderLineItemModelMock03(),
			replaceable: true
		}
	]
};
