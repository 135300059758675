import { OrderReturnReason } from '@abb-emobility/shared/domain-model';
import { ModelConverter } from '@abb-emobility/shared/domain-model-foundation';

import { CustomerServiceOrderLineItemCommandInvocationPayloadModal } from './common/CustomerServiceOrderLineItemInvocationPayloadModel';

export type CustomerServiceReturnOrderInvocationPayloadModel = {
	orderLineItems: Array<CustomerServiceOrderLineItemCommandInvocationPayloadModal>,
	reason: OrderReturnReason,
	comment?: string
};

export class CustomerServiceReturnOrderInvocationPayloadModelConverter extends ModelConverter<CustomerServiceReturnOrderInvocationPayloadModel> {
}
