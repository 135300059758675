import { CustomerServiceOrderCollectionItemModel, CustomerServiceOrderCollectionItemModelConverter } from '@abb-emobility/platform/domain-model';
import { AbstractCrudApiClient } from '@abb-emobility/shared/api-integration-foundation';

export class CustomerServiceOrderCollectionApiClient extends AbstractCrudApiClient<CustomerServiceOrderCollectionItemModel> {
	protected collectionControllerUri = 'orders/';
	protected paginationControllerUri = 'orders/';
	protected entityControllerUri = 'order/{id}/';
	protected entitySearchControllerUri = 'order/';
	protected modelConverter = new CustomerServiceOrderCollectionItemModelConverter();
}
