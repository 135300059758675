import React, { ReactNode } from 'react';

import { CustomerServiceOrderInformationPrecheckModel } from '@abb-emobility/platform/domain-model';
import { DecisionType } from '@abb-emobility/shared/domain-model';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	BasicInformation,
	CollectionItemContentSection,
	Hint,
	HintLevel,
	IconIdentifier,
	OrderComment,
	SectionHeader
} from '@abb-emobility/shared/ui-primitive';
import { l10nLiteralFromEnumValue } from '@abb-emobility/shared/util';

import './OrderInformationPrecheck.scss';

type PrecheckProps = {
	orderInformation: CustomerServiceOrderInformationPrecheckModel
};

export function OrderInformationPrecheck(props: PrecheckProps) {

	const { orderInformation } = props;

	const l10n = useL10n();

	const renderNotification = (): ReactNode => {
		let hintHeading = l10n.translate(l10nLiteralFromEnumValue(orderInformation.payload.precheckDecision.decision, 'platformCustomerServiceApp.orderDetail.informationList.precheck.decision'));
		if (orderInformation.payload.precheckDecision.decision === DecisionType.ACCEPTED) {
			hintHeading += ': ' + l10n.translate(l10nLiteralFromEnumValue(orderInformation.payload.precheckDecision.installationType, 'platformCustomerServiceApp.orderDetail.informationList.precheck.installationType'));
		}
		switch (orderInformation.payload.precheckDecision.decision) {
			case DecisionType.ACCEPTED:
				return (<Hint
					heading={hintHeading}
					level={HintLevel.SUCCESS}
					icon={IconIdentifier.CHECK}
				/>);
			case DecisionType.PENDING:
				return (<Hint
					heading={hintHeading}
					level={HintLevel.WARNING}
					icon={IconIdentifier.WARNING}
				/>);
			case DecisionType.DENIED:
				return (<Hint
					heading={hintHeading}
					level={HintLevel.ERROR}
					icon={IconIdentifier.WARNING}
				/>);
			default:
				return null;
		}
	};

	const renderEfforts = (): ReactNode => {
		if (orderInformation.payload.requestedEffort.length === 0) {
			return;
		}
		const efforts = orderInformation.payload.requestedEffort.map((effort) => {
			return <BasicInformation
				key={effort.trade}
				label={effort.trade}
				description={`${l10n.formatNumber(effort.expectedEffort, 0)} ${l10n.translate('platformCustomerServiceApp.orderDetail.informationList.precheck.hours')}`} />;
		});
		return (
			<CollectionItemContentSection>
				<SectionHeader heading={l10n.translate('platformCustomerServiceApp.orderDetail.informationList.precheck.effort')} />
				{efforts}
			</CollectionItemContentSection>
		);
	};

	const renderComment = (): ReactNode => {
		if (orderInformation.comment) {
			return (
				<CollectionItemContentSection>
					<SectionHeader heading={l10n.translate('platformCustomerServiceApp.orderDetail.informationList.precheck.notes')} />
					<OrderComment comment={orderInformation.comment} />
				</CollectionItemContentSection>
			);
		}
		return;
	};

	return (
		<>
			{renderNotification()}
			{renderEfforts()}
			{renderComment()}
		</>
	);

}
