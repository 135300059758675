import { ReactNode } from 'react';

import './TimelineSection.scss';

export type TimelineSectionProps = {
	label: string,
	children: ReactNode
};

export const TimelineSection = (props: TimelineSectionProps) => {
	const { label, children } = props;

	return (
		<section className="timeline-section">
			<h2 className="timeline-section__header">{label}</h2>
			<main className="timeline-section__items">
				{children}
			</main>
		</section>
	);
};
