import React from 'react';

import { InstallationPartnerModel } from '@abb-emobility/shared/domain-model';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	SegmentHeader,
	Separator,
	TabularInformationItem,
	TabularInformationItemType,
	TabularInformationRow
} from '@abb-emobility/shared/ui-primitive';

export type InstallationPartnerInformationProps = {
	installationPartner: InstallationPartnerModel
};

export function InstallationPartnerInformation(props: InstallationPartnerInformationProps) {

	const { installationPartner } = props;

	const l10n = useL10n();

	const mailAddress = installationPartner.mailAddress ?? null;
	const phone = installationPartner.phone ?? null;

	const renderMailAddress = () => {
		if (mailAddress === null) {
			return null;
		}
		return (
			<TabularInformationRow>
				<TabularInformationItem
					label={l10n.translate('platformCustomerServiceApp.modal.task.section.installationPartnerInformation.mail')}
					variant={TabularInformationItemType.GREY}
				/>
				<TabularInformationItem label={mailAddress} />
			</TabularInformationRow>
		);
	};

	const renderPhone = () => {
		if (phone === null) {
			return null;
		}
		return (
			<TabularInformationRow>
				<TabularInformationItem
					label={l10n.translate('platformCustomerServiceApp.modal.task.section.installationPartnerInformation.phone')}
					variant={TabularInformationItemType.GREY}
				/>
				<TabularInformationItem label={phone} />
			</TabularInformationRow>
		);
	};

	return (
		<section>
			<SegmentHeader caption={l10n.translate('platformCustomerServiceApp.modal.task.section.installationPartnerInformation.heading')} />
			<Separator />
			<TabularInformationRow>
				<TabularInformationItem
					label={l10n.translate('platformCustomerServiceApp.modal.task.section.installationPartnerInformation.position')}
					variant={TabularInformationItemType.HEADING} />
				<TabularInformationItem
					label={l10n.translate('platformCustomerServiceApp.modal.task.section.installationPartnerInformation.description')}
					variant={TabularInformationItemType.HEADING} />
			</TabularInformationRow>

			<TabularInformationRow>
				<TabularInformationItem
					label={l10n.translate('platformCustomerServiceApp.modal.task.section.installationPartnerInformation.name')}
					variant={TabularInformationItemType.GREY}
				/>
				<TabularInformationItem label={installationPartner.name} />
			</TabularInformationRow>

			{renderMailAddress()}
			{renderPhone()}

		</section>
	);
}
