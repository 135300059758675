import { AnyCustomerServiceOrderShipmentModel, AnyCustomerServiceOrderShipmentModelConverter } from '@abb-emobility/platform/domain-model';
import { AbstractCrudApiClient } from '@abb-emobility/shared/api-integration-foundation';

export class CustomerServiceOrderShipmentApiClient extends AbstractCrudApiClient<AnyCustomerServiceOrderShipmentModel> {
	protected collectionControllerUri = 'order-shipments/';
	protected paginationControllerUri = 'order-shipments/';
	protected entityControllerUri = 'order-shipment/{id}/';
	protected entitySearchControllerUri = 'order-shipment/';
	protected modelConverter = new AnyCustomerServiceOrderShipmentModelConverter();
}
