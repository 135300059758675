import { ModelConverter, ModelConverterInterface } from '@abb-emobility/shared/domain-model-foundation';

import { CustomerServiceOrderPaymentModel, CustomerServiceOrderPaymentModelConverter } from '../CustomerServiceOrderPaymentModel';
import {
	CustomerServiceOrderInformationQuotationInformationModel, CustomerServiceOrderInformationQuotationInformationModelConverter
} from '../order-information/CustomerServiceOrderInformationQuotationInformationModel';

export type CustomerServiceOrderEventPaymentConfirmationMailSentDetailModel = {
	mailAddress: string,
	quotation?: CustomerServiceOrderInformationQuotationInformationModel,
	paymentData?: Array<CustomerServiceOrderPaymentModel>,
};

export class CustomerServiceOrderEventPaymentConfirmationMailSentDetailModelConverter extends ModelConverter<CustomerServiceOrderEventPaymentConfirmationMailSentDetailModel> {

	override getFieldConverterMapByModel() {
		return new Map<
			keyof CustomerServiceOrderEventPaymentConfirmationMailSentDetailModel,
			ModelConverterInterface<CustomerServiceOrderInformationQuotationInformationModel> | ModelConverterInterface<CustomerServiceOrderPaymentModel>
		>([
			['quotation', new CustomerServiceOrderInformationQuotationInformationModelConverter()],
			['paymentData', new CustomerServiceOrderPaymentModelConverter()]
		]);
	}
}
