import React from 'react';

import { CustomerServiceOrderLineItemModel, PricingModel } from '@abb-emobility/platform/domain-model';
import { CurrencyCode, calculateMajorUnitFromMinorUnit, getCurrencyByCode } from '@abb-emobility/shared/currency';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { SegmentHeader, Separator, TabularInformationItem, TabularInformationRow } from '@abb-emobility/shared/ui-primitive';

import { OrderLineItem } from '../order-line-item/OrderLineItem';
import { OrderLineItemCollectionHeader } from '../order-line-item-collection-header/OrderLineItemCollectionHeader';

export type OrderLineItemsCollectionProps = {
	orderLineItems: Array<CustomerServiceOrderLineItemModel>
	currencyCode?: CurrencyCode
	totalGrossPrice?: PricingModel
};

export function OrderLineItemCollection(props: OrderLineItemsCollectionProps) {

	const { orderLineItems, currencyCode, totalGrossPrice } = props;

	const l10n = useL10n();

	const renderOrderLineItems = () => {
		if (orderLineItems.length === 0) {
			return null;
		}

		return orderLineItems.map((item, idx) => {
				return (
					<OrderLineItem key={item.id} position={idx} orderLineItem={item} currencyCode={currencyCode} />
				);
			}
		);
	};

	const renderTotalPrice = () => {
		if (totalGrossPrice === undefined) {
			return null;
		}

		const currency = getCurrencyByCode(totalGrossPrice.currency);
		if (currency === null) {
			return null;
		}

		return (
			<>
				<Separator />
				<TabularInformationRow>
					<TabularInformationItem label="" />
					<TabularInformationItem label="" />
					<TabularInformationItem
						label={l10n.translate('platformCustomerServiceApp.orderLineItemCollection.totalPrice')}
						flex={1}
					/>
					<TabularInformationItem
						label={`${l10n.formatNumber(calculateMajorUnitFromMinorUnit(totalGrossPrice.currency, totalGrossPrice.amount), currency.fraction)} ${currency.symbol}`}
					/>
				</TabularInformationRow>
			</>

		);
	};

	return (
		<>
			<SegmentHeader caption={l10n.translate('platformCustomerServiceApp.orderLineItemCollection.heading')} />
			<OrderLineItemCollectionHeader />
			{renderOrderLineItems()}
			{renderTotalPrice()}
		</>
	);
}
