import { CustomerServiceOrderModel, CustomerServiceOrderModelConverter } from '@abb-emobility/platform/domain-model';
import { AbstractCrudApiClient } from '@abb-emobility/shared/api-integration-foundation';

export class CustomerServiceOrderApiClient extends AbstractCrudApiClient<CustomerServiceOrderModel> {
	protected collectionControllerUri = 'orders/';
	protected paginationControllerUri = 'orders/';
	protected entityControllerUri = 'order/{id}/';
	protected entitySearchControllerUri = 'order/';
	protected modelConverter = new CustomerServiceOrderModelConverter();
}
