import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import {
	platformDataProviderLiteralsDe,
	platformDataProviderLiteralsEn,
	platformDataProviderLiteralsEs,
	platformDataProviderLiteralsFr,
	platformDataProviderLiteralsIt,
	platformDataProviderLiteralsNl,
	platformDataProviderLiteralsPt,
	platformDataProviderLiteralsSv
} from '@abb-emobility/platform/data-provider';
import { CandidateGroupProvider } from '@abb-emobility/shared/candidate-groups-provider';
import { EnvProvider } from '@abb-emobility/shared/environment';
import { NotFoundError } from '@abb-emobility/shared/error';
import { IdentityHandler, IdentityProvider } from '@abb-emobility/shared/identity-provider';
import { L10n, L10nProvider, Locale } from '@abb-emobility/shared/localization-provider';
import { ConsoleLogger, Logger, LoggerProvider } from '@abb-emobility/shared/logger';
import { sentryConfig, SentryLogger, TagValue } from '@abb-emobility/shared/sentry-integration';
import {
	sharedUiAuthEmitterLiteralsDe,
	sharedUiAuthEmitterLiteralsEn,
	sharedUiAuthEmitterLiteralsEs,
	sharedUiAuthEmitterLiteralsFr,
	sharedUiAuthEmitterLiteralsIt,
	sharedUiAuthEmitterLiteralsNl,
	sharedUiAuthEmitterLiteralsPt,
	sharedUiAuthEmitterLiteralsSv
} from '@abb-emobility/shared/ui-auth-emitter';
import {
	sharedUiErrorHandlerLiteralsDe,
	sharedUiErrorHandlerLiteralsEn,
	sharedUiErrorHandlerLiteralsEs,
	sharedUiErrorHandlerLiteralsFr,
	sharedUiErrorHandlerLiteralsIt,
	sharedUiErrorHandlerLiteralsNl,
	sharedUiErrorHandlerLiteralsPt,
	sharedUiErrorHandlerLiteralsSv
} from '@abb-emobility/shared/ui-error-handler';
import {
	sharedUiPrimitiveLiteralsDe,
	sharedUiPrimitiveLiteralsEn,
	sharedUiPrimitiveLiteralsEs,
	sharedUiPrimitiveLiteralsFr,
	sharedUiPrimitiveLiteralsIt,
	sharedUiPrimitiveLiteralsNl,
	sharedUiPrimitiveLiteralsPt,
	sharedUiPrimitiveLiteralsSv
} from '@abb-emobility/shared/ui-primitive';
import {
	sharedUiQuestionnaireLiteralsDe,
	sharedUiQuestionnaireLiteralsEn,
	sharedUiQuestionnaireLiteralsEs,
	sharedUiQuestionnaireLiteralsFr,
	sharedUiQuestionnaireLiteralsIt,
	sharedUiQuestionnaireLiteralsNl,
	sharedUiQuestionnaireLiteralsPt,
	sharedUiQuestionnaireLiteralsSv
} from '@abb-emobility/shared/ui-questionnaire';
import { WebsocketInstrument } from '@abb-emobility/shared/websocket';
import {
	usertaskDataProviderLiteralsDe,
	usertaskDataProviderLiteralsEn,
	usertaskDataProviderLiteralsEs,
	usertaskDataProviderLiteralsFr,
	usertaskDataProviderLiteralsIt,
	usertaskDataProviderLiteralsNl,
	usertaskDataProviderLiteralsPt,
	usertaskDataProviderLiteralsSv
} from '@abb-emobility/usertask/data-provider';

import { AppFactory } from './app/AppFactory';
import { environment } from './environments/environment';
import { default as appLiteralsDe } from './l10n/literals-de';
import { default as appLiteralsEn } from './l10n/literals-en';
import { default as appLiteralsEs } from './l10n/literals-es';
import { default as appLiteralsFr } from './l10n/literals-fr';
import { default as appLiteralsIt } from './l10n/literals-it';
import { default as appLiteralsNl } from './l10n/literals-nl';
import { default as appLiteralsPt } from './l10n/literals-pt';
import { default as appLiteralsSv } from './l10n/literals-sv';
import { store } from './store/store';

// Set up locales to be used by L10n
const locales = [
	new Locale('de', [
		appLiteralsDe,
		sharedUiAuthEmitterLiteralsDe,
		sharedUiErrorHandlerLiteralsDe,
		platformDataProviderLiteralsDe,
		sharedUiPrimitiveLiteralsDe,
		sharedUiQuestionnaireLiteralsDe,
		usertaskDataProviderLiteralsDe
	]),
	new Locale('en', [
		appLiteralsEn,
		sharedUiAuthEmitterLiteralsEn,
		sharedUiErrorHandlerLiteralsEn,
		platformDataProviderLiteralsEn,
		sharedUiPrimitiveLiteralsEn,
		sharedUiQuestionnaireLiteralsEn,
		usertaskDataProviderLiteralsEn
	]),
	new Locale('es', [
		appLiteralsEs,
		sharedUiAuthEmitterLiteralsEs,
		sharedUiErrorHandlerLiteralsEs,
		platformDataProviderLiteralsEs,
		sharedUiPrimitiveLiteralsEs,
		sharedUiQuestionnaireLiteralsEs,
		usertaskDataProviderLiteralsEs
	]),
	new Locale('fr', [
		appLiteralsFr,
		sharedUiAuthEmitterLiteralsFr,
		sharedUiErrorHandlerLiteralsFr,
		platformDataProviderLiteralsFr,
		sharedUiPrimitiveLiteralsFr,
		sharedUiQuestionnaireLiteralsFr,
		usertaskDataProviderLiteralsFr
	]),
	new Locale('it', [
		appLiteralsIt,
		sharedUiAuthEmitterLiteralsIt,
		sharedUiErrorHandlerLiteralsIt,
		platformDataProviderLiteralsIt,
		sharedUiPrimitiveLiteralsIt,
		sharedUiQuestionnaireLiteralsIt,
		usertaskDataProviderLiteralsIt
	]),
	new Locale('nl', [
		appLiteralsNl,
		sharedUiAuthEmitterLiteralsNl,
		sharedUiErrorHandlerLiteralsNl,
		platformDataProviderLiteralsNl,
		sharedUiPrimitiveLiteralsNl,
		sharedUiQuestionnaireLiteralsNl,
		usertaskDataProviderLiteralsNl
	]),
	new Locale('pt', [
		appLiteralsPt,
		sharedUiAuthEmitterLiteralsPt,
		sharedUiErrorHandlerLiteralsPt,
		platformDataProviderLiteralsPt,
		sharedUiPrimitiveLiteralsPt,
		sharedUiQuestionnaireLiteralsPt,
		usertaskDataProviderLiteralsPt
	]),
	new Locale('sv', [
		appLiteralsSv,
		sharedUiAuthEmitterLiteralsSv,
		sharedUiErrorHandlerLiteralsSv,
		platformDataProviderLiteralsSv,
		sharedUiPrimitiveLiteralsSv,
		sharedUiQuestionnaireLiteralsSv,
		usertaskDataProviderLiteralsSv
	])
];

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

// Set up logger instances to be used by ErrorHandler components
const loggers: Array<Logger> = [
	new ConsoleLogger(true, undefined, undefined)
];
if (process.env['NX_SENTRY_ENABLED'] === 'true') {
	loggers.push(new SentryLogger(
		sentryConfig(),
		true,
		(error) => !(error instanceof NotFoundError),
		undefined,
		() => IdentityHandler.get().getIdentity().getOrUndefined()?.userId,
		() => {
			return new Map<string, TagValue>([
				['client.locale', L10n.selectedLocale()],
				['stomp.enabled', process.env['NX_STOMP_ENABLED'] === 'true'],
				['stomp.open', WebsocketInstrument.get().isOpened()],
				['feature.marketplaceOrders.enabled', process.env['NX_FEATURE_TOGGLE_MARKETPLACE_ORDERS'] === 'true']
			]);
		}
	));
}

root.render(
	<StrictMode>
		<LoggerProvider loggers={loggers}>
			<BrowserRouter>
				<Provider store={store}>
					<EnvProvider env={environment}>
						<L10nProvider
							locales={locales}
							fallbackLocaleIdentifier={'en'}>
							<IdentityProvider>
								<CandidateGroupProvider>
									<AppFactory />
								</CandidateGroupProvider>
							</IdentityProvider>
						</L10nProvider>
					</EnvProvider>
				</Provider>
			</BrowserRouter>
		</LoggerProvider>
	</StrictMode>
);
