import React from 'react';

import { CustomerServiceOrderInformationApprovalModel } from '@abb-emobility/platform/domain-model';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	CollectionItemContentSection,
	OrderComment,
	SectionHeader
} from '@abb-emobility/shared/ui-primitive';

import { Approval } from '../approval/Approval';

type ApprovalProps = {
	orderInformation: CustomerServiceOrderInformationApprovalModel
};

export function OrderInformationApproval(props: ApprovalProps) {

	const { orderInformation } = props;

	const l10n = useL10n();

	const renderComment = () => {
		if (orderInformation.comment) {
			return (
				<CollectionItemContentSection>
					<SectionHeader heading={l10n.translate('platformCustomerServiceApp.orderDetail.informationList.precheck.notes')} />
					<OrderComment comment={orderInformation.comment} />
				</CollectionItemContentSection>
			);
		}
		return;
	};

	return (
		<>
			<Approval approvalInformation={orderInformation.payload}/>
			{renderComment()}
		</>
	);

}
