import React from 'react';

import { CustomerServiceOrderCollectionItemModel } from '@abb-emobility/platform/domain-model';
import { FilterCriteria, SingleValueFilterComparator } from '@abb-emobility/shared/api-integration-foundation';
import { createAccessTokenLoader, useAuth } from '@abb-emobility/shared/auth-provider';
import { FetchError } from '@abb-emobility/shared/business-error';
import {
	CollectionDataProviderProps,
	createCrudCollectionDataProviderValue,
	FetchMode,
	useDataProviderFetchFailedHandler,
	useDataProviderFetchInit,
	useDataProviderFetchSuspense
} from '@abb-emobility/shared/data-provider-foundation';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { useEnv } from '@abb-emobility/shared/environment';
import { AppError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { Optional } from '@abb-emobility/shared/util';

import { customerServiceCustomerOrderCollectionData } from './CustomerServiceCustomerOrderCollectionData';
import {
	CustomerServiceCustomerOrderCollectionStore,
	customerServiceCustomerOrderCollectionStoreAccessors,
	customerServiceCustomerOrderCollectionStoreName
} from './CustomerServiceCustomerOrderCollectionSlice';

export type CustomerServiceCustomerOrderCollectionDataProviderProps = {
	customerId: ModelPrimaryKey
} & Omit<CollectionDataProviderProps<CustomerServiceOrderCollectionItemModel>, 'filterCriteria'>;

export function CustomerServiceCustomerOrderCollectionDataProvider(props: CustomerServiceCustomerOrderCollectionDataProviderProps) {
	const {
		fetchMode = FetchMode.IMMEDIATE,
		suspense = fetchMode === FetchMode.IMMEDIATE,
		pendingComponent = null,
		customerId,
		forceFetch
	} = props;

	const auth = useAuth();
	const env = useEnv();
	const l10n = useL10n();

	const apiBaseUrl = new Optional(process.env['NX_CUSTOMER_SERVICE_API_BASE_URL']).getOrThrow(new AppError('API base URL unavailable'));

	const dataProviderValue = createCrudCollectionDataProviderValue<CustomerServiceOrderCollectionItemModel, CustomerServiceCustomerOrderCollectionStore>(
		customerServiceCustomerOrderCollectionStoreName,
		customerServiceCustomerOrderCollectionStoreAccessors,
		apiBaseUrl,
		createAccessTokenLoader(auth, env)
	);

	useDataProviderFetchFailedHandler(dataProviderValue, new FetchError(l10n.translate('platformDataProvider.error.orderCollectionFetchMessage')));

	const filterCriteria: FilterCriteria<CustomerServiceOrderCollectionItemModel> = [
		{
			id: 'customer',
			criteria: [
				{
					flavor: 'free',
					comparator: SingleValueFilterComparator.EQUAL,
					property: 'customer.id',
					value: customerId
				}
			]
		}
	];
	const sortCriteria = dataProviderValue.querySortCriteria();
	const initialSortCriteria = props.sortCriteria;
	const effectiveSortCriteria = sortCriteria ?? initialSortCriteria;
	useDataProviderFetchInit(fetchMode, () => dataProviderValue.fetch(effectiveSortCriteria, filterCriteria, forceFetch));

	return useDataProviderFetchSuspense(dataProviderValue, suspense, pendingComponent).getOrDefault(
		<customerServiceCustomerOrderCollectionData.Provider value={dataProviderValue}>
			{props.children}
		</customerServiceCustomerOrderCollectionData.Provider>
	);

}
