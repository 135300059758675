import { useNavigate } from 'react-router-dom';

import { useL10n } from '@abb-emobility/shared/localization-provider';
import { usePageTitle } from '@abb-emobility/shared/react';
import { useBreadcrumbModifier, ErrorFeedback, ErrorFeedbackAction } from '@abb-emobility/shared/ui-primitive';

import { useBuildPageTitle } from '../components/page-title/PageTitle.hook';
import {
	useClearSearchbarContentModifier
} from '../components/searchbar-content/SearchbarContent.hooks';
import { RoutePath } from '../router/Routes';

export function NotFoundView() {

	const l10n = useL10n();
	const navigate = useNavigate();
	usePageTitle(useBuildPageTitle('platformCustomerServiceApp.pageTitle.notFound'));
	useBreadcrumbModifier([]);
	useClearSearchbarContentModifier();

	const reloadAction: ErrorFeedbackAction = {
		label: l10n.translate('platformCustomerServiceApp.error.notFound.resolveAction.label'),
		onInvoke: (): void => {
			navigate(RoutePath.ROOT);
		}
	};

	return (
		<ErrorFeedback
			heading={l10n.translate('platformCustomerServiceApp.error.notFound.heading')}
			message={l10n.translate('platformCustomerServiceApp.error.notFound.message')}
			actions={[reloadAction]}
		/>
	);
}
