import React from 'react';

import { CustomerServiceOrderCollectionDataProvider, customerServiceOrderCollectionStoreName } from '@abb-emobility/platform/data-provider';
import { CustomerServiceOrderCollectionItemModel } from '@abb-emobility/platform/domain-model';
import { FilterCriteria, SortCriteria } from '@abb-emobility/shared/api-integration-foundation';
import { useFilterCriteriaStorage, useSortCriteriaStorage } from '@abb-emobility/shared/data-provider-util';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { usePageTitle } from '@abb-emobility/shared/react';
import { UiErrorHandler } from '@abb-emobility/shared/ui-error-handler';
import { useBreadcrumbModifier, SpinnerCircle, ViewHeader } from '@abb-emobility/shared/ui-primitive';

import { OrderCollection } from '../components/order-collection/OrderCollection';
import { orderCollectionDefaultSortCriteria } from '../components/order-collection/OrderCollectionDefaultSortCriteria';
import { useBuildPageTitle } from '../components/page-title/PageTitle.hook';
import {
	useClearSearchbarContentModifier
} from '../components/searchbar-content/SearchbarContent.hooks';
import { createRouteUrl, RoutePath } from '../router/Routes';

export function OrdersView() {

	const l10n = useL10n();

	const filterCriteriaStorage = useFilterCriteriaStorage<CustomerServiceOrderCollectionItemModel>(customerServiceOrderCollectionStoreName);
	const persistedFilterCriteria = filterCriteriaStorage.readFilterCriteria();

	const sortCriteriaStorage = useSortCriteriaStorage<CustomerServiceOrderCollectionItemModel>(customerServiceOrderCollectionStoreName);
	const persistedSortCriteria = sortCriteriaStorage.readSortCriteria();

	const effectiveSortCriteria = persistedSortCriteria ?? orderCollectionDefaultSortCriteria;

	const persistFilter = (filterCriteria: FilterCriteria<CustomerServiceOrderCollectionItemModel>): void => {
		filterCriteriaStorage.writeFilterCriteria(filterCriteria);
	};

	const persistSort = (sortCriteria: SortCriteria<CustomerServiceOrderCollectionItemModel>): void => {
		sortCriteriaStorage.writeSortCriteria(sortCriteria);
	};

	usePageTitle(useBuildPageTitle(l10n.translate('platformCustomerServiceApp.pageTitle.orders')));
	useBreadcrumbModifier([
		{
			label: l10n.translate('platformCustomerServiceApp.breadcrumbs.orders'),
			link: createRouteUrl(RoutePath.CUSTOMERS)
		}
	]);
	useClearSearchbarContentModifier();

	return (
		<>
			<ViewHeader heading={l10n.translate('platformCustomerServiceApp.viewHeader.orders')} />
			<UiErrorHandler>
				<CustomerServiceOrderCollectionDataProvider
					pendingComponent={SpinnerCircle}
					filterCriteria={persistedFilterCriteria}
					sortCriteria={effectiveSortCriteria}
					suspense={false}
				>
					<OrderCollection onSortChange={persistSort} onFilterChange={persistFilter} />
				</CustomerServiceOrderCollectionDataProvider>
			</UiErrorHandler>
		</>
	);
}
