import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	platformCustomerServiceApp: {
		action: {
			showCustomer: 'Mostra cliente'
		},
		actionItemFactory: {
			addCustomerNoteLabel: 'Aggiungi nota',
			addOrderNoteLabel: 'Aggiungi nota',
			cancelOrderLabel: 'Annulla',
			cancelInstallationLabel: 'Annulla installazione',
			cancelOrderAppointmentsLabel: 'Annulla appuntamenti',
			externalLinkLabel: 'Collegamento esterno',
			resendMailLabel: 'Reinvia mail',
			returnOrderLabel: 'Reso',
			replaceOrderLabel: 'Sostituisci',
			updateCustomerLabel: 'Modifica dati',
			updateOrderLabel: 'Modifica dati'
		},
		actionModalFactory: {
			addCustomerNoteHeading: 'Aggiungi nota',
			addOrderNoteHeading: 'Aggiungi nota',
			cancelOrderHeading: 'Annulla ordine',
			cancelInstallationHeading: 'Annullamento dell\'installazione',
			cancelOrderAppointmentsHeading: 'Annulla gli appuntamenti per l\'ordine',
			resendMailHeading: 'Reinvia mail',
			returnOrderHeading: 'Restituisci ordine',
			replaceOrderHeading: 'Sostituisci ordine',
			updateCustomerHeading: 'Modifica dati del cliente',
			updateOrderHeading: 'Modifica dati dell\'ordine'
		},
		actionModal: {
			pleaseSelect: 'Seleziona',
			addNote: {
				content: {
					category: 'Categoria',
					categories: {
						order: 'Nota d\'ordine',
						customer: 'Nota del cliente',
						customerCall: 'Chiamata del cliente'
					}
				}
			},
			addOrderNote: {
				content: {
					noteHeading: 'Nota',
					notePlaceholder: 'Inserisci testo',
					successHeading: 'Nota d\'ordine creata con successo',
					closeButtonLabel: 'Chiudi'
				},
				footer: {
					confirm: 'Conferma',
					cancel: 'Annulla'
				},
				error: {
					heading: 'Impossibile creare la nota d\'ordine.',
					message: 'Si è verificato un errore durante la creazione della nota.'
				}
			},
			addCustomerNote: {
				content: {
					noteHeading: 'Nota',
					notePlaceholder: 'Inserisci testo',
					successHeading: 'Nota del cliente creata con successo',
					closeButtonLabel: 'Chiudi'
				},
				footer: {
					confirm: 'Conferma',
					cancel: 'Annulla'
				},
				error: {
					heading: 'Impossibile creare la nota del cliente.',
					message: 'Si è verificato un errore durante la creazione della nota del cliente.'
				}
			},
			addCustomerCallNote: {
				content: {
					noteHeading: 'Nota',
					notePlaceholder: 'Inserisci testo',
					customerCallReason: 'Motivo della chiamata del cliente',
					callReasonOptions: {
						afterSales: 'Post vendita',
						product: 'Prodotto',
						installation: 'Installazione',
						preCheck: 'Controllo preliminare',
						payment: 'Pagamento',
						dataProtection: 'Protezione dei dati',
						shipment: 'Spedizione',
						cancellation: 'Restituzioni',
						falseRouting: 'Instradamento falso',
						other: 'Altro',
						complaints: 'Reclami',
						returns: 'Restituzioni'
					},
					callReasonNotePlaceholder: 'Descrizione',
					successHeading: 'Nota di chiamata cliente creata con successo',
					closeButtonLabel: 'Chiudi'
				},
				footer: {
					confirm: 'Conferma',
					cancel: 'Annulla'
				},
				error: {
					heading: 'Impossibile creare la nota di chiamata del cliente.',
					message: 'Si è verificato un errore durante la creazione della nota di chiamata del cliente.'
				}
			},
			cancelOrder: {
				heading: 'Ordine',
				position: 'Posizione',
				description: 'Descrizione',
				reason: 'Motivo',
				note: 'Descrizione',
				cancelReasonValue: {
					other: 'Altro',
					wallboxBroken: 'Centralina a parete rotta',
					wallboxNotWorking: 'Centralina a parete non funzionante',
					withdrawal: 'Ritiro',
					installationNotPossible: 'Installazione non possibile'
				},
				footer: {
					cancel: 'Annulla',
					confirm: 'Conferma'
				},
				content: {
					successHeading: 'L\'ordine è stato annullato con successo',
					closeButtonLabel: 'Chiudi'
				},
				error: {
					loading: {
						heading: 'Impossibile caricare i dati dell\'ordine.',
						message: 'Si è verificato un errore durante il caricamento dei dati dell\'ordine.'
					},
					writing: {
						heading: 'Non è stato possibile annullare l\'ordine.',
						message: 'Si è verificato un errore durante l\'annullamento dell\'ordine.'
					}
				}
			},
			cancelInstallation: {
				heading: 'Ordine',
				position: 'Posizione',
				description: 'Descrizione',
				reason: 'Motivo',
				note: 'Descrizione',
				cancelReasonValue: {
					other: 'Altro',
					wallboxBroken: 'Centralina a parete rotta',
					wallboxNotWorking: 'Centralina a parete non funzionante',
					withdrawal: 'Ritiro',
					installationNotPossible: 'Installazione non possibile'
				},
				footer: {
					cancel: 'Annulla',
					confirm: 'Conferma'
				},
				content: {
					successHeading: 'Cancellazione dell\'installazione avvenuta con successo',
					closeButtonLabel: 'Chiudi'
				},
				error: {
					loading: {
						heading: 'Impossibile caricare i dati dell\'ordine.',
						message: 'Si è verificato un errore durante il caricamento dei dati dell\'ordine.'
					},
					writing: {
						heading: 'Non è stato possibile annullare l\'installazione',
						message: 'Si è verificato un errore durante l\'annullamento dell\'installazione'
					}
				}
			},
			cancelOrderAppointments: {
				content: {
					notificationHeading: 'Il partner di installazione sarà informato dell\'annullamento dell\'appuntamento.',
					notificationMessage: 'Il partner di installazione contatterà il cliente per fissare un nuovo appuntamento.',
					successHeading: 'Appuntamenti annullati',
					successCloseButtonLabel: 'Chiudi',
					reason: 'Motivo'
				},
				cancelReasonValue: {
					deliveryDelay: 'Ritardo nella consegna',
					installationPartnerConflict: 'Conflitto con il partner di installazione',
					customerConflict: 'Conflitto con il cliente',
					replacement: 'Sostituzione'
				},
				footer: {
					confirm: 'Conferma annullamento',
					cancel: 'Annulla'
				},
				error: {
					heading: 'Non è stato possibile annullare gli appuntamenti.',
					message: 'Si è verificato un errore durante l\'eliminazione degli appuntamenti per l\'ordine.'
				}
			},
			resendMail: {
				content: {
					notificationHeading: 'La mail verrà reinviata.',
					notificationMessage: 'Il cliente riceverà una nuova mail.',
					successHeading: 'Mail inviata con successo.',
					successCloseButtonLabel: 'Chiudi'
				},
				footer: {
					confirm: 'Reinvia mail',
					cancel: 'Annulla'
				},
				error: {
					heading: 'Impossibile inviare nuovamente la mail.',
					message: 'Si è verificato un errore durante l\'invio della mail.'
				}
			},
			returnOrder: {
				heading: 'Ordine',
				position: 'Posizione',
				description: 'Descrizione',
				note: 'Descrizione',
				'return': 'Reso',
				selectReturn: 'Seleziona gli articoli del reso',
				selectReason: 'Seleziona il motivo del reso',
				replacementItem: 'Articolo sostitutivo',
				yes: 'Sì',
				no: 'No',
				returnReason: {
					other: 'Altro',
					broken: 'Rotto'
				},
				footer: {
					cancel: 'Annulla',
					confirm: 'Conferma'
				},
				content: {
					successHeading: 'Processo di reso avviato con successo',
					closeButtonLabel: 'Chiudi'
				},
				error: {
					loading: {
						heading: 'Impossibile caricare i dati dell\'ordine.',
						message: 'Si è verificato un errore durante il caricamento dei dati dell\'ordine.'
					},
					writing: {
						heading: 'Non è stato possibile avviare il processo di reso.',
						message: 'Si è verificato un errore durante l\'esecuzione del processo dell\'ordine di reso.'
					}
				}
			},
			replaceOrder: {
				heading: 'Ordine',
				position: 'Posizione',
				description: 'Descrizione',
				note: 'Descrizione',
				'return': 'Sostituisci',
				selectReturn: 'Seleziona gli articoli sostitutivi',
				selectReason: 'Seleziona il motivo della sostituzione',
				returnReason: {
					other: 'Altro',
					broken: 'Rotto'
				},
				footer: {
					cancel: 'Annulla',
					confirm: 'Conferma'
				},
				content: {
					successHeading: 'Processo di sostituzione avviato con successo',
					closeButtonLabel: 'Chiudi'
				},
				error: {
					loading: {
						heading: 'Impossibile caricare i dati dell\'ordine.',
						message: 'Si è verificato un errore durante il caricamento dei dati dell\'ordine.'
					},
					writing: {
						heading: 'Non è stato possibile avviare il processo di sostituzione.',
						message: 'Si è verificato un errore durante l\'esecuzione del processo dell\'ordine di sostituzione.'
					}
				}
			},
			updateCustomer: {
				content: {
					firstname: 'Nome',
					lastname: 'Cognome',
					email: 'E-mail',
					personalInfoHeading: 'Dati personali',
					contactDataHeading: 'Dati di contatto',
					successHeading: 'Le informazioni dell\'utente sono state modificate con successo.',
					successCloseButtonLabel: 'Chiudi'
				},
				footer: {
					confirm: 'Conferma',
					cancel: 'Annulla'
				},
				error: {
					loading: {
						heading: 'Impossibile caricare le informazioni del cliente.',
						message: 'Si è verificato un errore durante il caricamento delle informazioni del cliente.'
					},
					writing: {
						heading: 'Impossibile aggiornare le informazioni del cliente.',
						message: 'Si è verificato un errore durante l\'aggiornamento delle informazioni del cliente.'
					}
				}
			},
			updateOrder: {
				content: {
					orderInformationHeading: 'Informazioni per l\'ordine',
					phoneLabel: 'Telefono',
					languageHeading: 'Lingua',
					languageLabel: ' Codice lingua',
					successHeading: 'Le informazioni dell\'ordine sono state modificate con successo.',
					successCloseButtonLabel: 'Chiudi'
				},
				footer: {
					confirm: 'Conferma',
					cancel: 'Annulla'
				},
				error: {
					loading: {
						heading: 'Impossibile caricare le informazioni per l\'ordine.',
						message: 'Si è verificato un errore durante il caricamento delle informazioni per l\'ordine.'
					},
					writing: {
						heading: 'Impossibile aggiornare le informazioni per l\'ordine.',
						message: 'Si è verificato un errore durante l\'aggiornamento delle informazioni per l\'ordine.'
					}
				}
			}
		},
		appName: 'Servizio clienti ABB',
		breadcrumbs: {
			tasks: 'Panoramica attività',
			orders: 'Panoramica ordine',
			marketplaceOrders: 'Panoramica degli ordini del marketplace',
			customers: 'Panoramica cliente'
		},
		comment: {
			categories: {
				order: 'Nota d\'ordine',
				customer: 'Nota del cliente',
				customerCall: 'Chiamata del cliente'
			},
			callReason: {
				afterSales: 'Post vendita',
				product: 'Prodotto',
				installation: 'Installazione',
				preCheck: 'Controllo preliminare',
				payment: 'Pagamento',
				dataProtection: 'Protezione dei dati',
				shipment: 'Spedizione',
				cancellation: 'Restituzioni',
				falseRouting: 'Instradamento falso',
				other: 'Altro',
				complaints: 'Reclami',
				returns: 'Restituzioni'
			}
		},
		commentCollection: {
			heading: 'Note',
			emptyState: {
				heading: 'Nessun commento',
				message: 'Al momento non sono presenti commenti disponibili.'
			}
		},
		common: {
			tableHeader: {
				position: 'Posizione',
				description: 'Descrizione'
			}
		},
		customerCollection: {
			heading: 'Clienti',
			tableHead: {
				customer: 'Cliente',
				customerId: 'ID cliente',
				firstOrder: 'Primo ordine',
				lastOrder: 'Ultimo ordine'
			},
			emptyState: {
				heading: 'Nessun cliente trovato',
				message: 'Regola le impostazioni dei filtri per trovare clienti.'
			},
			action: {
				showMore: 'Mostra altro'
			}
		},
		customerInformation: {
			heading: 'Informazioni del cliente',
			tableHeader: {
				position: 'Posizione',
				description: 'Descrizione'
			},
			tableItem: {
				firstName: 'Nome',
				lastName: 'Cognome',
				companyName: 'Nome dell\'azienda',
				mail: 'E-mail',
				firstOrder: 'Primo ordine',
				lastOrder: 'Ultimo ordine'
			}
		},
		customerOrders: {
			heading: 'Ordini del cliente'
		},
		customerMarketplaceOrders: {
			heading: 'Marketplace ordini del cliente'
		},
		customerDetail: {
			subheader: {
				customerId: 'ID cliente'
			},
			tabItems: {
				overview: 'Panoramica',
				comments: 'Note'
			}
		},
		error: {
			generic: {
				heading: 'Si è verificato un errore',
				message: 'Si è verificato un errore imprevisto che non è stato possibile risolvere',
				resolveAction: {
					label: 'Ricarica'
				}
			},
			offline: {
				heading: 'Connessione di rete non disponibile',
				message: 'Per utilizzare l\'applicazione, collegarsi a una rete.'
			},
			notFound: {
				heading: 'Non trovato',
				message: 'La risorsa richiesta non è stata trovata',
				resolveAction: {
					label: 'Le mie attività'
				}
			},
			customerNotFound: 'Il cliente richiesto non è stato trovato',
			orderNotFound: 'L\'ordine richiesto non è stato trovato',
			taskNotFound: 'L\'attività richiesta non è stata trovata',
			callerNotFound: 'Non sono state trovate informazioni sul chiamante',
			taskAssignMessage: 'Si è verificato un errore durante l\'assegnazione dell\'attività.',
			taskCompleteMessage: 'Si è verificato un errore durante il completamento dell\'attività.'
		},
		navigation: {
			tasks: 'Le mie attività',
			orders: 'Ordini',
			marketplaceOrders: 'Marketplace Ordini',
			customers: 'Clienti',
			logout: 'Esci'
		},
		order: {
			installationType: {
				undetermined: 'Indeterminato',
				standard: 'Standard',
				delta: 'Delta',
				cancelled: 'Annullato'
			}
		},
		orderDetail: {
			subheader: {
				orderDate: 'Data dell\'ordine',
				orderId: 'ID ordine',
				marketplace: 'Mercato',
				plannedDelivery: 'Consegna programmata',
				orderState: {
					onSchedule: 'In programma',
					delayed: 'Rimandato',
					cancelPending: 'Annulla in sospeso',
					cancelled: 'Annullato',
					imported: 'Importato',
					validationFailed: 'Invalido',
					done: 'Fatto'
				}
			},
			tabItems: {
				overview: 'Panoramica',
				installation: 'Installazione',
				delivery: 'Consegna',
				accounting: 'Contabilità',
				files: 'File',
				comments: 'Note',
				tasks: 'Compiti'
			},
			overview: {
				customerInformation: {
					heading: 'Informazioni del cliente'
				},
				orderInformation: {
					heading: 'Informazioni per l\'ordine',
					tableHeader: {
						position: 'Posizione',
						description: 'Descrizione'
					},
					tableItem: {
						language: 'Lingua',
						phone: 'Telefono',
						deliveryAddress: 'Indirizzo'
					}
				},
				timeline: {
					heading: 'Tempistica'
				}
			},
			installation: {
				cancellation: {
					notification: {
						heading: 'L\'installazione è stata annullata',
						message: 'La cancellazione è stata effettuata in data {{cancellationDate}}. Il motivo fornito è "{{cancellationReason}}".'
					}
				},
				information: {
					heading: 'Informazioni',
					types: {
						basicAnswers: 'Risposte di base',
						gridOperatorRegistration: 'Registrazione gestore di rete',
						gridOperatorDecision: 'Decisione gestore di rete',
						preCheck: 'Controllo preliminare',
						offer: 'Offerta',
						approval: 'Approvazione'
					},
					emptyState: {
						heading: 'Non sono disponibili informazioni sull\'ordine',
						message: 'Non sono disponibili informazioni sugli ordini.'
					}
				},
				installationAppointments: {
					heading: 'Installazioni programmate',
					installer: 'Installatore',
					appointment: 'Appuntamento',
					phone: 'Telefono',
					mail: 'E-mail',
					emptyState: {
						heading: 'Nessun appuntamento trovato.',
						message: 'Non sono presenti appuntamenti programmati.'
					}
				}
			},
			delivery: {
				orderItem: 'Ordina articolo',
				productCode: 'Codice prodotto',
				address: {
					heading: 'Indirizzo di consegna',
					name: 'Nome',
					street: 'Via',
					location: 'Città'
				},
				details: {
					heading: 'Dettagli della consegna',
					shippingDate: 'Data di spedizione',
					address: 'Indirizzo di spedizione',
					trackingCode: 'Codice di tracciamento',
					state: 'Stato della spedizione',
					partner: 'Partner di spedizione',
					tracking: 'Tracciamento',
					shippingState: {
						delivered: 'Consegnato',
						pending: 'In attesa'
					},
					estimatedDeliveryDate: 'Data di consegna prevista',
					deliveryDate: 'Data di consegna'
				},
				retoureDetails: {
					heading: 'Dettagli reso',
					date: 'Data della richiesta',
					address: 'Indirizzo del reso',
					reason: 'Motivo del reso',
					returnNote: 'Nota di reso',
					created: 'Creato presso'
				},
				replacementDetails: {
					heading: 'Dettagli della consegna sostitutiva'
				},
				emptyState: {
					heading: 'Non sono disponibili informazioni sulla spedizione',
					message: 'Al momento non sono disponibili ulteriori informazioni sulla spedizione.'
				}
			},
			accounting: {
				heading: 'Transazione',
				date: 'Data del pagamento',
				state: 'Stato',
				notification: 'Messaggio di errore',
				cardOwner: 'Titolare della carta',
				cardNumber: 'Numero di carta',
				cardDueDate: 'Scadenza della carta',
				accountOwner: 'Titolare del conto',
				accountNumber: 'IBAN',
				paymentState: {
					success: 'Successo',
					failed: 'Fallito',
					settlementPending: 'In attesa di finalizzazione'
				},
				emptyPaymentState: {
					heading: 'Non sono disponibili informazioni sul pagamento',
					message: 'Al momento non sono disponibili ulteriori informazioni sul pagamento.'
				},
				emptyDownloadFilesState: {
					heading: 'Nessun file di download disponibile',
					message: 'Al momento non sono disponibili file di download.'
				}
			},
			files: {
				emptyState: {
					heading: 'Nessun file di download disponibile',
					message: 'Al momento non sono disponibili file di download.'
				}
			},
			throttleWallboxHint: {
				caption: 'Attenzione',
				message: 'Il consumo energetico della centralina a parete deve essere ridotto in base ai requisiti del gestore di rete.'
			},
			informationList: {
				heading: 'Informazioni',
				emptyState: {
					heading: 'Non sono ancora disponibili informazioni sull\'ordine',
					message: 'Man mano che l\'ordine procede, tutte le informazioni rilevanti verranno riportate qui.'
				},
				caption: {
					basicAnswers: 'Risposte di base',
					gridOperatorRegistration: 'Registrazione gestore di rete',
					gridOperatorDecision: 'Decisione gestore di rete',
					preCheck: 'Controllo preliminare',
					offer: 'Offerta',
					approval: 'Approvazione'
				},
				precheck: {
					heading: 'Controllo preliminare',
					effort: 'Risultati del controllo preliminare: Appuntamenti e sforzi per l\'installazione',
					questionnaire: {
						heading: 'Risposte dei clienti'
					},
					installationType: {
						undetermined: 'Indeterminato',
						standard: 'Installazione Standard',
						delta: 'Installazione Delta'
					},
					decision: {
						accepted: 'Informazioni sufficienti',
						more: 'Sono necessarie ulteriori informazioni',
						denied: 'Installazione non possibile'
					},
					notes: 'Note',
					hours: 'ore'
				},
				decision: {
					heading: 'Decisione gestore di rete',
					decision: {
						pending: 'La richiesta di installazione è ancora in sospeso',
						accepted: 'Il gestore di rete ha acconsentito all\'installazione',
						denied: 'Il gestore di rete ha rifiutato l\'installazione'
					}
				},
				registration: {
					heading: 'Registrazione gestore di rete',
					documents: 'Documenti',
					gridOperator: 'Gestore di rete'
				},
				quotation: {
					heading: 'Preventivazione',
					conditions: 'Condizioni dell\'offerta'
				},
				complete: {
					success: {
						heading: 'Installazione riuscita'
					},
					notes: 'Note',
					images: 'Immagini relative all\'installazione',
					documents: 'Documenti relativi all\'installazione',
					signature: 'Firma del cliente'
				}
			}
		},
		orderEventTimeline: {
			emptyState: {
				heading: 'Nessun evento trovato',
				message: 'La cronologia è ancora vuota'
			}
		},
		timelineItemDetails: {
			mailAddress: {
				label: 'Email'
			},
			sezione: {
				payment: 'Pagamenti',
				appointments: 'Appuntamenti programmati'
			}
		},
		orderEvent: {
			type: {
				orderReceived: 'Ordine ricevuto',
				orderAddressValidationSucceeded: 'Convalida dell\'indirizzo riuscita',
				orderAddressValidationFailed: 'Convalida dell\'indirizzo non riuscita',
				orderAddressCorrected: 'Indirizzo dell\'ordine corretto',
				accountingOrderCreated: 'Ordine contabile creato',
				orderConfirmationMailSent: 'Mail di conferma inviata',
				basicAnswersReceived: 'Risposte al questionario ricevute',
				gridOperatorRegistrationStarted: 'Registrazione dell\'operatore di rete avviata',
				gridOperatorFeedbackReceived: 'Feedback dell\'operatore di griglia ricevuto',
				gridOperatorRejectionNotificationSent: 'Rifiuto dell\'operatore di griglia ricevuto',
				basicAnswersEvaluated: 'Risposte al questionario valutate',
				precheckPreferencesMailSent: 'Mail di precontrollo delle richieste di contatto inviata',
				remotePrecheckExecuted: 'Precheck remoto eseguito',
				deltaQuotationCreated: 'Preventivo Delta creato',
				deltaQuotationMailSent: 'Invio di posta elettronica per la quotazione Delta',
				deltaQuotationAccepted: 'Offerta Delta accettata',
				paymentSucceeded: 'Pagamento riuscito',
				paymentFailedOrTimedOut: 'Pagamento non riuscito',
				paymentReminderMailSent: 'Promemoria di pagamento inviato',
				paymentConfirmationMailSent: 'Mail di conferma del pagamento inviata',
				appointmentArranged: 'Appuntamento fissato',
				appointmentArrangedNotificationSent: 'Mail di notifica dell\'appuntamento inviata',
				shippingConfirmed: 'Consegna confermata',
				installationConfirmed: 'Installazione confermata',
				replacementInstallationConfirmed: 'Installazione sostitutiva confermata',
				installationApprovalMailSent: 'Mail di conferma dell\'installazione inviata',
				invoiceCreated: 'Fattura creata',
				invoiceNotificationSent: 'Notifica di fattura inviata',
				orderCancelled: 'Ordine annullato',
				gridOperatorRegistrationRevoked: 'Registrazione dell\'operatore di rete annullata',
				customerCallReceived: 'Chiamata cliente ricevuta',
				returnOrReplacementShipmentReceived: 'Consegna di ritorno o di sostituzione ricevuta',
				returnInitiated: 'Restituzione inizializzata',
				replacementInitiated: 'Consegna sostitutiva inizializzata',
				cancellationInitiated: 'Cancellazione inizializzata',
				orderNoteCreated: 'Nota d\'ordine creata',
				refundCompleted: 'Rimborso completato',
				installationCancellationMailSent: 'Invio della notifica di annullamento dell\'installazione'
			},
			category: {
				accounting: 'Contabilità',
				customer: 'Cliente',
				customerService: 'Servizio clienti',
				deltaInstallation: 'Installazione delta',
				gridOperator: 'Operatore di rete',
				installation: 'Installazione',
				order: 'Ordine',
				payment: 'Pagamento',
				preCheck: 'PreCheck',
				shipping: 'Spedizione'
			}
		},
		marketplaceOrder: {
			orderState: {
				paymentPending: 'Pagamento in sospeso',
				waitingForFulfillment: 'In attesa di adempimento',
				fulfillmentStarted: 'Adempimento iniziato',
				cancelled: 'Annullato',
				done: 'Fatto'
			}
		},
		orderCollection: {
			heading: 'Ordini',
			tableHead: {
				order: 'Ordine',
				status: 'Stato',
				language: 'Lingua',
				plannedDeliveryDate: 'Consegna programmata',
				createdAt: 'Creato presso'
			},
			emptyState: {
				heading: 'Nessun ordine trovato',
				message: 'Regola le impostazioni dei filtri per trovare ordini.'
			},
			action: {
				refresh: 'Aggiorna',
				showMore: 'Mostra altro'
			},
			orderState: {
				onSchedule: 'In programma',
				delayed: 'Rimandato',
				cancelPending: 'Annulla in sospeso',
				cancelled: 'Annullato',
				imported: 'Importato',
				validationFailed: 'Invalido',
				done: 'Fatto'
			}
		},
		customerOrderCollection: {
			emptyState: {
				heading: 'Nessun ordine trovato',
				message: 'Non sono stati trovati ordini per questo cliente.'
			}
		},
		orderLineItemCollection: {
			heading: 'Ordine',
			position: 'Posizione',
			description: 'Descrizione',
			price: 'Prezzo',
			productCode: 'Codice prodotto',
			totalPrice: 'Prezzo totale'
		},
		inboundOrderCollection: {
			heading: 'Ordini associati al numero di telefono {{caller}}',
			emptyState: {
				heading: 'Nessun ordine trovato',
				message: 'Provare a fare una ricerca.',
				searchLabel: 'Cerca'
			},
			action: {
				showMore: 'Mostra altro'
			}
		},
		searchbar: {
			placeholder: 'Cerca',
			results: {
				orders: 'Ordini',
				marketplaceOrders: 'Ordini del mercato',
				customers: 'Clienti',
				showResultPage: 'Visualizza pagina dei risultati',
				showResultPageDescription: {
					resultsFromTo: 'Risultato da 1 a',
					of: 'di'
				}
			}
		},
		searchResultCollection: {
			heading: 'Risultati ricerca',
			headingWithSearchterm: 'Risultati ricerca per',
			headingCustomers: 'Clienti',
			headingOrders: 'Ordini',
			headingMarketplaceOrders: 'Ordini del mercato',
			emptyState: {
				heading: 'Nessun risultato di ricerca trovato',
				message: 'Modifica il termine di ricerca e riprovare.'
			},
			idleState: {
				heading: 'Non è stato fornito alcun termine di ricerca',
				message: 'Inserire un termine di ricerca.'
			},
			action: {
				showMore: 'Mostra altro'
			}
		},
		task: {
			assign: {
				successMessage: 'Attività assegnata'
			},
			error: {
				taskNotFound: 'Attività non trovata',
				taskTypeNotFound: 'Tipo di attività non trovato'
			},
			gridOperatorRegistration: {
				continueToFeedback: {
					buttonLabel: 'Continua'
				}
			},
			basicAnswers: {
				continueToQuotation: {
					buttonLabel: 'Continua'
				},
				button: {
					close: 'Chiudi',
					submit: 'Invia'
				},
				questionnaire: {
					heading: 'Risposte di base'
				}
			},
			completion: {
				successHeading: 'Attività completata con successo',
				closeButtonLabel: 'Chiudi'
			},
			preCheck: {
				button: {
					close: 'Chiudi',
					submit: 'Invia'
				},
				contact: {
					description: 'Contatto'
				}
			},
			gridOperator: {
				button: {
					close: 'Chiudi',
					submit: 'Invia'
				}
			},
			quotation: {
				button: {
					close: 'Chiudi',
					submit: 'Invia'
				},
				deltaPackages: {
					totalPurchaseNetPrice: 'Prezzo totale di acquisto (netto)',
					totalSalesGrossPrice: 'Prezzo totale di vendita (incluso {{vatRate}}% di IVA)',
					purchaseNetPrice: 'Prezzo di acquisto (netto)',
					salesGrossPrice: 'Prezzo di vendita (lordo)'
				}
			},
			payment: {
				button: {
					close: 'Chiudi',
					submit: 'Invia'
				}
			},
			appointment: {
				button: {
					close: 'Chiudi',
					submit: 'Invia'
				}
			},
			installation: {
				button: {
					close: 'Chiudi',
					submit: 'Invia'
				}
			}
		},
		taskCollection: {
			heading: 'Attività',
			tableHead: {
				task: 'Attività',
				status: 'Stato',
				language: 'Lingua'
			},
			action: {
				refresh: 'Aggiorna',
				showMore: 'Mostra altro'
			},
			assign: {
				successMessage: 'L\'attività è stato assegnata con successo',
				errorMessage: 'Si è verificato un errore durante l\'assegnazione dell\'attività.'
			},
			emptyState: {
				heading: 'Nessuna attività trovata',
				message: 'Regola le impostazioni dei filtri per trovare attività.'
			},
			orderEmptyState: {
				heading: 'Nessuna attività trovata',
				message: 'Per questo ordine non è necessaria alcuna azione.'
			}
		},
		taskCollectionItem: {
			type: {
				customerAnswerBasicQuestionsEscalation: 'Aumento: Domanda di base',
				customerProvidePreCheckPreferencesEscalation: 'Aumento: Controllo preliminare',
				customerHandleUnfeasibleInstallationEscalation: 'Aumento: Installazione non realizzabile',
				customerHandleGridOperatorRejectionEscalation: 'Aumento: Rifiuto del gestore di rete',
				customerAcceptQuotationEscalation: 'Aumento: Preventivazione',
				customerPerformPaymentEscalation: 'Aumento: Pagamento',
				customerApproveInstallationEscalation: 'Aumento: Approva installazione',
				customerAddressValidationEscalation: 'Aumento: Convalida dell\'indirizzo',
				customerUncoveredInstallationAddress: 'Indirizzo dell\'installazione non coperta',
				installationPartnerEvaluateBasicAnswersEscalation: 'Aumento: Risposte di base',
				installationPartnerExecuteRemotePreCheckEscalation: 'Aumento: Esegui controllo preliminare in remoto',
				installationPartnerRegisterAtGridOperatorEscalation: 'Aumento: Registrazione presso il gestore di rete',
				installationPartnerProvideGridOperatorDecisionEscalation: 'Aumento: Indicare gestore di rete',
				installationPartnerProvideQuotationEscalation: 'Aumento: Fornire preventivo',
				installationPartnerArrangeAppointmentEscalation: 'Aumento: Fissa appuntamenti',
				installationPartnerCompleteInstallationEscalation: 'Aumento: Completa installazione'
			},
			status: {
				started: 'Prossimamente',
				dueToday: 'In scadenza oggi',
				overdue: 'Scaduto',
				escalated: 'Inoltrato',
				done: 'Fatto',
				doneOverdue: 'Fatto',
				doneEscalated: 'Fatto'
			},
			category: {
				customer: 'Cliente',
				installationPartner: 'Install. Partner'
			}
		},
		pageTitle: {
			tasks: 'Panoramica attività',
			orders: 'Panoramica ordine',
			order: 'Dettagli ordine',
			marketplaceOrders: 'Panoramica degli ordini del mercato',
			marketplaceOrder: 'Dettaglio ordine del mercato',
			customers: 'Panoramica cliente',
			error: 'Errore',
			notFound: 'Non trovato',
			inbound: 'Chiamata in arrivo {{caller}}'
		},
		viewHeader: {
			tasks: 'Panoramica attività',
			orders: 'Panoramica ordine',
			marketplaceOrders: 'Panoramica degli ordini del marketplace',
			customers: 'Panoramica cliente',
			inbound: 'Chiamata in arrivo da {{caller}}'
		},
		modal: {
			task: {
				section: {
					customerInformation: {
						heading: 'Cliente',
						position: 'Posizione',
						description: 'Descrizione',
						name: 'Nome',
						address: 'Indirizzo di consegna',
						phone: 'Numero di telefono',
						mail: 'E-mail'
					},
					installationEffortInformation: {
						heading: 'Lavoro di installazione',
						trade: 'Attività',
						effort: 'Sforzo',
						hours: 'ore'
					},
					installationPartnerInformation: {
						heading: 'Partner di installazione',
						position: 'Posizione',
						description: 'Descrizione',
						name: 'Nome',
						mail: 'E-mail',
						phone: 'Telefono'
					},
					orderInformation: {
						heading: 'Informazioni per l\'ordine'
					},
					quotationInformation: {
						heading: 'Preventivazione',
						position: 'Posizione',
						description: 'Descrizione',
						price: 'Prezzo',
						deltaInstallation: 'Decisione: Installazione Delta',
						grossPrice: 'Somma',
						vat: 'VAT',
						include: 'incluso'
					},
					taskInformation: {
						heading: 'Attività',
						position: 'Posizione',
						description: 'Descrizione',
						createdAt: 'Creato presso',
						dueDate: 'Scadenza',
						responsible: 'Responsabile',
						openTask: 'Apri attività'
					},
					contactInformation: {
						heading: 'Preferenze di contatto'
					},
					gridOperatorInformation: {
						heading: 'Informazioni sul gestore di rete'
					},
					payment: {
						accept: 'Preventivo accettato'
					},
					addressValidation: {
						heading: 'Convalida dell\'indirizzo',
						invalidAddressLabel: {
							delivery: 'Indirizzo di consegna non valido',
							billing: 'Indirizzo di fatturazione non valido',
							multiple: 'Indirizzo di consegna e di fatturazione non valido'
						},
						hint: {
							heading: 'Inserire un nuovo indirizzo per la convalida',
							message: 'Il nuovo indirizzo verrà riconfermato al momento della presentazione dell\'attività.'
						},
						error: {
							heading: 'La convalida dell\'indirizzo non è riuscita',
							message: 'Inserire le informazioni corrette relative all\'indirizzo.'
						},
						newAddress: {
							heading: 'Nuovo indirizzo',
							street: 'Via e numero civico ',
							zip: 'Codice postale',
							city: 'Città',
							country: 'Paese',
							region: 'Regione'
						}
					},
					uncoveredInstallationAddress: {
						message: 'Il cliente è stato informato dell\'annullamento dell\'ordine',
						info: 'Indirizzo dell\'installazione non coperta',
						infoHeading: 'Informare il cliente',
						infoDescription: 'Il cliente deve essere informato che l\'indirizzo dell\'installazione non è coperto e che l\'installazione è annullata.'
					},
					hint: {
						heading: 'Verifica finale',
						unfeasible: 'Decisione: Installazione non realizzabile',
						rejected: 'Rifiutato dal gestore di rete'
					}
				}
			}
		}
	}
};

export default literals;
