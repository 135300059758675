import React from 'react';

import { CustomerServiceOrderLineItemModel } from '@abb-emobility/platform/domain-model';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	TabularInformationItem,
	TabularInformationItemType,
	TabularInformationRow
} from '@abb-emobility/shared/ui-primitive';

type OrderDetailDeliveryOrderLineItemsProps = {
	orderLineItems: Array<CustomerServiceOrderLineItemModel>
};

export function OrderDetailDeliveryOrderLineItems(props: OrderDetailDeliveryOrderLineItemsProps) {

	const { orderLineItems } = props;

	const l10n = useL10n();
	const renderOrderItems = () => {
		return orderLineItems.map((item, idx) => {
			return (
				<TabularInformationRow key={item.id}>
					<TabularInformationItem
						label={l10n.translate('platformCustomerServiceApp.orderDetail.delivery.orderItem') + ' #' + String(idx + 1).padStart(2, '0')}
						variant={TabularInformationItemType.GREY}
					/>
					<TabularInformationItem
						label={l10n.translate('platformCustomerServiceApp.orderDetail.delivery.productCode') + ': ' + item.productCode + '; ' + item.productName}
					/>
				</TabularInformationRow>
			);
		});
	};

	return (
		<>
			{renderOrderItems()}
		</>
	);
}
