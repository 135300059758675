import React, { FormEvent, ReactNode, useEffect, useRef, useState } from 'react';

import { KeyboardEventValues } from '@abb-emobility/shared/browser';
import { useOnClickOutside } from '@abb-emobility/shared/interaction';
import { buildCssClassStringFromClassMap } from '@abb-emobility/shared/util';

import { IconIdentifier } from '../icon/Icon.enums';
import IconSvg from '../icon/IconSvg';

import './Searchbar.scss';

type SearchbarProps = {
	placeholder: string,
	onSubmit: (value: string) => void
	onFocus: () => void,
	onBlur: () => void,
	onClear: () => void,
	results?: ReactNode
};

export const Searchbar = (props: SearchbarProps) => {
	const { placeholder, onSubmit, onFocus, onBlur, onClear, results = null } = props;

	const [inputValue, setInputValue] = useState<string>('');
	const [showResults, setShowResults] = useState<boolean>(false);

	const searchbarInputRef = useRef<HTMLInputElement>(null);
	const searchbarRef = useRef<HTMLInputElement>(null);

	const inputClassMap = {
		'searchbar__header__input': true,
		'searchbar__header__input--active': showResults
	};

	const searchbarClassMap = {
		'searchbar': true,
		'searchbar--active': showResults
	};

	useEffect(() => {
		window.addEventListener('keyup', handleEscapeKeyPress);
		window.addEventListener('keypress', handleAltFKeyPress);
		return () => {
			window.removeEventListener('keyup', handleEscapeKeyPress);
			window.removeEventListener('keypress', handleAltFKeyPress);
		};
	}, []);

	useOnClickOutside(searchbarRef, () => {
		setShowResults(false);
		onBlur();
	}, true);

	const handleEscapeKeyPress = (event: KeyboardEvent) => {
		if (event.key === 'Escape' satisfies KeyboardEventValues) {
			event.preventDefault();
			setShowResults(false);
			onBlur();
		}
	};

	const handleAltFKeyPress = (event: KeyboardEvent) => {
		if (event.keyCode === 402 && event.altKey) {
			event.preventDefault();
			searchbarInputRef?.current?.focus();
			onFocus();
		}
	};

	const handleClear = (): void => {
		setInputValue('');
		onClear();
	};

	const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		setShowResults(true);
		onSubmit(inputValue);
	};

	const handleFocus = () => {
		if (inputValue !== '') {
			setShowResults(true);
		}
		onFocus();
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setInputValue(e.target.value);
	};

	const renderClearIcon = (): ReactNode => {
		if (inputValue === '') {
			return;
		}
		return (
			<span className="searchbar__header__clear" onClick={handleClear}>
				<IconSvg name={IconIdentifier.X} />
			</span>
		);
	};

	const renderResults = (): ReactNode => {
		if (showResults && searchbarInputRef.current === document.activeElement) {
			return (
				<section className="searchbar__results">
					{results}
				</section>
			);
		}
		return;
	};

	return (
		<form action="POST" onSubmit={handleSubmit}>
			<section className={buildCssClassStringFromClassMap(searchbarClassMap)} ref={searchbarRef}>
				<label className="searchbar__header">
					<span className="searchbar__header__icon">
						<IconSvg name={IconIdentifier.MAGNIFYING_GLASS} />
					</span>
					<input
						ref={searchbarInputRef}
						className={buildCssClassStringFromClassMap(inputClassMap)}
						type="text"
						value={inputValue}
						placeholder={placeholder}
						onChange={handleChange}
						onFocus={handleFocus}
					/>
					{renderClearIcon()}
				</label>
				{renderResults()}
			</section>
		</form>
	);
};
