import React from 'react';

import { CustomerModel } from '@abb-emobility/shared/domain-model';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	SegmentHeader,
	Separator,
	TabularInformationItem,
	TabularInformationItemType,
	TabularInformationRow
} from '@abb-emobility/shared/ui-primitive';

export type CustomerInformationProps = {
	customer: CustomerModel,
	showDeliveryAddress?: boolean
};

export function CustomerInformation(props: CustomerInformationProps) {

	const { customer, showDeliveryAddress = true } = props;

	const l10n = useL10n();

	const renderDeliveryAddress = () => {
		if (!showDeliveryAddress) {
			return null;
		}
		return (
			<TabularInformationRow>
				<TabularInformationItem
					label={l10n.translate('platformCustomerServiceApp.modal.task.section.customerInformation.address')}
					variant={TabularInformationItemType.GREY}
				/>
				<TabularInformationItem label={customer.address} />
			</TabularInformationRow>
		);
	};

	return (
		<section>
			<SegmentHeader caption={l10n.translate('platformCustomerServiceApp.modal.task.section.customerInformation.heading')} />
			<Separator />
			<TabularInformationRow>
				<TabularInformationItem
					label={l10n.translate('platformCustomerServiceApp.modal.task.section.customerInformation.position')}
					variant={TabularInformationItemType.HEADING} />
				<TabularInformationItem
					label={l10n.translate('platformCustomerServiceApp.modal.task.section.customerInformation.description')}
					variant={TabularInformationItemType.HEADING} />
			</TabularInformationRow>

			<TabularInformationRow>
				<TabularInformationItem label={l10n.translate('platformCustomerServiceApp.modal.task.section.customerInformation.name')} variant={TabularInformationItemType.GREY} />
				<TabularInformationItem label={customer.name} />
			</TabularInformationRow>

			{renderDeliveryAddress()}

			<TabularInformationRow>
				<TabularInformationItem label={l10n.translate('platformCustomerServiceApp.modal.task.section.customerInformation.phone')} variant={TabularInformationItemType.GREY} />
				<TabularInformationItem label={customer.phoneNumber} />
			</TabularInformationRow>

			<TabularInformationRow>
				<TabularInformationItem label={l10n.translate('platformCustomerServiceApp.modal.task.section.customerInformation.mail')} variant={TabularInformationItemType.GREY} />
				<TabularInformationItem label={customer.mailAddress} />
			</TabularInformationRow>
		</section>
	);
}
