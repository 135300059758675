import { AnyCustomerServiceSearchResultModel } from '@abb-emobility/platform/domain-model';
import { FilterCriteria, SingleValueFilterComparator, SortCriteria, SortDirection } from '@abb-emobility/shared/api-integration-foundation';

export const createFilterCriteriaFromSearchString = (searchString: string): FilterCriteria<AnyCustomerServiceSearchResultModel> => {
	return (
		[
			{
				id: 'search',
				criteria: [{
					flavor: 'free',
					property: 'query',
					comparator: SingleValueFilterComparator.LIKE,
					value: searchString
				}]
			}
		]
	);
};

export const createSearchStringFromFilterCriteria = (filterCriteria: FilterCriteria<AnyCustomerServiceSearchResultModel>): string => {
	let searchTerm = '';
	filterCriteria.forEach((val) => {
		if (val.id === 'search') {
			val.criteria.forEach(crit => {
				if (crit.property === 'query') {
					searchTerm = crit.value.toString();
				}
			});
		}
	});
	return searchTerm;
};

export const defaultCustomerServiceSearchDataProviderSortCriteria: SortCriteria<AnyCustomerServiceSearchResultModel> = [
	{ property: 'createdAt', direction: SortDirection.DESCENDING }
];
