export enum CustomerServiceCustomerCallReason {
	COMPLAINTS = 'COMPLAINTS',
	AFTER_SALES = 'AFTER_SALES',
	PRODUCT = 'PRODUCT',
	INSTALLATION = 'INSTALLATION',
	PRE_CHECK = 'PRE_CHECK',
	PAYMENT = 'PAYMENT',
	DATA_PROTECTION = 'DATA_PROTECTION',
	SHIPMENT = 'SHIPMENT',
	RETURNS = 'RETURNS',
	FALSE_ROUTING = 'FALSE_ROUTING',
	OTHER = 'OTHER'
}
