export enum CustomerServiceActionType {
	ADD_CUSTOMER_NOTE = 'ADD_CUSTOMER_NOTE',
	ADD_ORDER_NOTE = 'ADD_ORDER_NOTE',
	CANCEL_ORDER = 'CANCEL_ORDER',
	CANCEL_ORDER_APPOINTMENTS = 'CANCEL_ORDER_APPOINTMENTS',
	CANCEL_ORDER_INSTALLATION = 'CANCEL_ORDER_INSTALLATION',
	REPLACE_ORDER = 'REPLACE_ORDER',
	RETURN_ORDER = 'RETURN_ORDER',
	UPDATE_CUSTOMER = 'UPDATE_CUSTOMER',
	UPDATE_ORDER = 'UPDATE_ORDER',
	RESEND_MAIL = 'RESEND_MAIL',
	EXTERNAL_LINK = 'EXTERNAL_LINK'
}
