import { ModelConverter } from '@abb-emobility/shared/domain-model-foundation';

import {
	CustomerServiceOrderInformationApprovalInformationModel
} from '../order-information/CustomerServiceOrderInformationApprovalInformationModel';

export type CustomerServiceOrderEventInstallationApprovalMailSentDetailModel = {
	mailAddress: string,
	confirmationData?: CustomerServiceOrderInformationApprovalInformationModel,
};

export class CustomerServiceOrderEventInstallationApprovalMailSentDetailModelConverter extends ModelConverter<CustomerServiceOrderEventInstallationApprovalMailSentDetailModel> {
}
