import React, { useEffect, useState } from 'react';

import {
	CustomerServiceReplaceableOrderLineItemModel,
	CustomerServiceReplaceOrderInvocationPayloadModelConverter,
	CustomerServiceReplaceOrderPayloadModelConverter
} from '@abb-emobility/platform/domain-model';
import { CommandStatus, useCommandData } from '@abb-emobility/shared/command-api';
import { OrderReturnReason } from '@abb-emobility/shared/domain-model';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	ActionModalFooter,
	ButtonGhost,
	ButtonPrimary,
	InputCheckbox,
	InputSelect,
	InputSelectOption,
	InputTextArea,
	NotificationBig,
	NotificationBigStatus,
	SectionHeader,
	SegmentHeader,
	SpinnerCircle,
	SubmissionStatus,
	SuccessFeeback,
	SuccessImageIdentifier,
	TabularInformationItem,
	TabularInformationItemType,
	useModalDialogue,
	useModalDialogueManager
} from '@abb-emobility/shared/ui-primitive';
import { l10nLiteralFromEnumValue, Nullable } from '@abb-emobility/shared/util';

import { OrderLineItem } from '../../../order-line-item/OrderLineItem';
import { OrderLineItemCollectionHeader } from '../../../order-line-item-collection-header/OrderLineItemCollectionHeader';
import { HypermediaAction } from '../../ActionModalFactory.types';

import './ReplaceOrderActionModal.scss';

type ReplaceOrderActionModalProps = {
	action: HypermediaAction
};

export function ReplaceOrderActionModal(props: ReplaceOrderActionModalProps) {

	const { action } = props;

	const l10n = useL10n();
	const modalDialogue = useModalDialogue();
	const dialogueManager = useModalDialogueManager();

	const commandData = useCommandData(
		action.hypermediaLink,
		new CustomerServiceReplaceOrderPayloadModelConverter(),
		new CustomerServiceReplaceOrderInvocationPayloadModelConverter()
	);
	const fetchPayload = commandData.query();
	const fetchError = commandData.queryFetchError();
	const fetchStatus = commandData.queryFetchStatus();
	const commandInvocationStatus = commandData.queryInvocationStatus();
	const commandInvocationError = commandData.queryInvocationError();

	const [reason, setReason] = useState<Nullable<OrderReturnReason>>(null);
	
	const [orderLineItems, setOrderLineItems] = useState<Array<CustomerServiceReplaceableOrderLineItemModel>>([]);
	const [comment, setComment] = useState<Nullable<string>>(null);

	useEffect(() => {
		if (fetchStatus === CommandStatus.SUCCESSFULL && fetchPayload !== null) {
			setOrderLineItems(fetchPayload.replaceableOrderLineItems.filter((item => item.replaceable)));
		}
		return;

	}, [fetchStatus]);

	useEffect(() => {
		const handleSubmitAction = () => {
			if(reason !== null){
			commandData.invoke({
				orderLineItems: orderLineItems.map(item => {
					return {
						id: item.orderLineItem.id
					};
				}),
				reason: reason,
				comment: comment ?? undefined
			});
		}
		};

		const isFormValid = (): boolean => {
			const orderLineItemsReady = orderLineItems.length > 0;
			const reasonReady =
				(reason !== null) && reason !== OrderReturnReason.OTHER ||
				((reason === OrderReturnReason.OTHER) && (comment?.length ?? 0) > 0);

			return orderLineItemsReady && reasonReady;
		};

		switch (commandInvocationStatus) {
			case CommandStatus.IDLE:
				modalDialogue.setFooter(
					<ActionModalFooter>
						<ButtonGhost
							label={l10n.translate('platformCustomerServiceApp.actionModal.replaceOrder.footer.cancel')}
							onClick={handleDismissDialogue}
						/>
						<ButtonPrimary
							label={l10n.translate('platformCustomerServiceApp.actionModal.replaceOrder.footer.confirm')}
							submissionStatus={SubmissionStatus.IDLE}
							onClick={handleSubmitAction}
							disabled={!isFormValid()}
						/>
					</ActionModalFooter>
				);
				break;
			case CommandStatus.PENDING:
				modalDialogue.setFooter(
					<ActionModalFooter>
						<ButtonGhost
							label={l10n.translate('platformCustomerServiceApp.actionModal.replaceOrder.footer.cancel')}
							onClick={handleDismissDialogue}
						/>
						<ButtonPrimary
							label={l10n.translate('platformCustomerServiceApp.actionModal.replaceOrder.footer.confirm')}
							submissionStatus={SubmissionStatus.PENDING}
							onClick={handleSubmitAction}
						/>
					</ActionModalFooter>
				);
				break;
			case CommandStatus.SUCCESSFULL:
			case CommandStatus.FAILED:
				modalDialogue.unsetFooter();
				break;
		}

	}, [commandInvocationStatus, orderLineItems, reason, comment]);

	const options: Array<InputSelectOption<OrderReturnReason>> = Object.values(OrderReturnReason)
		.map((value) => (
				{
					label: l10n.translate(l10nLiteralFromEnumValue(
							value,
							'platformCustomerServiceApp.actionModal.replaceOrder.returnReason'
						)
					),
					value: value
				}
			)
		);

	const handleDismissDialogue = () => {
		dialogueManager.pop();
	};

	const handleReasonChange = (value: OrderReturnReason): void => {
		setReason(value);
	};

	const handleCommentChange = (value: string): void => {
		setComment(value);
	};

	const addItemToPayload = (item: CustomerServiceReplaceableOrderLineItemModel): void => {
		setOrderLineItems((prev) => {
				return [...prev, item];
			}
		);
	};

	const removeItemFromPayload = (item: CustomerServiceReplaceableOrderLineItemModel): void => {
		setOrderLineItems((prev) => {
				return prev.filter((currentItem) => {
					return (currentItem.orderLineItem.id !== item.orderLineItem.id);
				});
			}
		);
	};

	const handleItemsChange = (checked: boolean, item: CustomerServiceReplaceableOrderLineItemModel): void => {
		if (checked) {
			addItemToPayload(item);
		} else {
			removeItemFromPayload(item);
		}
	};

	const renderReasonDescription = () => {
		if (reason === OrderReturnReason.OTHER) {
			return (
				<InputTextArea
					placeholder={l10n.translate('platformCustomerServiceApp.actionModal.replaceOrder.note')}
					rows={5}
					onChange={handleCommentChange}
					value={comment ?? ''}
				/>
			);
		}

		return;
	};
	const renderOrderLineItems = () => {
		if (fetchPayload?.replaceableOrderLineItems?.length === 0) {
			return null;
		}

		return fetchPayload?.replaceableOrderLineItems.map((item, idx) => {
				return (
					<OrderLineItem key={item.orderLineItem.id} position={idx} orderLineItem={item.orderLineItem}>
						<TabularInformationItem label={''} variant={TabularInformationItemType.GREY} width={150}>
							<InputCheckbox
								label={''}
								value={item.orderLineItem.productName}
								onChange={(checked) => (handleItemsChange(checked, item))}
								disabled={!item.replaceable}
								checked={item.replaceable && orderLineItems.some(currentItem => currentItem.orderLineItem.id === item.orderLineItem.id)}
							/>
						</TabularInformationItem>
					</OrderLineItem>
				);
			}
		);
	};

	if (fetchPayload === null) {
		return (
			<SpinnerCircle />
		);
	}

	if (fetchError) {
		return (
			<NotificationBig
				status={NotificationBigStatus.ERROR}
				heading={l10n.translate('platformCustomerServiceApp.actionModal.replaceOrder.error.loading.heading')}
				message={l10n.translate('platformCustomerServiceApp.actionModal.replaceOrder.error.loading.message')}
				subline={fetchError?.message}
			/>
		);
	}

	if (commandInvocationStatus === CommandStatus.SUCCESSFULL) {
		return (
			<SuccessFeeback
				heading={l10n.translate('platformCustomerServiceApp.actionModal.replaceOrder.content.successHeading')}
				image={SuccessImageIdentifier.IMAGE4}
			>
				<ButtonGhost
					label={l10n.translate('platformCustomerServiceApp.actionModal.replaceOrder.content.closeButtonLabel')}
					onClick={handleDismissDialogue}
				/>
			</SuccessFeeback>
		);
	}

	if (commandInvocationStatus === CommandStatus.FAILED) {
		return (
			<NotificationBig
				status={NotificationBigStatus.ERROR}
				heading={l10n.translate('platformCustomerServiceApp.actionModal.replaceOrder.error.writing.heading')}
				message={l10n.translate('platformCustomerServiceApp.actionModal.replaceOrder.error.writing.message')}
				subline={commandInvocationError?.message}
			/>

		);
	}

	return (
		<section className="replace-order-action-modal">
			<div>
				<section className="replace-order-action-modal__section">
					<SectionHeader heading={l10n.translate('platformCustomerServiceApp.actionModal.replaceOrder.selectReturn')} />
				</section>
				<SegmentHeader caption={l10n.translate('platformCustomerServiceApp.actionModal.replaceOrder.heading')} />
				<OrderLineItemCollectionHeader>
					<TabularInformationItem
						label={l10n.translate('platformCustomerServiceApp.actionModal.replaceOrder.return')}
						variant={TabularInformationItemType.HEADING}
						width={150} />
				</OrderLineItemCollectionHeader>

				{renderOrderLineItems()}
			</div>

			<section className="replace-order-action-modal__section">
				<SectionHeader heading={l10n.translate('platformCustomerServiceApp.actionModal.replaceOrder.selectReason')} />
				<div className="replace-order-action-modal__section__item">
					<InputSelect<OrderReturnReason>
						options={options}
						onSelect={handleReasonChange}
						value={reason === null ? undefined : reason}
						placeholder={l10n.translate('platformCustomerServiceApp.actionModal.pleaseSelect')}
					/>
					{renderReasonDescription()}
				</div>
			</section>
		</section>
	);
}
