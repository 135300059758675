import { AnyCustomerServiceCommentModel, AnyCustomerServiceCommentModelConverter } from '@abb-emobility/platform/domain-model';
import { AbstractCrudApiClient } from '@abb-emobility/shared/api-integration-foundation';

export class CustomerServiceCustomerCommentApiClient extends AbstractCrudApiClient<AnyCustomerServiceCommentModel> {
	protected collectionControllerUri = 'customer-comments/';
	protected paginationControllerUri = 'customer-comments/';
	protected entityControllerUri = 'customer-comment/{id}/';
	protected entitySearchControllerUri = 'customer-comment/';
	protected modelConverter = new AnyCustomerServiceCommentModelConverter();
}
