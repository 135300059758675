import React, { ReactNode } from 'react';

import {
	CustomerServiceOrderInformationCollectionDataProvider,
	CustomerServiceOrderInstallationAppointmentCollectionDataProvider
} from '@abb-emobility/platform/data-provider';
import { CustomerServiceOrderModel } from '@abb-emobility/platform/domain-model';
import { InstallationType } from '@abb-emobility/shared/domain-model';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { UiErrorHandler } from '@abb-emobility/shared/ui-error-handler';
import { Notification, NotificationLevel, SpinnerCircle } from '@abb-emobility/shared/ui-primitive';
import { l10nLiteralFromEnumValue } from '@abb-emobility/shared/util';

import { OrderDetailInstallationAppointments } from './OrderDetailInstallationAppointments';
import { OrderDetailInstallationInformation } from './OrderDetailInstallationInformation';

import './OrderDetailInstallation.scss';

type OrderDetailInstallationProps = {
	order: CustomerServiceOrderModel
};

export function OrderDetailInstallation(props: OrderDetailInstallationProps) {
	const { order } = props;

	const l10n = useL10n();

	const renderCancellationNotification = (): ReactNode => {
		if (order.installationType !== InstallationType.CANCELLED) {
			return null;
		}
		return (
			<Notification
				heading={l10n.translate('platformCustomerServiceApp.orderDetail.installation.cancellation.notification.heading')}
				message={l10n.translate('platformCustomerServiceApp.orderDetail.installation.cancellation.notification.message', new Map([
					['cancellationDate', l10n.formatTimestampDateTime(order.cancellationDate)],
					['cancellationReason', l10n.translate(l10nLiteralFromEnumValue(order.cancellationReason ?? '', 'platformCustomerServiceApp.actionModal.cancelInstallation.cancelReasonValue'))]
				]))}
				level={NotificationLevel.INFO}
				dismissable={false}
			/>
		);
	};

	const renderInformationSection = (): ReactNode => {
		return (
			<UiErrorHandler>
				<CustomerServiceOrderInformationCollectionDataProvider
					orderId={order.id}
					pendingComponent={SpinnerCircle}
				>
					<OrderDetailInstallationInformation />
				</CustomerServiceOrderInformationCollectionDataProvider>
			</UiErrorHandler>
		);
	};

	return (
		<section className="order-detail-installation">
			{renderCancellationNotification()}
			{renderInformationSection()}
			<UiErrorHandler>
				<CustomerServiceOrderInstallationAppointmentCollectionDataProvider
					orderId={order.id}
					pendingComponent={SpinnerCircle}
				>
					<OrderDetailInstallationAppointments />
				</CustomerServiceOrderInstallationAppointmentCollectionDataProvider>
			</UiErrorHandler>
		</section>

	);
}
