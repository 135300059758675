import { ModelConverter } from '@abb-emobility/shared/domain-model-foundation';
import { ArrayOfTimestampFieldNames, TimestampFieldNames } from '@abb-emobility/shared/domain-model-foundation';

import { CustomerServiceOrderInformationFoundationModel } from './foundation/CustomerServiceOrderInformationFoundationModel';
import { CustomerServiceOrderInformationType } from './foundation/CustomerServiceOrderInformationType';

export type AnyCustomerServiceOrderInformationCollectionItemModel = {
	type: CustomerServiceOrderInformationType
} & Omit<CustomerServiceOrderInformationFoundationModel, 'comment'>;

export class AnyCustomerServiceOrderInformationCollectionItemModelConverter extends ModelConverter<AnyCustomerServiceOrderInformationCollectionItemModel> {

	override getTimestampFields(): Array<TimestampFieldNames<AnyCustomerServiceOrderInformationCollectionItemModel> | ArrayOfTimestampFieldNames<AnyCustomerServiceOrderInformationCollectionItemModel>> {
		return ['createdAt'];
	}

}
