import React from 'react';

import {
	CustomerServiceOrderPaymentBankAccountModel,
	CustomerServiceOrderPaymentCreditCardModel, CustomerServiceOrderPaymentModel,
	isCustomerServiceOrderPaymentBankAccount,
	isCustomerServiceOrderPaymentCreditCard
} from '@abb-emobility/platform/domain-model';
import { formatCreditcardNumber, formatIban } from '@abb-emobility/shared/formatter';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	Card,
	SegmentHeader,
	TabularInformationItem,
	TabularInformationItemType,
	TabularInformationRow
} from '@abb-emobility/shared/ui-primitive';
import { l10nLiteralFromEnumValue } from '@abb-emobility/shared/util';

import './Payments.scss';

export type AccountingProps = {
	payments: Array<CustomerServiceOrderPaymentModel>
	noBackground?: boolean
};

export function Payments(props: AccountingProps) {
	const { payments, noBackground = false } = props;

	const l10n = useL10n();

	const renderSections = () => {
		if (payments.length === 0) {
			return null;
		}
		return payments.map((payment) => {
			const renderErrorMessage = () => {
				if (payment.errorMessage) {
					return (
						<TabularInformationRow key={payment.id}>
							<TabularInformationItem
								label={l10n.translate('platformCustomerServiceApp.orderDetail.accounting.notification')}
								variant={TabularInformationItemType.GREY}
							/>
							<TabularInformationItem label={payment.errorMessage} />
						</TabularInformationRow>
					);
				}
				return;
			};

			const renderCreditCard = (details: CustomerServiceOrderPaymentCreditCardModel) => {
				return (
					<>
						<TabularInformationRow>
							<TabularInformationItem
								label={l10n.translate('platformCustomerServiceApp.orderDetail.accounting.cardOwner')}
								variant={TabularInformationItemType.GREY}
							/>
							<TabularInformationItem label={details.cardOwner} />
						</TabularInformationRow>

						<TabularInformationRow>
							<TabularInformationItem
								label={l10n.translate('platformCustomerServiceApp.orderDetail.accounting.cardNumber')}
								variant={TabularInformationItemType.GREY}
							/>
							<TabularInformationItem label={formatCreditcardNumber(details.cardNumber)} />
						</TabularInformationRow>

						<TabularInformationRow>
							<TabularInformationItem
								label={l10n.translate('platformCustomerServiceApp.orderDetail.accounting.cardDueDate')}
								variant={TabularInformationItemType.GREY}
							/>
							<TabularInformationItem label={details.expiryDate} />
						</TabularInformationRow>
					</>

				);
			};

			const renderBankAccount = (details: CustomerServiceOrderPaymentBankAccountModel) => {
				return (
					<>
						<TabularInformationRow>
							<TabularInformationItem
								label={l10n.translate('platformCustomerServiceApp.orderDetail.accounting.accountOwner')}
								variant={TabularInformationItemType.GREY}
							/>
							<TabularInformationItem label={details.accountOwner} />
						</TabularInformationRow>

						<TabularInformationRow>
							<TabularInformationItem
								label={l10n.translate('platformCustomerServiceApp.orderDetail.accounting.accountNumber')}
								variant={TabularInformationItemType.GREY}
							/>
							<TabularInformationItem label={formatIban(details.iban)} />
						</TabularInformationRow>
					</>
				);
			};

			const renderDetails = () => {
				if (isCustomerServiceOrderPaymentCreditCard(payment.details)) {
					return renderCreditCard(payment.details);
				}

				if (isCustomerServiceOrderPaymentBankAccount(payment.details)) {
					return renderBankAccount(payment.details);
				}

				return null;
			};

			const renderContent = () => {
				return (
					<React.Fragment key={payment.id}>

						<SegmentHeader caption={l10n.translate('platformCustomerServiceApp.orderDetail.accounting.heading')} />

						<TabularInformationRow>
							<TabularInformationItem
								label={l10n.translate('platformCustomerServiceApp.orderDetail.accounting.date')}
								variant={TabularInformationItemType.GREY}
							/>
							<TabularInformationItem label={l10n.formatTimestampDateTime(payment.checkoutDate)} />
						</TabularInformationRow>

						<TabularInformationRow>
							<TabularInformationItem
								label={l10n.translate('platformCustomerServiceApp.orderDetail.accounting.state')}
								variant={TabularInformationItemType.GREY}
							/>
							<TabularInformationItem label={l10n.translate(l10nLiteralFromEnumValue(payment.status, 'platformCustomerServiceApp.orderDetail.accounting.paymentState'))} />
						</TabularInformationRow>

						{renderErrorMessage()}

						{renderDetails()}

					</React.Fragment>
				);
			};
			return (
				noBackground ? <div> {renderContent()} </div> : <Card> {renderContent()}</Card>
			);
		});
	};

	return (
		<section className="payments">
			{renderSections()}
		</section>
	);
}
