import React, { ReactNode } from 'react';

import { CustomerServiceOrderInformationGridOperatorDecisionModel } from '@abb-emobility/platform/domain-model';
import { DecisionType } from '@abb-emobility/shared/domain-model';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	CollectionItemContentSection,
	DownloadList,
	DownloadListItem, GridOperatorInformation,
	Hint,
	HintLevel,
	IconIdentifier, OrderComment, SectionHeader
} from '@abb-emobility/shared/ui-primitive';
import { l10nLiteralFromEnumValue } from '@abb-emobility/shared/util';

type OrderInformationGridOperatorDecisionProps = {
	orderInformation: CustomerServiceOrderInformationGridOperatorDecisionModel
};

export function OrderInformationGridOperatorDecision(props: OrderInformationGridOperatorDecisionProps) {

	const { orderInformation } = props;

	const l10n = useL10n();

	const renderNotification = (): ReactNode => {
		const hintHeading = l10n.translate(
			l10nLiteralFromEnumValue(orderInformation.payload.decision, 'platformCustomerServiceApp.orderDetail.informationList.decision.decision')
		);
		switch (orderInformation.payload.decision) {
			case DecisionType.ACCEPTED:
				return (<Hint
					heading={hintHeading}
					level={HintLevel.SUCCESS}
					icon={IconIdentifier.CHECK}
				/>);
			case DecisionType.PENDING:
				return (<Hint
					heading={hintHeading}
					level={HintLevel.WARNING}
					icon={IconIdentifier.WARNING}
				/>);
			case DecisionType.DENIED:
				return (<Hint
					heading={hintHeading}
					level={HintLevel.ERROR}
					icon={IconIdentifier.WARNING}
				/>);
			default:
				return null;
		}
	};

	const renderNotes = () => {
		if (orderInformation.comment) {
			return (
				<CollectionItemContentSection>
					<SectionHeader heading={l10n.translate('platformCustomerServiceApp.orderDetail.informationList.precheck.notes')} />
					<OrderComment comment={orderInformation.comment} />
				</CollectionItemContentSection>
			);
		}
		return;
	};

	const renderFiles = () => {
		const files = orderInformation.payload.files ?? [];
		if (files.length === 0) {
			return null;
		}
		return (
			<DownloadList>
				{files.map((file, index) => (<DownloadListItem file={file} key={index} />))}
			</DownloadList>
		);
	};

	return (
		<>
			{renderNotification()}

			<CollectionItemContentSection>
				<SectionHeader heading={l10n.translate('platformCustomerServiceApp.orderDetail.informationList.registration.gridOperator')} />
				<GridOperatorInformation gridOperator={orderInformation.payload.gridOperator} />
			</CollectionItemContentSection>

			<CollectionItemContentSection>
				<SectionHeader heading={l10n.translate('platformCustomerServiceApp.orderDetail.informationList.registration.documents')} />
				{renderFiles()}
			</CollectionItemContentSection>

			{renderNotes()}
		</>
	);

}
