import React, { ReactNode } from 'react';

import './ViewSubheaderItem.scss';

export type ViewSubheaderItemProps = {
	label: string,
	children: ReactNode
};

export function ViewSubheaderItem(props: ViewSubheaderItemProps): React.JSX.Element {
	const { label, children } = props;

	return (
		<span className={'view-subheader-item'}>
			{label}
			{children}
		</span>
	);
}
