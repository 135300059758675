import React, { ReactNode } from 'react';

import {
	useCustomerServiceMarketplaceOrderEntityData
} from '@abb-emobility/platform/data-provider';
import { CustomerServiceMarketplaceOrderPaymentCollectionDataProvider } from '@abb-emobility/platform/data-provider';
import { createFullNameFromCustomer } from '@abb-emobility/platform/domain-model';
import { NotFoundError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { UiErrorHandler } from '@abb-emobility/shared/ui-error-handler';
import {
	SpinnerCircle,
	Status,
	TabbarItem,
	TabbedPane,
	ViewHeader,
	ViewSubheader,
	ViewSubheaderItem,
	ViewSubheaderItemValueNumber
} from '@abb-emobility/shared/ui-primitive';
import { buildCssClassStringFromClassMap, l10nLiteralFromEnumValue } from '@abb-emobility/shared/util';

import './MarketplaceOrderDetail.scss';
import { MarketplaceOrderDetailAccounting } from '../marketplace-order-detail-accounting/MarketplaceOrderDetailAccounting';
import { MarketplaceOrderDetailOverview } from '../marketplace-order-detail-overview/MarketplaceOrderDetailOverview';

enum TabItem {
	OVERVIEW = 'OVERVIEW',
	ACCOUNTING = 'ACCOUNTING',
}

type OrderDetailContentProps = {
	modal?: boolean
};

export function MarketplaceOrderDetail(props: OrderDetailContentProps) {

	const { modal = false } = props;

	const l10n = useL10n();

	const orderData = useCustomerServiceMarketplaceOrderEntityData();

	const order = orderData.query().getOrThrow(new NotFoundError(l10n.translate('platformCustomerServiceApp.error.orderNotFound')));

	const renderTabbedPane = (): ReactNode => {
		return (
			<TabbedPane activeTabId={TabItem.OVERVIEW} renderTabContent={renderTabContent} connectUrl={true}>
				<TabbarItem
					label={l10n.translate('platformCustomerServiceApp.orderDetail.tabItems.overview')}
					key={TabItem.OVERVIEW}
					tabId={TabItem.OVERVIEW}
				/>
				<TabbarItem
					label={l10n.translate('platformCustomerServiceApp.orderDetail.tabItems.accounting')}
					key={TabItem.ACCOUNTING}
					tabId={TabItem.ACCOUNTING}
				/>
			</TabbedPane>
		);
	};

	const renderTabContent = (tabId: TabItem): ReactNode => {
		switch (tabId) {
			case TabItem.OVERVIEW: {
				return (
					<MarketplaceOrderDetailOverview order={order} />
				);
			}
			case TabItem.ACCOUNTING: {
				return (
					<UiErrorHandler key={TabItem.ACCOUNTING}>
						<CustomerServiceMarketplaceOrderPaymentCollectionDataProvider
							orderId={order.id}
							pendingComponent={SpinnerCircle}
						>
							<MarketplaceOrderDetailAccounting />
						</CustomerServiceMarketplaceOrderPaymentCollectionDataProvider>
					</UiErrorHandler>
				);
			}
			default:
				return null;
		}
	};

	const classMap = {
		'marketplace-order-detail-content': true,
		'marketplace-order-detail-content--modal': modal
	};

	return (
		<section className={buildCssClassStringFromClassMap(classMap)}>
			<header>
				<ViewHeader heading={createFullNameFromCustomer(order.customer)} />
				<ViewSubheader>
					<ViewSubheaderItem label={l10n.translate('platformCustomerServiceApp.orderDetail.subheader.orderDate') + ': '}>
						{l10n.formatTimestampDate(order.orderDate)}
					</ViewSubheaderItem>
					<ViewSubheaderItem label={l10n.translate('platformCustomerServiceApp.orderDetail.subheader.orderId') + ': '}>
						<ViewSubheaderItemValueNumber>
							{order.marketplaceOrderId}
						</ViewSubheaderItemValueNumber>
					</ViewSubheaderItem>
					<ViewSubheaderItem label={l10n.translate('platformCustomerServiceApp.orderDetail.subheader.marketplace') + ': '}>
						{order.marketplace}
					</ViewSubheaderItem>
					<Status
						label={l10n.translate(l10nLiteralFromEnumValue(order.state, 'platformCustomerServiceApp.marketplaceOrder.orderState'))}
						state={order.state}
					/>
				</ViewSubheader>
			</header>
			{renderTabbedPane()}
		</section>
	);
}
