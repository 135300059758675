import {
	CustomerServiceSearchResultType,
	CustomerServiceCustomerSearchResultModel,
	CustomerServiceMarketplaceOrderSearchResultModel,
	CustomerServiceOrderSearchResultModel
} from '@abb-emobility/platform/domain-model';
import { Dto, ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';

import { customerServiceCustomerModelMock } from './CustomerServiceCustomerModel.mock';
import { customerServiceMarketplaceOrderCollectionItemModelMock } from './CustomerServiceMarketplaceOrderCollectionItemModel.mock';
import { customerServiceOrderCollectionItemModelMock } from './CustomerServiceOrderCollectionItemModel.mock';

export const customerServiceOrderSearchResultModelMockTemplate: Dto<CustomerServiceOrderSearchResultModel> = {
	id: '1',
	type: CustomerServiceSearchResultType.ORDER,
	payload: customerServiceOrderCollectionItemModelMock
};

export const customerServiceOrderSearchResultModelMock = (id: ModelPrimaryKey = '1'): Dto<CustomerServiceOrderSearchResultModel> => {
	return { ...customerServiceOrderSearchResultModelMockTemplate, id };
};

export const customerServiceCustomerSearchResultModelMockTemplate: Dto<CustomerServiceCustomerSearchResultModel> = {
	id: '2',
	type: CustomerServiceSearchResultType.CUSTOMER,
	payload: customerServiceCustomerModelMock
};

export const customerServiceCustomerSearchResultModelMock = (id: ModelPrimaryKey = '1'): Dto<CustomerServiceCustomerSearchResultModel> => {
	return { ...customerServiceCustomerSearchResultModelMockTemplate, id };
};

export const customerServiceMarketplaceOrderSearchResultModelMockTemplate: Dto<CustomerServiceMarketplaceOrderSearchResultModel> = {
	id: '1',
	type: CustomerServiceSearchResultType.MARKETPLACE_ORDER,
	payload: customerServiceMarketplaceOrderCollectionItemModelMock
};

export const customerServiceMarketplaceOrderSearchResultModelMock = (id: ModelPrimaryKey = '1'): Dto<CustomerServiceMarketplaceOrderSearchResultModel> => {
	return { ...customerServiceMarketplaceOrderSearchResultModelMockTemplate, id };
};

export const anyCustomerServiceSearchResultModelCollectionMock = [
	customerServiceOrderSearchResultModelMock,
	customerServiceMarketplaceOrderSearchResultModelMock,
	customerServiceCustomerSearchResultModelMock
];
