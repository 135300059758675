import React from 'react';

import { CustomerServiceOrderInstallationAppointmentModel } from '@abb-emobility/platform/domain-model';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	SectionHeader,
	TabularInformationItem,
	TabularInformationItemType,
	TabularInformationRow
} from '@abb-emobility/shared/ui-primitive';

import './InstallationAppointments.scss';

type SortedTradeAppointment = {
	tradeName: string,
	installerAppointments: Array<CustomerServiceOrderInstallationAppointmentModel>
};

export type InstallationAppointmentsProps = {
	installerAppointments: Array<CustomerServiceOrderInstallationAppointmentModel>
}

export function InstallationAppointments(props: InstallationAppointmentsProps) {

	const { installerAppointments } = props;

	const l10n = useL10n();

	const appointmentCollection = installerAppointments;
	const hasAppointments = appointmentCollection.length > 0;

	const sortedAppointments = (): Array<SortedTradeAppointment> => {
		const result: Array<SortedTradeAppointment> = [];

		appointmentCollection.forEach((installerAppointment) => {
			const availableResult = result.find(item => item.tradeName === installerAppointment.trade.name);

			if (availableResult) {
				availableResult.installerAppointments.push(installerAppointment);
				return;
			} else {
				result.push({
					tradeName: installerAppointment.trade.name,
					installerAppointments: [installerAppointment]
				});
			}
		});

		return result;
	};

	const renderTradeAppointments = () => {
		if (!hasAppointments) {
			return null;
		}

		return sortedAppointments().map((tradeAppointment) => {

			const renderAppointments = () => {
				return tradeAppointment.installerAppointments.map((appointment, index) => {

					const { name = 'Name', phoneNumber = '—', mailAddress = '—' } = appointment.installer;

					const startTime = l10n.formatTimestampTime(appointment.period.start);
					const endTime = l10n.formatTimestampTime(appointment.period.end);

					return (
						<TabularInformationRow key={index}>
							<TabularInformationItem
								label={name}
								variant={TabularInformationItemType.GREY}
								width={200}
							/>
							<TabularInformationItem
								label={`${l10n.formatTimestampDate(appointment.periodStartsAt)}; ${startTime} - ${endTime}`}
								width={200}
							/>
							<TabularInformationItem label={phoneNumber} />
							<TabularInformationItem label={mailAddress} />
						</TabularInformationRow>
					);
				});
			};

			return (
				<section className="installation-appointments__section" key={tradeAppointment.tradeName}>
					<div className="installation-appointments__section__header">
						<SectionHeader heading={tradeAppointment.tradeName} />
					</div>
					<TabularInformationRow>
						<TabularInformationItem
							label={l10n.translate('platformCustomerServiceApp.orderDetail.installation.installationAppointments.installer')}
							variant={TabularInformationItemType.HEADING}
							width={200}
						/>
						<TabularInformationItem
							label={l10n.translate('platformCustomerServiceApp.orderDetail.installation.installationAppointments.appointment')}
							variant={TabularInformationItemType.HEADING}
							width={200}
						/>
						<TabularInformationItem
							label={l10n.translate('platformCustomerServiceApp.orderDetail.installation.installationAppointments.phone')}
							variant={TabularInformationItemType.HEADING}
						/>
						<TabularInformationItem
							label={l10n.translate('platformCustomerServiceApp.orderDetail.installation.installationAppointments.mail')}
							variant={TabularInformationItemType.HEADING}
						/>
					</TabularInformationRow>

					{renderAppointments()}
				</section>
			);
		});
	};

	return (
		<section className="installation-appointments">
			{renderTradeAppointments()}
		</section>

	);
}
