import React, { ReactNode } from 'react';

import { useL10n } from '@abb-emobility/shared/localization-provider';
import {

	TabularInformationItem,
	TabularInformationItemType,
	TabularInformationRow
} from '@abb-emobility/shared/ui-primitive';

export type OrderLineItemCollectionHeaderProps = {
	children?: ReactNode
};

export function OrderLineItemCollectionHeader(props: OrderLineItemCollectionHeaderProps) {
	const { children } = props;

	const l10n = useL10n();

	return (
		<TabularInformationRow>
			{children}
			<TabularInformationItem
				label={l10n.translate('platformCustomerServiceApp.orderLineItemCollection.position')}
				variant={TabularInformationItemType.HEADING}
			/>
			<TabularInformationItem
				label={l10n.translate('platformCustomerServiceApp.orderLineItemCollection.productCode')}
				variant={TabularInformationItemType.HEADING}
			/>
			<TabularInformationItem
				label={l10n.translate('platformCustomerServiceApp.orderLineItemCollection.description')}
				variant={TabularInformationItemType.HEADING}
				flex={1}
			/>
			<TabularInformationItem
				label={l10n.translate('platformCustomerServiceApp.orderLineItemCollection.price')}
				variant={TabularInformationItemType.HEADING}
			/>
		</TabularInformationRow>
	);
}
