import React from 'react';

import { useCustomerServiceOrderEventEntityData } from '@abb-emobility/platform/data-provider';
import { CustomerServiceOrderEventType } from '@abb-emobility/platform/domain-model';
import { HypermediaLinkSort } from '@abb-emobility/shared/data-provider-foundation';
import { NotFoundError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	SectionHeader,
	Separator,
	TabularInformationItem,
	TabularInformationItemType,
	TabularInformationRow
} from '@abb-emobility/shared/ui-primitive';
import { l10nLiteralFromEnumValue } from '@abb-emobility/shared/util';

import { ActionItemFactory } from '../action-item-factory/ActionItemFactory';
import { useActionModalFactory } from '../action-modal-factory/ActionModalFactory.hooks';
import { createHypermediaActionFromHypermediaLink } from '../action-modal-factory/ActionModalFactory.types';
import { Approval } from '../approval/Approval';
import { InstallationAppointments } from '../installation-appointments/InstallationAppointments';
import { Payments } from '../payments/Payments';
import { Quotation } from '../quotation/Quotation';

import './TimelineItemDetails.scss';

export const TimelineItemDetails = () => {
	const customerServiceOrderEventEntityData = useCustomerServiceOrderEventEntityData();
	const event = customerServiceOrderEventEntityData.query().getOrThrow(new NotFoundError('Event details not found'));

	const hypermediaLinkSort: HypermediaLinkSort = (left, right) => left.rel.localeCompare(right.rel);
	const links = customerServiceOrderEventEntityData.queryHypermediaLinks(hypermediaLinkSort);

	const actionModalFactory = useActionModalFactory();

	const l10n = useL10n();

	const renderActionbar = () => {
		const actions = links.map((link, index) => {
			return (
				<ActionItemFactory
					key={index}
					hypermediaLink={link}
					onClick={() => actionModalFactory.renderModal(createHypermediaActionFromHypermediaLink(link))}
				/>
			);
		});

		return <div className="timeline-item-details__actions">{actions}</div>;
	};

	const renderMailAddress = (mailAddress: string) => {
		return (
			<div>
				<Separator />
				<TabularInformationRow>
					<TabularInformationItem
						label={l10n.translate('platformCustomerServiceApp.timelineItemDetails.mailAddress.label')}
						variant={TabularInformationItemType.GREY}
					/>
					<TabularInformationItem label={mailAddress} />
				</TabularInformationRow>
			</div>
		);
	};

	const renderContent = () => {
		switch (event.type) {
			case CustomerServiceOrderEventType.ORDER_CONFIRMATION_MAIL_SENT:
				return (
					<>
						{renderMailAddress(event.details.mailAddress)}
					</>
				);
			case CustomerServiceOrderEventType.GRID_OPERATOR_REJECTION_NOTIFICATION_SENT:
				return (
					<>
						{renderMailAddress(event.details.mailAddress)}
					</>
				);
			case CustomerServiceOrderEventType.PRECHECK_PREFERENCES_MAIL_SENT:
				return (
					<>
						{renderMailAddress(event.details.mailAddress)}
					</>
				);
			case CustomerServiceOrderEventType.DELTA_QUOTATION_MAIL_SENT:
				return (
					<>
						{renderMailAddress(event.details.mailAddress)}
						{event.details.quotation !== undefined ?
							<Quotation includePricing={true} quotationInformation={event.details.quotation} /> : null}
					</>
				);

			case CustomerServiceOrderEventType.PAYMENT_FAILED_OR_TIMED_OUT:
				return (
					<Quotation includePricing={true} quotationInformation={event.details.quotation} />
				);
			case CustomerServiceOrderEventType.PAYMENT_CONFIRMATION_MAIL_SENT:
				return (
					<>
						{renderMailAddress(event.details.mailAddress)}
						{event.details.quotation !== undefined ? <Quotation includePricing={true} quotationInformation={event.details.quotation} /> : null}

						<SectionHeader heading={l10n.translate('platformCustomerServiceApp.timelineItemDetails.section.payment')} />
						{event.details.paymentData !== undefined ? <Payments payments={event.details.paymentData} noBackground={true} /> : null}

					</>
				);
			case CustomerServiceOrderEventType.APPOINTMENT_ARRANGED_NOTIFICATION_SENT:
				return (
					<>
						{renderMailAddress(event.details.mailAddress)}
						<SectionHeader heading={l10n.translate('platformCustomerServiceApp.timelineItemDetails.section.appointments')} />
						{event.details.appointments !== undefined ?
							<InstallationAppointments installerAppointments={event.details.appointments} /> : null}

					</>
				);
			case CustomerServiceOrderEventType.INSTALLATION_APPROVAL_MAIL_SENT:
				return (
					<>
						{renderMailAddress(event.details.mailAddress)}
						<SectionHeader heading={l10n.translate('platformCustomerServiceApp.orderDetail.installation.information.types.approval')} />
						<div className="timeline-item-details__content__subsection">
							{event.details.confirmationData !== undefined ?
							<Approval approvalInformation={event.details.confirmationData} /> : null}
						</div>

					</>
				);
			case CustomerServiceOrderEventType.INVOICE_NOTIFICATION_SENT:
				return (
					<>
						{renderMailAddress(event.details.mailAddress)}
					</>
				);
			case CustomerServiceOrderEventType.INSTALLATION_CANCELLATION_MAIL_SENT:
				return (
					<>
						{renderMailAddress(event.details.mailAddress)}
					</>
				);
			// default:
			// 	throw new NotFoundError(l10n.translate('platformCustomerServiceApp.error.eventTypeNotFound', new Map([['eventType', event.type]])));
		}
	};

	return (
		<div className="timeline-item-details">
			{renderActionbar()}
			<h4 className="timeline-item-details__heading">
				{l10n.translate(l10nLiteralFromEnumValue(event.type, 'platformCustomerServiceApp.orderEvent.type'))}
			</h4>
			<div className="timeline-item-details__content">
				{renderContent()}
			</div>
		</div>
	);
};
