import { withProfiler } from '@sentry/react';
import React, { ReactElement } from 'react';
import { useDispatch } from 'react-redux';

import { AuthProvider, AuthProviderInterceptingProps } from '@abb-emobility/shared/auth-provider';
import { CandidateGroup, useCandidateGroups } from '@abb-emobility/shared/candidate-groups-provider';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { useEnv } from '@abb-emobility/shared/environment';
import { ErrorHandler, AppError, WebSocketError, SilentErrorHandler } from '@abb-emobility/shared/error';
import { useIdentity } from '@abb-emobility/shared/identity-provider';
import { KeycloakApiClientFactory, TokenResponseModel } from '@abb-emobility/shared/keycloak-integration';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { useNetworkAvailable } from '@abb-emobility/shared/network';
import { OauthEmitterFactory } from '@abb-emobility/shared/ui-auth-emitter';
import { ErrorFeedback, ModalDialogueManager, ScrollToTop } from '@abb-emobility/shared/ui-primitive';
import { Optional } from '@abb-emobility/shared/util';
import { WebsocketProvider } from '@abb-emobility/shared/websocket';

import { AppContainer } from './components/app-container/AppContainer';
import { DefaultErrorView } from './views/DefaultErrorView';
import { UNAUTHENTICATE_SIGNAL } from '../store/store';

type AdditionalTokenResponse = {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	user_id?: ModelPrimaryKey,
	// eslint-disable-next-line @typescript-eslint/naming-convention
	user_fullname?: string,
	// eslint-disable-next-line @typescript-eslint/naming-convention
	user_mail_address?: string,
	// eslint-disable-next-line @typescript-eslint/naming-convention
	user_candidate_groups?: ReadonlyArray<CandidateGroup>,
	// eslint-disable-next-line @typescript-eslint/naming-convention
	available_candidate_groups?: ReadonlyArray<CandidateGroup>
};

export function App() {

	const env = useEnv();
	const l10n = useL10n();
	const dispatch = useDispatch();
	const identity = useIdentity();
	const candidateGroups = useCandidateGroups();
	const networkStatus = useNetworkAvailable();

	if (!networkStatus) {
		return (
			<ErrorFeedback
				heading={l10n.translate('platformCustomerServiceApp.error.offline.heading')}
				message={l10n.translate('platformCustomerServiceApp.error.offline.message')}
			/>
		);
	}

	const stompSocketUrl = new Optional(process.env['NX_FRONTEND_NOTIFICATION_STOMP_SOCKET_URL'])
		.getOrThrow(new AppError('STOMP socket URL unavailable'));

	const handleReauthenticate = () => {
		identity.unsetIdentity();
		candidateGroups.unsetAvailableCandidateGroups();
		dispatch({ type: UNAUTHENTICATE_SIGNAL });
	};

	const handleUnauthenticate = () => {
		identity.unsetIdentity();
		candidateGroups.unsetAvailableCandidateGroups();
		dispatch({ type: UNAUTHENTICATE_SIGNAL });
		try {
			const oauthBaseUrl = new Optional(process.env['NX_KEYCLOAK_API_BASE_URL'])
				.getOrThrow(new AppError('Oauth base URL unavailable'));
			const oauthRealm = env.get<string>('oauthRealm').getOrThrow(new AppError('Oauth realm unavailable'));
			KeycloakApiClientFactory.create().logout(oauthBaseUrl, oauthRealm);
		} catch (e) {
			console.warn(e);
		}
	};

	const handleTokenReceived = (oauthTokenResponse: TokenResponseModel & AdditionalTokenResponse) => {
		identity.setIdentity({
			userId: oauthTokenResponse.user_id,
			fullname: oauthTokenResponse.user_fullname,
			mailAddress: oauthTokenResponse.user_mail_address,
			candidateGroups: oauthTokenResponse.user_candidate_groups ? [...oauthTokenResponse.user_candidate_groups] : []
		});
		candidateGroups.setAvailableCandidateGroups(oauthTokenResponse.available_candidate_groups ? [...oauthTokenResponse.available_candidate_groups] : []);
	};

	const renderAuthEmitter = (): ReactElement<AuthProviderInterceptingProps> => {
		return (<OauthEmitterFactory<AdditionalTokenResponse> onTokenReceived={handleTokenReceived} />);
	};

	const handleWebsocketError = (error: Error): boolean => {
		console.warn(error);
		return error instanceof WebSocketError;
	};

	return (
		<ErrorHandler errorComponent={DefaultErrorView}>
			<AuthProvider
				interceptingComponent={renderAuthEmitter}
				onReauthenticate={handleReauthenticate}
				onUnauthenticate={handleUnauthenticate}
			>
				<SilentErrorHandler handleError={handleWebsocketError} transparent={true}>
					<WebsocketProvider socketUrl={stompSocketUrl} onError={handleWebsocketError}>
						<ModalDialogueManager>
							<AppContainer />
						</ModalDialogueManager>
					</WebsocketProvider>
				</SilentErrorHandler>
			</AuthProvider>
			<ScrollToTop />
		</ErrorHandler>
	);
}

export const AppWithSentryProfiler = withProfiler(App);
