import React from 'react';

import { CustomerServiceOrderInformationGridOperatorRegistrationModel } from '@abb-emobility/platform/domain-model';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	CollectionItemContentSection,
	DownloadList,
	DownloadListItem,
	GridOperatorInformation,
	OrderComment,
	SectionHeader
} from '@abb-emobility/shared/ui-primitive';

type OrderInformationGridOperatorRegistrationProps = {
	orderInformation: CustomerServiceOrderInformationGridOperatorRegistrationModel
};

export function OrderInformationGridOperatorRegistration(props: OrderInformationGridOperatorRegistrationProps) {

	const { orderInformation } = props;

	const l10n = useL10n();

	const renderComment = () => {
		if (orderInformation.comment) {
			return (
				<CollectionItemContentSection>
					<SectionHeader heading={l10n.translate('platformCustomerServiceApp.orderDetail.informationList.precheck.notes')} />
					<OrderComment comment={orderInformation.comment} />
				</CollectionItemContentSection>
			);
		}
		return;
	};

	const renderFiles = () => {
		const files = orderInformation.payload.files ?? [];
		return (
			<DownloadList>
				{files.map((file, index) => (<DownloadListItem file={file} key={index} />))}
			</DownloadList>
		);
	};

	return (
		<>
			<CollectionItemContentSection>
				<SectionHeader heading={l10n.translate('platformCustomerServiceApp.orderDetail.informationList.registration.gridOperator')} />
				<GridOperatorInformation gridOperator={orderInformation.payload.gridOperator} />
			</CollectionItemContentSection>
			<CollectionItemContentSection>
				<SectionHeader heading={l10n.translate('platformCustomerServiceApp.orderDetail.informationList.registration.documents')} />
				{renderFiles()}
			</CollectionItemContentSection>
			{renderComment()}
		</>
	);

}
