import { ReactNode } from 'react';

import './Timeline.scss';

export type TimelineProps = {
	children: ReactNode
};

export const Timeline = (props: TimelineProps) => {
	const { children } = props;

	return (
		<article className="timeline">
			{children}
		</article>
	);
};
