import { CustomerServiceOrderDownloadFileModel, CustomerServiceOrderDownloadFileModelConverter } from '@abb-emobility/platform/domain-model';
import { AbstractCrudApiClient } from '@abb-emobility/shared/api-integration-foundation';

export class CustomerServiceOrderDownloadFileApiClient extends AbstractCrudApiClient<CustomerServiceOrderDownloadFileModel> {
	protected collectionControllerUri = 'order-files/';
	protected paginationControllerUri = 'order-files/';
	protected entityControllerUri = 'order-file/{id}/';
	protected entitySearchControllerUri = 'order-file/';
	protected modelConverter = new CustomerServiceOrderDownloadFileModelConverter();
}
