import React from 'react';

import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { SilentErrorHandler, ValidationError } from '@abb-emobility/shared/error';
import { UiErrorHandler } from '@abb-emobility/shared/ui-error-handler';
import { SpinnerCircle } from '@abb-emobility/shared/ui-primitive';
import { AnyTaskEntityDataProvider } from '@abb-emobility/usertask/data-provider';

import { TaskFactory } from '../task-factory/TaskFactory';

import './TaskModal.scss';

type TaskModalAssemblerProps = {
	taskId: ModelPrimaryKey
};

export function TaskModalAssembler(props: TaskModalAssemblerProps) {

	const { taskId } = props;

	const handleValidationError = (error: Error) => {
		return error instanceof ValidationError;
	};

	return (
		<main className="task-modal">
			<UiErrorHandler>
				<SilentErrorHandler handleError={handleValidationError} transparent={true}>
					<AnyTaskEntityDataProvider
						primaryKey={taskId}
						pendingComponent={SpinnerCircle}
					>
						<TaskFactory />
					</AnyTaskEntityDataProvider>
				</SilentErrorHandler>
			</UiErrorHandler>
		</main>
	);
}
