import { InstallationState } from '@abb-emobility/shared/domain-model';
import { ModelConverter } from '@abb-emobility/shared/domain-model-foundation';
import { ArrayOfDownloadFileFieldNames, DownloadFile, DownloadFileFieldNames } from '@abb-emobility/shared/domain-model-foundation';

export type CustomerServiceOrderInformationApprovalInformationModel = {
	readonly installationState: InstallationState,
	readonly customerSignature?: DownloadFile,
	readonly installationDocuments: Array<DownloadFile>
};

export class CustomerServiceOrderInformationApprovalInformationModelConverter extends ModelConverter<CustomerServiceOrderInformationApprovalInformationModel> {

	override getDownloadFileFields(): Array<DownloadFileFieldNames<CustomerServiceOrderInformationApprovalInformationModel> | ArrayOfDownloadFileFieldNames<CustomerServiceOrderInformationApprovalInformationModel>> {
		return ['customerSignature', 'installationDocuments'];
	}

}
