import React, { useEffect, useState } from 'react';

import {
	CustomerServiceCancelAppointmentsInvocationPayloadModelConverter,
	CustomerServiceCancelAppointmentsReason
} from '@abb-emobility/platform/domain-model';
import { CommandStatus, useCommandData } from '@abb-emobility/shared/command-api';
import { VoidModelConverter } from '@abb-emobility/shared/domain-model-foundation';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	ActionModalFooter,
	ButtonGhost,
	ButtonPrimary,
	Hint,
	InputSelect,
	InputSelectOption,
	NotificationBig,
	NotificationBigStatus,
	SubmissionStatus,
	SuccessFeeback,
	SuccessImageIdentifier,
	useModalDialogue,
	useModalDialogueManager
} from '@abb-emobility/shared/ui-primitive';
import { l10nLiteralFromEnumValue, Nullable } from '@abb-emobility/shared/util';

import { HypermediaAction } from '../../ActionModalFactory.types';
import './CancelOrderAppointmentsActionModal.scss';

export type CancelOrderAppointmentsActionModalProps = {
	action: HypermediaAction
};

export function CancelOrderAppointmentsActionModal(props: CancelOrderAppointmentsActionModalProps) {

	const { action } = props;

	const l10n = useL10n();
	const dialogueManager = useModalDialogueManager();
	const modalDialogue = useModalDialogue();

	const [reason, setReason] = useState<Nullable<CustomerServiceCancelAppointmentsReason>>(null);

	const commandData = useCommandData(
		action.hypermediaLink,
		new VoidModelConverter(),
		new CustomerServiceCancelAppointmentsInvocationPayloadModelConverter()
	);

	const invokeCommandStatus = commandData.queryInvocationStatus();
	const invokeCommandError = commandData.queryInvocationError();

	const options: Array<InputSelectOption<CustomerServiceCancelAppointmentsReason>> = Object.values(CustomerServiceCancelAppointmentsReason)
		.map((value) => (
				{
					label: l10n.translate(l10nLiteralFromEnumValue(
							value,
							'platformCustomerServiceApp.actionModal.cancelOrderAppointments.cancelReasonValue'
						)
					),
					value: value
				}
			)
		);

	const footerCancelLabel = l10n.translate('platformCustomerServiceApp.actionModal.cancelOrderAppointments.footer.cancel');
	const footerConfirmlabel = l10n.translate('platformCustomerServiceApp.actionModal.cancelOrderAppointments.footer.confirm');

	useEffect(() => {
		const handleSubmitAction = () => {
			if(reason !== null){
			commandData.invoke({
				reason: reason
			});
		}
		};

		switch (invokeCommandStatus) {
			case CommandStatus.IDLE:
				modalDialogue.setFooter(
					<ActionModalFooter>
						<ButtonGhost
							label={footerCancelLabel}
							onClick={handleDismissDialogue}
						/>
						<ButtonPrimary
							label={footerConfirmlabel}
							submissionStatus={SubmissionStatus.IDLE}
							onClick={handleSubmitAction}
							disabled={reason !== null ? false : true}
						/>
					</ActionModalFooter>
				);
				break;
			case CommandStatus.PENDING:
				modalDialogue.setFooter(
					<ActionModalFooter>
						<ButtonGhost
							label={footerCancelLabel}
							onClick={handleDismissDialogue}
						/>
						<ButtonPrimary
							label={footerConfirmlabel}
							submissionStatus={SubmissionStatus.PENDING}
							onClick={handleSubmitAction}
							disabled={false}
						/>
					</ActionModalFooter>
				);
				break;
			case CommandStatus.SUCCESSFULL:
			case CommandStatus.FAILED:
				modalDialogue.unsetFooter();
				break;
		}

	}, [invokeCommandStatus, reason]);

	const handleDismissDialogue = () => {
		dialogueManager.pop();
	};

	if (invokeCommandStatus === CommandStatus.SUCCESSFULL) {
		return (
			<SuccessFeeback
				heading={l10n.translate('platformCustomerServiceApp.actionModal.cancelOrderAppointments.content.successHeading')}
				image={SuccessImageIdentifier.IMAGE4}
			>
				<ButtonGhost
					label={l10n.translate('platformCustomerServiceApp.actionModal.cancelOrderAppointments.content.successCloseButtonLabel')}
					onClick={handleDismissDialogue}
				/>
			</SuccessFeeback>
		);
	}

	if (invokeCommandStatus === CommandStatus.FAILED) {
		return (
			<NotificationBig
				status={NotificationBigStatus.ERROR}
				heading={l10n.translate('platformCustomerServiceApp.actionModal.cancelOrderAppointments.error.heading')}
				message={l10n.translate('platformCustomerServiceApp.actionModal.cancelOrderAppointments.error.message')}
				subline={invokeCommandError?.message}
			/>
		);
	}

	const handleReasonChange = (value: CustomerServiceCancelAppointmentsReason): void => {
		setReason(value);
	};

	return (
		<section className="cancel-order-appointments-action-modal">
			<Hint
				gray={true}
				heading={l10n.translate('platformCustomerServiceApp.actionModal.cancelOrderAppointments.content.notificationHeading')}
				message={l10n.translate('platformCustomerServiceApp.actionModal.cancelOrderAppointments.content.notificationMessage')}
			/>
			<InputSelect<CustomerServiceCancelAppointmentsReason>
				label={l10n.translate('platformCustomerServiceApp.actionModal.cancelOrderAppointments.content.reason')}
				options={options}
				onSelect={handleReasonChange}
				value={reason === null ? undefined : reason}
				placeholder={l10n.translate('platformCustomerServiceApp.actionModal.pleaseSelect')}
			/>
		</section>
	);
}
