import { CustomerServiceOrderDownloadFileModel, CustomerServiceOrderDownloadFileType } from '@abb-emobility/platform/domain-model';
import { DownloadFileDto, Dto } from '@abb-emobility/shared/domain-model-foundation';

const downloadFile: DownloadFileDto = {
	url: {
		accessible: true,
		value: 'https://fiege.com'
	},
	meta: {
		mimeType: 'application/pdf',
		title: 'Test',
		fileName: 'test.pdf',
		lastModifiedDate: '2022-01-01T16:30:12.345Z',
		size: 12345
	}
};

export const customerServiceOrderDownloadFileModelMock: Dto<CustomerServiceOrderDownloadFileModel> = {
	id: '1',
	orderId: '1',
	file: downloadFile,
	createdAt: '2022-01-01T16:30:12.345Z',
	fileType: CustomerServiceOrderDownloadFileType.ACCOUNTING
};

export const customerServiceOrderDownloadFileModelCollectionMock = (): Array<Dto<CustomerServiceOrderDownloadFileModel>> => {
	const collectionMock = [];
	for (let i = 0; i < 10; i++) {
		collectionMock.push({
			...customerServiceOrderDownloadFileModelMock,
			id: String(i + 1),
			fileType: (i < 5) ? CustomerServiceOrderDownloadFileType.ACCOUNTING : CustomerServiceOrderDownloadFileType.NON_ACCOUNTING
		});
	}
	return collectionMock;
};
