import React from 'react';

import {
	CustomerServiceOrderInformationQuotationInformationModel
} from '@abb-emobility/platform/domain-model';
import { calculateMajorUnitFromMinorUnit, getCurrencyByCode, getCurrencySymbolByCurrency } from '@abb-emobility/shared/currency';
import { OrderedInstallationPartnerDeltaInstallationPackageModel } from '@abb-emobility/shared/domain-model';
import { AppError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	CollectionItemContentSection,
	SectionHeader,
	Separator,
	Specification,
	SpecificationItem
} from '@abb-emobility/shared/ui-primitive';

import './Quotation.scss';

type QuotationProps = {
	quotationInformation: CustomerServiceOrderInformationQuotationInformationModel,
	includePricing?: boolean
};

// TODO: Improve and check if can be used in OrderInformation Quotation
export function Quotation(props: QuotationProps) {

	const { quotationInformation, includePricing = true } = props;

	const l10n = useL10n();

	const currency = getCurrencyByCode(quotationInformation.currencyCode);
	if (currency === null) {
		throw new AppError('Currency with code ' + quotationInformation.currencyCode + ' not found');
	}

	const renderConditions = () => {
		if (quotationInformation.conditions) {
			return (
				<CollectionItemContentSection>
					<SectionHeader heading={l10n.translate('platformCustomerServiceApp.orderDetail.informationList.quotation.conditions')} />
					<p className="quotation__conditions">{quotationInformation.conditions}</p>
				</CollectionItemContentSection>
			);
		}
		return;
	};

	const renderPurchaseNetPrice = (item: OrderedInstallationPartnerDeltaInstallationPackageModel): React.ReactNode => {
		if (!includePricing) {
			return null;
		}

		const currency = getCurrencyByCode(item.deltaPackage.currencyCode);
		const totalPurchaseNetPrice = item.amount * item.deltaPackage.purchaseNetPrice;

		return (
			<span className="quotation-collection-item__header__item__price">
				<span className="quotation-collection-item__header__item__price--default">
					{l10n.translate('platformCustomerServiceApp.task.quotation.deltaPackages.purchaseNetPrice')}
				</span>
				<span className="quotation-collection-item__header__item__price--default">
					{l10n.formatNumber(item.amount, 0)}
					&nbsp;&times;&nbsp;
					{getCurrencySymbolByCurrency(currency)}
					&nbsp;
					{l10n.formatNumber(calculateMajorUnitFromMinorUnit(item.deltaPackage.currencyCode, item.deltaPackage.purchaseNetPrice), currency?.fraction ?? 0)}
				</span>
				/
				<span className="quotation-collection-item__header__item__price--bold">
					{getCurrencySymbolByCurrency(currency)}
					&nbsp;
					{l10n.formatNumber(calculateMajorUnitFromMinorUnit(item.deltaPackage.currencyCode, totalPurchaseNetPrice), currency?.fraction ?? 0)}
				</span>
			</span>
		);
	};

	const renderSalesGrossPrice = (item: OrderedInstallationPartnerDeltaInstallationPackageModel): React.ReactNode => {
		if (!includePricing || !item.deltaPackage.salesGrossPrice) {
			return null;
		}

		const currency = getCurrencyByCode(item.deltaPackage.currencyCode);
		const totalSalesGrossPrice = item.amount * item.deltaPackage.salesGrossPrice;

		return (
			<span className="quotation-collection-item__header__item__price">
				<span className="quotation-collection-item__header__item__price--default">
					{l10n.translate('platformCustomerServiceApp.task.quotation.deltaPackages.salesGrossPrice')}
				</span>
				<span className="quotation-collection-item__header__item__price--default">
					{l10n.formatNumber(item.amount, 0)}
					&nbsp;&times;&nbsp;
					{getCurrencySymbolByCurrency(currency)}
					&nbsp;
					{l10n.formatNumber(calculateMajorUnitFromMinorUnit(item.deltaPackage.currencyCode, item.deltaPackage.salesGrossPrice), currency?.fraction ?? 0)}
				</span>
				/
				<span className="quotation-collection-item__header__item__price--bold">
					{getCurrencySymbolByCurrency(currency)}
					&nbsp;
					{l10n.formatNumber(calculateMajorUnitFromMinorUnit(item.deltaPackage.currencyCode, totalSalesGrossPrice), currency?.fraction ?? 0)}
				</span>
			</span>
		);
	};

	const renderTotalPrice = () => {
		if (!includePricing) {
			return null;
		}
		return (
			<footer className="quotation-collection__footer">
				<section className="quotation-collection__footer__wrapper">
					<div className="quotation-collection__footer__wrapper__price">
						<span className="quotation-collection__footer__wrapper__price__key">
							{l10n.translate('platformCustomerServiceApp.task.quotation.deltaPackages.totalPurchaseNetPrice')}
						</span>
						<span className="quotation-collection__footer__wrapper__price__value">
							{l10n.formatNumber(calculateMajorUnitFromMinorUnit(quotationInformation.currencyCode, quotationInformation.totalPurchaseNetPrice), currency?.fraction ?? 0)} {getCurrencySymbolByCurrency(currency)}
						</span>
					</div>
					<div className="quotation-collection__footer__wrapper__price">
						<span className="quotation-collection__footer__wrapper__price__key">
							{l10n.translate('platformCustomerServiceApp.task.quotation.deltaPackages.totalSalesGrossPrice', new Map([['vatRate', l10n.formatNumber(quotationInformation.vatRate, 1)]]))}
						</span>
						<span className="quotation-collection__footer__wrapper__price__value">
							{l10n.formatNumber(calculateMajorUnitFromMinorUnit(quotationInformation.currencyCode, quotationInformation.totalSalesGrossPrice), currency?.fraction ?? 0)} {getCurrencySymbolByCurrency(currency)}
						</span>
					</div>
				</section>
			</footer>
		);
	};

	const renderPackages = () => {

		return quotationInformation.deltaPackages.map((item) => {
			const renderAttributes = () => {
				return item.deltaPackage.includedGoodsInternal?.map((good: string, index: number) => {
					return <SpecificationItem key={index} attribute={good} />;
				});
			};

			return (
				<article className="quotation-collection-item" key={item.deltaPackage.id}>
					<header className="quotation-collection-item__header">
						<div className="quotation-collection-item__header__item">
							<h1 className="quotation-collection-item__header__item__heading">
								{item.deltaPackage.name}
							</h1>
							<p className="quotation-collection-item__header__item__description">
								{item.deltaPackage.description}
							</p>
						</div>
					</header>

					<Separator />

					<main className="quotation-collection-item__main">
						<Specification>
							{renderAttributes()}
						</Specification>
					</main>

					<Separator />

					<main className="quotation-collection-item__main">
						<div className="quotation-collection-item__header__item__prices">
							{renderPurchaseNetPrice(item)}
							{renderSalesGrossPrice(item)}
						</div>
					</main>
				</article>
			);
		});
	};

	return (
		<>
			<CollectionItemContentSection>
				<SectionHeader heading={l10n.translate('platformCustomerServiceApp.orderDetail.informationList.quotation.heading')} />

				<section className="quotation-collection">
					<main className="quotation-collection__main">
						{renderPackages()}
					</main>
					{renderTotalPrice()}

				</section>
			</CollectionItemContentSection>

			{renderConditions()}
		</>
	);

}
