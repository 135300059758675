import {
	CustomerServiceMarketplaceOrderCollectionItemModel,
	CustomerServiceMarketplaceOrderCollectionItemModelConverter
} from '@abb-emobility/platform/domain-model';
import { AbstractCrudApiClient } from '@abb-emobility/shared/api-integration-foundation';

export class CustomerServiceMarketplaceOrderCollectionApiClient extends AbstractCrudApiClient<CustomerServiceMarketplaceOrderCollectionItemModel> {
	protected collectionControllerUri = 'marketplace-orders/';
	protected paginationControllerUri = 'marketplace-orders/';
	protected entityControllerUri = 'marketplace-order/{id}/';
	protected entitySearchControllerUri = 'marketplace-order/';
	protected modelConverter = new CustomerServiceMarketplaceOrderCollectionItemModelConverter();
}
