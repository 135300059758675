import {
	CustomerServiceOrderPaymentBankAccountModel,
	CustomerServiceOrderPaymentCreditCardModel
} from './CustomerServiceOrderPaymentModel';

export const isCustomerServiceOrderPaymentCreditCard = (paymentDetails?: unknown): paymentDetails is CustomerServiceOrderPaymentCreditCardModel => {
	if ((paymentDetails ?? null) === null) {
		return false;
	}
	return (paymentDetails as CustomerServiceOrderPaymentCreditCardModel).cardNumber !== undefined
		&& (paymentDetails as CustomerServiceOrderPaymentCreditCardModel).cardOwner !== undefined
		&& (paymentDetails as CustomerServiceOrderPaymentCreditCardModel).expiryDate !== undefined;
};

export const isCustomerServiceOrderPaymentBankAccount = (paymentDetails?: unknown): paymentDetails is CustomerServiceOrderPaymentBankAccountModel => {
	if ((paymentDetails ?? null) === null) {
		return false;
	}
	return (paymentDetails as CustomerServiceOrderPaymentBankAccountModel).accountOwner !== undefined
		&& (paymentDetails as CustomerServiceOrderPaymentBankAccountModel).iban !== undefined;
};
