import { CustomerServiceOrderEventCollectionModel } from '@abb-emobility/platform/domain-model';
import { createDateFromTimestamp } from '@abb-emobility/shared/domain-model-foundation';
import { isSameMonth } from '@abb-emobility/shared/util';

import { GroupedEvents } from './OrderEventTimeline.types';

export const preprocessEvents = (events: ReadonlyArray<CustomerServiceOrderEventCollectionModel>) => {
	return [...events].sort((left, right) => {
		const leftDate = createDateFromTimestamp(left.eventDate).getTime();
		const rightDate = createDateFromTimestamp(right.eventDate).getTime();
		if (leftDate === rightDate) {
			return 0;
		}
		return leftDate < rightDate ? 1 : -1;
	});
};

export const groupByMonth = (events: ReadonlyArray<CustomerServiceOrderEventCollectionModel>) => {
	const listGroupedByMonth: Array<GroupedEvents> = [];
	let accu: Array<CustomerServiceOrderEventCollectionModel> = [];

	events.forEach((event, idx, actions) => {
		accu.push(event);

		if ((idx === actions.length - 1) || !isSameMonth(createDateFromTimestamp(event['eventDate']), createDateFromTimestamp(events[idx + 1]['eventDate']))) {
			listGroupedByMonth.push({
				month: event['eventDate'],
				events: accu
			});
			accu = [];
		}
	});
	return listGroupedByMonth;
};
