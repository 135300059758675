import React from 'react';

import { useL10n } from '@abb-emobility/shared/localization-provider';

import './SearchbarResultsHeader.scss';

type SearchbarResultsHeaderProps = {
	caption: string
};

export const SearchbarResultsHeader = (props: SearchbarResultsHeaderProps) => {
	const { caption } = props;

	const l10n = useL10n();

	return (
		<header className="searchbar-results-header">
			<span className="searchbar-results-header__precaption">{l10n.translate('sharedUiPrimitive.search.foundIn')}</span>
			<h1 className="searchbar-results-header__caption">{caption}</h1>
		</header>
	);
};
