import { Children, ReactNode } from 'react';

import './ViewSubheader.scss';

export type ViewSubheaderProps = {
	children: ReactNode
};

export function ViewSubheader(props: ViewSubheaderProps) {
	const { children } = props;

	const renderChildrenWithSeperator = (): ReactNode => {
		return Children.map(children, (child, index) => {
			if ((index >= 0 && Children.toArray(children).length > 0) && index !== Children.toArray(children).length - 1) {
				return (
					<>
						{child}
						<span className="view-subheader__seperator" />
					</>
				);
			}
			return child;
		});
	};

	return (
		<section className="view-subheader">
			{renderChildrenWithSeperator()}
		</section>
	);
}
