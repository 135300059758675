import React from 'react';

import { useL10n } from '@abb-emobility/shared/localization-provider';
import { CollectionHead, CollectionHeadItem } from '@abb-emobility/shared/ui-primitive';

export function CustomerCollectionHeader() {
	const l10n = useL10n();

	return (
		<CollectionHead>
			<CollectionHeadItem label={l10n.translate('platformCustomerServiceApp.customerCollection.tableHead.customer')} />
			<CollectionHeadItem label={l10n.translate('platformCustomerServiceApp.customerCollection.tableHead.firstOrder')} />
			<CollectionHeadItem label={l10n.translate('platformCustomerServiceApp.customerCollection.tableHead.lastOrder')} />
			{/* last item is used as an alignment util for the list item action */}
			<CollectionHeadItem />
		</CollectionHead>
	);
}
