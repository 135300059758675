import { AnyCustomerServiceOrderEventEntityModel } from '@abb-emobility/platform/domain-model';
import { CrudApiClient } from '@abb-emobility/shared/api-integration-foundation';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import { Nullable } from '@abb-emobility/shared/util';

import { CustomerServiceOrderEventEntityApiClient } from './CustomerServiceOrderEventEntityApiClient';
import { CustomerServiceOrderEventEntityApiClientMock } from './CustomerServiceOrderEventEntityApiClientMock';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CustomerServiceOrderEventEntityApiClientInterface extends CrudApiClient<AnyCustomerServiceOrderEventEntityModel> {
}

export class CustomerServiceOrderEventEntityApiClientFactory {

	private static mockConfigured = false;

	public static create(baseUrl: string, jsonWebToken: Nullable<JsonWebToken> = null): CustomerServiceOrderEventEntityApiClientInterface {
		if (process.env.NX_PLATFORM_CS_ORDER_EVENT_API_MOCKED === 'true') {
			const apiClientMock = new CustomerServiceOrderEventEntityApiClientMock().init(baseUrl, jsonWebToken);
			if (!this.mockConfigured) {
				apiClientMock.configureFetchMock();
				this.mockConfigured = true;
			}
			return apiClientMock;
		}
		return new CustomerServiceOrderEventEntityApiClient().init(baseUrl, jsonWebToken);
	}

}
