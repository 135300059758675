import React, { ReactNode } from 'react';

import {
	CustomerServiceOrderDownloadFileCollectionDataInterceptEmpty,
	CustomerServiceOrderPaymentCollectionDataInterceptEmpty,
	useCustomerServiceOrderDownloadFileCollectionData,
	useCustomerServiceOrderPaymentCollectionData
} from '@abb-emobility/platform/data-provider';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	DownloadListItem,
	NotificationBig,
	NotificationBigStatus
} from '@abb-emobility/shared/ui-primitive';

import { Payments } from '../payments/Payments';

import './OrderDetailAccounting.scss';

export function OrderDetailAccounting() {

	const orderDownloadFilesData = useCustomerServiceOrderDownloadFileCollectionData();
	const documents = orderDownloadFilesData.query();

	const orderPaymentData = useCustomerServiceOrderPaymentCollectionData();
	const payments = orderPaymentData.query();

	const l10n = useL10n();

	const renderAccountingDocuments = () => {
		return documents.map((document) => {
			return (
				<DownloadListItem file={document.file} key={document.id} />
			);
		});
	};

	const renderEmptyPaymentState = (): ReactNode => {
		return (
			<NotificationBig
				status={NotificationBigStatus.EMPTY2}
				heading={l10n.translate('platformCustomerServiceApp.orderDetail.accounting.emptyPaymentState.heading')}
				message={l10n.translate('platformCustomerServiceApp.orderDetail.accounting.emptyPaymentState.message')}
			/>
		);
	};

	const renderEmptyDownloadFilesState = (): ReactNode => {
		return (
			<NotificationBig
				status={NotificationBigStatus.EMPTY2}
				heading={l10n.translate('platformCustomerServiceApp.orderDetail.accounting.emptyDownloadFilesState.heading')}
				message={l10n.translate('platformCustomerServiceApp.orderDetail.accounting.emptyDownloadFilesState.message')}
			/>
		);
	};

	return (
		<main className="order-detail-accounting">
			<section className="order-detail-accounting__files">
				<CustomerServiceOrderDownloadFileCollectionDataInterceptEmpty emptyStateComponent={renderEmptyDownloadFilesState}>
					{renderAccountingDocuments()}
				</CustomerServiceOrderDownloadFileCollectionDataInterceptEmpty>
			</section>

			<section className="order-detail-accounting__content">
				<CustomerServiceOrderPaymentCollectionDataInterceptEmpty emptyStateComponent={renderEmptyPaymentState}>
					<Payments payments={[...payments]} />
				</CustomerServiceOrderPaymentCollectionDataInterceptEmpty>
			</section>
		</main>
	);
}
