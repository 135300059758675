import { CustomerServiceCustomerModel } from '@abb-emobility/platform/domain-model';
import { CrudApiClient } from '@abb-emobility/shared/api-integration-foundation';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import { Nullable } from '@abb-emobility/shared/util';

import { CustomerServiceCustomerApiClient } from './CustomerServiceCustomerApiClient';
import { CustomerServiceCustomerApiClientMock } from './CustomerServiceCustomerApiClientMock';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CustomerServiceCustomerApiClientInterface extends CrudApiClient<CustomerServiceCustomerModel> {
}

export class CustomerServiceCustomerApiClientFactory {

	private static mockConfigured = false;

	public static create(baseUrl: string, jsonWebToken: Nullable<JsonWebToken> = null): CustomerServiceCustomerApiClientInterface {
		if (process.env.NX_PLATFORM_CS_CUSTOMER_API_MOCKED === 'true') {
			const apiClientMock = new CustomerServiceCustomerApiClientMock().init(baseUrl, jsonWebToken);
			if (!this.mockConfigured) {
				apiClientMock.configureFetchMock();
				this.mockConfigured = true;
			}
			return apiClientMock;
		}
		return new CustomerServiceCustomerApiClient().init(baseUrl, jsonWebToken);
	}

}
