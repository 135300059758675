import { useEffect, useRef, useState } from 'react';

import { CustomerServiceOrderApiClientFactory } from '@abb-emobility/platform/api-integration';
import { CustomerServiceOrderModel } from '@abb-emobility/platform/domain-model';
import { FilterCriteria } from '@abb-emobility/shared/api-integration-foundation';
import { createAccessTokenLoader, useAuth } from '@abb-emobility/shared/auth-provider';
import { useEnv } from '@abb-emobility/shared/environment';
import { AppError } from '@abb-emobility/shared/error';
import { Nullable, Optional } from '@abb-emobility/shared/util';

export const useOrderCountPolling = (orderCountInterval?: number, orderfilter?: FilterCriteria<CustomerServiceOrderModel>) => {

	const DEFAULT_ORDER_COUNT_INTERVAL = 60000;

	const env = useEnv();
	const auth = useAuth();

	const orderCountIntervalRef = useRef<Nullable<number>>(null);
	const [orderCount, setOrderCount] = useState<Nullable<number>>(null);

	const stopPolling = () => {
		if (orderCountIntervalRef.current !== null) {
			window.clearInterval(orderCountIntervalRef.current);
		}
		orderCountIntervalRef.current = null;
	};

	const startPolling = () => {
		if (orderCountIntervalRef.current !== null) {
			return;
		}
		orderCountIntervalRef.current = window.setInterval(updateOrderCount, orderCountInterval ?? DEFAULT_ORDER_COUNT_INTERVAL);
	};

	const updateOrderCount = async (): Promise<void> => {
		const apiBaseUrl = new Optional(process.env['NX_CUSTOMER_SERVICE_API_BASE_URL']).getOrThrow(
			new AppError('Could read customerServiceApiBaseUrl from env')
		);
		try {
			const accessTokenLoader = createAccessTokenLoader(auth, env);
			const response = await CustomerServiceOrderApiClientFactory
				.create(apiBaseUrl, await accessTokenLoader())
				.headCollection(orderfilter);
			setOrderCount(response.totalItems ?? null);
		} catch (e) {
			stopPolling();
		}
	};

	useEffect(() => {
		void updateOrderCount();
		startPolling();
		return stopPolling;
	}, []);

	return orderCount;
};
