import { ModelConverter } from '@abb-emobility/shared/domain-model-foundation';

import { CustomerServiceCommentType } from '../../AnyCustomerServiceCommentModel';
import {
	CustomerServiceAddNoteInvocationPayloadFoundationModel
} from '../../foundation/CustomerServiceAddNoteInvocationPayloadFoundationModel';

export type CustomerServiceAddOrderNoteInvocationPayloadModel = CustomerServiceAddNoteInvocationPayloadFoundationModel & {
	commentType: CustomerServiceCommentType.ORDER,
};

export class CustomerServiceAddOrderNoteInvocationPayloadModelConverter extends ModelConverter<CustomerServiceAddOrderNoteInvocationPayloadModel> {
}
