import { ModelConverter } from '@abb-emobility/shared/domain-model-foundation';

import { CustomerServiceCommentType } from '../../AnyCustomerServiceCommentModel';
import {
	CustomerServiceAddNoteInvocationPayloadFoundationModel
} from '../../foundation/CustomerServiceAddNoteInvocationPayloadFoundationModel';

export type CustomerServiceAddCustomerNoteInvocationPayloadModel = CustomerServiceAddNoteInvocationPayloadFoundationModel & {
	commentType: CustomerServiceCommentType.CUSTOMER,
};

export class CustomerServiceAddCustomerNoteInvocationPayloadModelConverter extends ModelConverter<CustomerServiceAddCustomerNoteInvocationPayloadModel> {
}
