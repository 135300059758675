import { useEffect, useRef, useState } from 'react';

import { FilterCriteria } from '@abb-emobility/shared/api-integration-foundation';
import { createAccessTokenLoader, useAuth } from '@abb-emobility/shared/auth-provider';
import { useEnv } from '@abb-emobility/shared/environment';
import { AppError } from '@abb-emobility/shared/error';
import { Nullable, Optional } from '@abb-emobility/shared/util';
import { AnyTaskCollectionApiClientFactory } from '@abb-emobility/usertask/api-integration';
import { AnyTaskCollectionItemModel } from '@abb-emobility/usertask/domain-model';

export const useTaskCountPolling = (taskCountInterval?: number, taskFilter?: FilterCriteria<AnyTaskCollectionItemModel>) => {

	const DEFAULT_TASK_COUNT_INTERVAL = 60000;

	const env = useEnv();
	const auth = useAuth();

	const taskCountIntervalRef = useRef<Nullable<number>>(null);
	const [taskCount, setTaskCount] = useState<Nullable<number>>(null);

	const stopPolling = () => {
		if (taskCountIntervalRef.current !== null) {
			window.clearInterval(taskCountIntervalRef.current);
		}
		taskCountIntervalRef.current = null;
	};

	const startPolling = () => {
		if (taskCountIntervalRef.current !== null) {
			return;
		}
		taskCountIntervalRef.current = window.setInterval(updateTaskCount, taskCountInterval ?? DEFAULT_TASK_COUNT_INTERVAL);
	};

	const updateTaskCount = async (): Promise<void> => {
		const apiBaseUrl = new Optional(process.env['NX_USER_TASK_MANAGEMENT_API_BASE_URL'])
			.getOrThrow(new AppError('Could read userTaskManagementApiBaseUrl from env'));
		try {
			const accessTokenLoader = createAccessTokenLoader(auth, env);
			const response = await AnyTaskCollectionApiClientFactory
				.create(apiBaseUrl, await accessTokenLoader())
				.headCollection(taskFilter);
			setTaskCount(response.totalItems ?? null);
		} catch (e) {
			stopPolling();
		}
	};

	useEffect(() => {
		void updateTaskCount();
		startPolling();
		return stopPolling;
	}, []);

	return taskCount;
};
