import React, { ReactNode } from 'react';

import {
	CustomerServiceOrderShipmentCollectionDataInterceptEmpty,
	useCustomerServiceOrderShipmentCollectionData
} from '@abb-emobility/platform/data-provider';
import { AnyCustomerServiceOrderShipmentModel, ShipmentType } from '@abb-emobility/platform/domain-model';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	Card,
	NotificationBig,
	NotificationBigStatus,
	SegmentHeader,
	TabularInformationItem,
	TabularInformationItemType,
	TabularInformationRow
} from '@abb-emobility/shared/ui-primitive';

import { OrderDetailDeliveryReturn } from './OrderDetailDeliveryReturn';
import { OrderDetailDeliveryShipment } from './OrderDetailDeliveryShipment';

import './OrderDetailDelivery.scss';

export function OrderDetailDelivery() {

	const l10n = useL10n();
	const shipmentCollectionData = useCustomerServiceOrderShipmentCollectionData();
	const shipmentCollection = shipmentCollectionData.query();

	const deliveryAddressLabel = l10n.translate('platformCustomerServiceApp.orderDetail.delivery.address.heading');

	const renderEmptyState = (): ReactNode => {
		return (
			<NotificationBig
				status={NotificationBigStatus.EMPTY2}
				heading={l10n.translate('platformCustomerServiceApp.orderDetail.delivery.emptyState.heading')}
				message={l10n.translate('platformCustomerServiceApp.orderDetail.delivery.emptyState.message')}
			/>
		);
	};

	const renderAddressSection = (): ReactNode => {
		if (shipmentCollection.length === 0) {
			return null;
		}
		const shippingModel = shipmentCollection[0];
		if (shippingModel.deliveryAddress) {
			return (
				<Card>
					<SegmentHeader caption={deliveryAddressLabel} />
					<TabularInformationRow>
						<TabularInformationItem label={l10n.translate('platformCustomerServiceApp.orderDetail.delivery.address.name')} variant={TabularInformationItemType.GREY} />
						<TabularInformationItem label={shippingModel.receiverName} />
					</TabularInformationRow>

					<TabularInformationRow>
						<TabularInformationItem label={l10n.translate('platformCustomerServiceApp.orderDetail.delivery.address.street')} variant={TabularInformationItemType.GREY} />
						<TabularInformationItem label={shippingModel.deliveryAddress.street} />
					</TabularInformationRow>

					<TabularInformationRow>
						<TabularInformationItem label={l10n.translate('platformCustomerServiceApp.orderDetail.delivery.address.location')} variant={TabularInformationItemType.GREY} />
						<TabularInformationItem label={`${shippingModel.deliveryAddress.zip} ${shippingModel.deliveryAddress.city}`} />
					</TabularInformationRow>
				</Card>
			);
		}

		return;
	};

	const renderDetailSection = (shippingModel: AnyCustomerServiceOrderShipmentModel): ReactNode => {
		switch (shippingModel.type) {
			case ShipmentType.SHIPMENT : {
				return (
					<OrderDetailDeliveryShipment
						key={shippingModel.id}
						header={l10n.translate('platformCustomerServiceApp.orderDetail.delivery.details.heading')}
						shippingModel={shippingModel}
					/>
				);
			}
			case ShipmentType.RETURN: {
				return (
					<OrderDetailDeliveryReturn shippingModel={shippingModel} key={shippingModel.id} />
				);
			}
			case ShipmentType.REPLACEMENT: {
				return (
					<OrderDetailDeliveryShipment
						key={shippingModel.id}
						header={l10n.translate('platformCustomerServiceApp.orderDetail.delivery.replacementDetails.heading')}
						shippingModel={shippingModel}
					/>
				);
			}
			default:
				return null;
		}
	};

	const renderDetailsSections = (): ReactNode => {
		return shipmentCollection.map((shipmentModel) => {
			return renderDetailSection(shipmentModel);
		});
	};

	return (
		<main className="order-detail-delivery">
			<CustomerServiceOrderShipmentCollectionDataInterceptEmpty emptyStateComponent={renderEmptyState}>
				{renderAddressSection()}
				{renderDetailsSections()}
			</CustomerServiceOrderShipmentCollectionDataInterceptEmpty>
		</main>
	);
}
