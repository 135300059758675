import React from 'react';

import { CustomerServiceCustomerModel, CustomerServiceOrderCustomerModel } from '@abb-emobility/platform/domain-model';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	SegmentHeader,
	TabularInformationItem,
	TabularInformationItemType,
	TabularInformationRow
} from '@abb-emobility/shared/ui-primitive';

import { isCustomerServiceCustomerModel } from './CustomerInformation.types';

export type CustomerInformationContentProps = {
	header: string
	customer: CustomerServiceCustomerModel | CustomerServiceOrderCustomerModel
};

export function CustomerInformation(props: CustomerInformationContentProps) {
	const { header, customer } = props;

	const l10n = useL10n();

	const renderAdditionalInformation = () => {
		if (!isCustomerServiceCustomerModel(customer)) {
			return null;
		}

		return (
			<>
				<TabularInformationRow>
					<TabularInformationItem
						label={l10n.translate('platformCustomerServiceApp.customerInformation.tableItem.firstOrder')}
						variant={TabularInformationItemType.GREY}
					/>
					<TabularInformationItem label={l10n.formatTimestampDate(customer.firstOrder)} />
				</TabularInformationRow>

				<TabularInformationRow>
					<TabularInformationItem
						label={l10n.translate('platformCustomerServiceApp.customerInformation.tableItem.lastOrder')}
						variant={TabularInformationItemType.GREY}
					/>
					<TabularInformationItem label={l10n.formatTimestampDate(customer.lastOrder)} />
				</TabularInformationRow>
			</>
		);

	};

	const renderCompanyName = () => {
		if (customer.companyName === undefined) {
			return null;
		}
		return (
			<TabularInformationRow>
				<TabularInformationItem
					label={l10n.translate('platformCustomerServiceApp.customerInformation.tableItem.companyName')}
					variant={TabularInformationItemType.GREY}
				/>
				<TabularInformationItem label={customer.companyName} />
			</TabularInformationRow>
		);
	};

	return (
		<>
			<SegmentHeader caption={header} />

			{renderCompanyName()}

			<TabularInformationRow>
				<TabularInformationItem
					label={l10n.translate('platformCustomerServiceApp.customerInformation.tableItem.firstName')}
					variant={TabularInformationItemType.GREY}
				/>
				<TabularInformationItem label={customer.firstName} />
			</TabularInformationRow>

			<TabularInformationRow>
				<TabularInformationItem
					label={l10n.translate('platformCustomerServiceApp.customerInformation.tableItem.lastName')}
					variant={TabularInformationItemType.GREY}
				/>
				<TabularInformationItem label={customer.lastName} />
			</TabularInformationRow>

			<TabularInformationRow>
				<TabularInformationItem
					label={l10n.translate('platformCustomerServiceApp.customerInformation.tableItem.mail')}
					variant={TabularInformationItemType.GREY}
				/>
				<TabularInformationItem label={customer.mailAddress} />
			</TabularInformationRow>

			{renderAdditionalInformation()}
		</>
	);
}
