import { ReactNode, useState } from 'react';

import { Nullable } from '@abb-emobility/shared/util';

import { actionbarContentContext } from './ActionbarContent.context';
import { HypermediaAction } from '../action-modal-factory/ActionModalFactory.types';
import { ApplicationAction } from '../application-action-factory/ApplicationActionItemFactory.types';

export type TopbarProps = {
	children: ReactNode
};

export function ActionbarContent(props: TopbarProps) {

	const { children } = props;
	const [currentHypermediaActions, setCurrentHypermediaActions] = useState<Nullable<Array<HypermediaAction>>>(null);
	const [currentApplicationActions, setCurrentApplicationActions] = useState<Nullable<Array<ApplicationAction>>>(null);

	const providerValue = {
		getHypermediaActions: (): Array<HypermediaAction> => {
			if (currentHypermediaActions === null) {
				return [];
			}
			return currentHypermediaActions;
		},

		setHypermediaActions: (actions: Array<HypermediaAction>) => {
			setCurrentHypermediaActions(actions);
		},

		getApplicationActions: (): Array<ApplicationAction> => {
			if (currentApplicationActions === null) {
				return [];
			}
			return currentApplicationActions;
		},

		setApplicationActions: (actions: Array<ApplicationAction>) => {
			setCurrentApplicationActions(actions);
		}
	};

	return (
		<actionbarContentContext.Provider value={providerValue}>
			{children}
		</actionbarContentContext.Provider>
	);
}
