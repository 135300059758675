import { Nullable } from '@abb-emobility/shared/util';
import { TaskType } from '@abb-emobility/usertask/domain-model';

export enum TaskTypeResponsibility {
	CUSTOMER = 'CUSTOMER',
	INSTALLATION_PARTNER = 'INSTALLATION_PARTNER'
}

export const getTaskTypeResponsibility = (taskType: TaskType): Nullable<TaskTypeResponsibility> => {
	switch (taskType) {
		case TaskType.CUSTOMER_ANSWER_BASIC_QUESTIONS_ESCALATION:
		case TaskType.CUSTOMER_PROVIDE_PRE_CHECK_PREFERENCES_ESCALATION:
		case TaskType.CUSTOMER_HANDLE_UNFEASIBLE_INSTALLATION_ESCALATION:
		case TaskType.CUSTOMER_HANDLE_GRID_OPERATOR_REJECTION_ESCALATION:
		case TaskType.CUSTOMER_ACCEPT_QUOTATION_ESCALATION:
		case TaskType.CUSTOMER_PERFORM_PAYMENT_ESCALATION:
		case TaskType.CUSTOMER_ADDRESS_VALIDATION_ESCALATION:
		case TaskType.CUSTOMER_UNCOVERED_INSTALLATION_ADDRESS:
			return TaskTypeResponsibility.CUSTOMER;
		case TaskType.INSTALLATION_PARTNER_EVALUATE_BASIC_ANSWERS_ESCALATION:
		case TaskType.INSTALLATION_PARTNER_EXECUTE_REMOTE_PRE_CHECK_ESCALATION:
		case TaskType.INSTALLATION_PARTNER_REGISTER_AT_GRID_OPERATOR_ESCALATION:
		case TaskType.INSTALLATION_PARTNER_PROVIDE_GRID_OPERATOR_DECISION_ESCALATION:
		case TaskType.INSTALLATION_PARTNER_PROVIDE_QUOTATION_ESCALATION:
		case TaskType.INSTALLATION_PARTNER_ARRANGE_APPOINTMENT_ESCALATION:
		case TaskType.INSTALLATION_PARTNER_COMPLETE_INSTALLATION_ESCALATION:
			return TaskTypeResponsibility.INSTALLATION_PARTNER;
		default:
			console.warn('getTaskTypeResponsibility: Task Type responsibility undefined: ' + taskType);
			return null;
	}
};
