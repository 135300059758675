import { useEffect } from 'react';

import { Nullable } from '@abb-emobility/shared/util';

import { useSearchbarContent } from './SearchbarContent.context';

export const useSearchbarContentModifier = (orderId: Nullable<string>, customerId: Nullable<string>, dropdownAvailable?: boolean): void => {
	const searchbarContent = useSearchbarContent();

	useEffect(() => {
		searchbarContent.setOrderId(orderId);
		searchbarContent.setCustomerId(customerId);
		searchbarContent.setDropdownAvailable(dropdownAvailable ?? true);

	}, [orderId, customerId]);
};

export const useClearSearchbarContentModifier = (): void => {
	const searchbarContent = useSearchbarContent();

	useEffect(() => {
		searchbarContent.setOrderId(null);
		searchbarContent.setCustomerId(null);
		searchbarContent.setDropdownAvailable(true);
	}, []);
};
