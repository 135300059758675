import { CustomerServiceOrderPaymentModel, PaymentStatus } from '@abb-emobility/platform/domain-model';
import { Dto, createTimestampDtoFromDate } from '@abb-emobility/shared/domain-model-foundation';

export const customerServiceOrderCreditCardPaymentModelMock: Dto<CustomerServiceOrderPaymentModel> = {
	id: '1',
	orderId: '1',

	checkoutDate: createTimestampDtoFromDate(new Date('2022-02-01T16:30:12.345Z')),
	status: PaymentStatus.FAILED,
	errorMessage: 'Some descriptive error message coming from the PSP',

	details: {
		cardOwner: 'Max Mustermann',
		cardNumber: 'xxxxxxxxxxxx0869',
		expiryDate: '12/24'
	}
};

export const customerServiceOrderBankAccountPaymentModelMock: Dto<CustomerServiceOrderPaymentModel> = {
	id: '2',
	orderId: '1',

	checkoutDate: createTimestampDtoFromDate(new Date('2022-02-02T16:30:12.345Z')),
	settlementDate: createTimestampDtoFromDate(new Date('2022-02-02T16:34:10.345Z')),
	status: PaymentStatus.SUCCESS,

	details: {
		accountOwner: 'Max Mustermann',
		iban: 'DE02300209000106531065'
	}
};

export const customerServiceOrderPaymentModelCollectionMock = [
	customerServiceOrderCreditCardPaymentModelMock,
	customerServiceOrderBankAccountPaymentModelMock
];
