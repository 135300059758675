import { AnyCustomerServiceOrderInformationCollectionItemModel } from '@abb-emobility/platform/domain-model';
import { DataInterceptEmptyProps } from '@abb-emobility/shared/data-provider-foundation';

import { useCustomerServiceOrderInformationCollectionData } from './CustomerServiceOrderInformationCollectionDataConsumer';

export function CustomerServiceOrderInformationCollectionDataInterceptEmpty(props: DataInterceptEmptyProps<AnyCustomerServiceOrderInformationCollectionItemModel>) {
	const { children, emptyStateComponent, filter } = props;

	const collectionData = useCustomerServiceOrderInformationCollectionData();
	const hasResults = collectionData.hasResults(filter);

	if (!hasResults) {
		return (
			<>
				{emptyStateComponent()}
			</>
		);
	}

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{children}
		</>
	);
}
