import {
	CustomerServiceMarketplaceOrderCollectionItemModel
} from '@abb-emobility/platform/domain-model';
import { useCrudCollectionData } from '@abb-emobility/shared/data-provider-foundation';

import { customerServiceMarketplaceOrderCollectionData } from './CustomerServiceMarketplaceOrderCollectionData';

export const useCustomerServiceMarketplaceOrderCollectionData = () => {
	return useCrudCollectionData<CustomerServiceMarketplaceOrderCollectionItemModel>(customerServiceMarketplaceOrderCollectionData);
};
