import { CustomerServiceOrderCollectionItemModel } from '@abb-emobility/platform/domain-model';
import { DataInterceptEmptyProps } from '@abb-emobility/shared/data-provider-foundation';

import { useCustomerServiceCustomerOrderCollectionData } from './CustomerServiceCustomerOrderCollectionDataConsumer';

export function CustomerServiceCustomerOrderCollectionDataInterceptEmpty(props: DataInterceptEmptyProps<CustomerServiceOrderCollectionItemModel>) {
	const { children, emptyStateComponent, filter } = props;

	const taskCollectionData = useCustomerServiceCustomerOrderCollectionData();
	const hasResults = taskCollectionData.hasResults(filter);

	if (!hasResults) {
		return (
			<>
				{emptyStateComponent()}
			</>
		);
	}

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{children}
		</>
	);
}
