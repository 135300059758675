import { CustomerServiceOrderPaymentModel } from '@abb-emobility/platform/domain-model';
import { CrudApiClient } from '@abb-emobility/shared/api-integration-foundation';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import { Nullable } from '@abb-emobility/shared/util';

import { CustomerServiceMarketplaceOrderPaymentApiClient } from './CustomerServiceMarketplaceOrderPaymentApiClient';
import { CustomerServiceMarketplaceOrderPaymentApiClientMock } from './CustomerServiceMarketplaceOrderPaymentApiClientMock';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CustomerServiceMarketplaceOrderPaymentApiClientInterface extends CrudApiClient<CustomerServiceOrderPaymentModel> {
}

export class CustomerServiceMarketplaceOrderPaymentApiClientFactory {

	private static mockConfigured = false;

	public static create(baseUrl: string, jsonWebToken: Nullable<JsonWebToken> = null): CustomerServiceMarketplaceOrderPaymentApiClientInterface {
		if (process.env.NX_PLATFORM_CS_MARKETPLACE_ORDER_PAYMENT_API_MOCKED === 'true') {
			const apiClientMock = new CustomerServiceMarketplaceOrderPaymentApiClientMock().init(baseUrl, jsonWebToken);
			if (!this.mockConfigured) {
				apiClientMock.configureFetchMock();
				this.mockConfigured = true;
			}
			return apiClientMock;
		}
		return new CustomerServiceMarketplaceOrderPaymentApiClient().init(baseUrl, jsonWebToken);
	}

}
