import { AnyCustomerServiceSearchResultModel, AnyCustomerServiceSearchResultModelConverter } from '@abb-emobility/platform/domain-model';
import { AbstractCrudApiClient } from '@abb-emobility/shared/api-integration-foundation';

export class CustomerServiceSearchApiClient extends AbstractCrudApiClient<AnyCustomerServiceSearchResultModel> {
	protected collectionControllerUri = 'search/';
	protected paginationControllerUri = 'search/';
	protected entityControllerUri = 'search/{id}/';
	protected entitySearchControllerUri = 'search/';
	protected override paginationSize = 20;
	protected modelConverter = new AnyCustomerServiceSearchResultModelConverter();
}
