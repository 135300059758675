import { useEffect, useRef, useState } from 'react';

import { CustomerServiceMarketplaceOrderApiClientFactory } from '@abb-emobility/platform/api-integration';
import { CustomerServiceMarketplaceOrderModel } from '@abb-emobility/platform/domain-model';
import { FilterCriteria } from '@abb-emobility/shared/api-integration-foundation';
import { createAccessTokenLoader, useAuth } from '@abb-emobility/shared/auth-provider';
import { useEnv } from '@abb-emobility/shared/environment';
import { AppError } from '@abb-emobility/shared/error';
import { booleanFromString, Nullable, Optional } from '@abb-emobility/shared/util';

export const useMarketplaceOrderCountPolling = (orderCountInterval?: number, orderfilter?: FilterCriteria<CustomerServiceMarketplaceOrderModel>) => {

	const DEFAULT_ORDER_COUNT_INTERVAL = 60000;

	const env = useEnv();
	const auth = useAuth();

	const orderCountIntervalRef = useRef<Nullable<number>>(null);
	const [orderCount, setOrderCount] = useState<Nullable<number>>(null);
	const marketplaceOrdersEnabled = booleanFromString(
		new Optional(process.env['NX_FEATURE_TOGGLE_MARKETPLACE_ORDERS']).getOrDefault('false')
	);

	const stopPolling = () => {
		if (orderCountIntervalRef.current !== null) {
			window.clearInterval(orderCountIntervalRef.current);
		}
		orderCountIntervalRef.current = null;
	};

	const startPolling = () => {
		if (orderCountIntervalRef.current !== null) {
			return;
		}
		orderCountIntervalRef.current = window.setInterval(updateOrderCount, orderCountInterval ?? DEFAULT_ORDER_COUNT_INTERVAL);
	};

	const updateOrderCount = async (): Promise<void> => {
		const apiBaseUrl = new Optional(process.env['NX_CUSTOMER_SERVICE_API_BASE_URL']).getOrThrow(
			new AppError('Could read customerServiceApiBaseUrl from env')
		);
		try {
			const accessTokenLoader = createAccessTokenLoader(auth, env);
			const response = await CustomerServiceMarketplaceOrderApiClientFactory
				.create(apiBaseUrl, await accessTokenLoader())
				.headCollection(orderfilter);
			setOrderCount(response.totalItems ?? null);
		} catch (e) {
			stopPolling();
		}
	};

	useEffect(() => {
		if (!marketplaceOrdersEnabled) {
			return;
		}
		void updateOrderCount();
		startPolling();
		return stopPolling;
	}, []);

	if (!marketplaceOrdersEnabled) {
		return null;
	}

	return orderCount;
};
