import { ModelConverter, ModelConverterInterface } from '@abb-emobility/shared/domain-model-foundation';

import { CustomerServiceOrderLineItemModel, CustomerServiceOrderLineItemModelConverter } from '../../CustomerServiceOrderLineItemModel';
import { PricingModel, PricingModelConverter } from '../../common/PricingModel';

export type CustomerServiceCancelInstallationPayloadModel = {
	orderLineItems: Array<CustomerServiceOrderLineItemModel>,
	totalPrice: PricingModel
};

export class CustomerServiceCancelInstallationPayloadModelConverter extends ModelConverter<CustomerServiceCancelInstallationPayloadModel> {

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	override getFieldConverterMapByModel(): Map<keyof CustomerServiceCancelInstallationPayloadModel, ModelConverterInterface<any>> {
		return new Map<
			keyof CustomerServiceCancelInstallationPayloadModel,
			ModelConverterInterface<CustomerServiceOrderLineItemModel> | ModelConverterInterface<PricingModel>
		>([
			['orderLineItems', new CustomerServiceOrderLineItemModelConverter()],
			['totalPrice', new PricingModelConverter()]
		]);
	}
}
