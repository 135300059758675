import React from 'react';

import { useL10n } from '@abb-emobility/shared/localization-provider';
import { CollectionHead, CollectionHeadItem, FirstChildWidth } from '@abb-emobility/shared/ui-primitive';

import './OrderCollectionHeader.scss';

export function OrderCollectionHeader() {
	const l10n = useL10n();

	const width = FirstChildWidth.FIRST_CHILD_WIDTH_2;

	return (
		<CollectionHead>
			<CollectionHeadItem
				label={l10n.translate('platformCustomerServiceApp.orderCollection.tableHead.order')}
				firstChildWidth={width}
			/>
			<CollectionHeadItem
				label={l10n.translate('platformCustomerServiceApp.orderCollection.tableHead.status')}
				firstChildWidth={width}
			/>
			<CollectionHeadItem
				label={l10n.translate('platformCustomerServiceApp.orderCollection.tableHead.language')}
				firstChildWidth={width}
			/>
			<CollectionHeadItem
				label={l10n.translate('platformCustomerServiceApp.orderCollection.tableHead.plannedDeliveryDate')}
				firstChildWidth={width}
			/>
			<CollectionHeadItem
				label={l10n.translate('platformCustomerServiceApp.orderCollection.tableHead.createdAt')}
				firstChildWidth={width}
			/>
			<CollectionHeadItem firstChildWidth={width} />
		</CollectionHead>
	);
}

