import {
	CustomerServiceMarketplaceOrderCollectionApiClientFactory,
	CustomerServiceMarketplaceOrderCollectionApiClientInterface
} from '@abb-emobility/platform/api-integration';
import { CustomerServiceMarketplaceOrderCollectionItemModel } from '@abb-emobility/platform/domain-model';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import {
	createCrudCreateThunk,
	createCrudCollectionSlice,
	createCrudDeleteThunk,
	createCrudFetchAllThunk,
	createCrudFetchNextThunk,
	createCrudFetchCollectionEntityThunk,
	createCrudMutateThunk,
	createCrudSelectCollection,
	createCrudSelectCollectionEntity,
	createCrudSelectCreatedEntity,
	CrudCollectionStore,
	CrudCollectionStoreAccessors,
	ThunkApiConfig,
	createCrudCountCollection,
	createCrudRefetchAllThunk,
	createCrudFetchAllSortedThunk,
	createCrudFetchAllFilteredThunk,
	createCrudRefetchCollectionEntityThunk,
	createCrudCollectionStoreSize,
	createCrudCollectionStoreEntryIds
} from '@abb-emobility/shared/data-provider-foundation';
import { Nullable } from '@abb-emobility/shared/util';


// Basic definition
export const customerServiceMarketplaceOrderCollectionStoreName = 'customer-service-marketplace-order-collection-crud';
export type CustomerServiceMarketplaceOrderCollectionStore = { [customerServiceMarketplaceOrderCollectionStoreName]: CrudCollectionStore<CustomerServiceMarketplaceOrderCollectionItemModel> };

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the
const createApiClient = (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) => {
	return CustomerServiceMarketplaceOrderCollectionApiClientFactory.create(apiBaseUrl, jsonWebToken);
};
const fetchAllThunk = createCrudFetchAllThunk<CustomerServiceMarketplaceOrderCollectionItemModel, CustomerServiceMarketplaceOrderCollectionStore, CustomerServiceMarketplaceOrderCollectionApiClientInterface>(customerServiceMarketplaceOrderCollectionStoreName, createApiClient);
const refetchAllThunk = createCrudRefetchAllThunk<CustomerServiceMarketplaceOrderCollectionItemModel, CustomerServiceMarketplaceOrderCollectionStore, CustomerServiceMarketplaceOrderCollectionApiClientInterface>(customerServiceMarketplaceOrderCollectionStoreName, createApiClient);
const fetchAllSortedThunk = createCrudFetchAllSortedThunk<CustomerServiceMarketplaceOrderCollectionItemModel, CustomerServiceMarketplaceOrderCollectionStore, CustomerServiceMarketplaceOrderCollectionApiClientInterface>(customerServiceMarketplaceOrderCollectionStoreName, createApiClient);
const fetchAllFilteredThunk = createCrudFetchAllFilteredThunk<CustomerServiceMarketplaceOrderCollectionItemModel, CustomerServiceMarketplaceOrderCollectionStore, CustomerServiceMarketplaceOrderCollectionApiClientInterface>(customerServiceMarketplaceOrderCollectionStoreName, createApiClient);
const fetchNextThunk = createCrudFetchNextThunk<CustomerServiceMarketplaceOrderCollectionItemModel, CustomerServiceMarketplaceOrderCollectionStore, CustomerServiceMarketplaceOrderCollectionApiClientInterface>(customerServiceMarketplaceOrderCollectionStoreName, createApiClient);
const fetchEntityThunk = createCrudFetchCollectionEntityThunk<CustomerServiceMarketplaceOrderCollectionItemModel, CustomerServiceMarketplaceOrderCollectionStore, CustomerServiceMarketplaceOrderCollectionApiClientInterface>(customerServiceMarketplaceOrderCollectionStoreName, createApiClient);
const refetchEntityThunk = createCrudRefetchCollectionEntityThunk<CustomerServiceMarketplaceOrderCollectionItemModel, CustomerServiceMarketplaceOrderCollectionStore, CustomerServiceMarketplaceOrderCollectionApiClientInterface>(customerServiceMarketplaceOrderCollectionStoreName, createApiClient);
const mutateThunk = createCrudMutateThunk<CustomerServiceMarketplaceOrderCollectionItemModel, CustomerServiceMarketplaceOrderCollectionApiClientInterface>(customerServiceMarketplaceOrderCollectionStoreName, createApiClient);
const createThunk = createCrudCreateThunk<CustomerServiceMarketplaceOrderCollectionItemModel, CustomerServiceMarketplaceOrderCollectionApiClientInterface>(customerServiceMarketplaceOrderCollectionStoreName, createApiClient);
const deleteThunk = createCrudDeleteThunk<CustomerServiceMarketplaceOrderCollectionItemModel, CustomerServiceMarketplaceOrderCollectionApiClientInterface>(customerServiceMarketplaceOrderCollectionStoreName, createApiClient);

// Slice definition
export const customerServiceMarketplaceOrderCollectionSlice = createCrudCollectionSlice<CustomerServiceMarketplaceOrderCollectionItemModel, ThunkApiConfig>(
	customerServiceMarketplaceOrderCollectionStoreName,
	fetchAllThunk,
	refetchAllThunk,
	fetchAllSortedThunk,
	fetchAllFilteredThunk,
	fetchNextThunk,
	fetchEntityThunk,
	refetchEntityThunk,
	mutateThunk,
	createThunk,
	deleteThunk
);
const { resolveFetchStatus, resolveActionStatus, resetStore } = customerServiceMarketplaceOrderCollectionSlice.actions;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
const selectCollection = createCrudSelectCollection<CustomerServiceMarketplaceOrderCollectionItemModel, CustomerServiceMarketplaceOrderCollectionStore>(customerServiceMarketplaceOrderCollectionStoreName);
const countCollection = createCrudCountCollection<CustomerServiceMarketplaceOrderCollectionItemModel, CustomerServiceMarketplaceOrderCollectionStore>(customerServiceMarketplaceOrderCollectionStoreName);
const selectEntity = createCrudSelectCollectionEntity<CustomerServiceMarketplaceOrderCollectionItemModel, CustomerServiceMarketplaceOrderCollectionStore>(customerServiceMarketplaceOrderCollectionStoreName);
const selectCreated = createCrudSelectCreatedEntity<CustomerServiceMarketplaceOrderCollectionItemModel, CustomerServiceMarketplaceOrderCollectionStore>(customerServiceMarketplaceOrderCollectionStoreName);
const storeSize = createCrudCollectionStoreSize<CustomerServiceMarketplaceOrderCollectionItemModel, CustomerServiceMarketplaceOrderCollectionStore>(customerServiceMarketplaceOrderCollectionStoreName);
const storeEntryIds = createCrudCollectionStoreEntryIds<CustomerServiceMarketplaceOrderCollectionItemModel, CustomerServiceMarketplaceOrderCollectionStore>(customerServiceMarketplaceOrderCollectionStoreName);

// Export the store accessors to be used by the data provider value
export const customerServiceMarketplaceOrderCollectionStoreAccessors = {
	fetchAll: fetchAllThunk,
	refetchAll: refetchAllThunk,
	fetchAllSorted: fetchAllSortedThunk,
	fetchAllFiltered: fetchAllFilteredThunk,
	fetchNext: fetchNextThunk,
	fetchEntity: fetchEntityThunk,
	refetchEntity: refetchEntityThunk,
	create: createThunk,
	mutate: mutateThunk,
	delete: deleteThunk,
	resolveFetchStatus,
	resolveActionStatus,
	selectCollection,
	countCollection,
	selectEntity,
	selectCreated,
	resetStore,
	storeSize,
	storeEntryIds
} as CrudCollectionStoreAccessors<CustomerServiceMarketplaceOrderCollectionItemModel, CustomerServiceMarketplaceOrderCollectionStore>;
