import { AnyCustomerServiceCommentModel, AnyCustomerServiceCommentModelConverter } from '@abb-emobility/platform/domain-model';
import { AbstractCrudApiClient } from '@abb-emobility/shared/api-integration-foundation';

export class CustomerServiceOrderCommentApiClient extends AbstractCrudApiClient<AnyCustomerServiceCommentModel> {
	protected collectionControllerUri = 'order-comments/';
	protected paginationControllerUri = 'order-comments/';
	protected entityControllerUri = 'order-comment/{id}/';
	protected entitySearchControllerUri = 'order-comment/';
	protected modelConverter = new AnyCustomerServiceCommentModelConverter();
}
