import { GridOperatorModel, GridOperatorModelConverter } from '@abb-emobility/shared/domain-model';
import { ModelConverter, ModelConverterInterface } from '@abb-emobility/shared/domain-model-foundation';
import { ArrayOfDownloadFileFieldNames, DownloadFile, DownloadFileFieldNames } from '@abb-emobility/shared/domain-model-foundation';

export type CustomerServiceOrderInformationGridOperatorRegistrationInformationModel = {
	readonly gridOperator: GridOperatorModel,
	readonly files?: Array<DownloadFile>
};

export class CustomerServiceOrderInformationGridOperatorRegistrationInformationModelConverter extends ModelConverter<CustomerServiceOrderInformationGridOperatorRegistrationInformationModel> {
	override getDownloadFileFields(): Array<DownloadFileFieldNames<CustomerServiceOrderInformationGridOperatorRegistrationInformationModel> | ArrayOfDownloadFileFieldNames<CustomerServiceOrderInformationGridOperatorRegistrationInformationModel>> {
		return ['files'];
	}

	override getFieldConverterMapByModel(): Map<keyof CustomerServiceOrderInformationGridOperatorRegistrationInformationModel, ModelConverterInterface<GridOperatorModel>> {
		return new Map([
			['gridOperator', new GridOperatorModelConverter()]
		]);
	}

}
