import { CustomerServiceOrderInstallationAppointmentModel } from '@abb-emobility/platform/domain-model';
import { CrudApiClient } from '@abb-emobility/shared/api-integration-foundation';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import { Nullable } from '@abb-emobility/shared/util';

import { CustomerServiceOrderInstallationAppointmentApiClient } from './CustomerServiceOrderInstallationAppointmentApiClient';
import { CustomerServiceOrderInstallationAppointmentApiClientMock } from './CustomerServiceOrderInstallationAppointmentApiClientMock';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CustomerServiceOrderInstallationAppointmentApiClientInterface extends CrudApiClient<CustomerServiceOrderInstallationAppointmentModel> {
}

export class CustomerServiceOrderInstallationAppointmentApiClientFactory {

	private static mockConfigured = false;

	public static create(baseUrl: string, jsonWebToken: Nullable<JsonWebToken> = null): CustomerServiceOrderInstallationAppointmentApiClientInterface {
		if (process.env.NX_PLATFORM_CS_INSTALLATION_APPOINTMENT_API_MOCKED === 'true') {
			const apiClientMock = new CustomerServiceOrderInstallationAppointmentApiClientMock().init(baseUrl, jsonWebToken);
			if (!this.mockConfigured) {
				apiClientMock.configureFetchMock();
				this.mockConfigured = true;
			}
			return apiClientMock;
		}
		return new CustomerServiceOrderInstallationAppointmentApiClient().init(baseUrl, jsonWebToken);
	}

}
