import React, { ReactNode } from 'react';

import {
	CustomerServiceOrderInstallationAppointmentCollectionDataInterceptEmpty,
	useCustomerServiceOrderEntityData,
	useCustomerServiceOrderInstallationAppointmentCollectionData
} from '@abb-emobility/platform/data-provider';
import { CustomerServiceActionType } from '@abb-emobility/platform/domain-model';
import { HypermediaLinkFilter, HypermediaLinkSort } from '@abb-emobility/shared/data-provider-foundation';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	Card,
	NotificationBig,
	NotificationBigStatus,
	SegmentHeader
} from '@abb-emobility/shared/ui-primitive';

import { ActionItemFactory } from '../action-item-factory/ActionItemFactory';
import { useActionModalFactory } from '../action-modal-factory/ActionModalFactory.hooks';
import { createHypermediaActionFromHypermediaLink } from '../action-modal-factory/ActionModalFactory.types';
import { InstallationAppointments } from '../installation-appointments/InstallationAppointments';

import './OrderDetailInstallationAppointments.scss';

export function OrderDetailInstallationAppointments() {
	const l10n = useL10n();

	const appointmentCollectionData = useCustomerServiceOrderInstallationAppointmentCollectionData();
	const appointmentCollection = appointmentCollectionData.query();
	const orderEntityData = useCustomerServiceOrderEntityData();
	const actionModalFactory = useActionModalFactory();
	const hasAppointments = appointmentCollectionData.hasResults();

	const orderHypermdiaLinksFilter: HypermediaLinkFilter = (value) => {
		return value.rel === CustomerServiceActionType.CANCEL_ORDER_APPOINTMENTS;
	};
	const hypermediaLinkSort: HypermediaLinkSort = (left, right) => left.rel.localeCompare(right.rel);
	const orderHypermediaLinks = orderEntityData.queryHypermediaLinks(hypermediaLinkSort, orderHypermdiaLinksFilter);

	const renderActionItems = (): ReactNode => {
		if (!hasAppointments) {
			return;
		}
		return orderHypermediaLinks.map((hypermediaLink, idx) => {
			return (
				<ActionItemFactory
					key={idx}
					hypermediaLink={hypermediaLink}
					onClick={() => actionModalFactory.renderModal(createHypermediaActionFromHypermediaLink(hypermediaLink))} />
			);
		});
	};

	const renderEmptyState = (): ReactNode => {
		return (
			<NotificationBig
				status={NotificationBigStatus.NO_APPOINTMENT}
				heading={l10n.translate('platformCustomerServiceApp.orderDetail.installation.installationAppointments.emptyState.heading')}
				message={l10n.translate('platformCustomerServiceApp.orderDetail.installation.installationAppointments.emptyState.message')}
			/>
		);
	};

	return (
		<Card>
			<SegmentHeader caption={l10n.translate('platformCustomerServiceApp.orderDetail.installation.installationAppointments.heading')}>
				{renderActionItems()}
			</SegmentHeader>
			<section className="order-detail-installation-appointments">
				<CustomerServiceOrderInstallationAppointmentCollectionDataInterceptEmpty emptyStateComponent={renderEmptyState}>
					<InstallationAppointments installerAppointments={[...appointmentCollection]} />
				</CustomerServiceOrderInstallationAppointmentCollectionDataInterceptEmpty>
			</section>
		</Card>
	);
}
