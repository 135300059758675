import { CustomerServiceOrderCollectionItemModel } from '@abb-emobility/platform/domain-model';
import {
	FilterCriteria,
	FilterCriteriaEntry,
	FilterCriteriaRuleValue,
	MultiValueFilterComparator,
	SingleValueFilterComparator,
	ValueFilterCriteriaRule
} from '@abb-emobility/shared/api-integration-foundation';
import { FilterOptionGroup } from '@abb-emobility/shared/ui-primitive';
import { Nullable } from '@abb-emobility/shared/util';

export enum OrderFilterOption {
	STATUS_IMPORTED = 'IMPORTED',
	STATUS_VALIDATION_FAILED = 'VALIDATION_FAILED',
	STATUS_ON_SCHEDULE = 'ON_SCHEDULE',
	STATUS_DELAYED = 'DELAYED',
	STATUS_CANCELLED = 'CANCELLED',
	STATUS_DONE = 'DONE',
	LANGUAGE_CODE_DE = 'DE'
}

const statusFilterOptions = [
	OrderFilterOption.STATUS_IMPORTED,
	OrderFilterOption.STATUS_VALIDATION_FAILED,
	OrderFilterOption.STATUS_ON_SCHEDULE,
	OrderFilterOption.STATUS_DELAYED,
	OrderFilterOption.STATUS_CANCELLED,
	OrderFilterOption.STATUS_DONE
];

const languageFilterOptions = [
	OrderFilterOption.LANGUAGE_CODE_DE
];

const stateFilterGroup: FilterOptionGroup<OrderFilterOption, CustomerServiceOrderCollectionItemModel> = {
	property: 'state',
	options: statusFilterOptions,
	buildFilterCriteria: (selectedOptions: Array<FilterCriteriaRuleValue>): Nullable<FilterCriteriaEntry<CustomerServiceOrderCollectionItemModel>> => {
		selectedOptions = selectedOptions.filter((selectedOption) => {
			return statusFilterOptions.includes(selectedOption as OrderFilterOption);
		});
		if (selectedOptions.length === 0) {
			return null;
		}
		let criteriaId: string;
		let criteria: Array<ValueFilterCriteriaRule<CustomerServiceOrderCollectionItemModel>>;
		if (selectedOptions.length === 1) {
			const selectedOption = selectedOptions[0];
			criteriaId = selectedOption as string;
			criteria = [
				{
					property: 'state',
					comparator: SingleValueFilterComparator.EQUAL,
					value: selectedOption
				}
			];
		} else {
			criteriaId = selectedOptions.join(':');
			criteria = [
				{
					property: 'state',
					comparator: MultiValueFilterComparator.ONE_OF,
					value: selectedOptions
				}
			];
		}
		return {
			id: criteriaId,
			criteria
		};
	},
	buildFilterOptions: <Model extends CustomerServiceOrderCollectionItemModel>(filterCriteria: FilterCriteria<Model>): Array<OrderFilterOption> => {
		let filterOptions: Array<OrderFilterOption> = [];
		for (const filterCriterion of filterCriteria) {
			filterOptions = [...filterOptions, ...(filterCriterion.id.split(':') as Array<OrderFilterOption>)];
		}
		return filterOptions.filter((taskFilterOption) => {
			return statusFilterOptions.includes(taskFilterOption);
		});
	}
};

const languageCodeFilterGroup: FilterOptionGroup<OrderFilterOption, CustomerServiceOrderCollectionItemModel> = {
	property: 'languageCode',
	options: languageFilterOptions,
	buildFilterCriteria: (selectedOptions: Array<FilterCriteriaRuleValue>): Nullable<FilterCriteriaEntry<CustomerServiceOrderCollectionItemModel>> => {
		selectedOptions = selectedOptions.filter((selectedOption) => {
			return languageFilterOptions.includes(selectedOption as OrderFilterOption);
		});
		if (selectedOptions.length === 0) {
			return null;
		}
		let criteriaId: string;
		let criteria: Array<ValueFilterCriteriaRule<CustomerServiceOrderCollectionItemModel>>;
		if (selectedOptions.length === 1) {
			const selectedOption = selectedOptions[0];
			criteriaId = selectedOption as string;
			criteria = [
				{
					property: 'languageCode',
					comparator: SingleValueFilterComparator.EQUAL,
					value: selectedOption
				}
			];
		} else {
			criteriaId = selectedOptions.join(':');
			criteria = [
				{
					property: 'languageCode',
					comparator: MultiValueFilterComparator.ONE_OF,
					value: selectedOptions
				}
			];
		}
		return {
			id: criteriaId,
			criteria
		};
	},
	buildFilterOptions: <Model extends CustomerServiceOrderCollectionItemModel>(filterCriteria: FilterCriteria<Model>): Array<OrderFilterOption> => {
		let filterOptions: Array<OrderFilterOption> = [];
		for (const filterCriterion of filterCriteria) {
			filterOptions = [...filterOptions, ...(filterCriterion.id.split(':') as Array<OrderFilterOption>)];
		}
		return filterOptions.filter((filterOption) => {
			return languageFilterOptions.includes(filterOption);
		});
	}
};

export const orderFilterOptionGroups: Array<FilterOptionGroup<OrderFilterOption, CustomerServiceOrderCollectionItemModel>> = [
	stateFilterGroup,
	languageCodeFilterGroup
];
