import React, { useEffect } from 'react';

import { CommandStatus, useCommandData } from '@abb-emobility/shared/command-api';
import { VoidModelConverter } from '@abb-emobility/shared/domain-model-foundation';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	ActionModalFooter,
	ButtonGhost,
	ButtonPrimary,
	Hint,
	NotificationBig,
	NotificationBigStatus,
	SubmissionStatus,
	SuccessFeeback,
	SuccessImageIdentifier,
	useModalDialogue,
	useModalDialogueManager
} from '@abb-emobility/shared/ui-primitive';

import { HypermediaAction } from '../../ActionModalFactory.types';

export type ResendMailActionModalProps = {
	action: HypermediaAction
};

export function ResendMailActionModal(props: ResendMailActionModalProps) {

	const { action } = props;

	const l10n = useL10n();
	const dialogueManager = useModalDialogueManager();
	const modalDialogue = useModalDialogue();

	const commandData = useCommandData(
		action.hypermediaLink,
		new VoidModelConverter(),
		new VoidModelConverter(),
		false
	);

	const invokeCommandStatus = commandData.queryInvocationStatus();
	const invokeCommandError = commandData.queryInvocationError();

	const footerCancelLabel = l10n.translate('platformCustomerServiceApp.actionModal.resendMail.footer.cancel');
	const footerConfirmlabel = l10n.translate('platformCustomerServiceApp.actionModal.resendMail.footer.confirm');

	useEffect(() => {
		const handleSubmitAction = () => {
			commandData.invoke({});
		};

		switch (invokeCommandStatus) {
			case CommandStatus.IDLE:
				modalDialogue.setFooter(
					<ActionModalFooter>
						<ButtonGhost
							label={footerCancelLabel}
							onClick={handleDismissDialogue}
						/>
						<ButtonPrimary
							label={footerConfirmlabel}
							submissionStatus={SubmissionStatus.IDLE}
							onClick={handleSubmitAction}
							disabled={false}
						/>
					</ActionModalFooter>
				);
				break;
			case CommandStatus.PENDING:
				modalDialogue.setFooter(
					<ActionModalFooter>
						<ButtonGhost
							label={footerCancelLabel}
							onClick={handleDismissDialogue}
						/>
						<ButtonPrimary
							label={footerConfirmlabel}
							submissionStatus={SubmissionStatus.PENDING}
							onClick={handleSubmitAction}
							disabled={false}
						/>
					</ActionModalFooter>
				);
				break;
			case CommandStatus.SUCCESSFULL:
			case CommandStatus.FAILED:
				modalDialogue.unsetFooter();
				break;
		}

	}, [invokeCommandStatus]);

	const handleDismissDialogue = () => {
		dialogueManager.pop();
	};

	if (invokeCommandStatus === CommandStatus.SUCCESSFULL) {
		return (
			<SuccessFeeback
				heading={l10n.translate('platformCustomerServiceApp.actionModal.resendMail.content.successHeading')}
				image={SuccessImageIdentifier.IMAGE4}
			>
				<ButtonGhost
					label={l10n.translate('platformCustomerServiceApp.actionModal.resendMail.content.successCloseButtonLabel')}
					onClick={handleDismissDialogue}
				/>
			</SuccessFeeback>
		);
	}

	if (invokeCommandStatus === CommandStatus.FAILED) {
		return (
			<NotificationBig
				status={NotificationBigStatus.ERROR}
				heading={l10n.translate('platformCustomerServiceApp.actionModal.resendMail.error.heading')}
				message={l10n.translate('platformCustomerServiceApp.actionModal.resendMail.error.message')}
				subline={invokeCommandError?.message}
			/>
		);
	}

	return (
		<Hint
			gray={true}
			heading={l10n.translate('platformCustomerServiceApp.actionModal.resendMail.content.notificationHeading')}
			message={l10n.translate('platformCustomerServiceApp.actionModal.resendMail.content.notificationMessage')}
		/>
	);
}
