import React from 'react';
import { useParams } from 'react-router-dom';

import { CustomerServiceMarketplaceOrderEntityDataProvider } from '@abb-emobility/platform/data-provider';
import { NotFoundError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { UiErrorHandler } from '@abb-emobility/shared/ui-error-handler';
import { SpinnerCircle } from '@abb-emobility/shared/ui-primitive';

import { MarketplaceOrderDetailDataAccessor } from '../components/marketplace-order-detail/MarketplaceOrderDetailDataAccessor';
import { useSearchbarContentModifier } from '../components/searchbar-content/SearchbarContent.hooks';

export function MarketplaceOrderView() {

	const params = useParams();
	const marketplaceOrderId = params?.orderId ?? null;
	const l10n = useL10n();

	useSearchbarContentModifier(marketplaceOrderId, null, true);

	if (marketplaceOrderId === null) {
		throw new NotFoundError(l10n.translate('platformCustomerServiceApp.error.orderNotFound'));
	}

	return (
		<UiErrorHandler>
			<CustomerServiceMarketplaceOrderEntityDataProvider
				pendingComponent={SpinnerCircle}
				primaryKey={marketplaceOrderId}
				key={marketplaceOrderId}
			>
				<MarketplaceOrderDetailDataAccessor />
			</CustomerServiceMarketplaceOrderEntityDataProvider>
		</UiErrorHandler>
	);
}
