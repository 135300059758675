import { CustomerServiceOrderEventCollectionModel, CustomerServiceOrderEventType } from '@abb-emobility/platform/domain-model';
import { createTimestampDtoFromDate, Dto } from '@abb-emobility/shared/domain-model-foundation';

export const customerServiceOrderEventCollectionModelMock: Dto<Array<CustomerServiceOrderEventCollectionModel>>  = [
	{
		type: CustomerServiceOrderEventType.ORDER_RECEIVED,
		id: '1',
		orderId: '1',
		eventDate: createTimestampDtoFromDate(new Date('2022-03-01T16:30:12.345Z')),
		entityAvailable: false
	},
	{
		type: CustomerServiceOrderEventType.ORDER_ADDRESS_VALIDATION_SUCCEEDED,
		id: '2',
		orderId: '1',
		eventDate: createTimestampDtoFromDate(new Date('2022-03-01T16:30:12.345Z')),
		entityAvailable: false
	},
	{
		type: CustomerServiceOrderEventType.ORDER_ADDRESS_VALIDATION_FAILED,
		id: '3',
		orderId: '1',
		eventDate: createTimestampDtoFromDate(new Date('2022-03-01T16:30:12.345Z')),
		entityAvailable: false
	},
	{
		type: CustomerServiceOrderEventType.ORDER_ADDRESS_CORRECTED,
		id: '4',
		orderId: '1',
		eventDate: createTimestampDtoFromDate(new Date('2022-03-01T16:30:12.345Z')),
		entityAvailable: false
	},
	{
		type: CustomerServiceOrderEventType.ACCOUNTING_ORDER_CREATED,
		id: '5',
		orderId: '1',
		eventDate: createTimestampDtoFromDate(new Date('2022-03-01T16:30:12.345Z')),
		entityAvailable: false
	},
	{
		type: CustomerServiceOrderEventType.ORDER_CONFIRMATION_MAIL_SENT,
		id: '6',
		orderId: '1',
		eventDate: createTimestampDtoFromDate(new Date('2022-03-01T16:30:12.345Z')),
		entityAvailable: true
	},
	{
		type: CustomerServiceOrderEventType.BASIC_ANSWERS_RECEIVED,
		id: '7',
		orderId: '1',
		eventDate: createTimestampDtoFromDate(new Date('2022-03-01T16:30:12.345Z')),
		entityAvailable: false
	},
	{
		type: CustomerServiceOrderEventType.GRID_OPERATOR_REGISTRATION_STARTED,
		id: '8',
		orderId: '1',
		eventDate: createTimestampDtoFromDate(new Date('2022-03-01T16:30:12.345Z')),
		entityAvailable: false
	},
	{
		type: CustomerServiceOrderEventType.GRID_OPERATOR_FEEDBACK_RECEIVED,
		id: '9',
		orderId: '1',
		eventDate: createTimestampDtoFromDate(new Date('2022-03-01T16:30:12.345Z')),
		entityAvailable: false
	},
	{
		type: CustomerServiceOrderEventType.GRID_OPERATOR_REJECTION_NOTIFICATION_SENT,
		id: '10',
		orderId: '1',
		eventDate: createTimestampDtoFromDate(new Date('2022-03-01T16:30:12.345Z')),
		entityAvailable: true
	},
	{
		type: CustomerServiceOrderEventType.BASIC_ANSWERS_EVALUATED,
		id: '11',
		orderId: '1',
		eventDate: createTimestampDtoFromDate(new Date('2022-03-01T16:30:12.345Z')),
		entityAvailable: false
	},
	{
		type: CustomerServiceOrderEventType.PRECHECK_PREFERENCES_MAIL_SENT,
		id: '12',
		orderId: '1',
		eventDate: createTimestampDtoFromDate(new Date('2022-03-01T16:30:12.345Z')),
		entityAvailable: false
	},
	{
		type: CustomerServiceOrderEventType.REMOTE_PRECHECK_EXECUTED,
		id: '13',
		orderId: '1',
		eventDate: createTimestampDtoFromDate(new Date('2022-03-01T16:30:12.345Z')),
		entityAvailable: false
	},
	{
		type: CustomerServiceOrderEventType.DELTA_QUOTATION_CREATED,
		id: '14',
		orderId: '1',
		eventDate: createTimestampDtoFromDate(new Date('2022-03-01T16:30:12.345Z')),
		entityAvailable: false
	},
	{
		type: CustomerServiceOrderEventType.DELTA_QUOTATION_MAIL_SENT,
		id: '15',
		orderId: '1',
		eventDate: createTimestampDtoFromDate(new Date('2022-03-01T16:30:12.345Z')),
		entityAvailable: true
	},
	{
		type: CustomerServiceOrderEventType.DELTA_QUOTATION_ACCEPTED,
		id: '16',
		orderId: '1',
		eventDate: createTimestampDtoFromDate(new Date('2022-03-01T16:30:12.345Z')),
		entityAvailable: false
	},
	{
		type: CustomerServiceOrderEventType.PAYMENT_SUCCEEDED,
		id: '17',
		orderId: '1',
		eventDate: createTimestampDtoFromDate(new Date('2022-03-01T16:30:12.345Z')),
		entityAvailable: false
	},
	{
		type: CustomerServiceOrderEventType.PAYMENT_FAILED_OR_TIMED_OUT,
		id: '18',
		orderId: '1',
		eventDate: createTimestampDtoFromDate(new Date('2022-03-01T16:30:12.345Z')),
		entityAvailable: true
	},
	{
		type: CustomerServiceOrderEventType.PAYMENT_CONFIRMATION_MAIL_SENT,
		id: '20',
		orderId: '1',
		eventDate: createTimestampDtoFromDate(new Date('2022-03-01T16:30:12.345Z')),
		entityAvailable: true
	},
	{
		type: CustomerServiceOrderEventType.APPOINTMENT_ARRANGED,
		id: '21',
		orderId: '1',
		eventDate: createTimestampDtoFromDate(new Date('2022-03-01T16:30:12.345Z')),
		entityAvailable: false
	},
	{
		type: CustomerServiceOrderEventType.APPOINTMENT_ARRANGED_NOTIFICATION_SENT,
		id: '22',
		orderId: '1',
		eventDate: createTimestampDtoFromDate(new Date('2022-03-01T16:30:12.345Z')),
		entityAvailable: true
	},
	{
		type: CustomerServiceOrderEventType.SHIPPING_CONFIRMED,
		id: '23',
		orderId: '1',
		eventDate: createTimestampDtoFromDate(new Date('2022-03-01T16:30:12.345Z')),
		entityAvailable: false
	},
	{
		type: CustomerServiceOrderEventType.INSTALLATION_CONFIRMED,
		id: '24',
		orderId: '1',
		eventDate: createTimestampDtoFromDate(new Date('2022-03-01T16:30:12.345Z')),
		entityAvailable: false
	},
	{
		type: CustomerServiceOrderEventType.REPLACEMENT_INSTALLATION_CONFIRMED,
		id: '25',
		orderId: '1',
		eventDate: createTimestampDtoFromDate(new Date('2022-03-01T16:30:12.345Z')),
		entityAvailable: false
	},
	{
		type: CustomerServiceOrderEventType.INSTALLATION_APPROVAL_MAIL_SENT,
		id: '26',
		orderId: '1',
		eventDate: createTimestampDtoFromDate(new Date('2022-03-01T16:30:12.345Z')),
		entityAvailable: true
	},
	{
		type: CustomerServiceOrderEventType.INVOICE_CREATED,
		id: '27',
		orderId: '1',
		eventDate: createTimestampDtoFromDate(new Date('2022-03-01T16:30:12.345Z')),
		entityAvailable: false
	},
	{
		type: CustomerServiceOrderEventType.INVOICE_NOTIFICATION_SENT,
		id: '28',
		orderId: '1',
		eventDate: createTimestampDtoFromDate(new Date('2022-03-01T16:30:12.345Z')),
		entityAvailable: true
	},
	{
		type: CustomerServiceOrderEventType.ORDER_CANCELLED,
		id: '29',
		orderId: '1',
		eventDate: createTimestampDtoFromDate(new Date('2022-03-01T16:30:12.345Z')),
		entityAvailable: false
	},
	{
		type: CustomerServiceOrderEventType.GRID_OPERATOR_REGISTRATION_REVOKED,
		id: '30',
		orderId: '1',
		eventDate: createTimestampDtoFromDate(new Date('2022-03-01T16:30:12.345Z')),
		entityAvailable: false
	},
	{
		type: CustomerServiceOrderEventType.CUSTOMER_CALL_RECEIVED,
		id: '31',
		orderId: '1',
		eventDate: createTimestampDtoFromDate(new Date('2022-03-01T16:30:12.345Z')),
		entityAvailable: false
	},
	{
		type: CustomerServiceOrderEventType.RETURN_OR_REPLACEMENT_SHIPMENT_RECEIVED,
		id: '32',
		orderId: '1',
		eventDate: createTimestampDtoFromDate(new Date('2022-03-01T16:30:12.345Z')),
		entityAvailable: false
	},
	{
		type: CustomerServiceOrderEventType.RETURN_INITIATED,
		id: '33',
		orderId: '1',
		eventDate: createTimestampDtoFromDate(new Date('2022-03-01T16:30:12.345Z')),
		entityAvailable: false
	},
	{
		type: CustomerServiceOrderEventType.REPLACEMENT_INITIATED,
		id: '34',
		orderId: '1',
		eventDate: createTimestampDtoFromDate(new Date('2022-03-01T16:30:12.345Z')),
		entityAvailable: false
	},
	{
		type: CustomerServiceOrderEventType.CANCELLATION_INITIATED,
		id: '35',
		orderId: '1',
		eventDate: createTimestampDtoFromDate(new Date('2022-03-01T16:30:12.345Z')),
		entityAvailable: false
	},
	{
		type: CustomerServiceOrderEventType.ORDER_NOTE_CREATED,
		id: '36',
		orderId: '1',
		eventDate: createTimestampDtoFromDate(new Date('2022-03-01T16:30:12.345Z')),
		entityAvailable: false
	},
	{
		type: CustomerServiceOrderEventType.REFUND_COMPLETED,
		id: '37',
		orderId: '1',
		eventDate: createTimestampDtoFromDate(new Date('2022-03-01T16:30:12.345Z')),
		entityAvailable: false
	},
	{
		type: CustomerServiceOrderEventType.INSTALLATION_CANCELLATION_MAIL_SENT,
		id: '38',
		orderId: '1',
		eventDate: createTimestampDtoFromDate(new Date('2022-03-01T16:30:12.345Z')),
		entityAvailable: true
	}
];
