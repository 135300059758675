import React, { ReactNode } from 'react';

import {
	CustomerServiceOrderDownloadFileCollectionDataInterceptEmpty,
	useCustomerServiceOrderDownloadFileCollectionData
} from '@abb-emobility/platform/data-provider';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { DownloadListItem, NotificationBig, NotificationBigStatus } from '@abb-emobility/shared/ui-primitive';

import './OrderDetailDocuments.scss';

export function OrderDetailFiles() {

	const orderDownloadFilesData = useCustomerServiceOrderDownloadFileCollectionData();
	const documents = orderDownloadFilesData.query();
	const l10n = useL10n();

	const renderAccountingDocuments = () => {
		return documents.map((document) => {
			return (
				<DownloadListItem file={document.file} key={document.id} />
			);
		});
	};

	const renderEmptyDownloadFilesState = (): ReactNode => {
		return (
			<NotificationBig
				status={NotificationBigStatus.EMPTY2}
				heading={l10n.translate('platformCustomerServiceApp.orderDetail.files.emptyState.heading')}
				message={l10n.translate('platformCustomerServiceApp.orderDetail.files.emptyState.message')}
			/>
		);
	};

	return (
		<main className="order-detail-documents">
			<CustomerServiceOrderDownloadFileCollectionDataInterceptEmpty emptyStateComponent={renderEmptyDownloadFilesState}>
				{renderAccountingDocuments()}
			</CustomerServiceOrderDownloadFileCollectionDataInterceptEmpty>
		</main>
	);
}
