import { CustomerServiceCustomerModel, CustomerServiceCustomerModelConverter } from '@abb-emobility/platform/domain-model';
import { AbstractCrudApiClient } from '@abb-emobility/shared/api-integration-foundation';

export class CustomerServiceCustomerApiClient extends AbstractCrudApiClient<CustomerServiceCustomerModel> {
	protected collectionControllerUri = 'customers/';
	protected paginationControllerUri = 'customers/';
	protected entityControllerUri = 'customer/{id}/';
	protected entitySearchControllerUri = 'customer/';
	protected modelConverter = new CustomerServiceCustomerModelConverter();
}
