import React from 'react';

import { CustomerServiceMarketplaceOrderModel } from '@abb-emobility/platform/domain-model';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	Card
} from '@abb-emobility/shared/ui-primitive';

import { CustomerInformation } from '../customer-information/CustomerInformation';
import { OrderInformationSection } from '../order-information-section/OrderInformationSection';
import { OrderLineItemCollection } from '../order-line-item-collection/OrderLineItemCollection';

import './MarketplaceOrderDetailOverview.scss';

export type MarketplaceOrderDetailOverviewProps = {
	order: CustomerServiceMarketplaceOrderModel
};

export function MarketplaceOrderDetailOverview(props: MarketplaceOrderDetailOverviewProps) {

	const l10n = useL10n();

	const { order } = props;

	return (
		<article className="marketplace-order-detail-overview">
			<Card>
				<CustomerInformation
					header={l10n.translate('platformCustomerServiceApp.orderDetail.overview.customerInformation.heading')}
					customer={order.customer}
				/>
			</Card>

			<Card>
				<OrderInformationSection order={order} />
			</Card>

			<Card>
				<OrderLineItemCollection orderLineItems={order.orderLineItems} currencyCode={order.currencyCode} />
			</Card>

		</article>

	);
}
