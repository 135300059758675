import { ReactNode } from 'react';

import { AnyCustomerServiceCommentModel, CustomerServiceCommentType, CustomerServiceCustomerCallReason } from '@abb-emobility/platform/domain-model';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { CommentUser, Gravatar, Separator, Tag } from '@abb-emobility/shared/ui-primitive';
import { l10nLiteralFromEnumValue } from '@abb-emobility/shared/util';

import './Comment.scss';

export type CommentProps = {
	comment: AnyCustomerServiceCommentModel
};

export function Comment(props: CommentProps) {
	const { comment } = props;

	const l10n = useL10n();

	const renderContactReasonOther = (): ReactNode => {
		if (
			comment.type !== CustomerServiceCommentType.CUSTOMER_CALL
			|| comment.contactReason !== CustomerServiceCustomerCallReason.OTHER
			|| (comment.contactReasonOther ?? undefined) === undefined
			|| String(comment.contactReasonOther).length === 0
		) {
			return null;
		}

		return (
			<div className="comment__main__section">
				{comment.contactReasonOther}
			</div>
		);
	};

	const renderContactReason = (): ReactNode => {
		if (comment.type !== CustomerServiceCommentType.CUSTOMER_CALL) {
			return null;
		}

		return (
			<>
				<div className="comment__main__section">
					{l10n.translate(l10nLiteralFromEnumValue(comment.contactReason, 'platformCustomerServiceApp.comment.callReason'))}
				</div>
				{renderContactReasonOther()}
				<Separator />
			</>
		);
	};

	return (
		<article className="comment">
			<header className="comment__header">
				<CommentUser name={comment.author.fullname}>
					<Gravatar mailAddress={comment.author.mailAddress} />
				</CommentUser>

				<span className="comment__header__date">
					{l10n.formatTimestampDate(comment.createdAt)}
				</span>

				<Tag label={l10n.translate(l10nLiteralFromEnumValue(comment.type, 'platformCustomerServiceApp.comment.categories'))} />
			</header>

			<main className="comment__main">
				{renderContactReason()}
				<div className="comment__main__section">
					{comment.comment}
				</div>
			</main>
		</article>

	);
}
