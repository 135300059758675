import { Nullable } from '@abb-emobility/shared/util';

export const parseErrorReference = (input: string): Nullable<number> => {
	const regex = /\[(\d+)\]$/;
	const match = input.match(regex);

	if (match) {
		const indexStr = match[1];
		const index = parseInt(indexStr, 10);

		if (!isNaN(index)) {
			return index;
		}
	}

	return null;
};
