import React from 'react';

import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	SegmentHeader,
	Separator,
	TabularInformationItem,
	TabularInformationItemType,
	TabularInformationRow
} from '@abb-emobility/shared/ui-primitive';
import { RequestedTradeEffort } from '@abb-emobility/usertask/domain-model';

export type InstallationEffortInformationProps = {
	requestedEffort: Array<RequestedTradeEffort>
};

export function InstallationTradeEffortInformation(props: InstallationEffortInformationProps) {

	const { requestedEffort } = props;

	const l10n = useL10n();

	const renderEfforts = () => {
		return requestedEffort.map((effort) => {
			return (
				<TabularInformationRow>
					<TabularInformationItem label={effort.trade.name} variant={TabularInformationItemType.GREY} />
					<TabularInformationItem label={`${l10n.formatNumber(effort.expectedEffort)} ${l10n.translate('platformCustomerServiceApp.modal.task.section.installationEffortInformation.hours')}`} />
				</TabularInformationRow>
			);
		});
	};

	return (
		<section>
			<SegmentHeader caption={l10n.translate('platformCustomerServiceApp.modal.task.section.installationEffortInformation.heading')} />
			<Separator />
			<TabularInformationRow>
				<TabularInformationItem
					label={l10n.translate('platformCustomerServiceApp.modal.task.section.installationEffortInformation.trade')}
					variant={TabularInformationItemType.HEADING} />
				<TabularInformationItem
					label={l10n.translate('platformCustomerServiceApp.modal.task.section.installationEffortInformation.effort')}
					variant={TabularInformationItemType.HEADING} />
			</TabularInformationRow>

			{renderEfforts()}
		</section>
	);
}
