import { ModelConverter, ModelConverterInterface } from '@abb-emobility/shared/domain-model-foundation';

import {
	CustomerServiceOrderInformationQuotationInformationModel, CustomerServiceOrderInformationQuotationInformationModelConverter
} from '../order-information/CustomerServiceOrderInformationQuotationInformationModel';

export type CustomerServiceOrderEventDeltaQuotationMailSentDetailModel = {
	mailAddress: string,
	quotation?: CustomerServiceOrderInformationQuotationInformationModel
};

export class CustomerServiceOrderEventDeltaQuotationMailSentDetailModelConverter extends ModelConverter<CustomerServiceOrderEventDeltaQuotationMailSentDetailModel> {

	override getFieldConverterMapByModel(): Map<keyof CustomerServiceOrderEventDeltaQuotationMailSentDetailModel, ModelConverterInterface<CustomerServiceOrderInformationQuotationInformationModel>> {
		return new Map([
			['quotation', new CustomerServiceOrderInformationQuotationInformationModelConverter()]
		]);
	}
}
