import {
	AnyCustomerServiceOrderInformationModel,
	AnyCustomerServiceOrderInformationModelConverter
} from '@abb-emobility/platform/domain-model';
import { AbstractCrudApiClient } from '@abb-emobility/shared/api-integration-foundation';

export class AnyCustomerServiceOrderInformationApiClient extends AbstractCrudApiClient<AnyCustomerServiceOrderInformationModel> {
	protected collectionControllerUri = 'order-informations/';
	protected paginationControllerUri = 'order-informations/';
	protected entityControllerUri = 'order-information/{id}/';
	protected entitySearchControllerUri = 'order-information/';
	protected override paginationSize = 20;
	protected modelConverter = new AnyCustomerServiceOrderInformationModelConverter();
}
