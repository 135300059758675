/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	InstallationPeriod,
	InstallationTradeModel,
	InstallationTradeModelConverter,
	InstallerModel
} from '@abb-emobility/shared/domain-model';
import {
	ArrayOfDateRangeFieldNames,
	ArrayOfTimestampFieldNames,
	DateRange, DateRangeFieldNames,
	ModelConverter, ModelConverterInterface,
	ModelPrimaryKey,
	Timestamp,
	TimestampFieldNames
} from '@abb-emobility/shared/domain-model-foundation';

export type CustomerServiceOrderInstallationAppointmentModel = {
	readonly id: ModelPrimaryKey,
	readonly orderId: ModelPrimaryKey,
	readonly installer: InstallerModel,
	readonly trade: InstallationTradeModel,
	readonly period: DateRange,
	readonly periodStartsAt: Timestamp,
	readonly installationPeriod: InstallationPeriod
};

export class CustomerServiceOrderInstallationAppointmentModelConverter extends ModelConverter<CustomerServiceOrderInstallationAppointmentModel> {

	override getTimestampFields(): Array<TimestampFieldNames<CustomerServiceOrderInstallationAppointmentModel> | ArrayOfTimestampFieldNames<CustomerServiceOrderInstallationAppointmentModel>> {
		return ['periodStartsAt'];
	}

	override getDateRangeFields(): Array<DateRangeFieldNames<CustomerServiceOrderInstallationAppointmentModel> | ArrayOfDateRangeFieldNames<CustomerServiceOrderInstallationAppointmentModel>> {
		return ['period'];
	}

	override getFieldConverterMapByModel(): Map<keyof CustomerServiceOrderInstallationAppointmentModel, ModelConverterInterface<any>> {
		return new Map<keyof CustomerServiceOrderInstallationAppointmentModel, ModelConverterInterface<any>>([
			['trade', new InstallationTradeModelConverter()]
		]);
	}

}
