import { CustomerServiceOrderInstallationAppointmentModel } from '@abb-emobility/platform/domain-model';
import {
	installationTradeModelMock01,
	installationTradeModelMock02,
	installationTradeModelMock03,
	installerModelMock01,
	installerModelMock02
} from '@abb-emobility/shared/api-integration';
import { InstallationPeriod } from '@abb-emobility/shared/domain-model';
import {
	DateRangeDto,
	createTimestampDtoFromDate,
	Dto
} from '@abb-emobility/shared/domain-model-foundation';

const createDateRange = (forToday: boolean): DateRangeDto => {
	const todayStart = new Date();
	todayStart.setHours(10, 0, 0, 0);
	const todayEnd = new Date();
	todayEnd.setHours(16, 30, 0, 0);
	if (!forToday) {
		todayStart.setDate(todayStart.getDate() + 1 + Math.round(Math.random() * 5));
		todayEnd.setDate(todayEnd.getDate() + 1 + Math.round(Math.random() * 5));
	}
	return {
		start: createTimestampDtoFromDate(todayStart),
		end: createTimestampDtoFromDate(todayEnd)
	};
};

let period = createDateRange(true);
export const customerServiceOrderInstallationAppointmentModelMock01: Dto<CustomerServiceOrderInstallationAppointmentModel> = {
	id: '1',
	orderId: '1',
	installer: installerModelMock01,
	trade: installationTradeModelMock01,
	period: period,
	periodStartsAt: period.start,
	installationPeriod: InstallationPeriod.PREPARATION
};

period = createDateRange(false);
export const customerServiceOrderInstallationAppointmentModelMock02: Dto<CustomerServiceOrderInstallationAppointmentModel> = {
	id: '2',
	orderId: '1',
	installer: installerModelMock02,
	trade: installationTradeModelMock02,
	period: period,
	periodStartsAt: period.start,
	installationPeriod: InstallationPeriod.PERMANENT
};

period = createDateRange(false);
export const customerServiceOrderInstallationAppointmentModelMock03: Dto<CustomerServiceOrderInstallationAppointmentModel> = {
	id: '3',
	orderId: '1',
	installer: installerModelMock02,
	trade: installationTradeModelMock03,
	period: period,
	periodStartsAt: period.start,
	installationPeriod: InstallationPeriod.BEGINNING
};

export const customerServiceOrderInstallationAppointmentCollectionMock: Array<Dto<CustomerServiceOrderInstallationAppointmentModel>> = [
	customerServiceOrderInstallationAppointmentModelMock01,
	customerServiceOrderInstallationAppointmentModelMock02,
	customerServiceOrderInstallationAppointmentModelMock03
];
