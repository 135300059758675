import { ReactNode, useState } from 'react';

import { CustomerServiceOrderEventEntityDataProvider } from '@abb-emobility/platform/data-provider';
import {
	CustomerServiceOrderEventCollectionModel,
	customerServiceOrderEventTypeSpecifics
} from '@abb-emobility/platform/domain-model';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { UiErrorHandler } from '@abb-emobility/shared/ui-error-handler';
import { Icon, IconIdentifier, SpinnerCircle, Tag } from '@abb-emobility/shared/ui-primitive';
import { buildCssClassNameFromEnumValue, buildCssClassStringFromClassMap, l10nLiteralFromEnumValue } from '@abb-emobility/shared/util';

import { TimelineItemDetails } from '../timeline-item-details/TimelineItemDetails';

import './TimelineItem.scss';

export type TimelineItemProps = {
	event: CustomerServiceOrderEventCollectionModel
};

export const TimelineItem = (props: TimelineItemProps) => {

	const { event } = props;

	const l10n = useL10n();
	const [expanded, setExpanded] = useState(false);
	const eventSpecifics = customerServiceOrderEventTypeSpecifics?.[event.type] ?? null;

	if (eventSpecifics === null) {
		console.warn('No specifics for order timeline event of type ' + event.type + ' provided.');
		return null;
	}

	const renderDetailTrigger = (): ReactNode => {
		if (event.entityAvailable) {
			if (expanded) {
				return (
					<Icon name={IconIdentifier.CARET_DOWN} />
				);
			}
			return (
				<Icon name={IconIdentifier.CARET_RIGHT} />
			);
		}
		return;
	};

	const renderDetails = (): ReactNode => {
		if (event.entityAvailable && expanded) {
			return (
				<UiErrorHandler key={event.id}>
					<CustomerServiceOrderEventEntityDataProvider
						primaryKey={event.id}
						pendingComponent={SpinnerCircle}
					>
						<TimelineItemDetails />
					</CustomerServiceOrderEventEntityDataProvider>
				</UiErrorHandler>
			);
		}
		return null;
	};

	const itemStateClassMap = {
		'timeline-item__state': true,
		'timeline-item--milestone': eventSpecifics.isMilestone,
		[buildCssClassNameFromEnumValue(eventSpecifics.eventState, 'timeline-item__state')]: true
	};

	return (
		<section className="timeline-item">
			<div className="timeline-item__date">
				{l10n.formatTimestampDateTime(event.eventDate)}
			</div>
			<div className={buildCssClassStringFromClassMap(itemStateClassMap)}></div>
			<div className="timeline-item__content">
				<div className="timeline-item__content__header">
					<div className="timeline-item__content__header__heading">
						<h3 className="timeline-item__content__header__heading__label">
							{l10n.translate(l10nLiteralFromEnumValue(event.type, 'platformCustomerServiceApp.orderEvent.type'))}
						</h3>
						<div className="timeline-item__content__header__heading__tag">
							<Tag label={l10n.translate(l10nLiteralFromEnumValue(
								eventSpecifics.category,
								'platformCustomerServiceApp.orderEvent.category'
							))} />
						</div>
					</div>
					<div className="timeline-item__content__action" onClick={() => setExpanded(!expanded)}>
						{renderDetailTrigger()}
					</div>
				</div>
				{renderDetails()}
			</div>
		</section>
	);

};
