import { DecisionType, GridOperatorModel, GridOperatorModelConverter } from '@abb-emobility/shared/domain-model';
import { ModelConverter, ModelConverterInterface } from '@abb-emobility/shared/domain-model-foundation';
import { ArrayOfDownloadFileFieldNames, DownloadFile, DownloadFileFieldNames } from '@abb-emobility/shared/domain-model-foundation';

export type CustomerServiceOrderInformationGridOperatorDecisionInformationModel = {
	readonly decision: DecisionType,
	readonly gridOperator: GridOperatorModel,
	readonly files?: Array<DownloadFile>
};

export class CustomerServiceOrderInformationGridOperatorDecisionInformationModelConverter extends ModelConverter<CustomerServiceOrderInformationGridOperatorDecisionInformationModel> {
	override getDownloadFileFields(): Array<DownloadFileFieldNames<CustomerServiceOrderInformationGridOperatorDecisionInformationModel> | ArrayOfDownloadFileFieldNames<CustomerServiceOrderInformationGridOperatorDecisionInformationModel>> {
		return ['files'];
	}

	override getFieldConverterMapByModel(): Map<keyof CustomerServiceOrderInformationGridOperatorDecisionInformationModel, ModelConverterInterface<GridOperatorModel>> {
		return new Map([
			['gridOperator', new GridOperatorModelConverter()]
		]);
	}

}
