import React from 'react';

import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	ButtonIcon,
	CollectionItemHeader,
	CollectionItemHeaderItem,
	CollectionItemLabel,
	Icon,
	IconIdentifier,
	Status,
	Tag
} from '@abb-emobility/shared/ui-primitive';
import { l10nLiteralFromEnumValue } from '@abb-emobility/shared/util';
import { AnyTaskCollectionItemModel } from '@abb-emobility/usertask/domain-model';

import './TaskCollectionItem.scss';
import { getTaskTypeResponsibility } from '../task-factory/utils/TaskTypeResponsibility';

type TaskCollectionItemHeaderProps = {
	task: AnyTaskCollectionItemModel,
	icon?: IconIdentifier,
	onClick?: () => void,
	disabled?: boolean
};

export function TaskCollectionItemHeader(props: TaskCollectionItemHeaderProps) {

	const { task, icon = IconIdentifier.CARDS, onClick = null, disabled = false } = props;

	const l10n = useL10n();

	const handleClick = () => {
		if (onClick !== null) {
			onClick();
		}
	};

	const renderTaskTypeTag = (): React.ReactNode => {
		const taskTypeResponsibility = getTaskTypeResponsibility(task.type);

		if (taskTypeResponsibility === null) {
			return null;
		}

		return (
			<Tag
				label={l10n.translate(l10nLiteralFromEnumValue(taskTypeResponsibility, 'platformCustomerServiceApp.taskCollectionItem.category'))}
				noWrap={true}
			/>
		);
	};

	return (
		<CollectionItemHeader>
			<CollectionItemHeaderItem>
				<CollectionItemLabel
					label={l10n.translate(l10nLiteralFromEnumValue(task.type, 'platformCustomerServiceApp.taskCollectionItem.type'))}
					orderId={task.marketplaceOrderId}
					additionalAttribute={task.marketplace}
					additionalIcon={IconIdentifier.PACKAGE}
					disabled={disabled}
				>
					{renderTaskTypeTag()}
				</CollectionItemLabel>
			</CollectionItemHeaderItem>

			<CollectionItemHeaderItem>
				<Status
					label={l10n.translate(l10nLiteralFromEnumValue(task.status, 'platformCustomerServiceApp.taskCollectionItem.status'))}
					state={task.status}
				/>
			</CollectionItemHeaderItem>

			<CollectionItemHeaderItem>
				<span className="task-collection-item__language">
					{task.languageCode?.toUpperCase()}
				</span>
			</CollectionItemHeaderItem>

			<CollectionItemHeaderItem>
				<ButtonIcon disabled={onClick === null} onClick={handleClick}>
					<Icon name={icon} />
				</ButtonIcon>
			</CollectionItemHeaderItem>

		</CollectionItemHeader>
	);
}
