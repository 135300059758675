import React, { useEffect, useState } from 'react';

import {
	CustomerServiceUpdateOrderInvocationPayloadModelConverter,
	CustomerServiceUpdateOrderPayloadModelConverter
} from '@abb-emobility/platform/domain-model';
import { CommandStatus, useCommandData } from '@abb-emobility/shared/command-api';
import { IsoLanguageCode } from '@abb-emobility/shared/iso-language-code';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	ActionModalFooter,
	ActionModalContentSection,
	ButtonGhost,
	ButtonPrimary,
	InputSelect,
	InputSelectOption,
	InputText,
	NotificationBig,
	NotificationBigStatus,
	SectionHeader,
	SpinnerCircle,
	SubmissionStatus,
	SuccessFeeback,
	SuccessImageIdentifier,
	useModalDialogue,
	useModalDialogueManager
} from '@abb-emobility/shared/ui-primitive';
import { Nullable } from '@abb-emobility/shared/util';

import { HypermediaAction } from '../../ActionModalFactory.types';

export type UpdateOrderActionModalProps = {
	action: HypermediaAction
};

export function UpdateOrderActionModal(props: UpdateOrderActionModalProps) {

	const { action } = props;

	const l10n = useL10n();
	const modalDialogue = useModalDialogue();
	const dialogueManager = useModalDialogueManager();

	const commandData = useCommandData(
		action.hypermediaLink,
		new CustomerServiceUpdateOrderPayloadModelConverter(),
		new CustomerServiceUpdateOrderInvocationPayloadModelConverter()
	);
	const fetchPayload = commandData.query();
	const fetchStatus = commandData.queryFetchStatus();
	const fetchError = commandData.queryFetchError();
	const invokeCommandStatus = commandData.queryInvocationStatus();
	const invokeCommandError = commandData.queryInvocationError();

	const [languageCode, setLanguageCode] = useState<Nullable<IsoLanguageCode>>(null);
	const [phoneNumber, setPhoneNumber] = useState<Nullable<string>>(null);

	const footerCancelLabel = l10n.translate('platformCustomerServiceApp.actionModal.updateOrder.footer.cancel');
	const footerConfirmlabel = l10n.translate('platformCustomerServiceApp.actionModal.updateOrder.footer.confirm');

	const options: Array<InputSelectOption<IsoLanguageCode>> = Object.values(IsoLanguageCode)
		.map((value) => (
				{
					label: value.toUpperCase(),
					value: value
				}
			)
		);

	useEffect(() => {
		if (fetchStatus === CommandStatus.SUCCESSFULL && fetchPayload !== null) {
			setLanguageCode(fetchPayload.languageCode);
			setPhoneNumber(fetchPayload.phoneNumber);
		}
		return;

	}, [fetchStatus]);

	useEffect(() => {
		const handleSubmitAction = () => {
			if (languageCode !== null && phoneNumber !== null) {
				commandData.invoke({ languageCode: languageCode, phoneNumber: phoneNumber });
			}
		};

		const isFormValid =
			((languageCode?.length ?? 0) > 0) && ((phoneNumber?.length ?? 0) > 0) &&
			((languageCode !== fetchPayload?.languageCode) || (phoneNumber !== fetchPayload?.phoneNumber));

		switch (invokeCommandStatus) {
			case CommandStatus.IDLE:
				modalDialogue.setFooter(
					<ActionModalFooter>
						<ButtonGhost
							label={footerCancelLabel}
							onClick={handleDismissDialogue}
						/>
						<ButtonPrimary
							label={footerConfirmlabel}
							submissionStatus={SubmissionStatus.IDLE}
							onClick={handleSubmitAction}
							disabled={!isFormValid}
						/>
					</ActionModalFooter>
				);
				break;
			case CommandStatus.PENDING:
				modalDialogue.setFooter(
					<ActionModalFooter>
						<ButtonGhost
							label={footerCancelLabel}
							onClick={handleDismissDialogue}
						/>
						<ButtonPrimary
							label={footerConfirmlabel}
							submissionStatus={SubmissionStatus.PENDING}
							onClick={handleSubmitAction}
							disabled={false}
						/>
					</ActionModalFooter>
				);
				break;
			case CommandStatus.SUCCESSFULL:
			case CommandStatus.FAILED:
				modalDialogue.unsetFooter();
				break;
		}

	}, [invokeCommandStatus, languageCode, phoneNumber]);

	const handleDismissDialogue = () => {
		dialogueManager.pop();
	};

	const handleOrderLanguageInputChange = (value: string): void => {
		setLanguageCode(value as IsoLanguageCode);
	};

	const handlePhoneNumberInputChange = (value: string): void => {
		setPhoneNumber(value);
	};

	const renderContent = () => {
		if (fetchStatus === CommandStatus.FAILED) {
			return (
				<NotificationBig
					status={NotificationBigStatus.ERROR}
					heading={l10n.translate('platformCustomerServiceApp.actionModal.updateOrder.error.loading.heading')}
					message={l10n.translate('platformCustomerServiceApp.actionModal.updateOrder.error.loading.message')}
					subline={fetchError?.message}
				/>
			);
		}
		if (fetchStatus === CommandStatus.PENDING) {
			return (
				<SpinnerCircle />
			);
		}

		if (languageCode !== null && phoneNumber !== null && invokeCommandStatus !== CommandStatus.SUCCESSFULL) {
			return (
				<>
					<ActionModalContentSection>
						<SectionHeader heading={l10n.translate('platformCustomerServiceApp.actionModal.updateOrder.content.orderInformationHeading')} />
						<InputText
							label={l10n.translate('platformCustomerServiceApp.actionModal.updateOrder.content.phoneLabel')}
							value={phoneNumber}
							onChange={handlePhoneNumberInputChange}
						/>

					</ActionModalContentSection>

					<ActionModalContentSection>
						<SectionHeader heading={l10n.translate('platformCustomerServiceApp.actionModal.updateOrder.content.languageHeading')} />
						<InputSelect<IsoLanguageCode>
							label={l10n.translate('platformCustomerServiceApp.actionModal.updateOrder.content.languageLabel')}
							value={languageCode}
							options={options}
							onSelect={handleOrderLanguageInputChange}
						/>
					</ActionModalContentSection>
				</>
			);
		}

		if (invokeCommandStatus === CommandStatus.SUCCESSFULL) {
			return (
				<SuccessFeeback
					heading={l10n.translate('platformCustomerServiceApp.actionModal.updateOrder.content.successHeading')}
					image={SuccessImageIdentifier.IMAGE4}
				>
					<ButtonGhost
						label={l10n.translate('platformCustomerServiceApp.actionModal.updateOrder.content.successCloseButtonLabel')}
						onClick={handleDismissDialogue}
					/>
				</SuccessFeeback>
			);
		}

		if (invokeCommandStatus === CommandStatus.FAILED) {
			return (
				<NotificationBig
					status={NotificationBigStatus.ERROR}
					heading={l10n.translate('platformCustomerServiceApp.actionModal.updateOrder.error.writing.heading')}
					message={l10n.translate('platformCustomerServiceApp.actionModal.updateOrder.error.writing.message')}
					subline={invokeCommandError?.message}
				/>
			);
		}
		return null;
	};

	return (
		renderContent()
	);
}
