import React, { ReactNode } from 'react';

import { ActionItemFactory } from '../action-item-factory/ActionItemFactory';
import { useActionModalFactory } from '../action-modal-factory/ActionModalFactory.hooks';
import { useActionbarContent } from '../actionbar-content/ActionbarContent.context';
import { ApplicationActionItemFactory } from '../application-action-factory/ApplicationActionItemFactory';
import { Searchbar } from '../searchbar/Searchbar';

import './SearchActionbar.scss';

export const SearchActionbar = () => {

	const actionbarContent = useActionbarContent();
	const actionModalFactory = useActionModalFactory();

	const hypermediaActions = actionbarContent.getHypermediaActions() ?? [];
	const rerouteActions = actionbarContent.getApplicationActions() ?? [];

	const renderHypermediaActions = (): ReactNode => {
		if (hypermediaActions.length === 0) {
			return null;
		}

		return hypermediaActions
			.map((action, idx): ReactNode => {
				return (
					<ActionItemFactory
						key={idx}
						hypermediaLink={action.hypermediaLink}
						onClick={() => actionModalFactory.renderModal(action)}
					/>
				);
			});
	};

	const renderApplicationActions = (): ReactNode => {
		if (rerouteActions.length === 0) {
			return null;
		}

		return rerouteActions.map((action, idx) => {
			return (
				<ApplicationActionItemFactory key={idx} action={action} />
			);
		});
	};

	return (
		<section className="search-actionbar">
			<div className="search-actionbar__searchbar">
				<Searchbar />
			</div>

			<div className="search-actionbar__actionbar">
				{renderHypermediaActions()}
				{renderApplicationActions()}
			</div>
		</section>
	);
};
