import { CustomerServiceOrderPaymentModel, CustomerServiceOrderPaymentModelConverter } from '@abb-emobility/platform/domain-model';
import { AbstractCrudApiClient } from '@abb-emobility/shared/api-integration-foundation';

export class CustomerServiceMarketplaceOrderPaymentApiClient extends AbstractCrudApiClient<CustomerServiceOrderPaymentModel> {
	protected collectionControllerUri = 'marketplace-order-payments/';
	protected paginationControllerUri = 'marketplace-order-payments/';
	protected entityControllerUri = 'marketplace-order-payment/{id}/';
	protected entitySearchControllerUri = 'marketplace-order-payment/';
	protected modelConverter = new CustomerServiceOrderPaymentModelConverter();
}
