import {
	AnyCustomerServiceCommentModel,
	CustomerServiceCommentAuthorModel,
	CustomerServiceCommentType, CustomerServiceCustomerCallReason
} from '@abb-emobility/platform/domain-model';
import { Dto, createTimestampDtoFromDate } from '@abb-emobility/shared/domain-model-foundation';

const author: Dto<CustomerServiceCommentAuthorModel> = {
	id: '1',
	fullname: 'Lisa Marie Kloster',
	mailAddress: 'lisa-marie.kloster@fiege.com'
};

export const customerServiceOrderCommentModelMock: Dto<AnyCustomerServiceCommentModel> = {
	id: '1',
	orderId: '1',
	type: CustomerServiceCommentType.ORDER,
	author: author,
	createdAt: createTimestampDtoFromDate(new Date('2022-02-01T16:30:12.345Z')),
	comment: 'Donec id elit non mi porta gravida at eget metus. Sed posuere consectetur est at lobortis. Maecenas faucibus mollis interdum. Nullam id dolor id nibh ultricies vehicula ut id elit. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Donec ullamcorper nulla non metus auctor fringilla.'
};

export const customerServiceCustomerCommentModelMock: Dto<AnyCustomerServiceCommentModel> = {
	id: '2',
	customerId: '1',
	type: CustomerServiceCommentType.CUSTOMER,
	author: author,
	createdAt: createTimestampDtoFromDate(new Date('2022-02-01T16:30:12.345Z')),
	comment: 'Donec id elit non mi porta gravida at eget metus. Sed posuere consectetur est at lobortis. Maecenas faucibus mollis interdum. Nullam id dolor id nibh ultricies vehicula ut id elit. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Donec ullamcorper nulla non metus auctor fringilla.'
};

export const customerServiceCustomerCallCommentModelMock: Dto<AnyCustomerServiceCommentModel> = {
	id: '3',
	customerId: '1',
	orderId: '1',
	type: CustomerServiceCommentType.CUSTOMER_CALL,
	contactReason: CustomerServiceCustomerCallReason.PAYMENT,
	author: author,
	createdAt: createTimestampDtoFromDate(new Date('2022-02-01T16:30:12.345Z')),
	comment: 'Donec id elit non mi porta gravida at eget metus. Sed posuere consectetur est at lobortis. Maecenas faucibus mollis interdum. Nullam id dolor id nibh ultricies vehicula ut id elit. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Donec ullamcorper nulla non metus auctor fringilla.'
};

export const customerServiceCustomerCallOtherReasonCommentModelMock: Dto<AnyCustomerServiceCommentModel> = {
	id: '4',
	customerId: '1',
	orderId: '1',
	type: CustomerServiceCommentType.CUSTOMER_CALL,
	contactReason: CustomerServiceCustomerCallReason.OTHER,
	contactReasonOther: 'Nullam quis risus eget urna mollis ornare vel eu leo. Etiam porta sem malesuada magna mollis euismod. Nullam id dolor id nibh ultricies vehicula ut id elit. Curabitur blandit tempus porttitor.',
	author: author,
	createdAt: createTimestampDtoFromDate(new Date('2022-02-01T16:30:12.345Z')),
	comment: 'Donec id elit non mi porta gravida at eget metus. Sed posuere consectetur est at lobortis. Maecenas faucibus mollis interdum. Nullam id dolor id nibh ultricies vehicula ut id elit. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Donec ullamcorper nulla non metus auctor fringilla.'
};

export const customerServiceCommentCollectionMock: Array<Dto<AnyCustomerServiceCommentModel>> = [
	customerServiceOrderCommentModelMock,
	customerServiceCustomerCommentModelMock,
	customerServiceCustomerCallCommentModelMock,
	customerServiceCustomerCallOtherReasonCommentModelMock
];
