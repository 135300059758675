import React from 'react';

import { CustomerServiceMarketplaceOrderModel } from '@abb-emobility/platform/domain-model';
import { createAccessTokenLoader, useAuth } from '@abb-emobility/shared/auth-provider';
import { FetchError } from '@abb-emobility/shared/business-error';
import {
	createCrudEntityDataProviderValue,
	EntityDataProviderProps,
	FetchMode,
	useDataProviderFetchFailedHandler,
	useDataProviderFetchInit,
	useDataProviderFetchSuspense
} from '@abb-emobility/shared/data-provider-foundation';
import { useEnv } from '@abb-emobility/shared/environment';
import { AppError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { Optional } from '@abb-emobility/shared/util';

import { customerServiceMarketplaceOrderEntityData } from './CustomerServiceMarketplaceOrderEntityData';
import {
	CustomerServiceMarketplaceOrderEntityStore,
	customerServiceMarketplaceOrderEntityStoreAccessors,
	customerServiceMarketplaceOrderEntityStoreName
} from './CustomerServiceMarketplaceOrderEntitySlice';

export function CustomerServiceMarketplaceOrderEntityDataProvider(props: EntityDataProviderProps) {
	const {
		fetchMode = FetchMode.IMMEDIATE,
		primaryKey,
		suspense = fetchMode === FetchMode.IMMEDIATE,
		pendingComponent = null,
		forceFetch
	} = props;

	const auth = useAuth();
	const env = useEnv();
	const l10n = useL10n();

	const apiBaseUrl = new Optional(process.env['NX_CUSTOMER_SERVICE_API_BASE_URL']).getOrThrow(new AppError('API base URL unavailable'));

	const dataProviderValue = createCrudEntityDataProviderValue<CustomerServiceMarketplaceOrderModel, CustomerServiceMarketplaceOrderEntityStore>(
		customerServiceMarketplaceOrderEntityStoreName,
		customerServiceMarketplaceOrderEntityStoreAccessors,
		primaryKey,
		apiBaseUrl,
		createAccessTokenLoader(auth, env)
	);

	useDataProviderFetchFailedHandler(dataProviderValue, new FetchError(l10n.translate('platformDataProvider.error.orderFetchMessage')));

	useDataProviderFetchInit(
		fetchMode,
		() => dataProviderValue.fetch(forceFetch),
		() => dataProviderValue.resolveActionStatus()
	);

	return useDataProviderFetchSuspense(dataProviderValue, suspense, pendingComponent).getOrDefault(
		<customerServiceMarketplaceOrderEntityData.Provider value={dataProviderValue}>
			{props.children}
		</customerServiceMarketplaceOrderEntityData.Provider>
	);

}
