import { removeWhitespace } from '@abb-emobility/shared/util';

export const formatCreditcardNumber = (ccNumber: string, separator = ' ') => {
	ccNumber = removeWhitespace(ccNumber);
	const formattedCcNumberParts: Array<string> = [];
	for (let i = 0; i < ccNumber.length; i += 4) {
		formattedCcNumberParts.push(ccNumber.substring(i, i + 4));
	}
	return formattedCcNumberParts.join(separator);
};
