/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	Dto,
	ArrayOfTimestampFieldNames,
	ModelConverter,
	ModelConverterInterface,
	ModelPrimaryKey,
	Timestamp,
	TimestampFieldNames
} from '@abb-emobility/shared/domain-model-foundation';

import {
	isCustomerServiceOrderPaymentBankAccount,
	isCustomerServiceOrderPaymentCreditCard
} from './CustomerServiceOrderPaymentModel.guards';

export enum PaymentStatus {
	SETTLEMENT_PENDING = 'SETTLEMENT_PENDING',
	SUCCESS = 'SUCCESS',
	FAILED = 'FAILED'
}

export type CustomerServiceOrderPaymentCreditCardModel = {
	readonly cardOwner: string,
	readonly cardNumber: string,
	readonly expiryDate: string
};

class CustomerServiceOrderPaymentCreditCardModelConverter extends ModelConverter<CustomerServiceOrderPaymentBankAccountModel> {
}

export type CustomerServiceOrderPaymentBankAccountModel = {
	readonly accountOwner: string,
	readonly iban: string
};

class CustomerServiceOrderPaymentBankAccountModelConverter extends ModelConverter<CustomerServiceOrderPaymentBankAccountModel> {
}

export type CustomerServiceOrderPaymentModel = {
	readonly id: ModelPrimaryKey,
	readonly orderId: ModelPrimaryKey,

	readonly checkoutDate: Timestamp,
	readonly settlementDate?: Timestamp,
	readonly status: PaymentStatus,
	readonly errorMessage?: string,

	readonly details?: CustomerServiceOrderPaymentCreditCardModel | CustomerServiceOrderPaymentBankAccountModel
};

export class CustomerServiceOrderPaymentModelConverter extends ModelConverter<CustomerServiceOrderPaymentModel> {

	override getTimestampFields(): Array<TimestampFieldNames<CustomerServiceOrderPaymentModel> | ArrayOfTimestampFieldNames<CustomerServiceOrderPaymentModel>> {
		return ['checkoutDate', 'settlementDate'];
	}

	override getFieldConverterMapByModel(
		model: Dto<CustomerServiceOrderPaymentModel> | CustomerServiceOrderPaymentModel
	): Map<keyof CustomerServiceOrderPaymentModel, ModelConverterInterface<any>> {
		if (isCustomerServiceOrderPaymentCreditCard(model.details)) {
			return new Map([['details', new CustomerServiceOrderPaymentCreditCardModelConverter()]]);
		}
		if (isCustomerServiceOrderPaymentBankAccount(model.details)) {
			return new Map([['details', new CustomerServiceOrderPaymentBankAccountModelConverter()]]);
		}
		return new Map();
	}

}
