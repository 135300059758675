import {
	CustomerServiceOrderPaymentApiClientFactory,
	CustomerServiceOrderPaymentApiClientInterface
} from '@abb-emobility/platform/api-integration';
import { CustomerServiceOrderPaymentModel } from '@abb-emobility/platform/domain-model';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import {
	createCrudCreateThunk,
	createCrudCollectionSlice,
	createCrudDeleteThunk,
	createCrudFetchAllThunk,
	createCrudFetchNextThunk,
	createCrudFetchCollectionEntityThunk,
	createCrudMutateThunk,
	createCrudSelectCollection,
	createCrudSelectCollectionEntity,
	createCrudSelectCreatedEntity,
	CrudCollectionStore,
	CrudCollectionStoreAccessors,
	ThunkApiConfig,
	createCrudCountCollection,
	createCrudRefetchAllThunk,
	createCrudFetchAllSortedThunk,
	createCrudFetchAllFilteredThunk,
	createCrudRefetchCollectionEntityThunk,
	createCrudCollectionStoreSize,
	createCrudCollectionStoreEntryIds
} from '@abb-emobility/shared/data-provider-foundation';
import { Nullable } from '@abb-emobility/shared/util';

// Basic definition
export const customerServiceOrderPaymentCollectionStoreName = 'customer-service-order-payment-collection-crud';
export type CustomerServiceOrderPaymentCollectionStore = { [customerServiceOrderPaymentCollectionStoreName]: CrudCollectionStore<CustomerServiceOrderPaymentModel> };

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the
const createApiClient = (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) => {
	return CustomerServiceOrderPaymentApiClientFactory.create(apiBaseUrl, jsonWebToken);
};
const fetchAllThunk = createCrudFetchAllThunk<CustomerServiceOrderPaymentModel, CustomerServiceOrderPaymentCollectionStore, CustomerServiceOrderPaymentApiClientInterface>(customerServiceOrderPaymentCollectionStoreName, createApiClient);
const refetchAllThunk = createCrudRefetchAllThunk<CustomerServiceOrderPaymentModel, CustomerServiceOrderPaymentCollectionStore, CustomerServiceOrderPaymentApiClientInterface>(customerServiceOrderPaymentCollectionStoreName, createApiClient);
const fetchAllSortedThunk = createCrudFetchAllSortedThunk<CustomerServiceOrderPaymentModel, CustomerServiceOrderPaymentCollectionStore, CustomerServiceOrderPaymentApiClientInterface>(customerServiceOrderPaymentCollectionStoreName, createApiClient);
const fetchAllFilteredThunk = createCrudFetchAllFilteredThunk<CustomerServiceOrderPaymentModel, CustomerServiceOrderPaymentCollectionStore, CustomerServiceOrderPaymentApiClientInterface>(customerServiceOrderPaymentCollectionStoreName, createApiClient);
const fetchNextThunk = createCrudFetchNextThunk<CustomerServiceOrderPaymentModel, CustomerServiceOrderPaymentCollectionStore, CustomerServiceOrderPaymentApiClientInterface>(customerServiceOrderPaymentCollectionStoreName, createApiClient);
const fetchEntityThunk = createCrudFetchCollectionEntityThunk<CustomerServiceOrderPaymentModel, CustomerServiceOrderPaymentCollectionStore, CustomerServiceOrderPaymentApiClientInterface>(customerServiceOrderPaymentCollectionStoreName, createApiClient);
const refetchEntityThunk = createCrudRefetchCollectionEntityThunk<CustomerServiceOrderPaymentModel, CustomerServiceOrderPaymentCollectionStore, CustomerServiceOrderPaymentApiClientInterface>(customerServiceOrderPaymentCollectionStoreName, createApiClient);
const mutateThunk = createCrudMutateThunk<CustomerServiceOrderPaymentModel, CustomerServiceOrderPaymentApiClientInterface>(customerServiceOrderPaymentCollectionStoreName, createApiClient);
const createThunk = createCrudCreateThunk<CustomerServiceOrderPaymentModel, CustomerServiceOrderPaymentApiClientInterface>(customerServiceOrderPaymentCollectionStoreName, createApiClient);
const deleteThunk = createCrudDeleteThunk<CustomerServiceOrderPaymentModel, CustomerServiceOrderPaymentApiClientInterface>(customerServiceOrderPaymentCollectionStoreName, createApiClient);

// Slice definition
export const customerServiceOrderPaymentCollectionSlice = createCrudCollectionSlice<CustomerServiceOrderPaymentModel, ThunkApiConfig>(
	customerServiceOrderPaymentCollectionStoreName,
	fetchAllThunk,
	refetchAllThunk,
	fetchAllSortedThunk,
	fetchAllFilteredThunk,
	fetchNextThunk,
	fetchEntityThunk,
	refetchEntityThunk,
	mutateThunk,
	createThunk,
	deleteThunk
);
const { resolveFetchStatus, resolveActionStatus, resetStore } = customerServiceOrderPaymentCollectionSlice.actions;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
const selectCollection = createCrudSelectCollection<CustomerServiceOrderPaymentModel, CustomerServiceOrderPaymentCollectionStore>(customerServiceOrderPaymentCollectionStoreName);
const countCollection = createCrudCountCollection<CustomerServiceOrderPaymentModel, CustomerServiceOrderPaymentCollectionStore>(customerServiceOrderPaymentCollectionStoreName);
const selectEntity = createCrudSelectCollectionEntity<CustomerServiceOrderPaymentModel, CustomerServiceOrderPaymentCollectionStore>(customerServiceOrderPaymentCollectionStoreName);
const selectCreated = createCrudSelectCreatedEntity<CustomerServiceOrderPaymentModel, CustomerServiceOrderPaymentCollectionStore>(customerServiceOrderPaymentCollectionStoreName);
const storeSize = createCrudCollectionStoreSize<CustomerServiceOrderPaymentModel, CustomerServiceOrderPaymentCollectionStore>(customerServiceOrderPaymentCollectionStoreName);
const storeEntryIds = createCrudCollectionStoreEntryIds<CustomerServiceOrderPaymentModel, CustomerServiceOrderPaymentCollectionStore>(customerServiceOrderPaymentCollectionStoreName);

// Export the store accessors to be used by the data provider value
export const customerServiceOrderShipmentCollectionStoreAccessors = {
	fetchAll: fetchAllThunk,
	refetchAll: refetchAllThunk,
	fetchAllSorted: fetchAllSortedThunk,
	fetchAllFiltered: fetchAllFilteredThunk,
	fetchNext: fetchNextThunk,
	fetchEntity: fetchEntityThunk,
	refetchEntity: refetchEntityThunk,
	create: createThunk,
	mutate: mutateThunk,
	delete: deleteThunk,
	resolveFetchStatus,
	resolveActionStatus,
	selectCollection,
	countCollection,
	selectEntity,
	selectCreated,
	resetStore,
	storeSize,
	storeEntryIds
} as CrudCollectionStoreAccessors<CustomerServiceOrderPaymentModel, CustomerServiceOrderPaymentCollectionStore>;
