import React from 'react';

import { useL10n } from '@abb-emobility/shared/localization-provider';

import './SearchbarResultsEmpty.scss';

export const SearchbarResultsEmpty = () => {
	const l10n = useL10n();

	return (
		<section className="searchbar-results-empty">
			{l10n.translate('sharedUiPrimitive.search.noResults')}
		</section>
	);
};
