import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	platformCustomerServiceApp: {
		action: {
			showCustomer: 'Show Customer'
		},
		actionItemFactory: {
			addCustomerNoteLabel: 'Add Note',
			addOrderNoteLabel: 'Add Note',
			cancelOrderLabel: 'Cancel',
			cancelInstallationLabel: 'Cancel installation',
			cancelOrderAppointmentsLabel: 'Cancel Appointments',
			externalLinkLabel: 'External Link',
			resendMailLabel: 'Resend Mail',
			returnOrderLabel: 'Return',
			replaceOrderLabel: 'Replace',
			updateCustomerLabel: 'Change Data',
			updateOrderLabel: 'Change Data'
		},
		actionModalFactory: {
			addCustomerNoteHeading: 'Add Note',
			addOrderNoteHeading: 'Add Note',
			cancelOrderHeading: 'Cancel Order',
			cancelInstallationHeading: 'Installation stornieren',
			cancelOrderAppointmentsHeading: 'Cancel Order Appointments',
			resendMailHeading: 'Resend Mail',
			returnOrderHeading: 'Return Order',
			replaceOrderHeading: 'Replace Order',
			updateCustomerHeading: 'Change Customer Data',
			updateOrderHeading: 'Change Order Data'
		},
		actionModal: {
			pleaseSelect: 'Please select',
			addNote: {
				content: {
					category: 'Category',
					categories: {
						order: 'Order Note',
						customer: 'Customer Note',
						customerCall: 'Customer Call'
					}
				}
			},
			addOrderNote: {
				content: {
					noteHeading: 'Note',
					notePlaceholder: 'Insert Text',
					successHeading: 'Successfully created Order Note',
					closeButtonLabel: 'Close'
				},
				footer: {
					confirm: 'Confirm',
					cancel: 'Cancel'
				},
				error: {
					heading: 'Could not create order note.',
					message: 'An Error occured while creating the note.'
				}
			},
			addCustomerNote: {
				content: {
					noteHeading: 'Note',
					notePlaceholder: 'Insert Text',
					successHeading: 'Successfully created Customer Note',
					closeButtonLabel: 'Close'
				},
				footer: {
					confirm: 'Confirm',
					cancel: 'Cancel'
				},
				error: {
					heading: 'Could not create customer note.',
					message: 'An Error occured while creating the customer note.'
				}
			},
			addCustomerCallNote: {
				content: {
					noteHeading: 'Note',
					notePlaceholder: 'Insert Text',
					customerCallReason: 'Reason of Customer Call',
					callReasonOptions: {
						afterSales: 'After Sales',
						product: 'Product',
						installation: 'Installation',
						preCheck: 'Pre-Check',
						payment: 'Payment',
						dataProtection: 'DataProtection',
						shipment: 'Shipment',
						cancellation: 'Returns',
						falseRouting: 'False Routing',
						other: 'Other',
						complaints: 'Complaints',
						returns: 'Returns'
					},
					callReasonNotePlaceholder: 'Description',
					successHeading: 'Successfully created Customer Call Note',
					closeButtonLabel: 'Close'
				},
				footer: {
					confirm: 'Confirm',
					cancel: 'Cancel'
				},
				error: {
					heading: 'Could not create customer call note.',
					message: 'An Error occured while creating the customer call note.'
				}
			},
			cancelOrder: {
				heading: 'Order',
				position: 'Position',
				description: 'Description',
				reason: 'Reason',
				note: 'Description',
				cancelReasonValue: {
					other: 'Other',
					wallboxBroken: 'Wallbox broken',
					wallboxNotWorking: 'Wallbox not working',
					withdrawal: 'Withdrawal',
					installationNotPossible: 'Installation not possible'
				},
				footer: {
					cancel: 'Cancel',
					confirm: 'Confirm'
				},
				content: {
					successHeading: 'Successfully cancelled the order',
					closeButtonLabel: 'Close'
				},
				error: {
					loading: {
						heading: 'Could not load the order data.',
						message: 'An Error occured while loading the order data.'
					},
					writing: {
						heading: 'Could not cancel order.',
						message: 'An Error occured while cancelling the order.'
					}
				}
			},
			cancelInstallation: {
				heading: 'Order',
				position: 'Position',
				description: 'Description',
				reason: 'Reason',
				note: 'Description',
				cancelReasonValue: {
					other: 'Other',
					wallboxBroken: 'Wallbox broken',
					wallboxNotWorking: 'Wallbox not working',
					withdrawal: 'Withdrawal',
					installationNotPossible: 'Installation not possible'
				},
				footer: {
					cancel: 'Cancel',
					confirm: 'Confirm'
				},
				content: {
					successHeading: 'Successfully cancelled the installation',
					closeButtonLabel: 'Close'
				},
				error: {
					loading: {
						heading: 'Could not load the order data.',
						message: 'An Error occured while loading the order data.'
					},
					writing: {
						heading: 'Could not cancel installation.',
						message: 'An Error occured while cancelling the installation.'
					}
				}
			},
			cancelOrderAppointments: {
				content: {
					notificationHeading: 'The Installation Partner will be informed about the appointment cancellation.',
					notificationMessage: 'The Installation Partner will contact the customer to arrange a new appointment.',
					successHeading: 'Appointments cancelled',
					successCloseButtonLabel: 'Close',
					reason: 'Reason'
				},
				cancelReasonValue: {
					deliveryDelay: 'Delivery Delay',
					installationPartnerConflict: 'Installation Partner Conflict',
					customerConflict: 'Customer Conflict',
					replacement: 'Replacement'
				},
				footer: {
					confirm: 'Confirm Cancellation',
					cancel: 'Cancel'
				},
				error: {
					heading: 'Could not cancel appointments.',
					message: 'An Error occured while deleting the order appointments.'
				}
			},
			resendMail: {
				content: {
					notificationHeading: 'The e-mail will be resend.',
					notificationMessage: 'The customer will receive a new e-mail.',
					successHeading: 'Successfully sent e-mail.',
					successCloseButtonLabel: 'Close'
				},
				footer: {
					confirm: 'Resend Email',
					cancel: 'Cancel'
				},
				error: {
					heading: 'Could not resend E-Mail.',
					message: 'An Error occured while sending the e-mail.'
				}
			},
			returnOrder: {
				heading: 'Order',
				position: 'Position',
				description: 'Description',
				note: 'Description',
				return: 'Return',
				selectReturn: 'Select return items',
				selectReason: 'Select return reason',
				replacementItem: 'Replacement item',
				yes: 'Yes',
				no: 'No',
				returnReason: {
					other: 'Other',
					broken: 'Broken'
				},
				footer: {
					cancel: 'Cancel',
					confirm: 'Confirm'
				},
				content: {
					successHeading: 'Successfully initiated the return process',
					closeButtonLabel: 'Close'
				},
				error: {
					loading: {
						heading: 'Could not load the order data.',
						message: 'An Error occured while loading the order data.'
					},
					writing: {
						heading: 'Could not initiate the return process.',
						message: 'An Error occured while invoking the return order process.'
					}
				}
			},
			replaceOrder: {
				heading: 'Order',
				position: 'Position',
				description: 'Description',
				note: 'Description',
				return: 'Replace',
				selectReturn: 'Select replace items',
				selectReason: 'Select replace reason',
				returnReason: {
					other: 'Other',
					broken: 'Broken'
				},
				footer: {
					cancel: 'Cancel',
					confirm: 'Confirm'
				},
				content: {
					successHeading: 'Successfully initiated the replacement process',
					closeButtonLabel: 'Close'
				},
				error: {
					loading: {
						heading: 'Could not load the order data.',
						message: 'An Error occured while loading the order data.'
					},
					writing: {
						heading: 'Could not initiate the replacement process.',
						message: 'An Error occured while invoking the replacement order process.'
					}
				}
			},
			updateCustomer: {
				content: {
					firstname: 'First name',
					lastname: 'Last name',
					email: 'E-Mail',
					personalInfoHeading: 'Personal information',
					contactDataHeading: 'Contact data',
					successHeading: 'User information successfully changed.',
					successCloseButtonLabel: 'Close'
				},
				footer: {
					confirm: 'Confirm',
					cancel: 'Cancel'
				},
				error: {
					loading: {
						heading: 'Could not load customer information.',
						message: 'An Error occured while loading the customer information.'
					},
					writing: {
						heading: 'Could not update customer information.',
						message: 'An Error occured while updating the customer infromation.'
					}
				}
			},
			updateOrder: {
				content: {
					orderInformationHeading: 'Order information',
					phoneLabel: 'Phone',
					languageHeading: 'Language',
					languageLabel: ' Language Code',
					successHeading: 'Order information successfully changed.',
					successCloseButtonLabel: 'Close'
				},
				footer: {
					confirm: 'Confirm',
					cancel: 'Cancel'
				},
				error: {
					loading: {
						heading: 'Could not load order information.',
						message: 'An Error occured while loading the order information.'
					},
					writing: {
						heading: 'Could not update order information.',
						message: 'An Error occured while updating the order infromation.'
					}
				}
			}
		},
		appName: 'ABB customer service',
		breadcrumbs: {
			tasks: 'Task overview',
			orders: 'Order overview',
			marketplaceOrders: 'Marketplace order overview',
			customers: 'Customer Overview'
		},
		comment: {
			categories: {
				order: 'Order Note',
				customer: 'Customer Note',
				customerCall: 'Customer Call'
			},
			callReason: {
				afterSales: 'After Sales',
				product: 'Product',
				installation: 'Installation',
				preCheck: 'Pre-Check',
				payment: 'Payment',
				dataProtection: 'DataProtection',
				shipment: 'Shipment',
				cancellation: 'Returns',
				falseRouting: 'False Routing',
				other: 'Other',
				complaints: 'Complaints',
				returns: 'Returns'
			}
		},
		commentCollection: {
			heading: 'Notes',
			emptyState: {
				heading: 'No comments',
				message: 'There are currently no comments available.'
			}
		},
		common: {
			tableHeader: {
				position: 'Position',
				description: 'Description'
			}

		},
		customerCollection: {
			heading: 'Customers',
			tableHead: {
				customer: 'Customer',
				customerId: 'Customer ID',
				firstOrder: 'First Order',
				lastOrder: 'Last Order'
			},
			emptyState: {
				heading: 'No customers found',
				message: 'Adjust your filter settings to find some customers.'
			},
			action: {
				showMore: 'Show more'
			}
		},
		customerInformation: {
			heading: 'Customer Information',
			tableHeader: {
				position: 'Position',
				description: 'Description'
			},
			tableItem: {
				firstName: 'First name',
				lastName: 'Last name',
				companyName: 'Company name',
				mail: 'Mail',
				firstOrder: 'First order',
				lastOrder: 'Last order'
			}
		},
		customerOrders: {
			heading: 'Customer orders'
		},
		customerMarketplaceOrders: {
			heading: 'Marketplace orders'
		},
		customerDetail: {
			subheader: {
				customerId: 'Customer ID'
			},
			tabItems: {
				overview: 'Overview',
				comments: 'Notes'
			}
		},
		error: {
			generic: {
				heading: 'Something went wrong',
				message: 'An unforeseen error occurred that was not resolvable',
				resolveAction: {
					label: 'Reload'
				}
			},
			offline: {
				heading: 'Network connection unavailable',
				message: 'Please connect to a network to use the application.'
			},
			notFound: {
				heading: 'Not found',
				message: 'The requested ressource was not found',
				resolveAction: {
					label: 'My Tasks'
				}
			},
			customerNotFound: 'The requested customer was not found',
			orderNotFound: 'The requested order was not found',
			taskNotFound: 'The requested task was not found',
			callerNotFound: 'No caller information was found',
			taskAssignMessage: 'While assigning the task an error occurred.',
			taskCompleteMessage: 'While completing the task an error occurred.'
		},
		navigation: {
			tasks: 'My Tasks',
			orders: 'Orders',
			marketplaceOrders: 'Marketplace Orders',
			customers: 'Customers',
			logout: 'Logout'
		},
		order: {
			installationType: {
				undetermined: 'Undetermined',
				standard: 'Standard',
				delta: 'Delta',
				cancelled: 'Cancelled'
			}
		},
		orderDetail: {
			subheader: {
				orderDate: 'Order Date',
				orderId: 'Order ID',
				marketplace: 'Marketplace',
				plannedDelivery: 'Planned Delivery',
				orderState: {
					onSchedule: 'On Schedule',
					delayed: 'Delayed',
					cancelPending: 'Cancel pending',
					cancelled: 'Cancelled',
					imported: 'Imported',
					validationFailed: 'Invalid',
					done: 'Done'
				}
			},
			tabItems: {
				overview: 'Overview',
				installation: 'Installation',
				delivery: 'Delivery',
				accounting: 'Accounting',
				files: 'Files',
				comments: 'Notes',
				tasks: 'Tasks'
			},
			overview: {
				customerInformation: {
					heading: 'Customer Information'
				},
				orderInformation: {
					heading: 'Order Information',
					tableHeader: {
						position: 'Position',
						description: 'Description'
					},
					tableItem: {
						language: 'Language',
						phone: 'Phone',
						deliveryAddress: 'Address'
					}
				},
				timeline: {
					heading: 'Timeline'
				}
			},
			installation: {
				cancellation: {
					notification: {
						heading: 'The installation has been cancelled',
						message: 'The cancellation was carried out on {{cancellationDate}}. The reason given was "{{cancellationReason}}".'
					}
				},
				information: {
					heading: 'Information',
					types: {
						basicAnswers: 'Basic Answers',
						gridOperatorRegistration: 'Grid operator registration',
						gridOperatorDecision: 'Grid operator decision',
						preCheck: 'PreCheck',
						offer: 'Offer',
						approval: 'Approval'
					},
					emptyState: {
						heading: 'No order information available',
						message: 'There is no order information available.'
					}
				},
				installationAppointments: {
					heading: 'Planned Installations',
					installer: 'Installer',
					appointment: 'Appointment',
					phone: 'Phone',
					mail: 'E-Mail',
					emptyState: {
						heading: 'No appointments found.',
						message: 'There are no planned appointments.'
					}
				}
			},
			delivery: {
				orderItem: 'Order item',
				productCode: 'Product code',
				address: {
					heading: 'Delivery Address',
					name: 'Name',
					street: 'Street',
					location: 'City'
				},
				details: {
					heading: 'Delivery Details',
					shippingDate: 'Shipping date',
					address: 'Shipping address',
					trackingCode: 'Tracking code',
					state: 'Shipping state',
					partner: 'Shipping partner',
					tracking: 'Tracking',
					shippingState: {
						delivered: 'Delivered',
						pending: 'Pending'
					},
					estimatedDeliveryDate: 'Estimated delivery date',
					deliveryDate: 'Delivery date'
				},
				retoureDetails: {
					heading: 'Retoure Details',
					date: 'Request date',
					address: 'Return address',
					reason: 'Return reason',
					returnNote: 'Return note',
					created: 'Created at'
				},
				replacementDetails: {
					heading: 'Replacement Delivery Details'
				},
				emptyState: {
					heading: 'No shipment information available',
					message: 'There is currently no further information about the shipment.'
				}
			},
			accounting: {
				heading: 'Transaction',
				date: 'Payment date',
				state: 'State',
				notification: 'Error message',
				cardOwner: 'Card owner',
				cardNumber: 'Card number',
				cardDueDate: 'Card due date',
				accountOwner: 'Account owner',
				accountNumber: 'IBAN',
				paymentState: {
					success: 'Success',
					failed: 'Failed',
					settlementPending: 'Settlement pending'
				},
				emptyPaymentState: {
					heading: 'No payment information available',
					message: 'There is currently no further information about payments.'
				},
				emptyDownloadFilesState: {
					heading: 'No download files available',
					message: 'There are currently no download files available.'
				}
			},
			files: {
				emptyState: {
					heading: 'No download files available',
					message: 'There are currently no download files available.'
				}
			},
			throttleWallboxHint: {
				caption: 'Attention',
				message: 'The power consumption of the wallbox has to get reduced according to the requirements of the grid operator.'
			},
			informationList: {
				heading: 'Information',
				emptyState: {
					heading: 'No order information available yet',
					message: 'As the order progresses, you will find all relevant information about the order here.'
				},
				caption: {
					basicAnswers: 'Basic Answers',
					gridOperatorRegistration: 'Grid operator registration',
					gridOperatorDecision: 'Grid operator decision',
					preCheck: 'PreCheck',
					offer: 'Offer',
					approval: 'Approval'
				},
				precheck: {
					heading: 'Precheck',
					effort: 'Precheck results: Installation appointments and effort',
					questionnaire: {
						heading: 'Customer answers'
					},
					installationType: {
						undetermined: 'Undetermined',
						standard: 'Standard installation',
						delta: 'Delta installation'
					},
					decision: {
						accepted: 'Information sufficient',
						more: 'More information needed',
						denied: 'Installation not posible'
					},
					notes: 'Notes',
					hours: 'hours'
				},
				decision: {
					heading: 'Grid Operator Decision',
					decision: {
						pending: 'The installation request is still pending',
						accepted: 'The grid operator agreed with the installation',
						denied: 'The grid operator declined the installation'
					}
				},
				registration: {
					heading: 'Grid Operator Registration',
					documents: 'Documents',
					gridOperator: 'Grid operator'
				},
				quotation: {
					heading: 'Quotation',
					conditions: 'Offer conditions'
				},
				complete: {
					success: {
						heading: 'Installation successful'
					},
					notes: 'Notes',
					images: 'Images related to the installation',
					documents: 'Documents related to the installation',
					signature: 'Signature client'
				}
			}
		},
		orderEventTimeline: {
			emptyState: {
				heading: 'No events found',
				message: 'There are no events available for this order, yet.'
			}
		},
		timelineItemDetails: {
			mailAddress: {
				label: 'Mail'
			},
			section: {
				payment: 'Payments',
				appointments: 'Installation appointments'
			}
		},
		orderEvent: {
			type: {
				orderReceived: 'Order received',
				orderAddressValidationSucceeded: 'Order address validation succeeded',
				orderAddressValidationFailed: 'Order address validation failed',
				orderAddressCorrected: 'Order address corrected',
				accountingOrderCreated: 'Accounting order created',
				orderConfirmationMailSent: 'Order confirmation mail sent',
				basicAnswersReceived: 'Basic answers received',
				gridOperatorRegistrationStarted: 'Grid operator registration started',
				gridOperatorFeedbackReceived: 'Grid operator feedback received',
				gridOperatorRejectionNotificationSent: 'Grid operator rejection notification',
				basicAnswersEvaluated: 'Basic answers evaluated',
				precheckPreferencesMailSent: 'Precheck preferences mail sent',
				remotePrecheckExecuted: 'Remote precheck executed',
				deltaQuotationCreated: 'Delta quotation created',
				deltaQuotationMailSent: 'Delta quotation mail sent',
				deltaQuotationAccepted: 'Delta quotation accepted',
				paymentSucceeded: 'Payment succeeded',
				paymentFailedOrTimedOut: 'Payment failed or timedout',
				paymentReminderMailSent: 'Payment reminder mail sent',
				paymentConfirmationMailSent: 'Payment confirmation mail sent',
				appointmentArranged: 'Appointment arranged',
				appointmentArrangedNotificationSent: 'Appointment arranged notification sent',
				shippingConfirmed: 'Shipping confirmed',
				installationConfirmed: 'Installation confirmed',
				replacementInstallationConfirmed: 'Replacement installation confirmed',
				installationApprovalMailSent: 'Installation approval mail sent',
				invoiceCreated: 'Invoice created',
				invoiceNotificationSent: 'Invoice notification sent',
				orderCancelled: 'Order cancelled',
				gridOperatorRegistrationRevoked: 'Grid operator rejection revoked',
				customerCallReceived: 'Customer call received',
				returnOrReplacementShipmentReceived: 'Return / Replacement shipment received',
				returnInitiated: 'Return initiated',
				replacementInitiated: 'Replacement initiated',
				cancellationInitiated: 'Cancellation initiated',
				orderNoteCreated: 'Order note created',
				refundCompleted: 'Refund completed',
				installationCancellationMailSent: 'Installation cancellation notification sent'
			},
			category: {
				accounting: 'Accounting',
				customer: 'Customer',
				customerService: 'Customer service',
				deltaInstallation: 'Delta installation',
				gridOperator: 'Grid operator',
				installation: 'Installation',
				order: 'Order',
				payment: 'Payment',
				preCheck: 'Precheck',
				shipping: 'Shipping'
			}
		},
		marketplaceOrder: {
			orderState: {
				paymentPending: 'Payment Pending',
				waitingForFulfillment: 'Awaiting Fulfillment',
				fulfillmentStarted: 'Fulfillment Started',
				cancelled: 'Cancelled',
				done: 'Done'
			}
		},
		orderCollection: {
			heading: 'Orders',
			tableHead: {
				order: 'Order',
				status: 'Status',
				language: 'Language',
				plannedDeliveryDate: 'Planned Delivery',
				createdAt: 'Created At'
			},
			emptyState: {
				heading: 'No orders found',
				message: 'Adjust your filter settings to find some orders.'
			},
			action: {
				refresh: 'Refresh',
				showMore: 'Show more'
			},
			orderState: {
				onSchedule: 'On Schedule',
				delayed: 'Delayed',
				cancelPending: 'Cancel pending',
				cancelled: 'Cancelled',
				imported: 'Imported',
				validationFailed: 'Invalid',
				done: 'Done'
			}
		},
		customerOrderCollection: {
			emptyState: {
				heading: 'No orders found',
				message: 'No orders were found for this customer.'
			}
		},
		orderLineItemCollection: {
			heading: 'Order',
			position: 'Position',
			description: 'Description',
			price: 'Price',
			productCode: 'Product code',
			totalPrice: 'Total Price'
		},
		inboundOrderCollection: {
			heading: 'Orders associated with the phone number {{caller}}',
			emptyState: {
				heading: 'No orders found',
				message: 'Maybe you want to try a search instead.',
				searchLabel: 'Search'
			},
			action: {
				showMore: 'Show more'
			}
		},
		searchbar: {
			placeholder: 'Search',
			results: {
				orders: 'Orders',
				marketplaceOrders: 'Marketplace Orders',
				customers: 'Customers',
				showResultPage: 'Show results page',
				showResultPageDescription: {
					resultsFromTo: 'Result 1 to',
					of: 'of'
				}
			}
		},
		searchResultCollection: {
			heading: 'Search Results',
			headingWithSearchterm: 'Search Results for',
			headingCustomers: 'Customers',
			headingOrders: 'Orders',
			headingMarketplaceOrders: 'Marketplace',
			emptyState: {
				heading: 'No search results found',
				message: 'Adjust your search term and try again.'
			},
			idleState: {
				heading: 'No search term provided',
				message: 'Please enter a search term.'
			},
			action: {
				showMore: 'Show more'
			}
		},
		task: {
			assign: {
				successMessage: 'Task assigned'
			},
			error: {
				taskNotFound: 'Task not found',
				taskTypeNotFound: 'Task type not found'
			},
			gridOperatorRegistration: {
				continueToFeedback: {
					buttonLabel: 'Continue'
				}
			},
			basicAnswers: {
				continueToQuotation: {
					buttonLabel: 'Continue'
				},
				button: {
					close: 'Close',
					submit: 'Submit'
				},
				questionnaire: {
					heading: 'Basic answers'
				}
			},
			completion: {
				successHeading: 'Task successfully completed',
				closeButtonLabel: 'Close'
			},
			preCheck: {
				button: {
					close: 'Close',
					submit: 'Submit'
				},
				contact: {
					description: 'Contact'
				}
			},
			gridOperator: {
				button: {
					close: 'Close',
					submit: 'Submit'
				}
			},
			quotation: {
				button: {
					close: 'Close',
					submit: 'Submit'
				},
				deltaPackages: {
					totalPurchaseNetPrice: 'Total purchase price (net)',
					totalSalesGrossPrice: 'Total sales price (including {{vatRate}}% VAT)',
					purchaseNetPrice: 'Purchase price (net)',
					salesGrossPrice: 'Sales price (gross)'
				}
			},
			payment: {
				button: {
					close: 'Close',
					submit: 'Submit'
				}
			},
			appointment: {
				button: {
					close: 'Close',
					submit: 'Submit'
				}
			},
			installation: {
				button: {
					close: 'Close',
					submit: 'Submit'
				}
			}
		},
		taskCollection: {
			heading: 'Tasks',
			tableHead: {
				task: 'Task',
				status: 'Status',
				language: 'Language'
			},
			action: {
				refresh: 'Refresh',
				showMore: 'Show more'
			},
			assign: {
				successMessage: 'The task was assigned successful',
				errorMessage: 'While assigning the task an error occurred.'
			},
			emptyState: {
				heading: 'No tasks found',
				message: 'Adjust your filter settings to find some tasks.'
			},
			orderEmptyState: {
				heading: 'No tasks found',
				message: 'Nothing needs to be done for this order.'
			}
		},
		taskCollectionItem: {
			type: {
				customerAnswerBasicQuestionsEscalation: 'Escalation: Basic Question',
				customerProvidePreCheckPreferencesEscalation: 'Escalation: Pre-Check',
				customerHandleUnfeasibleInstallationEscalation: 'Escalation: Unfeasible Installation',
				customerHandleGridOperatorRejectionEscalation: 'Escalation: Grid Operator Rejection',
				customerAcceptQuotationEscalation: 'Escalation: Quotation',
				customerPerformPaymentEscalation: 'Escalation: Payment',
				customerApproveInstallationEscalation: 'Escalation: Approve Installation',
				customerAddressValidationEscalation: 'Address Validation',
				customerUncoveredInstallationAddress: 'Uncovered Installation Address',
				installationPartnerEvaluateBasicAnswersEscalation: 'Escalation: Basic Answers',
				installationPartnerExecuteRemotePreCheckEscalation: 'Escalation: Execute Remote Pre-Check',
				installationPartnerRegisterAtGridOperatorEscalation: 'Escalation: Register at Grid Operator',
				installationPartnerProvideGridOperatorDecisionEscalation: 'Escalation: Provide Grid Operator',
				installationPartnerProvideQuotationEscalation: 'Escalation: Provide Quotation',
				installationPartnerArrangeAppointmentEscalation: 'Escalation: Arrange Appointments',
				installationPartnerCompleteInstallationEscalation: 'Escalation: Complete Installation'
			},

			status: {
				started: 'Upcoming',
				dueToday: 'Due today',
				overdue: 'Overdue',
				escalated: 'Escalated',
				done: 'Done',
				doneOverdue: 'Done',
				doneEscalated: 'Done'
			},
			category: {
				customer: 'Customer',
				installationPartner: 'Install. Partner'
			}
		},
		pageTitle: {
			tasks: 'Task overview',
			orders: 'Order Overview',
			order: 'Order Detail',
			marketplaceOrders: 'Marketplace Order Overview',
			marketplaceOrder: 'Marketplace Order Detail',
			customers: 'Customer Overview',
			error: 'Error',
			notFound: 'Not found',
			inbound: 'Incoming call {{caller}}'
		},
		viewHeader: {
			tasks: 'Task overview',
			orders: 'Order Overview',
			marketplaceOrders: 'Marketplace Order Overview',
			customers: 'Customer Overview',
			inbound: 'Incoming call from {{caller}}'
		},
		modal: {
			task: {
				section: {
					customerInformation: {
						heading: 'Customer',
						position: 'Position',
						description: 'Description',
						name: 'Name',
						address: 'Delivery address',
						phone: 'Phone number',
						mail: 'Email'
					},
					installationEffortInformation: {
						heading: 'Installation effort',
						trade: 'Trade',
						effort: 'Effort',
						hours: 'hours'
					},
					installationPartnerInformation: {
						heading: 'Installation partner',
						position: 'Position',
						description: 'Description',
						name: 'Name',
						mail: 'Email',
						phone: 'Phone'
					},
					orderInformation: {
						heading: 'Order information'
					},
					quotationInformation: {
						heading: 'Quotation',
						position: 'Position',
						description: 'Description',
						price: 'Price',
						deltaInstallation: 'Decicsion: Delta installation',
						grossPrice: 'Sum',
						vat: 'VAT',
						include: 'include'
					},
					taskInformation: {
						heading: 'Task',
						position: 'Position',
						description: 'Description',
						createdAt: 'Created at',
						dueDate: 'Due date',
						responsible: 'Responsible',
						openTask: 'Open task'
					},
					contactInformation: {
						heading: 'Contact preferences'
					},
					gridOperatorInformation: {
						heading: 'Grid operator information'
					},
					payment: {
						accept: 'Quotation accepted'
					},
					addressValidation: {
						heading: 'Address Validation',
						invalidAddressLabel: {
							delivery: 'Invalid delivery address',
							billing: 'Invalid billing address',
							multiple: 'Invalid delivery and billing address'
						},
						hint: {
							heading: 'Insert a new address for validation',
							message: 'The new address will be re-validated when the task is submitted.'
						},
						error: {
							heading: 'The address validation failed',
							message: 'Please insert the correct address information.'
						},
						newAddress: {
							heading: 'New Address',
							street: 'Street and house number ',
							zip: 'Zip',
							city: 'City',
							country: 'Country',
							region: 'Region'
						}
					},
					uncoveredInstallationAddress: {
						message: 'The customer was informed about the cancellation of the order',
						info: 'Uncovered Installation Address',
						infoHeading: 'Inform the customer',
						infoDescription: 'The customer needs to be informed that the installation address is not covered and that the installation ist cancelled'
					},
					hint: {
						heading: 'Final check',
						unfeasible: 'Decision: Installation not feasible',
						rejected: 'Rejected by Grid operator'
					}
				}
			}
		}
	}
};

export default literals;
