import {
	CustomerServiceOrderEventCollectionModel,
	CustomerServiceOrderEventCollectionModelConverter
} from '@abb-emobility/platform/domain-model';
import { AbstractCrudApiClient } from '@abb-emobility/shared/api-integration-foundation';

export class CustomerServiceOrderEventCollectionApiClient extends AbstractCrudApiClient<CustomerServiceOrderEventCollectionModel> {
	protected collectionControllerUri = 'order-history/';
	protected paginationControllerUri = 'order-history/';
	protected entityControllerUri = 'order-history/{id}/';
	protected entitySearchControllerUri = 'order-history/';
	protected modelConverter = new CustomerServiceOrderEventCollectionModelConverter();
	protected override paginationSize = 200;
}
