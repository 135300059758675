import { useEffect, useState } from 'react';

import { UrlResolver } from '@abb-emobility/shared/api-integration-foundation';
import { useAuth } from '@abb-emobility/shared/auth-provider';
import { DownloadFile } from '@abb-emobility/shared/domain-model-foundation';
import { AppError } from '@abb-emobility/shared/error';
import { Nullable, Optional } from '@abb-emobility/shared/util';

import './OrderDetailDeliveryDownloadLink.scss';

export type OrderDetailDeliveryDownloadLinkProps = {
	file: DownloadFile
};

export function OrderDetailDeliveryDownloadLink(props: OrderDetailDeliveryDownloadLinkProps) {
	const { file } = props;

	const auth = useAuth();
	const documentStoreApiBaseUrl = new Optional(process.env['NX_DOCUMENT_STORE_API_BASE_URL'])
		.getOrThrow(new AppError('No document store API base URL provided'));
	const documentStoreMediaBaseUrl = new Optional(process.env['NX_DOCUMENT_STORE_MEDIA_BASE_URL'])
		.getOrThrow(new AppError('No document store media base URL provided'));
	const [downloadUrl, setDownloadUrl] = useState<Nullable<string>>(null);

	useEffect(() => {
		const urlResolver = new UrlResolver();
		const resolveUrl = async (): Promise<void> => {
			try {
				const resolvedUrl = await urlResolver.resolve(documentStoreApiBaseUrl, documentStoreMediaBaseUrl, file.url, auth.getToken().get());
				setDownloadUrl(resolvedUrl);
			} catch (e) {
				console.error(e);
			}
		};
		void resolveUrl();
		return () => {
			urlResolver.abort();
		};
	}, []);

	return (
		<a href={downloadUrl ?? ''} download={true} className="order-detail-delivery-download-link">
			{file.meta.fileName}
		</a>
	);

}
