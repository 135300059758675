import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	platformCustomerServiceApp: {
		action: {
			showCustomer: 'Afficher le client'
		},
		actionItemFactory: {
			addCustomerNoteLabel: 'Ajouter une note',
			addOrderNoteLabel: 'Ajouter une note',
			cancelOrderLabel: 'Annuler',
			cancelInstallationLabel: 'Annuler l\'installation',
			cancelOrderAppointmentsLabel: 'Annuler des rendez-vous',
			externalLinkLabel: 'Lien externe',
			resendMailLabel: 'Renvoyer le courrier',
			returnOrderLabel: 'Retour',
			replaceOrderLabel: 'Remplacer',
			updateCustomerLabel: 'Modifier les données',
			updateOrderLabel: 'Modifier les données'
		},
		actionModalFactory: {
			addCustomerNoteHeading: 'Ajouter une note',
			addOrderNoteHeading: 'Ajouter une note',
			cancelOrderHeading: 'Annuler la commande',
			cancelInstallationHeading: 'Annuler l\'installation',
			cancelOrderAppointmentsHeading: 'Annuler les rendez-vous de commande',
			resendMailHeading: 'Renvoyer le courrier',
			returnOrderHeading: 'Ordre de retour',
			replaceOrderHeading: 'Ordre de remplacement',
			updateCustomerHeading: 'Modifier les données des clients',
			updateOrderHeading: 'Modifier les données relatives aux commandes'
		},
		actionModal: {
			pleaseSelect: 'Veuillez sélectionner',
			addNote: {
				content: {
					category: 'Catégorie',
					categories: {
						order: 'Note de commande',
						customer: 'Note à l’attention des clients',
						customerCall: 'Appel aux clients'
					}
				}
			},
			addOrderNote: {
				content: {
					noteHeading: 'Note',
					notePlaceholder: 'Insérer un texte',
					successHeading: 'Création réussie de la note de commande',
					closeButtonLabel: 'Fermer'
				},
				footer: {
					confirm: 'Confirmer',
					cancel: 'Annuler'
				},
				error: {
					heading: 'Impossible de créer une note de commande.',
					message: 'Une erreur s’est produite lors de la création de la note.'
				}
			},
			addCustomerNote: {
				content: {
					noteHeading: 'Note',
					notePlaceholder: 'Insérer un texte',
					successHeading: 'Création réussie de la note au client',
					closeButtonLabel: 'Fermer'
				},
				footer: {
					confirm: 'Confirmer',
					cancel: 'Annuler'
				},
				error: {
					heading: 'Impossible de créer une note au client.',
					message: 'Une erreur s’est produite lors de la création de la note au client.'
				}
			},
			addCustomerCallNote: {
				content: {
					noteHeading: 'Note',
					notePlaceholder: 'Insérer un texte',
					customerCallReason: 'Motif de l’appel au client',
					callReasonOptions: {
						afterSales: 'Après-vente',
						product: 'Produit',
						installation: 'Installation',
						preCheck: 'Pré-contrôle',
						payment: 'Paiement',
						dataProtection: 'Protection des données',
						shipment: 'Expédition',
						cancellation: 'Retours',
						falseRouting: 'Faux routage',
						other: 'Autre',
						complaints: 'Plaintes',
						returns: 'Retours'
					},
					callReasonNotePlaceholder: 'Description',
					successHeading: 'Création réussie d’une note d’appel client',
					closeButtonLabel: 'Fermer'
				},
				footer: {
					confirm: 'Confirmer',
					cancel: 'Annuler'
				},
				error: {
					heading: 'Impossible de créer une note d’appel client.',
					message: 'Une erreur s’est produite lors de la création de la note d’appel client.'
				}
			},
			cancelOrder: {
				heading: 'Commande',
				position: 'Position',
				description: 'Description',
				reason: 'Motif',
				note: 'Description',
				cancelReasonValue: {
					other: 'Autre',
					wallboxBroken: 'Wallbox cassée',
					wallboxNotWorking: 'La Wallbox ne fonctionne pas',
					withdrawal: 'Retrait',
					installationNotPossible: 'Installation impossible'
				},
				footer: {
					cancel: 'Annuler',
					confirm: 'Confirmer'
				},
				content: {
					successHeading: 'Annulation réussie de la commande',
					closeButtonLabel: 'Fermer'
				},
				error: {
					loading: {
						heading: 'Impossible de charger les données de l’ordre.',
						message: 'Une erreur s’est produite lors du chargement des données de l’ordre.'
					},
					writing: {
						heading: 'Impossible d’annuler la commande.',
						message: 'Une erreur s’est produite lors de l’annulation de la commande.'
					}
				}
			},
			cancelInstallation: {
				heading: 'Commande',
				position: 'Position',
				description: 'Description',
				reason: 'Motif',
				note: 'Description',
				cancelReasonValue: {
					other: 'Autre',
					wallboxBroken: 'Wallbox cassée',
					wallboxNotWorking: 'La Wallbox ne fonctionne pas',
					withdrawal: 'Retrait',
					installationNotPossible: 'Installation impossible'
				},
				footer: {
					cancel: 'Annuler',
					confirm: 'Confirmer'
				},
				content: {
					successHeading: 'Annulation de l\'installation réussie',
					closeButtonLabel: 'Fermer'
				},
				error: {
					loading: {
						heading: 'Impossible de charger les données de l’ordre.',
						message: 'Une erreur s’est produite lors du chargement des données de l’ordre.'
					},
					writing: {
						heading: 'L\'installation n\'a pas pu être annulée',
						message: 'Une erreur s\'est produite pendant l\'annulation de l\'installation'
					}
				}
			},
			cancelOrderAppointments: {
				content: {
					notificationHeading: 'Le partenaire d’installation sera informé de l’annulation du rendez-vous.',
					notificationMessage: 'Le partenaire d’installation contactera le client pour convenir d’un nouveau rendez-vous.',
					successHeading: 'Rendez-vous annulés',
					successCloseButtonLabel: 'Fermer',
					reason: 'Motif'
				},
				cancelReasonValue: {
					deliveryDelay: 'Retard de livraison',
					installationPartnerConflict: 'Conflit de partenaire d’installation',
					customerConflict: 'Conflit de clientèle',
					replacement: 'Remplacements'
				},
				footer: {
					confirm: 'Confirmer l’annulation',
					cancel: 'Annuler'
				},
				error: {
					heading: 'Impossibilité d’annuler des rendez-vous.',
					message: 'Une erreur s’est produite lors de la suppression des rendez-vous de commande.'
				}
			},
			resendMail: {
				content: {
					notificationHeading: 'L’e-mail sera renvoyé.',
					notificationMessage: 'Le client recevra un nouvel e-mail.',
					successHeading: 'L’envoi de l’e-mail a été effectué avec succès.',
					successCloseButtonLabel: 'Fermer'
				},
				footer: {
					confirm: 'Renvoyer un e-mail',
					cancel: 'Annuler'
				},
				error: {
					heading: 'Impossible de renvoyer un e-mail.',
					message: 'Une erreur s’est produite lors de l’envoi de l’e-mail.'
				}
			},
			returnOrder: {
				heading: 'Commande',
				position: 'Position',
				description: 'Description',
				note: 'Description',
				'return': 'Retour',
				selectReturn: 'Sélectionner les articles retournés',
				selectReason: 'Sélectionner le motif du retour',
				replacementItem: 'Article de remplacement',
				yes: 'Oui',
				no: 'Non',
				returnReason: {
					other: 'Autre',
					broken: 'Cassé'
				},
				footer: {
					cancel: 'Annuler',
					confirm: 'Confirmer'
				},
				content: {
					successHeading: 'Lancement réussi du processus de retour',
					closeButtonLabel: 'Fermer'
				},
				error: {
					loading: {
						heading: 'Impossible de charger les données de l’ordre.',
						message: 'Une erreur s’est produite lors du chargement des données de l’ordre.'
					},
					writing: {
						heading: 'Il n’a pas été possible de lancer le processus de retour.',
						message: 'Une erreur s’est produite lors de l’invocation du processus d’ordre de retour.'
					}
				}
			},
			replaceOrder: {
				heading: 'Commande',
				position: 'Position',
				description: 'Description',
				note: 'Description',
				'return': 'Remplacer',
				selectReturn: 'Sélectionner les éléments à remplacer',
				selectReason: 'Sélectionner le motif de remplacement',
				returnReason: {
					other: 'Autre',
					broken: 'Cassé'
				},
				footer: {
					cancel: 'Annuler',
					confirm: 'Confirmer'
				},
				content: {
					successHeading: 'Lancement réussi du processus de remplacement',
					closeButtonLabel: 'Fermer'
				},
				error: {
					loading: {
						heading: 'Impossible de charger les données de l’ordre.',
						message: 'Une erreur s’est produite lors du chargement des données de l’ordre.'
					},
					writing: {
						heading: 'Il n’a pas été possible de lancer le processus de remplacement.',
						message: 'Une erreur s’est produite lors de l’invocation du processus d’ordre de remplacement.'
					}
				}
			},
			updateCustomer: {
				content: {
					firstname: 'Prénom',
					lastname: 'Nom de famille',
					email: 'E-mail',
					personalInfoHeading: 'Informations personnelles',
					contactDataHeading: 'Données de contact',
					successHeading: 'Les informations sur l’utilisateur ont été modifiées avec succès.',
					successCloseButtonLabel: 'Fermer'
				},
				footer: {
					confirm: 'Confirmer',
					cancel: 'Annuler'
				},
				error: {
					loading: {
						heading: 'Impossible de charger les informations sur le client.',
						message: 'Une erreur s’est produite lors du chargement des informations sur le client.'
					},
					writing: {
						heading: 'Impossible de mettre à jour les informations sur le client.',
						message: 'Une erreur s’est produite lors de la mise à jour des informations sur le client.'
					}
				}
			},
			updateOrder: {
				content: {
					orderInformationHeading: 'Informations sur les commandes',
					phoneLabel: 'Téléphone',
					languageHeading: 'Langue',
					languageLabel: ' Code langue',
					successHeading: 'Les informations relatives à la commande ont été modifiées avec succès.',
					successCloseButtonLabel: 'Fermer'
				},
				footer: {
					confirm: 'Confirmer',
					cancel: 'Annuler'
				},
				error: {
					loading: {
						heading: 'Impossible de charger les informations relatives à la commande.',
						message: 'Une erreur s’est produite lors du chargement des informations relatives à la commande.'
					},
					writing: {
						heading: 'Impossible de mettre à jour les informations relatives à la commande.',
						message: 'Une erreur s’est produite lors de la mise à jour des informations relatives à la commande.'
					}
				}
			}
		},
		appName: 'Service clientèle ABB',
		breadcrumbs: {
			tasks: 'Aperçu des tâches',
			orders: 'Aperçu des commandes',
			marketplaceOrders: 'Aperçu des commandes sur le marché',
			customers: 'Aperçu des clients'
		},
		comment: {
			categories: {
				order: 'Note de commande',
				customer: 'Note du client',
				customerCall: 'Appel client'
			},
			callReason: {
				afterSales: 'Après-vente',
				product: 'Produit',
				installation: 'Installation',
				preCheck: 'Précontrôle',
				payment: 'Paiement',
				dataProtection: 'DataProtection',
				shipment: 'Expédition',
				cancellation: 'Retours',
				falseRouting: 'Faux acheminement',
				other: 'Autre',
				complaints: 'Plaintes',
				returns: 'Retours'
			}
		},
		commentCollection: {
			heading: 'Notes',
			emptyState: {
				heading: 'Pas de commentaires',
				message: 'Il n’y a actuellement aucun commentaire disponible.'
			}
		},
		common: {
			tableHeader: {
				position: 'Position',
				description: 'Description'
			}
		},
		customerCollection: {
			heading: 'Clients',
			tableHead: {
				customer: 'Client',
				customerId: 'Identifiant du client',
				firstOrder: 'Première commande',
				lastOrder: 'Dernière commande'
			},
			emptyState: {
				heading: 'Aucun client trouvé',
				message: 'Ajustez vos paramètres de filtrage pour trouver des clients.'
			},
			action: {
				showMore: 'Afficher plus'
			}
		},
		customerInformation: {
			heading: 'Informations sur les clients',
			tableHeader: {
				position: 'Position',
				description: 'Description'
			},
			tableItem: {
				firstName: 'Prénom',
				lastName: 'Nom de famille',
				companyName: 'Nom de l\'entreprise',
				mail: 'Courrier',
				firstOrder: 'Première commande',
				lastOrder: 'Dernière commande'
			}
		},
		customerOrders: {
			heading: 'Commandes de clients'
		},
		customerMarketplaceOrders: {
			heading: 'Marketplace commandes de clients'
		},
		customerDetail: {
			subheader: {
				customerId: 'Identifiant du client'
			},
			tabItems: {
				overview: 'Vue d’ensemble',
				comments: 'Notes'
			}
		},
		error: {
			generic: {
				heading: 'Un problème est survenu',
				message: 'Une erreur imprévue s’est produite et n’a pas pu être résolue',
				resolveAction: {
					label: 'Recharger'
				}
			},
			offline: {
				heading: 'Connexion réseau indisponible',
				message: 'Veuillez vous connecter à un réseau pour utiliser l’application.'
			},
			notFound: {
				heading: 'Introuvable',
				message: 'Ressource demandée introuvable',
				resolveAction: {
					label: 'Mes tâches'
				}
			},
			customerNotFound: 'Client demandé introuvable',
			orderNotFound: 'Commande demandée introuvable',
			taskNotFound: 'Tâche demandée introuvable',
			callerNotFound: 'Information sur l’appelant introuvable',
			taskAssignMessage: 'Une erreur s’est produite lors de l’attribution de la tâche.',
			taskCompleteMessage: 'Une erreur s’est produite lors de l’exécution de la tâche.'
		},
		navigation: {
			tasks: 'Mes tâches',
			orders: 'Commandes',
			marketplaceOrders: 'Marketplace Commandes',
			customers: 'Clients',
			logout: 'Déconnexion'
		},
		order: {
			installationType: {
				undetermined: 'Indéterminé',
				standard: 'Standard',
				delta: 'Delta',
				cancelled: 'Annulé'
			}
		},
		orderDetail: {
			subheader: {
				orderDate: 'Date de la commande',
				orderId: 'Identifiant de la commande',
				marketplace: 'Marché',
				plannedDelivery: 'Livraison prévue',
				orderState: {
					onSchedule: 'Dans les délais',
					delayed: 'Retardé',
					cancelPending: 'Annuler en attente',
					cancelled: 'Annulé',
					imported: 'Importé',
					validationFailed: 'Non valide',
					done: 'Terminé'
				}
			},
			tabItems: {
				overview: 'Vue d’ensemble',
				installation: 'Installation',
				delivery: 'Livraison',
				accounting: 'Comptabilité',
				files: 'Fichiers',
				comments: 'Notes',
				tasks: 'Tâches'
			},
			overview: {
				customerInformation: {
					heading: 'Informations sur les clients'
				},
				orderInformation: {
					heading: 'Informations sur les commandes',
					tableHeader: {
						position: 'Position',
						description: 'Description'
					},
					tableItem: {
						language: 'Langue',
						phone: 'Téléphone',
						deliveryAddress: 'Adresse'
					}
				},
				timeline: {
					heading: 'Chronologie'
				}
			},
			installation: {
				cancellation: {
					notification: {
						heading: 'L\'installation a été annulée',
						message: 'L\'annulation a été effectuée le {{cancellationDate}}. La raison invoquée était "{{cancellationReason}}".'
					}
				},
				information: {
					heading: 'Information',
					types: {
						basicAnswers: 'Réponses de base',
						gridOperatorRegistration: 'Enregistrement de l’opérateur réseau',
						gridOperatorDecision: 'Décision de l’opérateur réseau',
						preCheck: 'Pré-contrôle',
						offer: 'Offre',
						approval: 'Homologation'
					},
					emptyState: {
						heading: 'Aucune information sur les commandes disponible',
						message: 'Aucune information sur les commandes n’est disponible.'
					}
				},
				installationAppointments: {
					heading: 'Installations prévues',
					installer: 'Installateur',
					appointment: 'Rendez-vous',
					phone: 'Téléphone',
					mail: 'E-mail',
					emptyState: {
						heading: 'Aucun rendez-vous trouvé.',
						message: 'Il n’y a pas de rendez-vous planifiés.'
					}
				}
			},
			delivery: {
				orderItem: 'Commander un article',
				productCode: 'Code produit',
				address: {
					heading: 'Adresse de livraison',
					name: 'Nom',
					street: 'Rue',
					location: 'Ville'
				},
				details: {
					heading: 'Détails de la livraison',
					shippingDate: 'Date d\'expédition',
					date: 'Date d’expédition',
					address: 'Adresse d’expédition',
					trackingCode: 'Code de suivi',
					state: 'État de l’expédition',
					partner: 'Partenaire d’expédition',
					tracking: 'Suivi',
					shippingState: {
						delivered: 'Fourni',
						pending: 'En attente'
					},
					estimatedDeliveryDate: 'Date de livraison estimée',
					deliveryDate: 'Date de livraison'
				},
				retoureDetails: {
					heading: 'Détails sur le retour',
					date: 'Date de la demande',
					address: 'Adresse de retour',
					reason: 'Motif du retour',
					returnNote: 'Note de retour',
					created: 'Créé à'
				},
				replacementDetails: {
					heading: 'Détails du remplacement'
				},
				emptyState: {
					heading: 'Aucune information sur l’expédition disponible',
					message: 'Il n’y a actuellement pas d’autres informations sur l’expédition.'
				}
			},
			accounting: {
				heading: 'Transaction',
				date: 'Date de paiement',
				state: 'État',
				notification: 'Message d’erreur',
				cardOwner: 'Propriétaire de la carte',
				cardNumber: 'Numéro de carte',
				cardDueDate: 'Date d’échéance de la carte',
				accountOwner: 'Propriétaire du compte',
				accountNumber: 'IBAN',
				paymentState: {
					success: 'Opération réussie',
					failed: 'Échec',
					settlementPending: 'Règlement en cours'
				},
				emptyPaymentState: {
					heading: 'Aucune information sur les paiements disponible',
					message: 'Il n’y a actuellement pas d’autres informations sur les paiements.'
				},
				emptyDownloadFilesState: {
					heading: 'Pas de fichiers de téléchargement disponibles',
					message: 'Il n’y a actuellement aucun fichier de téléchargement disponible.'
				}
			},
			files: {
				emptyState: {
					heading: 'Pas de fichiers de téléchargement disponibles',
					message: 'Il n’y a actuellement aucun fichier de téléchargement disponible.'
				}
			},
			throttleWallboxHint: {
				caption: 'Attention',
				message: 'La consommation électrique de la wallbox doit être réduite en fonction des exigences de l\'opérateur du réseau.'
			},
			informationList: {
				heading: 'Information',
				emptyState: {
					heading: 'Aucune information sur les commandes n\'est encore disponible',
					message: 'Au fur et à mesure de l\'avancement de la commande, vous trouverez ici toutes les informations utiles concernant la commande.'
				},
				caption: {
					basicAnswers: 'Réponses de base',
					gridOperatorRegistration: 'Enregistrement du gestionnaire de réseau',
					gridOperatorDecision: 'Décision de l\'opérateur du réseau',
					preCheck: 'Précontrôle',
					offer: 'Offre',
					approval: 'Approbation'
				},
				precheck: {
					heading: 'Précontrôle',
					effort: 'Résultats du contrôle préalable : Rendez-vous et efforts d\'installation',
					questionnaire: {
						heading: 'Réponses des clients'
					},
					installationType: {
						undetermined: 'Indéterminé',
						standard: 'Installation standard',
						delta: 'Installation delta'
					},
					decision: {
						accepted: 'Informations suffisantes',
						more: 'Plus d\'informations sont nécessaires',
						denied: 'Installation impossible'
					},
					notes: 'Notes',
					hours: 'heures'
				},
				decision: {
					heading: 'Décision de l\'opérateur du réseau',
					decision: {
						pending: 'La demande d\'installation est toujours en cours',
						accepted: 'Le gestionnaire du réseau a accepté l\'installation',
						denied: 'Le gestionnaire de réseau a refusé l\'installation'
					}
				},
				registration: {
					heading: 'Enregistrement du gestionnaire de réseau',
					documents: 'Documents',
					gridOperator: 'Opérateur de réseau'
				},
				quotation: {
					heading: 'Devis',
					conditions: 'Conditions de l\'offre'
				},
				complete: {
					success: {
						heading: 'Installation réussie'
					},
					notes: 'Notes',
					images: 'Images liées à l\'installation',
					documents: 'Documents relatifs à l\'installation',
					signature: 'Signature client'
				}
			}
		},
		orderEventTimeline: {
			emptyState: {
				heading: 'Aucun événement trouvé',
				message: 'L\'historique est vide pour le moment'
			}
		},
		timelineItemDetails: {
			mailAddress: {
				label: 'E-mail'
			},
			section: {
				payment: 'Paiements',
				appointments: 'Rendez-vous programmés'
			}
		},
		orderEvent: {
			type: {
				orderReceived: 'Commande reçue',
				orderAddressValidationSucceeded: 'Validation d\'adresse réussie',
				orderAddressValidationFailed: 'Échec de la validation de l\'adresse',
				orderAddressCorrected: 'Adresse de commande corrigée',
				accountingOrderCreated: 'Commande de comptabilité créée',
				orderConfirmationMailSent: 'E-mail de confirmation envoyé',
				basicAnswersReceived: 'Réponses au questionnaire reçues',
				gridOperatorRegistrationStarted: 'L\'enregistrement de l\'opérateur réseau a commencé',
				gridOperatorFeedbackReceived: 'Réaction de l\'opérateur de réseau reçue',
				gridOperatorRejectionNotificationSent: 'Opérateur de réseau ayant reçu un refus',
				basicAnswersEvaluated: 'Réponses au questionnaire évaluées',
				precheckPreferencesMailSent: 'Mail de demandes de contact PreCheck envoyé',
				remotePrecheckExecuted: 'Precheck à distance exécuté',
				deltaQuotationCreated: 'Offre delta créée',
				deltaQuotationMailSent: 'Offre delta envoyée par mail',
				deltaQuotationAccepted: 'Offre delta acceptée',
				paymentSucceeded: 'Paiement réussi',
				paymentFailedOrTimedOut: 'Paiement échoué',
				paymentReminderMailSent: 'Rappel de paiement envoyé',
				paymentConfirmationMailSent: 'E-mail de confirmation de paiement envoyé',
				appointmentArranged: 'Rendez-vous fixé',
				appointmentArrangedNotificationSent: 'Rendez-vous envoyé par mail de notification',
				shippingConfirmed: 'Livraison confirmée',
				installationConfirmed: 'Installation confirmée',
				replacementInstallationConfirmed: 'installation de remplacement confirmée',
				installationApprovalMailSent: 'Mail de confirmation d\'installation envoyé',
				invoiceCreated: 'Facture créée',
				invoiceNotificationSent: 'Notification de facture envoyée',
				orderCancelled: 'Commande annulée',
				gridOperatorRegistrationRevoked: 'Enregistrement de l\'opérateur réseau annulé',
				customerCallReceived: 'Appel client reçu',
				returnOrReplacementShipmentReceived: 'Livraison de retour ou de remplacement reçue',
				returnInitiated: 'Retour initialisé',
				replacementInitiated: 'Livraison de remplacement initialisée',
				cancellationInitiated: 'Annulation initialisée',
				orderNoteCreated: 'Note de commande générée',
				refundCompleted: 'Remboursement terminé',
				installationCancellationMailSent: 'Envoi de la notification d\'annulation de l\'installation'
			},
			category: {
				accounting: 'Comptabilité',
				customer: 'Client',
				customerService: 'Service clientèle',
				deltaInstallation: 'Installation delta',
				gridOperator: 'Opérateur de réseau',
				installation: 'Installation',
				order: 'Commande',
				payment: 'Paiement',
				preCheck: 'PreCheck',
				shipping: 'Expédition'
			}
		},
		marketplaceOrder: {
			orderState: {
				paymentPending: 'Paiement en attente',
				waitForFulfillment: 'En attente d\'accomplissement',
				fulfillmentStated: 'Exécution commencée',
				cancelled: 'Annulé',
				done: 'Terminé'
			}
		},
		orderCollection: {
			heading: 'Commandes',
			tableHead: {
				order: 'Commande',
				status: 'État',
				language: 'Langue',
				plannedDeliveryDate: 'Livraison prévue',
				createdAt: 'Créé à'
			},
			emptyState: {
				heading: 'Aucune commande trouvée',
				message: 'Ajustez vos paramètres de filtrage pour trouver des commandes.'
			},
			action: {
				refresh: 'Actualiser',
				showMore: 'Afficher plus'
			},
			orderState: {
				onSchedule: 'Dans les délais',
				delayed: 'Retardé',
				cancelPending: 'Annuler l\'attente',
				cancelled: 'Annulé',
				imported: 'Importé',
				validationFailed: 'Non valide',
				done: 'Terminé'
			}
		},
		customerOrderCollection: {
			emptyState: {
				heading: 'Aucune commande trouvée',
				message: 'Aucune commande n\'a été trouvée pour ce client.'
			}
		},
		orderLineItemCollection: {
			heading: 'Commande',
			position: 'Position',
			description: 'Description',
			price: 'Prix',
			productCode: 'Code produit',
			totalPrice: 'Prix total'
		},
		inboundOrderCollection: {
			heading: 'Commandes associées au numéro de téléphone {{caller}}',
			emptyState: {
				heading: 'Aucune commande trouvée',
				message: 'Peut-être souhaitez-vous plutôt effectuer une recherche.',
				searchLabel: 'Rechercher'
			},
			action: {
				showMore: 'Afficher plus'
			}
		},
		searchbar: {
			placeholder: 'Rechercher',
			results: {
				orders: 'Commandes',
				marketplaceOrders: 'Commandes sur le marché',
				customers: 'Clients',
				showResultPage: 'Afficher la page des résultats',
				showResultPageDescription: {
					resultsFromTo: 'Résultat 1 à',
					of: 'de'
				}
			}
		},
		searchResultCollection: {
			heading: 'Résultats de la recherche',
			headingWithSearchterm: 'Résultats de la recherche pour',
			headingCustomers: 'Clients',
			headingOrders: 'Commandes',
			headingMarketplaceOrders: 'Commandes sur le marché',
			emptyState: {
				heading: 'Aucun résultat de recherche trouvé',
				message: 'Ajustez votre terme de recherche et réessayez.'
			},
			idleState: {
				heading: 'Aucun terme de recherche n\'a été fourni',
				message: 'Veuillez saisir un terme de recherche.'
			},
			action: {
				showMore: 'Afficher plus'
			}
		},
		task: {
			assign: {
				successMessage: 'Tâche assignée'
			},
			error: {
				taskNotFound: 'Tâche introuvable',
				taskTypeNotFound: 'Type de tâche introuvable'
			},
			gridOperatorRegistration: {
				continueToFeedback: {
					buttonLabel: 'Continuer'
				}
			},
			basicAnswers: {
				continueToQuotation: {
					buttonLabel: 'Continuer'
				},
				button: {
					close: 'Fermer',
					submit: 'Soumettre'
				},
				questionnaire: {
					heading: 'Réponses de base'
				}
			},
			completion: {
				successHeading: 'Tâche accomplie avec succès',
				closeButtonLabel: 'Fermer'
			},
			preCheck: {
				button: {
					close: 'Fermer',
					submit: 'Soumettre'
				},
				contact: {
					description: 'Contact'
				}
			},
			gridOperator: {
				button: {
					close: 'Fermer',
					submit: 'Soumettre'
				}
			},
			quotation: {
				button: {
					close: 'Fermer',
					submit: 'Soumettre'
				},
				deltaPackages: {
					totalPurchaseNetPrice: 'Prix d\'achat total (net)',
					totalSalesGrossPrice: 'Prix de vente total (y compris {{vatRate}}% de TVA)',
					purchaseNetPrice: 'Prix d\'achat (net)',
					salesGrossPrice: 'Prix de vente (brut)'
				}
			},
			payment: {
				button: {
					close: 'Fermer',
					submit: 'Soumettre'
				}
			},
			appointment: {
				button: {
					close: 'Fermer',
					submit: 'Soumettre'
				}
			},
			installation: {
				button: {
					close: 'Fermer',
					submit: 'Soumettre'
				}
			}
		},
		taskCollection: {
			heading: 'Tâches',
			tableHead: {
				task: 'Tâche',
				status: 'État',
				language: 'Langue'
			},
			action: {
				refresh: 'Actualiser',
				showMore: 'Afficher plus'
			},
			assign: {
				successMessage: 'La tâche a été assignée avec succès',
				errorMessage: 'Une erreur s’est produite lors de l’attribution de la tâche.'
			},
			emptyState: {
				heading: 'Aucune tâche trouvée',
				message: 'Ajustez vos paramètres de filtrage pour trouver certaines tâches.'
			},
			orderEmptyState: {
				heading: 'Aucune tâche trouvée',
				message: 'Il n’y a rien à faire pour cette commande.'
			}
		},
		taskCollectionItem: {
			type: {
				customerAnswerBasicQuestionsEscalation: 'Escalade : Question de base',
				customerProvidePreCheckPreferencesEscalation: 'Escalade : Pré-contrôle',
				customerHandleUnfeasibleInstallationEscalation: 'Escalade : Installation irréalisable',
				customerHandleGridOperatorRejectionEscalation: 'Escalade : Rejet par l’opérateur réseau',
				customerAcceptQuotationEscalation: 'Escalade : Devis',
				customerPerformPaymentEscalation: 'Escalade : Paiement',
				customerAddressValidationEscalation: 'Validation de l’adresse',
				customerUncoveredInstallationAddress: 'Installation non couverte Adresse',
				customerApproveInstallationEscalation: 'Escalade : Approuver l’installation',
				installationPartnerEvaluateBasicAnswersEscalation: 'Escalade : Réponses de base',
				installationPartnerExecuteRemotePreCheckEscalation: 'Escalade : Exécuter le pré-contrôle à distance',
				installationPartnerRegisterAtGridOperatorEscalation: 'Escalade : S’inscrire auprès de l’opérateur réseau',
				installationPartnerProvideGridOperatorDecisionEscalation: 'Escalade : Fournir un opérateur réseau',
				installationPartnerProvideQuotationEscalation: 'Escalade : Fournir un devis',
				installationPartnerArrangeAppointmentEscalation: 'Escalade : Fixer des rendez-vous',
				installationPartnerCompleteInstallationEscalation: 'Escalade : Installation terminée'
			},
			status: {
				started: 'À venir',
				dueToday: 'À remettre aujourd’hui',
				overdue: 'En retard',
				escalated: 'Remonté',
				done: 'Terminé',
				doneOverdue: 'Terminé',
				doneEscalated: 'Terminé'
			},
			category: {
				customer: 'Client',
				installationPartner: 'Install. Partenaire'
			}
		},
		pageTitle: {
			tasks: 'Aperçu des tâches',
			orders: 'Aperçu des commandes',
			order: 'Détails de commande',
			marketplaceOrders: 'Aperçu de la commande sur le marché',
			marketplaceOrder: 'Détail de la commande sur le marché',
			customers: 'Aperçu des clients',
			error: 'Erreur',
			notFound: 'Introuvable',
			inbound: 'Appel entrant {{caller}}'
		},
		viewHeader: {
			tasks: 'Aperçu des tâches',
			orders: 'Aperçu des commandes',
			marketplaceOrders: 'Aperçu des commandes sur le marché',
			customers: 'Aperçu des clients',
			inbound: 'Appel entrant de {{caller}}'
		},
		modal: {
			task: {
				section: {
					customerInformation: {
						heading: 'Client',
						position: 'Position',
						description: 'Description',
						name: 'Nom',
						address: 'Adresse',
						phone: 'Numéro de téléphone',
						mail: 'E-mail'
					},
					installationEffortInformation: {
						heading: 'Effort d’installation',
						trade: 'Métier',
						effort: 'Effort',
						hours: 'heures'
					},
					installationPartnerInformation: {
						heading: 'Partenaire d’installation',
						position: 'Position',
						description: 'Description',
						name: 'Nom',
						mail: 'E-mail',
						phone: 'Teléfono'
					},
					orderInformation: {
						heading: 'Informations sur les commandes'
					},
					quotationInformation: {
						heading: 'Devis',
						position: 'Position',
						description: 'Description',
						price: 'Prix',
						deltaInstallation: 'Décision : Installation Delta',
						grossPrice: 'Somme',
						vat: 'TVA',
						include: 'inclure'
					},
					taskInformation: {
						heading: 'Tâche',
						position: 'Position',
						description: 'Description',
						createdAt: 'Créé à',
						dueDate: 'Date d’échéance',
						responsible: 'Responsable',
						openTask: 'Tâche ouverte'
					},
					contactInformation: {
						heading: 'Préférences de contact'
					},
					gridOperatorInformation: {
						heading: 'Informations sur l’opérateur réseau'
					},
					payment: {
						accept: 'Devis accepté'
					},
					addressValidation: {
						heading: 'Validation de l\'adresse',
						invalidAddressLabel: {
							delivery: 'Adresse de livraison non valide',
							billing: 'Adresse de facturation non valide',
							multiple: 'Adresse de livraison et de facturation non valide'
						},
						hint: {
							heading: 'Insérer une nouvelle adresse pour validation',
							message: 'La nouvelle adresse sera revalidée lorsque la tâche sera soumise'
						},
						error: {
							heading: 'La validation de l\'adresse a échoué',
							message: 'Veuillez insérer les informations d\'adresse correctes.'
						},
						newAddress: {
							heading: 'Nouvelle adresse',
							street: 'Rue et numéro de maison',
							zip: 'Code postal',
							city: 'Ville',
							country: 'Pays',
							region: 'Région'
						}
					},
					uncoveredInstallationAddress: {
						message: 'Le client a été informé de l\'annulation de la commande',
						info: 'Installation non couverte Adresse',
						infoHeading: 'Informer le client',
						infoDescription: 'Le client doit être informé que l\'adresse d\'installation n\'est pas couverte et que l\'installation est annulée.'
					},
					hint: {
						heading: 'Contrôle final',
						unfeasible: 'Décision : Installation infaisable',
						rejected: 'Refusé par l’opérateur réseau'
					}
				}
			}
		}
	}
};

export default literals;
