import React, { ReactNode } from 'react';

import { CustomerServiceOrderReturnModel } from '@abb-emobility/platform/domain-model';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	Card,
	SegmentHeader,
	TabularInformationItem,
	TabularInformationItemType,
	TabularInformationRow
} from '@abb-emobility/shared/ui-primitive';
import { Nullable } from '@abb-emobility/shared/util';

import { OrderDetailDeliveryDownloadLink } from './OrderDetailDeliveryDownloadLink';
import { OrderDetailDeliveryOrderLineItems } from './OrderDetailDeliveryOrderLineItemCollection';

type OrderDetailDeliveryReturnProps = {
	shippingModel: CustomerServiceOrderReturnModel
};

export function OrderDetailDeliveryReturn(props: OrderDetailDeliveryReturnProps) {

	const { shippingModel } = props;

	const l10n = useL10n();

	const retourDetailsLabel = l10n.translate('platformCustomerServiceApp.orderDetail.delivery.retoureDetails.heading');

	const renderReason = (): ReactNode => {
		if (shippingModel.reason) {
			return (
				<TabularInformationRow>
					<TabularInformationItem label={l10n.translate('platformCustomerServiceApp.orderDetail.delivery.retoureDetails.reason')} variant={TabularInformationItemType.GREY} />
					<TabularInformationItem label={shippingModel.reason} />
				</TabularInformationRow>
			);
		}

		return;
	};

	const renderShipmentDocuments = (): Nullable<Array<JSX.Element>> => {
		if (shippingModel.shipmentDocuments) {
			return shippingModel.shipmentDocuments.map((document, index) => {
				const lastModified = document.meta.lastModifiedDate ?? null;
				const renderDownloadLink = () => {
					if (lastModified === null) {
						return (
							<OrderDetailDeliveryDownloadLink file={document} />
						);
					}
					return (
						<TabularInformationItem
							label={
								l10n.translate('platformCustomerServiceApp.orderDetail.delivery.retoureDetails.created') + ' ' +
								l10n.formatTimestampDate(document.meta.lastModifiedDate)}
						>
							<OrderDetailDeliveryDownloadLink file={document} />
						</TabularInformationItem>
					);
				};

				return (
					<TabularInformationRow key={index}>
						<TabularInformationItem
							label={l10n.translate('platformCustomerServiceApp.orderDetail.delivery.retoureDetails.returnNote')}
							variant={TabularInformationItemType.GREY}
						/>
						{renderDownloadLink()}
					</TabularInformationRow>
				);
			});
		}

		return null;
	};

	return (
		<Card>
			<SegmentHeader caption={retourDetailsLabel} />
			<OrderDetailDeliveryOrderLineItems orderLineItems={shippingModel.orderLineItems} />

			<TabularInformationRow>
				<TabularInformationItem
					label={l10n.translate('platformCustomerServiceApp.orderDetail.delivery.retoureDetails.date')}
					variant={TabularInformationItemType.GREY}
				/>
				<TabularInformationItem label={l10n.formatTimestampDate(shippingModel.shipmentDate)} />
			</TabularInformationRow>

			<TabularInformationRow>
				<TabularInformationItem
					label={l10n.translate('platformCustomerServiceApp.orderDetail.delivery.retoureDetails.address')}
					variant={TabularInformationItemType.GREY}
				/>
				<TabularInformationItem
					label={`${shippingModel.deliveryAddress.street}, ${shippingModel.deliveryAddress.zip} ${shippingModel.deliveryAddress.city}`}
				/>
			</TabularInformationRow>

			{renderReason()}

			{renderShipmentDocuments()}
		</Card>
	);
}
