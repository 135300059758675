import {
	CustomerServiceCustomerCommentApiClientFactory,
	CustomerServiceCustomerCommentApiClientInterface
} from '@abb-emobility/platform/api-integration';
import { AnyCustomerServiceCommentModel } from '@abb-emobility/platform/domain-model';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import {
	createCrudCreateThunk,
	createCrudCollectionSlice,
	createCrudDeleteThunk,
	createCrudFetchAllThunk,
	createCrudFetchNextThunk,
	createCrudFetchCollectionEntityThunk,
	createCrudMutateThunk,
	createCrudSelectCollection,
	createCrudSelectCollectionEntity,
	createCrudSelectCreatedEntity,
	CrudCollectionStore,
	CrudCollectionStoreAccessors,
	ThunkApiConfig,
	createCrudCountCollection,
	createCrudRefetchAllThunk,
	createCrudFetchAllSortedThunk,
	createCrudFetchAllFilteredThunk,
	createCrudRefetchCollectionEntityThunk,
	createCrudCollectionStoreSize,
	createCrudCollectionStoreEntryIds
} from '@abb-emobility/shared/data-provider-foundation';
import { Nullable } from '@abb-emobility/shared/util';

// Basic definition
export const customerServiceCustomerCommentCollectionStoreName = 'customer-service-customer-comment-collection-crud';
export type CustomerServiceCustomerCommentCollectionStore = { [customerServiceCustomerCommentCollectionStoreName]: CrudCollectionStore<AnyCustomerServiceCommentModel> };

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the
const createApiClient = (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) => {
	return CustomerServiceCustomerCommentApiClientFactory.create(apiBaseUrl, jsonWebToken);
};
const fetchAllThunk = createCrudFetchAllThunk<AnyCustomerServiceCommentModel, CustomerServiceCustomerCommentCollectionStore, CustomerServiceCustomerCommentApiClientInterface>(customerServiceCustomerCommentCollectionStoreName, createApiClient);
const refetchAllThunk = createCrudRefetchAllThunk<AnyCustomerServiceCommentModel, CustomerServiceCustomerCommentCollectionStore, CustomerServiceCustomerCommentApiClientInterface>(customerServiceCustomerCommentCollectionStoreName, createApiClient);
const fetchAllSortedThunk = createCrudFetchAllSortedThunk<AnyCustomerServiceCommentModel, CustomerServiceCustomerCommentCollectionStore, CustomerServiceCustomerCommentApiClientInterface>(customerServiceCustomerCommentCollectionStoreName, createApiClient);
const fetchAllFilteredThunk = createCrudFetchAllFilteredThunk<AnyCustomerServiceCommentModel, CustomerServiceCustomerCommentCollectionStore, CustomerServiceCustomerCommentApiClientInterface>(customerServiceCustomerCommentCollectionStoreName, createApiClient);
const fetchNextThunk = createCrudFetchNextThunk<AnyCustomerServiceCommentModel, CustomerServiceCustomerCommentCollectionStore, CustomerServiceCustomerCommentApiClientInterface>(customerServiceCustomerCommentCollectionStoreName, createApiClient);
const fetchEntityThunk = createCrudFetchCollectionEntityThunk<AnyCustomerServiceCommentModel, CustomerServiceCustomerCommentCollectionStore, CustomerServiceCustomerCommentApiClientInterface>(customerServiceCustomerCommentCollectionStoreName, createApiClient);
const refetchEntityThunk = createCrudRefetchCollectionEntityThunk<AnyCustomerServiceCommentModel, CustomerServiceCustomerCommentCollectionStore, CustomerServiceCustomerCommentApiClientInterface>(customerServiceCustomerCommentCollectionStoreName, createApiClient);
const mutateThunk = createCrudMutateThunk<AnyCustomerServiceCommentModel, CustomerServiceCustomerCommentApiClientInterface>(customerServiceCustomerCommentCollectionStoreName, createApiClient);
const createThunk = createCrudCreateThunk<AnyCustomerServiceCommentModel, CustomerServiceCustomerCommentApiClientInterface>(customerServiceCustomerCommentCollectionStoreName, createApiClient);
const deleteThunk = createCrudDeleteThunk<AnyCustomerServiceCommentModel, CustomerServiceCustomerCommentApiClientInterface>(customerServiceCustomerCommentCollectionStoreName, createApiClient);

// Slice definition
export const customerServiceCustomerCommentCollectionSlice = createCrudCollectionSlice<AnyCustomerServiceCommentModel, ThunkApiConfig>(
	customerServiceCustomerCommentCollectionStoreName,
	fetchAllThunk,
	refetchAllThunk,
	fetchAllSortedThunk,
	fetchAllFilteredThunk,
	fetchNextThunk,
	fetchEntityThunk,
	refetchEntityThunk,
	mutateThunk,
	createThunk,
	deleteThunk
);
const { resolveFetchStatus, resolveActionStatus, resetStore } = customerServiceCustomerCommentCollectionSlice.actions;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
const selectCollection = createCrudSelectCollection<AnyCustomerServiceCommentModel, CustomerServiceCustomerCommentCollectionStore>(customerServiceCustomerCommentCollectionStoreName);
const countCollection = createCrudCountCollection<AnyCustomerServiceCommentModel, CustomerServiceCustomerCommentCollectionStore>(customerServiceCustomerCommentCollectionStoreName);
const selectEntity = createCrudSelectCollectionEntity<AnyCustomerServiceCommentModel, CustomerServiceCustomerCommentCollectionStore>(customerServiceCustomerCommentCollectionStoreName);
const selectCreated = createCrudSelectCreatedEntity<AnyCustomerServiceCommentModel, CustomerServiceCustomerCommentCollectionStore>(customerServiceCustomerCommentCollectionStoreName);
const storeSize = createCrudCollectionStoreSize<AnyCustomerServiceCommentModel, CustomerServiceCustomerCommentCollectionStore>(customerServiceCustomerCommentCollectionStoreName);
const storeEntryIds = createCrudCollectionStoreEntryIds<AnyCustomerServiceCommentModel, CustomerServiceCustomerCommentCollectionStore>(customerServiceCustomerCommentCollectionStoreName);

// Export the store accessors to be used by the data provider value
export const customerServiceCustomerCommentCollectionStoreAccessors = {
	fetchAll: fetchAllThunk,
	refetchAll: refetchAllThunk,
	fetchAllSorted: fetchAllSortedThunk,
	fetchAllFiltered: fetchAllFilteredThunk,
	fetchNext: fetchNextThunk,
	fetchEntity: fetchEntityThunk,
	refetchEntity: refetchEntityThunk,
	create: createThunk,
	mutate: mutateThunk,
	delete: deleteThunk,
	resolveFetchStatus,
	resolveActionStatus,
	selectCollection,
	countCollection,
	selectEntity,
	selectCreated,
	resetStore,
	storeSize,
	storeEntryIds
} as CrudCollectionStoreAccessors<AnyCustomerServiceCommentModel, CustomerServiceCustomerCommentCollectionStore>;
