import { ReactNode } from 'react';

import { buildCssClassNameFromEnumValue } from '@abb-emobility/shared/util';

import { TabularInformationItemType } from './TabularInformationItemType.enums';

import './TabularInformationItem.scss';

export type TabularInformationItemProps = {
	children?: ReactNode
	label: string,
	variant?: TabularInformationItemType,
	width?: number,
	flex?: number
};

export function TabularInformationItem(props: TabularInformationItemProps) {
	const { children, label, variant = TabularInformationItemType.DEFAULT, width, flex } = props;

	return (
		<div style={{
			flexBasis: width,
			maxWidth: width,
			flex: flex
		}} className={`tabular-information-item ${buildCssClassNameFromEnumValue(variant, 'tabular-information-item')}`}>{label} {children}</div>
	);
}
