import React, { FormEvent, ReactNode, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
	createFilterCriteriaFromSearchString,
	CustomerServiceSearchDataInterceptEmpty,
	CustomerServiceSearchDataSuspense,
	defaultCustomerServiceSearchDataProviderSortCriteria,
	useCustomerServiceSearchData
} from '@abb-emobility/platform/data-provider';
import { AnyCustomerServiceSearchResultModel, CustomerServiceSearchResultType } from '@abb-emobility/platform/domain-model';
import { KeyboardEventValues } from '@abb-emobility/shared/browser';
import { ModelFilter } from '@abb-emobility/shared/data-provider-foundation';
import { useOnClickOutside } from '@abb-emobility/shared/interaction';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	ButtonSecondary,
	IconIdentifier,
	IconSvg,
	SearchbarResultsEmpty,
	SearchbarResultsHeader,
	Separator,
	SpinnerDots
} from '@abb-emobility/shared/ui-primitive';
import { buildCssClassStringFromClassMap } from '@abb-emobility/shared/util';

import { createRouteUrl, RoutePath } from '../../router/Routes';
import { CustomerCollectionHeader } from '../customer-collection-header/CustomerCollectionHeader';
import { CustomerCollectionItem } from '../customer-collection-item/CustomerCollectionItem';
import { MarketplaceOrderCollectionHeader } from '../marketplace-order-collection-header/MarketplaceOrderCollectionHeader';
import { MarketplaceOrderCollectionItem } from '../marketplace-order-collection-item/MarketplaceOrderCollectionItem';
import { OrderCollectionHeader } from '../order-collection-header/OrderCollectionHeader';
import { OrderCollectionItem } from '../order-collection-item/OrderCollectionItem';
import { useSearchbarContent } from '../searchbar-content/SearchbarContent.context';

import './Searchbar.scss';

type SearchbarProps = {
	customerResultLengthLimit?: number,
	orderResultsLenghtLimit?: number,
	marketplaceOrderResultsLenghtLimit?: number
};

export const Searchbar = (props: SearchbarProps) => {
	const { customerResultLengthLimit = 3, orderResultsLenghtLimit = 3, marketplaceOrderResultsLenghtLimit = 3 } = props;

	const l10n = useL10n();
	const location = useLocation();
	const navigate = useNavigate();

	const [searchTerm, setSearchTerm] = useState<string>('');
	const [showResults, setShowResults] = useState<boolean>(false);

	const searchData = useCustomerServiceSearchData();
	const fetchPending = searchData.pending();
	const searchbarContent = useSearchbarContent();
	const resultCount = searchData.queryCount();

	const customerId = searchbarContent.getCustomerId();
	const orderId = searchbarContent.getOrderId();
	const dropdownAvailable = searchbarContent.getDropdownAvailable();

	const customerModelFilter: ModelFilter<AnyCustomerServiceSearchResultModel> = (value) => {
		return value.type === CustomerServiceSearchResultType.CUSTOMER;
	};

	const orderModelFilter: ModelFilter<AnyCustomerServiceSearchResultModel> = (value) => {
		return value.type === CustomerServiceSearchResultType.ORDER;
	};

	const marketplaceOrderModelFilter: ModelFilter<AnyCustomerServiceSearchResultModel> = (value) => {
		return value.type === CustomerServiceSearchResultType.MARKETPLACE_ORDER;
	};

	const customerSearchResults = searchData.query(undefined, customerModelFilter, { limit: customerResultLengthLimit, offset: 0 });
	const orderSearchResults = searchData.query(undefined, orderModelFilter, { limit: orderResultsLenghtLimit, offset: 0 });
	const marketplaceOrderSearchResults = searchData.query(undefined, marketplaceOrderModelFilter, { limit: marketplaceOrderResultsLenghtLimit, offset: 0 });

	const searchbarInputRef = useRef<HTMLInputElement>(null);
	const searchbarRef = useRef<HTMLInputElement>(null);

	const inputClassMap = {
		'searchbar__header__input': true,
		'searchbar__header__input--active': showResults && dropdownAvailable
	};

	const searchbarClassMap = {
		'searchbar': true,
		'searchbar--active': showResults && dropdownAvailable
	};

	useLayoutEffect(() => {
		setShowResults(false);
		searchbarInputRef?.current?.blur();
	}, [location.pathname]);

	useEffect(() => {
		window.addEventListener('keyup', handleEscapeKeyPress);
		window.addEventListener('keypress', handleAltFKeyPress);
		return () => {
			window.removeEventListener('keyup', handleEscapeKeyPress);
			window.removeEventListener('keypress', handleAltFKeyPress);
		};
	}, []);

	const openSearchResultPage = () => {
		navigate(createRouteUrl(RoutePath.SEARCH_RESULTS));
	};

	// EVENTS
	useOnClickOutside(searchbarRef, () => {
		setShowResults(false);
	}, true);

	const handleEscapeKeyPress = (event: KeyboardEvent) => {
		if (event.key === 'Escape' satisfies KeyboardEventValues) {
			event.preventDefault();
			searchbarInputRef?.current?.blur();
			setShowResults(false);
		}
	};

	const handleAltFKeyPress = (event: KeyboardEvent) => {
		if (event.keyCode === 402 && event.altKey) {
			event.preventDefault();
			searchbarInputRef?.current?.focus();
		}
	};

	const handleClear = (): void => {
		setSearchTerm('');
		setShowResults(false);
		searchData.resetStore();
	};

	const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		if (searchTerm === '') {
			return;
		}
		setShowResults(true);
		searchData.fetch(defaultCustomerServiceSearchDataProviderSortCriteria, createFilterCriteriaFromSearchString(searchTerm), false);
	};

	const handleFocus = () => {
		if (searchTerm !== '') {
			setShowResults(true);
		}
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(e.target.value);
	};

	// RENDER
	const renderClearIcon = (): ReactNode => {
		if (searchTerm === '') {
			return;
		}
		return (
			<span className="searchbar__header__clear" onClick={handleClear}>
				<IconSvg name={IconIdentifier.X_CIRCLE} />
			</span>
		);
	};

	const renderCustomerSearchResults = () => {
		if (customerSearchResults.length === 0) {
			return null;
		}
		return (
			<>
				<SearchbarResultsHeader caption={l10n.translate('platformCustomerServiceApp.searchbar.results.customers')} />
				<CustomerCollectionHeader />
				<Separator />
				{renderCustomerSearchResultItems()}
			</>
		);
	};

	const renderOrderSearchResults = () => {
		if (orderSearchResults.length === 0) {
			return null;
		}
		return (
			<>
				<SearchbarResultsHeader caption={l10n.translate('platformCustomerServiceApp.searchbar.results.orders')} />
				<OrderCollectionHeader />
				<Separator />
				{renderOrderSearchResultItems()}
			</>
		);
	};

	const renderMarketplaceOrderSearchResults = () => {
		if (marketplaceOrderSearchResults.length === 0) {
			return null;
		}
		return (
			<>
				<SearchbarResultsHeader caption={l10n.translate('platformCustomerServiceApp.searchbar.results.marketplaceOrders')} />
				<MarketplaceOrderCollectionHeader />
				<Separator />
				{renderMarketplaceOrderSearchResultItems()}
			</>
		);
	};

	const renderCustomerSearchResultItems = () => {
		return customerSearchResults.map((res) => {
			if (res.type !== CustomerServiceSearchResultType.CUSTOMER) {
				return null;
			}
			return (
				<CustomerCollectionItem key={res.id} customer={res.payload} disabled={customerId === res.id} />
			);
		});
	};

	const renderOrderSearchResultItems = () => {
		return orderSearchResults.map((res) => {
			if (res.type !== CustomerServiceSearchResultType.ORDER) {
				return null;
			}
			return (
				<OrderCollectionItem key={res.id} order={res.payload} disabled={orderId === res.id} />
			);
		});
	};

	const renderMarketplaceOrderSearchResultItems = () => {
		return marketplaceOrderSearchResults.map((res) => {
			if (res.type !== CustomerServiceSearchResultType.MARKETPLACE_ORDER) {
				return null;
			}
			return (
				<MarketplaceOrderCollectionItem key={res.id} order={res.payload} disabled={orderId === res.id} />
			);
		});
	};

	const renderPaginationInformation = () => {
		const showResultsPageDescription =
			l10n.translate('platformCustomerServiceApp.searchbar.results.showResultPageDescription.resultsFromTo') + ' '
			+ (customerSearchResults.length + orderSearchResults.length + marketplaceOrderSearchResults.length).toString() + ' '
			+ l10n.translate('platformCustomerServiceApp.searchbar.results.showResultPageDescription.of') + ' '
			+ resultCount.toString();

		return (
			<span className="searchbar__results__show-more__label"> {showResultsPageDescription} </span>
		);
	};

	const renderShowMore = () => {
		if (resultCount <= customerSearchResults.length + orderSearchResults.length + marketplaceOrderSearchResults.length) {
			return null;
		}
		return (
			<footer className="searchbar__results__show-more">
				{renderPaginationInformation()}
				<ButtonSecondary
					label={l10n.translate('platformCustomerServiceApp.searchbar.results.showResultPage')}
					onClick={openSearchResultPage}
					disabled={fetchPending}
				/>
			</footer>

		);
	};

	const renderResults = () => {
		if (dropdownAvailable && showResults && searchbarInputRef.current === document.activeElement) {
			return (
				<section className="searchbar__results">
					<CustomerServiceSearchDataSuspense pendingComponent={SpinnerDots}>
						<CustomerServiceSearchDataInterceptEmpty emptyStateComponent={SearchbarResultsEmpty}>

							{renderOrderSearchResults()}
							{renderMarketplaceOrderSearchResults()}
							{renderCustomerSearchResults()}

							{renderShowMore()}

						</CustomerServiceSearchDataInterceptEmpty>
					</CustomerServiceSearchDataSuspense>
				</section>
			);
		}
		return null;
	};

	return (
		<form action="POST" onSubmit={handleSubmit}>
			<section className={buildCssClassStringFromClassMap(searchbarClassMap)} ref={searchbarRef}>
				<label className="searchbar__header">
					<span className="searchbar__header__icon">
						<IconSvg name={IconIdentifier.MAGNIFYING_GLASS} />
					</span>
					<input
						ref={searchbarInputRef}
						className={buildCssClassStringFromClassMap(inputClassMap)}
						type="text"
						value={searchTerm}
						placeholder={l10n.translate('platformCustomerServiceApp.searchbar.placeholder')}
						onChange={handleChange}
						onFocus={handleFocus}
					/>
					{renderClearIcon()}
				</label>

				{renderResults()}

			</section>
		</form>
	);
};
