import { DecisionType, InstallationDecisionType } from '@abb-emobility/shared/domain-model';
import { ModelConverter } from '@abb-emobility/shared/domain-model-foundation';

type PrecheckRequestedEffort = {
	trade: string,
	expectedEffort: number
};

type PrecheckDecision = {
	decision: DecisionType,
	installationType: InstallationDecisionType
};

export type CustomerServiceOrderInformationPrecheckInformationModel = {
	readonly precheckDecision: PrecheckDecision,
	readonly requestedEffort: Array<PrecheckRequestedEffort>
};

export class CustomerServiceOrderInformationPrecheckInformationModelConverter extends ModelConverter<CustomerServiceOrderInformationPrecheckInformationModel> {
}
