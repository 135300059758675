import { CustomerServiceOrderCustomerModel } from '@abb-emobility/platform/domain-model';
import { customerModelMock } from '@abb-emobility/shared/api-integration';
import { Dto } from '@abb-emobility/shared/domain-model-foundation';

export const customerServiceOrderCustomerModelMock: Dto<CustomerServiceOrderCustomerModel> = {
	id: customerModelMock.id,
	firstName: 'Max',
	lastName: 'Mustermann',
	mailAddress: 'max@muster.de',
	companyName: 'Company Name'
};
