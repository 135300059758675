import {
	CustomerServiceCustomerApiClientFactory,
	CustomerServiceCustomerApiClientInterface
} from '@abb-emobility/platform/api-integration';
import { CustomerServiceCustomerModel } from '@abb-emobility/platform/domain-model';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import {
	createCrudEntitySlice,
	createCrudDeleteThunk,
	createCrudFetchEntityThunk,
	createCrudMutateThunk,
	createCrudSelectEntity,
	CrudEntityStore,
	CrudEntityStoreAccessor,
	ThunkApiConfig,
	createCrudSelectHypermediaLinks, createCrudEntityStoreSize, createCrudEntityStoreEntryIds
} from '@abb-emobility/shared/data-provider-foundation';
import { Nullable } from '@abb-emobility/shared/util';

// Basic definition
export const customerServiceCustomerEntityStoreName = 'customer-service-customer-entity-crud';
export type CustomerServiceCustomerEntityStore = { [customerServiceCustomerEntityStoreName]: CrudEntityStore<CustomerServiceCustomerModel> };

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the
const createApiClient = (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) => {
	return CustomerServiceCustomerApiClientFactory.create(apiBaseUrl, jsonWebToken);
};
const fetchThunk = createCrudFetchEntityThunk<CustomerServiceCustomerModel, CustomerServiceCustomerEntityStore, CustomerServiceCustomerApiClientInterface>(customerServiceCustomerEntityStoreName, createApiClient);
const mutateThunk = createCrudMutateThunk<CustomerServiceCustomerModel, CustomerServiceCustomerApiClientInterface>(customerServiceCustomerEntityStoreName, createApiClient);
const deleteThunk = createCrudDeleteThunk<CustomerServiceCustomerModel, CustomerServiceCustomerApiClientInterface>(customerServiceCustomerEntityStoreName, createApiClient);

// Slice definition
export const customerServiceCustomerEntitySlice = createCrudEntitySlice<CustomerServiceCustomerModel, ThunkApiConfig>(customerServiceCustomerEntityStoreName, fetchThunk, mutateThunk, deleteThunk);
const { resolveFetchStatus, resolveActionStatus, resetStore } = customerServiceCustomerEntitySlice.actions;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
const select = createCrudSelectEntity<CustomerServiceCustomerModel, CustomerServiceCustomerEntityStore>(customerServiceCustomerEntityStoreName);
const selectHypermediaLinks = createCrudSelectHypermediaLinks<CustomerServiceCustomerModel, CustomerServiceCustomerEntityStore>(customerServiceCustomerEntityStoreName);
const storeSize = createCrudEntityStoreSize<CustomerServiceCustomerModel, CustomerServiceCustomerEntityStore>(customerServiceCustomerEntityStoreName);
const storeEntryIds = createCrudEntityStoreEntryIds<CustomerServiceCustomerModel, CustomerServiceCustomerEntityStore>(customerServiceCustomerEntityStoreName);

// Export the store accessors to be used by the data provider value
export const customerServiceCustomerEntityStoreAccessors = {
	fetch: fetchThunk,
	mutate: mutateThunk,
	delete: deleteThunk,
	resolveFetchStatus,
	resolveActionStatus,
	select,
	selectHypermediaLinks,
	resetStore,
	storeSize,
	storeEntryIds
} as CrudEntityStoreAccessor<CustomerServiceCustomerModel, CustomerServiceCustomerEntityStore>;
