import { OrderCancelReason } from '@abb-emobility/shared/domain-model';
import { ModelConverter } from '@abb-emobility/shared/domain-model-foundation';

export type CustomerServiceCancelOrderInvocationPayloadModel = {
	reason: OrderCancelReason,
	comment?: string
};

export class CustomerServiceCancelOrderInvocationPayloadModelConverter extends ModelConverter<CustomerServiceCancelOrderInvocationPayloadModel> {
}
