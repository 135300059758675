import React, { useEffect, useState } from 'react';

import { CustomerServiceOrderEntityDataProvider } from '@abb-emobility/platform/data-provider';
import { TaskActionStatus } from '@abb-emobility/shared/data-provider-foundation';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { UiErrorHandler } from '@abb-emobility/shared/ui-error-handler';
import {
	ButtonGhost,
	ButtonGroup,
	ButtonPrimary,
	SpinnerCircle,
	SubmissionStatus,
	useModalDialogue,
	useModalDialogueManager,
	InputCheckbox,
	SegmentHeader,
	TabularInformationItem,
	TabularInformationItemType,
	TabularInformationRow,
	Separator,
	IconIdentifier,
	HintLevel,
	Hint
} from '@abb-emobility/shared/ui-primitive';
import { useAnyTaskEntityData } from '@abb-emobility/usertask/data-provider';
import {
	UncoveredInstallationAddressTaskModel
} from '@abb-emobility/usertask/domain-model';

import { CustomerInformation } from '../sections/customer-information/CustomerInformation';
import { OrderInformation } from '../sections/order-information/OrderInformation';
import { TaskInformation } from '../sections/task-information/TaskInformation';

import './TaskUncoveredInstallationAddress.scss';

export type UncoveredAddressValidationProps = {
	task: UncoveredInstallationAddressTaskModel
};

export function TaskUncoveredInstallationAddress(props: UncoveredAddressValidationProps) {

	const { task } = props;

	const l10n = useL10n();
	const modalDialogue = useModalDialogue();
	const modalDialogueManager = useModalDialogueManager();
	const anyTaskData = useAnyTaskEntityData();

	const [taskCompletionButtonState, setTaskCompletionButtonState] = useState<SubmissionStatus>(SubmissionStatus.IDLE);

	const [isCancelInfoRead, setIsCancelInfoRead] = useState<boolean>(true);

	anyTaskData.useActionStatusEffect([TaskActionStatus.COMPLETE_PENDING], () => {
		setTaskCompletionButtonState(SubmissionStatus.PENDING);
	}, false);

	anyTaskData.useActionStatusEffect([TaskActionStatus.COMPLETE_SUCCESS], () => {
		setTaskCompletionButtonState(SubmissionStatus.DONE);
	}, true);

	anyTaskData.useActionStatusEffect([TaskActionStatus.IDLE], () => {
		setTaskCompletionButtonState(SubmissionStatus.IDLE);
	}, true);

	useEffect(() => {
		modalDialogue.setFooter(
			<ButtonGroup>
				<ButtonGhost
					label={l10n.translate('platformCustomerServiceApp.task.preCheck.button.close')}
					onClick={handleCancel}
				/>
				<ButtonPrimary
					label={l10n.translate('platformCustomerServiceApp.task.preCheck.button.submit')}
					submissionStatus={taskCompletionButtonState}
					onClick={handleTaskCompletion}
					disabled={isCancelInfoRead}
				/>
			</ButtonGroup>
		);
		return () => {
			modalDialogue.unsetFooter();
		};
	}, [isCancelInfoRead]);

	const handleTaskCompletion = () => {
		anyTaskData.complete(task, {
			...task.payload
		});
	};

	const handleCancel = (): void => {
		modalDialogueManager.pop();
	};

	const handleInfoCheckbox = (checked: boolean) => {
		setIsCancelInfoRead(!checked);
	};

	return (
		<>
			<TaskInformation task={task} type={task.type} />

			<UiErrorHandler>
				<CustomerServiceOrderEntityDataProvider
					primaryKey={task.orderId}
					pendingComponent={SpinnerCircle}
				>
					<OrderInformation />
				</CustomerServiceOrderEntityDataProvider>
			</UiErrorHandler>

			<CustomerInformation customer={task.payload.customer} showDeliveryAddress={false} />

			<div className="task-uncovered-installation-address">
				<SegmentHeader caption={l10n.translate('platformCustomerServiceApp.modal.task.section.uncoveredInstallationAddress.info')} />

				<Separator />
				<TabularInformationRow>
					<TabularInformationItem
						label={l10n.translate('platformCustomerServiceApp.modal.task.section.uncoveredInstallationAddress.info')}
						variant={TabularInformationItemType.GREY}
					/>
					<TabularInformationItem label={task.installationAddress?.street + ' ' + task.installationAddress?.zip + ' ' + task.installationAddress?.city} />
				</TabularInformationRow>

				<div className="task-uncovered-installation-address__cancel-info">
					<Hint
						gray={true}
						heading={l10n.translate('platformCustomerServiceApp.modal.task.section.uncoveredInstallationAddress.infoHeading')}
						message={l10n.translate('platformCustomerServiceApp.modal.task.section.uncoveredInstallationAddress.infoDescription')}
						icon={IconIdentifier.INFO}
						level={HintLevel.INFO}
					/>
					<div className="task-uncovered-installation-address__checkbox">
						<InputCheckbox
							label={l10n.translate('platformCustomerServiceApp.modal.task.section.uncoveredInstallationAddress.message')}
							value=""
							onChange={(checked: boolean) => (handleInfoCheckbox(checked))}
						/>
					</div>
				</div>
			</div>
		</>
	);

}
