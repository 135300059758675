import {
	ArrayOfDownloadFileFieldNames, ArrayOfTimestampFieldNames,
	DownloadFile,
	DownloadFileFieldNames,
	ModelConverter,
	ModelPrimaryKey, Timestamp, TimestampFieldNames
} from '@abb-emobility/shared/domain-model-foundation';

import { CustomerServiceOrderDownloadFileType } from './CustomerServiceOrderDownloadFileModel.enums';

export type CustomerServiceOrderDownloadFileModel = {
	readonly id: ModelPrimaryKey,
	readonly orderId: ModelPrimaryKey,
	readonly createdAt: Timestamp,
	readonly file: DownloadFile,
	readonly fileType: CustomerServiceOrderDownloadFileType
};

export class CustomerServiceOrderDownloadFileModelConverter extends ModelConverter<CustomerServiceOrderDownloadFileModel> {

	override getTimestampFields(): Array<TimestampFieldNames<CustomerServiceOrderDownloadFileModel> | ArrayOfTimestampFieldNames<CustomerServiceOrderDownloadFileModel>> {
		return ['createdAt'];
	}

	override getDownloadFileFields(): Array<DownloadFileFieldNames<CustomerServiceOrderDownloadFileModel> | ArrayOfDownloadFileFieldNames<CustomerServiceOrderDownloadFileModel>> {
		return ['file'];
	}

}
