import React, {  ReactNode } from 'react';

import {
	CustomerServiceOrderInformationCollectionDataInterceptEmpty,
	CustomerServiceOrderInformationEntityDataProvider,
	useCustomerServiceOrderInformationCollectionData
} from '@abb-emobility/platform/data-provider';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { UiErrorHandler } from '@abb-emobility/shared/ui-error-handler';
import {
	Card, Cardlayout,
	CardSection,
	Direction,
	ExpandableSectionHeader,
	Information,
	InformationItems,
	NotificationBig,
	NotificationBigStatus,
	SegmentHeader,
	SpinnerCircle
} from '@abb-emobility/shared/ui-primitive';
import { l10nLiteralFromEnumValue } from '@abb-emobility/shared/util';

import './OrderDetailInstallationInformation.scss';
import { OrderDetailInstallationInformationFactory } from './OrderDetailInstallationInformationFactory';

export function OrderDetailInstallationInformation() {

	const l10n = useL10n();

	const informationCollectionData = useCustomerServiceOrderInformationCollectionData();
	const informationCollection = informationCollectionData.query();

	const renderEmptyState = (): ReactNode => {
		return (
			<NotificationBig
				status={NotificationBigStatus.EMPTY2}
				heading={l10n.translate('platformCustomerServiceApp.orderDetail.installation.information.emptyState.heading')}
				message={l10n.translate('platformCustomerServiceApp.orderDetail.installation.information.emptyState.message')}
			/>
		);
	};
	const renderSections = () => {
		return informationCollection.map((orderInformation) => {
			return (
				<Information key={orderInformation.id}>
					<ExpandableSectionHeader
						heading={l10n.translate(l10nLiteralFromEnumValue(
							orderInformation.type,
							'platformCustomerServiceApp.orderDetail.installation.information.types'
						))}
					/>
					<InformationItems>
						<UiErrorHandler>
							<CustomerServiceOrderInformationEntityDataProvider
								primaryKey={orderInformation.id}
								pendingComponent={SpinnerCircle}
							>
								<OrderDetailInstallationInformationFactory />
							</CustomerServiceOrderInformationEntityDataProvider>
						</UiErrorHandler>
					</InformationItems>
				</Information>
			);
		});
	};

	return (
		<Card>
			<SegmentHeader caption={l10n.translate('platformCustomerServiceApp.orderDetail.installation.information.heading')} />
			<Cardlayout>
				<CardSection direction={Direction.COLUMN}>
					<section className="order-detail-installation-information__items">
						<CustomerServiceOrderInformationCollectionDataInterceptEmpty emptyStateComponent={renderEmptyState}>
							{renderSections()}
						</CustomerServiceOrderInformationCollectionDataInterceptEmpty>
					</section>
				</CardSection>
			</Cardlayout>
		</Card>
	);
}
