import React, { useEffect, useState } from 'react';

import {
	CustomerServiceOrderLineItemModel,
	CustomerServiceReturnOrderInvocationPayloadModelConverter,
	CustomerServiceReturnOrderPayloadModelConverter
} from '@abb-emobility/platform/domain-model';
import { CommandStatus, useCommandData } from '@abb-emobility/shared/command-api';
import { OrderLineItemModel, OrderReturnReason } from '@abb-emobility/shared/domain-model';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	ActionModalFooter,
	ButtonGhost,
	ButtonPrimary,
	InputSelect,
	InputSelectOption,
	InputTextArea,
	NotificationBig,
	NotificationBigStatus,
	SectionHeader,
	SegmentHeader,
	SpinnerCircle,
	SubmissionStatus,
	SuccessFeeback,
	SuccessImageIdentifier,
	useModalDialogue,
	useModalDialogueManager
} from '@abb-emobility/shared/ui-primitive';
import { l10nLiteralFromEnumValue, Nullable } from '@abb-emobility/shared/util';

import { OrderLineItem } from '../../../order-line-item/OrderLineItem';
import { OrderLineItemCollectionHeader } from '../../../order-line-item-collection-header/OrderLineItemCollectionHeader';
import { HypermediaAction } from '../../ActionModalFactory.types';

import './ReturnOrderActionModal.scss';

type ReturnOrderActionModalProps = {
	action: HypermediaAction
};

export function ReturnOrderActionModal(props: ReturnOrderActionModalProps) {

	const { action } = props;

	const l10n = useL10n();
	const modalDialogue = useModalDialogue();
	const dialogueManager = useModalDialogueManager();

	const commandData = useCommandData(
		action.hypermediaLink,
		new CustomerServiceReturnOrderPayloadModelConverter(),
		new CustomerServiceReturnOrderInvocationPayloadModelConverter()
	);
	const fetchPayload = commandData.query();
	const fetchError = commandData.queryFetchError();
	const fetchStatus = commandData.queryFetchStatus();
	const commandInvocationStatus = commandData.queryInvocationStatus();
	const commandInvocationError = commandData.queryInvocationError();

	const [reason, setReason] = useState<Nullable<OrderReturnReason>>(null);
	const [orderLineItems, setOrderLineItems] = useState<Array<OrderLineItemModel>>([]);
	const [comment, setComment] = useState<Nullable<string>>(null);

	useEffect(() => {
		if (fetchStatus === CommandStatus.SUCCESSFULL && fetchPayload !== null) {
			setOrderLineItems(fetchPayload.orderLineItems);
		}
		return;

	}, [fetchStatus]);

	useEffect(() => {
		const handleSubmitAction = () => {
			if(reason !==null){
			commandData.invoke({
				orderLineItems: orderLineItems.map(item => {
					return {
						id: item.id
					};
				}),
				reason: reason,
				comment: comment ?? undefined
			});
		}
		};

		const formIsValid = (reason !== null) && (reason !== OrderReturnReason.OTHER) || ((reason === OrderReturnReason.OTHER) && (comment?.length ?? 0) > 0);

		switch (commandInvocationStatus) {
			case CommandStatus.IDLE:
				modalDialogue.setFooter(
					<ActionModalFooter>
						<ButtonGhost
							label={l10n.translate('platformCustomerServiceApp.actionModal.returnOrder.footer.cancel')}
							onClick={handleDismissDialogue}
						/>
						<ButtonPrimary
							label={l10n.translate('platformCustomerServiceApp.actionModal.returnOrder.footer.confirm')}
							submissionStatus={SubmissionStatus.IDLE}
							onClick={handleSubmitAction}
							disabled={!formIsValid}
						/>
					</ActionModalFooter>
				);
				break;
			case CommandStatus.PENDING:
				modalDialogue.setFooter(
					<ActionModalFooter>
						<ButtonGhost
							label={l10n.translate('platformCustomerServiceApp.actionModal.returnOrder.footer.cancel')}
							onClick={handleDismissDialogue}
						/>
						<ButtonPrimary
							label={l10n.translate('platformCustomerServiceApp.actionModal.returnOrder.footer.confirm')}
							submissionStatus={SubmissionStatus.PENDING}
							onClick={handleSubmitAction}
						/>
					</ActionModalFooter>
				);
				break;
			case CommandStatus.SUCCESSFULL:
			case CommandStatus.FAILED:
				modalDialogue.unsetFooter();
				break;
		}

	}, [commandInvocationStatus, orderLineItems, reason, comment]);

	const options: Array<InputSelectOption<OrderReturnReason>> = Object.values(OrderReturnReason)
		.map((value) => (
				{
					label: l10n.translate(l10nLiteralFromEnumValue(
							value,
							'platformCustomerServiceApp.actionModal.returnOrder.returnReason'
						)
					),
					value: value
				}
			)
		);

	const handleDismissDialogue = () => {
		dialogueManager.pop();
	};

	const handleReasonChange = (value: OrderReturnReason): void => {
		setReason(value);
	};

	const handleCommentChange = (value: string): void => {
		setComment(value);
	};

	const renderReasonDescription = () => {
		if (reason === OrderReturnReason.OTHER) {
			return (
				<InputTextArea
					placeholder={l10n.translate('platformCustomerServiceApp.actionModal.returnOrder.note')}
					rows={5}
					onChange={handleCommentChange}
					value={comment ?? ''}
				/>
			);
		}

		return;
	};
	const renderOrderLineItems = () => {
		if (fetchPayload?.orderLineItems?.length === 0) {
			return null;
		}

		return fetchPayload?.orderLineItems.map((item: CustomerServiceOrderLineItemModel, idx) => {
				return (
					<OrderLineItem key={item.id} position={idx} orderLineItem={item} />
				);
			}
		);
	};

	if (fetchPayload === null) {
		return (
			<SpinnerCircle />
		);
	}

	if (fetchError) {
		return (
			<NotificationBig
				status={NotificationBigStatus.ERROR}
				heading={l10n.translate('platformCustomerServiceApp.actionModal.returnOrder.error.loading.heading')}
				message={l10n.translate('platformCustomerServiceApp.actionModal.returnOrder.error.loading.message')}
				subline={fetchError?.message}
			/>
		);
	}

	if (commandInvocationStatus === CommandStatus.SUCCESSFULL) {
		return (
			<SuccessFeeback
				heading={l10n.translate('platformCustomerServiceApp.actionModal.returnOrder.content.successHeading')}
				image={SuccessImageIdentifier.IMAGE4}
			>
				<ButtonGhost
					label={l10n.translate('platformCustomerServiceApp.actionModal.returnOrder.content.closeButtonLabel')}
					onClick={handleDismissDialogue}
				/>
			</SuccessFeeback>
		);
	}

	if (commandInvocationStatus === CommandStatus.FAILED) {
		return (
			<NotificationBig
				status={NotificationBigStatus.ERROR}
				heading={l10n.translate('platformCustomerServiceApp.actionModal.returnOrder.error.writing.heading')}
				message={l10n.translate('platformCustomerServiceApp.actionModal.returnOrder.error.writing.message')}
				subline={commandInvocationError?.message}
			/>

		);
	}

	return (
		<section className="return-order-action-modal">
			<div>
				<section className="return-order-action-modal__section">
					<SectionHeader heading={l10n.translate('platformCustomerServiceApp.actionModal.returnOrder.selectReturn')} />
				</section>

				<SegmentHeader caption={l10n.translate('platformCustomerServiceApp.actionModal.returnOrder.heading')} />

				<OrderLineItemCollectionHeader />
				{renderOrderLineItems()}
			</div>

			<section className="return-order-action-modal__section">
				<SectionHeader heading={l10n.translate('platformCustomerServiceApp.actionModal.returnOrder.selectReason')} />
				<div className="return-order-action-modal__section__item">
					<InputSelect<OrderReturnReason>
						options={options}
						onSelect={handleReasonChange}
						value={reason === null ? undefined : reason}
						placeholder={l10n.translate('platformCustomerServiceApp.actionModal.pleaseSelect')}
					/>
					{renderReasonDescription()}
				</div>
			</section>
		</section>
	);
}
