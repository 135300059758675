import { CustomerServiceMarketplaceOrderCollectionItemModel } from '@abb-emobility/platform/domain-model';
import { MarketplaceOrderState } from '@abb-emobility/shared/domain-model';
import { Dto, createTimestampDtoFromDate } from '@abb-emobility/shared/domain-model-foundation';
import { IsoLanguageCode } from '@abb-emobility/shared/iso-language-code';

import { customerServiceOrderCustomerModelMock } from './CustomerServiceOrderCustomerModel.mock';

export const customerServiceMarketplaceOrderCollectionItemModelMock: Dto<CustomerServiceMarketplaceOrderCollectionItemModel> = {
	id: '1',
	marketplaceOrderId: 'B2C-627',
	marketplace: 'Smart Marketplace Germany',
	state: MarketplaceOrderState.DONE,
	orderDate: createTimestampDtoFromDate(new Date('2022-01-01T16:30:12.345Z')),
	languageCode: IsoLanguageCode.DE,
	customer: customerServiceOrderCustomerModelMock
};
