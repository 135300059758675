import { CustomerServiceMarketplaceOrderModel, CustomerServiceOrderModel } from '@abb-emobility/platform/domain-model';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	SegmentHeader,
	TabularInformationItem,
	TabularInformationItemType,
	TabularInformationRow
} from '@abb-emobility/shared/ui-primitive';

export type OrderInformationSectionProps = {
	order: CustomerServiceOrderModel | CustomerServiceMarketplaceOrderModel
};

export function OrderInformationSection(props: OrderInformationSectionProps) {

	const l10n = useL10n();

	const { order } = props;

	return (
		<>
			<SegmentHeader caption={l10n.translate('platformCustomerServiceApp.orderDetail.overview.orderInformation.heading')} />

			<TabularInformationRow>
				<TabularInformationItem
					label={l10n.translate('platformCustomerServiceApp.orderDetail.overview.orderInformation.tableItem.phone')}
					variant={TabularInformationItemType.GREY}
				/>
				<TabularInformationItem label={order.phoneNumber} />
			</TabularInformationRow>

			<TabularInformationRow>
				<TabularInformationItem
					label={l10n.translate('platformCustomerServiceApp.orderDetail.overview.orderInformation.tableItem.language')}
					variant={TabularInformationItemType.GREY}
				/>
				<TabularInformationItem label={order.languageCode.toUpperCase()} />
			</TabularInformationRow>

			<TabularInformationRow>
				<TabularInformationItem
					label={l10n.translate('platformCustomerServiceApp.orderDetail.overview.orderInformation.tableItem.deliveryAddress')}
					variant={TabularInformationItemType.GREY}
				/>
				<TabularInformationItem
					label={
						order.deliveryAddress.street + ', ' +
						order.deliveryAddress.zip + ' ' +
						order.deliveryAddress.city + ', ' +
						order.deliveryAddress.countryCode
					}
				/>
			</TabularInformationRow>
		</>
	);
}
