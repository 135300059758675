import { CustomerServiceCustomerModel } from '@abb-emobility/platform/domain-model';
import { Dto, createTimestampDtoFromDate } from '@abb-emobility/shared/domain-model-foundation';

// TODO: use global modal (common/CustomerModel) again
export const customerServiceCustomerModelMock: Dto<CustomerServiceCustomerModel> = {
	id: '1',
	mailAddress: 'max@muster.de',
	firstName: 'Max',
	lastName: 'Mustermann',
	companyName: 'Company Name',
	firstOrder: createTimestampDtoFromDate(new Date('2022-01-01T16:30:12.345Z')),
	lastOrder: createTimestampDtoFromDate(new Date('2022-01-01T16:30:12.345Z')),
	runningOrderCount: 2
};
