import React from 'react';

import { CustomerServiceSearchDataProvider } from '@abb-emobility/platform/data-provider';
import { FetchMode } from '@abb-emobility/shared/data-provider-foundation';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { UiErrorHandler } from '@abb-emobility/shared/ui-error-handler';
import { SpinnerCircle, ViewHeader } from '@abb-emobility/shared/ui-primitive';

import { SearchResultCollection } from '../components/search-result-collection/SearchResultCollection';
import { useSearchbarContentModifier } from '../components/searchbar-content/SearchbarContent.hooks';

export function SearchResultsView() {

	const l10n = useL10n();

	useSearchbarContentModifier(null, null, false);

	return (
		<>
			<ViewHeader heading={l10n.translate('platformCustomerServiceApp.searchResultCollection.heading')} />
			<UiErrorHandler>
				<CustomerServiceSearchDataProvider
					pendingComponent={SpinnerCircle}
					fetchMode={FetchMode.LAZY}
					suspense={false}
				>
					<SearchResultCollection />
				</CustomerServiceSearchDataProvider>
			</UiErrorHandler>
		</>

	);
}
