import React, { ReactNode } from 'react';

import './ViewSubheaderItemValueNumber.scss';

export type ViewSubheaderItemValueProps = {
	children: ReactNode
};

export function ViewSubheaderItemValueNumber(props: ViewSubheaderItemValueProps): React.JSX.Element {
	const { children } = props;



	return (
		<span className={"view-subheader-item-value-number"}>
			{children}
		</span>
	);
}
