import { AnyCustomerServiceCommentModel } from '@abb-emobility/platform/domain-model';
import { DataInterceptEmptyProps } from '@abb-emobility/shared/data-provider-foundation';

import { useCustomerServiceOrderCommentCollectionData } from './CustomerServiceOrderCommentCollectionDataConsumer';

export function CustomerServiceOrderCommentCollectionDataInterceptEmpty(props: DataInterceptEmptyProps<AnyCustomerServiceCommentModel>) {
	const { children, emptyStateComponent, filter } = props;

	const collectionData = useCustomerServiceOrderCommentCollectionData();
	const hasResults = collectionData.hasResults(filter);

	if (!hasResults) {
		return (
			<>
				{emptyStateComponent()}
			</>
		);
	}

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{children}
		</>
	);
}
