/* eslint-disable @typescript-eslint/no-explicit-any */
import { ModelConverter, ModelConverterInterface } from '@abb-emobility/shared/domain-model-foundation';

import { CustomerServiceOrderLineItemModel, CustomerServiceOrderLineItemModelConverter } from '../../CustomerServiceOrderLineItemModel';

export type CustomerServiceReplaceableOrderLineItemModel = {
	orderLineItem: CustomerServiceOrderLineItemModel,
	replaceable: boolean
};

export class CustomerServiceReplaceableOrderLineItemModelConverter extends ModelConverter<CustomerServiceReplaceableOrderLineItemModel> {

	override getFieldConverterMapByModel(): Map<keyof CustomerServiceReplaceableOrderLineItemModel, ModelConverterInterface<CustomerServiceOrderLineItemModel>> {
		return new Map([
			['orderLineItem', new CustomerServiceOrderLineItemModelConverter()]
		]);
	}

}

export type CustomerServiceReplaceOrderPayloadModel = {
	replaceableOrderLineItems: Array<CustomerServiceReplaceableOrderLineItemModel>
};

export class CustomerServiceReplaceOrderPayloadModelConverter extends ModelConverter<CustomerServiceReplaceOrderPayloadModel> {

	override getFieldConverterMapByModel(): Map<keyof CustomerServiceReplaceOrderPayloadModel, ModelConverterInterface<any>> {
		return new Map([
			['replaceableOrderLineItems', new CustomerServiceReplaceableOrderLineItemModelConverter()]
		]);
	}

}
