import { TaskCompletionCommentModelConverter } from '@abb-emobility/shared/domain-model';
import { ModelConverter } from '@abb-emobility/shared/domain-model-foundation';
import { ArrayOfTimestampFieldNames, Dto, TimestampFieldNames } from '@abb-emobility/shared/domain-model-foundation';

import { CustomerServiceOrderInformationFoundationModel } from './foundation/CustomerServiceOrderInformationFoundationModel';
import { CustomerServiceOrderInformationType } from './foundation/CustomerServiceOrderInformationType';
import {
	CustomerServiceOrderInformationApprovalInformationModel,
	CustomerServiceOrderInformationApprovalInformationModelConverter
} from './order-information/CustomerServiceOrderInformationApprovalInformationModel';
import {
	CustomerServiceOrderInformationBasicAnswersInformationModel,
	CustomerServiceOrderInformationBasicAnswersInformationModelConverter
} from './order-information/CustomerServiceOrderInformationBasicAnswersInformationModel';
import {
	CustomerServiceOrderInformationGridOperatorDecisionInformationModel,
	CustomerServiceOrderInformationGridOperatorDecisionInformationModelConverter
} from './order-information/CustomerServiceOrderInformationGridOperatorDecisionInformationModel';
import {
	CustomerServiceOrderInformationGridOperatorRegistrationInformationModel,
	CustomerServiceOrderInformationGridOperatorRegistrationInformationModelConverter
} from './order-information/CustomerServiceOrderInformationGridOperatorRegistrationInformationModel';
import {
	CustomerServiceOrderInformationPrecheckInformationModel, CustomerServiceOrderInformationPrecheckInformationModelConverter
} from './order-information/CustomerServiceOrderInformationPrecheckInformationModel';
import {
	CustomerServiceOrderInformationQuotationInformationModel, CustomerServiceOrderInformationQuotationInformationModelConverter
} from './order-information/CustomerServiceOrderInformationQuotationInformationModel';

export type AnyCustomerServiceOrderInformationModel =
	CustomerServiceOrderInformationBasicAnswersModel |
	CustomerServiceOrderInformationGridOperatorRegistrationModel |
	CustomerServiceOrderInformationGridOperatorDecisionModel |
	CustomerServiceOrderInformationPrecheckModel |
	CustomerServiceOrderInformationQuotationModel |
	CustomerServiceOrderInformationApprovalModel;

export type AnyCustomerServiceOrderInformationPayloadModel<Model extends AnyCustomerServiceOrderInformationModel> = Model['payload'];

export type CustomerServiceOrderInformationBasicAnswersModel = {
	type: CustomerServiceOrderInformationType.BASIC_ANSWERS,
	payload: CustomerServiceOrderInformationBasicAnswersInformationModel
} & CustomerServiceOrderInformationFoundationModel;

export type CustomerServiceOrderInformationGridOperatorRegistrationModel = {
	type: CustomerServiceOrderInformationType.GRID_OPERATOR_REGISTRATION,
	payload: CustomerServiceOrderInformationGridOperatorRegistrationInformationModel
} & CustomerServiceOrderInformationFoundationModel;

export type CustomerServiceOrderInformationGridOperatorDecisionModel = {
	type: CustomerServiceOrderInformationType.GRID_OPERATOR_DECISION,
	payload: CustomerServiceOrderInformationGridOperatorDecisionInformationModel
} & CustomerServiceOrderInformationFoundationModel;

export type CustomerServiceOrderInformationPrecheckModel = {
	type: CustomerServiceOrderInformationType.PRE_CHECK,
	payload: CustomerServiceOrderInformationPrecheckInformationModel
} & CustomerServiceOrderInformationFoundationModel;

export type CustomerServiceOrderInformationQuotationModel = {
	type: CustomerServiceOrderInformationType.OFFER,
	payload: CustomerServiceOrderInformationQuotationInformationModel
} & CustomerServiceOrderInformationFoundationModel;

export type CustomerServiceOrderInformationApprovalModel = {
	type: CustomerServiceOrderInformationType.APPROVAL,
	payload: CustomerServiceOrderInformationApprovalInformationModel
} & CustomerServiceOrderInformationFoundationModel;

export class AnyCustomerServiceOrderInformationModelConverter extends ModelConverter<AnyCustomerServiceOrderInformationModel> {

	override getTimestampFields(): Array<TimestampFieldNames<AnyCustomerServiceOrderInformationModel> | ArrayOfTimestampFieldNames<AnyCustomerServiceOrderInformationModel>> {
		return ['createdAt'];
	}

	override getFieldConverterMapByModel(
		model: AnyCustomerServiceOrderInformationModel | Dto<AnyCustomerServiceOrderInformationModel>
	) {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const converterMap = new Map<keyof AnyCustomerServiceOrderInformationModel, any>([
			['comment', new TaskCompletionCommentModelConverter()]
		]);
		switch (model.type) {
			case CustomerServiceOrderInformationType.BASIC_ANSWERS:
				converterMap.set('payload', new CustomerServiceOrderInformationBasicAnswersInformationModelConverter());
				break;
			case CustomerServiceOrderInformationType.GRID_OPERATOR_REGISTRATION:
				converterMap.set('payload', new CustomerServiceOrderInformationGridOperatorRegistrationInformationModelConverter());
				break;
			case CustomerServiceOrderInformationType.GRID_OPERATOR_DECISION:
				converterMap.set('payload', new CustomerServiceOrderInformationGridOperatorDecisionInformationModelConverter());
				break;
			case CustomerServiceOrderInformationType.PRE_CHECK:
				converterMap.set('payload', new CustomerServiceOrderInformationPrecheckInformationModelConverter());
				break;
			case CustomerServiceOrderInformationType.OFFER:
				converterMap.set('payload', new CustomerServiceOrderInformationQuotationInformationModelConverter());
				break;
			case CustomerServiceOrderInformationType.APPROVAL:
				converterMap.set('payload', new CustomerServiceOrderInformationApprovalInformationModelConverter());
				break;
		}
		return converterMap;
	}

}
