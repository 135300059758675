import { ModelConverter, ModelConverterInterface } from '@abb-emobility/shared/domain-model-foundation';

import { CustomerServiceOrderLineItemModel, CustomerServiceOrderLineItemModelConverter } from '../../CustomerServiceOrderLineItemModel';

export type CustomerServiceCancelOrderPayloadModel = {
	orderLineItems: Array<CustomerServiceOrderLineItemModel>
};

export class CustomerServiceCancelOrderPayloadModelConverter extends ModelConverter<CustomerServiceCancelOrderPayloadModel> {

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	override getFieldConverterMapByModel(): Map<keyof CustomerServiceCancelOrderPayloadModel, ModelConverterInterface<any>> {
		return new Map<keyof CustomerServiceCancelOrderPayloadModel, ModelConverterInterface<CustomerServiceOrderLineItemModel>>([
			['orderLineItems', new CustomerServiceOrderLineItemModelConverter()]
		]);
	}
}
