import { ReactNode } from 'react';

import { CustomerServiceOrderLineItemModel, isPrizingModel } from '@abb-emobility/platform/domain-model';
import {
	calculateMajorUnitFromMinorUnit,
	Currency,
	CurrencyCode,
	getCurrencyByCode
} from '@abb-emobility/shared/currency';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { TabularInformationItem, TabularInformationItemType, TabularInformationRow } from '@abb-emobility/shared/ui-primitive';
import { Nullable } from '@abb-emobility/shared/util';

export type OrderLineItemProps = {
	position: number,
	orderLineItem: CustomerServiceOrderLineItemModel,
	currencyCode?: CurrencyCode
	children?: ReactNode
};

// TODO: remove currencyCode and generic props when backend has changed
export function OrderLineItem(props: OrderLineItemProps) {

	const { position, orderLineItem, currencyCode = null, children } = props;

	const l10n = useL10n();

	const netPrice = orderLineItem.price ?? null;
	const informationItemType = orderLineItem.cancelled ? TabularInformationItemType.DISABLED : undefined;

	const renderPrice = () => {
		if (netPrice === null) {
			return null;
		}

		let price: number;
		let currency: Nullable<Currency>;

		if (isPrizingModel(netPrice)) {
			price = netPrice.amount;
			currency = getCurrencyByCode(netPrice.currency);
		} else if (currencyCode === null) {
			price = netPrice;
			currency = getCurrencyByCode(CurrencyCode.EUR);
		} else {
			price = netPrice;
			currency = getCurrencyByCode(currencyCode);
		}

		if (currency !== null) {
			return (
				<TabularInformationItem
					label={`${l10n.formatNumber(calculateMajorUnitFromMinorUnit(currency.code, price), currency.fraction)} ${currency.symbol}`}
					variant={informationItemType}
				/>
			);
		}

		return (
			<TabularInformationItem
				label={l10n.formatNumber(price / 100, 2)}
				variant={informationItemType}
			/>
		);
	};

	return (
		<TabularInformationRow>
			{children}
			<TabularInformationItem label={`#${String(position + 1).padStart(2, '0')}`} variant={TabularInformationItemType.GREY} />
			<TabularInformationItem label={orderLineItem.productCode} variant={informationItemType} />
			<TabularInformationItem label={orderLineItem.productName} flex={1} variant={informationItemType} />
			{renderPrice()}
		</TabularInformationRow>
	);
}
