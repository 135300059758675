import { CustomerServiceOrderDownloadFileModel } from '@abb-emobility/platform/domain-model';
import { CrudApiClient } from '@abb-emobility/shared/api-integration-foundation';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import { Nullable } from '@abb-emobility/shared/util';

import { CustomerServiceOrderDownloadFileApiClient } from './CustomerServiceOrderDownloadFileApiClient';
import { CustomerServiceOrderDownloadFileApiClientMock } from './CustomerServiceOrderDownloadFileApiClientMock';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CustomerServiceOrderDownloadFileApiClientInterface extends CrudApiClient<CustomerServiceOrderDownloadFileModel> {
}

export class CustomerServiceOrderDownloadFileApiClientFactory {

	private static mockConfigured = false;

	public static create(baseUrl: string, jsonWebToken: Nullable<JsonWebToken> = null): CustomerServiceOrderDownloadFileApiClientInterface {
		if (process.env.NX_PLATFORM_CS_ORDER_FILE_API_MOCKED === 'true') {
			const apiClientMock = new CustomerServiceOrderDownloadFileApiClientMock().init(baseUrl, jsonWebToken);
			if (!this.mockConfigured) {
				apiClientMock.configureFetchMock();
				this.mockConfigured = true;
			}
			return apiClientMock;
		}
		return new CustomerServiceOrderDownloadFileApiClient().init(baseUrl, jsonWebToken);
	}

}
