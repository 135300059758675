import React from 'react';

import { useL10n } from '@abb-emobility/shared/localization-provider';
import { ActionbarItem, IconIdentifier } from '@abb-emobility/shared/ui-primitive';
import { l10nLiteralFromEnumValue } from '@abb-emobility/shared/util';

import { ApplicationActionType } from './ApplicationActionItemFactory.enums';
import { ApplicationAction } from './ApplicationActionItemFactory.types';

export type ApplicationActionItemFactoryProps = {
	action: ApplicationAction
};

export function ApplicationActionItemFactory(props: ApplicationActionItemFactoryProps) {

	const l10n = useL10n();

	const { action } = props;

	const renderActionItem = () => {

		const label = l10n.translate(l10nLiteralFromEnumValue(action.actionType, 'platformCustomerServiceApp.action'));
		switch (action.actionType) {
			case ApplicationActionType.SHOW_CUSTOMER:
				return (
					<ActionbarItem
						label={label}
						icon={IconIdentifier.USER}
						onTrigger={action.callback}
					/>
				);
			default:
				console.warn('ApplicationActionItemFactory: Action not defined: ' + action.actionType);
				return null;
		}
	};

	return (
		renderActionItem()
	);
}
