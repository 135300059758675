import React, { useEffect, useState } from 'react';

import { CustomerServiceOrderEntityDataProvider } from '@abb-emobility/platform/data-provider';
import { TaskActionStatus } from '@abb-emobility/shared/data-provider-foundation';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { UiErrorHandler } from '@abb-emobility/shared/ui-error-handler';
import {
	ButtonGhost,
	ButtonGroup,
	ButtonPrimary,
	GridOperatorInformation,
	SectionHeader,
	SpinnerCircle,
	SubmissionStatus,
	useModalDialogue,
	useModalDialogueManager
} from '@abb-emobility/shared/ui-primitive';
import { useAnyTaskEntityData } from '@abb-emobility/usertask/data-provider';
import { PreCheckRegistrationGridOperatorEscalationTaskModel } from '@abb-emobility/usertask/domain-model';

import { CustomerInformation } from '../sections/customer-information/CustomerInformation';
import { InstallationPartnerInformation } from '../sections/installation-partner-information/InstallationPartnerInformation';
import { OrderInformation } from '../sections/order-information/OrderInformation';
import { TaskInformation } from '../sections/task-information/TaskInformation';

import './TaskRegisterAtGridOperatorEscalation.scss';

export type PrecheckRemoteProps = {
	task: PreCheckRegistrationGridOperatorEscalationTaskModel
};

export function TaskRegisterAtGridOperatorEscalation(props: PrecheckRemoteProps) {

	const { task } = props;

	const l10n = useL10n();
	const modalDialogue = useModalDialogue();
	const modalDialogueManager = useModalDialogueManager();
	const anyTaskData = useAnyTaskEntityData();
	const [taskCompletionButtonState, setTaskCompletionButtonState] = useState<SubmissionStatus>(SubmissionStatus.IDLE);
	const completionStatus = anyTaskData.queryActionStatus();
	const taskCompletionButtonDisabled = completionStatus === TaskActionStatus.COMPLETE_PENDING || completionStatus === TaskActionStatus.COMPLETE_SUCCESS;

	anyTaskData.useActionStatusEffect([TaskActionStatus.COMPLETE_PENDING], () => {
		setTaskCompletionButtonState(SubmissionStatus.PENDING);
	}, false);

	anyTaskData.useActionStatusEffect([TaskActionStatus.COMPLETE_SUCCESS], () => {
		setTaskCompletionButtonState(SubmissionStatus.DONE);
	}, true);

	anyTaskData.useActionStatusEffect([TaskActionStatus.COMPLETE_FAILED], () => {
		setTaskCompletionButtonState(SubmissionStatus.IDLE);
	}, true);

	useEffect(() => {
		modalDialogue.setFooter(
			<ButtonGroup>
				<ButtonGhost
					label={l10n.translate('platformCustomerServiceApp.task.preCheck.button.close')}
					onClick={handleCancel}
				/>
				<ButtonPrimary
					label={l10n.translate('platformCustomerServiceApp.task.preCheck.button.submit')}
					submissionStatus={taskCompletionButtonState}
					onClick={handleTaskCompletion}
					disabled={taskCompletionButtonDisabled}
				/>
			</ButtonGroup>
		);
		return () => {
			modalDialogue.unsetFooter();
		};
	}, [taskCompletionButtonState, taskCompletionButtonDisabled]);

	const handleTaskCompletion = () => {
		anyTaskData.complete(task, task.payload);
	};

	const handleCancel = (): void => {
		modalDialogueManager.pop();
	};

	return (
		<>
			<TaskInformation task={task} type={task.type} />

			<UiErrorHandler>
				<CustomerServiceOrderEntityDataProvider
					primaryKey={task.orderId}
					pendingComponent={SpinnerCircle}
				>
					<OrderInformation />
				</CustomerServiceOrderEntityDataProvider>
			</UiErrorHandler>

			<InstallationPartnerInformation installationPartner={task.payload.installationPartner} />

			<CustomerInformation customer={task.payload.customer} />

			<section className="task-register-at-grid-operator-escalation">
				<SectionHeader heading={l10n.translate('platformCustomerServiceApp.modal.task.section.gridOperatorInformation.heading')} />
				<GridOperatorInformation gridOperator={task.payload.gridOperator} />
			</section>
		</>
	);
}
