import React, { ReactNode } from 'react';

import { CustomerServiceOrderEntityDataProvider, useCustomerServiceOrderEntityData } from '@abb-emobility/platform/data-provider';
import { HypermediaLinkSort } from '@abb-emobility/shared/data-provider-foundation';
import { NotFoundError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { UiErrorHandler } from '@abb-emobility/shared/ui-error-handler';
import {
	IconIdentifier,
	SegmentHeader,
	Separator,
	SpinnerCircle,
	useModalDialogueManager
} from '@abb-emobility/shared/ui-primitive';

import { ActionItemFactory } from '../../../action-item-factory/ActionItemFactory';
import { useActionModalFactory } from '../../../action-modal-factory/ActionModalFactory.hooks';
import { createHypermediaActionFromHypermediaLink } from '../../../action-modal-factory/ActionModalFactory.types';
import { OrderCollectionHeader } from '../../../order-collection-header/OrderCollectionHeader';
import { OrderCollectionItem } from '../../../order-collection-item/OrderCollectionItem';
import { OrderDetail } from '../../../order-detail/OrderDetail';

export function OrderInformation() {

	const l10n = useL10n();

	const orderData = useCustomerServiceOrderEntityData();
	const orderModel = orderData.query().getOrThrow(new NotFoundError(l10n.translate('platformCustomerServiceApp.error.orderNotFound')));

	const orderEntityData = useCustomerServiceOrderEntityData();
	const actionModalFactory = useActionModalFactory();
	const modalDialogueManager = useModalDialogueManager();

	const hypermediaLinkSort: HypermediaLinkSort = (left, right) => left.rel.localeCompare(right.rel);
	const hypermediaLinks = orderEntityData.queryHypermediaLinks(hypermediaLinkSort);

	const renderActionItems = (): ReactNode => {
		return hypermediaLinks.map((hypermediaLink, idx) => {
			return (
				<ActionItemFactory
					key={idx}
					hypermediaLink={hypermediaLink}
					onClick={() => actionModalFactory.renderModal(createHypermediaActionFromHypermediaLink(hypermediaLink))} />);
		});
	};

	const handleOnNavigate = () => {
		modalDialogueManager.push({
			header: () => {
				return (
					<OrderCollectionItem order={orderModel} onNavigate={() => modalDialogueManager.pop()} icon={IconIdentifier.X} />
				);
			},
			content: (): ReactNode => {
				return (
					<UiErrorHandler>
						<CustomerServiceOrderEntityDataProvider
							pendingComponent={SpinnerCircle}
							primaryKey={orderModel.id}
						>
							<OrderDetail modal={true} />
						</CustomerServiceOrderEntityDataProvider>
					</UiErrorHandler>
				);
			}
		});
	};

	return (
		<section>
			<SegmentHeader hideOverflow={true} caption={l10n.translate('platformCustomerServiceApp.modal.task.section.orderInformation.heading')}>
				{renderActionItems()}
			</SegmentHeader>
			<Separator />
			<OrderCollectionHeader />
			<Separator />
			<OrderCollectionItem order={orderModel} onNavigate={handleOnNavigate} closingSeperator={false} />
			<Separator />
		</section>
	);
}
