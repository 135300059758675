import React from 'react';

import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	SegmentHeader,
	Separator,
	TabularInformationItem,
	TabularInformationItemType,
	TabularInformationRow
} from '@abb-emobility/shared/ui-primitive';
import { l10nLiteralFromEnumValue } from '@abb-emobility/shared/util';
import { TaskFoundationModel, TaskType } from '@abb-emobility/usertask/domain-model';

import { getTaskTypeResponsibility } from '../../utils/TaskTypeResponsibility';


export type TaskInformationProps = {
	task: TaskFoundationModel,
	type: TaskType
};

export function TaskInformation(props: TaskInformationProps) {

	const { task, type } = props;

	const l10n = useL10n();

	const renderTaskResponsibility = () => {
		const taskTypeResponsibility = getTaskTypeResponsibility(type);

		if (taskTypeResponsibility === null) {
			return null;
		}

		return (
			<TabularInformationRow>
				<TabularInformationItem
					label={l10n.translate('platformCustomerServiceApp.modal.task.section.taskInformation.responsible')}
					variant={TabularInformationItemType.GREY}
				/>
				<TabularInformationItem
					label={l10n.translate(l10nLiteralFromEnumValue(taskTypeResponsibility, 'platformCustomerServiceApp.taskCollectionItem.category'))}
				/>
			</TabularInformationRow>
		);
	};

	return (
		<section>
			<SegmentHeader caption={l10n.translate('platformCustomerServiceApp.modal.task.section.taskInformation.heading')} />
			<Separator />
			<TabularInformationRow>
				<TabularInformationItem
					label={l10n.translate('platformCustomerServiceApp.modal.task.section.taskInformation.position')}
					variant={TabularInformationItemType.HEADING} />
				<TabularInformationItem
					label={l10n.translate('platformCustomerServiceApp.modal.task.section.taskInformation.description')}
					variant={TabularInformationItemType.HEADING} />
			</TabularInformationRow>

			<TabularInformationRow>
				<TabularInformationItem label={l10n.translate('platformCustomerServiceApp.modal.task.section.taskInformation.openTask')} variant={TabularInformationItemType.GREY} />
				<TabularInformationItem label={l10n.translate(l10nLiteralFromEnumValue(type, 'platformCustomerServiceApp.taskCollectionItem.type'))} />
			</TabularInformationRow>

			{renderTaskResponsibility()}

			<TabularInformationRow>
				<TabularInformationItem label={l10n.translate('platformCustomerServiceApp.modal.task.section.taskInformation.createdAt')} variant={TabularInformationItemType.GREY} />
				<TabularInformationItem label={l10n.formatTimestampDate(task.updatedOn)} />
			</TabularInformationRow>
			<TabularInformationRow>
				<TabularInformationItem label={l10n.translate('platformCustomerServiceApp.modal.task.section.taskInformation.dueDate')} variant={TabularInformationItemType.GREY} />
				<TabularInformationItem label={l10n.formatTimestampDate(task.dueDate)} />
			</TabularInformationRow>
		</section>
	);
}
