import React from 'react';

import { useCustomerServiceOrderCommentCollectionData } from '@abb-emobility/platform/data-provider';

import { CommentCollection } from './CommentCollection';

export function OrderCommentCollection() {

	const commentsData = useCustomerServiceOrderCommentCollectionData();
	const comments = commentsData.query();

	return (
		<CommentCollection comments={comments} />
	);
}
