import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCustomerServiceOrderEntityData } from '@abb-emobility/platform/data-provider';
import { HypermediaLinkSort } from '@abb-emobility/shared/data-provider-foundation';
import { NotFoundError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { usePageTitleModifier } from '@abb-emobility/shared/react';
import { useBreadcrumbs } from '@abb-emobility/shared/ui-primitive';

import { OrderDetail } from './OrderDetail';
import { createRouteUrl, RoutePath } from '../../router/Routes';
import { useActionbarContentModifier } from '../actionbar-content/ActionbarContent.hooks';
import { ApplicationActionType } from '../application-action-factory/ApplicationActionItemFactory.enums';
import { usePageTitleBuilder } from '../page-title/PageTitle.hook';

export function OrderDetailDataAccessor() {

	const l10n = useL10n();
	const pageTitleModifier = usePageTitleModifier();
	const pageTitleBuilder = usePageTitleBuilder();
	const breadcrumbs = useBreadcrumbs();
	const navigate = useNavigate();
	const orderData = useCustomerServiceOrderEntityData();

	const hypermediaLinkSort: HypermediaLinkSort = (left, right) => left.rel.localeCompare(right.rel);
	const hypermediaLinks = orderData.queryHypermediaLinks(hypermediaLinkSort);
	const hypermediaActions = hypermediaLinks.map(hypermediaLink => ({ hypermediaLink: hypermediaLink }));

	const order = orderData.query().getOrThrow(new NotFoundError(l10n.translate('platformCustomerServiceApp.error.orderNotFound')));
	const name = order.customer.firstName + ' ' + order.customer.lastName;

	useActionbarContentModifier(
		hypermediaActions,
		[{
			actionType: ApplicationActionType.SHOW_CUSTOMER,
			callback: () => navigate(createRouteUrl(RoutePath.CUSTOMER, ['customerId', order.customer.id]))
		}]
	);

	useEffect(() => {
		const pageTitleReplacements = new Map();
		pageTitleReplacements.set('displayName', name);
		pageTitleModifier(pageTitleBuilder('platformCustomerServiceApp.pageTitle.order', pageTitleReplacements));
		breadcrumbs.setBreadcrumbEntries([
			{
				label: l10n.translate('platformCustomerServiceApp.breadcrumbs.orders'),
				link: createRouteUrl(RoutePath.ORDERS)
			},
			{
				label: name,
				link: createRouteUrl(RoutePath.ORDER, ['orderId', order.id])
			}
		]);
	}, []);

	return (
		<OrderDetail />
	);
}
