import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	platformCustomerServiceApp: {
		action: {
			showCustomer: 'Visa kund'
		},
		actionItemFactory: {
			addCustomerNoteLabel: 'Lägg till anmärkning',
			addOrderNoteLabel: 'Lägg till anmärkning',
			cancelOrderLabel: 'Avbryt',
			cancelInstallationLabel: 'Avbryt installationen',
			cancelOrderAppointmentsLabel: 'Avboka möten',
			externalLinkLabel: 'Extern länk',
			resendMailLabel: 'Skicka e-post igen',
			returnOrderLabel: 'Retur',
			replaceOrderLabel: 'Byt ut',
			updateCustomerLabel: 'Ändra data',
			updateOrderLabel: 'Ändra data'
		},
		actionModalFactory: {
			addCustomerNoteHeading: 'Lägg till anmärkning',
			addOrderNoteHeading: 'Lägg till anmärkning',
			cancelOrderHeading: 'Avbryt order',
			cancelInstallationHeading: 'Avbryt installationen',
			cancelOrderAppointmentsHeading: 'Avbryt ordermöten',
			resendMailHeading: 'Skicka e-post igen',
			returnOrderHeading: 'Returorder',
			replaceOrderHeading: 'Ersätt order',
			updateCustomerHeading: 'Ändra kunddata',
			updateOrderHeading: 'Ändra orderuppgifter'
		},
		actionModal: {
			pleaseSelect: 'Vänligen välj',
			addNote: {
				content: {
					category: 'Kategori',
					categories: {
						order: 'Orderanmärkning',
						customer: 'Kundmeddelande',
						customerCall: 'Kundsamtal'
					}
				}
			},
			addOrderNote: {
				content: {
					noteHeading: 'Obs!',
					notePlaceholder: 'Infoga text',
					successHeading: 'Orderanmärkning har skapats',
					closeButtonLabel: 'Stäng'
				},
				footer: {
					confirm: 'Bekräfta',
					cancel: 'Avbryt'
				},
				error: {
					heading: 'Det gick inte att skapa orderanmärkningen.',
					message: 'Ett fel uppstod när anmärkningen skapades.'
				}
			},
			addCustomerNote: {
				content: {
					noteHeading: 'Obs!',
					notePlaceholder: 'Infoga text',
					successHeading: 'Kundmeddelandet har skapats',
					closeButtonLabel: 'Stäng'
				},
				footer: {
					confirm: 'Bekräfta',
					cancel: 'Avbryt'
				},
				error: {
					heading: 'Det gick inte att skapa kundmeddelandet.',
					message: 'Ett fel uppstod när kundmeddelandet skapades.'
				}
			},
			addCustomerCallNote: {
				content: {
					noteHeading: 'Obs!',
					notePlaceholder: 'Infoga text',
					customerCallReason: 'Anledning till kundsamtal',
					callReasonOptions: {
						afterSales: 'Eftersälj',
						product: 'Produkt',
						installation: 'Installation',
						preCheck: 'Inledande kontroll',
						payment: 'Betalning',
						dataProtection: 'Dataskydd',
						shipment: 'Leverans',
						cancellation: 'Returer',
						falseRouting: 'Felaktig dragning',
						other: 'Annat',
						complaints: 'Klagomål',
						returns: 'Returer'
					},
					callReasonNotePlaceholder: 'Beskrivning',
					successHeading: 'Anmärkning om kundsamtal har skapats',
					closeButtonLabel: 'Stäng'
				},
				footer: {
					confirm: 'Bekräfta',
					cancel: 'Avbryt'
				},
				error: {
					heading: 'Kunde inte skapa anmärkning om kundsamtal.',
					message: 'Ett fel uppstod vid skapandet av anmärkning om kundsamtal.'
				}
			},
			cancelOrder: {
				heading: 'Order',
				position: 'Position',
				description: 'Beskrivning',
				reason: 'Orsak',
				note: 'Beskrivning',
				cancelReasonValue: {
					other: 'Annat',
					wallboxBroken: 'Laddbox trasig',
					wallboxNotWorking: 'Laddbox fungerar inte',
					withdrawal: 'Tillbakadragande',
					installationNotPossible: 'Installation ej möjlig'
				},
				footer: {
					cancel: 'Avbryt',
					confirm: 'Bekräfta'
				},
				content: {
					successHeading: 'Ordern har annullerats',
					closeButtonLabel: 'Stäng'
				},
				error: {
					loading: {
						heading: 'Det gick inte att läsa in orderdata.',
						message: 'Ett fel uppstod vid inläsning av orderdata.'
					},
					writing: {
						heading: 'Det gick inte att avbryta ordern.',
						message: 'Ett fel uppstod vid annullering av ordern.'
					}
				}
			},
			cancelInstallation: {
				heading: 'Order',
				position: 'Position',
				description: 'Beskrivning',
				reason: 'Orsak',
				note: 'Beskrivning',
				cancelReasonValue: {
					other: 'Annat',
					wallboxBroken: 'Laddbox trasig',
					wallboxNotWorking: 'Laddbox fungerar inte',
					withdrawal: 'Tillbakadragande',
					installationNotPossible: 'Installation ej möjlig'
				},
				footer: {
					cancel: 'Avbryt',
					confirm: 'Bekräfta'
				},
				content: {
					successHeading: 'Annullering av installation lyckades',
					closeButtonLabel: 'Stäng'
				},
				error: {
					loading: {
						heading: 'Det gick inte att läsa in orderdata.',
						message: 'Ett fel uppstod vid inläsning av orderdata.'
					},
					writing: {
						heading: 'Installationen kunde inte avbrytas',
						message: 'Ett fel uppstod när installationen avbröts'
					}
				}
			},
			cancelOrderAppointments: {
				content: {
					notificationHeading: 'Installationspartnern kommer att informeras om det avbokade mötet.',
					notificationMessage: 'Installationspartnern kommer att kontakta kunden för att boka en ny tid.',
					successHeading: 'Avbokade möten',
					successCloseButtonLabel: 'Stäng',
					reason: 'Orsak'
				},
				cancelReasonValue: {
					deliveryDelay: 'Leveransförsening',
					installationPartnerConflict: 'Konflikt med installationspartner',
					customerConflict: 'Kundkonflikt',
					replacement: 'Byte'
				},
				footer: {
					confirm: 'Bekräfta avbokning',
					cancel: 'Avbryt'
				},
				error: {
					heading: 'Kunde inte avboka möten.',
					message: 'Det uppstod ett fel vid radering av ordermöten.'
				}
			},
			resendMail: {
				content: {
					notificationHeading: 'Mejlet kunde inte skickas igen.',
					notificationMessage: 'Kunden kommer att få ett nytt e-postmeddelande.',
					successHeading: 'Mejlet har skickats.',
					successCloseButtonLabel: 'Stäng'
				},
				footer: {
					confirm: 'Skicka e-post igen',
					cancel: 'Avbryt'
				},
				error: {
					heading: 'Kunde inte skicka e-post igen.',
					message: 'Ett fel uppstod när e-postmeddelandet skickades.'
				}
			},
			returnOrder: {
				heading: 'Order',
				position: 'Position',
				description: 'Beskrivning',
				note: 'Beskrivning',
				'return': 'Retur',
				selectReturn: 'Välj returartiklar',
				selectReason: 'Välj orsak till retur',
				replacementItem: 'Ersättningsartikel',
				yes: 'Ja',
				no: 'Nej',
				returnReason: {
					other: 'Annat',
					broken: 'Bruten'
				},
				footer: {
					cancel: 'Avbryt',
					confirm: 'Bekräfta'
				},
				content: {
					successHeading: 'Returprocessen har initierats',
					closeButtonLabel: 'Stäng'
				},
				error: {
					loading: {
						heading: 'Det gick inte att läsa in orderdata.',
						message: 'Ett fel uppstod vid inläsning av orderdata.'
					},
					writing: {
						heading: 'Kunde inte påbörja returprocessen.',
						message: 'Ett fel uppstod när returorderprocessen påbörjades.'
					}
				}
			},
			replaceOrder: {
				heading: 'Order',
				position: 'Position',
				description: 'Beskrivning',
				note: 'Beskrivning',
				'return': 'Byt ut',
				selectReturn: 'Välj ersättningsartiklar',
				selectReason: 'Välj orsak till ersättning',
				returnReason: {
					other: 'Annat',
					broken: 'Bruten'
				},
				footer: {
					cancel: 'Avbryt',
					confirm: 'Bekräfta'
				},
				content: {
					successHeading: 'Ersättningsprocessen har initierats',
					closeButtonLabel: 'Stäng'
				},
				error: {
					loading: {
						heading: 'Det gick inte att läsa in orderdata.',
						message: 'Ett fel uppstod vid inläsning av orderdata.'
					},
					writing: {
						heading: 'Kunde inte initiera ersättningsprocessen.',
						message: 'Ett fel uppstod när processen för ersättningsorder påbörjades.'
					}
				}
			},
			updateCustomer: {
				content: {
					firstname: 'Förnamn',
					lastname: 'Efternamn',
					email: 'E-post',
					personalInfoHeading: 'Personuppgifter',
					contactDataHeading: 'Kontaktuppgifter',
					successHeading: 'Användarinformationen har ändrats.',
					successCloseButtonLabel: 'Stäng'
				},
				footer: {
					confirm: 'Bekräfta',
					cancel: 'Avbryt'
				},
				error: {
					loading: {
						heading: 'Kunde inte läsa in kundinformation.',
						message: 'Ett fel uppstod när kundinformationen lästes in.'
					},
					writing: {
						heading: 'Kunde inte uppdatera kundinformation.',
						message: 'Ett fel uppstod vid uppdatering av kundinformationen.'
					}
				}
			},
			updateOrder: {
				content: {
					orderInformationHeading: 'Orderinformation',
					phoneLabel: 'Tel',
					languageHeading: 'Språk',
					languageLabel: ' Språkkod',
					successHeading: 'Orderinformationen har ändrats.',
					successCloseButtonLabel: 'Stäng'
				},
				footer: {
					confirm: 'Bekräfta',
					cancel: 'Avbryt'
				},
				error: {
					loading: {
						heading: 'Kunde inte läsa in orderinformation.',
						message: 'Ett fel uppstod när orderinformationen lästes in.'
					},
					writing: {
						heading: 'Kunde inte uppdatera orderinformation.',
						message: 'Ett fel uppstod vid uppdatering av orderinformationen.'
					}
				}
			}
		},
		appName: 'ABB:s kundtjänst',
		breadcrumbs: {
			tasks: 'Översikt över uppgiften',
			orders: 'Översikt över beställning',
			marketplaceOrders: 'Översikt över order på marknadsplats',
			customers: 'Kundöversikt'
		},
		comment: {
			categories: {
				order: 'Orderanmärkning',
				customer: 'Kundmeddelande',
				customerCall: 'Kundsamtal'
			},
			callReason: {
				afterSales: 'Eftersälj',
				product: 'Produkt',
				installation: 'Installation',
				preCheck: 'Inledande kontroll',
				payment: 'Betalning',
				dataProtection: 'Dataskydd',
				shipment: 'Leverans',
				cancellation: 'Returer',
				falseRouting: 'Felaktig dragning',
				other: 'Annat',
				complaints: 'Klagomål',
				returns: 'Returer'
			}
		},
		commentCollection: {
			heading: 'Anm.',
			emptyState: {
				heading: 'Inga kommentarer',
				message: 'Det finns för närvarande inga kommentarer.'
			}
		},
		common: {
			tableHeader: {
				position: 'Position',
				description: 'Beskrivning'
			}
		},
		customerCollection: {
			heading: 'Kunder',
			tableHead: {
				customer: 'Kund',
				customerId: 'Kund-ID',
				firstOrder: 'Första ordern',
				lastOrder: 'Senaste order'
			},
			emptyState: {
				heading: 'Inga kunder hittades',
				message: 'Justera dina filterinställningar för att hitta kunder.'
			},
			action: {
				showMore: 'Visa mer'
			}
		},
		customerInformation: {
			heading: 'Kundinformation',
			tableHeader: {
				position: 'Position',
				description: 'Beskrivning'
			},
			tableItem: {
				firstName: 'Förnamn',
				lastName: 'Efternamn',
				companyName: 'Företagets namn',
				mail: 'E-post',
				firstOrder: 'Första order',
				lastOrder: 'Senaste order'
			}
		},
		customerOrders: {
			heading: 'Kundordrar'
		},
		customerMarketplaceOrders: {
			heading: 'Marketplace Kundordrar'
		},
		customerDetail: {
			subheader: {
				customerId: 'Kund-ID'
			},
			tabItems: {
				overview: 'Översikt',
				comments: 'Anm.'
			}
		},
		error: {
			generic: {
				heading: 'Något gick fel',
				message: 'Ett oförutsett fel inträffade som inte kunde åtgärdas',
				resolveAction: {
					label: 'Ladda om'
				}
			},
			offline: {
				heading: 'Nätverksanslutningen är inte tillgänglig',
				message: 'Anslut till ett nätverk för att använda applikationen.'
			},
			notFound: {
				heading: 'Hittades inte',
				message: 'Den begärda resursen hittades inte',
				resolveAction: {
					label: 'Mina uppgifter'
				}
			},
			customerNotFound: 'Den efterfrågade kunden hittades inte',
			orderNotFound: 'Den begärda ordern hittades inte',
			taskNotFound: 'Den begärda uppgiften hittades inte',
			callerNotFound: 'Ingen information om uppringaren hittades',
			taskAssignMessage: 'När uppgiften tilldelades uppstod ett fel.',
			taskCompleteMessage: 'Under slutförandet av uppgiften uppstod ett fel.'
		},
		navigation: {
			tasks: 'Mina uppgifter',
			orders: 'Ordrar',
			marketplaceOrders: 'Marketplace Ordrar',
			customers: 'Kunder',
			logout: 'Logga ut'
		},
		order: {
			installationType: {
				undetermined: 'Ej fastställd',
				standard: 'Standard',
				delta: 'Delta',
				cancelled: 'Avbokad'
			}
		},
		orderDetail: {
			subheader: {
				orderDate: 'Orderdatum',
				orderId: 'Order-ID',
				marketplace: 'Marknadsplats',
				plannedDelivery: 'Planerad leverans',
				orderState: {
					onSchedule: 'Enligt tidtabell',
					delayed: 'Fördröjd',
					cancelPending: 'Avbryt väntande',
					cancelled: 'Avbruten',
					imported: 'Importerad',
					validationFailed: 'Ogiltig',
					done: 'Klar'
				}
			},
			tabItems: {
				overview: 'Översikt',
				installation: 'Installation',
				delivery: 'Leverans',
				accounting: 'Redovisning',
				files: 'Filer',
				comments: 'Anm.',
				tasks: 'Uppgifter'
			},
			overview: {
				customerInformation: {
					heading: 'Kundinformation'
				},
				orderInformation: {
					heading: 'Orderinformation',
					tableHeader: {
						position: 'Position',
						description: 'Beskrivning'
					},
					tableItem: {
						language: 'Språk',
						phone: 'Tel',
						deliveryAddress: 'Adress'
					}
				},
				timeline: {
					heading: 'Tidslinje'
				}
			},
			installation: {
				cancellation: {
					notification: {
						heading: 'Installationen har avbrutits',
						message: 'Annulleringen genomfördes den {{cancellationDate}}. Den angivna orsaken var "{{cancellationReason}}".'
					}
				},
				information: {
					heading: 'Information',
					types: {
						basicAnswers: 'Grundläggande svar',
						gridOperatorRegistration: 'Registrering av nätoperatör',
						gridOperatorDecision: 'Beslut av nätoperatör',
						preCheck: 'Förhandskontroll',
						offer: 'Erbjudande',
						approval: 'Godkännande'
					},
					emptyState: {
						heading: 'Ingen orderinformation tillgänglig',
						message: 'Det finns ingen tillgänglig orderinformation.'
					}
				},
				installationAppointments: {
					heading: 'Planerade installationer',
					installer: 'Installatör',
					appointment: 'Möte',
					phone: 'Tel',
					mail: 'E-post',
					emptyState: {
						heading: 'Inga möten hittades.',
						message: 'Det finns inga planerade möten.'
					}
				}
			},
			delivery: {
				orderItem: 'Orderartikel',
				productCode: 'Produktkod',
				address: {
					heading: 'Leveransadress',
					name: 'Namn',
					street: 'Gata',
					location: 'Ort'
				},
				details: {
					heading: 'Leveransuppgifter',
					shippingDate: 'Försändelsedatum',
					address: 'Leveransadress',
					trackingCode: 'Spårningskod',
					state: 'Fraktstatus',
					partner: 'Fraktpartner',
					tracking: 'Spårning',
					shippingState: {
						delivered: 'Levererad',
						pending: 'Väntar'
					},
					estimatedDeliveryDate: 'Beräknat leveransdatum',
					deliveryDate: 'Leveransdatum'
				},
				retoureDetails: {
					heading: 'Returuppgifter',
					date: 'Datum för begäran',
					address: 'Returadress',
					reason: 'Orsak till retur',
					returnNote: 'Retursedel',
					created: 'Skapad'
				},
				replacementDetails: {
					heading: 'Uppgifter om ersättningsleverans'
				},
				emptyState: {
					heading: 'Ingen leveransinformation tillgänglig',
					message: 'Det finns för närvarande ingen ytterligare information om leveransen.'
				}
			},
			accounting: {
				heading: 'Transaktioner',
				date: 'Betalningsdatum',
				state: 'Tillstånd',
				notification: 'Felmeddelande',
				cardOwner: 'Kortets ägare',
				cardNumber: 'Kortnummer',
				cardDueDate: 'Kortets utgångsdatum',
				accountOwner: 'Kontoägare',
				accountNumber: 'IBAN',
				paymentState: {
					success: 'Lyckad',
					failed: 'Misslyckad',
					settlementPending: 'Förlikning pågår'
				},
				emptyPaymentState: {
					heading: 'Ingen betalningsinformation tillgänglig',
					message: 'Det finns för närvarande ingen ytterligare information om betalningar.'
				},
				emptyDownloadFilesState: {
					heading: 'Inga nedladdningsfiler tillgängliga',
					message: 'Det finns för närvarande inga nedladdningsfiler tillgängliga.'
				}
			},
			files: {
				emptyState: {
					heading: 'Inga nedladdningsfiler tillgängliga',
					message: 'Det finns för närvarande inga nedladdningsfiler tillgängliga.'
				}
			},
			throttleWallboxHint: {
				caption: 'Obs!',
				message: 'Laddboxens effektförbrukning måste minskas i enlighet med nätoperatörens krav.'
			},
			informationList: {
				heading: 'Information',
				emptyState: {
					heading: 'Ingen orderinformation tillgänglig än',
					message: 'Allteftersom ordern fortskrider kommer du att hitta all relevant information om ordern här.'
				},
				caption: {
					basicAnswers: 'Grundläggande svar',
					gridOperatorRegistration: 'Registrering av nätoperatör',
					gridOperatorDecision: 'Beslut av nätoperatör',
					preCheck: 'Förhandskontroll',
					offer: 'Erbjudande',
					approval: 'Godkännande'
				},
				precheck: {
					heading: 'Förhandskontroll',
					effort: 'Resultat av inledande kontroll: Tid och arbetsinsats för installation',
					questionnaire: {
						heading: 'Svar från kund'
					},
					installationType: {
						undetermined: 'Ej fastställd',
						standard: 'Standardinstallation',
						delta: 'Delta-installation'
					},
					decision: {
						accepted: 'Tillräcklig information',
						more: 'Mer information behövs',
						denied: 'Installation ej möjlig'
					},
					notes: 'Anm.',
					hours: 'timmar'
				},
				decision: {
					heading: 'Beslut av nätoperatör',
					decision: {
						pending: 'Ansökan om installation är fortfarande under behandling',
						accepted: 'Nätoperatören godkände installationen',
						denied: 'Nätoperatören nekade installationen'
					}
				},
				registration: {
					heading: 'Registrering av nätoperatör',
					documents: 'Dokument',
					gridOperator: 'Nätoperatör'
				},
				quotation: {
					heading: 'Offert',
					conditions: 'Villkor för erbjudandet'
				},
				complete: {
					success: {
						heading: 'Installationen genomfördes'
					},
					notes: 'Anm.',
					images: 'Bilder relaterade till installationen',
					documents: 'Dokument relaterade till installationen',
					signature: 'Kundens underskrift'
				}
			}
		},
		orderEventTimeline: {
			emptyState: {
				heading: 'Inga händelser hittades',
				message: 'Historiken är fortfarande tom.'
			}
		},
		timelineItemDetails: {
			mailAddress: {
				label: 'E-post'
			},
			section: {
				payment: 'Betalningar',
				appointments: 'Schemalagda möten'
			}
		},
		orderEvent: {
			type: {
				orderReceived: 'Beställning mottagen',
				orderAddressValidationSucceeded: 'Adressvalidering lyckades',
				orderAddressValidationFailed: 'Adressvalidering misslyckades',
				orderAddressCorrected: 'Beställningens adress har korrigerats',
				accountingOrderCreated: 'Bokföringsorder skapad',
				orderConfirmationMailSent: 'Bekräftelsemail skickat',
				basicAnswersReceived: 'Frågeformulärssvar mottagna',
				gridOperatorRegistrationStarted: 'Registrering av nätoperatör påbörjad',
				gridOperatorFeedbackReceived: 'Feedback från nätoperatören mottagen',
				gridOperatorRejectionNotificationSent: 'Avslag från nätoperatör mottaget',
				basicAnswersEvaluated: 'Svaren på frågeformuläret utvärderade',
				precheckPreferencesMailSent: 'E-post för förhandskontroll av kontaktförfrågningar skickad',
				remotePrecheckExecuted: 'Fjärrstyrd förhandskontroll utförd',
				deltaQuotationCreated: 'Delta offert skapad',
				deltaQuotationMailSent: 'Delta offert e-post skickad',
				deltaQuotationAccepted: 'Delta erbjudande accepterat',
				paymentSucceeded: 'Betalningen lyckades',
				paymentFailedOrTimedOut: 'Betalningen misslyckades',
				paymentReminderMailSent: 'Betalningspåminnelse skickad',
				paymentConfirmationMailSent: 'E-post med betalningsbekräftelse skickad',
				appointmentArranged: 'Avtalad tid har bokats',
				appointmentArrangedNotificationSent: 'Meddelande om bokad tid skickat',
				shippingConfirmed: 'Leverans bekräftad',
				installationConfirmed: 'Installation bekräftad',
				replacementInstallationConfirmed: 'Ersättningsinstallation bekräftad',
				installationApprovalMailSent: 'E-post med bekräftelse på installation skickad',
				invoiceCreated: 'Faktura skapad',
				invoiceNotificationSent: 'Fakturameddelande skickat',
				orderCancelled: 'Beställning annullerad',
				gridOperatorRegistrationRevoked: 'Registrering av nätoperatör avbruten',
				customerCallReceived: 'Kundsamtal mottaget',
				returnOrReplacementShipmentReceived: 'Retur eller ersättningsleverans mottagen',
				returnInitiated: 'Retur initierad',
				replacementInitiated: 'Ersättningsleverans initierad',
				cancellationInitiated: 'Annullering initierad',
				orderNoteCreated: 'Orderanteckning skapad',
				refundCompleted: 'Återbetalning slutförd',
				installationCancellationMailSent: 'Meddelande om annullering av installation skickat'
			},
			category: {
				accounting: 'Redovisning',
				customer: 'Kund',
				customerService: 'Kundtjänst',
				deltaInstallation: 'Delta-installation',
				gridOperator: 'Nätoperatör',
				installation: 'Installation',
				order: 'Order',
				payment: 'Betalning',
				preCheck: 'Förkontroll',
				shipping: 'Frakt'
			}
		},
		marketplaceOrder: {
			orderState: {
				paymentPending: 'Utstående betalning',
				waitingForFulfillment: 'Väntar på uppfyllelse',
				fulfillmentStarted: 'Uppfyllelsen började',
				cancelled: 'Inställt',
				done: 'Klar'
			}
		},
		orderCollection: {
			heading: 'Ordrar',
			tableHead: {
				order: 'Order',
				status: 'Status',
				language: 'Språk',
				plannedDeliveryDate: 'Planerad leverans',
				createdAt: 'Skapad'
			},
			emptyState: {
				heading: 'Inga ordrar hittades',
				message: 'Justera dina filterinställningar för att hitta några ordrar.'
			},
			action: {
				refresh: 'Uppdatera',
				showMore: 'Visa mer'
			},
			orderState: {
				onSchedule: 'Enligt tidtabell',
				delayed: 'Fördröjd',
				cancelPending: 'Avbryt väntande',
				cancelled: 'Avbruten',
				imported: 'Importerad',
				validationFailed: 'Ogiltig',
				done: 'Klar'
			}
		},
		customerOrderCollection: {
			emptyState: {
				heading: 'Inga ordrar hittades',
				message: 'Inga beställningar hittades för denna kund.'
			}
		},
		orderLineItemCollection: {
			heading: 'Order',
			position: 'Position',
			description: 'Beskrivning',
			price: 'Pris',
			productCode: 'Produktkod',
			totalPrice: 'Totalt pris'
		},
		inboundOrderCollection: {
			heading: 'Ordrar kopplade till telefonnumret {{caller}}',
			emptyState: {
				heading: 'Inga ordrar hittades',
				message: 'Du kan prova med en sökning istället.',
				searchLabel: 'Sökning'
			},
			action: {
				showMore: 'Visa mer'
			}
		},
		searchbar: {
			placeholder: 'Sökning',
			results: {
				orders: 'Ordrar',
				marketplaceOrders: 'Ordrar från marknadsplatsen',
				customers: 'Kunder',
				showResultPage: 'Visa resultatsidan',
				showResultPageDescription: {
					resultsFromTo: 'Resultat 1 till',
					of: 'av'
				}
			}
		},
		searchResultCollection: {
			heading: 'Sökresultat',
			headingWithSearchterm: 'Sökresultat för',
			headingCustomers: 'Kunder',
			headingOrders: 'Ordrar',
			marketplaceOrders: 'Ordrar från marknadsplatsen',
			emptyState: {
				heading: 'Inga sökresultat hittades',
				message: 'Ändra din sökterm och försök igen.'
			},
			idleState: {
				heading: 'Ingen sökterm har angetts',
				message: 'Vänligen ange en sökterm.'
			},
			action: {
				showMore: 'Visa mer'
			}
		},
		task: {
			assign: {
				successMessage: 'Tilldelad uppgift'
			},
			error: {
				taskNotFound: 'Uppgiften hittades inte',
				taskTypeNotFound: 'Uppgiftstyp hittades inte'
			},
			gridOperatorRegistration: {
				continueToFeedback: {
					buttonLabel: 'Fortsätt'
				}
			},
			basicAnswers: {
				continueToQuotation: {
					buttonLabel: 'Fortsätt'
				},
				button: {
					close: 'Stäng',
					submit: 'Skicka'
				},
				questionnaire: {
					heading: 'Grundläggande svar'
				}
			},
			completion: {
				successHeading: 'Uppgiften har slutförts',
				closeButtonLabel: 'Stäng'
			},
			preCheck: {
				button: {
					close: 'Stäng',
					submit: 'Skicka'
				},
				contact: {
					description: 'Kontakt'
				}
			},
			gridOperator: {
				button: {
					close: 'Stäng',
					submit: 'Skicka'
				}
			},
			quotation: {
				button: {
					close: 'Stäng',
					submit: 'Skicka'
				},
				deltaPackages: {
					totalPurchaseNetPrice: 'Total köpeskilling (netto)',
					totalSalesGrossPrice: 'Totalt försäljningspris (inklusive {{vatRate}}% moms)',
					purchaseNetPrice: 'Köpeskilling (netto)',
					salesGrossPrice: 'Försäljningspris (brutto)'
				}
			},
			payment: {
				button: {
					close: 'Stäng',
					submit: 'Skicka'
				}
			},
			appointment: {
				button: {
					close: 'Stäng',
					submit: 'Skicka'
				}
			},
			installation: {
				button: {
					close: 'Stäng',
					submit: 'Skicka'
				}
			}
		},
		taskCollection: {
			heading: 'Uppgifter',
			tableHead: {
				task: 'Uppgift',
				status: 'Status',
				language: 'Språk'
			},
			action: {
				refresh: 'Uppdatera',
				showMore: 'Visa mer'
			},
			assign: {
				successMessage: 'Uppgiften har tilldelats',
				errorMessage: 'När uppgiften tilldelades uppstod ett fel.'
			},
			emptyState: {
				heading: 'Inga uppgifter hittades',
				message: 'Justera dina filterinställningar för att hitta uppgifter.'
			},
			orderEmptyState: {
				heading: 'Inga uppgifter hittades',
				message: 'Inget behöver göras för denna order.'
			}
		},
		taskCollectionItem: {
			type: {
				customerAnswerBasicQuestionsEscalation: 'Eskalering: Grundläggande fråga',
				customerProvidePreCheckPreferencesEscalation: 'Eskalering: Inledande kontroll',
				customerHandleUnfeasibleInstallationEscalation: 'Eskalering: Ogenomförbar installation',
				customerHandleGridOperatorRejectionEscalation: 'Eskalering: Avslag från nätoperatör',
				customerAcceptQuotationEscalation: 'Eskalering: Offert',
				customerPerformPaymentEscalation: 'Eskalering: Betalning',
				customerApproveInstallationEscalation: 'Eskalering: Godkänna installation',
				customerAddressValidationEscalation: 'Eskalering: Validering av adress',
				customerUncoveredInstallationAddress: 'Avtäckt installation Adress',
				installationPartnerEvaluateBasicAnswersEscalation: 'Eskalering: Grundläggande svar',
				installationPartnerExecuteRemotePreCheckEscalation: 'Eskalering: Utför inledande kontroll på distans',
				installationPartnerRegisterAtGridOperatorEscalation: 'Eskalering: Registrering hos nätoperatören',
				installationPartnerProvideGridOperatorDecisionEscalation: 'Eskalering: Tillhandahålla nätoperatör',
				installationPartnerProvideQuotationEscalation: 'Eskalering: Lämna offert',
				installationPartnerArrangeAppointmentEscalation: 'Eskalering: Ordna möten',
				installationPartnerCompleteInstallationEscalation: 'Eskalering: Slutför installation'
			},
			status: {
				started: 'Kommande',
				dueToday: 'Förfaller idag',
				overdue: 'Förfallen',
				escalated: 'Eskalerad',
				done: 'Klar',
				doneOverdue: 'Klar',
				doneEscalated: 'Klar'
			},
			category: {
				customer: 'Kund',
				installationPartner: 'Install. partner'
			}
		},
		pageTitle: {
			tasks: 'Översikt över uppgiften',
			orders: 'Översikt över beställning',
			order: 'Beställningsinformation',
			marketplaceOrders: 'Översikt över order på marknadsplats',
			marketplaceOrder: 'Detaljer om order på marknadsplats',
			customers: 'Kundöversikt',
			error: 'Fel',
			notFound: 'Hittades inte',
			inbound: 'Inkommande samtal {{caller}}'
		},
		viewHeader: {
			tasks: 'Översikt över uppgiften',
			orders: 'Översikt över beställning',
			marketplaceOrders: 'Översikt över order på marknadsplats',
			customers: 'Kundöversikt',
			inbound: 'Inkommande samtal från {{caller}}'
		},
		modal: {
			task: {
				section: {
					customerInformation: {
						heading: 'Kund',
						position: 'Position',
						description: 'Beskrivning',
						name: 'Namn',
						address: 'Leveransadress',
						phone: 'Telefonnummer',
						mail: 'E-post'
					},
					installationEffortInformation: {
						heading: 'Installationsarbete',
						trade: 'Avtal',
						effort: 'Arbetsinsats',
						hours: 'timmar'
					},
					installationPartnerInformation: {
						heading: 'Installationspartner',
						position: 'Position',
						description: 'Beskrivning',
						name: 'Namn',
						mail: 'E-post',
						phone: 'Telefon'
					},
					orderInformation: {
						heading: 'Orderinformation'
					},
					quotationInformation: {
						heading: 'Offert',
						position: 'Position',
						description: 'Beskrivning',
						price: 'Pris',
						deltaInstallation: 'Beslut: Delta-installation',
						grossPrice: 'Summa',
						vat: 'Moms',
						include: 'inkluderar'
					},
					taskInformation: {
						heading: 'Uppgift',
						position: 'Position',
						description: 'Beskrivning',
						createdAt: 'Skapad',
						dueDate: 'Förfallodag',
						responsible: 'Ansvarig',
						openTask: 'Öppen uppgift'
					},
					contactInformation: {
						heading: 'Kontaktpreferens'
					},
					gridOperatorInformation: {
						heading: 'Information om nätoperatör'
					},
					payment: {
						accept: 'Offert accepterad'
					},
					addressValidation: {
						heading: 'Validering av adress',
						invalidAddressLabel: {
							delivery: 'Ogiltig leveransadress',
							billing: 'Ogiltig faktureringsadress',
							multiple: 'Ogiltig leverans- och faktureringsadress'
						},
						hint: {
							heading: 'Infoga en ny adress för validering',
							message: 'Den nya adressen kommer att valideras på nytt när uppgiften skickas in.'
						},
						error: {
							heading: 'Adressvalideringen misslyckades',
							message: 'Vänligen ange korrekt adressinformation.'
						},
						newAddress: {
							heading: 'Ny adress',
							street: 'Gata och husnummer ',
							zip: 'Postnummer',
							city: 'Ort',
							country: 'Land',
							region: 'Region'
						}
					},
					uncoveredInstallationAddress: {
						message: 'Kunden informerades om annulleringen av beställningen',
						info: 'Avtäckt installation Adress',
						infoHeading: 'Informera kunden',
						infoDescription: 'Kunden måste informeras om att installationsadressen inte täcks och att installationen är avbokad'
					},
					hint: {
						heading: 'Slutkontroll',
						unfeasible: 'Beslut: Ogenomförbar installation',
						rejected: 'Avvisad av nätoperatör'
					}
				}
			}
		}
	}
};

export default literals;
