import React, { ReactNode } from 'react';

import { CustomerServiceOrderReplacementModel, CustomerServiceOrderShipmentModel } from '@abb-emobility/platform/domain-model';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	Card,
	SegmentHeader,
	TabularInformationItem,
	TabularInformationItemType,
	TabularInformationRow
} from '@abb-emobility/shared/ui-primitive';
import { l10nLiteralFromEnumValue } from '@abb-emobility/shared/util';

import { OrderDetailDeliveryOrderLineItems } from './OrderDetailDeliveryOrderLineItemCollection';

type OrderDetailDeliveryShipmentProps = {
	shippingModel: CustomerServiceOrderShipmentModel | CustomerServiceOrderReplacementModel,
	header: string
};

export function OrderDetailDeliveryShipment(props: OrderDetailDeliveryShipmentProps) {

	const { shippingModel, header } = props;

	const l10n = useL10n();

	const estimatedDeliveryDate = shippingModel.estimatedDeliveryDate ?? null;
	const deliveryDate = shippingModel.deliveryDate ?? null;
	const tracking = shippingModel.tracking ?? null;
	const trackingCode = shippingModel.tracking?.trackingCode ?? null;

	const renderState = () => {
		if (tracking === null) {
			return null;
		}
		return (
			<TabularInformationRow>
				<TabularInformationItem
					label={l10n.translate('platformCustomerServiceApp.orderDetail.delivery.details.state')}
					variant={TabularInformationItemType.GREY}
				/>
				<TabularInformationItem label={l10n.translate(l10nLiteralFromEnumValue(tracking.state, 'platformCustomerServiceApp.orderDetail.delivery.details.shippingState'))} />
			</TabularInformationRow>
		);
	};

	const renderEstimatedDeliveryDate = () => {
		if (estimatedDeliveryDate === null) {
			return null;
		}

		return (
			<TabularInformationRow>
				<TabularInformationItem
					label={l10n.translate('platformCustomerServiceApp.orderDetail.delivery.details.estimatedDeliveryDate')}
					variant={TabularInformationItemType.GREY}
				/>
				<TabularInformationItem label={l10n.formatTimestampDateTime(estimatedDeliveryDate)} />
			</TabularInformationRow>
		);
	};

	const renderDeliveryDate = () => {
		if (deliveryDate === null) {
			return null;
		}

		return (
			<TabularInformationRow>
				<TabularInformationItem
					label={l10n.translate('platformCustomerServiceApp.orderDetail.delivery.details.deliveryDate')}
					variant={TabularInformationItemType.GREY}
				/>
				<TabularInformationItem label={l10n.formatTimestampDateTime(deliveryDate)} />
			</TabularInformationRow>
		);
	};

	const renderTrackingCode = () => {
		if (trackingCode === null) {
			return null;
		}

		return (
			<TabularInformationRow>
				<TabularInformationItem
					label={l10n.translate('platformCustomerServiceApp.orderDetail.delivery.details.trackingCode')}
					variant={TabularInformationItemType.GREY}
				/>
				<TabularInformationItem label={trackingCode} />
			</TabularInformationRow>
		);
	};
	const renderCarrier = (): ReactNode => {
		if (tracking === null) {
			return null;
		}

		const trackingUrl = tracking.trackingUrl ?? null;

		if (trackingUrl === null) {
			return (
				<TabularInformationRow>
					<TabularInformationItem
						label={l10n.translate('platformCustomerServiceApp.orderDetail.delivery.details.partner')}
						variant={TabularInformationItemType.GREY} />
					<TabularInformationItem
						label={`${tracking.carrier} - ${l10n.translate(l10nLiteralFromEnumValue(tracking.state, 'platformCustomerServiceApp.orderDetail.delivery.details.shippingState'))}`}
					/>
				</TabularInformationRow>
			);
		}

		return (
			<TabularInformationRow>
				<TabularInformationItem
					label={l10n.translate('platformCustomerServiceApp.orderDetail.delivery.details.partner')}
					variant={TabularInformationItemType.GREY}
				/>
				<TabularInformationItem label={`${tracking.carrier} - ${l10n.translate(l10nLiteralFromEnumValue(tracking.state, 'platformCustomerServiceApp.orderDetail.delivery.details.shippingState'))}`}>
					<a href={trackingUrl}>
						{l10n.translate('platformCustomerServiceApp.orderDetail.delivery.details.tracking')}
					</a>
				</TabularInformationItem>
			</TabularInformationRow>
		);
	};

	return (
		<Card>
			<SegmentHeader caption={header} />

			<OrderDetailDeliveryOrderLineItems orderLineItems={shippingModel.orderLineItems} />

			<TabularInformationRow>
				<TabularInformationItem
					label={l10n.translate('platformCustomerServiceApp.orderDetail.delivery.details.shippingDate')}
					variant={TabularInformationItemType.GREY}
				/>
				<TabularInformationItem label={l10n.formatTimestampDateTime(shippingModel.shipmentDate)} />
			</TabularInformationRow>

			<TabularInformationRow>
				<TabularInformationItem
					label={l10n.translate('platformCustomerServiceApp.orderDetail.delivery.details.address')}
					variant={TabularInformationItemType.GREY}
				/>
				<TabularInformationItem label={`${shippingModel.deliveryAddress.street}, ${shippingModel.deliveryAddress.zip} ${shippingModel.deliveryAddress.city}`} />
			</TabularInformationRow>

			{renderTrackingCode()}
			{renderEstimatedDeliveryDate()}
			{renderState()}
			{renderDeliveryDate()}
			{renderCarrier()}
		</Card>
	);
}
