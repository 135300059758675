import React, { ReactNode, useEffect } from 'react';

import {
	CustomerServiceCustomerOrderCollectionDataProvider,
	useCustomerServiceCustomerEntityData,
	CustomerServiceCustomerCommentCollectionDataProvider,
	CustomerServiceCustomerCommentCollectionDataInterceptEmpty,
	CustomerServiceCustomerMarketplaceOrderCollectionDataProvider
} from '@abb-emobility/platform/data-provider';
import { createFullNameFromCustomer } from '@abb-emobility/platform/domain-model';
import { HypermediaLinkSort } from '@abb-emobility/shared/data-provider-foundation';
import { NotFoundError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { usePageTitleModifier } from '@abb-emobility/shared/react';
import { UiErrorHandler } from '@abb-emobility/shared/ui-error-handler';
import {
	Card,
	NotificationBig,
	NotificationBigStatus,
	SegmentHeader,
	SpinnerCircle,
	TabbarItem,
	TabbedPane,
	useBreadcrumbs,
	ViewHeader,
	ViewSubheader,
	ViewSubheaderItem,
	ViewSubheaderItemValueNumber
} from '@abb-emobility/shared/ui-primitive';
import { booleanFromString, Optional } from '@abb-emobility/shared/util';

import { createRouteUrl, RoutePath } from '../../router/Routes';
import { HypermediaAction } from '../action-modal-factory/ActionModalFactory.types';
import { useActionbarContentModifier } from '../actionbar-content/ActionbarContent.hooks';
import { CustomerCommentCollection } from '../comment-collection/CustomerCommentCollection';
import { CustomerInformation } from '../customer-information/CustomerInformation';
import { CustomerMarketplaceOrderCollection } from '../customer-marketplace-order-collection/CustomerMarketplaceOrderCollection';
import {
	CustomerOrderCollection
} from '../customer-order-collection/CustomerOrderCollection';
import {
	marketplaceOrderCollectionDefaultSortCriteria
} from '../marketplace-order-collection/MarketplaceOrderCollectionDefaultSortCriteria';
import { orderCollectionDefaultSortCriteria } from '../order-collection/OrderCollectionDefaultSortCriteria';
import { usePageTitleBuilder } from '../page-title/PageTitle.hook';

import './CustomerDetail.scss';

enum TabItem {
	OVERVIEW = 'OVERVIEW',
	COMMENTS = 'COMMENTS'
}

export function CustomerDetail() {

	const l10n = useL10n();
	const pageTitleModifier = usePageTitleModifier();
	const pageTitleBuilder = usePageTitleBuilder();
	const breadcrumbs = useBreadcrumbs();
	const customerData = useCustomerServiceCustomerEntityData();

	const hypermediaLinkSort: HypermediaLinkSort = (left, right) => left.rel.localeCompare(right.rel);
	const hypermediaLinks = customerData.queryHypermediaLinks(hypermediaLinkSort);
	const hypermediaActions: Array<HypermediaAction> = hypermediaLinks.map((link) => ({ hypermediaLink: link }));

	const customer = customerData.query()
		.getOrThrow(new NotFoundError(l10n.translate('platformCustomerServiceApp.error.customerNotFound')));
	const name = createFullNameFromCustomer(customer);

	useActionbarContentModifier(hypermediaActions, []);

	useEffect(() => {
		const pageTitleReplacements = new Map();
		pageTitleReplacements.set('displayName', name);
		pageTitleModifier(pageTitleBuilder('platformCustomerServiceApp.pageTitle.order', pageTitleReplacements));
		breadcrumbs.setBreadcrumbEntries([
			{
				label: l10n.translate('platformCustomerServiceApp.breadcrumbs.customers'),
				link: createRouteUrl(RoutePath.CUSTOMERS)
			},
			{
				label: name,
				link: createRouteUrl(RoutePath.CUSTOMER, ['customerId', customer.id])
			}
		]);
	}, []);

	const renderTabbedPane = (): ReactNode => {
		return (
			<TabbedPane activeTabId={TabItem.OVERVIEW} renderTabContent={renderTabContent} connectUrl={true}>
				<TabbarItem
					label={l10n.translate('platformCustomerServiceApp.customerDetail.tabItems.overview')}
					key={TabItem.OVERVIEW}
					tabId={TabItem.OVERVIEW}
				/>
				<TabbarItem
					label={l10n.translate('platformCustomerServiceApp.customerDetail.tabItems.comments')}
					key={TabItem.COMMENTS}
					tabId={TabItem.COMMENTS}
				/>
			</TabbedPane>
		);
	};

	const renderEmptyCommentsState = (): ReactNode => {
		return (
			<NotificationBig
				status={NotificationBigStatus.EMPTY2}
				heading={l10n.translate('platformCustomerServiceApp.commentCollection.emptyState.heading')}
				message={l10n.translate('platformCustomerServiceApp.commentCollection.emptyState.message')}
			/>
		);
	};

	const renderTabContent = (tabId: TabItem): ReactNode => {
		switch (tabId) {
			case TabItem.OVERVIEW: {
				return (
					<section className={'customer-detail-overview'}>
						<Card>
							<CustomerInformation
								header={l10n.translate('platformCustomerServiceApp.customerInformation.heading')}
								customer={customer}
							/>
						</Card>

						<Card>
							<UiErrorHandler>
								<CustomerServiceCustomerOrderCollectionDataProvider
									customerId={customer.id}
									pendingComponent={SpinnerCircle}
									sortCriteria={orderCollectionDefaultSortCriteria}
									suspense={false}
								>
									<CustomerOrderCollection />
								</CustomerServiceCustomerOrderCollectionDataProvider>
							</UiErrorHandler>
						</Card>

						{renderMarketplaceOrderCollection()}

					</section>
				);
			}
			case TabItem.COMMENTS: {
				return (
					<Card>
						<SegmentHeader caption={l10n.translate('platformCustomerServiceApp.commentCollection.heading')} />
						<UiErrorHandler>
							<CustomerServiceCustomerCommentCollectionDataProvider
								customerId={customer.id}
								pendingComponent={SpinnerCircle}
							>
								<CustomerServiceCustomerCommentCollectionDataInterceptEmpty emptyStateComponent={renderEmptyCommentsState}>
									<CustomerCommentCollection />
								</CustomerServiceCustomerCommentCollectionDataInterceptEmpty>

							</CustomerServiceCustomerCommentCollectionDataProvider>
						</UiErrorHandler>
					</Card>
				);
			}
			default:
				return null;
		}
	};

	const renderMarketplaceOrderCollection = (): ReactNode => {
		const featureToggleMarketplaceOrders = booleanFromString(
			new Optional(process.env['NX_FEATURE_TOGGLE_MARKETPLACE_ORDERS']).getOrDefault('false')
		);
		if (!featureToggleMarketplaceOrders) {
			return null;
		}
		return (
			<Card>
				<UiErrorHandler>
					<CustomerServiceCustomerMarketplaceOrderCollectionDataProvider
						customerId={customer.id}
						pendingComponent={SpinnerCircle}
						sortCriteria={marketplaceOrderCollectionDefaultSortCriteria}
						suspense={false}
					>
						<CustomerMarketplaceOrderCollection />
					</CustomerServiceCustomerMarketplaceOrderCollectionDataProvider>
				</UiErrorHandler>
			</Card>
		);
	};

	return (
		<>
			<header>
				<ViewHeader heading={name} />
				<ViewSubheader>
					<ViewSubheaderItem label={l10n.translate('platformCustomerServiceApp.customerDetail.subheader.customerId') + ': '}>
						<ViewSubheaderItemValueNumber>
							{customer.id}
						</ViewSubheaderItemValueNumber>
					</ViewSubheaderItem>
				</ViewSubheader>
			</header>
			{renderTabbedPane()}
		</>

	);
}
