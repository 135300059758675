import {
	CustomerServiceOrderInstallationAppointmentModel,
	CustomerServiceOrderInstallationAppointmentModelConverter
} from '@abb-emobility/platform/domain-model';
import { AbstractCrudApiClient } from '@abb-emobility/shared/api-integration-foundation';

export class CustomerServiceOrderInstallationAppointmentApiClient extends AbstractCrudApiClient<CustomerServiceOrderInstallationAppointmentModel> {
	protected collectionControllerUri = 'order-installation-appointments/';
	protected paginationControllerUri = 'order-installation-appointments/';
	protected entityControllerUri = 'order-installation-appointment/{id}/';
	protected entitySearchControllerUri = 'order-installation-appointment/';
	protected modelConverter = new CustomerServiceOrderInstallationAppointmentModelConverter();
}
