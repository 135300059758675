import { ModelConverter } from '@abb-emobility/shared/domain-model-foundation';

import { CustomerServiceOrderLineItemModel } from '../../CustomerServiceOrderLineItemModel';

export type CustomerServiceReturnOrderPayloadModel = {
	orderLineItems: Array<CustomerServiceOrderLineItemModel>
};

export class CustomerServiceReturnOrderPayloadModelConverter extends ModelConverter<CustomerServiceReturnOrderPayloadModel> {
}
