import React, { ReactNode } from 'react';

import {
	CustomerServiceMarketplaceOrderCollectionDataInterceptEmpty,
	CustomerServiceMarketplaceOrderCollectionDataSuspense, useCustomerServiceMarketplaceOrderCollectionData
} from '@abb-emobility/platform/data-provider';
import { CustomerServiceMarketplaceOrderCollectionItemModel } from '@abb-emobility/platform/domain-model';
import { SortCriteria } from '@abb-emobility/shared/api-integration-foundation';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	ActionbarItem,
	ButtonGhost,
	Card,
	CollectionFooter,
	CollectionSort,
	IconIdentifier,
	NotificationBig,
	NotificationBigStatus,
	SegmentHeader,
	Separator,
	SpinnerCircle
} from '@abb-emobility/shared/ui-primitive';

import { useActionbarContentModifier } from '../actionbar-content/ActionbarContent.hooks';
import { MarketplaceOrderCollectionHeader } from '../marketplace-order-collection-header/MarketplaceOrderCollectionHeader';
import { MarketplaceOrderCollectionItem } from '../marketplace-order-collection-item/MarketplaceOrderCollectionItem';

export type OrderCollectionProps = {
	onSortChange: (sortCriteria: SortCriteria<CustomerServiceMarketplaceOrderCollectionItemModel>) => void
};

export function MarketplaceOrderCollection(props: OrderCollectionProps) {

	const { onSortChange } = props;

	const l10n = useL10n();

	const orderCollectionData = useCustomerServiceMarketplaceOrderCollectionData();
	const effectiveSortCriteria = orderCollectionData.querySortCriteria();
	const isPaginated = orderCollectionData.isPaginated();
	const hasNextPage = orderCollectionData.hasNextPage();
	const fetchPending = orderCollectionData.pending();
	const orders = orderCollectionData.query();

	useActionbarContentModifier([], []);

	const handleSortApply = (sortCiteria: SortCriteria<CustomerServiceMarketplaceOrderCollectionItemModel>) => {
		onSortChange(sortCiteria);
		orderCollectionData.applySort(sortCiteria);
	};

	const handleShowMore = () => {
		orderCollectionData.fetchNext();
	};

	const handleRefresh = () => {
		orderCollectionData.refetch();
	};

	const renderEmptyState = (): ReactNode => {
		return (
			<NotificationBig
				status={NotificationBigStatus.EMPTY2}
				heading={l10n.translate('platformCustomerServiceApp.orderCollection.emptyState.heading')}
				message={l10n.translate('platformCustomerServiceApp.orderCollection.emptyState.message')}
			/>
		);
	};

	const renderShowMore = (): ReactNode => {
		if (!isPaginated) {
			return;
		}
		return (
			<ButtonGhost
				label={l10n.translate('platformCustomerServiceApp.orderCollection.action.showMore')}
				onClick={handleShowMore}
				disabled={!hasNextPage || fetchPending}
			/>
		);
	};

	const renderOrders = () => {
		return orders.map((order): React.JSX.Element => {
			return (
				<MarketplaceOrderCollectionItem
					order={order}
					key={order.id}
				/>
			);
		});
	};

	return (
		<Card>
			<SegmentHeader caption={l10n.translate('platformCustomerServiceApp.orderCollection.heading')}>
				<ActionbarItem
					label={l10n.translate('platformCustomerServiceApp.orderCollection.action.refresh')}
					icon={IconIdentifier.ARROWS_CLOCKWISE}
					onTrigger={handleRefresh}
				/>
				<CollectionSort
					sortOptions={['orderDate']}
					sortCriteria={effectiveSortCriteria ?? undefined}
					implicitSortOption={'orderDate'}
					onApply={handleSortApply}
				/>
			</SegmentHeader>

			<Separator />

			<CustomerServiceMarketplaceOrderCollectionDataSuspense pendingComponent={SpinnerCircle}>
				<CustomerServiceMarketplaceOrderCollectionDataInterceptEmpty emptyStateComponent={renderEmptyState}>

					<MarketplaceOrderCollectionHeader />
					<Separator />

					{renderOrders()}

					<CollectionFooter>
						{renderShowMore()}
					</CollectionFooter>

				</CustomerServiceMarketplaceOrderCollectionDataInterceptEmpty>
			</CustomerServiceMarketplaceOrderCollectionDataSuspense>
		</Card>
	);
}
