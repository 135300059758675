import {
	CustomerServiceOrderEventAppointmentArrangedNotificationSentModel,
	CustomerServiceOrderEventDeltaQuotationMailSentModel,
	CustomerServiceOrderEventGridOperatorRejectionNotificationSentModel,
	CustomerServiceOrderEventInstallationApprovalMailSentModel,
	CustomerServiceOrderEventInstallationCancellationConfirmationMailSentModel,
	CustomerServiceOrderEventInvoiceNotificationSentModel,
	CustomerServiceOrderEventOrderConfirmationMailSentModel,
	CustomerServiceOrderEventPaymentConfirmationMailSentModel,
	CustomerServiceOrderEventPaymentFailedOrTimedOutModel,
	CustomerServiceOrderEventPreCheckPreferencesMailSentModel,
	CustomerServiceOrderEventType
} from '@abb-emobility/platform/domain-model';
import { createTimestampDtoFromDate, Dto } from '@abb-emobility/shared/domain-model-foundation';

import {
	customerServiceOrderEventAppointmentArrangedMailSentDetailMock
} from './order-event/CustomerServiceOrderEventAppointmentArrangedNotificationSentDetailModel.mock';
import {
	customerServiceOrderEventDeltaQuotationMailSentDetailMock
} from './order-event/CustomerServiceOrderEventDeltaQuotationMailSentDetailModel.mock';
import {
	customerServiceOrderEventGridOperatorRejectionNotificationSentDetailMock
} from './order-event/CustomerServiceOrderEventGridOperatorRejectionNotificationSentDetailModel.mock';
import {
	customerServiceOrderEventInstallationApprovalMailSentDetailMock
} from './order-event/CustomerServiceOrderEventInstallationApprovalMailSentDetailModel.mock';
import {
	customerServiceOrderEventInstallationCancellationConfirmationMailSentDetailMock
} from './order-event/CustomerServiceOrderEventInstallationCancellationConfirmationMailSentDetailModel.mock';
import {
	customerServiceOrderEventInvoiceNotificationSentDetailMock
} from './order-event/CustomerServiceOrderEventInvoiceNotificationSentDetailModel.mock';
import {
	customerServiceOrderEventOrderConfirmationMailSentDetailMock
} from './order-event/CustomerServiceOrderEventOrderConfirmationMailSentDetailModel.mock';
import {
	customerServiceOrderEventPaymentConfirmationMailSentDetailMock
} from './order-event/CustomerServiceOrderEventPaymentConfirmationMailSentDetailModel.mock';
import {
	customerServiceOrderEventPaymentFailedOrTimedOutDetailMock
} from './order-event/CustomerServiceOrderEventPaymentFailedOrTimedOutDetailModel.mock';
import {
	customerServiceOrderEventPreCheckPreferencesMailSentDetailMock
} from './order-event/CustomerServiceOrderEventPreCheckPreferencesMailSentDetailModel.mock';

export const customerServiceOrderEventOrderConfirmationMailSentModelMock: Dto<CustomerServiceOrderEventOrderConfirmationMailSentModel> = {
	type: CustomerServiceOrderEventType.ORDER_CONFIRMATION_MAIL_SENT,
	id: '6',
	orderId: '1',
	eventDate: createTimestampDtoFromDate(new Date('2022-03-01T16:30:12.345Z')),
	details: customerServiceOrderEventOrderConfirmationMailSentDetailMock
};

export const customerServiceOrderEventGridOperatorRejectionNotificationSendModelMock: Dto<CustomerServiceOrderEventGridOperatorRejectionNotificationSentModel> = {
	type: CustomerServiceOrderEventType.GRID_OPERATOR_REJECTION_NOTIFICATION_SENT,
	id: '10',
	orderId: '1',
	eventDate: createTimestampDtoFromDate(new Date('2022-03-01T16:30:12.345Z')),
	details: customerServiceOrderEventGridOperatorRejectionNotificationSentDetailMock
};

export const customerServiceOrderEventPreCheckPreferencesMailSentModelMock: Dto<CustomerServiceOrderEventPreCheckPreferencesMailSentModel> = {
	type: CustomerServiceOrderEventType.PRECHECK_PREFERENCES_MAIL_SENT,
	id: '12',
	orderId: '1',
	eventDate: createTimestampDtoFromDate(new Date('2022-03-01T16:30:12.345Z')),
	details: customerServiceOrderEventPreCheckPreferencesMailSentDetailMock
};

export const customerServiceOrderEventDeltaQuotationMailSentModelMock: Dto<CustomerServiceOrderEventDeltaQuotationMailSentModel> = {
	type: CustomerServiceOrderEventType.DELTA_QUOTATION_MAIL_SENT,
	id: '15',
	orderId: '1',
	eventDate: createTimestampDtoFromDate(new Date('2022-03-01T16:30:12.345Z')),
	details: customerServiceOrderEventDeltaQuotationMailSentDetailMock
};

export const customerServiceOrderEventPaymentFailedOrTimedOutModelMock: Dto<CustomerServiceOrderEventPaymentFailedOrTimedOutModel> = {
	type: CustomerServiceOrderEventType.PAYMENT_FAILED_OR_TIMED_OUT,
	id: '18',
	orderId: '1',
	eventDate: createTimestampDtoFromDate(new Date('2022-03-01T16:30:12.345Z')),
	details: customerServiceOrderEventPaymentFailedOrTimedOutDetailMock
};

export const customerServiceOrderEventPaymentConfirmationMailSentModelMock: Dto<CustomerServiceOrderEventPaymentConfirmationMailSentModel> = {
	type: CustomerServiceOrderEventType.PAYMENT_CONFIRMATION_MAIL_SENT,
	id: '20',
	orderId: '1',
	eventDate: createTimestampDtoFromDate(new Date('2022-03-01T16:30:12.345Z')),
	details: customerServiceOrderEventPaymentConfirmationMailSentDetailMock
};

export const customerServiceOrderEventAppointmentArrangedNotificationSentModelMock: Dto<CustomerServiceOrderEventAppointmentArrangedNotificationSentModel> = {
	type: CustomerServiceOrderEventType.APPOINTMENT_ARRANGED_NOTIFICATION_SENT,
	id: '22',
	orderId: '1',
	eventDate: createTimestampDtoFromDate(new Date('2022-03-01T16:30:12.345Z')),
	details: customerServiceOrderEventAppointmentArrangedMailSentDetailMock
};

export const customerServiceInstallationApprovalMailSentModelMock: Dto<CustomerServiceOrderEventInstallationApprovalMailSentModel> = {
	type: CustomerServiceOrderEventType.INSTALLATION_APPROVAL_MAIL_SENT,
	id: '26',
	orderId: '1',
	eventDate: createTimestampDtoFromDate(new Date('2022-03-01T16:30:12.345Z')),
	details: customerServiceOrderEventInstallationApprovalMailSentDetailMock
};

export const customerServiceOrderEventInvoiceNotificationSentModelMock: Dto<CustomerServiceOrderEventInvoiceNotificationSentModel> = {
	type: CustomerServiceOrderEventType.INVOICE_NOTIFICATION_SENT,
	id: '28',
	orderId: '1',
	eventDate: createTimestampDtoFromDate(new Date('2022-03-01T16:30:12.345Z')),
	details: customerServiceOrderEventInvoiceNotificationSentDetailMock
};

export const customerServiceOrderEventInstallationCancellationConfirmationMailSentModelMock: Dto<CustomerServiceOrderEventInstallationCancellationConfirmationMailSentModel> = {
	type: CustomerServiceOrderEventType.INSTALLATION_CANCELLATION_MAIL_SENT,
	id: '38',
	orderId: '1',
	eventDate: createTimestampDtoFromDate(new Date('2022-03-01T16:30:12.345Z')),
	details: customerServiceOrderEventInstallationCancellationConfirmationMailSentDetailMock
};

export const customerServiceOrderEventEntityModelMockCollection = [
	customerServiceOrderEventOrderConfirmationMailSentModelMock,
	customerServiceOrderEventGridOperatorRejectionNotificationSendModelMock,
	customerServiceOrderEventPreCheckPreferencesMailSentModelMock,
	customerServiceOrderEventDeltaQuotationMailSentModelMock,
	customerServiceOrderEventPaymentFailedOrTimedOutModelMock,
	customerServiceOrderEventPaymentConfirmationMailSentModelMock,
	customerServiceOrderEventAppointmentArrangedNotificationSentModelMock,
	customerServiceInstallationApprovalMailSentModelMock,
	customerServiceOrderEventInvoiceNotificationSentModelMock,
	customerServiceOrderEventInstallationCancellationConfirmationMailSentModelMock
];
