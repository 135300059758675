import { useContext } from 'react';

import { AnyCustomerServiceOrderInformationCollectionItemModel } from '@abb-emobility/platform/domain-model';
import { CrudCollectionDataProviderValue } from '@abb-emobility/shared/data-provider-foundation';

import { customerServiceOrderInformationCollectionData } from './CustomerServiceOrderInformationCollectionData';

export const useCustomerServiceOrderInformationCollectionData = (): CrudCollectionDataProviderValue<AnyCustomerServiceOrderInformationCollectionItemModel> => {
	return useContext(customerServiceOrderInformationCollectionData);
};
