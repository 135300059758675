import React, { ReactNode } from 'react';

import {
	CustomerServiceCustomerOrderCollectionDataInterceptEmpty,
	CustomerServiceCustomerOrderCollectionDataSuspense,
	useCustomerServiceCustomerOrderCollectionData
} from '@abb-emobility/platform/data-provider';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { NotificationBig, NotificationBigStatus, SegmentHeader, Separator, SpinnerCircle } from '@abb-emobility/shared/ui-primitive';

import { OrderCollectionHeader } from '../order-collection-header/OrderCollectionHeader';
import { OrderCollectionItem } from '../order-collection-item/OrderCollectionItem';

export function CustomerOrderCollection() {

	const l10n = useL10n();
	const customerOrderCollectionData = useCustomerServiceCustomerOrderCollectionData();

	const orders = customerOrderCollectionData.query();

	const renderEmptyOrderCollectionState = (): ReactNode => {
		return (
			<NotificationBig
				status={NotificationBigStatus.EMPTY2}
				heading={l10n.translate('platformCustomerServiceApp.customerOrderCollection.emptyState.heading')}
				message={l10n.translate('platformCustomerServiceApp.customerOrderCollection.emptyState.message')}
			/>
		);
	};
	const renderOrderItems = () => {
		return orders.map((order) => {
			return (
				<OrderCollectionItem key={order.id} order={order} />
			);
		});
	};

	return (
		<>
			<SegmentHeader caption={l10n.translate('platformCustomerServiceApp.customerOrders.heading')} />
			<CustomerServiceCustomerOrderCollectionDataSuspense pendingComponent={SpinnerCircle}>
				<CustomerServiceCustomerOrderCollectionDataInterceptEmpty emptyStateComponent={renderEmptyOrderCollectionState}>
					<Separator />
					<OrderCollectionHeader />
					<Separator />
					{renderOrderItems()}
				</CustomerServiceCustomerOrderCollectionDataInterceptEmpty>
			</CustomerServiceCustomerOrderCollectionDataSuspense>
		</>

	);
}
