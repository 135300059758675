import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	platformDataProvider: {
		error: {
			customerCollectionFetchMessage: 'Si è verificato un errore durante il caricamento dei dati del cliente.',
			customerEntityFetchMessage: 'Si è verificato un errore durante il caricamento dei dettagli del cliente.',
			taskFetchMessage: 'Si è verificato un errore durante il caricamento dei dettagli dell\'attività.',
			taskSearchMessage: 'Si è verificato un errore durante la ricerca dell\'attività.',
			taskCollectionFetchMessage: 'Si è verificato un errore durante il caricamento dei dati dell\'attività.',
			orderFetchMessage: 'Si è verificato un errore durante il caricamento dei dettagli dell\'ordine.',
			orderCollectionFetchMessage: 'Si è verificato un errore durante il caricamento dei dati dell\'ordine.',
			orderEventCollectionFetchMessage: 'Si è verificato un errore durante il caricamento dello storico degli ordini.',
			orderEventEntityFetchMessage: 'Si è verificato un errore durante il caricamento dell\'evento dell\'ordine.',
			orderShipmentCollectionFetchMessage: 'Si è verificato un errore durante il caricamento dei dati di spedizione.',
			orderAccountingCollectionFetchMessage: 'Si è verificato un errore durante il caricamento dei dati dell\'account.',
			orderFilesCollectionFetchMessage: 'Si è verificato un errore durante il caricamento dei file.',
			orderInstallationCollectionFetchMessage: 'Si è verificato un errore durante il caricamento dei dati di installazione.',
			orderNotesCollectionFetchMessage: 'Si è verificato un errore durante il caricamento delle note.',
			orderInformationCollectionFetchMessage: 'Si è verificato un errore durante il caricamento delle informazioni dell\'ordine.',
			installerAppointmentCollectionFetchMessage: 'Si è verificato un errore durante il caricamento degli appuntamenti.',
			reportCollectionFetchMessage: 'Si è verificato un errore durante il caricamento dei report.',
			searchCollectionFetchMessage: 'Si è verificato un errore durante la ricerca dei dati.',
			orderPaymentCollectionFetchMessage: 'Si è verificato un errore durante il caricamento dei dati di pagamento.',
			orderInformationEntityFetchMessage: 'Si è verificato un errore durante il caricamento dei dettagli delle informazioni dell\'ordine.'
		}
	}
};

export default literals;
