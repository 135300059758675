import React, { useEffect, useState } from 'react';

import {
	CustomerServiceCancelInstallationPayloadModelConverter
} from '@abb-emobility/platform/domain-model';
import { CustomerServiceCancelInstallationInvocationPayloadModelConverter } from '@abb-emobility/platform/domain-model';
import { CommandStatus, useCommandData } from '@abb-emobility/shared/command-api';
import { InstallationCancelReason } from '@abb-emobility/shared/domain-model';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	ActionModalFooter,
	ButtonGhost,
	ButtonPrimary,
	InputSelect,
	InputSelectOption,
	InputTextArea,
	NotificationBig,
	NotificationBigStatus,
	SpinnerCircle,
	SubmissionStatus,
	SuccessFeeback,
	SuccessImageIdentifier,
	useModalDialogue,
	useModalDialogueManager
} from '@abb-emobility/shared/ui-primitive';
import { l10nLiteralFromEnumValue, Nullable } from '@abb-emobility/shared/util';

import { OrderLineItemCollection } from '../../../order-line-item-collection/OrderLineItemCollection';
import { HypermediaAction } from '../../ActionModalFactory.types';

import './CancelInstallationActionModal.scss';

type CancelInstallationActionModalProps = {
	action: HypermediaAction
};

export function CancelinstallationActionModal(props: CancelInstallationActionModalProps) {

	const { action } = props;

	const l10n = useL10n();
	const modalDialogue = useModalDialogue();
	const dialogueManager = useModalDialogueManager();

	const cancelInstallationCommandData = useCommandData(
		action.hypermediaLink,
		new CustomerServiceCancelInstallationPayloadModelConverter(),
		new CustomerServiceCancelInstallationInvocationPayloadModelConverter()
	);

	const fetchPayload = cancelInstallationCommandData.query();
	const fetchError = cancelInstallationCommandData.queryFetchError();
	const commandInvocationStatus = cancelInstallationCommandData.queryInvocationStatus();
	const commandInvocationError = cancelInstallationCommandData.queryInvocationError();

	const [reason, setReason] = useState<Nullable<InstallationCancelReason>>(null);

	const [comment, setComment] = useState<Nullable<string>>(null);

	const options: Array<InputSelectOption<InstallationCancelReason>> = Object.values(InstallationCancelReason)
		.map((value) => (
				{
					label: l10n.translate(l10nLiteralFromEnumValue(
							value,
							'platformCustomerServiceApp.actionModal.cancelInstallation.cancelReasonValue'
						)
					),
					value: value
				}
			)
		);

	useEffect(() => {
		const handleSubmitAction = () => {
			if (reason !== null) {
				cancelInstallationCommandData.invoke({
					reason: reason,
					comment: comment ?? undefined
				});
			}
		};

		const formIsReady = (reason !== null) &&
			(reason !== InstallationCancelReason.OTHER || (comment?.length ?? 0) > 0);

		switch (commandInvocationStatus) {
			case CommandStatus.IDLE:
				modalDialogue.setFooter(
					<ActionModalFooter>
						<ButtonGhost
							label={l10n.translate('platformCustomerServiceApp.actionModal.cancelInstallation.footer.cancel')}
							onClick={handleDismissDialogue}
						/>
						<ButtonPrimary
							label={l10n.translate('platformCustomerServiceApp.actionModal.cancelInstallation.footer.confirm')}
							submissionStatus={SubmissionStatus.IDLE}
							onClick={handleSubmitAction}
							disabled={!formIsReady}
						/>
					</ActionModalFooter>
				);
				break;
			case CommandStatus.PENDING:
				modalDialogue.setFooter(
					<ActionModalFooter>
						<ButtonGhost
							label={l10n.translate('platformCustomerServiceApp.actionModal.cancelInstallation.footer.cancel')}
							onClick={handleDismissDialogue}
						/>
						<ButtonPrimary
							label={l10n.translate('platformCustomerServiceApp.actionModal.cancelInstallation.footer.confirm')}
							submissionStatus={SubmissionStatus.PENDING}
							onClick={handleSubmitAction}
						/>
					</ActionModalFooter>
				);
				break;
			case CommandStatus.SUCCESSFULL:
			case CommandStatus.FAILED:
				modalDialogue.unsetFooter();
				break;
		}

	}, [commandInvocationStatus, reason, comment]);

	const handleDismissDialogue = () => {
		dialogueManager.pop();
	};

	const handleReasonChange = (value: InstallationCancelReason): void => {
		setReason(value);
	};

	const handleCommentChange = (value: string): void => {
		setComment(value);
	};

	const renderReasonDescription = () => {
		if (reason === InstallationCancelReason.OTHER) {
			return (
				<InputTextArea
					placeholder={l10n.translate('platformCustomerServiceApp.actionModal.cancelInstallation.note')}
					rows={5}
					onChange={handleCommentChange}
					value={comment ?? ''}
				/>
			);
		}

		return;
	};

	if (fetchError !== null) {
		return (
			<NotificationBig
				status={NotificationBigStatus.ERROR}
				heading={l10n.translate('platformCustomerServiceApp.actionModal.cancelInstallation.error.loading.heading')}
				message={l10n.translate('platformCustomerServiceApp.actionModal.cancelInstallation.error.loading.message')}
				subline={fetchError?.message}
			/>
		);
	}

	if (fetchPayload === null) {
		return (
			<SpinnerCircle />
		);
	}

	if (commandInvocationStatus === CommandStatus.SUCCESSFULL) {
		return (
			<SuccessFeeback heading={l10n.translate('platformCustomerServiceApp.actionModal.cancelInstallation.content.successHeading')}
				image={SuccessImageIdentifier.IMAGE4}
			>
				<ButtonGhost
					label={l10n.translate('platformCustomerServiceApp.actionModal.cancelInstallation.content.closeButtonLabel')}
					onClick={handleDismissDialogue}
				/>
			</SuccessFeeback>
		);
	}

	if (commandInvocationStatus === CommandStatus.FAILED) {
		return (
			<NotificationBig
				status={NotificationBigStatus.ERROR}
				heading={l10n.translate('platformCustomerServiceApp.actionModal.cancelInstallation.error.writing.heading')}
				message={l10n.translate('platformCustomerServiceApp.actionModal.cancelInstallation.error.writing.message')}
				subline={commandInvocationError?.message}
			/>
		);
	}

	return (
		<section className="cancel-installation-action-modal">
			<section>
				<OrderLineItemCollection
					orderLineItems={fetchPayload.orderLineItems}
					totalGrossPrice={fetchPayload.totalPrice}
				/>
			</section>

			<section className="cancel-installation-action-modal__section">
				<InputSelect<InstallationCancelReason>
					label={l10n.translate('platformCustomerServiceApp.actionModal.cancelInstallation.reason')}
					options={options}
					onSelect={handleReasonChange}
					value={reason === null ? undefined : reason}
					placeholder={l10n.translate('platformCustomerServiceApp.actionModal.pleaseSelect')}
				/>
				{renderReasonDescription()}
			</section>
		</section>
	);
}
