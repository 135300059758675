import { ModelConverter } from '@abb-emobility/shared/domain-model-foundation';
import { IsoLanguageCode } from '@abb-emobility/shared/iso-language-code';

export type CustomerServiceUpdateOrderInvocationPayloadModel = {
	phoneNumber: string,
	languageCode: IsoLanguageCode
};

export class CustomerServiceUpdateOrderInvocationPayloadModelConverter extends ModelConverter<CustomerServiceUpdateOrderInvocationPayloadModel> {
}
