import { ModelConverter, ModelConverterInterface } from '@abb-emobility/shared/domain-model-foundation';

import {
	CustomerServiceOrderInstallationAppointmentModel,
	CustomerServiceOrderInstallationAppointmentModelConverter
} from '../CustomerServiceOrderInstallationAppointmentModel';

export type CustomerServiceOrderEventAppointmentArrangedNotificationSentDetailModel = {
	mailAddress: string,
	appointments?: Array<CustomerServiceOrderInstallationAppointmentModel>,
};

export class CustomerServiceOrderEventAppointmentArrangedNotificationSentDetailModelConverter extends ModelConverter<CustomerServiceOrderEventAppointmentArrangedNotificationSentDetailModel> {
	override getFieldConverterMapByModel() {
		return new Map<keyof CustomerServiceOrderEventAppointmentArrangedNotificationSentDetailModel, ModelConverterInterface<CustomerServiceOrderInstallationAppointmentModel>>([
			['appointments', new CustomerServiceOrderInstallationAppointmentModelConverter()]
		]);
	}
}
