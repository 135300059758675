import React from 'react';

import { AnyCustomerServiceSearchResultModel } from '@abb-emobility/platform/domain-model';
import { createAccessTokenLoader, useAuth } from '@abb-emobility/shared/auth-provider';
import { FetchError } from '@abb-emobility/shared/business-error';
import {
	CollectionDataProviderProps,
	createCrudCollectionDataProviderValue,
	FetchMode,
	useDataProviderFetchFailedHandler,
	useDataProviderFetchInit,
	useDataProviderFetchSuspense
} from '@abb-emobility/shared/data-provider-foundation';
import { useEnv } from '@abb-emobility/shared/environment';
import { AppError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { Optional } from '@abb-emobility/shared/util';

import { customerServiceSearchData } from './CustomerServiceSearchData';
import {
	customerServiceSearchStoreAccessors,
	CustomerServiceSearchStore,
	customerServiceSearchStoreName
} from './CustomerServiceSearchSlice';

export function CustomerServiceSearchDataProvider(props: CollectionDataProviderProps<AnyCustomerServiceSearchResultModel>) {
	const {
		fetchMode = FetchMode.IMMEDIATE,
		suspense = fetchMode === FetchMode.IMMEDIATE,
		pendingComponent = null,
		forceFetch
	} = props;

	const auth = useAuth();
	const env = useEnv();
	const l10n = useL10n();

	const apiBaseUrl = new Optional(process.env['NX_CUSTOMER_SERVICE_API_BASE_URL']).getOrThrow(new AppError('API base URL unavailable'));

	const dataProviderValue = createCrudCollectionDataProviderValue<AnyCustomerServiceSearchResultModel, CustomerServiceSearchStore>(
		customerServiceSearchStoreName,
		customerServiceSearchStoreAccessors,
		apiBaseUrl,
		createAccessTokenLoader(auth, env)
	);

	useDataProviderFetchFailedHandler(dataProviderValue, new FetchError(l10n.translate('platformDataProvider.error.searchCollectionFetchMessage')));

	const initialSortCriteria = props.sortCriteria;
	const initialFilterCriteria = props.filterCriteria;
	const sortCriteria = dataProviderValue.querySortCriteria();
	const filterCriteria = dataProviderValue.queryFilterCriteria();
	const effectiveSortCriteria = sortCriteria ?? initialSortCriteria;
	const effectiveFilterCriteria = filterCriteria ?? initialFilterCriteria;
	useDataProviderFetchInit(fetchMode, () => dataProviderValue.fetch(effectiveSortCriteria, effectiveFilterCriteria, forceFetch));

	return useDataProviderFetchSuspense(dataProviderValue, suspense, pendingComponent).getOrDefault(
		<customerServiceSearchData.Provider value={dataProviderValue}>
			{props.children}
		</customerServiceSearchData.Provider>
	);

}
