import React from 'react';

import { CustomerServiceOrderInformationQuotationModel } from '@abb-emobility/platform/domain-model';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	CollectionItemContentSection,
	OrderComment,
	SectionHeader
} from '@abb-emobility/shared/ui-primitive';

import './OrderInformationQuotation.scss';
import { Quotation } from '../quotation/Quotation';

type QuotationProps = {
	orderInformation: CustomerServiceOrderInformationQuotationModel,
	includePricing?: boolean
};

export function OrderInformationQuotation(props: QuotationProps) {

	const { orderInformation, includePricing = true } = props;

	const l10n = useL10n();

	const renderComment = () => {
		if (orderInformation.comment) {
			return (
				<CollectionItemContentSection>
					<SectionHeader heading={l10n.translate('platformCustomerServiceApp.orderDetail.informationList.precheck.notes')} />
					<OrderComment comment={orderInformation.comment} />
				</CollectionItemContentSection>
			);
		}
		return;
	};

	return (
		<>
			<Quotation quotationInformation={orderInformation.payload} includePricing={includePricing} />
			{renderComment()}
		</>
	);

}
