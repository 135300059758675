import { createContext, useContext } from 'react';

import { HypermediaAction } from '../action-modal-factory/ActionModalFactory.types';
import { ApplicationAction } from '../application-action-factory/ApplicationActionItemFactory.types';

export type ActionbarContentContextValue = {
	getHypermediaActions: () => Array<HypermediaAction>;
	setHypermediaActions: (links: Array<HypermediaAction>) => void;
	getApplicationActions: () => Array<ApplicationAction>;
	setApplicationActions: (actions: Array<ApplicationAction>) => void;
};

export const actionbarContentContext = createContext<ActionbarContentContextValue>({
	getHypermediaActions: (): Array<HypermediaAction> => {
		throw new Error('No ActionbarContent provided');
	},

	setHypermediaActions: (_links: Array<HypermediaAction>) => {
		throw new Error('No ActionbarContent provided');
	},

	getApplicationActions: (): Array<ApplicationAction> => {
		throw new Error('No ActionbarContent provided');
	},

	setApplicationActions: (_actions: Array<ApplicationAction>) => {
		throw new Error('No ActionbarContent provided');
	}
});

export const useActionbarContent = () => {
	return useContext(actionbarContentContext);
};
