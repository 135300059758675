import React from 'react';

import { CustomerServiceCustomerCollectionDataProvider } from '@abb-emobility/platform/data-provider';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { usePageTitle } from '@abb-emobility/shared/react';
import { UiErrorHandler } from '@abb-emobility/shared/ui-error-handler';
import { useBreadcrumbModifier, SpinnerCircle, ViewHeader } from '@abb-emobility/shared/ui-primitive';

import { CustomerCollection } from '../components/customer-collection/CustomerCollection';
import { useBuildPageTitle } from '../components/page-title/PageTitle.hook';
import { useClearSearchbarContentModifier } from '../components/searchbar-content/SearchbarContent.hooks';
import { createRouteUrl, RoutePath } from '../router/Routes';

export function CustomersView() {

	const l10n = useL10n();

	usePageTitle(useBuildPageTitle('platformCustomerServiceApp.pageTitle.customers'));
	useBreadcrumbModifier([
		{
			label: l10n.translate('platformCustomerServiceApp.breadcrumbs.customers'),
			link: createRouteUrl(RoutePath.CUSTOMERS)
		}
	]);
	useClearSearchbarContentModifier();

	return (
		<>
			<ViewHeader heading={l10n.translate('platformCustomerServiceApp.viewHeader.customers')} />
			<UiErrorHandler>
				<CustomerServiceCustomerCollectionDataProvider
					pendingComponent={SpinnerCircle}
					suspense={false}
				>
					<CustomerCollection />
				</CustomerServiceCustomerCollectionDataProvider>
			</UiErrorHandler>
		</>
	);

}
