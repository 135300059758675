import React from 'react';

import { CustomerServiceSearchDataProvider } from '@abb-emobility/platform/data-provider';
import { useAuth } from '@abb-emobility/shared/auth-provider';
import { FetchMode } from '@abb-emobility/shared/data-provider-foundation';
import { useIdentity } from '@abb-emobility/shared/identity-provider';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { UiErrorHandler } from '@abb-emobility/shared/ui-error-handler';
import {
	AppLayout,
	AppLayoutMain,
	AppLayoutMainContent,
	AppLayoutMainHeader,
	AppLayoutNavigation,
	BreadcrumbFactory,
	Breadcrumbs,
	IconIdentifier,
	NavigationFooter,
	NavigationItem,
	NavigationItems,
	NavigationSubItem,
	SpinnerDots,
	ToastManager,
	Topbar,
	TopbarBreadcrumb,
	TopbarSection,
	TopbarUser
} from '@abb-emobility/shared/ui-primitive';
import { booleanFromString, Optional } from '@abb-emobility/shared/util';

import { useMarketplaceOrderCountPolling } from '../../hooks/useMarketplaceOrderCountPolling';
import { useOrderCountPolling } from '../../hooks/useOrderCountPolling';
import { useTaskCountPolling } from '../../hooks/useTaskCountPolling';
import { Router } from '../../router/Router';
import { RoutePath } from '../../router/Routes';
import { ActionbarContent } from '../actionbar-content/ActionbarContent';
import { SearchActionbar } from '../search-actionbar/SearchActionbar';
import { SearchbarContent } from '../searchbar-content/SearchbarContent';

export function AppContainer() {

	const auth = useAuth();
	const identity = useIdentity();
	const l10n = useL10n();

	const taskCount = useTaskCountPolling();
	const orderCount = useOrderCountPolling();
	const marketplaceOrderCount = useMarketplaceOrderCountPolling();

	const handleLogout = () => {
		auth.unauthenticate();
	};

	const taskPill = (taskCount !== null && taskCount > 0) ? l10n.formatNumber(taskCount, 0) : undefined;
	const orderPill = (orderCount !== null && orderCount > 0) ? l10n.formatNumber(orderCount, 0) : undefined;

	const marketplaceOrderPill = (marketplaceOrderCount !== null && marketplaceOrderCount > 0) ? l10n.formatNumber(marketplaceOrderCount, 0) : undefined;

	const renderMarketplaceOrderNavigationItem = () => {
		const marketplaceOrdersEnabled = booleanFromString(
			new Optional(process.env['NX_FEATURE_TOGGLE_MARKETPLACE_ORDERS']).getOrDefault('false')
		);
		if (!marketplaceOrdersEnabled) {
			return null;
		}
		return (
			<NavigationItem
				to={RoutePath.MARKETPLACE_ORDERS}
				icon={IconIdentifier.CLIPBOARD}
				pill={marketplaceOrderPill}
				label={l10n.translate('platformCustomerServiceApp.navigation.marketplaceOrders')}
				highlightPaths={[RoutePath.MARKETPLACE_ORDERS, RoutePath.MARKETPLACE_ORDER]}
			/>
		);
	};

	return (
		<AppLayout>
			<AppLayoutNavigation>
				<NavigationItems>
					<NavigationItem
						to={RoutePath.TASKS}
						icon={IconIdentifier.CHECK}
						pill={taskPill}
						label={l10n.translate('platformCustomerServiceApp.navigation.tasks')}
						highlightPaths={[RoutePath.TASKS, RoutePath.TASK]}
					/>
					<NavigationItem
						to={RoutePath.ORDERS}
						icon={IconIdentifier.CLIPBOARD}
						pill={orderPill}
						label={l10n.translate('platformCustomerServiceApp.navigation.orders')}
						highlightPaths={[RoutePath.ORDERS, RoutePath.ORDER, RoutePath.ORDER_TASK]}
					/>
					{renderMarketplaceOrderNavigationItem()}
					<NavigationItem
						to={RoutePath.CUSTOMERS}
						icon={IconIdentifier.PERSON}
						label={l10n.translate('platformCustomerServiceApp.navigation.customers')}
						highlightPaths={[RoutePath.CUSTOMERS, RoutePath.CUSTOMER]}
					/>
				</NavigationItems>
				<NavigationFooter>
					<NavigationSubItem
						onClick={handleLogout}
						icon={IconIdentifier.SIGN_OUT}
						label={l10n.translate('platformCustomerServiceApp.navigation.logout')}
					/>
				</NavigationFooter>
			</AppLayoutNavigation>
			<Breadcrumbs>
				<ActionbarContent>
					<SearchbarContent>
						<AppLayoutMain>
							<AppLayoutMainHeader>
								<Topbar>
									<TopbarSection>
										<TopbarBreadcrumb>
											<BreadcrumbFactory />
										</TopbarBreadcrumb>
									</TopbarSection>
									<TopbarSection>
										<TopbarUser
											name={identity.getIdentity().get()?.fullname}
											mailAddress={identity.getIdentity().get()?.mailAddress}
										/>
									</TopbarSection>
								</Topbar>
								<UiErrorHandler>
									<CustomerServiceSearchDataProvider
										pendingComponent={SpinnerDots}
										fetchMode={FetchMode.LAZY}
										suspense={false}
									>
										<SearchActionbar />
									</CustomerServiceSearchDataProvider>
								</UiErrorHandler>
							</AppLayoutMainHeader>
							<AppLayoutMainContent>
								<ToastManager>
									<Router />
								</ToastManager>
							</AppLayoutMainContent>
						</AppLayoutMain>
					</SearchbarContent>
				</ActionbarContent>
			</Breadcrumbs>
		</AppLayout>
	);
}
