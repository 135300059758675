import { CustomerServiceMarketplaceOrderModel } from '@abb-emobility/platform/domain-model';
import { addressModelMock } from '@abb-emobility/shared/api-integration';
import { CurrencyCode } from '@abb-emobility/shared/currency';
import { MarketplaceOrderState } from '@abb-emobility/shared/domain-model';
import { Dto, createTimestampDtoFromDate } from '@abb-emobility/shared/domain-model-foundation';
import { IsoLanguageCode } from '@abb-emobility/shared/iso-language-code';

import { customerServiceOrderCustomerModelMock } from './CustomerServiceOrderCustomerModel.mock';
import {
	customerServiceOrderLineItemModelMock01,
	customerServiceOrderLineItemModelMock02,
	customerServiceOrderLineItemModelMock03
} from './CustomerServiceOrderLineItemModel.mock';

export const customerServiceMarketplaceOrderModelMock: Dto<CustomerServiceMarketplaceOrderModel> = {
	id: '1',
	marketplaceOrderId: 'B2C-627',
	marketplace: 'Smart Marketplace Germany',
	state: MarketplaceOrderState.DONE,
	orderDate: createTimestampDtoFromDate(new Date('2022-01-01T16:30:12.345Z')),
	languageCode: IsoLanguageCode.DE,
	customer: customerServiceOrderCustomerModelMock,
	orderLineItems: [
		customerServiceOrderLineItemModelMock01(),
		customerServiceOrderLineItemModelMock02(),
		customerServiceOrderLineItemModelMock03()
	],
	currencyCode: CurrencyCode.EUR,
	phoneNumber: '0177/7123771237',
	deliveryAddress: addressModelMock
};
