import { CustomerServiceOrderEventInstallationApprovalMailSentDetailModel } from '@abb-emobility/platform/domain-model';
import { Dto } from '@abb-emobility/shared/domain-model-foundation';

import {
	customerServiceOrderInformationApprovalInformationModelMock
} from '../order-information/CustomerServiceOrderInformationApprovalInformationModel.mock';


export const customerServiceOrderEventInstallationApprovalMailSentDetailMock: Dto<CustomerServiceOrderEventInstallationApprovalMailSentDetailModel> = {
	mailAddress: 'lisa-marie.kloster@fiege.com',
	confirmationData: customerServiceOrderInformationApprovalInformationModelMock
};
