import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import {
	CustomerServiceSearchDataInterceptEmpty,
	CustomerServiceSearchDataSuspense,
	useCustomerServiceSearchData
} from '@abb-emobility/platform/data-provider';
import { CustomerServiceOrderSearchResultModel, CustomerServiceSearchResultType } from '@abb-emobility/platform/domain-model';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	ButtonGhost,
	ButtonPrimary,
	Card,
	CollectionFooter,
	NotificationBig,
	NotificationBigStatus,
	SegmentHeader,
	Separator,
	SpinnerCircle,
	useSearchbarFocus
} from '@abb-emobility/shared/ui-primitive';

import { createRouteUrl, RoutePath } from '../../router/Routes';
import { useActionbarContentModifier } from '../actionbar-content/ActionbarContent.hooks';
import { OrderCollectionHeader } from '../order-collection-header/OrderCollectionHeader';
import { OrderCollectionItem } from '../order-collection-item/OrderCollectionItem';

export type InboundOrderCollectionProps = {
	caller: string
};

export function InboundOrderCollection(props: InboundOrderCollectionProps) {

	const { caller } = props;

	const l10n = useL10n();
	const navigate = useNavigate();
	const searchbarFocus = useSearchbarFocus();

	const searchData = useCustomerServiceSearchData();
	const isPaginated = searchData.isPaginated();
	const hasNextPage = searchData.hasNextPage();
	const fetchPending = searchData.pending();
	const searchResults = searchData.query();

	const searchOrderResults: Array<CustomerServiceOrderSearchResultModel> = searchResults.filter(
		(model): model is CustomerServiceOrderSearchResultModel => {
			return model.type === CustomerServiceSearchResultType.ORDER;
		}
	);

	useActionbarContentModifier([], []);

	if (searchOrderResults.length === 1) {
		navigate(createRouteUrl(RoutePath.ORDER, ['orderId', searchOrderResults[0].payload.id]));
	}

	const handleShowMore = () => {
		searchData.fetchNext();
	};

	const renderEmptyState = (): ReactNode => {
		const handleSearch = () => {
			searchbarFocus();
		};
		return (
			<NotificationBig
				status={NotificationBigStatus.EMPTY2}
				heading={l10n.translate('platformCustomerServiceApp.inboundOrderCollection.emptyState.heading')}
				message={l10n.translate('platformCustomerServiceApp.inboundOrderCollection.emptyState.message')}
			>
				<ButtonPrimary
					label={l10n.translate('platformCustomerServiceApp.inboundOrderCollection.emptyState.searchLabel')}
					onClick={handleSearch}
				/>
			</NotificationBig>
		);
	};

	const renderShowMore = (): ReactNode => {
		if (!isPaginated) {
			return;
		}
		return (
			<ButtonGhost
				label={l10n.translate('platformCustomerServiceApp.inboundOrderCollection.action.showMore')}
				onClick={handleShowMore}
				disabled={!hasNextPage || fetchPending}
			/>
		);
	};

	const renderOrders = () => {
		return searchOrderResults.map((searchOrderResult): React.JSX.Element => {
			return (
				<OrderCollectionItem
					order={searchOrderResult.payload}
					key={searchOrderResult.payload.id}
				/>
			);
		});
	};

	return (
		<Card>
			<SegmentHeader caption={l10n.translate('platformCustomerServiceApp.inboundOrderCollection.heading', new Map([['caller', caller]]))} />

			<Separator />

			<CustomerServiceSearchDataSuspense pendingComponent={SpinnerCircle}>
				<CustomerServiceSearchDataInterceptEmpty emptyStateComponent={renderEmptyState}>

					<OrderCollectionHeader />

					<Separator />

					{renderOrders()}

					<CollectionFooter>
						{renderShowMore()}
					</CollectionFooter>

				</CustomerServiceSearchDataInterceptEmpty>
			</CustomerServiceSearchDataSuspense>
		</Card>
	);
}
