import { CustomerServiceOrderEventDeltaQuotationMailSentDetailModel } from '@abb-emobility/platform/domain-model';
import { Dto } from '@abb-emobility/shared/domain-model-foundation';

import {
	customerServiceOrderInformationQuotationInformationModelMock
} from '../order-information/CustomerServiceOrderInformationQuotationInformationModel.mock';

export const customerServiceOrderEventDeltaQuotationMailSentDetailMock: Dto<CustomerServiceOrderEventDeltaQuotationMailSentDetailModel> = {
	mailAddress: 'lisa-marie.kloster@fiege.com',
	quotation: customerServiceOrderInformationQuotationInformationModelMock
};

