import { CustomerServiceCustomerFoundationModel } from '../foundation/CustomerServiceCustomerFoundationModel';

/**
 * Turns a customer model into a name string like: "CompanyName, FirstName LastName"
 */
export const createFullNameFromCustomer = <Customer extends CustomerServiceCustomerFoundationModel>(customer: Customer) => {
	const companyName = customer.companyName ?? null;
	if (companyName === null) {
		return customer.firstName + ' ' + customer.lastName;
	}
	return customer.companyName + ', ' + customer.firstName + ' ' + customer.lastName;
};
