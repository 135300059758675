import {
	CustomerServiceMarketplaceOrderPaymentApiClientFactory,
	CustomerServiceMarketplaceOrderPaymentApiClientInterface
} from '@abb-emobility/platform/api-integration';
import { CustomerServiceOrderPaymentModel } from '@abb-emobility/platform/domain-model';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import {
	createCrudCreateThunk,
	createCrudCollectionSlice,
	createCrudDeleteThunk,
	createCrudFetchAllThunk,
	createCrudFetchNextThunk,
	createCrudFetchCollectionEntityThunk,
	createCrudMutateThunk,
	createCrudSelectCollection,
	createCrudSelectCollectionEntity,
	createCrudSelectCreatedEntity,
	CrudCollectionStore,
	CrudCollectionStoreAccessors,
	ThunkApiConfig,
	createCrudCountCollection,
	createCrudRefetchAllThunk,
	createCrudFetchAllSortedThunk,
	createCrudFetchAllFilteredThunk,
	createCrudRefetchCollectionEntityThunk,
	createCrudCollectionStoreSize,
	createCrudCollectionStoreEntryIds
} from '@abb-emobility/shared/data-provider-foundation';
import { Nullable } from '@abb-emobility/shared/util';

// Basic definition
export const customerServiceMarketplaceOrderPaymentCollectionStoreName = 'customer-service-marketplace-order-payment-collection-crud';
export type CustomerServiceMarketplacOrderPaymentCollectionStore = { [customerServiceMarketplaceOrderPaymentCollectionStoreName]: CrudCollectionStore<CustomerServiceOrderPaymentModel> };

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the
const createApiClient = (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) => {
	return CustomerServiceMarketplaceOrderPaymentApiClientFactory.create(apiBaseUrl, jsonWebToken);
};
const fetchAllThunk = createCrudFetchAllThunk<CustomerServiceOrderPaymentModel, CustomerServiceMarketplacOrderPaymentCollectionStore, CustomerServiceMarketplaceOrderPaymentApiClientInterface>(customerServiceMarketplaceOrderPaymentCollectionStoreName, createApiClient);
const refetchAllThunk = createCrudRefetchAllThunk<CustomerServiceOrderPaymentModel, CustomerServiceMarketplacOrderPaymentCollectionStore, CustomerServiceMarketplaceOrderPaymentApiClientInterface>(customerServiceMarketplaceOrderPaymentCollectionStoreName, createApiClient);
const fetchAllSortedThunk = createCrudFetchAllSortedThunk<CustomerServiceOrderPaymentModel, CustomerServiceMarketplacOrderPaymentCollectionStore, CustomerServiceMarketplaceOrderPaymentApiClientInterface>(customerServiceMarketplaceOrderPaymentCollectionStoreName, createApiClient);
const fetchAllFilteredThunk = createCrudFetchAllFilteredThunk<CustomerServiceOrderPaymentModel, CustomerServiceMarketplacOrderPaymentCollectionStore, CustomerServiceMarketplaceOrderPaymentApiClientInterface>(customerServiceMarketplaceOrderPaymentCollectionStoreName, createApiClient);
const fetchNextThunk = createCrudFetchNextThunk<CustomerServiceOrderPaymentModel, CustomerServiceMarketplacOrderPaymentCollectionStore, CustomerServiceMarketplaceOrderPaymentApiClientInterface>(customerServiceMarketplaceOrderPaymentCollectionStoreName, createApiClient);
const fetchEntityThunk = createCrudFetchCollectionEntityThunk<CustomerServiceOrderPaymentModel, CustomerServiceMarketplacOrderPaymentCollectionStore, CustomerServiceMarketplaceOrderPaymentApiClientInterface>(customerServiceMarketplaceOrderPaymentCollectionStoreName, createApiClient);
const refetchEntityThunk = createCrudRefetchCollectionEntityThunk<CustomerServiceOrderPaymentModel, CustomerServiceMarketplacOrderPaymentCollectionStore, CustomerServiceMarketplaceOrderPaymentApiClientInterface>(customerServiceMarketplaceOrderPaymentCollectionStoreName, createApiClient);
const mutateThunk = createCrudMutateThunk<CustomerServiceOrderPaymentModel, CustomerServiceMarketplaceOrderPaymentApiClientInterface>(customerServiceMarketplaceOrderPaymentCollectionStoreName, createApiClient);
const createThunk = createCrudCreateThunk<CustomerServiceOrderPaymentModel, CustomerServiceMarketplaceOrderPaymentApiClientInterface>(customerServiceMarketplaceOrderPaymentCollectionStoreName, createApiClient);
const deleteThunk = createCrudDeleteThunk<CustomerServiceOrderPaymentModel, CustomerServiceMarketplaceOrderPaymentApiClientInterface>(customerServiceMarketplaceOrderPaymentCollectionStoreName, createApiClient);

// Slice definition
export const customerServiceMarketplaceOrderPaymentCollectionSlice = createCrudCollectionSlice<CustomerServiceOrderPaymentModel, ThunkApiConfig>(
	customerServiceMarketplaceOrderPaymentCollectionStoreName,
	fetchAllThunk,
	refetchAllThunk,
	fetchAllSortedThunk,
	fetchAllFilteredThunk,
	fetchNextThunk,
	fetchEntityThunk,
	refetchEntityThunk,
	mutateThunk,
	createThunk,
	deleteThunk
);
const { resolveFetchStatus, resolveActionStatus, resetStore } = customerServiceMarketplaceOrderPaymentCollectionSlice.actions;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
const selectCollection = createCrudSelectCollection<CustomerServiceOrderPaymentModel, CustomerServiceMarketplacOrderPaymentCollectionStore>(customerServiceMarketplaceOrderPaymentCollectionStoreName);
const countCollection = createCrudCountCollection<CustomerServiceOrderPaymentModel, CustomerServiceMarketplacOrderPaymentCollectionStore>(customerServiceMarketplaceOrderPaymentCollectionStoreName);
const selectEntity = createCrudSelectCollectionEntity<CustomerServiceOrderPaymentModel, CustomerServiceMarketplacOrderPaymentCollectionStore>(customerServiceMarketplaceOrderPaymentCollectionStoreName);
const selectCreated = createCrudSelectCreatedEntity<CustomerServiceOrderPaymentModel, CustomerServiceMarketplacOrderPaymentCollectionStore>(customerServiceMarketplaceOrderPaymentCollectionStoreName);
const storeSize = createCrudCollectionStoreSize<CustomerServiceOrderPaymentModel, CustomerServiceMarketplacOrderPaymentCollectionStore>(customerServiceMarketplaceOrderPaymentCollectionStoreName);
const storeEntryIds = createCrudCollectionStoreEntryIds<CustomerServiceOrderPaymentModel, CustomerServiceMarketplacOrderPaymentCollectionStore>(customerServiceMarketplaceOrderPaymentCollectionStoreName);

// Export the store accessors to be used by the data provider value
export const customerServiceMarketplaceOrderShipmentCollectionStoreAccessors = {
	fetchAll: fetchAllThunk,
	refetchAll: refetchAllThunk,
	fetchAllSorted: fetchAllSortedThunk,
	fetchAllFiltered: fetchAllFilteredThunk,
	fetchNext: fetchNextThunk,
	fetchEntity: fetchEntityThunk,
	refetchEntity: refetchEntityThunk,
	create: createThunk,
	mutate: mutateThunk,
	delete: deleteThunk,
	resolveFetchStatus,
	resolveActionStatus,
	selectCollection,
	countCollection,
	selectEntity,
	selectCreated,
	resetStore,
	storeSize,
	storeEntryIds
} as CrudCollectionStoreAccessors<CustomerServiceOrderPaymentModel, CustomerServiceMarketplacOrderPaymentCollectionStore>;
