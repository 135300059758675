import { CustomerServiceOrderInformationPrecheckInformationModel } from '@abb-emobility/platform/domain-model';
import { DecisionType, InstallationDecisionType } from '@abb-emobility/shared/domain-model';
import { Dto } from '@abb-emobility/shared/domain-model-foundation';

export const customerServiceOrderInformationPrecheckInformationModelMock: Dto<CustomerServiceOrderInformationPrecheckInformationModel> = {
	precheckDecision: {
		decision: DecisionType.ACCEPTED,
		installationType: InstallationDecisionType.DELTA
	},
	requestedEffort: [
		{
			trade: 'Earthworks',
			expectedEffort: 12
		},
		{
			trade: 'Electrical',
			expectedEffort: 8
		}
	]
};
