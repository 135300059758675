import {
	CustomerServiceOrderReplacementModel,
	CustomerServiceOrderReturnModel,
	CustomerServiceOrderShipmentModel,
	ShipmentType
} from '@abb-emobility/platform/domain-model';
import { addressModelMock } from '@abb-emobility/shared/api-integration';
import { ShippingState } from '@abb-emobility/shared/domain-model';
import { Dto, createTimestampDtoFromDate } from '@abb-emobility/shared/domain-model-foundation';

import { customerServiceOrderLineItemModelMock02 } from './CustomerServiceOrderLineItemModel.mock';

export const customerServiceOrderShipmentModelMock: Dto<CustomerServiceOrderShipmentModel> = {
	id: '1',
	orderId: '1',
	type: ShipmentType.SHIPMENT,
	shipmentDate: createTimestampDtoFromDate(new Date('2022-02-01T16:30:12.345Z')),
	estimatedDeliveryDate: createTimestampDtoFromDate(new Date('2022-03-04T16:30:12.345Z')),
	deliveryDate: createTimestampDtoFromDate(new Date('2022-02-04T16:30:12.345Z')),
	receiverName: 'Phillip Otto Runge',
	deliveryAddress: addressModelMock,
	tracking: {
		carrier: 'DHL',
		state: ShippingState.DELIVERED,
		trackingCode: '123455678',
		trackingUrl: 'https://some-tracking.com/123455678'
	},
	orderLineItems: [customerServiceOrderLineItemModelMock02()]
};

export const customerServiceOrderReturnModelMock: Dto<CustomerServiceOrderReturnModel> = {
	id: '2',
	orderId: '1',
	type: ShipmentType.RETURN,
	shipmentDate: createTimestampDtoFromDate(new Date('2022-02-01T16:30:12.345Z')),
	estimatedDeliveryDate: createTimestampDtoFromDate(new Date('2022-02-04T16:30:12.345Z')),
	receiverName: 'Phillip Otto Runge',
	deliveryAddress: addressModelMock,
	tracking: {
		carrier: 'DHL',
		state: ShippingState.DELIVERED,
		trackingCode: '123455678',
		trackingUrl: 'https://some-tracking.com/123455678'
	},
	shipmentDocuments: [{
		url: {
			accessible: true,
			value: 'https://fiege.com'
		},
		meta: {
			mimeType: 'application/pdf',
			title: 'Return label',
			fileName: 'test.pdf',
			lastModifiedDate: '2022-01-01T16:30:12.345Z',
			size: 12345
		}
	}],
	reason: 'Damaged goods',
	orderLineItems: [customerServiceOrderLineItemModelMock02()]
};

export const customerServiceOrderReplacementModelMock: Dto<CustomerServiceOrderReplacementModel> = {
	id: '3',
	orderId: '1',
	type: ShipmentType.REPLACEMENT,
	shipmentDate: createTimestampDtoFromDate(new Date('2022-02-01T16:30:12.345Z')),
	estimatedDeliveryDate: createTimestampDtoFromDate(new Date('2022-02-04T16:30:12.345Z')),
	deliveryDate: createTimestampDtoFromDate(new Date('2022-02-04T16:30:12.345Z')),
	receiverName: 'Phillip Otto Runge',
	deliveryAddress: addressModelMock,
	tracking: {
		carrier: 'DHL',
		state: ShippingState.DELIVERED,
		trackingCode: '123455678',
		trackingUrl: 'https://some-tracking.com/123455678'
	},
	orderLineItems: [customerServiceOrderLineItemModelMock02()]
};

export const customerServiceOrderReplacementModelMock02: Dto<CustomerServiceOrderReplacementModel> = {
	id: '3',
	orderId: '1',
	type: ShipmentType.REPLACEMENT,
	shipmentDate: createTimestampDtoFromDate(new Date('2022-02-01T16:30:12.345Z')),
	receiverName: 'Phillip Otto Runge',
	deliveryAddress: addressModelMock,
	orderLineItems: [customerServiceOrderLineItemModelMock02()]
};

export const customerServiceOrderShipmentModelCollectionMock = [
	customerServiceOrderShipmentModelMock,
	customerServiceOrderReturnModelMock,
	customerServiceOrderReplacementModelMock,
	customerServiceOrderReplacementModelMock02
];
