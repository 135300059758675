import React, { ReactNode } from 'react';

import {
	CustomerServiceMarketplaceOrderPaymentCollectionDataInterceptEmpty,
	CustomerServiceMarketplaceOrderPaymentCollectionDataSuspense,
	useCustomerServiceMarketplaceOrderPaymentCollectionData
} from '@abb-emobility/platform/data-provider';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	NotificationBig,
	NotificationBigStatus,
	SpinnerCircle
} from '@abb-emobility/shared/ui-primitive';

import { Payments } from '../payments/Payments';

import './MarketplaceOrderDetailAccounting.scss';

export function MarketplaceOrderDetailAccounting() {

	const orderPaymentData = useCustomerServiceMarketplaceOrderPaymentCollectionData();
	const payments = orderPaymentData.query();

	const l10n = useL10n();

	const renderEmptyPaymentState = (): ReactNode => {
		return (
			<NotificationBig
				status={NotificationBigStatus.EMPTY2}
				heading={l10n.translate('platformCustomerServiceApp.orderDetail.accounting.emptyPaymentState.heading')}
				message={l10n.translate('platformCustomerServiceApp.orderDetail.accounting.emptyPaymentState.message')}
			/>
		);
	};

	return (
		<main className="marketplace-order-detail-accounting">
			<section className="marketplace-order-detail-accounting__content">
				<CustomerServiceMarketplaceOrderPaymentCollectionDataSuspense pendingComponent={SpinnerCircle}>
					<CustomerServiceMarketplaceOrderPaymentCollectionDataInterceptEmpty emptyStateComponent={renderEmptyPaymentState}>
						<Payments payments={[...payments]} />
					</CustomerServiceMarketplaceOrderPaymentCollectionDataInterceptEmpty>
				</CustomerServiceMarketplaceOrderPaymentCollectionDataSuspense>
			</section>
		</main>
	);
}
