import { numericApiSafeguard } from '@abb-emobility/shared/domain-model';
import {
	ArrayOfTimestampFieldNames,
	CustomConversion,
	Dto,
	ModelConverter,
	ModelConverterInterface,
	ModelPrimaryKey, Timestamp, TimestampFieldNames
} from '@abb-emobility/shared/domain-model-foundation';

import { PricingModel, PricingModelConverter } from './common/PricingModel';
import { isPrizingModel } from './common/PricingModel.guards';

type CancellationState = {
	readonly cancelled: true,
	readonly cancellationDate: Timestamp
} | {
	readonly cancelled: false,
	readonly cancellationDate?: never
};

export type CustomerServiceOrderLineItemModel = {
	readonly id: ModelPrimaryKey,
	readonly productName: string,
	readonly productCode: string,
	readonly price?: number | PricingModel
} & CancellationState;

export class CustomerServiceOrderLineItemModelConverter extends ModelConverter<CustomerServiceOrderLineItemModel> {

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	override getFieldConverterMapByModel(model: Dto<CustomerServiceOrderLineItemModel> | CustomerServiceOrderLineItemModel): Map<keyof CustomerServiceOrderLineItemModel, ModelConverterInterface<any>> {
		const price = model.price ?? null;
		if (price !== null && isPrizingModel(price)) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			return new Map<keyof CustomerServiceOrderLineItemModel, ModelConverterInterface<any>>([
				['price', new PricingModelConverter()]
			]);
		}

		return super.getFieldConverterMapByModel(model);
	}

	override getTimestampFields(): Array<TimestampFieldNames<CustomerServiceOrderLineItemModel> | ArrayOfTimestampFieldNames<CustomerServiceOrderLineItemModel>> {
		return ['cancellationDate'];
	}

	override getCustomConversionFields(): Map<keyof CustomerServiceOrderLineItemModel, CustomConversion> {
		return new Map([
			['price', numericApiSafeguard]
		]);
	}

}
