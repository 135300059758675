import { ArrayOfTimestampFieldNames, Dto, ModelConverter, TimestampFieldNames } from '@abb-emobility/shared/domain-model-foundation';

import { CustomerServiceOrderEventFoundationModel } from './foundation/CustomerServiceOrderEventFoundationModel';
import {
	CustomerServiceOrderEventAppointmentArrangedNotificationSentDetailModel,
	CustomerServiceOrderEventAppointmentArrangedNotificationSentDetailModelConverter
} from './order-event/CustomerServiceOrderEventAppointmentArrangedNotificationSentDetailModel';
import {
	CustomerServiceOrderEventDeltaQuotationMailSentDetailModel, CustomerServiceOrderEventDeltaQuotationMailSentDetailModelConverter
} from './order-event/CustomerServiceOrderEventDeltaQuotationMailSentDetailModel';
import {
	CustomerServiceOrderEventGridOperatorRejectionNotificationSentDetailModel,
	CustomerServiceOrderEventGridOperatorRejectionNotificationSentDetailModelConverter
} from './order-event/CustomerServiceOrderEventGridOperatorRejectionNotificationSentDetailModel';
import {
	CustomerServiceOrderEventInstallationApprovalMailSentDetailModel,
	CustomerServiceOrderEventInstallationApprovalMailSentDetailModelConverter
} from './order-event/CustomerServiceOrderEventInstallationApprovalMailSentDetailModel';
import {
	CustomerServiceOrderEventInstallationCancellationConfirmationMailSentDetailModel,
	CustomerServiceOrderEventInstallationCancellationConfirmationMailSentDetailModelConverter
} from './order-event/CustomerServiceOrderEventInstallationCancellationConfirmationMailSentDetailModel';
import {
	CustomerServiceOrderEventInvoiceNotificationSentDetailModel,
	CustomerServiceOrderEventInvoiceNotificationSentDetailModelConverter
} from './order-event/CustomerServiceOrderEventInvoiceNotificationSentDetailModel';
import {
	CustomerServiceOrderEventOrderConfirmationMailSentDetailModel,
	CustomerServiceOrderEventOrderConfirmationMailSentDetailModelConverter
} from './order-event/CustomerServiceOrderEventOrderConfirmationMailSentDetailModel';
import {
	CustomerServiceOrderEventPaymentConfirmationMailSentDetailModel,
	CustomerServiceOrderEventPaymentConfirmationMailSentDetailModelConverter
} from './order-event/CustomerServiceOrderEventPaymentConfirmationMailSentDetailModel';
import {
	CustomerServiceOrderEventPaymentFailedOrTimedOutDetailModel, CustomerServiceOrderEventPaymentFailedOrTimedOutDetailModelConverter
} from './order-event/CustomerServiceOrderEventPaymentFailedOrTimedOutDetailModel';
import {
	CustomerServiceOrderEventPreCheckPreferencesMailSentDetailModel,
	CustomerServiceOrderEventPreCheckPreferencesMailSentDetailModelConverter
} from './order-event/CustomerServiceOrderEventPreCheckPreferencesMailSentDetailModel';
import { CustomerServiceOrderEventType } from './order-event/CustomerServiceOrderEventType';

// TODO ADD MORE MODELS
export type AnyCustomerServiceOrderEventEntityModel =
	CustomerServiceOrderEventOrderConfirmationMailSentModel |
	CustomerServiceOrderEventGridOperatorRejectionNotificationSentModel |
	CustomerServiceOrderEventPreCheckPreferencesMailSentModel |
	CustomerServiceOrderEventDeltaQuotationMailSentModel |
	CustomerServiceOrderEventPaymentFailedOrTimedOutModel |
	CustomerServiceOrderEventPaymentConfirmationMailSentModel |
	CustomerServiceOrderEventAppointmentArrangedNotificationSentModel |
	CustomerServiceOrderEventInstallationApprovalMailSentModel |
	CustomerServiceOrderEventInvoiceNotificationSentModel |
	CustomerServiceOrderEventInstallationCancellationConfirmationMailSentModel;

export type CustomerServiceOrderEventOrderConfirmationMailSentModel = {
	type: CustomerServiceOrderEventType.ORDER_CONFIRMATION_MAIL_SENT,
	details: CustomerServiceOrderEventOrderConfirmationMailSentDetailModel
} & CustomerServiceOrderEventFoundationModel

export type CustomerServiceOrderEventGridOperatorRejectionNotificationSentModel = {
	type: CustomerServiceOrderEventType.GRID_OPERATOR_REJECTION_NOTIFICATION_SENT,
	details: CustomerServiceOrderEventGridOperatorRejectionNotificationSentDetailModel
} & CustomerServiceOrderEventFoundationModel

export type CustomerServiceOrderEventPreCheckPreferencesMailSentModel = {
	type: CustomerServiceOrderEventType.PRECHECK_PREFERENCES_MAIL_SENT,
	details: CustomerServiceOrderEventPreCheckPreferencesMailSentDetailModel
} & CustomerServiceOrderEventFoundationModel

export type CustomerServiceOrderEventDeltaQuotationMailSentModel = {
	type: CustomerServiceOrderEventType.DELTA_QUOTATION_MAIL_SENT,
	details: CustomerServiceOrderEventDeltaQuotationMailSentDetailModel
} & CustomerServiceOrderEventFoundationModel

export type CustomerServiceOrderEventPaymentFailedOrTimedOutModel = {
	type: CustomerServiceOrderEventType.PAYMENT_FAILED_OR_TIMED_OUT,
	details: CustomerServiceOrderEventPaymentFailedOrTimedOutDetailModel
} & CustomerServiceOrderEventFoundationModel

export type CustomerServiceOrderEventPaymentConfirmationMailSentModel = {
	type: CustomerServiceOrderEventType.PAYMENT_CONFIRMATION_MAIL_SENT,
	details: CustomerServiceOrderEventPaymentConfirmationMailSentDetailModel
} & CustomerServiceOrderEventFoundationModel

export type CustomerServiceOrderEventAppointmentArrangedNotificationSentModel = {
	type: CustomerServiceOrderEventType.APPOINTMENT_ARRANGED_NOTIFICATION_SENT,
	details: CustomerServiceOrderEventAppointmentArrangedNotificationSentDetailModel
} & CustomerServiceOrderEventFoundationModel

export type CustomerServiceOrderEventInstallationApprovalMailSentModel = {
	type: CustomerServiceOrderEventType.INSTALLATION_APPROVAL_MAIL_SENT,
	details: CustomerServiceOrderEventInstallationApprovalMailSentDetailModel
} & CustomerServiceOrderEventFoundationModel

export type CustomerServiceOrderEventInvoiceNotificationSentModel = {
	type: CustomerServiceOrderEventType.INVOICE_NOTIFICATION_SENT,
	details: CustomerServiceOrderEventInvoiceNotificationSentDetailModel
} & CustomerServiceOrderEventFoundationModel

export type CustomerServiceOrderEventInstallationCancellationConfirmationMailSentModel = {
	type: CustomerServiceOrderEventType.INSTALLATION_CANCELLATION_MAIL_SENT,
	details: CustomerServiceOrderEventInstallationCancellationConfirmationMailSentDetailModel
} & CustomerServiceOrderEventFoundationModel

export class AnyCustomerServiceOrderEventEntityModelConverter extends ModelConverter<AnyCustomerServiceOrderEventEntityModel> {
	override getTimestampFields(): Array<TimestampFieldNames<AnyCustomerServiceOrderEventEntityModel> | ArrayOfTimestampFieldNames<AnyCustomerServiceOrderEventEntityModel>> {
		return ['eventDate'];
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	override getFieldConverterMapByModel(model: AnyCustomerServiceOrderEventEntityModel | Dto<AnyCustomerServiceOrderEventEntityModel>) {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const converterMap = new Map<keyof AnyCustomerServiceOrderEventEntityModel, any>;
		switch (model.type) {
			case CustomerServiceOrderEventType.ORDER_CONFIRMATION_MAIL_SENT:
				converterMap.set('details', new CustomerServiceOrderEventOrderConfirmationMailSentDetailModelConverter());
				break;
			case CustomerServiceOrderEventType.GRID_OPERATOR_REJECTION_NOTIFICATION_SENT:
				converterMap.set('details', new CustomerServiceOrderEventGridOperatorRejectionNotificationSentDetailModelConverter());
				break;
			case CustomerServiceOrderEventType.PRECHECK_PREFERENCES_MAIL_SENT:
				converterMap.set('details', new CustomerServiceOrderEventPreCheckPreferencesMailSentDetailModelConverter());
				break;
			case CustomerServiceOrderEventType.DELTA_QUOTATION_MAIL_SENT:
				converterMap.set('details', new CustomerServiceOrderEventDeltaQuotationMailSentDetailModelConverter());
				break;
			case CustomerServiceOrderEventType.PAYMENT_FAILED_OR_TIMED_OUT:
				converterMap.set('details', new CustomerServiceOrderEventPaymentFailedOrTimedOutDetailModelConverter());
				break;
			case CustomerServiceOrderEventType.PAYMENT_CONFIRMATION_MAIL_SENT:
				converterMap.set('details', new CustomerServiceOrderEventPaymentConfirmationMailSentDetailModelConverter());
				break;
			case CustomerServiceOrderEventType.APPOINTMENT_ARRANGED_NOTIFICATION_SENT:
				converterMap.set('details', new CustomerServiceOrderEventAppointmentArrangedNotificationSentDetailModelConverter());
				break;
			case CustomerServiceOrderEventType.INSTALLATION_APPROVAL_MAIL_SENT:
				converterMap.set('details', new CustomerServiceOrderEventInstallationApprovalMailSentDetailModelConverter());
				break;
			case CustomerServiceOrderEventType.INVOICE_NOTIFICATION_SENT:
				converterMap.set('details', new CustomerServiceOrderEventInvoiceNotificationSentDetailModelConverter());
				break;
			case CustomerServiceOrderEventType.INSTALLATION_CANCELLATION_MAIL_SENT:
				converterMap.set('details', new CustomerServiceOrderEventInstallationCancellationConfirmationMailSentDetailModelConverter());
				break;
		}
		return converterMap;
	}
}
