import { InstallationCancelReason } from '@abb-emobility/shared/domain-model';
import { ModelConverter } from '@abb-emobility/shared/domain-model-foundation';

export type CustomerServiceCancelInstallationInvocationPayloadModel = {
	reason: InstallationCancelReason,
	comment?: string
};

export class CustomerServiceCancelInstallationInvocationPayloadModelConverter extends ModelConverter<CustomerServiceCancelInstallationInvocationPayloadModel> {
}
