import {
	ArrayOfTimestampFieldNames, CustomConversion,
	ModelConverter,
	ModelConverterInterface,
	TimestampFieldNames
} from '@abb-emobility/shared/domain-model-foundation';
import { IsoLanguageCode } from '@abb-emobility/shared/iso-language-code';

import { CustomerServiceOrderCustomerModel, CustomerServiceOrderCustomerModelConverter } from './CustomerServiceOrderCustomerModel';
import { CustomerServiceMarketplaceOrderFoundationModel } from './foundation/CustomerServiceMarketplaceOrderFoundationModel';

export type CustomerServiceMarketplaceOrderCollectionItemModel = CustomerServiceMarketplaceOrderFoundationModel & {
	readonly customer: CustomerServiceOrderCustomerModel,
};

export class CustomerServiceMarketplaceOrderCollectionItemModelConverter extends ModelConverter<CustomerServiceMarketplaceOrderCollectionItemModel> {

	override getCustomConversionFields(): Map<keyof CustomerServiceMarketplaceOrderCollectionItemModel, CustomConversion> {
		return new Map([[
			'languageCode', {
				toModel: (dtoValue: string) => {
					return dtoValue?.toLowerCase() as IsoLanguageCode;
				},
				toDto: (modelValue) => {
					return modelValue;
				}
			}
		]]);
	}

	override getTimestampFields(): Array<TimestampFieldNames<CustomerServiceMarketplaceOrderCollectionItemModel> | ArrayOfTimestampFieldNames<CustomerServiceMarketplaceOrderCollectionItemModel>> {
		return [ 'orderDate'];
	}

	override getFieldConverterMapByModel() {
		return new Map<keyof CustomerServiceMarketplaceOrderCollectionItemModel, ModelConverterInterface<CustomerServiceOrderCustomerModel>>([
			['customer', new CustomerServiceOrderCustomerModelConverter()]
		]);
	}
}
