import { createContext, useContext } from 'react';

import { Nullable } from '@abb-emobility/shared/util';

export type SearchbarContentContextValue = {
	getOrderId: () => Nullable<string>
	setOrderId: (orderId: Nullable<string>) => void;
	getCustomerId: () => Nullable<string>
	setCustomerId: (customer: Nullable<string>) => void;
	getDropdownAvailable: () => boolean;
	setDropdownAvailable: (dropdownAvailable: boolean) => void;
};

export const searchbarContentContext = createContext<SearchbarContentContextValue>({
	getOrderId: (): Nullable<string> => {
		throw new Error('No SearchActionbarContent provided');
	},

	setOrderId: (_orderId: Nullable<string>) => {
		throw new Error('No SearchActionbarContent provided');
	},

	getCustomerId: (): Nullable<string> => {
		throw new Error('No SearchActionbarContent provided');
	},
	setCustomerId: (_customerId: Nullable<string>) => {
		throw new Error('No SearchActionbarContent provided');
	},
	getDropdownAvailable: (): boolean => {
		throw new Error('No SearchActionbarContent provided');
	},
	setDropdownAvailable: (_dropdownAvailable: boolean) => {
		throw new Error('No SearchActionbarContent provided');
	}
});

export const useSearchbarContent = () => {
	return useContext(searchbarContentContext);
};
