import React, { useState } from 'react';

import { CustomerServiceCommentType } from '@abb-emobility/platform/domain-model';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { InputSelect, InputSelectOption } from '@abb-emobility/shared/ui-primitive';
import { l10nLiteralFromEnumValue } from '@abb-emobility/shared/util';

import { CustomerServiceCustomerCommentType, CustomerServiceOrderCommentType } from './AddNoteActionModal.enums';
import { AddCustomerCallNoteActionModal } from './add-customer-call-note-action-modal/AddCustomerCallNoteActionModal';
import { AddCustomerNoteActionModal } from './add-customer-note-action-modal/AddCustomerNoteActionModal';
import { AddOrderNoteActionModal } from './add-order-note-action-modal/AddOrderNoteActionModal';
import { HypermediaAction } from '../../ActionModalFactory.types';

export const enum NoteContext {
	ORDER = 'ORDER',
	CUSTOMER = 'CUSTOMER,'
}

export type AddNoteActionModalProps = {
	noteContext: NoteContext,
	action: HypermediaAction
};

export function AddNoteActionModal(props: AddNoteActionModalProps) {

	const { noteContext, action } = props;

	let defaultCategory: string;
	let availableCategoriesEnum: typeof CustomerServiceCommentType | typeof CustomerServiceCustomerCommentType | typeof CustomerServiceOrderCommentType;

	switch (noteContext) {
		case NoteContext.ORDER:
			defaultCategory = CustomerServiceCommentType.ORDER;
			availableCategoriesEnum = CustomerServiceCommentType;
			break;
		case NoteContext.CUSTOMER: {
			defaultCategory = CustomerServiceCustomerCommentType.CUSTOMER;
			availableCategoriesEnum = CustomerServiceCustomerCommentType;
			break;
		}
	}

	const l10n = useL10n();

	const [category, setCategory] = useState(defaultCategory);

	const categoryOptions: Array<InputSelectOption> = Object.values(availableCategoriesEnum)
		.map((value) => (
				{
					label: l10n.translate(l10nLiteralFromEnumValue(
							value,
							'platformCustomerServiceApp.actionModal.addNote.content.categories'
						)
					),
					value: value
				}
			)
		);

	const handleCategorySelect = (value: string) => {
		setCategory(value);
	};

	const renderInputFields = (): React.ReactNode => {
		switch (category) {
			case CustomerServiceCommentType.ORDER: {
				return (
					<AddOrderNoteActionModal action={action} />
				);
			}
			case CustomerServiceCommentType.CUSTOMER: {
				return (
					<AddCustomerNoteActionModal action={action} />
				);
			}
			case CustomerServiceCommentType.CUSTOMER_CALL: {
				return (
					<AddCustomerCallNoteActionModal action={action} />
				);
			}
			default:
				return null;
		}

	};
	return (
		<>
			<InputSelect
				label={l10n.translate('platformCustomerServiceApp.actionModal.addNote.content.category')}
				options={categoryOptions}
				onSelect={handleCategorySelect}
				disabled={categoryOptions.length < 2}
			/>
			{renderInputFields()}
		</>
	);
}
