import { ReactNode } from 'react';

import './CommentUser.scss';

export type CommentUserProps = {
	children?: ReactNode,
	name: string
};

export function CommentUser(props: CommentUserProps) {
	const { children, name } = props;

	return (
		<section className="comment-user">
			{children}
			{name}
		</section>
	);
}
