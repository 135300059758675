import {
	CustomerServiceOrderEventCollectionApiClientFactory,
	CustomerServiceOrderEventCollectionApiClientInterface
} from '@abb-emobility/platform/api-integration';
import { CustomerServiceOrderEventCollectionModel } from '@abb-emobility/platform/domain-model';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import {
	createCrudCreateThunk,
	createCrudCollectionSlice,
	createCrudDeleteThunk,
	createCrudFetchAllThunk,
	createCrudFetchNextThunk,
	createCrudFetchCollectionEntityThunk,
	createCrudMutateThunk,
	createCrudSelectCollection,
	createCrudSelectCollectionEntity,
	createCrudSelectCreatedEntity,
	CrudCollectionStore,
	CrudCollectionStoreAccessors,
	ThunkApiConfig,
	createCrudCountCollection,
	createCrudRefetchAllThunk,
	createCrudFetchAllSortedThunk,
	createCrudFetchAllFilteredThunk,
	createCrudRefetchCollectionEntityThunk,
	createCrudCollectionStoreSize,
	createCrudCollectionStoreEntryIds
} from '@abb-emobility/shared/data-provider-foundation';
import { Nullable } from '@abb-emobility/shared/util';

// Basic definition
export const customerServiceOrderEventCollectionStoreName = 'customer-service-order-event-collection-crud';
export type CustomerServiceOrderEventCollectionStore = { [customerServiceOrderEventCollectionStoreName]: CrudCollectionStore<CustomerServiceOrderEventCollectionModel> };

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the
const createApiClient = (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) => {
	return CustomerServiceOrderEventCollectionApiClientFactory.create(apiBaseUrl, jsonWebToken);
};
const fetchAllThunk = createCrudFetchAllThunk<CustomerServiceOrderEventCollectionModel, CustomerServiceOrderEventCollectionStore, CustomerServiceOrderEventCollectionApiClientInterface>(customerServiceOrderEventCollectionStoreName, createApiClient);
const refetchAllThunk = createCrudRefetchAllThunk<CustomerServiceOrderEventCollectionModel, CustomerServiceOrderEventCollectionStore, CustomerServiceOrderEventCollectionApiClientInterface>(customerServiceOrderEventCollectionStoreName, createApiClient);
const fetchAllSortedThunk = createCrudFetchAllSortedThunk<CustomerServiceOrderEventCollectionModel, CustomerServiceOrderEventCollectionStore, CustomerServiceOrderEventCollectionApiClientInterface>(customerServiceOrderEventCollectionStoreName, createApiClient);
const fetchAllFilteredThunk = createCrudFetchAllFilteredThunk<CustomerServiceOrderEventCollectionModel, CustomerServiceOrderEventCollectionStore, CustomerServiceOrderEventCollectionApiClientInterface>(customerServiceOrderEventCollectionStoreName, createApiClient);
const fetchNextThunk = createCrudFetchNextThunk<CustomerServiceOrderEventCollectionModel, CustomerServiceOrderEventCollectionStore, CustomerServiceOrderEventCollectionApiClientInterface>(customerServiceOrderEventCollectionStoreName, createApiClient);
const fetchEntityThunk = createCrudFetchCollectionEntityThunk<CustomerServiceOrderEventCollectionModel, CustomerServiceOrderEventCollectionStore, CustomerServiceOrderEventCollectionApiClientInterface>(customerServiceOrderEventCollectionStoreName, createApiClient);
const refetchEntityThunk = createCrudRefetchCollectionEntityThunk<CustomerServiceOrderEventCollectionModel, CustomerServiceOrderEventCollectionStore, CustomerServiceOrderEventCollectionApiClientInterface>(customerServiceOrderEventCollectionStoreName, createApiClient);
const mutateThunk = createCrudMutateThunk<CustomerServiceOrderEventCollectionModel, CustomerServiceOrderEventCollectionApiClientInterface>(customerServiceOrderEventCollectionStoreName, createApiClient);
const createThunk = createCrudCreateThunk<CustomerServiceOrderEventCollectionModel, CustomerServiceOrderEventCollectionApiClientInterface>(customerServiceOrderEventCollectionStoreName, createApiClient);
const deleteThunk = createCrudDeleteThunk<CustomerServiceOrderEventCollectionModel, CustomerServiceOrderEventCollectionApiClientInterface>(customerServiceOrderEventCollectionStoreName, createApiClient);

// Slice definition
export const customerServiceOrderEventCollectionSlice = createCrudCollectionSlice<CustomerServiceOrderEventCollectionModel, ThunkApiConfig>(
	customerServiceOrderEventCollectionStoreName,
	fetchAllThunk,
	refetchAllThunk,
	fetchAllSortedThunk,
	fetchAllFilteredThunk,
	fetchNextThunk,
	fetchEntityThunk,
	refetchEntityThunk,
	mutateThunk,
	createThunk,
	deleteThunk
);
const { resolveFetchStatus, resolveActionStatus, resetStore } = customerServiceOrderEventCollectionSlice.actions;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
const selectCollection = createCrudSelectCollection<CustomerServiceOrderEventCollectionModel, CustomerServiceOrderEventCollectionStore>(customerServiceOrderEventCollectionStoreName);
const countCollection = createCrudCountCollection<CustomerServiceOrderEventCollectionModel, CustomerServiceOrderEventCollectionStore>(customerServiceOrderEventCollectionStoreName);
const selectEntity = createCrudSelectCollectionEntity<CustomerServiceOrderEventCollectionModel, CustomerServiceOrderEventCollectionStore>(customerServiceOrderEventCollectionStoreName);
const selectCreated = createCrudSelectCreatedEntity<CustomerServiceOrderEventCollectionModel, CustomerServiceOrderEventCollectionStore>(customerServiceOrderEventCollectionStoreName);
const storeSize = createCrudCollectionStoreSize<CustomerServiceOrderEventCollectionModel, CustomerServiceOrderEventCollectionStore>(customerServiceOrderEventCollectionStoreName);
const storeEntryIds = createCrudCollectionStoreEntryIds<CustomerServiceOrderEventCollectionModel, CustomerServiceOrderEventCollectionStore>(customerServiceOrderEventCollectionStoreName);

// Export the store accessors to be used by the data provider value
export const customerServiceOrderEventCollectionStoreAccessors = {
	fetchAll: fetchAllThunk,
	refetchAll: refetchAllThunk,
	fetchAllSorted: fetchAllSortedThunk,
	fetchAllFiltered: fetchAllFilteredThunk,
	fetchNext: fetchNextThunk,
	fetchEntity: fetchEntityThunk,
	refetchEntity: refetchEntityThunk,
	create: createThunk,
	mutate: mutateThunk,
	delete: deleteThunk,
	resolveFetchStatus,
	resolveActionStatus,
	selectCollection,
	countCollection,
	selectEntity,
	selectCreated,
	resetStore,
	storeSize,
	storeEntryIds
} as CrudCollectionStoreAccessors<CustomerServiceOrderEventCollectionModel, CustomerServiceOrderEventCollectionStore>;
