import React from 'react';

import { CustomerServiceOrderInstallationAppointmentModel } from '@abb-emobility/platform/domain-model';
import { FilterCriteria, SingleValueFilterComparator, SortCriteria, SortDirection } from '@abb-emobility/shared/api-integration-foundation';
import { createAccessTokenLoader, useAuth } from '@abb-emobility/shared/auth-provider';
import { FetchError } from '@abb-emobility/shared/business-error';
import {
	CollectionDataProviderProps,
	createCrudCollectionDataProviderValue,
	FetchMode,
	useDataProviderFetchFailedHandler,
	useDataProviderFetchInit,
	useDataProviderFetchSuspense
} from '@abb-emobility/shared/data-provider-foundation';
import { ModelPrimaryKey } from '@abb-emobility/shared/domain-model-foundation';
import { useEnv } from '@abb-emobility/shared/environment';
import { AppError } from '@abb-emobility/shared/error';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { Optional } from '@abb-emobility/shared/util';

import { customerServiceOrderInstallationAppointmentCollectionData } from './CustomerServiceOrderInstallationAppointmentCollectionData';
import {
	CustomerServiceOrderInstallationAppointmentCollectionStore,
	customerServiceOrderInstallationAppointmentCollectionStoreAccessors,
	customerServiceOrderInstallationAppointmentCollectionStoreName
} from './CustomerServiceOrderInstallationAppointmentCollectionSlice';

export type CustomerServiceOrderInstallationAppointmentCollectionDataProviderProps = {
	orderId: ModelPrimaryKey
} & Omit<CollectionDataProviderProps<CustomerServiceOrderInstallationAppointmentModel>, 'filterCriteria' | 'sortCriteria'>;

export function CustomerServiceOrderInstallationAppointmentCollectionDataProvider(props: CustomerServiceOrderInstallationAppointmentCollectionDataProviderProps) {
	const {
		fetchMode = FetchMode.IMMEDIATE,
		suspense = fetchMode === FetchMode.IMMEDIATE,
		pendingComponent = null,
		forceFetch,
		orderId
	} = props;

	const auth = useAuth();
	const env = useEnv();
	const l10n = useL10n();

	const apiBaseUrl = new Optional(process.env['NX_CUSTOMER_SERVICE_API_BASE_URL']).getOrThrow(new AppError('API base URL unavailable'));

	const dataProviderValue = createCrudCollectionDataProviderValue<CustomerServiceOrderInstallationAppointmentModel, CustomerServiceOrderInstallationAppointmentCollectionStore>(
		customerServiceOrderInstallationAppointmentCollectionStoreName,
		customerServiceOrderInstallationAppointmentCollectionStoreAccessors,
		apiBaseUrl,
		createAccessTokenLoader(auth, env)
	);

	useDataProviderFetchFailedHandler(dataProviderValue, new FetchError(l10n.translate('platformDataProvider.error.orderInstallationCollectionFetchMessage')));

	const sortCriteria: SortCriteria<CustomerServiceOrderInstallationAppointmentModel> = [{
		property: 'periodStartsAt',
		direction: SortDirection.ASCENDING
	}];
	const filterCriteria: FilterCriteria<CustomerServiceOrderInstallationAppointmentModel> = [{
		id: 'order',
		criteria: [{
			property: 'orderId',
			value: orderId,
			comparator: SingleValueFilterComparator.EQUAL
		}]
	}];
	useDataProviderFetchInit(fetchMode, () => dataProviderValue.fetch(sortCriteria, filterCriteria, forceFetch));

	return useDataProviderFetchSuspense(dataProviderValue, suspense, pendingComponent).getOrDefault(
		<customerServiceOrderInstallationAppointmentCollectionData.Provider value={dataProviderValue}>
			{props.children}
		</customerServiceOrderInstallationAppointmentCollectionData.Provider>
	);

}
