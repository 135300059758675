import React, { ReactNode } from 'react';

import { CustomerServiceActionType } from '@abb-emobility/platform/domain-model';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { ActionModalContent } from '@abb-emobility/shared/ui-primitive';
import { ButtonIcon, Icon, IconIdentifier, SegmentHeader, useModalDialogueManager } from '@abb-emobility/shared/ui-primitive';

import { HypermediaAction } from './ActionModalFactory.types';
import { AddNoteActionModal, NoteContext } from './modals/add-note-action-modal/AddNoteActionModal';
import { CancelinstallationActionModal } from './modals/cancel-installation-action-modal/CancelinstallationActionModal';
import { CancelOrderActionModal } from './modals/cancel-order-action-modal/CancelOrderActionModal';
import { CancelOrderAppointmentsActionModal } from './modals/cancel-order-appointments-action-modal/CancelOrderAppointmentsActionModal';
import { ReplaceOrderActionModal } from './modals/replace-order-action-modal/ReplaceOrderActionModal';
import { ResendMailActionModal } from './modals/resend-mail-modal/ResendMailActionModal';
import { ReturnOrderActionModal } from './modals/return-order-action-modal/ReturnOrderActionModal';
import { UpdateCustomerActionModal } from './modals/update-customer-action-modal/UpdateCustomerActionModal';
import { UpdateOrderActionModal } from './modals/update-order-action-modal/UpdateOrderActionModal';

type ActionModalFactoryReturnType = {
	renderModal: (action: HypermediaAction) => void;
};

export function useActionModalFactory(): ActionModalFactoryReturnType {

	const modalDialogueManager = useModalDialogueManager();
	const l10n = useL10n();

	const renderActionModal = (action: HypermediaAction): void => {

		switch (action.hypermediaLink.rel) {
			case CustomerServiceActionType.ADD_CUSTOMER_NOTE:
				modalDialogueManager.push({
					header: () => {
						return (
							<SegmentHeader caption={l10n.translate('platformCustomerServiceApp.actionModalFactory.addCustomerNoteHeading')}>
								<ButtonIcon onClick={modalDialogueManager.pop}>
									<Icon name={IconIdentifier.X} />
								</ButtonIcon>
							</SegmentHeader>
						);
					},
					content: (): ReactNode => {
						return (
							<ActionModalContent>
								<AddNoteActionModal noteContext={NoteContext.CUSTOMER} action={action} />
							</ActionModalContent>
						);
					}
				});
				break;
			case CustomerServiceActionType.ADD_ORDER_NOTE:
				modalDialogueManager.push({
					header: () => {
						return (
							<SegmentHeader caption={l10n.translate('platformCustomerServiceApp.actionModalFactory.addOrderNoteHeading')}>
								<ButtonIcon onClick={modalDialogueManager.pop}>
									<Icon name={IconIdentifier.X} />
								</ButtonIcon>
							</SegmentHeader>
						);
					},
					content: (): ReactNode => {
						return (
							<ActionModalContent>
								<AddNoteActionModal noteContext={NoteContext.ORDER} action={action} />
							</ActionModalContent>
						);
					}
				});
				break;
			case CustomerServiceActionType.CANCEL_ORDER:
				modalDialogueManager.push({
					header: () => {
						return (
							<SegmentHeader caption={l10n.translate('platformCustomerServiceApp.actionModalFactory.cancelOrderHeading')}>
								<ButtonIcon onClick={modalDialogueManager.pop}>
									<Icon name={IconIdentifier.X} />
								</ButtonIcon>
							</SegmentHeader>
						);
					},
					content: (): ReactNode => {
						return (
							<ActionModalContent wide={true}>
								<CancelOrderActionModal action={action} />
							</ActionModalContent>
						);
					}
				});
				break;
			case CustomerServiceActionType.CANCEL_ORDER_INSTALLATION:
				modalDialogueManager.push({
					header: () => {
						return (
							<SegmentHeader caption={l10n.translate('platformCustomerServiceApp.actionModalFactory.cancelInstallationHeading')}>
								<ButtonIcon onClick={modalDialogueManager.pop}>
									<Icon name={IconIdentifier.X} />
								</ButtonIcon>
							</SegmentHeader>
						);
					},
					content: (): ReactNode => {
						return (
							<ActionModalContent wide={true}>
								<CancelinstallationActionModal action={action} />
							</ActionModalContent>
						);
					}
				});
				break;
			case CustomerServiceActionType.CANCEL_ORDER_APPOINTMENTS:
				modalDialogueManager.push({
						header: () => {
							return (
								<SegmentHeader caption={l10n.translate('platformCustomerServiceApp.actionModalFactory.cancelOrderAppointmentsHeading')}>
									<ButtonIcon onClick={modalDialogueManager.pop}>
										<Icon name={IconIdentifier.X} />
									</ButtonIcon>
								</SegmentHeader>
							);
						},
						content: (): ReactNode => {
							return (
								<ActionModalContent>
									<CancelOrderAppointmentsActionModal action={action} />
								</ActionModalContent>
							);
						}
					}
				);
				break;
			case CustomerServiceActionType.EXTERNAL_LINK:
				break;
			case CustomerServiceActionType.RESEND_MAIL:
				modalDialogueManager.push({
						header: () => {
							return (
								<SegmentHeader caption={l10n.translate('platformCustomerServiceApp.actionModalFactory.resendMailHeading')}>
									<ButtonIcon onClick={modalDialogueManager.pop}>
										<Icon name={IconIdentifier.X} />
									</ButtonIcon>
								</SegmentHeader>
							);
						},
						content: (): ReactNode => {
							return (
								<ActionModalContent>
									<ResendMailActionModal action={action} />
								</ActionModalContent>
							);
						}
					}
				);
				break;
			case CustomerServiceActionType.RETURN_ORDER:
				modalDialogueManager.push({
					header: () => {
						return (
							<SegmentHeader caption={l10n.translate('platformCustomerServiceApp.actionModalFactory.returnOrderHeading')}>
								<ButtonIcon onClick={modalDialogueManager.pop}>
									<Icon name={IconIdentifier.X} />
								</ButtonIcon>
							</SegmentHeader>

						);
					},
					content: (): ReactNode => {
						return (
							<ActionModalContent wide={true}>
								<ReturnOrderActionModal action={action} />
							</ActionModalContent>
						);
					}
				});
				break;
			case CustomerServiceActionType.REPLACE_ORDER:
				modalDialogueManager.push({
					header: () => {
						return (
							<SegmentHeader caption={l10n.translate('platformCustomerServiceApp.actionModalFactory.replaceOrderHeading')}>
								<ButtonIcon onClick={modalDialogueManager.pop}>
									<Icon name={IconIdentifier.X} />
								</ButtonIcon>
							</SegmentHeader>

						);
					},
					content: (): ReactNode => {
						return (
							<ActionModalContent wide={true}>
								<ReplaceOrderActionModal action={action} />
							</ActionModalContent>
						);
					}
				});
				break;
			case CustomerServiceActionType.UPDATE_CUSTOMER:
				modalDialogueManager.push({
					header: () => {
						return (
							<SegmentHeader caption={l10n.translate('platformCustomerServiceApp.actionModalFactory.updateCustomerHeading')}>
								<ButtonIcon onClick={modalDialogueManager.pop}>
									<Icon name={IconIdentifier.X} />
								</ButtonIcon>
							</SegmentHeader>
						);
					},
					content: (): ReactNode => {
						return (
							<ActionModalContent>
								<UpdateCustomerActionModal action={action} />
							</ActionModalContent>
						);
					}
				});

				break;
			case CustomerServiceActionType.UPDATE_ORDER:
				modalDialogueManager.push({
					header: () => {
						return (
							<SegmentHeader caption={l10n.translate('platformCustomerServiceApp.actionModalFactory.updateOrderHeading')}>
								<ButtonIcon onClick={modalDialogueManager.pop}>
									<Icon name={IconIdentifier.X} />
								</ButtonIcon>
							</SegmentHeader>
						);
					},
					content: (): ReactNode => {
						return (
							<ActionModalContent>
								<UpdateOrderActionModal action={action} />
							</ActionModalContent>
						);
					}
				});
				break;
			default:
				console.warn('HypermediaactionModalFactory: case not defined: ' + action.hypermediaLink.rel);
		}
	};

	return {
		renderModal: renderActionModal
	};
}
