import React, { ReactNode } from 'react';

import {
	CustomerServiceOrderCollectionDataInterceptEmpty,
	CustomerServiceOrderCollectionDataSuspense,
	useCustomerServiceOrderCollectionData
} from '@abb-emobility/platform/data-provider';
import { CustomerServiceOrderCollectionItemModel } from '@abb-emobility/platform/domain-model';
import { FilterCriteria, SortCriteria } from '@abb-emobility/shared/api-integration-foundation';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	ActionbarItem,
	ButtonGhost,
	Card,
	CollectionFooter,
	CollectionGroupedFilter,
	CollectionSort,
	filterCriteriaFromFilterOptions,
	FilterOptionGroup,
	filterOptionsFromFilterCriteria,
	IconIdentifier,
	NotificationBig,
	NotificationBigStatus,
	SegmentHeader,
	Separator,
	SpinnerCircle
} from '@abb-emobility/shared/ui-primitive';

import { OrderFilterOption, orderFilterOptionGroups } from './OrderCollection.types';
import { useActionbarContentModifier } from '../actionbar-content/ActionbarContent.hooks';
import { OrderCollectionHeader } from '../order-collection-header/OrderCollectionHeader';
import { OrderCollectionItem } from '../order-collection-item/OrderCollectionItem';

export type OrderCollectionProps = {
	onFilterChange: (filterCriteria: FilterCriteria<CustomerServiceOrderCollectionItemModel>) => void,
	onSortChange: (sortCriteria: SortCriteria<CustomerServiceOrderCollectionItemModel>) => void
};

export function OrderCollection(props: OrderCollectionProps) {

	const { onSortChange, onFilterChange } = props;

	const l10n = useL10n();

	const orderCollectionData = useCustomerServiceOrderCollectionData();
	const effectiveFilterCriteria = orderCollectionData.queryFilterCriteria();
	const effectiveSortCriteria = orderCollectionData.querySortCriteria();
	const isPaginated = orderCollectionData.isPaginated();
	const hasNextPage = orderCollectionData.hasNextPage();
	const fetchPending = orderCollectionData.pending();
	const orders = orderCollectionData.query();

	useActionbarContentModifier([], []);

	const handleFilterApply = (filterOptions: Array<OrderFilterOption>) => {
		const filterCriteria = filterCriteriaFromFilterOptions(orderFilterOptionGroups, filterOptions);
		onFilterChange(filterCriteria);
		orderCollectionData.applyFilter(filterCriteria);
	};

	const handleSortApply = (sortCiteria: SortCriteria<CustomerServiceOrderCollectionItemModel>) => {
		onSortChange(sortCiteria);
		orderCollectionData.applySort(sortCiteria);
	};

	const handleShowMore = () => {
		orderCollectionData.fetchNext();
	};

	const handleRefresh = () => {
		orderCollectionData.refetch();
	};

	const renderEmptyState = (): ReactNode => {
		return (
			<NotificationBig
				status={NotificationBigStatus.EMPTY2}
				heading={l10n.translate('platformCustomerServiceApp.orderCollection.emptyState.heading')}
				message={l10n.translate('platformCustomerServiceApp.orderCollection.emptyState.message')}
			/>
		);
	};

	const renderShowMore = (): ReactNode => {
		if (!isPaginated) {
			return;
		}
		return (
			<ButtonGhost
				label={l10n.translate('platformCustomerServiceApp.orderCollection.action.showMore')}
				onClick={handleShowMore}
				disabled={!hasNextPage || fetchPending}
			/>
		);
	};

	const renderOrders = () => {
		return orders.map((order): React.JSX.Element => {
			return (
				<OrderCollectionItem
					order={order}
					key={order.id}
				/>
			);
		});
	};

	return (
		<Card>
			<SegmentHeader caption={l10n.translate('platformCustomerServiceApp.orderCollection.heading')}>
				<ActionbarItem
					label={l10n.translate('platformCustomerServiceApp.orderCollection.action.refresh')}
					icon={IconIdentifier.ARROWS_CLOCKWISE}
					onTrigger={handleRefresh}
				/>
				<CollectionGroupedFilter
					filterOptions={filterOptionsFromFilterCriteria(
						orderFilterOptionGroups as Array<FilterOptionGroup<OrderFilterOption, CustomerServiceOrderCollectionItemModel>>,
						effectiveFilterCriteria ?? []
					)}
					filterOptionGroups={orderFilterOptionGroups}
					onApply={handleFilterApply}
				/>
				<CollectionSort
					sortOptions={['orderDate', 'estimatedCompletionDate']}
					sortCriteria={effectiveSortCriteria ?? undefined}
					implicitSortOption={'estimatedCompletionDate'}
					onApply={handleSortApply}
				/>
			</SegmentHeader>

			<Separator />

			<CustomerServiceOrderCollectionDataSuspense pendingComponent={SpinnerCircle}>
				<CustomerServiceOrderCollectionDataInterceptEmpty emptyStateComponent={renderEmptyState}>

					<OrderCollectionHeader />
					<Separator />

					{renderOrders()}

					<CollectionFooter>
						{renderShowMore()}
					</CollectionFooter>

				</CustomerServiceOrderCollectionDataInterceptEmpty>
			</CustomerServiceOrderCollectionDataSuspense>
		</Card>
	);
}
