import React, { ReactNode } from 'react';

import {
	CustomerServiceCustomerMarketplaceOrderCollectionDataInterceptEmpty,
	CustomerServiceCustomerMarketplaceOrderCollectionDataSuspense,
	useCustomerServiceCustomerMarketplaceOrderCollectionData
} from '@abb-emobility/platform/data-provider';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { NotificationBig, NotificationBigStatus, SegmentHeader, Separator, SpinnerCircle } from '@abb-emobility/shared/ui-primitive';

import { MarketplaceOrderCollectionHeader } from '../marketplace-order-collection-header/MarketplaceOrderCollectionHeader';
import { MarketplaceOrderCollectionItem } from '../marketplace-order-collection-item/MarketplaceOrderCollectionItem';

export function CustomerMarketplaceOrderCollection() {

	const l10n = useL10n();
	const customerMarketplaceOrderCollectionData = useCustomerServiceCustomerMarketplaceOrderCollectionData();

	const orders = customerMarketplaceOrderCollectionData.query();

	const renderEmptyOrderCollectionState = (): ReactNode => {
		return (
			<NotificationBig
				status={NotificationBigStatus.EMPTY2}
				heading={l10n.translate('platformCustomerServiceApp.customerOrderCollection.emptyState.heading')}
				message={l10n.translate('platformCustomerServiceApp.customerOrderCollection.emptyState.message')}
			/>
		);
	};

	const renderOrderItems = () => {
		return orders.map((order) => {
			return (
				<MarketplaceOrderCollectionItem key={order.id} order={order} />
			);
		});
	};

	return (
		<>
			<SegmentHeader caption={l10n.translate('platformCustomerServiceApp.customerMarketplaceOrders.heading')} />
			<CustomerServiceCustomerMarketplaceOrderCollectionDataSuspense pendingComponent={SpinnerCircle}>
				<CustomerServiceCustomerMarketplaceOrderCollectionDataInterceptEmpty emptyStateComponent={renderEmptyOrderCollectionState}>
					<Separator />
					<MarketplaceOrderCollectionHeader />
					<Separator />
					{renderOrderItems()}
				</CustomerServiceCustomerMarketplaceOrderCollectionDataInterceptEmpty>
			</CustomerServiceCustomerMarketplaceOrderCollectionDataSuspense>
		</>

	);
}
