import {
	ArrayOfTimestampFieldNames,
	ModelConverter,
	TimestampFieldNames
} from '@abb-emobility/shared/domain-model-foundation';

import { CustomerServiceOrderEventFoundationModel } from './foundation/CustomerServiceOrderEventFoundationModel';

export type CustomerServiceOrderEventCollectionModel = {
	readonly entityAvailable: boolean
} & CustomerServiceOrderEventFoundationModel;

export class CustomerServiceOrderEventCollectionModelConverter extends ModelConverter<CustomerServiceOrderEventCollectionModel> {
	override getTimestampFields(): Array<TimestampFieldNames<CustomerServiceOrderEventCollectionModel> | ArrayOfTimestampFieldNames<CustomerServiceOrderEventCollectionModel>> {
		return ['eventDate'];
	}
}
