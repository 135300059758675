import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	platformCustomerServiceApp: {
		action: {
			showCustomer: 'Klant tonen'
		},
		actionItemFactory: {
			addCustomerNoteLabel: 'Notitie toevoegen',
			addOrderNoteLabel: 'Notitie toevoegen',
			cancelOrderLabel: 'Annuleren',
			cancelInstallationLabel: 'Installatie annuleren',
			cancelOrderAppointmentsLabel: 'Afspraken annuleren',
			externalLinkLabel: 'Externe link',
			resendMailLabel: 'Mail opnieuw verzenden',
			returnOrderLabel: 'Retour',
			replaceOrderLabel: 'Vervangen',
			updateCustomerLabel: 'Gegevens wijzigen',
			updateOrderLabel: 'Gegevens wijzigen'
		},
		actionModalFactory: {
			addCustomerNoteHeading: 'Notitie toevoegen',
			addOrderNoteHeading: 'Notitie toevoegen',
			cancelOrderHeading: 'Bestelling annuleren',
			cancelInstallationHeading: 'Installatie annuleren',
			cancelOrderAppointmentsHeading: 'Bestelafspraken annuleren',
			resendMailHeading: 'Mail opnieuw verzenden',
			returnOrderHeading: 'Bestelling retourneren',
			replaceOrderHeading: 'Bestelling vervangen',
			updateCustomerHeading: 'Klantgegevens wijzigen',
			updateOrderHeading: 'Bestelgegevens wijzigen'
		},
		actionModal: {
			pleaseSelect: 'Selecteer',
			addNote: {
				content: {
					category: 'Categorie',
					categories: {
						order: 'Bestelbon',
						customer: 'Klantnotitie',
						customerCall: 'Klantoproep'
					}
				}
			},
			addOrderNote: {
				content: {
					noteHeading: 'Notitie',
					notePlaceholder: 'Tekst invoegen',
					successHeading: 'Creëren bestelbon geslaagd',
					closeButtonLabel: 'Afsluiten'
				},
				footer: {
					confirm: 'Bevestigen',
					cancel: 'Annuleren'
				},
				error: {
					heading: 'Kon geen bestelbon maken.',
					message: 'Er is een fout opgetreden tijdens het maken van de bestelbon.'
				}
			},
			addCustomerNote: {
				content: {
					noteHeading: 'Notitie',
					notePlaceholder: 'Tekst invoegen',
					successHeading: 'Creëren klantnotitie geslaagd',
					closeButtonLabel: 'Afsluiten'
				},
				footer: {
					confirm: 'Bevestigen',
					cancel: 'Annuleren'
				},
				error: {
					heading: 'Kon geen klantnotitie maken.',
					message: 'Er is een fout opgetreden bij het maken van de klantnotitie.'
				}
			},
			addCustomerCallNote: {
				content: {
					noteHeading: 'Notitie',
					notePlaceholder: 'Tekst invoegen',
					customerCallReason: 'Reden van klantoproep',
					callReasonOptions: {
						afterSales: 'Aftersales',
						product: 'Product',
						installation: 'Installatie',
						preCheck: 'Voorcontrole',
						payment: 'Betaling',
						dataProtection: 'Gegevensbescherming',
						shipment: 'Verzending',
						cancellation: 'Retourzendingen',
						falseRouting: 'Foute routering',
						other: 'Overige',
						complaints: 'Klachten',
						returns: 'Retourzendingen'
					},
					callReasonNotePlaceholder: 'Beschrijving',
					successHeading: 'Notitie klantoproep geslaagd',
					closeButtonLabel: 'Afsluiten'
				},
				footer: {
					confirm: 'Bevestigen',
					cancel: 'Annuleren'
				},
				error: {
					heading: 'Kon geen notitie klantoproep maken.',
					message: 'Er is een fout opgetreden bij het maken van de notitie klantoproep.'
				}
			},
			cancelOrder: {
				heading: 'Bestelling',
				position: 'Positie',
				description: 'Beschrijving',
				reason: 'Reden',
				note: 'Beschrijving',
				cancelReasonValue: {
					other: 'Overige',
					wallboxBroken: 'Wallbox beschadigd',
					wallboxNotWorking: 'Wallbox werkt niet',
					withdrawal: 'Opzegging',
					installationNotPossible: 'Installatie niet mogelijk'
				},
				footer: {
					cancel: 'Annuleren',
					confirm: 'Bevestigen'
				},
				content: {
					successHeading: 'Bestelling succesvol geannuleerd',
					closeButtonLabel: 'Afsluiten'
				},
				error: {
					loading: {
						heading: 'De bestelgegevens konden niet worden geladen.',
						message: 'Er is een fout opgetreden tijdens het laden van de bestelgegevens.'
					},
					writing: {
						heading: 'Kon bestelling niet annuleren.',
						message: 'Er is een fout opgetreden tijdens het annuleren van de bestelling.'
					}
				}
			},
			cancelInstallation: {
				heading: 'Bestelling',
				position: 'Positie',
				description: 'Beschrijving',
				reason: 'Reden',
				note: 'Beschrijving',
				cancelReasonValue: {
					other: 'Overige',
					wallboxBroken: 'Wallbox beschadigd',
					wallboxNotWorking: 'Wallbox werkt niet',
					withdrawal: 'Opzegging',
					installationNotPossible: 'Installatie niet mogelijk'
				},
				footer: {
					cancel: 'Annuleren',
					confirm: 'Bevestigen'
				},
				content: {
					successHeading: 'Installatie annulering succesvol',
					closeButtonLabel: 'Afsluiten'
				},
				error: {
					loading: {
						heading: 'De bestelgegevens konden niet worden geladen.',
						message: 'Er is een fout opgetreden tijdens het laden van de bestelgegevens.'
					},
					writing: {
						heading: 'Installatie kon niet worden geannuleerd',
						message: 'Er is een fout opgetreden tijdens het annuleren van de installatie'
					}
				}
			},
			cancelOrderAppointments: {
				content: {
					notificationHeading: 'De installatiepartner wordt geïnformeerd over de annulering van de afspraak.',
					notificationMessage: 'De installatiepartner neemt contact op met de klant om een nieuwe afspraak te maken.',
					successHeading: 'Afspraken geannuleerd',
					successCloseButtonLabel: 'Afsluiten',
					reason: 'Reden'
				},
				cancelReasonValue: {
					deliveryDelay: 'Vertraging in levering',
					installationPartnerConflict: 'Conflict tussen installatiepartners',
					customerConflict: 'Conflict met klant',
					replacement: 'Vervanging'
				},
				footer: {
					confirm: 'Annulering bevestigen',
					cancel: 'Annuleren'
				},
				error: {
					heading: 'Kon afspraken niet annuleren.',
					message: 'Er is een fout opgetreden tijdens het verwijderen van de bestelafspraken.'
				}
			},
			resendMail: {
				content: {
					notificationHeading: 'De e-mail wordt opnieuw verzonden.',
					notificationMessage: 'De klant ontvangt een nieuwe e-mail.',
					successHeading: 'E-mail succesvol verzonden.',
					successCloseButtonLabel: 'Afsluiten'
				},
				footer: {
					confirm: 'E-mail opnieuw verzenden',
					cancel: 'Annuleren'
				},
				error: {
					heading: 'Kon e-mail niet opnieuw verzenden.',
					message: 'Er is een fout opgetreden tijdens het verzenden van de e-mail.'
				}
			},
			returnOrder: {
				heading: 'Bestelling',
				position: 'Positie',
				description: 'Beschrijving',
				note: 'Beschrijving',
				'return': 'Retour',
				selectReturn: 'Retourartikelen selecteren',
				selectReason: 'Reden voor retourzending selecteren',
				replacementItem: 'Vervangend artikel',
				yes: 'Ja',
				no: 'Nee',
				returnReason: {
					other: 'Overige',
					broken: 'Defect'
				},
				footer: {
					cancel: 'Annuleren',
					confirm: 'Bevestigen'
				},
				content: {
					successHeading: 'Retourproces succesvol gestart',
					closeButtonLabel: 'Afsluiten'
				},
				error: {
					loading: {
						heading: 'De bestelgegevens konden niet worden geladen.',
						message: 'Er is een fout opgetreden tijdens het laden van de bestelgegevens.'
					},
					writing: {
						heading: 'Het retourproces kon niet worden gestart.',
						message: 'Er is een fout opgetreden tijdens het uitvoeren van het retourproces.'
					}
				}
			},
			replaceOrder: {
				heading: 'Bestelling',
				position: 'Positie',
				description: 'Beschrijving',
				note: 'Beschrijving',
				'return': 'Vervangen',
				selectReturn: 'Selecteer vervangende artikelen',
				selectReason: 'Reden voor vervanging selecteren',
				returnReason: {
					other: 'Overige',
					broken: 'Defect'
				},
				footer: {
					cancel: 'Annuleren',
					confirm: 'Bevestigen'
				},
				content: {
					successHeading: 'Vervangingsproces succesvol gestart',
					closeButtonLabel: 'Afsluiten'
				},
				error: {
					loading: {
						heading: 'De bestelgegevens konden niet worden geladen.',
						message: 'Er is een fout opgetreden tijdens het laden van de bestelgegevens.'
					},
					writing: {
						heading: 'Kon het vervangingsproces niet starten.',
						message: 'Er is een fout opgetreden tijdens het uitvoeren van het vervangingsproces.'
					}
				}
			},
			updateCustomer: {
				content: {
					firstname: 'Voornaam',
					lastname: 'Achternaam',
					email: 'E-mail',
					personalInfoHeading: 'Persoonlijke informatie',
					contactDataHeading: 'Contactgegevens',
					successHeading: 'Gebruikersinformatie succesvol gewijzigd.',
					successCloseButtonLabel: 'Afsluiten'
				},
				footer: {
					confirm: 'Bevestigen',
					cancel: 'Annuleren'
				},
				error: {
					loading: {
						heading: 'Kon klantgegevens niet laden.',
						message: 'Er is een fout opgetreden tijdens het laden van de klantgegevens.'
					},
					writing: {
						heading: 'Kon klantgegevens niet bijwerken.',
						message: 'Er is een fout opgetreden tijdens het bijwerken van de klantgegevens.'
					}
				}
			},
			updateOrder: {
				content: {
					orderInformationHeading: 'Bestelgegevens',
					phoneLabel: 'Telefoon',
					languageHeading: 'Taal',
					languageLabel: ' Taalcode',
					successHeading: 'Bestelgegevens succesvol gewijzigd.',
					successCloseButtonLabel: 'Afsluiten'
				},
				footer: {
					confirm: 'Bevestigen',
					cancel: 'Annuleren'
				},
				error: {
					loading: {
						heading: 'Kon bestelgegevens niet laden.',
						message: 'Er is een fout opgetreden tijdens het laden van de bestelgegevens.'
					},
					writing: {
						heading: 'Kon bestelgegevens niet bijwerken.',
						message: 'Er is een fout opgetreden tijdens het bijwerken van de bestelgegevens.'
					}
				}
			}
		},
		appName: 'Klantenservice ABB',
		breadcrumbs: {
			tasks: 'Taakoverzicht',
			orders: 'Besteloverzicht',
			marketplaceOrders: 'Overzicht marktplaatsbestellingen',
			customers: 'Klantoverzicht'
		},
		comment: {
			categories: {
				order: 'Bestelbon',
				customer: 'Klantnotitie',
				customerCall: 'Klantoproep'
			},
			callReason: {
				afterSales: 'Aftersales',
				product: 'Product',
				installation: 'Installatie',
				preCheck: 'Voorcontrole',
				payment: 'Betaling',
				dataProtection: 'Gegevensbescherming',
				shipment: 'Verzending',
				cancellation: 'Retourzendingen',
				falseRouting: 'Foute routering',
				other: 'Overige',
				complaints: 'Klachten',
				returns: 'Retourzendingen'
			}
		},
		commentCollection: {
			heading: 'Opmerkingen',
			emptyState: {
				heading: 'Geen opmerkingen',
				message: 'Er zijn momenteel geen opmerkingen beschikbaar.'
			}
		},
		common: {
			tableHeader: {
				position: 'Positie',
				description: 'Beschrijving'
			}
		},
		customerCollection: {
			heading: 'Klanten',
			tableHead: {
				customer: 'Klant',
				customerId: 'Klant-ID',
				firstOrder: 'Eerste bestelling',
				lastOrder: 'Laatste bestelling'
			},
			emptyState: {
				heading: 'Geen klanten gevonden',
				message: 'Pas je filterinstellingen aan om klanten te vinden.'
			},
			action: {
				showMore: 'Meer tonen'
			}
		},
		customerInformation: {
			heading: 'Klantgegevens',
			tableHeader: {
				position: 'Positie',
				description: 'Beschrijving'
			},
			tableItem: {
				firstName: 'Voornaam',
				lastName: 'Achternaam',
				companyName: 'Bedrijfsnaam',
				mail: 'E-mail',
				firstOrder: 'Eerste bestelling',
				lastOrder: 'Laatste bestelling'
			}
		},
		customerOrders: {
			heading: 'Klantbestellingen'
		},
		customerMarketplaceOrders: {
			heading: 'Marketplace Klantbestellingen'
		},
		customerDetail: {
			subheader: {
				customerId: 'Klant-ID'
			},
			tabItems: {
				overview: 'Overzicht',
				comments: 'Opmerkingen'
			}
		},
		error: {
			generic: {
				heading: 'Er ging iets mis',
				message: 'Er is een onvoorziene fout opgetreden die niet kon worden opgelost',
				resolveAction: {
					label: 'Opnieuw laden'
				}
			},
			offline: {
				heading: 'Netwerkverbinding niet beschikbaar',
				message: 'Maak verbinding met een netwerk om de applicatie te gebruiken.'
			},
			notFound: {
				heading: 'Niet gevonden',
				message: 'De opgevraagde bron werd niet gevonden',
				resolveAction: {
					label: 'Mijn taken'
				}
			},
			customerNotFound: 'De gevraagde klant werd niet gevonden',
			orderNotFound: 'De gevraagde bestelling werd niet gevonden',
			taskNotFound: 'De gevraagde taak werd niet gevonden',
			callerNotFound: 'Er werd geen informatie over de beller gevonden',
			taskAssignMessage: 'Tijdens het toewijzen van de taak is een fout opgetreden.',
			taskCompleteMessage: 'Tijdens het uitvoeren van de taak is een fout opgetreden.'
		},
		navigation: {
			tasks: 'Mijn taken',
			orders: 'Bestellingen',
			marketplaceOrders: 'Marketplace Bestellingen',
			customers: 'Klanten',
			logout: 'Afmelden'
		},
		order: {
			installationType: {
				undetermined: 'Onbepaald',
				standard: 'Standaard',
				delta: 'Delta',
				cancelled: 'Geannuleerd'
			}
		},
		orderDetail: {
			subheader: {
				orderDate: 'Besteldatum',
				orderId: 'Bestellings-ID',
				marketplace: 'Marktplaats',
				plannedDelivery: 'Geplande levering',
				orderState: {
					onSchedule: 'Op schema',
					delayed: 'Vertraagd',
					cancelPending: 'In afwachting van annulering',
					cancelled: 'Geannuleerd',
					imported: 'Geïmporteerd',
					validationFailed: 'Ongeldig',
					done: 'Gereed'
				}
			},
			tabItems: {
				overview: 'Overzicht',
				installation: 'Installatie',
				delivery: 'Levering',
				accounting: 'Boekhouding',
				files: 'Bestanden',
				comments: 'Opmerkingen',
				tasks: 'Taken'
			},
			overview: {
				customerInformation: {
					heading: 'Klantgegevens'
				},
				orderInformation: {
					heading: 'Bestelgegevens',
					tableHeader: {
						position: 'Positie',
						description: 'Beschrijving'
					},
					tableItem: {
						language: 'Taal',
						phone: 'Telefoon',
						deliveryAddress: 'Adres'
					}
				},
				timeline: {
					heading: 'Tijdlijn'
				}
			},
			installation: {
				cancellation: {
					notification: {
						heading: 'De installatie is geannuleerd',
						message: 'De annulering werd uitgevoerd op {{cancellationDate}}. De opgegeven reden was "{{cancellationReason}}".'
					}
				},
				information: {
					heading: 'Informatie',
					types: {
						basicAnswers: 'Basisantwoorden',
						gridOperatorRegistration: 'Registratie netbeheerder',
						gridOperatorDecision: 'Beslissing netbeheerder',
						preCheck: 'Voorcontrole',
						offer: 'Offerte',
						approval: 'Goedkeuring'
					},
					emptyState: {
						heading: 'Geen bestelgegevens beschikbaar',
						message: 'Er zijn geen bestelgegevens beschikbaar.'
					}
				},
				installationAppointments: {
					heading: 'Geplande installaties',
					installer: 'Installateur',
					appointment: 'Afspraak',
					phone: 'Telefoon',
					mail: 'E-mail',
					emptyState: {
						heading: 'Geen afspraken gevonden.',
						message: 'Er zijn geen geplande afspraken.'
					}
				}
			},
			delivery: {
				orderItem: 'Bestelartikel',
				productCode: 'Productcode',
				address: {
					heading: 'Afleveradres',
					name: 'Naam',
					street: 'Straat',
					location: 'Plaats'
				},
				details: {
					heading: 'Aflevergegevens',
					shippingDate: 'Verzenddatum',
					address: 'Afleveradres',
					trackingCode: 'Volgcode',
					state: 'Verzendstatus',
					partner: 'Verzendpartner',
					tracking: 'Volgen',
					shippingState: {
						delivered: 'Geleverd',
						pending: 'In afwachting'
					},
					estimatedDeliveryDate: 'Geschatte leveringsdatum',
					deliveryDate: 'Leveringsdatum'
				},
				retoureDetails: {
					heading: 'Gegevens retourzending',
					date: 'Datum aanvraag',
					address: 'Retouradres',
					reason: 'Reden retour',
					returnNote: 'Retourbon',
					created: 'Gemaakt op'
				},
				replacementDetails: {
					heading: 'Details vervangende levering'
				},
				emptyState: {
					heading: 'Geen verzendingsgegevens beschikbaar',
					message: 'Er is momenteel geen verdere informatie over de zending.'
				}
			},
			accounting: {
				heading: 'Transactie',
				date: 'Betalingsdatum',
				state: 'Status',
				notification: 'Foutmelding',
				cardOwner: 'Kaarthouder',
				cardNumber: 'Kaartnummer',
				cardDueDate: 'Vervaldatum kaart',
				accountOwner: 'Rekeninghouder',
				accountNumber: 'IBAN',
				paymentState: {
					success: 'Succes',
					failed: 'Mislukt',
					settlementPending: 'Afwikkeling in behandeling'
				},
				emptyPaymentState: {
					heading: 'Geen betalingsinformatie beschikbaar',
					message: 'Er is momenteel geen verdere informatie over betalingen.'
				},
				emptyDownloadFilesState: {
					heading: 'Geen downloadbestanden beschikbaar',
					message: 'Er zijn momenteel geen downloadbestanden beschikbaar.'
				}
			},
			files: {
				emptyState: {
					heading: 'Geen downloadbestanden beschikbaar',
					message: 'Er zijn momenteel geen downloadbestanden beschikbaar.'
				}
			},
			throttleWallboxHint: {
				caption: 'Let op',
				message: 'Het stroomverbruik van de wallbox moet worden verlaagd volgens de vereisten van de netbeheerder.'
			},
			informationList: {
				heading: 'Informatie',
				emptyState: {
					heading: 'Nog geen bestelgegevens beschikbaar',
					message: 'Naarmate de bestelling vordert, vind u hier alle relevante informatie over de bestelling.'
				},
				caption: {
					basicAnswers: 'Basisantwoorden',
					gridOperatorRegistration: 'Registratie netbeheerder',
					gridOperatorDecision: 'Beslissing netbeheerder',
					preCheck: 'Voorcontrole',
					offer: 'Offerte',
					approval: 'Goedkeuring'
				},
				precheck: {
					heading: 'Voorcontrole',
					effort: 'Resultaten voorcontrole: Installatieafspraken en -inspanning',
					questionnaire: {
						heading: 'Antwoorden van klant'
					},
					installationType: {
						undetermined: 'Onbepaald',
						standard: 'Standaardinstallatie',
						delta: 'Delta-installatie'
					},
					decision: {
						accepted: 'Voldoende informatie',
						more: 'Meer informatie nodig',
						denied: 'Installatie niet mogelijk'
					},
					notes: 'Opmerkingen',
					hours: 'uur'
				},
				decision: {
					heading: 'Besluit netbeheerder',
					decision: {
						pending: 'Het installatieverzoek is nog in behandeling',
						accepted: 'De netbeheerder is akkoord met de installatie',
						denied: 'De netbeheerder heeft de installatie afgewezen'
					}
				},
				registration: {
					heading: 'Registratie Netbeheerder',
					documents: 'Documenten',
					gridOperator: 'Netbeheerder'
				},
				quotation: {
					heading: 'Offerte',
					conditions: 'Offertevoorwaarden'
				},
				complete: {
					success: {
						heading: 'Installatie geslaagd'
					},
					notes: 'Opmerkingen',
					images: 'Afbeeldingen bij de installatie',
					documents: 'Documenten met betrekking tot de installatie',
					signature: 'Handtekening klant'
				}
			}
		},
		orderEventTimeline: {
			emptyState: {
				heading: 'Geen gebeurtenissen gevonden',
				message: 'De geschiedenis is nog leeg'
			}
		},
		timelineItemDetails: {
			mailAddress: {
				label: 'Email'
			},
			section: {
				payment: 'Betalingen',
				appointments: 'Geplande afspraken'
			}
		},
		orderEvent: {
			type: {
				orderReceived: 'Bestelling ontvangen',
				orderAddressValidationSucceeded: 'Adres validatie geslaagd',
				orderAddressValidationFailed: 'Adres validatie mislukt',
				orderAddressCorrected: 'adres van bestelling gecorrigeerd',
				accountingOrderCreated: 'Boekhoudkundige order aangemaakt',
				orderConfirmationMailSent: 'Bevestigingsmail verzonden',
				basicAnswersReceived: 'Antwoorden op vragenlijst ontvangen',
				gridOperatorRegistrationStarted: 'Registratie netbeheerder gestart',
				gridOperatorFeedbackReceived: 'Feedback netbeheerder ontvangen',
				gridOperatorRejectionNotificationSent: 'Netbeheerder afwijzing ontvangen',
				basicAnswersEvaluated: 'Antwoorden op vragenlijst geëvalueerd',
				precheckPreferencesMailSent: 'PreCheck contactverzoeken mail verzonden',
				remotePrecheckExecuted: 'Voorcontrole op afstand uitgevoerd',
				deltaQuotationCreated: 'Deltaofferte aangemaakt',
				deltaQuotationMailSent: 'Delta-offerte verzonden',
				deltaQuotationAccepted: 'Delta-offerte geaccepteerd',
				paymentSucceeded: 'Betaling geslaagd',
				paymentFailedOrTimedOut: 'Betaling mislukt',
				paymentReminderMailSent: 'Betalingsherinnering verzonden',
				paymentConfirmationMailSent: 'Bevestigingsmail betaling verzonden',
				appointmentArranged: 'Afspraak geregeld',
				appointmentArrangedNotificationSent: 'Kennisgeving afspraak verstuurd',
				shippingConfirmed: 'Levering bevestigd',
				installationConfirmed: 'Installatie bevestigd',
				replacementInstallationConfirmed: 'Vervangende installatie bevestigd',
				installationApprovalMailSent: 'Bevestigingsmail voor installatie verzonden',
				invoiceCreated: 'Factuur aangemaakt',
				invoiceNotificationSent: 'Factuurbevestiging verzonden',
				orderCancelled: 'Bestelling geannuleerd',
				gridOperatorRegistrationRevoked: 'Registratie netbeheerder geannuleerd',
				customerCallReceived: 'Ontvangen oproep van klant',
				returnOrReplacementShipmentReceived: 'Retourzending of vervangende levering ontvangen',
				returnInitiated: 'Retourzending geïnitialiseerd',
				replacementInitiated: 'Vervangende levering geïnitialiseerd',
				cancellationInitiated: 'Annulering geïnitialiseerd',
				orderNoteCreated: 'Bestelbon aangemaakt',
				refundCompleted: 'Restitutie voltooid',
				installationCancellationMailSent: 'Annuleringsmelding voor installatie verzonden'
			},
			category: {
				accounting: 'Boekhouding',
				customer: 'Klant',
				customerService: 'Klantenservice',
				deltaInstallation: 'Delta-installatie',
				gridOperator: 'Netbeheerder',
				installation: 'Installatie',
				order: 'Order',
				payment: 'Betaling',
				preCheck: 'PreCheck',
				shipping: 'Verzending'
			}
		},
		marketplaceOrder: {
			orderState: {
				paymentPending: 'Betaling in behandeling',
				waitingForFulfillment: 'Wachten op vervulling',
				fulfillmentStarted: 'Vervulling gestart',
				cancelled: 'Geannuleerd',
				done: 'Gereed'
			}
		},
		orderCollection: {
			heading: 'Bestellingen',
			tableHead: {
				order: 'Bestelling',
				status: 'Status',
				language: 'Taal',
				plannedDeliveryDate: 'Geplande levering',
				createdAt: 'Gemaakt op'
			},
			emptyState: {
				heading: 'Geen bestellingen gevonden',
				message: 'Pas je filterinstellingen aan om bestellingen te vinden.'
			},
			action: {
				refresh: 'Actualiseer',
				showMore: 'Meer tonen'
			},
			orderState: {
				onSchedule: 'Op schema',
				delayed: 'Vertraagd',
				cancelPending: 'In afwachting van annulering',
				cancelled: 'Geannuleerd',
				imported: 'Geïmporteerd',
				validationFailed: 'Ongeldig',
				done: 'Gereed'
			}
		},
		customerOrderCollection: {
			emptyState: {
				heading: 'Geen bestellingen gevonden',
				message: 'Er zijn geen bestellingen gevonden voor deze klant.'
			}
		},
		orderLineItemCollection: {
			heading: 'Bestelling',
			position: 'Positie',
			description: 'Beschrijving',
			price: 'Prijs',
			productCode: 'Productcode',
			totalPrice: 'Totale prijs'
		},
		inboundOrderCollection: {
			heading: 'Bestellingen gekoppeld aan het telefoonnummer {{caller}}',
			emptyState: {
				heading: 'Geen bestellingen gevonden',
				message: 'Misschien wil je in plaats daarvan een zoekopdracht proberen.',
				searchLabel: 'Zoeken'
			},
			action: {
				showMore: 'Meer tonen'
			}
		},
		searchbar: {
			placeholder: 'Zoeken',
			results: {
				orders: 'Bestellingen',
				marketplaceOrders: 'Marktplaats Bestellingen',
				customers: 'Klanten',
				showResultPage: 'Pagina met resultaten weergeven',
				showResultPageDescription: {
					resultsFromTo: 'Resultaat 1 tot',
					of: 'van'
				}
			}
		},
		searchResultCollection: {
			heading: 'Zoekresultaten',
			headingWithSearchterm: 'Zoekresultaten voor',
			headingCustomers: 'Klanten',
			headingOrders: 'Bestellingen',
			headingMarketplaceOrders: 'Marktplaats Bestellingen',
			emptyState: {
				heading: 'Geen zoekresultaten gevonden',
				message: 'Pas je zoekterm aan en probeer het opnieuw.'
			},
			idleState: {
				heading: 'Geen zoekterm opgegeven',
				message: 'Voer een zoekterm in.'
			},
			action: {
				showMore: 'Meer tonen'
			}
		},
		task: {
			assign: {
				successMessage: 'Taak toegewezen'
			},
			error: {
				taskNotFound: 'Taak niet gevonden',
				taskTypeNotFound: 'Taaktype niet gevonden'
			},
			gridOperatorRegistration: {
				continueToFeedback: {
					buttonLabel: 'Verdergaan'
				}
			},
			basicAnswers: {
				continueToQuotation: {
					buttonLabel: 'Verdergaan'
				},
				button: {
					close: 'Afsluiten',
					submit: 'Verzenden'
				},
				questionnaire: {
					heading: 'Basisantwoorden'
				}
			},
			completion: {
				successHeading: 'Taak met succes voltooid',
				closeButtonLabel: 'Afsluiten'
			},
			preCheck: {
				button: {
					close: 'Afsluiten',
					submit: 'Verzenden'
				},
				contact: {
					description: 'Contact'
				}
			},
			gridOperator: {
				button: {
					close: 'Afsluiten',
					submit: 'Verzenden'
				}
			},
			quotation: {
				button: {
					close: 'Afsluiten',
					submit: 'Verzenden'
				},
				deltaPackages: {
					totalPurchaseNetPrice: 'Totale aankoopprijs (netto)',
					totalSalesGrossPrice: 'Totale verkoopprijs (inclusief {{vatRate}}% BTW)',
					purchaseNetPrice: 'Aankoopprijs (netto)',
					salesGrossPrice: 'Verkoopprijs (bruto)'
				}
			},
			payment: {
				button: {
					close: 'Afsluiten',
					submit: 'Verzenden'
				}
			},
			appointment: {
				button: {
					close: 'Afsluiten',
					submit: 'Verzenden'
				}
			},
			installation: {
				button: {
					close: 'Afsluiten',
					submit: 'Verzenden'
				}
			}
		},
		taskCollection: {
			heading: 'Taken',
			tableHead: {
				task: 'Taak',
				status: 'Status',
				language: 'Taal'
			},
			action: {
				refresh: 'Actualiseer',
				showMore: 'Meer tonen'
			},
			assign: {
				successMessage: 'De taak werd met succes toegewezen',
				errorMessage: 'Tijdens het toewijzen van de taak is een fout opgetreden.'
			},
			emptyState: {
				heading: 'Geen taken gevonden',
				message: 'Pas je filterinstellingen aan om taken te vinden.'
			},
			orderEmptyState: {
				heading: 'Geen taken gevonden',
				message: 'Voor deze bestelling hoeft niets te worden gedaan.'
			}
		},
		taskCollectionItem: {
			type: {
				customerAnswerBasicQuestionsEscalation: 'Escalatie: Basisvraag',
				customerProvidePreCheckPreferencesEscalation: 'Escalatie: Voorcontrole',
				customerHandleUnfeasibleInstallationEscalation: 'Escalatie: Installatie onhaalbaar',
				customerHandleGridOperatorRejectionEscalation: 'Escalatie: Afwijzing netbeheerder',
				customerAcceptQuotationEscalation: 'Escalatie: Offerte',
				customerPerformPaymentEscalation: 'Escalatie: Betaling',
				customerApproveInstallationEscalation: 'Escalatie: Installatie goedkeuren',
				customerAddressValidationEscalation: 'Escalatie: Adresvalidatie',
				customerUncoveredInstallationAddress: 'Niet overdekte installatie Adres',
				installationPartnerEvaluateBasicAnswersEscalation: 'Escalatie: Basisantwoorden',
				installationPartnerExecuteRemotePreCheckEscalation: 'Escalatie: Voorcontrole op afstand uitvoeren',
				installationPartnerRegisterAtGridOperatorEscalation: 'Escalatie: Registreren bij netbeheerder',
				installationPartnerProvideGridOperatorDecisionEscalation: 'Escalatie: Netbeheerder opgeven',
				installationPartnerProvideQuotationEscalation: 'Escalatie: Offerte geven',
				installationPartnerArrangeAppointmentEscalation: 'Escalatie: Afspraken maken',
				installationPartnerCompleteInstallationEscalation: 'Escalatie: Volledige installatie'
			},
			status: {
				started: 'Komend',
				dueToday: 'Vandaag uitgerekend',
				overdue: 'Achterstallig',
				escalated: 'Geëscaleerd',
				done: 'Gereed',
				doneOverdue: 'Gereed',
				doneEscalated: 'Gereed'
			},
			category: {
				customer: 'Klant',
				installationPartner: 'Install. Partner'
			}
		},
		pageTitle: {
			tasks: 'Taakoverzicht',
			orders: 'Besteloverzicht',
			order: 'Bestelgegevens',
			marketplaceOrders: 'Overzicht marktplaatsbestellingen',
			marketplaceOrder: 'Detail marktplaatsbestelling',
			customers: 'Klantoverzicht',
			error: 'Fout',
			notFound: 'Niet gevonden',
			inbound: 'Inkomend gesprek {{caller}}'
		},
		viewHeader: {
			tasks: 'Taakoverzicht',
			orders: 'Besteloverzicht',
			marketplaceOrders: 'Overzicht marktplaatsbestellingen',
			customers: 'Klantoverzicht',
			inbound: 'Inkomend gesprek van {{caller}}'
		},
		modal: {
			task: {
				section: {
					customerInformation: {
						heading: 'Klant',
						position: 'Positie',
						description: 'Beschrijving',
						name: 'Naam',
						address: 'Afleveradres',
						phone: 'Telefoonnummer',
						mail: 'E-mail'
					},
					installationEffortInformation: {
						heading: 'Installatie-inspanning',
						trade: 'Vakgroep',
						effort: 'Inspanning',
						hours: 'uur'
					},
					installationPartnerInformation: {
						heading: 'Installatiepartner',
						position: 'Positie',
						description: 'Beschrijving',
						name: 'Naam',
						mail: 'E-mail',
						phone: 'Telefoon'
					},
					orderInformation: {
						heading: 'Bestelgegevens'
					},
					quotationInformation: {
						heading: 'Offerte',
						position: 'Positie',
						description: 'Beschrijving',
						price: 'Prijs',
						deltaInstallation: 'Beslissing: Delta-installatie',
						grossPrice: 'Som',
						vat: 'VAT',
						include: 'toevoegen'
					},
					taskInformation: {
						heading: 'Taak',
						position: 'Positie',
						description: 'Beschrijving',
						createdAt: 'Gemaakt op',
						dueDate: 'Vervaldatum',
						responsible: 'Verantwoordelijk',
						openTask: 'Open taak'
					},
					contactInformation: {
						heading: 'Contactvoorkeuren'
					},
					gridOperatorInformation: {
						heading: 'Netbeheerderinformatie'
					},
					payment: {
						accept: 'Offerte aanvaard'
					},
					addressValidation: {
						heading: 'Adresvalidatie',
						invalidAddressLabel: {
							delivery: 'Ongeldig afleveringsadres',
							billing: 'Ongeldig factuuradres',
							multiple: 'Ongeldig afleverings- en factuuradres'
						},
						hint: {
							heading: 'Een nieuw adres invoegen voor validatie',
							message: 'Het nieuwe adres wordt opnieuw gevalideerd wanneer de taak wordt ingediend.'
						},
						error: {
							heading: 'De adresvalidatie is mislukt',
							message: 'Vul de juiste adresgegevens in.'
						},
						newAddress: {
							heading: 'Nieuw adres',
							street: 'Straat en huisnummer ',
							zip: 'Postcode',
							city: 'Plaats',
							country: 'Land',
							region: 'Regio'
						}
					},
					uncoveredInstallationAddress: {
						message: 'De klant werd geïnformeerd over de annulering van de bestelling',
						info: 'Niet overdekte installatie Adres',
						infoHeading: 'De klant informeren',
						infoDescription: 'De klant moet geïnformeerd worden dat het installatieadres niet gedekt is en dat de installatie geannuleerd is.'
					},
					hint: {
						heading: 'Laatste controle',
						unfeasible: 'Beslissing: Installatie niet haalbaar',
						rejected: 'Afgewezen door netbeheerder'
					}
				}
			}
		}
	}
};

export default literals;
