import React from 'react';

import { CustomerServiceActionType } from '@abb-emobility/platform/domain-model';
import { HypermediaLink } from '@abb-emobility/shared/api-integration-foundation';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import { ActionbarItem, IconIdentifier } from '@abb-emobility/shared/ui-primitive';

export type ActionItemFactoryProps = {
	hypermediaLink: HypermediaLink
	onClick: () => void
};

export function ActionItemFactory(props: ActionItemFactoryProps): React.JSX.Element {

	const { hypermediaLink, onClick } = props;
	const l10n = useL10n();

	const renderActionItem = () => {

		switch (hypermediaLink.rel) {
			case CustomerServiceActionType.ADD_CUSTOMER_NOTE:
				return (
					<ActionbarItem
						label={l10n.translate('platformCustomerServiceApp.actionItemFactory.addCustomerNoteLabel')}
						icon={IconIdentifier.PENCIL_SIMPLE}
						onTrigger={onClick}
					/>
				);
			case CustomerServiceActionType.ADD_ORDER_NOTE:
				return (
					<ActionbarItem
						label={l10n.translate('platformCustomerServiceApp.actionItemFactory.addOrderNoteLabel')}
						icon={IconIdentifier.PENCIL_SIMPLE}
						onTrigger={onClick}
					/>
				);
			case CustomerServiceActionType.CANCEL_ORDER:
				return (
					<ActionbarItem
						label={l10n.translate('platformCustomerServiceApp.actionItemFactory.cancelOrderLabel')}
						icon={IconIdentifier.X}
						onTrigger={onClick}
					/>
				);
			case CustomerServiceActionType.CANCEL_ORDER_INSTALLATION:
				return (
					<ActionbarItem
						label={l10n.translate('platformCustomerServiceApp.actionItemFactory.cancelInstallationLabel')}
						icon={IconIdentifier.LIGHTNING_SLASH}
						onTrigger={onClick}
					/>
				);
			case CustomerServiceActionType.CANCEL_ORDER_APPOINTMENTS:
				return (
					<ActionbarItem
						label={l10n.translate('platformCustomerServiceApp.actionItemFactory.cancelOrderAppointmentsLabel')}
						icon={IconIdentifier.CALENDAR_X}
						onTrigger={onClick}
					/>
				);
			case CustomerServiceActionType.EXTERNAL_LINK:
				return (
					<ActionbarItem
						label={hypermediaLink.title ?? l10n.translate('platformCustomerServiceApp.actionItemFactory.externalLinkLabel')}
						icon={IconIdentifier.BROWSER}
						href={hypermediaLink.link}
					/>
				);
			case CustomerServiceActionType.RESEND_MAIL:
				return (
					<ActionbarItem
						label={l10n.translate('platformCustomerServiceApp.actionItemFactory.resendMailLabel')}
						icon={IconIdentifier.PAPER_PLANE_RIGHT}
						onTrigger={onClick}
					/>
				);
			case CustomerServiceActionType.RETURN_ORDER:
				return (
					<ActionbarItem
						label={l10n.translate('platformCustomerServiceApp.actionItemFactory.returnOrderLabel')}
						icon={IconIdentifier.ARROW_U_DOWN_LEFT}
						onTrigger={onClick}
					/>
				);
			case CustomerServiceActionType.REPLACE_ORDER:
				return (
					<ActionbarItem
						label={l10n.translate('platformCustomerServiceApp.actionItemFactory.replaceOrderLabel')}
						icon={IconIdentifier.ARROWS_COUNTER_CLOCKWISE}
						onTrigger={onClick}
					/>
				);
			case CustomerServiceActionType.UPDATE_CUSTOMER:
				return (
					<ActionbarItem
						label={l10n.translate('platformCustomerServiceApp.actionItemFactory.updateCustomerLabel')}
						icon={IconIdentifier.GEAR}
						onTrigger={onClick}
					/>
				);
			case CustomerServiceActionType.UPDATE_ORDER:
				return (
					<ActionbarItem
						label={l10n.translate('platformCustomerServiceApp.actionItemFactory.updateOrderLabel')}
						icon={IconIdentifier.GEAR}
						onTrigger={onClick}
					/>
				);
			default:
				console.warn('HypermediaActionItemFactory: case not defined: ' + hypermediaLink.rel);
				return null;
		}
	};

	return (
		<>
			{renderActionItem()}
		</>
	);
}
