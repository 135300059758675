import { AnyCustomerServiceSearchResultModel } from '@abb-emobility/platform/domain-model';
import { CrudApiClient } from '@abb-emobility/shared/api-integration-foundation';
import { JsonWebToken } from '@abb-emobility/shared/auth-provider';
import { Nullable } from '@abb-emobility/shared/util';

import { CustomerServiceSearchApiClient } from './CustomerServiceSearchApiClient';
import { CustomerServiceSearchApiClientMock } from './CustomerServiceSearchApiClientMock';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CustomerServiceSearchApiClientInterface extends CrudApiClient<AnyCustomerServiceSearchResultModel> {
}

export class CustomerServiceSearchApiClientFactory {

	private static mockConfigured = false;

	public static create(baseUrl: string, jsonWebToken: Nullable<JsonWebToken> = null): CustomerServiceSearchApiClientInterface {
		if (process.env.NX_PLATFORM_CS_SEARCH_API_MOCKED === 'true') {
			const apiClientMock = new CustomerServiceSearchApiClientMock().init(baseUrl, jsonWebToken);
			if (!this.mockConfigured) {
				apiClientMock.configureFetchMock();
				this.mockConfigured = true;
			}
			return apiClientMock;
		}
		return new CustomerServiceSearchApiClient().init(baseUrl, jsonWebToken);
	}

}
