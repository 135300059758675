import { ModelConverter } from '@abb-emobility/shared/domain-model-foundation';
import { IsoLanguageCode } from '@abb-emobility/shared/iso-language-code';

export type CustomerServiceUpdateOrderPayloadModel = {
	phoneNumber: string,
	languageCode: IsoLanguageCode
};

export class CustomerServiceUpdateOrderPayloadModelConverter extends ModelConverter<CustomerServiceUpdateOrderPayloadModel> {
}
