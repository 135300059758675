import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
	createFullNameFromCustomer,
	CustomerServiceMarketplaceOrderCollectionItemModel
} from '@abb-emobility/platform/domain-model';
import { useL10n } from '@abb-emobility/shared/localization-provider';
import {
	ButtonIcon,
	CollectionItemDate,
	CollectionItemHeader,
	CollectionItemHeaderItem,
	CollectionItemLabel,
	FirstChildWidth,
	Icon,
	IconIdentifier,
	Separator, Status
} from '@abb-emobility/shared/ui-primitive';
import { l10nLiteralFromEnumValue, truncateCenter } from '@abb-emobility/shared/util';

import { createRouteUrl, RoutePath } from '../../router/Routes';

import './MarketplaceOrderCollectionItem.scss';

type MarketplaceOrderCollectionItemProps = {
	order: CustomerServiceMarketplaceOrderCollectionItemModel,
	disabled?: boolean,
	onNavigate?: () => void;
	icon?: IconIdentifier,
	closingSeperator?: boolean
};

export function MarketplaceOrderCollectionItem(props: MarketplaceOrderCollectionItemProps) {

	const { order, disabled = false, icon = IconIdentifier.ARROW_RIGHT, onNavigate, closingSeperator = true } = props;

	const l10n = useL10n();

	const navigate = useNavigate();

	const width = FirstChildWidth.FIRST_CHILD_WIDTH_2;
	const handleNavigate = () => {
		if (onNavigate) {
			onNavigate();
		}
		navigate(createRouteUrl(RoutePath.MARKETPLACE_ORDER, ['orderId', order.id]));
	};

	const renderClosingSeperator = () => closingSeperator ? <Separator /> : null;
	return (
		<>
			<CollectionItemHeader disabled={disabled}>
				<CollectionItemHeaderItem firstChildWidth={width}>
					<CollectionItemLabel
						label={createFullNameFromCustomer(order.customer)}
						orderId={order.marketplaceOrderId}
						additionalIcon={IconIdentifier.PACKAGE}
						additionalAttribute={truncateCenter(order.marketplace, 18)}
					/>
				</CollectionItemHeaderItem>

				<CollectionItemHeaderItem firstChildWidth={width}>
					<Status
						label={l10n.translate(l10nLiteralFromEnumValue(order.state, 'platformCustomerServiceApp.marketplaceOrder.orderState'))}
						state={order.state}
					/>
				</CollectionItemHeaderItem>

				<CollectionItemHeaderItem firstChildWidth={width}>
					<div className="marketplace-order-collection-item__language">{order.languageCode.toUpperCase()}</div>
				</CollectionItemHeaderItem>

				<CollectionItemHeaderItem firstChildWidth={width}>
					<CollectionItemDate date={l10n.formatTimestampDate(order.orderDate)} />
				</CollectionItemHeaderItem>

				<CollectionItemHeaderItem firstChildWidth={width}>
					<ButtonIcon onClick={handleNavigate} disabled={disabled}>
						<Icon name={icon} />
					</ButtonIcon>
				</CollectionItemHeaderItem>

			</CollectionItemHeader>
			{renderClosingSeperator()}
		</>
	);
}
