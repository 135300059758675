import { LiteralStruct } from '@abb-emobility/shared/localization-provider';

export const literals: LiteralStruct = {
	platformDataProvider: {
		error: {
			customerCollectionFetchMessage: 'Tijdens het laden van de klantdata is een fout opgetreden.',
			customerEntityFetchMessage: 'Tijdens het laden van de klantgegevens is een fout opgetreden.',
			taskFetchMessage: 'Tijdens het laden van de taakgegevens is een fout opgetreden.',
			taskSearchMessage: 'Tijdens het zoeken naar een taak is een fout opgetreden.',
			taskCollectionFetchMessage: 'Tijdens het laden van de taakdata is een fout opgetreden.',
			orderFetchMessage: 'Tijdens het laden van de bestelgegevens is een fout opgetreden.',
			orderCollectionFetchMessage: 'Tijdens het laden van de besteldata is een fout opgetreden.',
			orderEventCollectionFetchMessage: 'Tijdens het laden van de bestelgeschiedenis is een fout opgetreden.',
			orderEventEntityFetchMessage: 'Tijdens het laden van de bestelgebeurtenis is een fout opgetreden.',
			orderShipmentCollectionFetchMessage: 'Tijdens het laden van de verzendgegevens is een fout opgetreden.',
			orderAccountingCollectionFetchMessage: 'Tijdens het laden van de accountgegevens is een fout opgetreden.',
			orderFilesCollectionFetchMessage: 'Tijdens het laden van de bestanden is een fout opgetreden.',
			orderInstallationCollectionFetchMessage: 'Tijdens het laden van de installatiegegevens is een fout opgetreden.',
			orderNotesCollectionFetchMessage: 'Tijdens het laden van de notities is er een fout opgetreden.',
			orderInformationCollectionFetchMessage: 'Tijdens het laden van de bestelgegevens is een fout opgetreden.',
			installerAppointmentCollectionFetchMessage: 'Tijdens het laden van de afspraken is er een fout opgetreden.',
			reportCollectionFetchMessage: 'Tijdens het laden van de rapporten is er een fout opgetreden.',
			searchCollectionFetchMessage: 'Tijdens het zoeken naar gegevens is een fout opgetreden.',
			orderPaymentCollectionFetchMessage: 'Tijdens het laden van de betalingsgegevens is een fout opgetreden.',
			orderInformationEntityFetchMessage: 'Tijdens het laden van de bestelgegevens is een fout opgetreden.'
		}
	}
};

export default literals;
